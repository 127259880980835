<template>
  <b-col cols="12" lg = "2" class="p-0 admin_sidebar nice-scroll"> 
    <div class="d-block p-2 mb-2">
      <div class="separator">
        Correlations Panel Control
      </div>
      <div class="card p-2 mb-2 text-muted small">
        Correlations Applied <br>
        <span>
          Select exchange: {{exchange}}<br>
        </span>
        <span>
          Symbols search: {{$store.state.search_symbols}}
        </span>
        <span>
          Symbols quantity: {{exchange === 'kucoin'? quantity_kucoin : quantity_binance}}
        </span>
        <span>
          Select symbols: {{$store.state.symbols_correlator.length}}
        </span>
      </div>
      <div class="card p-2 mb-2" v-if="!loadingSymbol">
        <label>Exchanges</label>
        <select v-model="exchange" class="form-control">
          <option value="kucoin">Kucoin</option>
          <option value="binance">Binance</option>
        </select>
      </div>
      <div class="card mb-2" v-if="loadingSymbol">
        <b-card>
          <b-skeleton animation="wave" width="75%"></b-skeleton>
          <b-skeleton animation="wave" width="55%"></b-skeleton>
        </b-card>
      </div>
      <TimeframesMenuResponsive v-if="!loadingSymbol"/>
      <div class="card mb-2" v-if="loadingSymbol">
        <b-card>
          <b-skeleton animation="wave" width="75%"></b-skeleton>
          <b-skeleton animation="wave" width="55%"></b-skeleton>
        </b-card>
      </div>
      <SymbolOptions :exchange="exchange" @payload="getData" @response="getResponse" @handleLoadingSymbol="handleLoadingSymbol"/>
      <TableSymbols/>
    </div>
  </b-col>
</template>

<script>
import SymbolOptions from './exchange-options/SymbolOptions.vue';
import TableSymbols from './TableSymbols.vue';
import TimeframesMenuResponsive from './correlation-chart/TimeframesMenuResponsive.vue'


export default {
  name: 'ExchangeOptions',
  components: {
    SymbolOptions,
    TableSymbols,
    TimeframesMenuResponsive
  },
  data() {
    return {
      exchange: 'kucoin',
      quantity_kucoin: 0,
      quantity_binance: 0,
      loadingSymbol: false,
    };
  },
  methods: {
    getData(value) {
      this.search = value.search;
    },
    getResponse(value) {
      if (value && value.paginate && value.paginate.binance_paginate && value.paginate.kucoin_paginate.records) this.quantity_kucoin = value.paginate.kucoin_paginate.records
      if (value && value.paginate && value.paginate.kucoin_paginate && value.paginate.binance_paginate.records) this.quantity_binance = value.paginate.binance_paginate.records
    },
    handleLoadingSymbol(value) {
      this.loadingSymbol = value;
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .custom-select, .swal2-select, .swal2-input {
    background-color: #1d1e25 !important;
    color: #f5f5f5 !important;
    border: 0 !important;
    font-size: 14px !important;
    height: 38px !important;
}
</style>