<template>
	<div :class="`page__investor`">
    <b-container fluid>
      <b-row class="p-0">
        <CardSubInvestorInfo />
        <ChartPerfomance v-show="!$store.state.expert_mode"/>
        <b-col cols="12" lg="2" class="p-0 sidebar__user_open_orders nice-scroll">
          <div class="row">
            <div class="col-md-12">
              <BalancesDetailInvestors />
            </div>
            <div class="col-md-12">
	            <div class="p-2">
		            <div class="separator">
		              Orders opened
		              <span class="float-right mono">
		                ({{$store.state.open_orders_paginate.records}})
		              </span>
		            </div>
		            <CardOpenedOrdersContainer @refresh_order="refresh_order" />
		          </div>
		        </div>
          </div>
        </b-col>
        <b-col cols="12" lg="2" class="p-0 sidebar__user_manual_orders nice-scroll">

          <!-- Esta seccion de la pantalla esta destinado
          para ir mostrando las notificaciones historicas
          y en tiempo real de la cuenta como tal
          cuando abrio operaciones cuando 
          y que esta sucediendo  
          Este caso se aplica para el investor -->
          <CardNotifications />
        </b-col>
      </b-row>
      <b-row class="px-3 py-0 sidebar__user_closed_orders nice-scroll">
        <b-col cols="12 p-0" lg="12">
          <OrdersTable ref="OrdersTable"/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
	import CardSubInvestorInfo from '@/components/pages/subinvestor/CardInfo';	
	import ChartPerfomance from '@/components/pages/investors/ChartPerfomance';
	import OrdersTable from '@/components/commons/OrdersTable';
	import BalancesDetailInvestors from '@/components/pages/investors/BalancesDetailInvestors';
	import CardOpenedOrdersContainer from '@/components/pages/investors/CardOpenedOrdersContainer';
	import CardNotifications from '@/components/pages/subinvestor/CardNotificationSubInvestor';
	
	export default {
		name: 'SubInvestor',
		components: {
			CardSubInvestorInfo,
			ChartPerfomance,
			OrdersTable,
			BalancesDetailInvestors,
			CardOpenedOrdersContainer,
			CardNotifications,
		},
		created() {
			this.$store.commit('set_subinvestor_loading', true);
			this.getMaintenanceMode()
		},
		mounted() {
			this.setTitle();
		},
		data() {
			return {
				data: '',
				loading: false,
				token: null,
				maintenanceMode: null,
			};
		},
		methods:{
			getMaintenanceMode() {
	      this.$http.server1('bwfs/admin/maintenance/mode', {}).then((res) => {
	        if (res.error) {
	          return;
	        }
	        this.maintenanceMode = res.data
	      }).catch(() => {

	      });
	    },
			getInfo() {
				let payload = {
					vm: this,
					data: {
						subinvestor_token: this.token,
					},
				};
				this.$store.commit("get_info_subinvestor", payload);
			},
			refresh_order() {
      	this.$refs.OrdersTable.getOrders();
    	},
    	setTitle() {
    		if (!this.$store.state.SubInvestorInfo.info) {
    			setTimeout(this.setTitle, 250);
    			return;
    		}
    		let name = this.$store.state.SubInvestorInfo.info.name;
    		document.title = `BWFS - ${name}`;
    	},
		},
		watch: {
			maintenanceMode(value) {
	      if (value) {
	        window.location.href = "/#/maintenance-mode";
	        window.location.reload();
	        return;
	      }
	      if (this.$route.params.hasOwnProperty('token')) {
		      this.token = this.$route.params.token;
		      if (Number(this.token) == 1) {
		        window.location.href = "/#/admin/backtestings";
		        window.location.reload();
		        return;
		      }
		      this.getInfo();
		    } else {
		      window.location.href = "/#/admin/backtestings";
		      window.location.reload();
		    }
	    }
		},
		beforeDestroy() {
    	document.title = `BWFS`;
    },
	};
</script>