<template>
	<apexchart type="bar" height="150" :options="chartOptions" :series="series"></apexchart>
</template>
<script>
	export default {
		name: 'DrawdownHistory',
		props: {
			historyData: {
				required: true,
				type: Array,
			},
		},
		data() {
      let vm = this;
			return {
				series: [{
          name: 'Drawdown History',
          data: []
        }],
        chartOptions: {
          chart: {
           	type: 'bar',
            height: 350,
            toolbar: {
              show: false
            },
            fill: {
          },
          },
          plotOptions: {
            bar: {
              colors: {
                ranges: [{
                  from: -999999999999,
                  to: 0,
                  color: '#50e7ffe6'
                }, {
                  from: 1,
                  to: 9999999999999,
                  color: '#50e7ffe6'
                }]
              },
              columnWidth: '80%',
            }
          },
          dataLabels: {
            enabled: false,
          },
          yaxis: {
            title: {
              text: '',
            },
            labels: {
              show: false,
              formatter: function (y) {
                return y.toFixed(2);
              },
              style: {
                colors: '#ffffff',
              },
            },
          },
          xaxis: {
            type: 'datetime',
            lines:{
              show: false,
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            categories: [],
            labels: {
              rotate: -90,
              show: false,
              style: {
                colors: '#ffffff',
              }
            }
          },
          tooltip: {
          	custom: function({series, seriesIndex, dataPointIndex, w}) {
              let drawdown = vm.historyData[dataPointIndex].maximun;
              let date = vm.historyData[dataPointIndex].date;
            	return `
                <div style="background: #232429;" class="p-2">
                  <span class="d-block small">
                    Drawdown: <span class="text-muted">${vm.$helpers.numberFormat(drawdown)}</span>
                  </span>
                  <span class="d-block small">
                    Date: <span class="text-muted">${date}</span>
                  </span>
                </div>
              `;
            }
          },
          grid: {
            show: true,
            borderColor: '#90A4AE',
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
              lines: {
                show: false,
              }
            },   
            yaxis: {
              lines: {
                show: false,
              }
            },  
            row: {
              colors: undefined,
              opacity: 0.5
            },  
            column: {
              colors: undefined,
              opacity: 0.5
            },  
            padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            },  
          }
        },
			};
		},
		created() {
			this.handleData();
		},
		methods: {
			handleData() {
				if (this.historyData.length == 0) {
					return;
				}
				let data = this.$props.historyData.map(item => item.maximun);
				let series = this.$props.historyData.map(item => item.date);
				this.series[0].data = data;
				this.chartOptions.xaxis.categories = series;
			},
		},
	};
</script>