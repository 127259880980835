import UpdateNotificationConfigService from "../../../services/update_notification_configuration.service";
export default ({ commit, state }, { callback, error, token, new_content }) => {
  commit("set", {
    _state: "update_notification_configuration",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });

  const body = {
    channel_identifier: new_content,
    token,
  };
  //mandar index o token ?

  UpdateNotificationConfigService({
    body,
    callback: ({ response }) => {
      commit("set", {
        _state: "update_notification_configuration",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "update_notification_configuration",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
