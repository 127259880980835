import random_number from "../../../helpers/generators/random_number";
import random_string from "../../../helpers/generators/random_string";
import random_date from "../../../helpers/generators/random_date";

export default () => {
  const names = [
    "Neo LMDD",
    "LMDD",
    "BWFS",
    "Palm jumeirah",
    "Karama",
    "Marasi drive",
  ];
  const priorities = ["low", "medium", "high"];
  const status = [true, false];

  const note = "loremmmmmmmmmmmmmmmmmmmmmmmmmmmmm";
  const is_on = status[random_number(0, status.length - 1)];
  const tags = ["mc", "algorithms", "microsrives"];
  const last_check = random_date("MM/DD/YYYY h:mm:ss");
  const name = names[random_number(0, names.length - 1)];
  const system_name = names[random_number(0, names.length - 1)];
  const timeframe = "3";
  const updated_at = "2023-11-02 10:38:52.966000";
  const token = random_string(30);
  const priority = priorities[random_number(0, priorities.length - 1)];

  return {
    is_on,
    last_check,
    name,
    note,
    priority,
    system_name,
    timeframe,
    tags,
    token,
    updated_at,
  };
};
