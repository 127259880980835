<template>
  <div class="header-bar-container">
    <div class="header-bar max-width">
      <div>
        <span class="breadcrumb-current"> Systems heartbeat</span>
      </div>
      <div class="filter-bars">
        <button @click="openAlgorithmHealthModal">
          <span><img :src="icon_plus" alt="" /></span>New item
        </button>
      </div>
    </div>
    <SystemsHeartbeatInstance
      v-if="showSystemsHeartbeatModal"
      :isEdit="false"
      @closed="showSystemsHeartbeatModal = false"
    />
  </div>
</template>

<script>
import SystemsHeartbeatInstance from "./modals/SystemsHeartbeatInstance.vue";
import IconCaret from "../../../../public/common/select-arrow-down.svg";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2";
import PlusIcon from "../../../../public/common/plus-white-icon.svg";

export default {
  name: "HeaderTopBar",
  data() {
    return {
      icon_caret: IconCaret,
      icon_plus: PlusIcon,
      isLogged: false,
      showSystemsHeartbeatModal: false,
    };
  },
  components: {
    SystemsHeartbeatInstance,
    Multiselect,
  },
  created() {
    const session = localStorage.getItem("userData");
    if (session && session !== "") {
      this.isLogged = true;
    }
  },
  methods: {
    openAlgorithmHealthModal() {
      this.showSystemsHeartbeatModal = true;
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/pages/systems_heartbeat/header_top_bar.scss";
</style>
