import Crypto from '@/helpers/crypto.js';

export default (to, from, next) => {
  const session = localStorage.getItem('userData');
  if (session && session !== '') {
  	const user = JSON.parse(Crypto.decrypt(session));
  	if (user.role_id === 1) {
     	next('/admin/backtestings');
    } if (user.role_id === 2) {
      next('/supervisors');
    } else {
     	next('/investor/1');
    }
  } else {
  	next('/login');
  }
};
