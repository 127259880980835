<template>
  <div>
    <b-button variant="dark" @click="showModal = true">
      New Supervisor
    </b-button>
    <b-modal
      id="modal-show-backtest"
      hide-footer
      hide-header
      size="md"
      v-model="showModal"
      @hide="CloseModal()"
    >
      <div class="modal-create-backtest" id="modal-create-backtest">
        <h3 class="modal-title">
          {{
            $store.state.edit_supervisor
              ? `Edit Supervisor`
              : "Create Supervisor"
          }}
        </h3>
        <div class="row">
          <div class="col" v-if="!$store.state.edit_supervisor">
            <b-form-group>
              <label class="label-supervisor"> Email </label>
              <div class="">
                <input
                  id="supervisor-email-input"
                  :class="['form-control']"
                  type="email"
                  placeholder="Email"
                  autocomplete="off"
                  @input="$v.supervisorForm.email.$touch()"
                  v-model="supervisorForm.email"
                />
                <div v-if="$v.supervisorForm.email.$dirty">
                  <b-form-invalid-feedback
                    :state="$v.supervisorForm.email.required"
                  >
                    Required Field
                  </b-form-invalid-feedback>

                  <b-form-invalid-feedback
                    :state="$v.supervisorForm.email.email"
                  >
                    Introduce a valid email address
                  </b-form-invalid-feedback>
                </div>
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div v-if="!loading_select_investors" class="col-md-12">
            <div class="form-group">
              <label> Clients </label>
              <div class="">
                <b-form-select
                  id="select-client"
                  v-model="supervisorForm.linked_users"
                  :options="clientsOptions"
                  multiple
                  :select-size="4"
                  @input="$v.supervisorForm.linked_users.$touch()"
                />

                <div v-if="$v.supervisorForm.linked_users.$dirty">
                  <b-form-invalid-feedback
                    :state="$v.supervisorForm.linked_users.required"
                  >
                    Required Field
                  </b-form-invalid-feedback>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-md-12">
            <b-skeleton animation="wave" width="70%"></b-skeleton>
            <b-skeleton animation="wave" width="70%"></b-skeleton>
            <b-skeleton animation="wave" width="70%"></b-skeleton>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="label-supervisor"> Status </label>
              <div class="">
                <b-form-select
                  v-model="supervisorForm.status"
                  :options="statusOptions"
                  @input="$v.supervisorForm.status.$touch()"
                />
                <div v-if="$v.supervisorForm.$dirty">
                  <b-form-invalid-feedback
                    :state="$v.supervisorForm.status.required"
                  >
                    Required Field
                  </b-form-invalid-feedback>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="!$store.state.edit_supervisor">
            <div class="input-group mb-3">
              <label class="label-supervisor"> Password </label>
              <div
                class="d-flex flex-row"
                style="position: relative; width: 100%"
              >
                <input
                  id="supervisor-password-input"
                  :class="['form-control']"
                  autocomplete="Chrome, stop autofilling. You don't know better than me. Why have you made this hard?"
                  :type="showPassword ? 'text' : 'password'"
                  @input="$v.supervisorForm.password.$touch()"
                  v-model="supervisorForm.password"
                />
                <span
                  @click="showPassword = !showPassword"
                  style="
                    position: absolute;
                    z-index: 100;
                    right: 10px;
                    font-size: 22px;
                    top: 1px;
                  "
                >
                  <b-icon icon="eye" />
                </span>
              </div>
              <div class="d-flex flex-row-reverse">
                <b-link @click="generatePassword" class="small text-muted"
                  >Generate password</b-link
                >
              </div>
            </div>
            <div v-if="$v.supervisorForm.password.$dirty">
              <b-form-invalid-feedback
                :state="$v.supervisorForm.password.required"
              >
                Required Field
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                :state="$v.supervisorForm.password.minLength"
              >
                Min. 8 Characters
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div class="row">
          <div id="save-button" class="col d-flex justify-content-end">
            <b-button
              :class="
                $store.state.supervisor_is_loaded
                  ? 'btn btn-success ml-2 btn-loading'
                  : 'btn btn-success ml-2'
              "
              @click="SaveSupervisor"
            >
              {{ $store.state.edit_supervisor ? "Edit" : "Save" }}
              <div
                class="loading loading-space"
                v-if="$store.state.supervisor_is_loaded"
              >
                <b-spinner variant="light"></b-spinner>
              </div>
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Input from "../../commons/Input.vue";
import Helpers from "../../../helpers/commons.js";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  name: "ModalNewSupervisor",
  components: {
    Input,
  },
  validations: {
    supervisorForm: {
      email: { required, email },
      status: { required },
      linked_users: { required },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  data() {
    return {
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
        <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
        </svg> Error`,
      supervisorForm: {
        email: null,
        linked_users: [],
        status: null,
        password: "",
        token: "",
      },
      showModal: false,
      showPassword: false,
      statusOptions: [
        { text: "Active", value: 0 },
        { text: "Inactive", value: 1 },
      ],
      investors: [],
      loading_select_investors: false,
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
          <g clip-path="url(#clip0_1634_940)">
          <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
          </g>
          <defs>
          <clipPath id="clip0_1634_940">
          <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
          </clipPath>
          </defs>
          </svg> Success`,
    };
  },
  computed: {
    clientsOptions() {
      if (this.$store.state.supervisor_investors) {
        return this.$store.state.supervisor_investors
          .filter((d) => d.info.investor.token_user !== "")
          .map((investor) => {
            return {
              text: investor.info.investor.name,
              value: investor.info.investor.token_user,
            };
          });
      }
      return [];
    },
  },
  methods: {
    CloseModal() {
      this.$store.commit("set_supervisor_is_loaded", false);
      this.supervisorForm.email = null;
      this.supervisorForm.status = null;
      this.supervisorForm.password = "";
      this.supervisorForm.linked_users = [];
      this.$v.$reset();
      this.$v.supervisorForm.password.$reset();
      this.$v.supervisorForm.linked_users.$reset();
      this.showModal = false;
      this.$store.commit("set_supervisor_data", {});
      this.$store.commit("set_edit_supervisor", false);
      this.supervisorForm.token = "";
    },
    async EditSupervisor() {
      if (
        this.$v.supervisorForm.email.$invalid ||
        this.$v.supervisorForm.status.$invalid ||
        this.supervisorForm.linked_users.length == 0
      ) {
        this.$toastr.error(
          "Fulfill the field filling requirements",
          `${this.error}`
        );
        this.$v.$touch();
      } else {
        const data = {
          token: this.supervisorForm.token,
          linked_users: JSON.stringify(this.supervisorForm.linked_users),
          status: this.supervisorForm.status,
        };
        const response = this.$http.server1(
          "bwfs/admin/supervisors/edit",
          data
        );
        if (response.error) {
          this.$toastr.error(`${response.message}`, `${this.error}`);
          this.$v.$touch();
          return;
        }
        this.$toastr.success(
          "The supervisor was successfully edit",
          `${this.success}`
        );

        this.$v.$reset();
        this.showModal = false;
        await this.$store.dispatch("bwfs_get_supervisor", {
          vm: this,
          data: {
            page: 1,
            search: this.$store.state.search_supervisor,
            status: this.$store.state.search_supervisor_status,
          },
        });
        this.$store.commit("set_supervisor_is_created", true);
      }
    },
    async SaveSupervisor() {
      if (this.$store.state.edit_supervisor) {
        this.EditSupervisor();
        return;
      }
      if (
        this.$v.supervisorForm.email.$invalid ||
        this.$v.supervisorForm.password.$invalid ||
        this.$v.supervisorForm.linked_users.$invalid ||
        this.$v.supervisorForm.status.$invalid
      ) {
        this.$toastr.error(
          "Fulfill the field filling requirements",
          `${this.error}`
        );
        this.$v.$touch();
      } else {
        const data = {
          email: this.supervisorForm.email,
          role_id: 2,
          linked_users: JSON.stringify(this.supervisorForm.linked_users),
          status: this.supervisorForm.status,
          password: this.supervisorForm.password,
        };
        await this.$store.dispatch("bwfs_add_supervisor", {
          vm: this,
          data,
        });
        if (this.$store.state.supervisor_response_error) {
          this.$v.$touch();
          return;
        }
        this.$v.$reset();
        this.showModal = false;
        await this.$store.dispatch("bwfs_get_supervisor", {
          vm: this,
          data: {
            page: 1,
            search: this.$store.state.search_supervisor,
            status: this.$store.state.search_supervisor_status,
          },
        });
      }
    },
    generatePassword() {
      this.supervisorForm.password = Helpers.MD5(
        Helpers.generateRandomString(10)
      );
    },
    async fetchingInvestors() {
      this.investors = [];
      this.loading_select_investors = true;
      console.log(this.supervisorForm.token);
      const response = await this.$http.server1(
        "bwfs/admin/supervisors/investors",
        { token_supervisor: this.supervisorForm.token }
      );
      this.supervisorForm.linked_users = response.data;
      this.loading_select_investors = false;
    },
  },
  watch: {
    "$store.state.supervisor_response": function () {
      if (!this.$store.state.supervisor_response_error) {
        this.$store.commit("set_supervisor_response_error", null);
        this.$store.dispatch("bwfs_get_supervisor", {
          vm: this,
          data: {
            page: 1,
            search: null,
          },
        });
      }
    },
    "$store.state.edit_supervisor"(value) {
      if (value && this.$store.state.supervisor_data) {
        console.log(this.$store.state.supervisor_data);
        this.supervisorForm.token = this.$store.state.supervisor_data.Token;
        this.supervisorForm.email = this.$store.state.supervisor_data.Email;
        this.supervisorForm.status = this.$store.state.supervisor_data.status;
        this.showModal = true;
        this.fetchingInvestors();
        // this.$store.commit('set_supervisor_data', {});
        // this.$store.commit('set_edit_supervisor', false);
      }
    },
    showModal() {
      if (!this.showModal) {
        this.CloseModal();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-group-text {
  background-color: #0e1019 !important;
  // border: 1px solid #495057!important;
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.loading-space {
  position: absolute;
  right: 12px;
  bottom: 11px;
  zoom: 0.6;
}
::v-deep .btn-group-vertical {
  background-color: #1d1e25 !important;
  color: #f5f5f5;
  border: 0;
  font-size: 14px;
}
#select-client {
  height: 150px !important;
}
::v-deep .custom-select {
  background-color: #1d1e25 !important;
  color: #f5f5f5 !important;
  border: 0 !important;
  font-size: 16px;
}
@media (min-width: 768px) and (max-width: 1200px) {
  ::v-deep .custom-select {
    font-size: 0.85rem;
  }
}
</style>

<style lang="scss">
@media (min-width: 600px) {
}
</style>
