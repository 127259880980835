<template>
  <div id="TimeFrameMenu" class="d-flex flex-row">
    <div class="d-flex flex-row mt-2">
      <span id="timeframe-title" class="mr-2 mt-1">Timeframes</span>
        <b-button :variant="$store.state.realtime_chart? 'dark' : 'light'" class="mr-2 mr-2" @click="$store.commit('set_realtime_chart', true)"> realtime</b-button>
        <b-button 
          :key="t"
          v-for="t in timeframes" 
          :variant="$store.state.timeframe === t && !$store.state.realtime_chart?'dark' :'light'" 
          class="mr-2 mr-2" 
          @click="selectButton(t)"
        >  
          <span>{{t}}</span>
        </b-button>
    </div>
  </div>
</template>

<script>
// #4f505a
export default {
  name: 'TimeframesMenu',
  data () {
    return {
      timeframes: ['1m','2m','3m','5m','15m','30m','1h','2h','4h', '6h', '8h', '12h','1d'],
    };
  },
  methods: {
    selectButton(value) {
      this.$store.commit('set_timeframe', value);
      this.$store.commit('set_timeframe_clicked', true);
      if (this.$store.state.realtime_chart) this.$store.commit('set_realtime_chart',false)
    }
  },
}
</script>
<style lang="scss" scoped>
  #timeframe-title {
    font-weight: 700;
  }
  #TimeFrameMenu {
    height: 40px;
    position: absolute;
    color: black;
    top: 18px;
    left: 28%;
    width: 70%;
    z-index: 1;
  }
  @media (min-width: 1300px) and (max-width: 1400px) {
    #TimeFrameMenu {
    height: 40px;
    position: absolute;
    color: black;
    top: 18px;
    left: 20%;
    width: 70%;
    z-index: 1;
  }
  }
</style>