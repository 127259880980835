<template>
  <div :class="`panel page__${$route.name.toLowerCase()}`">
    <b-container fluid>
      <b-row class="p-0">
        <BacktestSlide ref="BacktestSlide" />
        <b-col cols="12" lg="10" class="p-0 admin_content nice-scroll">
          <div class="content p-2">
            <div class="row pl-3 pr-3">
              <div class="col-md-12 p-1 text-right">
                <b-button variant="dark" @click="showModalCreateBacktest">
                  New backtest
                </b-button>
                <b-modal
                  id="modal-create-backtest"
                  hide-footer
                  hide-header
                  size="xl"
                  ok-only
                >
                  <div class="modal-create-backtest">
                    <h3 class="modal-title d-flex justify-content-between">
                      <span>Create new backtest </span>
                      <span></span>
                    </h3>
                    <div class="row">
                      <div class="col-md-12">
                        <label class="text-muted"> basic configuration </label>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3 mb-2 px-2">
                        <div class="content p-2 item-active-config">
                          <label>Symbol</label>
                          <div class="row px-2" style="margin-top: 0px">
                            <div class="col-md-12 px-2">
                              <select
                                class="form-control nice-scroll"
                                v-model="backtest.symbol"
                              >
                                <option
                                  v-for="(symbol, i) in $store.state.symbols"
                                  :key="i"
                                  :value="symbol"
                                >
                                  {{ symbol.toUpperCase() }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 mb-2 px-2">
                        <div class="content p-2 item-active-config">
                          <label>Client confirmation</label>
                          <input
                            type="text"
                            pattern="[0-9]"
                            min="0"
                            max="999999999"
                            class="form-control"
                            v-model="backtest.client_confirmation"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <label class="title_"> Timeframe configuration </label>
                      </div>
                    </div>

                    <div class="row px-2">
                      <div class="col-md-2 mb-2 px-2">
                        <div class="content p-2 item-active-config">
                          <label>Short</label>
                          <div class="row px-2" style="margin-top: 0px">
                            <div class="col-md-12 px-2">
                              <input
                                type="number"
                                pattern="[0-9]"
                                ref="short"
                                min="0"
                                max="999999999"
                                class="form-control"
                                v-model="backtest.timeframes.short"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2 mb-2 px-2">
                        <div class="content p-2 item-active-config">
                          <label>Medium</label>
                          <div class="row px-2" style="margin-top: 0px">
                            <div class="col-md-12 px-2">
                              <input
                                type="number"
                                pattern="[0-9]"
                                ref="medium"
                                min="0"
                                max="999999999"
                                class="form-control"
                                v-model="backtest.timeframes.medium"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2 mb-2 px-2">
                        <div class="content p-2 item-active-config">
                          <label>Long</label>
                          <div class="row px-2" style="margin-top: 0px">
                            <div class="col-md-12 px-2">
                              <input
                                type="number"
                                pattern="[0-9]"
                                ref="long"
                                min="0"
                                max="999999999"
                                class="form-control"
                                value="1440"
                                v-model="backtest.timeframes.long"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2 mb-2 px-2">
                        <div class="content p-2 item-active-config">
                          <label>Mega Long</label>
                          <div class="row px-2" style="margin-top: 0px">
                            <div class="col-md-12 px-2">
                              <input
                                type="number"
                                pattern="[0-9]"
                                ref="mega_long"
                                min="0"
                                max="999999999"
                                class="form-control"
                                value="1440"
                                v-model="backtest.timeframes.mega_long"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-12">
                        <label class="text-muted"> notes </label>
                        <textarea
                          class="form-control"
                          v-model="backtest.note"
                        ></textarea>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-12">
                        <label class="text-muted"> Date range </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <BaseDateRangePicker
                          v-model="dateRange"
                          format="YYYY-MM-DD HH:mm:ss"
                          :showLabel="false"
                          label="Date Range"
                          :minDate="minDate"
                          :maxDate="maxDate"
                          :dateDefault="dateRange"
                          :timepicker="true"
                        />
                      </div>
                    </div>
                    <div
                      class="row mt-2"
                      v-if="backtest.configurations.length > 0"
                    >
                      <div class="col-md-12">
                        <label class="text-muted"> Conditions </label>
                      </div>
                      <div class="col-md-10 mb-2">
                        <input
                          type="text"
                          class="form-control d-block mb-2"
                          v-model="filterValue"
                          placeholder="Filter condition..."
                          id="filterValueCondtions"
                        />
                      </div>
                    </div>
                    <div
                      v-for="(i, key) in backtest.configurations"
                      class="row mb-2"
                      v-if="backtest.configurations.length > 0"
                    >
                      <div
                        class="col-md-10 mb-2"
                        :style="{ borderLeft: '2px solid ' + colorID[key] }"
                      >
                        <ViewJson
                          :jsonData="i"
                          :filter="filterValueConditions"
                        />
                      </div>
                      <div class="col-md-2">
                        <div class="d-block edit-conditions">
                          <span
                            v-b-tooltip.hover
                            title="View and modify"
                            @click="handleModifyCondition({ ...i }, key)"
                          >
                            <b-icon-gear></b-icon-gear>
                          </span>
                          <span
                            v-b-tooltip.hover
                            title="Duplicate"
                            @click="handleDuplicateCondition(i, key)"
                          >
                            <b-icon-front></b-icon-front>
                          </span>
                          <span
                            v-b-tooltip.hover
                            title="Delete"
                            @click="deleteCondition(key)"
                          >
                            <b-icon-trash></b-icon-trash>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="row mt-2"
                      v-if="backtest.configurations.length > 0"
                    >
                      <div class="col-md-12">
                        <label class="text-muted"> Resumen </label>
                      </div>
                    </div>
                    <div
                      class="row mb-2"
                      v-if="backtest.configurations.length > 0"
                    >
                      <div class="col-md-10">
                        <input
                          type="text"
                          class="form-control d-block mb-2"
                          v-model="filterBacktestResumen"
                          placeholder="Filter resumen..."
                          id="filterResumenBacktests44"
                        />
                      </div>
                      <div class="col-md-10">
                        <ViewJson
                          :jsonData="backtest"
                          :filter="filterBacktest"
                        />
                      </div>
                    </div>
                    <div class="row" v-if="backtest.configurations.length > 0">
                      <div class="col-md-12">
                        <b-alert class="mb-2" show variant="success">
                          You carry {{ backtest.configurations.length }}/{{
                            maximunCondition
                          }}
                          conditions allowed from
                          {{ dateStart === "" ? "0000-00-00" : dateStart }} to
                          {{ dateEnd === "" ? "0000-00-00" : dateEnd }}.
                        </b-alert>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-md-12">
                        <label class="text-muted"> Instance </label>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3 mb-2 px-2">
                        <div class="content p-2 item-active-config">
                          <label>Type Instance</label>
                          <div class="row px-2" style="margin-top: 0px">
                            <div class="col-md-12 px-2">
                              <select
                                class="form-control"
                                v-model="backtest.type_instance"
                              >
                                <option value="automatic">Automatic</option>
                                <option value="number">Select Number</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-3 mb-2 px-2"
                        v-if="backtest.type_instance === 'number'"
                      >
                        <div class="content p-2 item-active-config">
                          <label>Number instance</label>
                          <div class="row px-2" style="margin-top: 0px">
                            <div class="col-md-12 px-2">
                              <select
                                class="form-control"
                                v-model.number="backtest.number_instance"
                              >
                                <option
                                  v-for="n in $store.state.number_instances"
                                  :value="n + 1"
                                  :key="n"
                                >
                                  {{ n + 1 }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-6">
                            <b-button
                              class="mr-2"
                              variant="dark"
                              v-b-modal.modal-add-condition
                              v-show="!isLoading"
                              @click.prevent="showModalAddCondition"
                              >Add new condition</b-button
                            >
                            <b-button
                              class="mr-2"
                              variant="success"
                              @click="saveBacktest"
                              v-show="!isLoading"
                              >Save and run Backtest</b-button
                            >
                            <div v-show="isLoading">
                              <b-button
                                class="mr-2 form-group pr-5"
                                variant="success"
                                :disabled="true"
                              >
                                Save and run Backtest
                                <div class="loading">
                                  <b-spinner variant="light"></b-spinner>
                                </div>
                              </b-button>
                            </div>
                          </div>
                          <div class="col-md-6 d-flex">
                            <b-button
                              class="mr-2 ml-auto"
                              variant="dark"
                              @click="pasteBacktest"
                              v-show="showBtnPasteBacktest()"
                              >Paste Backtest</b-button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-modal>
              </div>
            </div>
            <div class="row pl-3 pr-3">
              <div class="col-md-12 p-1">
                <div v-if="token">
                  <Keypad
                    v-if="
                      !$store.state.backtest_loading &&
                      $store.state.backtest_detail.token
                    "
                    @handleDeleteBacktest="handleDeleteBacktest"
                  />
                  <div
                    class="mb-4"
                    v-if="
                      ($store.state.backtest_detail.note &&
                        !$store.state.backtest_loading) ||
                      ($store.state.backtest_detail.token &&
                        $store.state.backtest_loading)
                    "
                  >
                    <label class="text-muted d-block mb-2"
                      >Backtest description</label
                    >
                    <div class="card-bwfs p-2">
                      <span>{{ $store.state.backtest_detail.note }}</span>
                    </div>
                  </div>

                  <div
                    class="mb-4"
                    v-if="
                      $store.state.backtest_loading &&
                      !$store.state.backtest_detail.token
                    "
                  >
                    <label class="text-muted d-block mb-2"
                      >Backtest description</label
                    >
                    <div class="card-bwfs p-2">
                      <b-skeleton
                        v-if="$store.state.backtest_loading"
                        animation="wave"
                        width="68%"
                      ></b-skeleton>
                    </div>
                  </div>
                  <CandlestickV2 v-if="$store.state.condition_token_selected" />
                  <Resumen v-if="$store.state.condition_token_selected" />
                  <Conditions
                    :backtest_token="token"
                    @handleShowCondition="handleShowCondition"
                    :success_rate="Number(success_rate)"
                    :performance_month_by_month="
                      Number(performance_month_by_month)
                    "
                  />
                  <div v-if="$store.state.condition_token_selected">
                    <label class="text-muted d-block mb-2 mt-4">Orders</label>
                    <div class="page__investor">
                      <div class="container-fluid">
                        <div
                          class="row p-0 sidebar__user_closed_orders"
                          style="height: auto"
                        >
                          <div class="col-ld-12 col-12 p-0">
                            <OrdersTable
                              :backtest_token="token"
                              :condition_token="
                                $store.state.condition_token_selected
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <b-alert class="mb-2" show variant="dark" v-else>
                  Please select a backtest to view your information
                </b-alert>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-modal
        ref="modal-add-condition"
        id="modal-add-condition"
        hide-footer
        hide-header
        size="xl"
        ok-only
      >
        <div class="modal-create-conditions">
          <h3 class="modal-title d-flex justify-content-between">
            <span> Condition generator </span>
            <div v-if="isCompound && !show">
              <a
                href="#"
                style="font-size: 13px; color: #fff"
                class="pr-2"
                @click.prevent="activeTabCondition('buy')"
              >
                <span
                  class="btn btn-dark small"
                  :style="{
                    background: activeBuy ? '#11131b' : '#232429',
                    fontWeight: '400',
                  }"
                >
                  Condition #1
                </span>
              </a>
              <a
                href="#"
                style="font-size: 13px; color: #fff"
                @click.prevent="activeTabCondition('sell')"
              >
                <span
                  class="btn btn-dark small"
                  :style="{
                    background: activeSell ? '#11131b' : '#232429',
                    fontWeight: '400',
                  }"
                >
                  Condition #2
                </span>
              </a>
            </div>
          </h3>
          <ConditionGenerator
            ref="conditionGeneratorBuy"
            @addCondition="handleAddCondition"
            @handleAddModify="handleAddModify"
            @handleCompound="handleCompoundCondition"
            @handlePriority="handlePriority"
            @handleAddCompound="handleAddCompoundCondition"
            @handleSetType="handletSeType"
            @handleBaseConfiguration="handleBaseConfiguration"
            @setConditionPasteSell="setConditionPasteSell"
            v-show="activeBuy"
            :isCompound="isCompound"
            :backtest="backtest"
          />
          <ConditionGenerator
            ref="conditionGeneratorSell"
            @addCondition="handleAddCondition"
            @handleAddModify="handleAddModify"
            @handleCompound="handleCompoundCondition"
            @handleAddCompound="handleAddCompoundCondition"
            :tabSell="true"
            :isCompound="isCompound"
            :valueCompound="is_compound"
            :priority="priority"
            v-show="activeSell"
            :backtest="backtest"
            :type="currentTypeCondition === 'buy' ? 'sell' : 'buy'"
          />
        </div>
      </b-modal>
    </b-container>
    <ModalShowBacktesting
      v-if="$store.state.backtest_detail.token"
      @handleShowCondition="handleShowCondition"
    />

    <b-modal
      ref="modal-show-valid-success"
      id="modal-show-valid-success"
      hide-footer
      hide-header
      size="lg"
      ok-only
    >
      <div class="modal-create-conditions">
        <h3 class="modal-title">Valid success backtesting</h3>
        <div class="row">
          <div class="col-md-6 mb-2 px-2">
            <div class="content p-2 item-active-config">
              <label>Success Rate(%)</label>
              <div class="row px-2" style="margin-top: 0px">
                <div class="col-md-12 px-2">
                  <input
                    type="number"
                    pattern="[0-9]"
                    ref="short"
                    min="0"
                    max="100"
                    class="form-control"
                    v-model="success_rate"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-2 px-2">
            <div class="content p-2 item-active-config">
              <label>Performance month by month(%)</label>
              <div class="row px-2" style="margin-top: 0px">
                <div class="col-md-12 px-2">
                  <input
                    type="number"
                    pattern="[0-9]"
                    ref="short"
                    min="0"
                    max="100"
                    class="form-control"
                    v-model="performance_month_by_month"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import ConditionGenerator from "@/components/commons/ConditionGenerator";
import BacktestSlide from "@/components/pages/backtesting/Slide";
import Resumen from "@/components/pages/backtesting/Resumen";
import Keypad from "@/components/pages/backtesting/Keypad";
import Conditions from "@/components/pages/backtesting/Conditions";
import Orders from "@/components/pages/backtesting/Orders";
import OrdersTable from "@/components/commons/OrdersTable";
import Textarea from "@/components/commons/Textarea";
import CandlestickV2 from "@/components/commons/CandlestickChartsV2";
import BaseDateRangePicker from "@/components/commons/BaseDateRangePicker";
import ModalShowBacktesting from "@/components/pages/backtesting/ModalShowBacktest";
import ViewJson from "@/components/commons/ViewJson";

export default {
  name: "Backtestings",
  components: {
    ConditionGenerator,
    BacktestSlide,
    Textarea,
    Keypad,
    Resumen,
    Conditions,
    OrdersTable,
    BaseDateRangePicker,
    ModalShowBacktesting,
    CandlestickV2,
    ViewJson,
  },
  mounted() {
    this.addEventSeach();
    this.addEventSeachResumen();
    this.$helpers.setWindowName();
    this.$refs.BacktestSlide.getBacktest();
  },
  created() {
    this.maxDate = new Date();
    this.minDate = new Date("04 Sep 2019");
    this.$helpers.setWindowName();
    if (this.$route.params.hasOwnProperty("token")) {
      this.token = this.$route.params.token;
      this.$store.commit("bwfs_token_backtest_detail", this.token);
    }

    if (this.$store.state.symbols.length === 0) {
      this.$store.commit("bwfs_get_symbols", this);
    }
    if (this.$store.state.number_instances.length === 0) {
      this.$store.commit("bwfs_get_number_instance", this);
    }
  },
  computed: {
    isMovil() {
      return window.innerWidth < 992;
    },
  },
  data() {
    return {
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
        <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
        </svg> Error`,
      filterValue: "",
      filterValueConditions: "",
      filterBacktestResumen: "",
      filterBacktest: "",
      user: {
        demo: false,
        future: true,
      },
      dateRange: [],
      backtest: {
        token: this.$helpers.getRandomInt(),
        note: "",
        date: [],
        symbol: "BTCUSDT",
        client_confirmation: 25,
        configurations: [],
        number_instance: 1,
        type_instance: "automatic",
        timeframes: {
          short: 5,
          medium: 60,
          long: 1440,
          mega_long: 2880,
        },
      },
      dateStart: "",
      dateEnd: "",
      maxDate: "",
      minDate: "",
      maximunCondition: 100,
      isLoading: false,
      indexConditionModify: 0,
      token: "",
      currentTypeCondition: "buy",
      isCompound: false,
      activeBuy: true,
      activeSell: false,
      isModify: false,
      colorID: {},
      is_compound: "",
      show: false,
      priority: "buy",
      success_rate: 80,
      performance_month_by_month: 0,
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
          <g clip-path="url(#clip0_1634_940)">
          <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
          </g>
          <defs>
          <clipPath id="clip0_1634_940">
          <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
          </clipPath>
          </defs>
          </svg> Success`,
    };
  },
  methods: {
    addEventSeachResumen() {
      const myInputResumen22 = document.getElementById(
        "filterResumenBacktests44"
      );
      if (!myInputResumen22) {
        setTimeout(() => {
          this.addEventSeachResumen();
        }, 500);
      } else {
        myInputResumen22.addEventListener(
          "keyup",
          this.$debounce(() => {
            this.filterBacktest = this.filterBacktestResumen;
          })
        );
        window.myInputResumen22 = myInputResumen22;
      }
    },
    addEventSeach() {
      const myInput = document.getElementById("filterValueCondtions");
      if (!myInput) {
        setTimeout(() => {
          this.addEventSeach();
        }, 500);
      } else {
        myInput.addEventListener(
          "keyup",
          this.$debounce(() => {
            this.filterValueConditions = this.filterValue;
          })
        );
      }
    },
    showModalCreateBacktest() {
      this.isCompound = false;
      this.activeSell = false;
      this.isModify = false;
      this.activeBuy = true;
      this.show = false;
      let startDate = new Date();
      let endDate = new Date();
      startDate.setDate(startDate.getDate() - 7);
      this.backtest = {
        token: this.$helpers.getRandomInt(),
        note: "",
        date: [startDate, endDate],
        symbol: "BTCUSDT",
        client_confirmation: 25,
        configurations: [],
        number_instance: 1,
        type_instance: "automatic",
        timeframes: {
          short: 5,
          medium: 60,
          long: 1440,
          mega_long: 2880,
        },
      };
      this.$bvModal.show("modal-create-backtest");
    },
    showBtnPasteBacktest() {
      return !this.isLoading && localStorage.getItem("copyBacktest");
    },
    handleAddCondition(condition) {
      if (this.backtest.configurations.length < this.maximunCondition) {
        let existOtherCondition = this.backtest.configurations.filter(
          (i) => i.base_configuration.is_compound != false
        );
        if (existOtherCondition.length > 0) {
          this.$toastr.error(
            "You only have to add compound or simple conditions.",
            `${this.error}`
          );
          return;
        }
        this.backtest.configurations.push(condition);
        this.$bvModal.hide("modal-add-condition");
      } else {
        this.$toastr.error(
          "Has exceeded the limit of allowed conditions.",
          `${this.error}`
        );
      }
    },
    handleAddCompoundCondition(condition, type) {
      let c = {};
      if (this.activeSell) {
        c = this.$refs.conditionGeneratorBuy.addCondition(true);
      } else {
        c = this.$refs.conditionGeneratorSell.addCondition(true);
      }
      if (!c) {
        return;
      }
      if (c.base_configuration.type == condition.base_configuration.type) {
        this.$toastr.error(
          "The compound conditions must be sell and buy.",
          `${this.error}`
        );
        return;
      }

      let existOtherCondition = this.backtest.configurations.filter(
        (i) => i.base_configuration.is_compound == false
      );
      if (existOtherCondition.length > 0) {
        this.$toastr.error(
          "You only have to add compound or simple conditions.",
          `${this.error}`
        );
        return;
      }

      if (!condition.token) {
        condition.token = this.$helpers.getRandomInt();
      }
      c.base_configuration.configuration_compound.token = condition.token;
      c.base_configuration.configuration_compound.method = parseInt(
        c.base_configuration.is_compound.replace("method", "")
      );

      if (!c.token) {
        c.token = this.$helpers.getRandomInt();
      }
      condition.base_configuration.configuration_compound.token = c.token;
      condition.base_configuration.configuration_compound.method = parseInt(
        condition.base_configuration.is_compound.replace("method", "")
      );
      this.$bvModal.hide("modal-add-condition");
      if (this.backtest.configurations.length < this.maximunCondition) {
        let index = this.backtest.configurations.length;
        const color = this.$helpers.getRandomColor();
        this.colorID[index] = color;
        this.colorID[index + 1] = color;
        if (this.activeSell) {
          this.backtest.configurations.push(c);
          this.backtest.configurations.push(condition);
        } else {
          this.backtest.configurations.push(condition);
          this.backtest.configurations.push(c);
        }
      } else {
        this.$toastr.error(
          "Has exceeded the limit of allowed conditions.",
          `${this.error}`
        );
      }
    },
    saveBacktest() {
      if (!this.validateBacktest()) {
        return;
      }
      const data = { ...this.backtest };
      if (Number(data.client_confirmation) < 0) {
        this.$toastr.error(
          "The client confirmation only allows positive values.",
          `${this.error}`
        );
        return;
      }
      this.isLoading = true;
      data["token"] = this.$helpers.getRandomInt();
      data["dates"] = JSON.stringify(data["date"]);
      data["timeframes"] = JSON.stringify(data["timeframes"]);

      for (let i = 0; i < data["configurations"].length; i++) {
        let c = data["configurations"][i];
        if (c.base_configuration.is_compound) {
          if (c.base_configuration.configuration_compound.method === 1) {
            delete c.base_configuration.configuration_compound.max_time_to_wait;
          } else if (c.base_configuration.configuration_compound.method === 3) {
            delete c.base_configuration.configuration_compound.max_time_to_wait;
            delete c.base_configuration.configuration_compound.priority;
          }
          data["configurations"][i] = c;
        }
      }

      if (data.configurations[0].base_configuration.configuration_compound) {
        for (let i = 0; i < data.configurations.length; i++) {
          if (i % 2 !== 0) {
            data.configurations[
              i
            ].base_configuration.configuration_compound.token =
              data.configurations[i - 1].token;
            data.configurations[
              i - 1
            ].base_configuration.configuration_compound.token =
              data.configurations[i].token;
            delete data.configurations[i].base_configuration
              .configuration_compound.child;
            delete data.configurations[i].base_configuration
              .configuration_compound.parent;
            delete data.configurations[i - 1].base_configuration
              .configuration_compound.child;
            delete data.configurations[i - 1].base_configuration
              .configuration_compound.parent;
          }
        }
      }

      data["configurations"] = JSON.stringify(data["configurations"]);
      let filters = {};
      if (localStorage.getItem("filters_backtest")) {
        filters = JSON.parse(localStorage.getItem("filters_backtest"));
      }
      if (!filters.endpoint) {
        filters.endpoint = "production";
      }
      this.$http
        .server2("bwfs/admin/backtest/create", data, {}, filters.endpoint)
        .then((res) => {
          if (res.error) {
            this.isLoading = false;
            this.$toastr.error(`${res.message}`, `${this.error}`);

            if (res.data && res.data.token) {
              this.$toastr.success(
                `Success created backtest.`,
                `${this.success}`
              );
              this.$bvModal.hide("modal-create-backtest");
            }
            return;
          }
          this.$toastr.success(`Success created backtest.`, `${this.success}`);
          this.$bvModal.hide("modal-create-backtest");
          this.$refs.BacktestSlide.getBacktest();
          this.isLoading = false;
          this.isCompound = false;
        })
        .catch((e) => {
          this.$toastr.error(`${e}`, `${this.error}`);
          this.isLoading = false;
          this.isCompound = false;
        });
    },
    validateBacktest() {
      if (this.backtest.date.length !== 2) {
        this.$toastr.error(
          "Please select the start date and end date of the backtest.",
          `${this.error}`
        );
        return false;
      }
      if (this.backtest.configurations.length == 0) {
        this.$toastr.error(
          "You must add at least one condition to the.",
          `${this.error}`
        );
        return false;
      }

      if (this.backtest.timeframes.short < 0) {
        this.$toastr.error(
          "The timeframe short must be positive.",
          `${this.error}`
        );
        this.$refs.short.focus();
        return false;
      }
      if (this.backtest.timeframes.medium < 0) {
        this.$toastr.error(
          "The timeframe medium must be positive.",
          `${this.error}`
        );
        this.$refs.medium.focus();
        return false;
      }
      if (this.backtest.timeframes.long < 0) {
        this.$toastr.error(
          "The timeframe long must be positive.",
          `${this.error}`
        );
        this.$refs.long.focus();
        return false;
      }
      if (this.backtest.timeframes.mega_long < 0) {
        this.$toastr.error(
          "The timeframe mega long must be positive.",
          `${this.error}`
        );
        this.$refs.mega_long.focus();
        return false;
      }
      return true;
    },
    deleteCondition(index) {
      if (this.backtest.configurations[index].base_configuration.is_compound) {
        this.backtest.configurations.splice(index, 1);
        delete this.colorID[index];
        if (index % 2 !== 0) {
          this.backtest.configurations.splice(index - 1, 1);
          delete this.colorID[index - 1];
        } else {
          this.backtest.configurations.splice(index, 1);
          delete this.colorID[index];
        }
      } else {
        this.backtest.configurations.splice(index, 1);
      }
    },
    handleModifyCondition(condition, index) {
      condition.base_configuration = {
        ...this.$helpers.observerClean(condition.base_configuration),
      };
      this.$bvModal.show("modal-add-condition");
      this.indexConditionModify = index;
      this.isModify = true;
      if (
        condition.base_configuration.is_compound &&
        condition.base_configuration.is_compound != "false"
      ) {
        if (index % 2 !== 0) {
          this.activeSell = true;
          this.activeBuy = false;
          setTimeout(() => {
            let cc = { ...this.backtest.configurations[index - 1] };
            cc.base_configuration = { ...cc.base_configuration };

            let typeBK = condition.base_configuration.type;
            condition.base_configuration = { ...cc.base_configuration };
            condition.base_configuration.type = typeBK;
            if (condition.is_compound == "method 3") {
              condition.base_configuration.configuration_compound.max_time_to_wait = 60;
              condition.base_configuration.configuration_compound.priority =
                "buy";
            }
            this.$refs.conditionGeneratorSell.modifyCondition(condition);
            if (cc.is_compound == "method 3") {
              cc.base_configuration.configuration_compound.max_time_to_wait = 60;
              cc.base_configuration.configuration_compound.priority = "buy";
            }
            this.$refs.conditionGeneratorBuy.modifyCondition(cc);
          }, 300);
        } else {
          this.activeSell = false;
          this.activeBuy = true;
          setTimeout(() => {
            if (condition.base_configuration.is_compound == "method 3") {
              condition.base_configuration.configuration_compound.max_time_to_wait = 60;
              condition.base_configuration.configuration_compound.priority =
                "buy";
            }
            this.$refs.conditionGeneratorBuy.modifyCondition(condition);
            let cc = { ...this.backtest.configurations[index + 1] };
            cc.base_configuration = { ...cc.base_configuration };
            if (cc.is_compound == "method 3") {
              cc.configuration_compound.max_time_to_wait = 60;
              cc.configuration_compound.priority = "buy";
            }
            this.$refs.conditionGeneratorSell.modifyCondition(cc);
          }, 300);
        }
      } else {
        setTimeout(() => {
          this.$refs.conditionGeneratorBuy.modifyCondition(condition);
        }, 300);
      }
    },
    handleShowCondition(condition) {
      this.$bvModal.show("modal-add-condition");
      this.isCompound = false;
      this.activeSell = false;
      this.activeBuy = true;
      this.show = true;
      setTimeout(() => {
        this.$refs.conditionGeneratorBuy.showCondition(condition);
      }, 500);
    },
    handleAddModify(condition, compoundSell) {
      if (
        condition.base_configuration.is_compound &&
        condition.base_configuration.is_compound != "false"
      ) {
        let condition1 = this.$refs.conditionGeneratorBuy.addCondition(true);
        let condition2 = this.$refs.conditionGeneratorSell.addCondition(true);

        if (
          condition1.base_configuration.type ===
          condition2.base_configuration.type
        ) {
          this.$toastr.error(
            "The compound conditions must be sell and buy.",
            `${this.error}`
          );
          return;
        }

        let indexOtherCondition = 0;
        if (this.indexConditionModify % 2 === 0) {
          indexOtherCondition = this.indexConditionModify + 1;
        } else {
          // indexOtherCondition = this.indexConditionModify - 1;
          indexOtherCondition = this.indexConditionModify;
          this.indexConditionModify -= 1;
        }

        const configurations = this.backtest.configurations;
        this.backtest.configurations = [];

        condition1.base_configuration.configuration_compound.method = parseInt(
          condition1.base_configuration.is_compound.replace("method", "")
        );
        condition2.base_configuration.configuration_compound.method = parseInt(
          condition2.base_configuration.is_compound.replace("method", "")
        );

        if (condition1.base_configuration.configuration_compound.method == 3) {
          if (condition1.base_configuration.configuration_compound.priority) {
            delete condition1.base_configuration.configuration_compound
              .priority;
          }
          if (
            condition1.base_configuration.configuration_compound
              .max_time_to_wait
          ) {
            delete condition1.base_configuration.configuration_compound
              .max_time_to_wait;
          }
        }

        if (condition2.base_configuration.configuration_compound.method == 3) {
          if (condition2.base_configuration.configuration_compound.priority) {
            delete condition2.base_configuration.configuration_compound
              .priority;
          }
          if (
            condition2.base_configuration.configuration_compound
              .max_time_to_wait
          ) {
            delete condition2.base_configuration.configuration_compound
              .max_time_to_wait;
          }
        }

        configurations[this.indexConditionModify] = {};
        configurations[this.indexConditionModify] = condition1;

        configurations[indexOtherCondition] = {};
        configurations[indexOtherCondition] = condition2;
        if (!this.colorID[this.indexConditionModify]) {
          const color = this.$helpers.getRandomColor();
          this.colorID[this.indexConditionModify] = color;
          this.colorID[indexOtherCondition] = color;
        }

        this.backtest.configurations = configurations;
        this.$bvModal.hide("modal-add-condition");
        this.isModify = false;
        return;
      }

      const configurations = this.backtest.configurations;
      this.backtest.configurations = [];
      configurations[this.indexConditionModify] = {};
      configurations[this.indexConditionModify] = condition;
      this.colorID = {};
      for (let i = 0; i < configurations.length; i++) {
        if (configurations[i].base_configuration.configuration_compound) {
          configurations.splice(i, 1);
        }
      }
      setTimeout(() => {
        this.backtest.configurations = configurations;
        this.$bvModal.hide("modal-add-condition");
        this.isModify = false;
      }, 200);
    },
    handleDuplicateCondition(condition, index) {
      if (
        condition.base_configuration.is_compound &&
        condition.base_configuration.is_compound !== "false"
      ) {
        let cpy1 = this.handleCopyCondition(condition);
        let cpy2 = {};
        if (index % 2 !== 0) {
          cpy2 = this.handleCopyCondition(
            this.backtest.configurations[index - 1]
          );
          let bkcpy1 = cpy1;
          cpy1 = cpy2;
          cpy2 = bkcpy1;
        } else {
          cpy2 = this.handleCopyCondition(
            this.backtest.configurations[index + 1]
          );
        }
        cpy2.base_configuration.configuration_compound.token = cpy1.token;
        cpy1.base_configuration.configuration_compound.token = cpy2.token;
        const color = this.$helpers.getRandomColor();
        this.colorID[this.backtest.configurations.length] = color;
        this.colorID[this.backtest.configurations.length + 1] = color;
        this.backtest.configurations.push(cpy1);
        this.backtest.configurations.push(cpy2);
        return;
      }
      this.backtest.configurations.push(this.handleCopyCondition(condition));
    },
    handleCopyCondition(condition) {
      let cpy = { ...condition };
      const token = this.$helpers.getRandomInt();
      const conditionClear = {};
      const options = Object.keys(cpy); //
      for (let i = 0; i < options.length; i += 1) {
        // recorremos los keys principales
        let key = cpy[options[i]];
        let keysInternos = Object.keys(cpy[options[i]]); // obtenemos los keys dentro de los principales (condiiton_ema, conditionClear_001, etc)
        let isAdd = false;
        conditionClear[options[i]] = {};
        for (let j = 0; j < keysInternos.length; j += 1) {
          let value = cpy[options[i]][keysInternos[j]];
          conditionClear[options[i]][keysInternos[j]] = undefined;
          if (typeof value === "object") {
            value = this.$helpers.observerClean(value);
          }
          conditionClear[options[i]][keysInternos[j]] = value;
        }
      }
      conditionClear.token = token;
      return conditionClear;
    },
    handleDeleteBacktest() {
      this.token = "";
    },
    pasteBacktest() {
      if (!localStorage.getItem("copyBacktest")) {
        this.$toastr.error("There is no backtest to paste", `${this.error}`);
        return;
      }
      let cpBacktest = this.$crypto.decrypt(
        localStorage.getItem("copyBacktest")
      );
      cpBacktest = JSON.parse(cpBacktest);
      if (typeof cpBacktest.timeframes === "string") {
        cpBacktest.timeframes = JSON.parse(cpBacktest.timeframes);
      }
      if (cpBacktest.dates[0] == "[") {
        cpBacktest.dates = JSON.parse(cpBacktest.dates);
      }
      this.dateRange = [];
      this.dateRange = {
        startDate: cpBacktest.dates[0],
        endDate: cpBacktest.dates[1],
      };

      this.backtest.date = cpBacktest.dates;
      this.backtest.note = cpBacktest.note;
      this.backtest.symbol = cpBacktest.symbol.toLowerCase();
      this.backtest.timeframes = cpBacktest.timeframes;
      this.backtest.client_confirmation = cpBacktest.client_confirmation;
      let conditions = cpBacktest.configurations;
      this.backtest.configurations = [];
      let compound = conditions.filter(
        (i) => i.condition.base_configuration.is_compound != false
      ).length;
      let color = "";
      let token = "";
      let oldToken = "";
      this.colorID = {};
      for (let i = 0; i < conditions.length; i += 1) {
        let c = conditions[i];
        if (!c.condition.base_configuration.configuration_compound) {
          c.condition.base_configuration.configuration_compound = {
            priority: "buy",
            max_time_to_wait: 60,
          };
        } else {
          if (c.condition.base_configuration.configuration_compound.method) {
            //delete c.condition.base_configuration.configuration_compound.method;
          }
        }
        token = this.$helpers.getRandomInt();
        c.condition.token = token;
        if (compound > 0) {
          if (i % 2 == 0) {
            color = this.$helpers.getRandomColor();
            this.colorID[i] = color;
            oldToken = token;
          } else {
            this.colorID[i] = color;
            c.condition.base_configuration.configuration_compound.token =
              oldToken;
            let prevIndex = i - 1;
            this.backtest.configurations[
              prevIndex
            ].base_configuration.configuration_compound.token = token;
          }
        }
        this.backtest.configurations.push(c.condition);
      }
    },
    handleCompoundCondition(value) {
      if (!value || value == "false") {
        this.isCompound = false;
        return;
      }
      this.isCompound = value != "false";
      this.is_compound = value;
    },
    handlePriority(value) {
      this.priority = value;
    },
    activeTabCondition(type) {
      if (type === "buy") {
        this.activeBuy = true;
        this.activeSell = false;
      } else {
        this.activeBuy = false;
        this.activeSell = true;
      }
    },
    handletSeType(type) {
      this.currentTypeCondition = "";
      setTimeout(() => {
        this.currentTypeCondition = type;
      }, 150);
    },
    handleBaseConfiguration(field, value) {
      this.$refs.conditionGeneratorSell.setData(field, value);
    },
    showModalAddCondition() {
      // this.isCompound = false;
      this.activeSell = false;
      this.isModify = false;
      this.show = false;
      this.activeBuy = true;
      this.currentTypeCondition = "buy";
    },
    setConditionPasteSell(condition) {
      setTimeout(() => {
        this.$refs.conditionGeneratorSell.setCondition(condition);
      }, 500);
    },
  },
  watch: {
    dateRange: function (newDates) {
      this.backtest.date = [newDates.startDate, newDates.endDate];
      this.dateStart = newDates.startDate;
      this.dateEnd = newDates.endDate;
    },
    "$store.state.token_backtest_detail"(value) {
      if (value) {
        this.token = value;
      }
    },
    "backtest.symbol"(value) {
      if (value.toLowerCase() !== "btcusdt") {
        this.minDate = new Date("25 Jan 2021");
      } else {
        this.minDate = new Date("04 Sep 2019");
      }
      // this.$store.commit('bwfs_backtest_mindate_by_symbol', {vm: this, symbol: value});
    },
    "$store.state.backtest_mindate"(value) {
      // this.minDate = new Date(value);
    },
    "backtest.client_confirmation"(value) {
      if (Number(value) < 0) {
        this.backtest.client_confirmation = 25;
      } else {
        value = value.toString().replace(/,/, ".");
        value = value.toString().replace("..", ".").replace(/[a-z]/gi, "");
        this.backtest.client_confirmation = value;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/commons/admin.scss";
@import "@/styles/pages/backtestings.scss";
</style>

<style>
.bg-light {
  background-color: #232429 !important;
}
</style>
