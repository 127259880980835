import random_number from "../../../helpers/generators/random_number";
import random_date from "../../../helpers/generators/random_date";

export default () => {
  const date = random_date("MM/DD/YYYY, h:mm:ss");
  const nav = random_number(-3000, 3000);
  const perfomance = random_number(1000, 20000);
  const performance_percentage = random_number(0, 100);
  const daily_returns = random_number(0, 4000);
  const daily_returns_percentage = random_number(0, 100);
  const deposit_withdraw = random_number(0, 30000);

  return {
    date,
    nav,
    perfomance,
    performance_percentage,
    daily_returns,
    daily_returns_percentage,
    deposit_withdraw,
  };
};
