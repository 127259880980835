import CONSTANTS from "@/helpers/constants.js";

const error_icon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
<path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
</svg> Error`;

const success_icon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
<g clip-path="url(#clip0_1634_940)">
<path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
</g>
<defs>
<clipPath id="clip0_1634_940">
<rect width="10" height="10" fill="white" transform="translate(5 5)"/>
</clipPath>
</defs>
</svg> Success`;

export default {
  state: {
    condition_loading: false,
    backtest_orders_loading: true,
    backtest_loading: false,
    backtest_add_remove_loading: false,
    symbol_loading: false,
    symbols: [],
    number_instances_loading: false,
    number_instances: [],
    token_backtest_detail: "",
    backtest_detail: {},
    backtest_paginate: {
      current_page: 1,
      per_page: 25,
      records: 0,
      total_pages: 5,
    },
    backtest_mindate_loading: false,
    backtest_mindate: "",
    condition_token_selected: "",
    algorithm_token_selected: null,
    condition_selected: {},
    backtest_conditions: [],
    backtest_orders: [],
    backtest_orders_paginate: {
      current_page: 1,
      per_page: 15,
      total_pages: 0,
      records: 0,
    },
    backtest_delete_loading: false,
    backtest_condition_resumen_loading: false,
    backtest_condition_resumen: {},
    history_balance_loading: false,
    history_balances: [],
    order_json: {},
    candlestick: [],
    candlestick_anotations: [],
    candlestick_anotations_points: [],
    candlestick_loading: false,
    count_backtest: [],
    search_order_token: "",
  },
  mutations: {
    bwfs_get_backtest(state, payload) {
      state.backtest_loading = true;
      payload.vm.$http
        .server2("bwfs/admin/backtest", payload.data, {}, payload.endpoint)
        .then((res) => {
          if (res.error) {
            state.backtest_loading = false;
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          state.backtest_loading = false;
          state.backtests = [];
          state.backtests = res.data;
          state.backtest_paginate = res.paginate;
          if (state.token_backtest_detail) {
            let searchBacktest = res.data.filter(
              (b) => b.token === state.token_backtest_detail
            );
            if (searchBacktest.length > 0) {
              state.backtest_detail = searchBacktest[0];
            }
          }
        });
    },
    bwfs_get_backtest_selected(state, payload) {
      let filters = {};
      if (localStorage.getItem("filters_backtest")) {
        filters = JSON.parse(localStorage.getItem("filters_backtest"));
      }
      if (!filters.endpoint) {
        filters.endpoint = "production";
      }

      payload.vm.$http
        .server2("bwfs/admin/backtest", payload.data, {}, filters.endpoint)
        .then((res) => {
          if (res.error) {
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          if (state.token_backtest_detail) {
            let searchBacktest = res.data.filter(
              (b) => b.token === state.token_backtest_detail
            );
            if (searchBacktest.length > 0) {
              state.backtest_detail = searchBacktest[0];
            }
          }
        });
    },
    bwfs_get_symbols(state, vm) {
      state.symbol_loading = true;
      vm.$http.server2("bwfs/admin/symbols/get", {}).then((res) => {
        if (res.error) {
          state.symbol_loading = false;
          vm.$toastr.error(
            res.message ? res.message : "An error has occurred",
            error_icon
          );
          return;
        }
        state.symbols = res.data;
        state.symbol_loading = false;
      });
    },
    bwfs_get_number_instance(state, vm) {
      state.number_instances_loading = true;
      vm.$http.server2("bwfs/admin/number/instances/get", {}).then((res) => {
        if (res.error) {
          state.number_instances_loading = false;
          vm.$toastr.error(
            res.message ? res.message : "An error has occurred",
            error_icon
          );
          return;
        }
        state.number_instances = Array.from(
          Array(res.data.instance_num).keys()
        );
        state.number_instances_loading = false;
      });
    },
    bwfs_get_backtest_favorites(state, vm) {
      let filters = {};
      if (localStorage.getItem("filters_backtest")) {
        filters = JSON.parse(localStorage.getItem("filters_backtest"));
      }
      if (!filters.endpoint) {
        filters.endpoint = "production";
      }
      vm.$http
        .server2(
          "bwfs/admin/backtest/favorite/get",
          { user_token: state.user.token },
          {},
          filters.endpoint
        )
        .then((res) => {
          if (res.error) {
            vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          state.backtest_favorites = res.data;
        });
    },
    bwfs_add_backtest_favorites(state, payload) {
      let data = {
        user_token: state.user.token,
        backtest_token: payload.backtestToken,
      };
      if (state.backtest_add_remove_loading) {
        return;
      }
      let filters = {};
      if (localStorage.getItem("filters_backtest")) {
        filters = JSON.parse(localStorage.getItem("filters_backtest"));
      }
      if (!filters.endpoint) {
        filters.endpoint = "production";
      }
      state.backtest_add_remove_loading = true;
      payload.vm.$http
        .server2("bwfs/admin/backtest/favorite/add", data, {}, filters.endpoint)
        .then((res) => {
          state.backtest_add_remove_loading = false;
          if (res.error) {
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          state.backtest_favorites.push(res.data);
          payload.vm.$store.commit("bwfs_get_backtest_favorites", payload.vm);
          payload.vm.$toastr.success(
            "The backtest was successfully added to favorites",
            success_icon
          );
        });
    },
    bwfs_delete_backtest_favorites(state, payload) {
      let data = {
        user_token: state.user.token,
        backtest_token: payload.backtestToken,
      };

      if (state.backtest_add_remove_loading) {
        return;
      }

      let filters = {};
      if (localStorage.getItem("filters_backtest")) {
        filters = JSON.parse(localStorage.getItem("filters_backtest"));
      }
      if (!filters.endpoint) {
        filters.endpoint = "production";
      }

      state.backtest_add_remove_loading = true;
      payload.vm.$http
        .server2(
          "bwfs/admin/backtest/favorite/delete",
          data,
          {},
          filters.endpoint
        )
        .then((res) => {
          state.backtest_add_remove_loading = false;
          if (res.error) {
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          payload.vm.$store.commit("bwfs_get_backtest_favorites", payload.vm);
          let favorites = state.backtest_favorites;
          let index = 0;

          for (let i = 0; i < favorites.length; i += 1) {
            if (favorites[i].backtest_token === payload.backtestToken) {
              index = i;
              break;
            }
          }
          favorites.splice(index, 1);
          state.backtest_favorites = favorites;
          payload.vm.$toastr.success(
            "The backtest was successfully removed from favorites.",
            success_icon
          );
        });
    },
    bwfs_get_condtions_backtest(state, payload) {
      state.condition_loading = true;
      state.backtest_conditions = [];
      const data = {
        backtest_token: payload.backtest_token,
        is_compound: payload.is_compound ? payload.is_compound : 0,
      };
      let filters = {};
      if (localStorage.getItem("filters_backtest")) {
        filters = JSON.parse(localStorage.getItem("filters_backtest"));
      }
      if (!filters.endpoint) {
        filters.endpoint = "production";
      }
      payload.vm.$http
        .server2(
          "bwfs/admin/backtest/conditions/get",
          data,
          {},
          filters.endpoint
        )
        .then((res) => {
          if (res.error) {
            state.condition_loading = false;
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          let data = res.data;
          let dates = state.backtest_detail.dates;
          let date_start = "";
          if (dates[0] == "[") {
            dates = JSON.parse(dates);
          }
          date_start = new Date(dates[0]);
          let date_end = dates[1].substr(0, 7);
          let date_end_at = new Date(dates[1]);
          let run = true;
          let index = 0;
          let monthly_yield = {};
          while (run) {
            let next_month = "";
            if (index == 0) {
              next_month = date_start.toISOString().substr(0, 7);
              monthly_yield[next_month] = {
                balance: 0,
                perfomance: 0,
              };
              index++;
              continue;
            }

            try {
              date_start.setMonth(parseInt(date_start.getMonth()) + 1);
            } catch (e) {
              run = false;
              console.log(e.message);
              console.log(
                date_start.getMonth(),
                parseInt(date_start.getMonth()) + 1
              );
            }

            // date_start.setMonth( parseInt(date_start.getMonth()) + 1);
            next_month = date_start.toISOString().substr(0, 7);
            try {
            } catch (e) {
              console.log(date_start);
              run = false;
            }
            monthly_yield[next_month] = {
              balance: 0,
              perfomance: 0,
            };
            if (
              next_month == date_end ||
              date_start.getTime() > date_end_at.getTime()
            ) {
              run = false;
            }

            index++;
          }

          for (let i = 0; i < data.length; i++) {
            let tm = {};
            Object.keys(monthly_yield).map(function (key) {
              tm[key] = { ...monthly_yield[key] };
              return key;
            });
            if (data[i].grafic) {
              for (let j = 0; j < data[i].grafic.length; j++) {
                let date_month = data[i].grafic[j].close_date_at.substr(0, 7);
                if (data[i].grafic[j].profit_percentage == undefined) {
                  data[i].grafic[j].profit_percentage = 0;
                }
                if (date_month) {
                  if (tm[date_month]) {
                    if (tm[date_month].perfomance) {
                      tm[date_month].perfomance += Number(
                        data[i].grafic[j].profit_percentage
                      );
                      tm[date_month].balance = Number(data[i].grafic[j].accum);
                    } else {
                      tm[date_month]["perfomance"] += Number(
                        data[i].grafic[j].profit_percentage
                      );
                      tm[date_month]["balance"] = Number(
                        data[i].grafic[j].accum
                      );
                    }
                  } else {
                    tm[date_month]["perfomance"] = Number(
                      data[i].grafic[j].profit_percentage
                    );
                    tm[date_month]["balance"] = Number(data[i].grafic[j].accum);
                  }
                }
              }
            }
            data[i]["monthly_yield"] = tm;
          }
          state.backtest_conditions = data;
          state.condition_loading = false;
        })
        .catch((e) => {
          state.condition_loading = false;
          payload.vm.$toastr.error(
            `Get Condtions Backtest error: ${e.message}`,
            error_icon
          );
        });
    },
    bwfs_get_condtion_orders_backtest(state, payload) {
      const vm = payload.vm;
      let url = "bwfs/admin/backtest/orders/condition";
      if (payload.is_investor) {
        url = "bwfs/admin/investors/orders";
      } else if (payload.is_subinvestor) {
        url = "bwfs/admin/subinvestors/orders";
      } else if (payload.is_contributor) {
        url = "bwfs/admin/contributors/orders";
      } else if (payload.several_investors) {
        url = "bwfs/admin/investors/orders_investors";
      }

      let server = "server2";

      let filters = {};
      if (localStorage.getItem("filters_backtest")) {
        filters = JSON.parse(localStorage.getItem("filters_backtest"));
      }
      if (!filters.endpoint) {
        filters.endpoint = "production";
      }

      if (
        payload.is_investor ||
        payload.is_subinvestor ||
        payload.several_investors ||
        payload.is_contributor
      ) {
        server = "server1";
      }

      delete payload.vm;
      delete payload.is_investor;
      state.backtest_orders_loading = true;
      vm.$http[server](url, payload, {}, filters.endpoint)
        .then((res) => {
          if (res.error) {
            state.backtest_orders_loading = false;
            vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          state.backtest_orders_loading = false;
          state.backtest_orders = res.data;
          state.backtest_orders_paginate = res.paginate;
        })
        .catch((e) => {
          state.backtest_orders_loading = false;
          // vm.$toastr.error(
          //   e.message ? e.message : "An error has occurred",
          //   error_icon
          // );
        });
    },
    bwfs_selected_condition_token(state, condition) {
      console.log("bwfs_selected_condition_token(state, condition) {");
      state.condition_token_selected = "";
      state.condition_selected = "";
      setTimeout(() => {
        state.condition_token_selected = condition.token;
        state.condition_selected = condition;
      }, 500);
    },
    bwfs_selected_algorithm_token(state, token_condition) {
      state.algorithm_token_selected = token_condition;
    },
    bwfs_token_backtest_detail(state, token) {
      state.token_backtest_detail = "";
      setTimeout(() => {
        state.token_backtest_detail = token;
      }, 200);
    },

    bwfs_backtest_detail(state, backtest) {
      state.backtest_detail = {};
      setTimeout(() => {
        state.backtest_detail = backtest;
      }, 200);
    },
    bwfs_backtest_mindate_by_symbol(state, payload) {
      const vm = payload.vm;
      delete payload.vm;

      let filters = {};
      if (localStorage.getItem("filters_backtest")) {
        filters = JSON.parse(localStorage.getItem("filters_backtest"));
      }
      if (!filters.endpoint) {
        filters.endpoint = "production";
      }
      state.backtest_mindate_loading = true;
      vm.$http
        .server2("bwfs/admin/mindate/symbol/get", payload, {}, filters.endpoint)
        .then((res) => {
          if (res.error) {
            state.backtest_mindate_loading = false;
            vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          state.backtest_mindate_loading = false;
          state.backtest_mindate = res.data.datetime;
        })
        .catch((e) => {
          state.backtest_mindate_loading = false;
          vm.$toastr.error(
            e.message ? e.message : "An error has occurred",
            error_icon
          );
        });
    },
    bwfs_reset_condtion_orders_backtest(state) {
      state.backtest_orders = [];
      state.condition_token_selected = "";
    },
    bwfs_delete_backtest(state, payload) {
      const vm = payload.vm;
      delete payload.vm;
      let filters = {};
      if (localStorage.getItem("filters_backtest")) {
        filters = JSON.parse(localStorage.getItem("filters_backtest"));
      }
      if (!filters.endpoint) {
        filters.endpoint = "production";
      }
      state.backtest_delete_loading = true;
      vm.$http
        .server2("bwfs/admin/backtest/delete", payload, {}, filters.endpoint)
        .then((res) => {
          if (res.error) {
            state.backtest_delete_loading = false;
            vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          state.backtest_delete_loading = false;
          vm.$toastr.success(
            res.message ? res.message : "Deleted successfully",
            success_icon
          );

          state.condition_token_selected = "";
          state.backtest_orders = [];
          state.backtest_conditions = [];
          state.token_backtest_detail = "";
          state.backtest_detail = {};
          state.condition_token_selected = "";
          // vm.$store.commit("bwfs_get_backtest", vm);
        })
        .catch((e) => {
          state.backtest_delete_loading = false;
          vm.$toastr.error(
            e.message ? e.message : "An error has occurred",
            error_icon
          );
        });
    },
    bwfs_get_condition_resumen(state, resumen) {
      state.backtest_condition_resumen = resumen;
    },
    bwfs_get_history_balance(state, payload) {
      state.history_balance_loading = true;
      state.history_balances = [];
      setTimeout(() => {
        state.history_balance_loading = false;
        state.history_balances = payload;
      }, 2000);
    },
    bwfs_get_order_json(state, payload) {
      const data = payload.data;
      const vm = payload.vm;
      let filters = {};
      if (localStorage.getItem("filters_backtest")) {
        filters = JSON.parse(localStorage.getItem("filters_backtest"));
      }
      if (!filters.endpoint) {
        filters.endpoint = "production";
      }
      vm.$http
        .server2("bwfs/admin/backtest/order/json", data, {}, filters.endpoint)
        .then((res) => {
          if (res.error) {
            state.order_json = {};
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          state.order_json = res.data;
        })
        .catch((e) => {
          state.order_json = {};
          payload.vm.$toastr.error(
            e.message ? e.message : "An error has occurred",
            error_icon
          );
        });
    },
    bwfs_get_candlestick(state, payload) {
      const data = payload.data;
      const vm = payload.vm;
      state.candlestick_loading = true;
      state.candlestick = [];
      state.candlestick_anotations = [];
      let condition = state.backtest_conditions.filter(
        (c) => c.condition.token === state.condition_token_selected
      )[0];
      let base_condition = JSON.parse(condition.condition.condition);
      let orders = condition.grafic;

      vm.$http
        .server2("bwfs/admin/candlestick/get", data)
        .then((res) => {
          if (res.error) {
            state.candlestick_loading = false;
            state.candlestick = [];
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          if (res.data.length == 0) {
            state.candlestick_loading = false;
            payload.vm.$toastr.error(
              "Candlestick chart not available.",
              error_icon
            );
            return;
          }
          let data = [];
          let anotations_flags = {};
          let anotations = {};
          let start = {
            x: new Date(res.data[0][0]).getTime(),
            title: `<span style="color: #000 !important">${
              new Date(res.data[0][0]).toString().split(" ")[1]
            }: Initial balance: ${
              base_condition.base_configuration.balance
            }</span>`,
          };

          res.data.forEach((el, index) => {
            let dateStart = el[0].substr(0, 7);
            if (!anotations[dateStart]) {
              anotations[dateStart] = {
                x: new Date(el[0]).getTime(),
                title: el[0],
                dateString: new Date(el[0]).toString().split(" ")[1],
                dateString2: new Date(el[0]).toString(),
                price_low: el[3],
                price_hight: el[3],
                last_date: el[0],
              };
            } else {
              anotations[dateStart].x = new Date(el[0]).getTime();
              anotations[dateStart].price_hight = el[3];
              let v =
                ((anotations[dateStart].price_hight -
                  anotations[dateStart].price_low) *
                  100) /
                anotations[dateStart].price_hight;
              anotations[
                dateStart
              ].title = `<span style="color: #000"> M${dateStart.substr(
                -2
              )} | V:<span style="color: ${
                v >= 0 ? "green" : "red"
              } !important">${v.toFixed(2)}% </span></span>`;
            }
            data.push([new Date(el[0]).getTime(), el[2], el[5], el[4], el[3]]);
          });

          let annotations_moths = condition.annotations_moths;
          let months = Object.keys(annotations_moths);
          for (var i = 0; i < months.length; i++) {
            let m = months[i];
            if (i > 0) {
              let m_p = months[i - 1];
              let p =
                ((annotations_moths[m] - annotations_moths[m_p]) * 100) /
                annotations_moths[m_p];
              if (anotations[m]) {
                let v =
                  ((anotations[m].price_hight - anotations[m_p].price_hight) /
                    anotations[m_p].price_hight) *
                  100;
                anotations[m].title = `<span style="color: #000">M${m.substr(
                  -2
                )} | P:<span style="color: ${
                  p >= 0 ? "green" : "red"
                } !important">${p.toFixed(2)}%</span> | V:<span style="color: ${
                  v >= 0 ? "green" : "red"
                } !important">${v.toFixed(2)}% </span></span>`;
              }
            } else {
              let balance = base_condition.base_configuration.balance;
              let p = ((annotations_moths[m] - balance) * 100) / balance;
              let v =
                ((anotations[m].price_hight - anotations[m].price_low) * 100) /
                anotations[m].price_hight;
              anotations[m].title = `<span style="color: #000"> M${m.substr(
                -2
              )}| P:<span style="color: ${
                p >= 0 ? "green" : "red"
              } !important">${p.toFixed(2)}%</span> | V:<span style="color: ${
                v >= 0 ? "green" : "red"
              } !important">${v.toFixed(2)}% </span></span>`;
            }
          }
          let anotations_data = Object.keys(anotations).map((key) => {
            let a = anotations[key];
            return {
              value: a.x,
              color: "#888",
              dashStyle: "solid",
              width: 1,
              zIndex: 10,
              label: {
                text: a.title,
                rotation: 0,
              },
            };
          });

          let anotations_points = orders.map((o, index) => {
            if (index > 0) {
              return {
                x: new Date(o.close_date_at.substr(0, 10)).getTime(),
                title: `<span style="color: #000 !important">${index}</span>`,
                text: `<span style="color: #000 !important"> O#${index} | P${o.profit_percentage.toFixed(
                  2
                )}%</span>`,
                color: o.profit_percentage >= 0 ? "green" : "red",
              };
            } else {
              return false;
            }
          });
          let start2 = {
            value: start.x,
            color: "#888",
            dashStyle: "solid",
            width: 1,
            zIndex: 10,
            label: {
              text: start.title,
              rotation: 0,
            },
          };
          anotations_data.unshift(start2);
          anotations_points.shift();
          /*let last = res.data[res.data.length - 1 ];
        let time_start = new Date(last[0]);
        time_start.setHours(time_start.getHours() + 1);
        data.push([
          time_start.getTime(),
          0,
          0,
          0,
          0,
        ]);*/
          /*for (let i = 0; i < 5; i++) {
          time_start.setHours(time_start.getHours() + 1);
          data.push([
            time_start.getTime(),
            1,
            1,
            1,
            1,
          ]);
        }*/
          state.candlestick = data;
          state.candlestick_anotations = anotations_data;
          state.candlestick_anotations_points = anotations_points;
          state.candlestick_loading = false;
        })
        .catch((e) => {
          state.candlestick_loading = false;
          state.candlestick = [];
          payload.vm.$toastr.error(
            e.message ? e.message : "An error has occurred",
            error_icon
          );
        });
    },
    bwfs_get_count_backtest_by_status(state, vm) {
      let filters = {};
      if (localStorage.getItem("filters_backtest")) {
        filters = JSON.parse(localStorage.getItem("filters_backtest"));
      }
      if (!filters.endpoint) {
        filters.endpoint = "production";
      }
      vm.$http
        .server2("bwfs/admin/backtest/count/status", {}, {}, filters.endpoint)
        .then((res) => {
          if (res.error) {
            state.count_backtest = [];
            vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          state.count_backtest = res.data;
        })
        .catch((e) => {
          state.count_backtest = [];
          vm.$toastr.error(
            e.message ? e.message : "An error has occurred",
            error_icon
          );
        });
    },
    bwfs_set_search_order(state, token) {
      state.search_order_token = token;
    },
    bwfs_download_report_by_condtion(state, payload) {
      let filters = {};
      if (localStorage.getItem("filters_backtest")) {
        filters = JSON.parse(localStorage.getItem("filters_backtest"));
      }
      if (!filters.endpoint) {
        filters.endpoint = "production";
      }
      payload.vm.$http
        .server2(
          "bwfs/admin/backtest/export/orders",
          payload.data,
          {},
          filters.endpoint
        )
        .then((res) => {
          if (res.error) {
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }

          let filters = {};
          if (localStorage.getItem("filters_backtest")) {
            filters = JSON.parse(localStorage.getItem("filters_backtest"));
          }
          if (!filters.endpoint) {
            filters.endpoint = "production";
          }
          if (payload.server) {
            if (filters.endpoint == "production") {
              window.open(
                CONSTANTS.backtesting_api + "/bwfs/static/" + res.message
              );
            } else {
              window.open(
                CONSTANTS.develop_api + "/bwfs/static/" + res.message
              );
            }
          } else {
            window.open(payload.server + "/bwfs/static/" + res.message);
          }
        })
        .catch((e) => {
          payload.vm.$toastr.error(
            e.message ? e.message : "An error has occurred",
            error_icon
          );
        });
    },
    bwfs_download_report_backtest(state, payload) {
      let filters = {};
      if (localStorage.getItem("filters_backtest")) {
        filters = JSON.parse(localStorage.getItem("filters_backtest"));
      }
      if (!filters.endpoint) {
        filters.endpoint = "production";
      }
      payload.vm.$http
        .server2(
          "bwfs/admin/backtest/export",
          payload.data,
          {},
          filters.endpoint
        )
        .then((res) => {
          if (res.error) {
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          let filters = {};
          if (localStorage.getItem("filters_backtest")) {
            filters = JSON.parse(localStorage.getItem("filters_backtest"));
          }
          if (!filters.endpoint) {
            filters.endpoint = "production";
          }

          if (payload.server) {
            if (filters.endpoint == "production") {
              window.open(
                CONSTANTS.backtesting_api + "/bwfs/static/" + res.message
              );
            } else {
              window.open(
                CONSTANTS.develop_api + "/bwfs/static/" + res.message
              );
            }
          } else {
            window.open(payload.server + "/bwfs/static/" + res.message);
          }
        })
        .catch((e) => {
          payload.vm.$toastr.error(
            e.message ? e.message : "An error has occurred",
            error_icon
          );
        });
    },
  },
};
