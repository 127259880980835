<template>
	<div :class="`page__login page__requestaccess`">
    <b-container>
      <b-row>
        <b-col cols="12" lg="3">
        </b-col>
        <b-col cols="12" lg="6">
          <div class="login p-2">
            <div class="d-block m-0 step-content">
              <div class="form-group">
                <div class="row">
                  <div class="col-md-12 text-center">
                    	<h3>
                      	The system is currently under maintenance.
                    	</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
	export default {
		name: 'MaintenanceMode'
	}
</script>
<style lang="scss">
  @import '@/styles/pages/login.scss';
</style>
