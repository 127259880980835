<template>
  <div class="orders_table_component">
    <div class="row buttons_filter">
      <div
        :class="
          $route.name.toLowerCase() == 'investors'
            ? 'col-md-10 d-flex justify-content-end align-items-center ml-auto mr-1'
            : 'col-md-12 d-flex justify-content-end align-items-center'
        "
      >
        <span
          v-b-tooltip.hover
          class="status_orders_table mr-3"
          @click="toggleStatus('cancelled')"
          v-if="status.cancelled"
        >
          Cancelled
          <span style="font-size: 25px">
            <b-icon-toggle-on class="ml-2"></b-icon-toggle-on>
          </span>
        </span>
        <span
          v-b-tooltip.hover
          class="status_orders_table mr-3"
          @click="toggleStatus('cancelled')"
          v-if="!status.cancelled && $store.state.user.token"
        >
          Cancelled
          <span style="font-size: 25px">
            <b-icon-toggle-off class="ml-2"></b-icon-toggle-off>
          </span>
        </span>

        <span
          v-b-tooltip.hover
          class="status_orders_table mr-3"
          @click="toggleStatus('pending')"
          v-if="status.pending && $store.state.user.token"
        >
          Pending
          <span style="font-size: 25px">
            <b-icon-toggle-on class="ml-2"></b-icon-toggle-on>
          </span>
        </span>
        <span
          v-b-tooltip.hover
          class="status_orders_table mr-3"
          @click="toggleStatus('pending')"
          v-if="!status.pending && $store.state.user.token"
        >
          Pending
          <span style="font-size: 25px">
            <b-icon-toggle-off class="ml-2"></b-icon-toggle-off>
          </span>
        </span>

        <span
          v-b-tooltip.hover
          class="status_orders_table mr-3"
          @click="toggleStatus('close')"
          v-if="status.close && $store.state.user.token"
        >
          Close
          <span style="font-size: 25px">
            <b-icon-toggle-on class="ml-2"></b-icon-toggle-on>
          </span>
        </span>
        <span
          v-b-tooltip.hover
          class="status_orders_table mr-3"
          @click="toggleStatus('close')"
          v-if="!status.close && $store.state.user.token"
        >
          Close
          <span style="font-size: 25px">
            <b-icon-toggle-off class="ml-2"></b-icon-toggle-off>
          </span>
        </span>

        <span
          v-b-tooltip.hover
          class="status_orders_table mr-3"
          @click="toggleStatus('open')"
          v-if="status.open && $store.state.user.token"
        >
          Open
          <span style="font-size: 25px">
            <b-icon-toggle-on class="ml-2"></b-icon-toggle-on>
          </span>
        </span>
        <span
          v-b-tooltip.hover
          class="status_orders_table mr-3"
          @click="toggleStatus('open')"
          v-if="!status.open && $store.state.user.token"
        >
          Open
          <span style="font-size: 25px">
            <b-icon-toggle-off class="ml-2"></b-icon-toggle-off>
          </span>
        </span>

        <div
          class="form-group d-flex aling-item-center justify-content-end"
          v-if="
            $store.state.user.token ||
            $store.state.investors_tokens.length > 0 ||
            true
          "
        >
          <input
            type="text"
            class="form-control float-right d-inline-block"
            id="searchOrder"
            placeholder="Search by token / symbol / status / type annd more..."
            v-model="search"
            :disabled="$store.state.backtest_orders_loading"
          />
          <div class="loading" v-show="$store.state.backtest_orders_loading">
            <b-spinner variant="light"></b-spinner>
          </div>
        </div>
        <div
          class="form-group form-select d-flex aling-item-center justify-content-end"
          v-if="
            $store.state.user.token ||
            $store.state.investors_tokens.length > 0 ||
            true
          "
        >
          <select
            class="form-control float-right d-inline-block"
            v-model="sortBy"
            :disabled="$store.state.backtest_orders_loading"
          >
            <option value="asc_open.date">↑ By Open Date</option>
            <option value="desc_open.date">↓ By Open Date</option>
            <option value="asc_close.date">↑ By Close Date</option>
            <option value="desc_close.date">↓ By Close Date</option>
            <option value="asc_type">↑ By Order Type</option>
            <option value="desc_type">↓ By Order Type</option>
            <option value="asc_status">↑ By Order Status</option>
            <option value="desc_status">↓ By Order Status</option>
          </select>
          <div class="loading" v-show="$store.state.backtest_orders_loading">
            <b-spinner variant="light"></b-spinner>
          </div>
        </div>
      </div>
    </div>

    <div class="orders-desktop">
      <div
        class="order"
        v-for="(order, i) in $store.state.backtest_orders"
        :key="i"
        v-if="
          !$store.state.backtest_orders_loading &&
          $store.state.backtest_orders.length > 0
        "
        style="display: flex"
      >
        <div class="item-content p-2">
          <div class="d-block content_block_item colum_token">
            <label class="m-0"
              >Token
              <span class="text-danger">{{
                order.suspicious_order > 0 ? "*" : null
              }}</span></label
            >
            <div
              class="description pointer"
              v-b-tooltip.hover
              :title="order.token"
              @click="handleCopyToken(order.token)"
            >
              {{ order.token.substr(0, 13) + "..." }}
            </div>
          </div>
        </div>

        <div class="item-content p-2">
          <div class="d-block content_block_item colum_token">
            <label class="m-0">Algorithm</label>
            <div
              class="description pointer"
              v-b-tooltip.hover
              :title="order.condition_token"
            >
              {{
                order.condition_token
                  | algorithmToken($store.state.info_investor)
              }}
            </div>
          </div>
        </div>

        <div
          class="item-content p-2"
          v-if="$route.name.toLowerCase() == 'investors'"
        >
          <div class="d-block content_block_item colum_type">
            <label class="m-0">Investor</label>
            <div
              class="description pointer"
              v-b-tooltip.hover
              :title="order.investor_name"
              @click="handleCopyToken(order.investor_name)"
            >
              {{ order.investor_name.substr(0, 13) + "..." }}
            </div>
          </div>
        </div>
        <div class="item-content p-2">
          <div class="d-block content_block_item colum_type">
            <label class="m-0">Method</label>
            <div
              :class="[
                'description mono',
                order.type == 0 ? 'text-danger' : 'text-success',
              ]"
            >
              {{ order.type === 0 ? "SELL" : "BUY" }}
            </div>
          </div>
        </div>
        <div class="item-content p-2">
          <div class="d-block content_block_item colum_type colum_time_runing">
            <label class="m-0">Symbol</label>
            <div :class="['description mono']">
              {{ getSymbol(order) }}
            </div>
          </div>
        </div>
        <div
          class="item-content p-2"
          v-if="$route.name.toLowerCase() == 'investor'"
        >
          <div class="d-block content_block_item colum_type">
            <label class="m-0">Type</label>
            <div class="description">
              {{ isSpot ? "Spot" : "Futures" }}
            </div>
          </div>
        </div>
        <div
          class="item-content p-2"
          v-if="$route.name.toLowerCase() == 'investors'"
        >
          <div class="d-block content_block_item colum_type">
            <label class="m-0">Type</label>
            <div class="description">
              {{ order.market_type }}
            </div>
          </div>
        </div>
        <div class="item-content p-2">
          <div class="d-block content_block_item colum_status">
            <label class="m-0">Status</label>
            <div class="description mono text-muted" v-if="order.status === 4">
              CLOSED
            </div>
            <div
              class="description mono text-success"
              v-if="order.status === 2"
            >
              OPEN
            </div>
            <div class="description mono text-muted" v-if="order.status === 1">
              PENDING
            </div>
            <div class="description mono text-danger" v-if="order.status >= 8">
              CANCELLED
            </div>
          </div>
        </div>
        <div class="item-content p-2">
          <div class="d-block content_block_item colum_signal_price">
            <label class="m-0">Signal price</label>
            <div
              class="description mono cut_text_100"
              v-b-tooltip.hover
              :title="'In the date: ' + order.signal_date_at.substr(0, 19)"
            >
              {{ $helpers.numberFormat(order.signal_price) }}
            </div>
          </div>
        </div>
        <div class="item-content p-2">
          <div class="d-block content_block_item colum_open_price">
            <label class="m-0">Open price</label>
            <div
              class="description mono cut_text_100"
              v-b-tooltip.hover
              :title="'In the date: ' + order.open_date_at.substr(0, 19)"
            >
              {{ $helpers.numberFormat(order.open_price) }}
            </div>
          </div>
        </div>

        <div
          class="item-content p-2"
          v-if="$route.name.toLowerCase() == 'investor'"
        >
          <div class="d-block content_block_item colum_open_price">
            <label class="m-0">Open date</label>
            <div
              class="description mono cut_text_100"
              v-b-tooltip.hover
              :title="'Hour ' + order.open_date_at.split(' ')[1]"
            >
              {{ order.open_date_at.substr(0, 10) }}
            </div>
          </div>
        </div>

        <div class="item-content p-2">
          <div class="d-block content_block_item colum_lot_open">
            <label class="m-0">Lot Open</label>
            <div
              class="description mono"
              v-b-tooltip.hover
              :title="
                'Conversion lot: ' +
                $helpers.numberFormat(order.perfomance_open_lot)
              "
            >
              {{ $helpers.numberFormat(order.open_lot) }}
            </div>
          </div>
        </div>
        <div class="item-content p-2">
          <div class="d-block content_block_item colum_close_price">
            <label class="m-0">Close price</label>
            <div
              class="description mono cut_text_100"
              v-b-tooltip.hover
              :title="'In the date: ' + order.close_date_at.substr(0, 19)"
            >
              {{ $helpers.numberFormat(order.close_price) }}
            </div>
          </div>
        </div>
        <div
          class="item-content p-2"
          v-if="$route.name.toLowerCase() == 'investor'"
        >
          <div class="d-block content_block_item colum_open_price">
            <label class="m-0">Close date</label>
            <div
              class="description mono"
              v-b-tooltip.hover
              :title="'Hour ' + order.close_date_at.split(' ')[1]"
            >
              {{ order.close_date_at.substr(0, 10) }}
            </div>
          </div>
        </div>
        <div class="item-content p-2">
          <div class="d-block content_block_item colum_lot_close">
            <label class="m-0">Lot Close</label>
            <div
              class="description"
              v-b-tooltip.hover
              :title="
                'Conversion lot: ' +
                $helpers.numberFormat(order.perfomance_close_lot)
              "
            >
              {{ $helpers.numberFormat(order.close_lot) }}
            </div>
          </div>
        </div>
        <div class="item-content p-2">
          <div class="d-block content_block_item colum_time_runing">
            <label class="m-0">Time running</label>
            <div class="description mono">
              {{
                handleDate(
                  order.status,
                  order.signal_date_at,
                  order.close_date_at
                )
              }}
            </div>
          </div>
        </div>
        <div class="item-content p-2">
          <div class="d-block content_block_item colum_profits">
            <label class="m-0">Profits</label>
            <div
              class="description mono"
              v-if="order.status == 4 || order.status == 2"
            >
              <span
                v-if="
                  order.type == 0 &&
                  order.symbol &&
                  (order.symbol.toLowerCase() == 'btcusdt' ||
                    baseSymbol.toLowerCase() == 'btcusdt') &&
                  isSpot
                "
              >
                {{ $helpers.numberFormat(order.profit_coin) }}
              </span>
              <span v-else>
                {{ $helpers.numberFormat(order.profit_coin) }}
              </span>

              <span
                :class="[
                  'badget ',
                  order.profit_percentage > 0
                    ? 'badget-success'
                    : 'badget-danger',
                ]"
              >
                {{ $helpers.numberToFixed(order.profit_percentage, 2) }}%
              </span>
            </div>
            <div class="description mono" v-else>---</div>
          </div>
        </div>
        <div
          class="item-content p-2"
          v-if="$route.name.toLowerCase() == 'investor'"
        >
          <div class="d-block content_block_item colum_open_price">
            <label class="m-0">Last updated</label>
            <div
              class="description mono"
              v-if="typeof order.update_at == 'string'"
              v-b-tooltip.hover
              :title="'Hour ' + order.update_at.split(' ')[1]"
            >
              {{ order.update_at.substr(0, 10) }}
            </div>
            <div
              class="description mono"
              :class="[order.update_at > 30 ? 'text-danger' : null]"
              v-else
            >
              {{ order.update_at | parseSeconds }}
            </div>
          </div>
        </div>
        <div class="item-content p-2">
          <div
            class="d-block admin-menu"
            v-if="
              (($store.state.user.role_id === 1 ||
                $store.state.user.role_id === 2) &&
                $route.name.toLowerCase() != 'contributor' &&
                $route.name.toLowerCase() !== 'investors') ||
              $route.name.toLowerCase() == 'subinvestor'
            "
          >
            <span
              v-b-tooltip.hover
              title="Remove order"
              class="pointer mr-2"
              @click="handleRemoveOneOrder(order.token)"
              v-if="
                $route.name.toLowerCase() == 'investor' &&
                $store.state.user &&
                $store.state.user.role_id == 1
              "
            >
              <b-icon-x></b-icon-x>
            </span>
            <span
              v-b-tooltip.hover
              title="Show json order"
              class="pointer"
              @click="showOrders(order.token, order.condition_token)"
            >
              <b-icon-braces></b-icon-braces>
            </span>
            <span
              v-b-tooltip.hover
              title="Show logs"
              class="ml-2 pointer"
              @click="showOrders(order.token, order.condition_token, true)"
            >
              <b-icon-list-check></b-icon-list-check>
            </span>
            <span
              v-if="order.profits_by_orders_blended"
              v-b-tooltip.hover
              :title="'Show order blended ' + order.accum_orders.length"
              class="ml-2 pointer"
              @click="showBlendedOrders(order)"
            >
              <b-icon-card-checklist></b-icon-card-checklist>
            </span>
          </div>

          <div
            class="d-block admin-menu"
            v-else-if="$route.name.toLowerCase() == 'investors'"
          >
            <span
              v-b-tooltip.hover
              title="Show json order"
              class="pointer"
              @click="showOrders(order.token, order)"
            >
              <b-icon-braces></b-icon-braces>
            </span>
            <span
              v-b-tooltip.hover
              title="Show logs"
              class="ml-2 pointer"
              @click="showOrders(order.token, order, true)"
            >
              <b-icon-list-check></b-icon-list-check>
            </span>
          </div>
        </div>
      </div>

      <b-alert
        class="my-2"
        show
        variant="dark"
        v-if="
          $store.state.backtest_orders.length === 0 &&
          !$store.state.backtest_orders_loading
        "
      >
        <span> No found orders. </span>
      </b-alert>

      <div class="text-center my-2" v-if="$store.state.backtest_orders_loading">
        <b-skeleton-table
          :rows="5"
          :columns="4"
          :table-props="{ striped: true }"
        ></b-skeleton-table>
      </div>
      <!-- Cuando se seleccione una pagina 
          siguiente hacer que haga scroll hacia 
          arriba el bloque de las ordenes -->
      <div class="pagination-content p-0">
        <b-pagination
          v-model="currentPage"
          :total-rows="$store.state.backtest_orders_paginate.records"
          :limit="$store.state.backtest_orders_paginate.per_page"
          :per-page="$store.state.backtest_orders_paginate.per_page"
          class="mt-2"
          @page-click="linkGen"
          v-if="$store.state.backtest_orders_paginate.total_pages > 0"
        ></b-pagination>
      </div>
    </div>

    <div v-show="screensize <= 992" class="'orders-movil'">
      <div class="row pl-3 pr-3">
        <div class="col-md-12 p-1">
          <div class="table-responsive">
            <b-table
              id="table"
              :items="$store.state.backtest_orders"
              :fields="fields"
              striped
              hover
              small
              borderless
              :busy="$store.state.backtest_orders_loading"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
            >
              <template #cell(token)="row">
                <div
                  class="description pointer"
                  v-b-tooltip.hover
                  :title="row.item.token"
                  @click="handleCopyToken(row.item.token)"
                >
                  {{ row.item.token.substr(0, 13) + "..." }}
                </div>
              </template>

              <template #cell(method)="row">
                <div
                  :class="[
                    'description mono',
                    row.item.type == 0 ? 'text-danger' : 'text-success',
                  ]"
                >
                  {{ row.item.type === 0 ? "SELL" : "BUY" }}
                </div>
              </template>

              <template
                #cell(type)="value"
                v-if="
                  $route.name.toLowerCase() == 'investor' ||
                  $route.name.toLowerCase() == 'investors'
                "
              >
                <div v-if="$route.name.toLowerCase() !== 'investors'">
                  {{ isSpot ? "Spot" : "Future" }}
                </div>
                <div v-else>
                  {{ value.item.market_type }}
                </div>
              </template>

              <template #cell(status)="row">
                <div
                  class="description mono text-muted"
                  v-if="row.item.status === 4"
                >
                  CLOSED
                </div>
                <div
                  class="description mono text-success"
                  v-if="row.item.status === 2"
                >
                  OPEN
                </div>
                <div
                  class="description mono text-muted"
                  v-if="row.item.status === 1"
                >
                  PENDING
                </div>
                <div
                  class="description mono text-danger"
                  v-if="row.item.status === 8"
                >
                  CANCELLED
                </div>
              </template>

              <template #cell(signal_price)="row">
                <div
                  class="description mono"
                  v-b-tooltip.hover
                  :title="'In the date: ' + row.item.signal_date_at"
                >
                  {{ $helpers.numberFormat(row.item.signal_price) }}
                </div>
              </template>

              <template #cell(open_price)="row">
                <div
                  class="description mono"
                  v-b-tooltip.hover
                  :title="'In the date: ' + row.item.open_date_at"
                >
                  {{ $helpers.numberFormat(row.item.open_price.toFixed(6)) }}
                </div>
              </template>

              <template
                #cell(open_date_at)="row"
                v-if="$route.name.toLowerCase() == 'investor'"
              >
                <div class="description mono" v-b-tooltip.hover>
                  {{ row.item.open_date_at.substr(0, 19) }}
                </div>
              </template>

              <template #cell(lot_open)="row">
                <div
                  class="description mono"
                  v-b-tooltip.hover
                  :title="
                    'Conversion lot: ' +
                    $helpers.numberFormat(row.item.perdomance_open_lot)
                  "
                >
                  {{ $helpers.numberFormat(row.item.open_lot) }}
                </div>
              </template>

              <template #cell(close_price)="row">
                <div
                  class="description mono"
                  v-b-tooltip.hover
                  :title="'In the date: ' + row.item.open_date_at"
                >
                  {{ $helpers.numberFormat(row.item.close_price) }}
                </div>
              </template>

              <template
                #cell(close_date_at)="row"
                v-if="$route.name.toLowerCase() == 'investor'"
              >
                <div class="description mono" v-b-tooltip.hover>
                  {{ row.item.close_date_at.substr(0, 19) }}
                </div>
              </template>

              <template #cell(lot_close)="row">
                <div
                  class="description"
                  v-b-tooltip.hover
                  :title="
                    'Conversion lot: ' +
                    $helpers.numberFormat(row.item.perfomance_close_lot)
                  "
                >
                  {{ $helpers.numberFormat(row.item.close_lot) }}
                </div>
              </template>

              <template #cell(time_running)="row">
                <div class="description mono">
                  {{
                    handleDate(
                      row.item.status,
                      row.item.signal_date_at,
                      row.item.close_date_at
                    )
                  }}
                </div>
              </template>

              <template #cell(comissions)="row">
                <div class="description mono">
                  {{ $helpers.numberFormat(row.item.total_commission) }}
                </div>
              </template>

              <template #cell(profits)="row">
                <div
                  class="description mono"
                  v-if="row.item.status == 4 || row.item.status == 2"
                >
                  {{ $helpers.numberFormat(row.item.profit_coin) }}

                  <span
                    :class="[
                      'badget ',
                      row.item.profit_percentage > 0
                        ? 'badget-success'
                        : 'badget-danger',
                    ]"
                  >
                    {{ $helpers.numberToFixed(row.item.profit_percentage, 2) }}%
                  </span>
                </div>
                <div class="description mono" v-else>---</div>
              </template>
              <template #cell(options)="value">
                <template v-if="$route.name.toLowerCase() == 'investors'">
                  <div
                    class="d-block admin-menu"
                    v-if="$store.state.user.role_id === 1"
                  >
                    <span
                      v-b-tooltip.hover
                      title="Show json order"
                      class="pointer"
                      @click="showOrders(value.item.token, value.item)"
                    >
                      <b-icon-braces></b-icon-braces>
                    </span>
                    <span
                      v-b-tooltip.hover
                      title="Show logs"
                      class="ml-2 pointer"
                      @click="showOrders(value.item.token, value.item, true)"
                    >
                      <b-icon-list-check></b-icon-list-check>
                    </span>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="d-block admin-menu"
                    v-if="
                      $store.state.user.role_id === 1 &&
                      $route.name.toLowerCase() != 'contributor'
                    "
                  >
                    <span
                      v-b-tooltip.hover
                      title="Remove order"
                      class="pointer mr-2"
                      @click="handleRemoveOneOrder(value.item.token)"
                      v-if="$route.name.toLowerCase() == 'investor'"
                    >
                      <b-icon-x></b-icon-x>
                    </span>
                    <span
                      v-b-tooltip.hover
                      title="Show json order"
                      class="pointer"
                      @click="showOrders(value.item.token)"
                    >
                      <b-icon-braces></b-icon-braces>
                    </span>
                    <span
                      v-b-tooltip.hover
                      title="Show logs"
                      class="ml-2 pointer"
                      @click="showOrders(value.item.token, true)"
                    >
                      <b-icon-list-check></b-icon-list-check>
                    </span>
                  </div>
                </template>
              </template>
              <template #table-busy>
                <b-skeleton-table
                  :rows="5"
                  :columns="4"
                  :table-props="{ striped: true }"
                ></b-skeleton-table>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="$store.state.backtest_orders_paginate.records"
              :limit="
                $route.name.toLowerCase() == 'investors'
                  ? 5
                  : $store.state.backtest_orders_paginate.per_page
              "
              :per-page="$store.state.backtest_orders_paginate.per_page"
              class="mt-2 p-2"
              @page-click="linkGen"
              v-if="$store.state.backtest_orders_paginate.total_pages > 0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="modal-show-json"
      id="modal-show-json"
      hide-footer
      hide-header
      size="xl"
      ok-only
    >
      <div class="modal-create-conditions">
        <h3 class="modal-title d-flex justify-content-between">
          <span v-if="!showLog"> Show order </span>
          <span v-else>Show logs</span>
        </h3>
        <input
          type="text"
          class="form-control d-block mb-2"
          v-model="filter_values_json"
          placeholder="Filter logs..."
          id="filterValuesJsonOrders"
        />
        <div class="row">
          <div class="col-md-11 pr-0">
            <ViewJson :jsonData="json_order" :filter="filter" />
          </div>
          <div class="col-md-1">
            <span
              class="pointer"
              style="font-size: 22px"
              v-b-tooltip.hover
              title="Copy one or more conditions"
              @click.prevent="
                $helpers.copyValues(JSON.stringify(json_order), _vue, EventBus)
              "
            >
              <b-icon-paperclip></b-icon-paperclip>
            </span>
          </div>
        </div>
      </div>
    </b-modal>

    <TableBlendedOrder
      v-if="order_blended.accum_orders"
      :orders_blended="order_blended.accum_orders"
      :orders_blended_profits="order_blended.profits_by_orders_blended"
      :order_token="order_blended.token"
      ref="table_blended_order"
    />
  </div>
</template>

<script>
import moment from "moment";
import ViewJson from "@/components/commons/ViewJson";
import TableBlendedOrder from "@/components/pages/investors/TableBlendedOrder";

export default {
  name: "OrdersTable",
  props: {
    showOpenOrders: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ViewJson,
    TableBlendedOrder,
  },
  created() {
    if (
      this.$store.state.backtest_detail &&
      this.$store.state.backtest_detail.symbol
    ) {
      this.baseSymbol = this.$store.state.backtest_detail.symbol;
    } else if (this.$store.state.info_investor.symbol_base) {
      this.baseSymbol = this.$store.state.info_investor.symbol_base;
    }

    this.screensize = window.innerWidth;
    if (this.$route.name.toLowerCase() == "investors") {
      this.fields = [
        { key: "token", sortable: true },
        { key: "investor_token", label: "investor token", sortable: true },
        { key: "method", sortable: true },
        { key: "type", sortable: true },
        { key: "symbol", sortable: false },
        { key: "status", sortable: true },
        { key: "signal_price", sortable: true },
        { key: "open_price", sortable: true },
        { key: "open_date_at", sortable: true },
        { key: "lot_open", sortable: true },
        { key: "close_price", sortable: true },
        { key: "close_date_at", sortable: true },
        { key: "lot_close", sortable: true },
        { key: "time_running", sortable: true },
        { key: "profits", sortable: true },
        { key: "last_update", sortable: false },
        { key: "options", sortable: false },
      ];
      this.status.open = true;
      this.status.pending = false;
      this.status.cancelled = false;
      this.status.close = false;
      this.sortBy = "desc_close.date";
      return;
    }

    if (
      this.$route.name.toLowerCase() == "investor" ||
      this.$route.name.toLowerCase() == "subinvestor" ||
      this.$route.name.toLowerCase() == "contributor"
    ) {
      this.fields = [
        { key: "token", sortable: true },
        { key: "method", sortable: true },
        { key: "type", sortable: true },
        { key: "status", sortable: true },
        { key: "signal_price", sortable: true },
        { key: "open_price", sortable: true },
        { key: "open_date_at", sortable: true },
        { key: "lot_open", sortable: true },
        { key: "close_price", sortable: true },
        { key: "close_date_at", sortable: true },
        { key: "lot_close", sortable: true },
        { key: "time_running", sortable: true },
        { key: "profits", sortable: true },
        { key: "last_update", sortable: false },
        { key: "options", sortable: false },
      ];
      this.status.open = false;
      this.status.pending = false;
      this.status.cancelled = false;
      this.status.close = true;
      if (this.showOpenOrders) {
        this.status.open = true;
        this.status.close = false;
      }
      this.sortBy = "desc_close.date";
    } else if (
      this.$store.state.token_backtest_detail &&
      this.$store.state.condition_token_selected
    ) {
      this.getOrders();
      setTimeout(() => {
        document.querySelector(".admin_content").scrollTo(0, 1500);
      }, 500);
    }
  },
  mounted() {
    this.addEventSeachOrder();
    this.addEventSeachJsonOrder();
  },
  data() {
    return {
      baseSymbol: "btcusdt",
      sortBy: "desc_open.date",
      sortDesc: true,
      search: "",
      filter_values_json: "",
      order_blended: {},
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
      <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
      </svg> Error`,
      filter: "",
      fields: [
        { key: "token", sortable: true },
        { key: "type", sortable: true },
        { key: "status", sortable: true },
        { key: "signal_price", sortable: true },
        { key: "open_price", sortable: true },
        { key: "lot_open", sortable: true },
        { key: "close_price", sortable: true },
        { key: "lot_close", sortable: true },
        { key: "time_running", sortable: true },
        { key: "profits", sortable: true },
        { key: "options", sortable: false },
      ],
      items: [],
      perPage: 15,
      currentPage: 1,
      isLoading: false,
      json_order: {},
      showLog: false,
      isSpot: true,
      status: {
        open: true,
        close: true,
        pending: true,
        cancelled: false,
      },
      screensize: 0,
    };
  },
  methods: {
    getSymbol(order) {
      let routeName = this.$route.name.toLowerCase();
      if (
        routeName == "investor" ||
        routeName == "subinvestor" ||
        routeName == "contributor" ||
        routeName == "investors"
      ) {
        return order.symbol ? order.symbol.toUpperCase() : "--";
      } else if (
        routeName == "backtestingdetailwithcondition" ||
        routeName == "backtesting"
      ) {
        if (order.symbol) {
          return order.symbol.toUpperCase();
        }
        return this.$store.state.backtest_detail.symbol.toUpperCase();
      }
    },
    addEventSeachOrder() {
      const myInput2 = document.getElementById("searchOrder");
      if (!myInput2) {
        setTimeout(() => {
          this.addEventSeachOrder();
        }, 500);
      } else {
        myInput2.addEventListener(
          "keyup",
          this.$debounce(() => {
            this.getOrders();
          })
        );
      }
    },
    addEventSeachJsonOrder() {
      const myInput = document.getElementById("filterValuesJsonOrders");
      if (!myInput) {
        setTimeout(() => {
          this.addEventSeachJsonOrder();
        }, 500);
      } else {
        myInput.addEventListener(
          "keyup",
          this.$debounce(() => {
            this.filter = this.filter_values_json;
          })
        );
      }
    },
    getOrders(page = 1) {
      let sort = this.sortBy.split("_");
      let conditions = [];
      let is_compound = 0;
      let condition_token = null;
      let backtest_token = "";
      let investor_token = "";
      let is_investor = false;
      let is_subinvestor = false;
      let is_contributor = false;
      let several_investors = false;

      if (this.$store.state.condition_token_selected) {
        condition_token = this.$store.state.condition_token_selected;
      } else if (this.$store.state.algorithm_token_selected) {
        condition_token = this.$store.state.algorithm_token_selected;
      }

      if (this.$store.state.token_backtest_detail) {
        backtest_token = this.$store.state.token_backtest_detail;
      }
      if (
        this.$route.name.toLowerCase() === "investor" &&
        this.$route.params.token
      ) {
        investor_token = this.$route.params.token;
        is_investor = true;
        is_subinvestor = false;
      }
      if (
        this.$route.name.toLowerCase() === "subinvestor" &&
        this.$route.params.token
      ) {
        investor_token = this.$route.params.token;
        is_investor = false;
        is_subinvestor = true;
      }

      if (
        this.$route.name.toLowerCase() === "contributor" &&
        this.$route.params.token
      ) {
        investor_token = this.$route.params.token;
        is_investor = false;
        is_subinvestor = false;
        is_contributor = true;
      }

      if (
        this.$store.state.backtest_detail.compound &&
        this.$store.state.backtest_detail.compound.length > 0
      ) {
        conditions = this.$store.state.backtest_detail.compound.filter(
          (c) =>
            c.parent === this.$store.state.condition_token_selected ||
            c.child === this.$store.state.condition_token_selected
        );
        is_compound = 1;
      }

      let status = [];
      Object.keys(this.status).forEach((i) => {
        if (this.status[i] === true) {
          if (i == "open") {
            status.push(2);
          } else if (i == "close") {
            status.push(4);
          } else if (i == "cancelled") {
            status.push(8);
            status.push(10);
            status.push(12);
          } else if (i == "pending") {
            status.push(1);
          }
        }
      });

      let data = {
        vm: this,
        is_investor: is_investor,
        is_subinvestor: is_subinvestor,
        is_contributor: is_contributor,
        several_investors: several_investors,
        page: page,
        limit: this.perPage,
        sort_by: sort[1].replace(".", "_"),
        order: sort[0].toUpperCase(),
        backtest_token: backtest_token,
        investor_token: investor_token,
        is_compound: is_compound,
        conditions:
          conditions.length > 0
            ? JSON.stringify({
                parent: conditions[0].parent,
                child: conditions[0].child,
              })
            : "[]",
        status: JSON.stringify(status),
      };

      if (condition_token) {
        data["condition_token"] = condition_token;
      }

      if (
        this.$route.name.toLowerCase() === "investor" &&
        this.$route.params.token
      ) {
        data.is_manual =
          this.$store.state.info_investor.info.investor.manual_order;
        if (data.is_manual == 0) {
          data.comissions =
            this.$store.state.info_investor.conditions[0].condition.base_configuration.comission;
        } else {
          data.comissions = 100;
        }

        if (
          this.$store.state.info_investor.info.investor.configurations ==
            "{}" ||
          this.$store.state.info_investor.info.investor.configurations == ""
        ) {
          this.isSpot = true;
        } else {
          if (
            typeof this.$store.state.info_investor.info.investor
              .configurations == "string"
          ) {
            let configuration = JSON.parse(
              this.$store.state.info_investor.info.investor.configurations
            );
            this.isSpot = configuration.market_type == "spot";
          } else {
            this.isSpot = false;
          }
        }
      }

      if (
        this.$route.name.toLowerCase() === "subinvestor" &&
        this.$route.params.token
      ) {
        data.token = data.investor_token;
        delete data.investor_token;
      }

      if (
        this.$route.name.toLowerCase() === "contributor" &&
        this.$route.params.token
      ) {
        data.token = data.investor_token;
        delete data.investor_token;
      }

      if (this.search) {
        data["search"] = this.search;
      }
      if (this.$route.name.toLowerCase() === "investors") {
        data.comissions = "";
        data.investors_tokens = JSON.stringify(
          this.$store.state.investors_tokens
        );
        data.several_investors = true;
        data.limit = 50;
        let payload = { ...data };
        payload.status = JSON.stringify([2]);
        const body = {
          vm: this,
          data: {
            investors_tokens: JSON.stringify(
              this.$store.state.investors_tokens
            ),
          },
        };
        // this.$store.dispatch('bwfs_get_investors_orders_opened', payload);
        if (this.showOpenOrders) {
          data.limit = 25;
        }
        this.$store.dispatch("bwfs_get_investors_sockets", body);
      }
      this.$store.commit("bwfs_get_condtion_orders_backtest", data);
    },
    linkGen(event, pageNum) {
      if (this.$store.state.backtest_orders_loading) {
        return;
      }
      this.currentPage = pageNum;
      this.getOrders(pageNum);
    },
    handleCopyToken(token) {
      this.$helpers.copyTextToClipboard(token, this);
    },
    handleNumber(number) {
      return number;
    },
    handleDate(status, signal_date_at, close_date_at) {
      if (status === 2 || status === 1) {
        close_date_at = new Date().toISOString().replace("T", " ");
      }
      const updated = moment(new Date(signal_date_at.split(".")[0]));
      let time;
      try {
        time = moment(new Date(close_date_at.split(".")[0]));
      } catch (e) {
        return "---";
      }

      if (time.diff(updated, "DAYS") > 0) {
        return `${time.diff(updated, "DAYS")} D`;
      }
      if (time.diff(updated, "hours") > 0) {
        return `${time.diff(updated, "hours")} H`;
      }
      if (time.diff(updated, "MINUTES") > 0) {
        return `${time.diff(updated, "MINUTES")} M`;
      }
      if (time.diff(updated, "SECONDS") > 0) {
        return `${time.diff(updated, "SECONDS")} S`;
      }
      return "---";
    },
    showOrders(token, condition_token, showLog = false) {
      this.showLog = showLog;
      let routeName = this.$route.name.toLowerCase();
      let data = {
        token_backtest: this.$store.state.token_backtest_detail,
        token_condition: condition_token,
        token_order: token,
        token_investor:
          routeName === "investor"
            ? this.$store.state.info_investor.info.token
            : "",
      };
      if (routeName === "investors") {
        data.token_condition = condition_token.condition_token;
        data.token_investor = condition_token.investor_token;
      }

      let server = "server2";

      if (
        routeName === "investor" ||
        routeName === "investors" ||
        routeName == "subinvestor" ||
        routeName == "contributor"
      ) {
        server = "server1";
      }

      if (routeName === "subinvestor") {
        data.token_investor = this.$store.state.SubInvestorInfo.info.token_user;
      } else if (routeName === "contributor") {
        data.token_investor = this.$store.state.contributorInfo.info.token_user;
      }

      let filters = {};
      if (localStorage.getItem("filters_backtest")) {
        filters = JSON.parse(localStorage.getItem("filters_backtest"));
      }
      if (!filters.endpoint) {
        filters.endpoint = "production";
      }

      this.$http[server](
        "bwfs/admin/backtest/order/json",
        data,
        {},
        filters.endpoint
      )
        .then((res) => {
          if (res.error) {
            this.json_order = {};
            this.$toastr.error(res.message, `${this.error}`);

            return;
          }
          let json = res.data;
          if (!json) {
            this.$toastr.error("No results found.", `${this.error}`);

            return;
          }
          if (showLog) {
            json = json.log;
          } else {
            delete json.log;
          }
          delete json.micro_pump_prices;
          this.json_order = json;
          this.$bvModal.show("modal-show-json");
        })
        .catch((e) => {
          this.json_order = {};
          this.$toastr.error(e.message, `${this.error}`);
        });
    },
    toggleStatus(field) {
      this.status[field] = !this.status[field];
      this.getOrders(1);
    },
    getType(value) {
      return "type";
    },
    handleRemoveOneOrder(token) {
      if (!confirm("Are sure?")) {
        return;
      }
      const data = {
        token_order: token,
      };
      this.$http
        .server1("bwfs/admin/investor/remove/oneorder", data)
        .then((res) => {
          if (res.error) {
            this.$toastr.error(res.message, `${this.error}`);

            return;
          }
          this.$toastr.error(res.message, `${this.error}`);
        })
        .catch((e) => {
          this.$toastr.error(e.message, `${this.error}`);
        });
    },
    showBlendedOrders(order) {
      this.order_blended = order;
      setTimeout(() => {
        this.$refs.table_blended_order.showModal();
      }, 500);
    },
  },
  computed: {
    orderTest() {
      return this.$store.state.backtest_orders;
    },
  },
  watch: {
    "$store.state.token_backtest_detail"(value) {
      this.$store.commit("bwfs_reset_condtion_orders_backtest");
      if (value) {
        document.querySelector(".admin_content").scrollTo(0, 1500);
      }
    },
    "$store.state.condition_token_selected"(value) {
      if (this.$store.state.token_backtest_detail) {
        this.getOrders();
      }
    },
    sortBy() {
      if (
        this.$store.state.info_investor.info ||
        this.$store.state.SubInvestorInfo.info ||
        this.$store.state.contributorInfo.info
      ) {
        this.getOrders();
      }
    },
    "$store.state.info_investor"(value) {
      let routeName = this.$route.name.toLowerCase();
      if (routeName !== "supervisor") {
        this.getOrders();
      }
    },
    "$store.state.SubInvestorInfo"(value) {
      this.getOrders();
    },
    "$store.state.contributorInfo"(value) {
      this.getOrders();
    },
    "$store.state.search_order_token"(value) {
      this.search = value;
      this.getOrders();
      document.querySelector(".admin_content").scrollTo(0, 1500);
    },
    "$store.state.orders_tracking_search"(value) {
      if (value) {
        this.getOrders();
        this.$store.commit("set_orders_tracking_search", false);
      }
    },
    showOpenOrders(value) {
      this.status.open = true;
      this.status.pending = false;
      this.status.cancelled = false;
      this.status.close = false;
      this.getOrders();
    },
  },
  filters: {
    parseSeconds(seconds) {
      if (seconds > 60) {
        seconds /= 60;
        return `${parseInt(seconds)} Minute(s)`;
      }
      return `${seconds} Second(s)`;
    },
    algorithmToken(token, investor) {
      if (!investor || !investor.conditions) {
        return `${token.substr(0, 13)}...`;
      }
      let condition = investor.conditions.find((e) => e.token == token);
      if (
        condition &&
        condition.condition &&
        Object.keys(condition.condition).indexOf("name")
      ) {
        return condition.condition.name;
      }
      return `${token.substr(0, 13)}...`;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/commons/orders_table_component.scss";
</style>

<style>
@media screen and (min-width: 992px) {
  .orders-desktop {
    display: block;
    overflow-x: auto;
  }
  .page__investor .sidebar__user_closed_orders .order {
    display: flex;
  }
  .orders-movil {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .orders-desktop {
    display: none;
  }
  .orders-movil {
    display: block;
  }
}

.orders_table_component .content_block_item.colum_close_price {
  width: 120px;
}
</style>
