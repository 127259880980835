<template>
  <div>
    <div class="col-md-12">
      <div class="text-muted">
          Token:
      </div>
      <div class="description" @click="copyToken(c)">
          {{$props.token}}
      </div>
    </div>
    <template v-if="isStatic || loading">
    <div class="col-md-12 mb-2 mt-2">
      <div class="text-muted">
        Static
      </div>
    </div>
    <div :class="!isStatic ?'col-md-12 mt-2' :'col-md-12'">
        <div class="table-responsive nice-scroll">
          <b-table
            :items="isStatic ? staticItems : []"
            :fields="fieldsStatic"
            responsive
            :busy="loading"
            show-empty
            empty-text="No conditions found"
          >
            <template #table-busy>
              <b-skeleton-table
                :rows="1"
                :columns="5"
                :table-props="{  striped: true }"
              >
              </b-skeleton-table>
            </template>
          </b-table>
        </div>
      </div>
      </template>
    <template v-if="isDynamic || loading">
    <div class="col-md-12 mb-2">
      <div class="text-muted">
        Dynamic
      </div>
    </div>
     <div class="col-md-12 item-data">
        <div class="table-responsive nice-scroll">
          <b-table
            :items="isDynamic ? dynamicItems : []"
            :fields="fieldsDynamic"
            responsive
            :busy="loading"
            show-empty
            empty-text="No conditions found"
          >
          <template #table-busy>
              <b-skeleton-table
                :rows="1"
                :columns="5"
                :table-props="{  striped: true }"
              >
              </b-skeleton-table>
            </template>
          </b-table>
        </div>
      </div>
      </template>
  </div>
</template>

<<script>
export default {
  name: 'TableConditions',
  props: ['token', 'items', 'loading'],
  data() {
    return {
      fieldsStatic: [
        'type',
        'max_price',
        'min_price',
        'ticks_in',
        'ticks_out',
        'btc_total',
        'btc_in',
        'btc_out',
        'btc_in_out',
        'avg_in',
        'avg_out',
        'start',
        'end',
      ],
      fieldsDynamic: [
        'type',
        'max_price',
        'min_price',
        'ticks_in',
        'ticks_out',
        'btc_total',
        'btc_in',
        'btc_out',
        'btc_in_out',
        'avg_in',
        'avg_out',
        'start',
        'end',
        'buyers_vs_sellers',
      ],
    }
  },
  methods: {
    copyToken(token) {
			this.$helpers.copyTextToClipboard(token, this);
		},
  },
  computed: {
    isStatic() {
      if (typeof this.items == 'object') return this.items.hasOwnProperty('static');
      return false;
    },
    isDynamic() {
      if (typeof this.items == 'object') return this.items.hasOwnProperty('dynamic');
      return false;
    },
    staticItems() {
      if (this.isStatic) {
        let items = [];
        let short = {};
        let medium = {};
        let long = {};
        let mega_long = {};
        short = this.items.static.short;
        short.type = 'Short';
        medium = this.items.static.medium;
        medium.type = 'Medium';
        long = this.items.static.long;
        long.type = 'Long';
        mega_long = this.items.static.mega_long;
        mega_long.type = 'Mega long';
        items.push(short);
        items.push(medium);
        items.push(long);
        items.push(mega_long);
        return items;
      }
      return [];
    },
    dynamicItems() {
      if (this.isDynamic) {
        let items = [];
        let short = {};
        let medium = {};
        short = this.items.dynamic.short;
        short.type = 'Short';
        medium = this.items.dynamic.medium;
        medium.type = 'Medium';
        items.push(short);
        items.push(medium);
        return items;
      }
      return [];
    }
  },
}
</script>

<style scoped lang="scss">


.fontOrders {
  font-size: 12px;
}

</style>