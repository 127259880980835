<template>
  <b-modal  size="xl" title="Open Orders" v-model="show"  hide-footer ok-only @hide="closeModal">
    <div :class="`page__${$route.name.toLowerCase()}`">
    <b-row class="px-3 py-0 sidebar__user_closed_orders nice-scroll">    
      <b-col cols="12 p-0" lg="12">
        <OrdersTable 
          :showOpenOrders="show"
        />
       </b-col>
    </b-row>
    </div>
  </b-modal>
</template>

<script>
import OrdersTable from '../../../commons/OrdersTable.vue';

export default {
    name: 'OpenOrdersModal',
    props: ['show'],
    components: {
        OrdersTable,
    },
    methods: {
      closeModal() {
        this.$emit('closeModal')
      }
    }
}
</script>

<style scoped>
::v-deep .modal-title {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0px;
  }
  @media (min-width: 1200px) {

    ::v-deep .modal-xl {
      max-width: 1200px;
    }
  }

</style>