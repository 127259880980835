import state from "@/helpers/store/state";
import set from "@/helpers/store/set";

import create_portfolio from "./actions/create_portfolio";
import delete_portfolio from "./actions/delete_portfolio";
import portfolio_list from "./actions/portfolio_list";
import portfolio_list_preview from "./actions/portfolio_list_preview";
import update_portfolio from "./actions/update_portfolio";

import set_creation_form from "./mutations/set_creation_form";
import set_current_balance from "./mutations/set_current_balance";
import set_investor_token from "./mutations/set_investor_token";

import data_array_adapter from "../../services/adapters/common/adapter_data_array";
import form_portfolio_adapter from "../../services/adapters/form_portfolio";
import message_response_adapter from "../../services/adapters/common/adapter_message";

export default {
  namespaced: true,
  state: () => ({
    create_portfolio: state(message_response_adapter),
    current_balance: null,
    delete_portfolio: state(message_response_adapter),
    form_portfolio: { ...form_portfolio_adapter },
    investor_token: null,
    portfolio_list: state(data_array_adapter),
    portfolio_list_preview: state(data_array_adapter),
    update_portfolio: state(message_response_adapter),
  }),
  actions: {
    create_portfolio,
    delete_portfolio,
    portfolio_list,
    portfolio_list_preview,
    update_portfolio,
  },
  mutations: {
    set,
    set_creation_form,
    set_current_balance,
    set_investor_token,
  },
};
