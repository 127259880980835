import random_string from "../../../helpers/generators/random_string";
import random_date from "../../../helpers/generators/random_date";

export default () => {
  return {
    error: false,
    data: {
      token: random_string(25),
      name: "Mock group name",
      secret_key: random_string(150),
      api_key: random_string(250),
      created_at: random_date("YYYY-MM-DD h:mm:ss"),
      updated_at: random_date("YYYY-MM-DD h:mm:ss"),
    },
  };
};
