import random_number from "../../../helpers/generators/random_number";
import random_string from "../../../helpers/generators/random_string";
import random_date from "../../../helpers/generators/random_date";

export default () => {
  const names = ["Neo LMDD", "LMDD", "BWFS"];
  const investor_names = [
    "Investor 1",
    "Investor 2",
    "Investor 3",
    "Investor 4",
  ];
  const name = names[random_number(0, names.length - 1)];
  const token = random_string(25);
  const investor_count = random_number(0, 9);
  let investors = [];
  for (let i = 0; i < investor_count; i++) {
    investors.push({
      name: investor_names[random_number(0, investor_names.length - 1)],
      token: random_string(25),
    });
  }
  const created_at = random_date("MM/DD/YYYY, h:mm:ss");
  const updated_at = random_date("MM/DD/YYYY, h:mm:ss");

  return {
    name,
    token,
    investors,
    created_at,
    updated_at,
  };
};
