<template>
	<b-modal id="modal-balances" hide-footer hide-header size="sm" ok-only>
		<h3 class="modal-title">
      Balances
    </h3>
    <div class="modal-body p-0">
			<BalancesDetailInvestors :showTitle="false"/>
    </div>
  </b-modal>
</template>
<script>
	import BalancesDetailInvestors from '@/components/pages/investors/BalancesDetailInvestors';
	export default {
		name: 'ShowBalances.vue',
		components:{
			BalancesDetailInvestors,
		}
	}
</script>