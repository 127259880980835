<template>
  <div class="container-fluid">
    <div class="module-content module-investors">
      <div class="row">
        <HeaderTopBar
          :open-investor-modal="openInvestorModal"
          :reload-investors="reloadInvestors"
          :show-reload-button="showReloadButton"
        />
      </div>
      <Filters :reload="reload" />
      <InvestorsTable
        :open-investor-modal="openInvestorModal"
        :open-deposit-modal="openDepositModal"
        @reload="showReloadButtonUpdate"
      />
    </div>
    <Investor :isEdit="is_edit_investor" ref="ref_investor_modal" />
    <TransactionModal ref="ref_transaction_modal" />
  </div>
</template>

<script>
import TransactionModal from "../components/pages/investors_list/modals/Transaction.vue";
import Filters from "../components/pages/investors_list/Filters.vue";
import HeaderTopBar from "../components/pages/investors_list/HeaderTopBar.vue";
import Investor from "../components/pages/investors_list/modals/Investor.vue";
import InvestorsTable from "../components/pages/investors_list/InvestorsTable.vue";
import "@vueform/multiselect/themes/default.css";
import { mapState } from "vuex";

export default {
  name: "Investors",
  components: {
    TransactionModal,
    HeaderTopBar,
    Investor,
    InvestorsTable,
    Filters,
  },
  data() {
    return {
      reload: false,
      showReloadButton: false,
    };
  },
  methods: {
    openDepositModal() {
      this.$refs["ref_transaction_modal"].show();
    },
    openInvestorModal() {
      this.$refs["ref_investor_modal"].show();
    },
    reloadInvestors() {
      this.reload = true;
      setTimeout(() => {
        this.reload = false;
      }, 200);
    },
    showReloadButtonUpdate(value) {
      this.showReloadButton = value;
    },
  },
  computed: {
    ...mapState("investors_v2", ["is_edit_investor"]),
  },
  mounted() {},
};
</script>
<style lang="scss">
@import "../styles/pages/investors_list.scss";
</style>
