import number_formatter from "../../../helpers/formatters/number_formatter";
import random_float from "../../../helpers/generators/random_float";
import random_number from "../../../helpers/generators/random_number";
import random_string from "../../../helpers/generators/random_string";
import random_date from "../../../helpers/generators/random_date";

export default () => {
  const statuses = [1, 2, 4, 8];
  const names = [
    "John Doe",
    "Martin Sutter",
    "Daniele Sinacori",
    "Alessio Vinassa",
    "Cayman C&B Digital",
  ];

  const investor_name = names[random_number(0, names.length - 1)];
  const live_nav = number_formatter(random_number(0, 100000), 2);
  const exposure_quantity = number_formatter(random_number(0, 100000), 2);
  const exposure_percentage = number_formatter(random_number(0, 100000), 2);
  const live_pnl_quantity = number_formatter(random_number(0, 100000), 2);
  const live_pnl_percentage = number_formatter(random_number(0, 100000), 2);
  const performance_mtd = number_formatter(random_number(0, 100000), 2);
  const performance_ytd = number_formatter(random_number(0, 100000), 2);
  const performance_since_inception = number_formatter(
    random_number(0, 100000),
    2
  );
  const inflows_outflows_mtd = number_formatter(random_number(0, 100000), 2);
  const inflows_outflows_ytd = number_formatter(random_number(0, 100000), 2);
  const inflows_outflows_since_inception = number_formatter(
    random_number(0, 100000),
    2
  );
  const high_water_mark = number_formatter(random_number(0, 100000), 2);
  const drawdown_from_high_water_mark = number_formatter(
    random_number(0, 100000),
    2
  );
  const status = statuses[random_number(0, statuses.length - 1)];

  return {
    drawdown_from_high_water_mark,
    exposure_quantity,
    exposure_percentage,
    high_water_mark,
    inflows_outflows_mtd,
    inflows_outflows_ytd,
    inflows_outflows_since_inception,
    investor_name: "John Doe",
    live_pnl_quantity,
    live_pnl_percentage,
    live_nav,
    performance_mtd,
    performance_ytd,
    performance_since_inception,
    status,
  };
};
