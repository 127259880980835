import random_number from "../../../helpers/generators/random_number";
import number_formatter from "../../../helpers/formatters/number_formatter";

export default () => {
  const nav = number_formatter(random_number(4000, 100000), 2);
  const performance_since_inception = number_formatter(
    random_number(4000, 100000),
    2
  );
  const performance_since_inception_percentage = number_formatter(
    random_number(0, 100),
    2
  );
  const performance_year_to_date = number_formatter(
    random_number(4000, 100000),
    2
  );
  const performance_year_to_date_percentage = number_formatter(
    random_number(0, 100),
    2
  );
  const performance_month_to_date = number_formatter(
    random_number(4000, 100000),
    2
  );

  const performance_month_to_date_percentage = number_formatter(
    random_number(0, 100),
    2
  );
  const exposure = number_formatter(random_number(4000, 50000), 2);
  const today_returns = number_formatter(random_number(4000, 50000), 2);
  const exposure_percentage = number_formatter(random_number(0, 100), 2);
  const upnl = number_formatter(random_number(100, 4000), 2);
  const upnl_percentage = number_formatter(random_number(0, 100), 2);

  return {
    nav,
    performance_since_inception,
    performance_since_inception_percentage,
    performance_year_to_date,
    performance_year_to_date_percentage,
    performance_month_to_date,
    performance_month_to_date_percentage,
    today_returns,
    exposure,
    exposure_percentage,
    upnl,
    upnl_percentage,
  };
};
