<template>
  <div class="header-bar-container">
    <div class="header-bar max-width">
      <div>
        <span class="breadcrumb-current" v-if="isLogged"> Investors </span>
      </div>
      <div class="filter-bars">
        <!-- <Multiselect
          class="w-bulkaction"
          label="name"
          placeholder="Bulk actions"
          :hideSelected="true"
          :options="bulkaction_options"
        >
          <template v-slot:caret>
            <span class="multiselect-caret">
              <img :src="icon_caret" alt="" />
            </span>
          </template>
        </Multiselect> -->
        <button v-if="user_role != 2" @click="openCreateInvestor">
          <span><img :src="icon_plus" alt="" /></span>New investor
        </button>
        <button v-if="showReloadButton" @click="reloadInvestors">Reload</button>
        <button class="d-none">Load users</button>
      </div>
    </div>
  </div>
</template>

<script>
import IconCaret from "../../../../public/common/select-arrow-down.svg";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2";
import PlusIcon from "../../../../public/common/plus-white-icon.svg";

export default {
  name: "HeaderTopBar",
  props: {
    openInvestorModal: {
      type: Function,
      default: () => {},
    },
    reloadInvestors: {
      type: Function,
      default: () => {},
    },
    showReloadButton: {
      type: Boolean,
    },
  },
  data() {
    return {
      bulkaction_options: [
        { value: "total_close", name: "Total close" },
        { value: "new_order", name: "New order" },
        {
          value: "close_profit_symbol",
          name: "Close orders by profit and symbol",
        },
        {
          value: "close_profit_all_symbol",
          name: "Close orders by profit all symbols.",
        },
        { value: "archive_account", name: "Archive account(s)" },
        { value: "unarchived_account", name: "Unarchive account(s)" },
        { value: "turn_on", name: "Turn on accounts" },
        { value: "turn_off", name: "Turn off accounts" },
      ],
      cant_investor: null,
      icon_caret: IconCaret,
      icon_plus: PlusIcon,
      isLogged: false,
      showInvestorModal: false,
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    user_role() {
      return this.$store.state.user ? this.$store.state.user.role_id : null;
    },
  },
  created() {
    const session = localStorage.getItem("userData");
    if (session && session !== "") {
      this.isLogged = true;
    }
  },
  methods: {
    openCreateInvestor() {
      this.$store.commit("investors_v2/set_edit_investor_modal", false);
      this.openInvestorModal();
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/pages/health_audit/header_top_bar.scss";
</style>
