export default () => {
  return {
    error: false,
    data: {
      token: "e1aca8d2cd02b114c6da08244c1c2202a14e0750f4418cdf2a09f752371306a1",
      symbol: "btcudt",
      current_quantity: 0.001,
      total_investment: 30.8988,
      open_date: 1689019263.80824,
      side: false,
      leverage: 1,
      commission: 0.01235952,
      logs: ["New lot recieved:-1.00%", "Leverage 1"],
      pending_information: {},
      take_profit: "-",
      stop_loss: "-",
      conditional_orders: [
        {
          type: "STOP_LOSS",
          nickname: "Jonathan",
          direction: "LESSER_EQUAL",
          symbol: "btcusdt",
          price: 21.508812342381862,
          side: false,
          qty: 0.1,
        },
      ],
    },
  };
};
