var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"notificationsModal",staticClass:"modal fade modal-notifications",attrs:{"id":"notificationsModal","tabindex":"-1","aria-hidden":"true","data-bs-backdrop":"static","data-bs-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Notifications")]),_c('span',{staticClass:"view-logs-modal-close",on:{"click":function($event){return _vm.modal.hide()}}},[_c('img',{attrs:{"src":_vm.close_icon}})])]),_c('div',{staticClass:"modal-body"},[(_vm.notifications.length)?_c('div',[(_vm.$store.state.user.token)?_c('div',{staticClass:"input-group mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_notification),expression:"search_notification"}],staticClass:"form-control float-right d-inline-block",attrs:{"type":"text","id":"searchNotificationInput","placeholder":"Search by message and date..."},domProps:{"value":(_vm.search_notification)},on:{"input":function($event){if($event.target.composing)return;_vm.search_notification=$event.target.value}}}),_c('span',{staticClass:"input-group-text no-border right dark",attrs:{"id":"search-form-icon"}},[_c('img',{attrs:{"src":_vm.search_icon,"alt":""}})])]):_vm._e(),(_vm.$store.state.investor_notifications_loading)?_c('b-skeleton-table',{attrs:{"rows":4,"columns":1,"table-props":{ striped: true }}}):_vm._e(),_vm._l((_vm.notifications),function(notification){return (
              _vm.notifications.length > 0 &&
              !_vm.$store.state.investor_notifications_loading
            )?_c('div',{staticClass:"item-data mb-2"},[_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(notification.msg ? notification.msg : notification)+" ")]),_c('div',{staticClass:"label small date"},[_vm._v(" "+_vm._s(notification.date ? notification.date.substr(0, 19) : notification.date)+" ")])]):_vm._e()}),(
              !_vm.$store.state.investor_notifications_loading &&
              _vm.notifications.length > 0
            )?_c('b-list-group-item',{key:'pgination',staticClass:"d-flex p-0"},[_c('b-button',{staticClass:"btn-sm mr-auto",attrs:{"variant":"dark","disabled":_vm.$store.state.investor_notifications_paginate.current_page -
                  1 ===
                0},on:{"click":function($event){return _vm.getNotifications(
                  _vm.$store.state.investor_notifications_paginate.current_page -
                    1
                )}}},[_c('b-icon-arrow-left')],1),_c('span',[_vm._v(_vm._s(_vm.$store.state.investor_notifications_paginate.current_page)+"/"+_vm._s(_vm.$store.state.investor_notifications_paginate.total_pages))]),_c('b-button',{staticClass:"btn-sm ml-auto",attrs:{"variant":"dark","disabled":_vm.$store.state.investor_notifications_paginate.current_page +
                  1 >
                _vm.$store.state.investor_notifications_paginate.total_pages},on:{"click":function($event){return _vm.getNotifications(
                  _vm.$store.state.investor_notifications_paginate.current_page +
                    1
                )}}},[_c('b-icon-arrow-right')],1)],1):_vm._e()],2):_c('div',[_c('AlertNoInformation')],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }