<template>
  <div>
    <div>
      <div
        ref="ApiKeyInfoModal"
        id="ApiKeyInfoModal"
        class="modal fade modal-create-connection"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content max-width">
            <div class="modal-header">
              <h5 class="modal-title">Key information</h5>
              <span class="view-logs-modal-close" @click="modal.hide()"
                ><img :src="close_icon"
              /></span>
            </div>
            <div class="modal-body">
              <div class="key-title">API Key</div>
              <div class="key-value">{{ keyInfo.api_key }}</div>
              <div class="key-title">Secret Key</div>
              <div class="key-value">
                {{ keyInfo.secret_key }}
                <span class="buttons copy" @click="copyToken"
                  ><img :src="copy" alt=""
                /></span>
                <span class="buttons"><img :src="eye_icon" alt="" /></span>
              </div>
              <div class="modal-buttons">
                <button @click="modal.hide()">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from "bootstrap";
import CloseIcon from "../../../assets/images/close.svg";
import CopyIcon from "../../../../public/common/copy-file.svg";
import EyeIcon from "../../../../public/common/eye-icon.svg";
export default {
  name: "ApiKeyInfoModal",
  mounted() {
    this.modal = new Modal(this.$refs.ApiKeyInfoModal);
  },
  props: {
    show: {
      type: Boolean,
    },
    keyInfo: {
      type: Object,
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.modal.show();
      } else {
        this.modal.hide();
      }
    },
  },
  data() {
    return {
      close_icon: CloseIcon,
      eye_icon: EyeIcon,
      copy: CopyIcon,
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
          <g clip-path="url(#clip0_1634_940)">
          <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
          </g>
          <defs>
          <clipPath id="clip0_1634_940">
          <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
          </clipPath>
          </defs>
          </svg> Success`,
    };
  },
  methods: {
    copyToken() {
      navigator.clipboard.writeText(this.keyInfo.secret_key);
      this.$toastr.success(
        "The token has been copied correctly.",
        `${this.success}`
      );
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/pages/investor_configuration/key_info_modal.scss";
</style>
