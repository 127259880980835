import number_formatter from "../../../helpers/formatters/number_formatter";
import random_date from "../../../helpers/generators/random_date";
import random_number from "../../../helpers/generators/random_number";
import random_string from "../../../helpers/generators/random_string";

export default () => {
  const names = [
    "Palm Jumeirah",
    "BWFS",
    "Holy Grail 24H_0",
    "Karama",
    "Blended",
    "NEO LMMD",
  ];

  const created_at = random_date("YYYY-MM-DD h:mm:ss");
  const current_balance = number_formatter(random_number(0, 100000), 1);
  const exchange_token = random_string(10);
  const group_token = random_string(10);
  const initial_balance = number_formatter(random_number(0, 100000), 1);
  const investor_token = random_string(10);
  const name = names[random_number(0, names.length - 1)];
  const token = random_string(10);
  const updated_at = random_date("YYYY-MM-DD h:mm:ss");

  return {
    created_at,
    current_balance,
    exchange_token,
    group_token,
    initial_balance,
    investor_token,
    name,
    token,
    updated_at,
  };
};
