import random_number from "../../../helpers/generators/random_number";
import random_string from "../../../helpers/generators/random_string";
import random_date from "../../../helpers/generators/random_date";

export default () => {
  const sides = ["Buy", "Sell"];
  const market_types = ["Futures", "Spot"];
  const token = random_string(25);
  const open_time = random_date("MM/DD/YYYY, h:mm:ss");
  const open_price = random_number(1000, 30000);
  const signal_price = random_number(1000, 30000);
  const open_lot = random_number(10, 300);
  const realized_pnl = random_number(10, 300);
  const realized_pnl_percentage = random_number(0, 100);
  const unrealized_pnl = random_number(-30000, 34400);
  const unrealized_pnl_percentage = random_number(-100, 100);
  const market_type = market_types[(Math.random() * market_types.length) | 0];
  const position_size = random_number(1000, 30000);
  const position_token = random_string(25);
  const side = sides[(Math.random() * sides.length) | 0];
  const symbol = "BTCUSDT";
  const created_at = random_date("MM/DD/YYYY, h:mm:ss");
  const commission = random_number(0, 100);
  const portfolio_name = "Deep Horizon";
  const allocation_exposure_percentage = random_number(0, 100);

  return {
    token,
    open_time,
    open_price,
    signal_price,
    open_lot,
    realized_pnl,
    realized_pnl_percentage,
    unrealized_pnl,
    unrealized_pnl_percentage,
    market_type,
    position_size,
    position_token,
    side,
    symbol,
    created_at,
    commission,
    portfolio_name,
    allocation_exposure_percentage,
  };
};
