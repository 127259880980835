<template>
  <div
    class="modal"
    ref="viewDetailsModal"
    :id="'viewDetailsModal' + typeOfOrder"
  >
    <div class="view-details-modal">
      <div class="view-details-modal-header">
        <span class="view-details-modal-title">Details</span>
        <span class="view-details-modal-close" @click="close"
          ><img :src="close_icon"
        /></span>
      </div>
      <div class="view-details-modal-content">
        <ul class="view-details-list">
          <li class="detail">
            <div class="label">Token</div>
            <div class="info" @click="copyToken(data.token)">
              <span>
                {{ token }} <img class="copy-icon" :src="copy_icon"
              /></span>
            </div>
          </li>
          <li class="detail">
            <div class="label">Strategy</div>
            <div class="info">{{ data.portfolio_name }}</div>
          </li>
          <li class="detail">
            <div class="label">Symbol</div>
            <div class="info">{{ data.symbol }}</div>
          </li>
          <li class="detail">
            <div class="label">Side</div>
            <div class="info">
              <span
                class="d-flex"
                :class="[data.side == 'Buy' ? 'green' : 'red']"
                ><span class="icon"
                  ><img
                    :src="data.side == 'Buy' ? arrow_up : arrow_down"
                    alt="" /></span
                >{{ data.side }}
              </span>
            </div>
          </li>
          <li class="detail">
            <div class="label">Type</div>
            <div class="info">{{ data.market_type }}</div>
          </li>
          <li class="detail">
            <div class="label">Status</div>
            <div class="info">
              <span
                class="d-flex"
                :class="{
                  red: data.status == 8,
                  gray: data.status == 4,
                  yellow: data.status == 1,
                  green: data.status == 2,
                }"
                >{{ getStatus(data.status) }}</span
              >
            </div>
          </li>
          <li class="detail">
            <div class="label">Slippage</div>
            <div class="info value">
              {{ data.signal_price - data.open_price }}
            </div>
          </li>
          <li class="detail">
            <div class="label">Signal price</div>
            <div class="info value">
              <span class="icon"><img :src="dollar" alt="" /></span
              >{{ formatter(data.signal_price) }}
            </div>
          </li>
          <li class="detail">
            <div class="label">Open time</div>
            <div class="info">{{ data.open_time }}</div>
          </li>
          <li class="detail">
            <div class="label">Open price</div>
            <div class="info value">
              <img :src="dollar" alt="" />{{ formatter(data.open_price) }}
            </div>
          </li>
          <li class="detail">
            <div class="label">Position size</div>
            <div class="info value">
              <img :src="dollar" alt="" />{{ formatter(data.position_size) }}
            </div>
          </li>
          <li class="detail">
            <div class="label">Lot open</div>
            <div class="info value">
              <img :src="dollar" alt="" /> {{ formatter(data.open_lot) }}
            </div>
          </li>
          <li v-show="typeOfOrder == 'Closed'" class="detail">
            <div class="label">Close price</div>
            <div class="info value">
              <img :src="dollar" alt="" /> {{ formatter(data.close_price) }}
            </div>
          </li>
          <li v-show="typeOfOrder == 'Closed'" class="detail">
            <div class="label">Close date</div>
            <div class="info">
              {{ data.close_time }}
            </div>
          </li>
          <li v-show="typeOfOrder == 'Closed'" class="detail">
            <div class="label">Lot close</div>
            <div class="info value">
              <img :src="dollar" alt="" /> {{ formatter(data.close_lot) }}
            </div>
          </li>
          <li v-show="typeOfOrder == 'Closed'" class="detail">
            <div class="label">Time running</div>
            <div class="info">
              {{ data.time_running }}
            </div>
          </li>
          <li class="detail">
            <div class="label">Last update</div>
            <div class="info">{{ data.updated_at }}</div>
          </li>
          <li v-show="typeOfOrder == 'Open'" class="detail">
            <div class="label">Unrealized PnL</div>
            <div class="info value">
              <span class="d-flex" :class="[colorStatus(data.unrealized_pnl)]"
                >$ {{ formatter(data.unrealized_pnl)
                }}<span class="dash">/</span>
                {{ formatter(data.unrealized_pnl_percentage) }} %</span
              >
            </div>
          </li>
          <li v-if="typeOfOrder == 'Closed'" class="detail">
            <div class="label">Realized PnL</div>
            <div class="info value">
              <span class="d-flex" :class="[colorStatus(data.realized_pnl)]"
                >$ {{ formatter(data.realized_pnl)
                }}<span class="dash">/</span>
                {{ formatter(data.realized_pnl_percentage) }} %</span
              >
            </div>
          </li>
          <li class="detail">
            <div class="label">Stop Loss</div>
            <div class="info">{{ formatPercentage(data.stop_loss) }}</div>
          </li>
          <li class="detail">
            <div class="label">Take Profit</div>
            <div class="info">{{ formatPercentage(data.take_profit) }}</div>
          </li>
        </ul>
      </div>
      <div class="view-details-modal-footer">
        <div class="view-details-close-button" @click="close">Close</div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import closeIcon from "../../../../assets/images/close.svg";
import copyIcon from "../../../../assets/images/copy.svg";
import ArrowUp from "../../../../../public/common/arrow-up.png";
import ArrowDown from "../../../../../public/common/arrow-down.png";
import Dollar from "../../../../../public/common/dollar.png";

import StatusOrderFormatter from "../../../../helpers/formatters/status_order_formatter";
import number_formatter from "../../../../helpers/formatters/number_formatter";

export default {
  name: "ViewDetailsModal",
  props: {
    data: {
      type: Object,
    },
    typeOfOrder: {
      type: String,
    },
  },
  data() {
    return {
      viewDetailsModal: null,
      close_icon: closeIcon,
      copy_icon: copyIcon,
      arrow_up: ArrowUp,
      arrow_down: ArrowDown,
      dollar: Dollar,
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
          <g clip-path="url(#clip0_1634_940)">
          <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
          </g>
          <defs>
          <clipPath id="clip0_1634_940">
          <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
          </clipPath>
          </defs>
          </svg> Success`,
    };
  },
  mounted() {
    $("#viewDetailsModal" + this.typeOfOrder).modal("show");
    const self = this;
    $("#viewDetailsModalOpen").on("hide.bs.modal", function (event) {
      self.$emit("closed");
    });
    $("#viewDetailsModalClosed").on("hide.bs.modal", function (event) {
      self.$emit("closed");
      self.$emit("openOrdersModalProp");
    });
  },
  computed: {
    token() {
      return this.data.token ? this.data.token.substring(0, 30) + "..." : null;
    },
  },
  methods: {
    colorStatus(value) {
      return value > 0 ? "green" : value < 0 ? "red" : "";
    },
    copyToken(token) {
      navigator.clipboard.writeText(token);

      this.$toastr.success(
        "The token has been copied correctly.",
        `${this.success}`,
        { closeButton: true }
      );
    },
    formatter(number) {
      return number_formatter(number);
    },
    getStatus(value) {
      return StatusOrderFormatter(value);
    },
    close() {
      $("#viewDetailsModal" + this.typeOfOrder).modal("hide");
    },
    formatPercentage(number) {
      const formatted = Math.round(parseFloat(number) * 100) / 100;
      return isNaN(formatted) ? number : formatted;
    },
  },
};
</script>

<style lang="scss">
@import "../../../../styles/pages/dashboard/opened_orders_table.scss";
#toast-container > .toast-success {
  background-color: #1d1e25;
  color: #f5f5f5;
  border-radius: 4px;
  padding: 20px;
  border: none;
}
#toast-container > .toast-error {
  background-color: #1d1e25;
  color: #f5f5f5;
  border-radius: 4px;

  padding: 20px;
  border: none;
}
.toast-title {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 7px;
}
.toast-message {
  color: var(--white-90, rgba(255, 255, 255, 0.9));
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
#toast-container > div {
  opacity: unset;
}
.toast-close-button {
  opacity: unset;
}
.toast-close-button:focus,
.toast-close-button:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: unset;
  filter: none;
}
</style>
