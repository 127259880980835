export default {
  state: {
    symbols_correlator: [],
    search_symbols: '',
    add_symbol_correlator: false,
    remove_symbol_correlator: false,
    make_correlation: false,
    timeframe: '1m',
    timeframe_clicked: false,
    realtime_chart: true,
    
  },
  mutations: {
    add_symbols_correlator(state, symbols_correlator) {
      const filter = state.symbols_correlator.filter((s) => s.symbol === symbols_correlator.symbol && s.exchange === symbols_correlator.exchange);
      if (filter.length === 0) state.symbols_correlator.push(symbols_correlator)
      state.add_symbol_correlator = true;
    },
    remove_symbols_correlator(state, symbols_correlator) {
      const removeIndex = [...state.symbols_correlator].map((s) => s.symbol).indexOf(symbols_correlator.symbol);
      state.symbols_correlator.splice(removeIndex, 1);
      state.remove_symbol_correlator = true;
    },
    set_search(state, search_symbols) {
      state.search_symbols = search_symbols;
    },
    set_add_symbol_correlator(state, add_symbol_correlator) {
      state.add_symbol_correlator = add_symbol_correlator;
    },
    set_remove_symbol_correlator(state,remove_symbol_correlator) {
      state.remove_symbol_correlator = remove_symbol_correlator;
    },
    set_make_correlation(state, make_correlation) {
      state.make_correlation = make_correlation;
    },
    set_timeframe(state, timeframe) {
      state.timeframe = timeframe;
    },
    set_timeframe_clicked(state, timeframe_clicked) {
      state.timeframe_clicked = timeframe_clicked;
    },
    set_realtime_chart(state, realtime_chart) {
      state.realtime_chart = realtime_chart;
    },
  },
  actions: {
  },
}