<template>
  <div ref="healthbar" class="healthbar-timeline">
    <div class="status">Operational</div>
    <div class="tabs">
      <div
        data-testid="options"
        class="tab"
        :class="[index == 0 ? 'active' : '']"
        v-for="(option, index) in timelineOptions"
        :key="index"
      >
        {{ option }}
      </div>
    </div>
    <div class="timeline-bars" ref="timeline">
      <div
        class="bar data-unavailable"
        v-for="index in addedTimeline"
        :key="`item-${index}`"
      ></div>
      <div
        class="bar"
        :class="mapHealthToColor(item)"
        v-for="(item, index) in data.historical_timeline"
        :key="index"
        @mouseover="handleMouseOver(item)"
        @mouseleave="handleMouseLeave(item)"
        @mousemove="updateTooltipPosition"
      ></div>
    </div>
    <div class="timeline-footer">
      <div class="text">{{ data.days_ago + addedTimeline }} days ago</div>
      <div class="line"></div>
      <div class="text">{{ typeof data.uptime_percentage === 'number' ? data.uptime_percentage.toFixed(2) : data.uptime_percentage }}% uptime</div>
      <div class="line"></div>
      <div class="text">Today</div>
    </div>
    <div
      class="timeline-tooltip"
      :style="{ left: left + 'px', top: top + 'px' }"
      v-if="tooltipIncidents.length > 0"
      @mouseover="mouseOk = true"
      @mouseleave="mouseOk = false"
    >
      <div class="timeline-title">Incidents</div>
      <div class="incidents-list">
        <div
          class="incident"
          v-for="(item, index) in tooltipIncidents"
          :key="index"
        >
          <div class="incident-title">{{ item.system_name }}</div>
          <div class="incident-date">{{ item.timestamp }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HealthTimeline",
  props: ["timelineOptions", "data"],
  data() {
    return {
      tooltipIncidents: [],
      addedTimeline: 0,
      left: 0,
      top: 0,
      width: 0,
      mouseOk: false,
    };
  },
  methods: {
    handleMouseLeave(data) {
      this.mouseOk = false;
    },
    handleMouseOver(data) {
      this.mouseOk = true;
      this.tooltipIncidents = data.incidents;
    },
    mapHealthToColor(data) {
      if (!data.was_running) {
        return "data-unavailable";
      } else if (data.was_running && data.incidents.length > 0) {
        return "weak";
      } else {
        return "good";
      }
    },
    scrollToLast() {
      this.$nextTick(() => {
        const timeline = this.$refs.timeline;
        if (timeline) {
          timeline.scrollLeft = timeline.scrollWidth - timeline.clientWidth;
        }
      });
    },
    render() {
      this.fillTimeline();
      this.scrollToLast();
    },
    fillTimeline() {
      const healthbar = this.$refs.healthbar;
      const width = healthbar.clientWidth - 40;
      const timelinesShort = parseInt(
        width / 9 - this.data.historical_timeline.length
      );
      if (timelinesShort > 0) {
        this.addedTimeline = timelinesShort;
      }
    },
    updateTooltipPosition(event) {
      const margin = 3;
      const tooltipWidth = 324; // Adjust this value based on your tooltip content width

      this.left = Math.min(
        event.pageX + margin,
        window.innerWidth - tooltipWidth - margin
      );

      this.top = Math.min(event.pageY + margin, window.innerHeight - margin);
    },
    hideTooltip() {
      setTimeout(() => {
        if (!this.mouseOk) {
          this.tooltipIncidents = [];
        }
      }, 200);
    },
  },
  watch: {
    mouseOk: {
      handler: "hideTooltip",
      deep: true,
    },
    data: {
      handler: "render",
      deep: true,
    },
  },
  mounted() {
    this.render();
    window.addEventListener('resize', this.fillTimeline);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.fillTimeline);
  },
};
</script>

<style lang="scss">
@import "../../styles/commons/health_bar_timeline.scss";
</style>
