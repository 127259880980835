import random_string from "../../../helpers/generators/random_string";

export default () => {
  return {
    error: false,
    data: {
      secret_key: random_string(25),
      api_key: random_string(200),
      name: random_string(10),
    },
  };
};
