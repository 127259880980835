import state from "@/helpers/store/state";
import set from "@/helpers/store/set";

import fetch_groups from "./actions/fetch_groups";
import create_group from "./actions/create_group";
import delete_group from "./actions/delete_group";
import edit_group from "./actions/edit_group";
import group_credentials from "./actions/group_credentials";
import groups_selector from "./actions/groups_selector";
import algorithm_selector from "./actions/algorithm_selector";

import groups_adapter from "../../services/adapters/groups";
import group_credentials_adapter from "../../services/adapters/group_credentials";
import create_group_adapter from "../../services/adapters/common/adapter_data";
import delete_group_adapter from "../../services/adapters/common/adapter_message";
import edit_group_adapter from "../../services/adapters/common/adapter_data";
import algorthms_adapter from "../../services/adapters/common/adapter_data_array";

export default {
  namespaced: true,
  state: () => ({
    groups: state(groups_adapter),
    create_group: state(create_group_adapter),
    delete_group: state(delete_group_adapter),
    edit_group: state(edit_group_adapter),
    group_credentials: state(group_credentials_adapter),
    algorithm_selector: state(algorthms_adapter),
    groups_selector: state(algorthms_adapter),
  }),
  actions: {
    algorithm_selector,
    fetch_groups,
    create_group,
    delete_group,
    edit_group,
    group_credentials,
    groups_selector,
  },
  mutations: { set },
};
