import algorithmSelectorService from "../../../services/algorithm_selector_investor.service";
export default ({ state, commit }) => {
  commit("set", {
    _state: "algorithm_selector_list",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });
  const investor_token = state.investor_token;
  algorithmSelectorService({
    investor_token,
    callback: ({ response }) => {
      commit("set", {
        _state: "algorithm_selector_list",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "algorithm_selector_list",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
