<template>
  <div class="table-responsive">
    <div class="col-md-12 d-flex px-0">
      <label for="">Sub Investors</label>
      <b-button
        variant="success"
        class="small ml-auto"
        v-if="$props.isEdit"
        @click="$emit('handleCreateSubInvestors')"
        >Add Sub Investor</b-button
      >
    </div>
    <b-table
      id="table"
      :items="$store.state.subinvestors"
      :fields="fields"
      striped
      hover
      small
      borderless
      :busy="$store.state.get_subinvestors_loading"
      v-if="
        $store.state.subinvestors.length > 0 ||
        $store.state.get_subinvestors_loading
      "
    >
      <template #cell(name)="row" #cell(index)="row">
        <div class="description pointer">
          <span class="d-block mono small">
            {{ row.item.subinvestor.name }}
          </span>
        </div>
      </template>
      <template #cell(is_real)="row" #cell(index)="row">
        <div class="description pointer">
          <span class="d-block mono small">
            {{ row.item.subinvestor.is_real ? "YES" : "NO" }}
          </span>
        </div>
      </template>
      <template #cell(future)="row" #cell(index)="row">
        <div class="description pointer">
          <span class="d-block mono small">
            {{
              JSON.parse(row.item.subinvestor.configurations).market_type ==
              "futures"
                ? "YES"
                : "NO"
            }}
          </span>
        </div>
      </template>
      <template #cell(options)="row" #cell(index)="row">
        <span
          v-b-tooltip.hover
          title="Audit"
          class="mr-2 pointer"
          style="font-size: 14px !important"
          @click.prevent="handleAudit(row.item)"
          v-if="row.item.subinvestor.is_real"
        >
          <b-icon-check-circle></b-icon-check-circle>
        </span>

        <span
          v-b-tooltip.hover
          class="mr-2"
          title="Download report"
          style="font-size: 14px !important"
        >
          <b-icon-cloud-download
            @click="downloadReport(row.item.subinvestor.token_user)"
            v-if="token_loading != row.item.subinvestor.token_user"
          ></b-icon-cloud-download>

          <b-icon
            icon="arrow-repeat"
            style="font-size: 14px !important"
            animation="spin-pulse"
            v-if="
              loadingReport && token_loading == row.item.subinvestor.token_user
            "
          ></b-icon>
        </span>

        <span
          v-b-tooltip.hover
          class="mr-2"
          title="Open new tab"
          style="font-size: 14px !important"
        >
          <router-link
            :to="`/subinvestor/${row.item.subinvestor.token_user}`"
            target="_blank"
          >
            <b-icon-box-arrow-up-right></b-icon-box-arrow-up-right>
          </router-link>
        </span>

        <span
          v-b-tooltip.hover
          title="Edit sub investor"
          class="mr-2 pointer"
          style="font-size: 14px !important"
          @click.prevent="$emit('handleEditSubinvestor', row.item)"
        >
          <b-icon-gear></b-icon-gear>
        </span>
        <span
          v-b-tooltip.hover
          title="Delete sub investors"
          v-if="$store.state.user && $store.state.user.role_id == 1"
          class="mr-2 pointer"
          style="font-size: 14px !important"
          @click.prevent="handleDelete(row.item.subinvestor)"
        >
          <b-icon-x-circle></b-icon-x-circle>
        </span>
      </template>
      <template #table-busy>
        <b-skeleton-table
          :rows="3"
          :columns="4"
          :table-props="{ striped: true }"
        ></b-skeleton-table>
      </template>
    </b-table>
    <b-alert
      class="my-2"
      show
      variant="dark"
      v-if="
        $store.state.subinvestors.length == 0 &&
        !$store.state.get_subinvestors_loading
      "
      >No sub investors available.</b-alert
    >
  </div>
</template>
<script>
export default {
  name: "ContainerSubInvestors",
  props: {
    isEdit: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
        <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
        </svg> Error`,
      fields: [
        { key: "token" },
        { key: "name" },
        { key: "is_real" },
        { key: "future" },
        { key: "options" },
      ],
      loadingReport: false,
      token_loading: "",
    };
  },
  methods: {
    handleDelete(subinvestor) {
      const data = { token: subinvestor.token_user };
      if (confirm("are you sure?")) {
        const payload = {
          vm: this,
          data,
        };
        const payload2 = {
          vm: this,
          data: {
            investor_token: subinvestor.parent_token_user,
          },
        };
        this.$store.commit("bwfs_delete_subinvestor", payload);
        setTimeout(() => {
          this.$store.commit("get_subinvestor", payload2);
        }, 1000);
      }
    },
    handleAudit(subinvestor) {
      console.log("handleAudit(subinvestor)");
      this.$store.commit("set_subinvestor", { subinvestor: subinvestor });
      this.$store.commit("set_show_modal_audit", true);
    },
    downloadReport(token) {
      const data = {
        token_user: token,
      };
      this.token_loading = token;
      this.loadingReport = true;
      this.$http
        .server1("bwfs/admin/subinvestors/downloadreport", data)
        .then((res) => {
          this.loadingReport = false;
          this.token_loading = "";
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          window.open(this.$http.url_api(true) + "/bwfs/static/" + res.message);
          if (this.$store.state.download_reports)
            this.$store.commit(
              "unselect_investor_for_select",
              this.investor.info.token
            );
        })
        .catch((e) => {
          this.loadingReport = false;
          this.$toastr.error(`${e.message}`, `${this.error}`);
          if (this.selectInvestor)
            this.$store.commit(
              "unselect_investor_for_select",
              this.investor.info.token
            );
        });
    },
  },
};
</script>
