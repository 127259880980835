<template>
  <div v-if="is_required">
    <input
      :type="type"
      :class="['form-control']"
      :placeholder="placeholder"
      @input="handleInput"
      @change="handleChange"
      v-model="content"
      :disabled="disabled"
      :id="id"
      :autocomplete="autocomplete"
    />
    <div v-if="$v.content.$dirty">
      <b-form-invalid-feedback :state="$v.content.required">
        Required Field
      </b-form-invalid-feedback>
      
      <b-form-invalid-feedback :state="$v.content.email">
        Introduce a valid email address  
      </b-form-invalid-feedback>
      
      <b-form-invalid-feedback :state="$v.content.password">
        Introduce a valid password
      </b-form-invalid-feedback>
      
      <b-form-invalid-feedback :state="$v.content.numeric"> 
        Introduce just numbers
      </b-form-invalid-feedback>
      
      <b-form-invalid-feedback :state="$v.content.minLength">
        Min. {{minLength}} Characters
      </b-form-invalid-feedback>
      
      <b-form-invalid-feedback :state="$v.content.maxLength">
        Max. {{manLength}} Characters
      </b-form-invalid-feedback>
      
    </div>
    </div>  
</template>

<script>
export default {
  name: "InputBase",
  props: {
    label: {
      required: false,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
    },
    id: {
      required: true,
      type: String,
    },
    messageError: {
      required: false,
      type: String,
    },
    error: {
      required: false,
      type: Boolean,
      default: false,
    },
    value: {
      required: false,
    },
    type: {
      required: true,
      default: "text",
      type: String,
      validator(value) {
        // The value must match one of these strings
        return (
          [
            "text",
            "number",
            "textarea",
            "email",
            "password",
            "checkbox",
          ].indexOf(value) !== -1
        );
      },
    },
    disabled: {
      required: false,
      default: false,
    },
    autocomplete: {
      required: false,
      type: String,
      default: "off",
    },
    minLength: {
      required: false,
      type: Number,
    },
    maxLength: {
      required: false,
      type: Number,
    },
    is_required: {
      required: false,
      type: Boolean,
    },
    email: {
      required: false,
      type: Boolean,
    },
    passowrd: {
      required: false,
      type: Boolean,
    },
    numeric: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    handleInput() {
      this.$v.content.$touch();
      this.$emit("input", this.content);
    },
    handleChange() {
      this.$emit("change", this.content);
    }
  },
  watch: {
    value(newValue) {
      this.content = newValue;
    },
  },
};
</script>