<template>
  <div>
    <div class="table-responsive">
      <b-table
        :items ="items"
        :fields="fields"
        striped 
        hover
        small
        borderless
        :busy="loading"
      >
      <template #cell(is_real)="data">
        <div :class="'small' + ' ' + (data.item.is_real == 0 ? 'text-info' : 'text-success')">
          {{data.item.is_real == 0 ? 'DEMO': 'REAL'}}
        </div>
      </template>
      <template #cell(name)="data">
        <div class="d-flex flex-column">
          <span v-for="name in data.item.name" :key="name">{{name}}</span>
        </div>
      </template>
      <template #cell(status)="data">
        <span class="turn-button" style="font-size:1.5rem;" v-if="data.item.status === 2">
          <b-icon icon ="toggle-on" variant="success"></b-icon>
        </span>
        <span  v-b-tooltip.hover class="turn-button"  style="font-size: 1.5rem;" v-else>
          <b-icon icon="toggle-off" variant="danger"></b-icon>
        </span>
      </template> 
      <template #cell(show_json)="data">
        <span v-b-tooltip.hover title="Show json config" style="font-size: 1.5rem;" class="pointer" @click="showOrders(data.item)">
          <b-icon-braces></b-icon-braces>
        </span>
      </template>
      <template #table-busy>
          <b-skeleton-table
            :rows="5"
            :columns="4"
            :table-props="{  striped: true }"
          ></b-skeleton-table>
        </template>
      </b-table>
      <div class="mt-2">
        <b-pagination
          v-model="accountsForm.page"
          :total-rows="count"
          :per-page="pageSize"
          @change="handlePageChange"
        />     
      </div>
    </div>
    <b-modal ref="modal-show-json" id="modal-show-json" hide-footer hide-header size="xl" ok-only>
          <div class="modal-create-conditions">
            <h3 class="modal-title d-flex justify-content-between">
              <span>Show configuration</span>
            </h3>
            <input type="text" class="form-control d-block mb-2" v-model="filter_values_json" placeholder="Filter logs..." id="filterValuesJsonOrders">
            <div class="row">
              <div class="col-md-11 pr-0">
                <ViewJson :jsonData="json_order" :filter="filter" />
              </div>
              <div class="col-md-1">
                <span class="pointer" style="font-size: 22px;" v-b-tooltip.hover title="Copy one or more conditions" @click.prevent="$helpers.copyValues(JSON.stringify(json_order), _vue, EventBus)" >
                  <b-icon-paperclip></b-icon-paperclip>
                </span>
              </div>
            </div>
          </div>
    </b-modal>
  </div>
</template>

<script>
import ViewJson from '@/components/commons/ViewJson';
import ToggleOn from './toggle/toggle-on.vue';
import ToggleOff from './toggle/toggle-off';

export default {
  name: 'LMDDAccountTable',
  props: ['searchForm'],
  components: {
    ToggleOn,
    ToggleOff
  },
  async created() {
    this._vue = this;
    this.fetchLMDDAccounts()
  },
  components: {
    ViewJson
  },
  data() {
    return {
      items: [],
      filter_values_json: '',
      filter: '',
      json_order: {},
      accountsForm: {
        search: '',
        page: 1,
        user_type: 10,
      },
      fields: [{
        key: 'investor_token', label: 'Investor Token'
      },
      {
        key: 'is_real', label:'User Type'
      },
      {
        key: 'name', label: 'Name'
      },
      {
        key:'status', label: 'Status'
      },
      {
        key: 'asset', label: 'asset',
      },
      {
        label: 'Show JSON', key: 'show_json'
      },
      ],
      loading: false,
      pageSize: 25,
      count: 0,
    }
  },
  methods: {
    showOrders(LMDD) {
      this.json_order = LMDD.conditions
      this.$bvModal.show('modal-show-json');
    },
    async fetchLMDDAccounts() {
      this.loading = true
      this.$emit('loadingLMDD', true)
      const data = { ...this.accountsForm }
      const response = await this.$http.server1('bwfs/lmdd/all_accounts', data)
      const investors = response.data.map((investor,index) => {
        try {
          const condition = JSON.parse(investor.conditions)
          const name = `${investor.name}-${index + 1}`
          investor.indexName = name;
          investor.conditions = {};
          investor.conditions[name] = {
            open_condition: condition.open_condition,
            close_condition: condition.close_condition,
            multiple_order: condition.multiple_order ? condition.multiple_order: false,
            base_configuration: {
              asset: investor.asset,
              commision: investor.commision,
              laverage: investor.laverage,
              loting: investor.loting,
              server: investor.server,
              shorten_trailing: investor.shorten_trailing,
              symbols_to_ignore: JSON.parse(investor.symbols_to_ignore),
              symbols_to_use: JSON.parse(investor.symbols_to_use)
            }
          };
          // investor.name = [investor.name];
          return investor;
        } catch (e) {
            investor.conditions = {},
            investor.conditions[investor.name] = {message_error: e}
          return investor;
        }
      })
      let items = [];
      let seen = {};
      const testD = investors.filter(function(entry) {
          let previous;

          // Have we seen this investor_token before?
          if (seen.hasOwnProperty(entry.investor_token)) {
              // Yes, grab it and add this data to it
              previous = seen[entry.investor_token];
              previous.name.push(entry.name);
              previous.conditions[entry.indexName] = entry.conditions[entry.indexName]
              // Don't keep this entry, we've merged it into the previous one
              return false;
          }

          // entry.name probably isn't an array; make it one for consistency
          if (!Array.isArray(entry.name)) {
              entry.name = [entry.name];
          }

          // Remember that we've seen it
          seen[entry.investor_token] = entry;

          // Keep this one, we'll merge any others that match into it
          return true;
      });
      this.items = testD
      this.count = response.paginate.records
      this.$emit('records', response.data.length)
      this.loading = false
      this.$emit('loadingLMDD', false)
    },
    handlePageChange(value) {
      this.accountsForm.page = value
      this.fetchLMDDAccounts()
    }
  },
  watch: {
    searchForm(value) {
      this.accountsForm = value
      this.fetchLMDDAccounts()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep svg {
  fill: white;
}
</style>