import * as request from "../helpers/request-new";
import adapter from "./adapters/open_orders";
import response_mock from "./mockdata/responses/open_orders_json";
import { immediate_close as microservice } from "../helpers/microservices";

export default ({ body, params, callback, error }) => {
  request.execute({
    microservice: microservice(),
    method: "POST",
    body,
    params,
    callback,
    error,
    adapter: adapter,
    mockdata: response_mock(),
    debug: false,
    /* auth_headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    }, */
  });
};
