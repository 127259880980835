export default {
  data: [],
  error: false,
  paginate: {
    current_page: 0,
    total_pages: 0,
    per_page: 0,
    records: 0,
  },
};
