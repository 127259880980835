export default {
  is_archived: 0,
  label: null,
  limit: 10,
  search: null,
  orderBy: "",
  sort: "",
  status: null,
  type: null,
};
