<template>
  <div :class="`panel page__${$route.name.toLowerCase()}`">
    <b-container fluid>
      <b-row class="p-0">
        <SearchSupervisor />
        <b-col cols="12" lg="10" class="p-0 admin_content nice-scroll">
          <div class="content p-2">
            <div class="row pl-3 pr-3">
              <div class="col-md-6 p-1 d-flex align-items-center">
                Total records found:
                {{
                  $store.state.supervisors ? $store.state.supervisors.length : 0
                }}
              </div>
              <div class="col d-flex justify-content-end">
                <ModalNewSupervisor />
              </div>
            </div>
            <div class="mt-3">
              <SupervisorList />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ModalNewSupervisor from "@/components/pages/supervisors/ModalNewSupervisor.vue";
import SupervisorList from "@/components/pages/supervisors/SupervisorList.vue";
import SearchSupervisor from "@/components/pages/supervisors/SearchSupervisor.vue";

export default {
  created() {
    this.$helpers.setWindowName();
  },
  name: "Supervisors",
  components: {
    ModalNewSupervisor,
    SupervisorList,
    SearchSupervisor,
  },
  data() {
    return {};
  },
  mounted() {
    this.$helpers.setWindowName();
  },
};
</script>

<style lang="scss">
@import "@/styles/commons/admin.scss";
@import "@/styles/pages/users.scss";
</style>
