<template>
  <b-modal
    id="modal-add-condition-v3"
    hide-footer
    hide-header
    size="xl"
    ok-only
  >
    <div class="modal-create-conditions">
      <h3 class="modal-title d-flex justify-content-between">
        <span> Condition generator aa </span>
        <div v-if="isCompound && !show">
          <a
            href="#"
            style="font-size: 13px; color: #fff"
            class="pr-2"
            @click.prevent="activeTabCondition('buy')"
          >
            <span
              class="btn btn-dark small"
              :style="{
                background: activeBuy ? '#11131b' : '#232429',
                fontWeight: '400',
              }"
            >
              Condition #1
            </span>
          </a>
          <a
            href="#"
            style="font-size: 13px; color: #fff"
            @click.prevent="activeTabCondition('sell')"
          >
            <span
              class="btn btn-dark small"
              :style="{
                background: activeSell ? '#11131b' : '#232429',
                fontWeight: '400',
              }"
            >
              Condition #2
            </span>
          </a>
        </div>
      </h3>
      <ConditionGenerator
        ref="conditionGeneratorBuy"
        @addCondition="handleAddCondition"
        @handleAddModify="handleAddModify"
        @handleCompound="handleCompoundCondition"
        @handlePriority="handlePriority"
        @handleAddCompound="handleAddCompoundCondition"
        @handleSetType="handletSeType"
        @handleBaseConfiguration="handleBaseConfiguration"
        @setConditionPasteSell="setConditionPasteSell"
        v-show="activeBuy"
        :isCompound="isCompound"
        :backtest="investorForm"
        :is_investor="true"
        :showSignal="showSignal"
        :showBaseConfiguration="showBaseConfiguration"
      />
      <ConditionGenerator
        ref="conditionGeneratorSell"
        @addCondition="handleAddCondition"
        @handleAddModify="handleAddModify"
        @handleCompound="handleCompoundCondition"
        @handleAddCompound="handleAddCompoundCondition"
        :tabSell="true"
        :isCompound="isCompound"
        :valueCompound="is_compound"
        :priority="priority"
        v-show="activeSell"
        :type="currentTypeCondition === 'buy' ? 'sell' : 'buy'"
        :backtest="investorForm"
        :is_investor="true"
        :showSignal="showSignal"
        :showBaseConfiguration="showBaseConfiguration"
      />
    </div>
  </b-modal>
</template>
<script>
import ConditionGenerator from "@/components/commons/ConditionGenerator";

export default {
  name: "ConfigureConditions",
  components: {
    ConditionGenerator,
  },
  props: {
    showSignal: {
      required: false,
      type: Boolean,
      default: true,
    },
    showBaseConfiguration: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
        <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
        </svg> Error`,
      show: false,
      maximunCondition: 50,
      isCompound: false,
      is_compound: "",
      activeSell: false,
      activeBuy: true,
      priority: "buy",
      investorForm: {},
      currentTypeCondition: "buy",
      conditions: [],
      colorID: {},
      idModal: "modal-add-condition-v3",
      indexConditionModify: 0,
    };
  },
  methods: {
    handleAddCondition(condition) {
      if (this.conditions.length < this.maximunCondition) {
        let existOtherCondition = this.conditions.filter(
          (i) => i.base_configuration.is_compound != false
        );
        if (existOtherCondition.length > 0) {
          this.$toastr.error(
            "You only have to add compound or simple conditions.",
            `${this.error}`
          );

          return;
        }
        this.conditions.push(condition);
        this.$bvModal.hide(this.idModal);
        this.$emit("handleConditions", this.conditions);
      } else {
        this.$toastr.error(
          "has exceeded the limit of allowed conditions",
          `${this.error}`
        );
      }
    },
    handleAddModify(condition, compoundSell) {
      if (
        condition.base_configuration.is_compound &&
        condition.base_configuration.is_compound != "false"
      ) {
        let condition1 = {};
        let condition2 = {};
        if (compoundSell) {
          condition1 = this.$refs.conditionGeneratorBuy.addCondition(true);
          condition2 = this.$refs.conditionGeneratorSell.addCondition(true);
        } else {
          condition2 = this.$refs.conditionGeneratorSell.addCondition(true);
          condition1 = this.$refs.conditionGeneratorBuy.addCondition(true);
        }

        if (
          condition1.base_configuration.type ===
          condition2.base_configuration.type
        ) {
          this.$toastr.error(
            "The compound conditions must be sell and buy.",
            `${this.error}`
          );
          return;
        }

        condition1.base_configuration.configuration_compound.method = parseInt(
          condition1.base_configuration.is_compound.replace("method", "")
        );
        condition2.base_configuration.configuration_compound.method = parseInt(
          condition2.base_configuration.is_compound.replace("method", "")
        );
        condition1.base_configuration.configuration_compound.token =
          condition2.token;
        condition2.base_configuration.configuration_compound.token =
          condition1.token;

        let indexOtherCondition = 0;
        if (this.indexConditionModify % 2 === 0) {
          indexOtherCondition = this.indexConditionModify + 1;
        } else {
          indexOtherCondition = this.indexConditionModify;
          this.indexConditionModify -= 1;
        }

        const conditions = this.conditions;
        this.conditions = [];

        conditions[this.indexConditionModify] = {};
        conditions[this.indexConditionModify] = condition1;

        conditions[indexOtherCondition] = {};
        conditions[indexOtherCondition] = condition2;

        this.conditions = conditions;
        if (!this.colorID[this.indexConditionModify]) {
          const color = this.$helpers.getRandomColor();
          this.colorID[this.indexConditionModify] = color;
          this.colorID[indexOtherCondition] = color;
        }

        this.$bvModal.hide(this.idModal);
        this.isModify = false;
        this.$emit("handleConditions", this.conditions);
        return;
      }
      const conditions = this.conditions;
      this.conditions = [];
      conditions[this.indexConditionModify] = {};
      conditions[this.indexConditionModify] = condition;
      this.colorID = {};
      for (let i = 0; i < conditions.length; i++) {
        if (conditions[i].base_configuration.configuration_compound) {
          conditions.splice(i, 1);
        }
      }
      this.conditions = conditions;
      this.$bvModal.hide(this.idModal);
      this.isModify = false;
    },
    handleCompoundCondition(value) {
      if (!value || value == "false") {
        this.isCompound = false;
        return;
      }
      this.isCompound = value != "false";
      this.is_compound = value;
    },
    handleAddCompoundCondition(condition, type) {
      let c = {};
      if (this.activeSell) {
        c = this.$refs.conditionGeneratorBuy.addCondition(true);
      } else {
        c = this.$refs.conditionGeneratorSell.addCondition(true);
      }
      if (!c) {
        return;
      }
      if (c.base_configuration.type == condition.base_configuration.type) {
        this.$toastr.error(
          "The compound conditions must be sell and buy.",
          `${this.error}`
        );
        return;
      }
      let existOtherCondition = this.conditions.filter(
        (i) => i.base_configuration.is_compound == false
      );
      if (existOtherCondition.length > 0) {
        this.$toastr.error(
          "You only have to add compound or simple conditions.",
          `${this.error}`
        );

        return;
      }
      c.base_configuration.configuration_compound.token = condition.token;
      c.base_configuration.configuration_compound.method = parseInt(
        c.base_configuration.is_compound.replace("method", "")
      );
      condition.base_configuration.configuration_compound.token = c.token;
      condition.base_configuration.configuration_compound.method = parseInt(
        condition.base_configuration.is_compound.replace("method", "")
      );
      this.$bvModal.hide(this.idModal);
      if (this.conditions.length < this.maximunCondition) {
        let index = this.conditions.length;
        const color = this.$helpers.getRandomColor();
        this.colorID[index] = color;
        this.colorID[index + 1] = color;
        if (this.activeSell) {
          this.conditions.push(c);
          this.conditions.push(condition);
        } else {
          this.conditions.push(condition);
          this.conditions.push(c);
        }
        this.$emit("handleConditions", this.conditions);
      } else {
        this.$toastr.error(
          "Has exceeded the limit of allowed conditions",
          `${this.error}`
        );
      }
    },
    setConditionPasteSell(condition) {
      setTimeout(() => {
        this.$refs.conditionGeneratorSell.setCondition(condition);
      }, 500);
    },
    handleBaseConfiguration(field, value) {
      this.$refs.conditionGeneratorSell.setData(field, value);
    },
    handletSeType(type) {
      // buy or sell
      this.currentTypeCondition = "";
      setTimeout(() => {
        this.currentTypeCondition = type;
      }, 150);
    },
    handlePriority(value) {
      // buy or sell
      this.priority = value;
    },
    activeTabCondition(type) {
      // muestra el type segun la condition
      // buy or sell
      if (type === "buy") {
        this.activeBuy = true;
        this.activeSell = false;
      } else {
        this.activeBuy = false;
        this.activeSell = true;
      }
    },
    handleEditCondition(condition, index) {
      condition = JSON.parse(JSON.stringify(condition));
      this.$bvModal.show(this.idModal);
      this.indexConditionModify = index;
      this.isModify = true;
      let is_compound = false;
      if (condition.base_configuration) {
        is_compound =
          condition.base_configuration.is_compound &&
          condition.base_configuration.is_compound != "false";
      } else if (
        condition.condition &&
        condition.condition.base_configuration
      ) {
        is_compound =
          condition.condition.base_configuration.is_compound &&
          condition.condition.base_configuration.is_compound != "false";
      }
      if (is_compound) {
        let indexCondition = 0;
        if (index % 2 !== 0) {
          this.activeSell = true;
          this.activeBuy = false;
          indexCondition = index - 1;
        } else {
          this.activeBuy = true;
          this.activeSell = false;
          indexCondition = index + 1;
        }

        setTimeout(() => {
          if (condition.condition) {
            condition = Object.assign(condition, condition.condition);
          }
          let cc = this.investorForm.configurations[indexCondition];
          cc = JSON.parse(JSON.stringify(cc));
          if (cc.condition) {
            cc = Object.assign(cc, cc.condition);
          }
          cc = this.clearShowCondition(cc);
          condition = this.clearShowCondition(condition);

          if (this.isShow) {
            cc.base_configuration = { ...cc.condition.base_configuration };
            cc.timeframes = cc.condition.timeframes;
            condition.base_configuration = {
              ...condition.condition.base_configuration,
            };
            condition.timeframes = { ...condition.condition.timeframes };
          } else {
            if (cc.base_configuration.is_compound) {
              cc.base_configuration.is_compound = `method ${cc.base_configuration.configuration_compound.method}`;
              condition.base_configuration.is_compound = `method ${condition.base_configuration.configuration_compound.method}`;
            }

            cc.base_configuration = { ...cc.base_configuration };
            cc.timeframes = cc.timeframes;
            condition.base_configuration = { ...condition.base_configuration };
            condition.timeframes = { ...condition.timeframes };
          }

          let method = "modifyCondition";
          if (this.isShow) {
            method = "showCondition";
          }

          if (indexCondition > index) {
            this.$refs.conditionGeneratorBuy[method](condition);
            this.$refs.conditionGeneratorSell[method](cc);
          } else {
            this.$refs.conditionGeneratorBuy[method](cc);
            this.$refs.conditionGeneratorSell[method](condition);
          }
        }, 300);
      } else {
        setTimeout(() => {
          if (this.isShow) {
            this.$refs.conditionGeneratorBuy.showCondition(condition);
          } else {
            this.$refs.conditionGeneratorBuy.modifyCondition(condition);
          }
        }, 300);
      }
    },
    resetConditions() {
      this.conditions = [];
    },
  },
};
</script>
