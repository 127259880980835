import random_number from "../../../helpers/generators/random_number";
import model from "../models/closed_orders_list";

export default () => {
  const num = random_number(1, 20);
  let data = [];
  for (let i = 0; i < num; i++) {
    const order = model(i);
    data.push(order);
  }

  return {
    data,
  };
};
