export default {
  data: {
    investor_name: null,
    current_nav: null,
    initial_deposit: null,
    total_deposits: null,
    total_withdawal: null,
    performance_to_date: null,
    performance_percentage: null,
    created_at: null,
  },
};
