import ExposureChartService from "../../../services/exposure_chart.service";
export default ({ state, commit }, payload) => {
  commit("set", {
    _state: "exposure_chart",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });
  const params = {
    investor_token: state.investor_token,
    portfolio_tokens: state.global_selected_algorithm,
    exchanges: state.global_selected_exchange,
    ...payload,
  };
  ExposureChartService({
    params,
    callback: ({ response }) => {
      commit("set", {
        _state: "exposure_chart",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "exposure_chart",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
