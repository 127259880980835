import CONSTANTS from "./constants";

var base_url_server1 = "";
var base_url_server2 = "";
var base_url_oms = "";
let current_host = window.location.hostname;
base_url_server2 = CONSTANTS.backtesting_api;
let schema = "https://";

if (current_host !== "localhost") {
  // base_url_server1 = CONSTANTS.production_api_testing;
  base_url_server2 = CONSTANTS.backtesting_api;
  if (current_host.substr(0, 10) == "10.103.245") {
    schema = "http://";
  }
  base_url_server1 = schema + current_host + ":8080";
  base_url_oms = schema + current_host + ":9090";
} else {
  base_url_server1 = CONSTANTS.develop_api;
  base_url_server2 = CONSTANTS.backtesting_api;
  base_url_oms = CONSTANTS.develop_api_oms;
}

// DASHBOARD
const algorithms = (token) =>
  `${base_url_server1}/bwfs/v2/portfolio/resume/${token}`;

const algorithm_selector_investor = (token) =>
  `${base_url_server1}/bwfs/v2/algorithms/investors/${token}`;

const basic_information = () =>
  `${base_url_server1}/bwfs/v2/investor/basic-information`;
const algorithm_selector = () =>
  `${base_url_server1}/bwfs/v2/algorithm/selector`;

const current_positions = () => `${base_url_server1}`;

const download_report = () =>
  `${base_url_server1}/bwfs/v2/investor/generate_investors_report`;

const download_orders_report = () =>
  `${base_url_server1}/bwfs/admin/investors/report`;

const exchange_selector = () => `${base_url_server1}/bwfs/v2/exchanges`;

const exposure = () => `${base_url_server1}/bwfs/v2/open_orders_summary`;

const exposure_chart = () => `${base_url_server1}/bwfs/v2/get_exposure`;

const open_orders = (token) => `${base_url_server1}/bwfs/v2/orders/${token}`;

const open_orders_json = () =>
  `${base_url_server1}/bwfs/admin/backtest/order/json`;

const immediate_close = () => `${base_url_oms}/api/v3/market_order/individual`;

const performance_account = () =>
  `${base_url_server1}/bwfs/v2/investor/perfomance/chart`;

const statics_fee = () => `${base_url_server1}/bwfs/v2/fees`;

const statics_max_drawdown = (token) =>
  `${base_url_server1}/bwfs/v2/investor/statistics_max_drawdown/${token}`;

const statics_metrics = () => `${base_url_server1}/bwfs/v2/annualized_metrics`;

const statistics_nav = () => `${base_url_server1}/bwfs/v2/performance`;

const crypto_slider = () => `${base_url_server1}/bwfs/v2/tickers`;

const news_sliders = () => `${base_url_server1}/bwfs/v2/news`;

const news_details = (token) =>
  `${base_url_server1}/bwfs/v2/news/bytoken/${token}`;
//GROUPS
const fetch_groups = () => `${base_url_server1}/bwfs/v2/groups/all`;

const create_group = () => `${base_url_server1}/bwfs/admin/groups/create`;

const delete_group = () => `${base_url_server1}/bwfs/v2/groups/delete_group`;

const edit_group = () => `${base_url_server1}/bwfs/v2/groups/update`;

const group_credentials = () =>
  `${base_url_server1}/bwfs/v2/groups/show_credential`;

// CONNECTIONS
const connection_list = () =>
  `${base_url_server1}/bwfs/v2/exchange_connections`;

const create_connection = () =>
  `${base_url_server1}/bwfs/v2/exchange_connection`;

const connection_list_selector = () =>
  `${base_url_server1}/bwfs/v2/exchange_connections_resume`;

const connection_update = (token) =>
  `${base_url_server1}/bwfs/v2/exchange_connection/${token}`;

const delete_connection = (token) =>
  `${base_url_server1}/bwfs/v2/exchange_connection/delete/${token}`;

// PORTFOLIOS
const create_portfolio = () => `${base_url_server1}/bwfs/v2/portfolio`;

const delete_portfolio = (token) =>
  `${base_url_server1}/bwfs/v2/portfolio/delete/${token}`;

const portfolio_list = (token) =>
  `${base_url_server1}/bwfs/v2/portfolio/investor/${token}`;

const update_portfolio = (token) =>
  `${base_url_server1}/bwfs/v2/portfolio/${token}`;

// Closed orders
const closed_orders_list = (token) =>
  `${base_url_server1}/bwfs/v2/orders/${token}`;

//Investors
const archive_account = () => `${base_url_server1}/bwfs/admin/investor/archive`;

const create_investor = () => `${base_url_server1}/bwfs/v2/investor/add`;

const create_labeling = () => `${base_url_server1}/bwfs/investors/labeling`;

const delete_investor = (token) =>
  `${base_url_server1}/bwfs/v2/investor/delete/${token}`;

const investors_list = () => `${base_url_server1}/bwfs/v2/investors`;

const investor_info = (token) =>
  `${base_url_server1}/bwfs/v2/admin/investor/information/${token}`;

const labeling_list = () => `${base_url_server1}/bwfs/get/labeling`;

const groups_selector = () => `${base_url_server1}/bwfs/admin/groups/all`;

const turn_on_off_account = () =>
  `${base_url_server1}/bwfs/admin/toogle/account`;

const update_investor = (token) =>
  `${base_url_server1}/bwfs/v2/investor/update/${token}`;

//ALGORITHM HEALTH
const systems_heartbeat_list = () =>
  `${base_url_server1}/bwfs/v2/algorithm_instance`;

const systems_heartbeat_timeline = () =>
  `${base_url_server1}/bwfs/v2/get_incidents_timeline`;

const tags_list = () =>
  `${base_url_server1}/bwfs/v2/algorithm_instance/get_all_tags`;

const delete_systems_heartbeat = (token) =>
  `${base_url_server1}/bwfs/v2/algorithm_instance/delete/${token}`;

const create_systems_heartbeat = () =>
  `${base_url_server1}/bwfs/v2/algorithm_instance/new`;

const update_systems_heartbeat = () =>
  `${base_url_server1}/bwfs/v2/algorithm_instance/update`;

const create_notification_list = () =>
  `${base_url_server1}/bwfs/v2/heartbeat_notification/new`;

const delete_notification_configuration = (token) =>
  `${base_url_server1}/bwfs/v2/heartbeat_notification/delete/${token}`;

const notification_configuration_list = () =>
  `${base_url_server1}/bwfs/v2/heartbeat_notification`;

const update_notification_configuration = () =>
  `${base_url_server1}/bwfs/v2/heartbeat_notification/update`;

// DEPOSIT AND WITHDRAWLS
const create_transaction = () => `${base_url_server1}/bwfs/v2/transaction/new`;

const delete_transaction = (token) =>
  `${base_url_server1}/bwfs/v2/transaction/delete/${token}`;

const transaction_list = () => `${base_url_server1}/bwfs/v2/transaction`;

const update_transaction = () =>
  `${base_url_server1}/bwfs/v2/transaction/modify`;

export {
  algorithm_selector,
  algorithm_selector_investor,
  archive_account,
  create_systems_heartbeat,
  create_investor,
  create_labeling,
  create_notification_list,
  create_transaction,
  basic_information,
  delete_systems_heartbeat,
  delete_investor,
  delete_notification_configuration,
  delete_transaction,
  algorithms,
  closed_orders_list,
  connection_list,
  connection_list_selector,
  connection_update,
  create_portfolio,
  create_connection,
  create_group,
  current_positions,
  delete_connection,
  delete_group,
  delete_portfolio,
  download_report,
  download_orders_report,
  edit_group,
  exchange_selector,
  exposure,
  exposure_chart,
  fetch_groups,
  group_credentials,
  investors_list,
  investor_info,
  immediate_close,
  labeling_list,
  news_details,
  news_sliders,
  notification_configuration_list,
  crypto_slider,
  open_orders,
  open_orders_json,
  performance_account,
  portfolio_list,
  statistics_nav,
  statics_fee,
  statics_max_drawdown,
  statics_metrics,
  update_portfolio,
  groups_selector,
  systems_heartbeat_list,
  systems_heartbeat_timeline,
  tags_list,
  transaction_list,
  turn_on_off_account,
  update_systems_heartbeat,
  update_investor,
  update_notification_configuration,
  update_transaction,
};
