import CreatePortfolioService from "../../../services/create_portfolio.service";
export default ({ commit, state }, { callback, error }) => {
  commit("set", {
    _state: "create_portfolio",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });

  const {
    exchange_token,
    ignore_transaction,
    group_token,
    name,
    type,
    amount,
  } = state.form_portfolio;

  const body = {
    exchange_token,
    investor_token: state.investor_token,
    ignore_transaction: ignore_transaction ? 1 : 0,
    group_token,
    name,
    amount,
    is_amount_percentage: type == "percentage" ? 1 : 0,
  };

  CreatePortfolioService({
    body,
    callback: ({ response }) => {
      commit("set", {
        _state: "create_portfolio",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "create_portfolio",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
