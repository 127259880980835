<template>
	<div id="chart">
    <apexchart type="pie" width="100%" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>
<script>
	export default {
		name: 'PieChart',
		props: {
			seriesData: {
				required: true,
				default: [],
				type: Array,
			},
			labels: {
				required: true,
				default: [],
				type: Array,
			},
		},
		created() {
			this.series = this.$props.seriesData;
			this.chartOptions.labels = this.$props.labels;
		},
		data() {
			return {
				series: [],
        chartOptions: {
          chart: {
            width: 380,
            type: 'pie',
          },
          stroke: {
            width: 0,
          },
          labels: [],
          legend: {
            show: false
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
			};
		},
	}
</script>

<style>
	.apexcharts-legend-text {
		color: #fff !important
	}
</style>