import random_number from "../../../helpers/generators/random_number";
import model from "../models/notification_configuration";

export default () => {
  const num = random_number(1, 20);
  let data = [];
  for (let i = 0; i < num; i++) {
    const notification_configuration = model(i);
    data.push(notification_configuration);
  }

  return {
    data,
  };
};
