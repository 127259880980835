var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"table-container d-flex flex-column",class:{ 'fixed-table': _vm.fixed }},[_c('div',{staticClass:"table-body table-responsive mb-0"},[_c('table',{staticClass:"table table-striped"},[_c(_setup.TableColumnHeader,{scopedSlots:_vm._u([{key:"checkboxx",fn:function(){return [_vm._t("checkboxx")]},proxy:true}],null,true)}),_c('tbody',_vm._l((_vm.data),function(row,index){return _c('tr',{key:index},[(row.__title__)?_c('td',[_vm._v(_vm._s(row.__title__))]):_vm._l((_setup.headers),function(header,j){return _c('td',{key:j,class:header.align == 'right'
                  ? 'text-right'
                  : header.align == 'left'
                  ? 'text-left'
                  : 'text-center',style:(header.color_options
                  ? {
                      color: _setup.findColor(header, row),
                    }
                  : '')},[(!header.action)?_vm._t(header.name,function(){return [(header.name)?_c('span',[_vm._v(_vm._s(_setup.printCellData(header, row)))]):_vm._e()]},{"text":header.secondary_name || header.third_name
                    ? [
                        row[header.name],
                        row[header.secondary_name],
                        row[header.third_name],
                      ]
                    : row[header.name]}):_c('span',{staticClass:"action-buttons"},[_vm._t("actions",null,{"id":header.id == false ? row : row[header.id]})],2)],2)})],2)}),0)],1)]),_c(_setup.TableFooter,{scopedSlots:_vm._u([{key:"pagination",fn:function(){return [_vm._t("pagination")]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }