<template>
  <div class="transaction-tab">
    <div class="transaction-tab-title">Transactions</div>
    <div class="transaction-tab-content">
      <div class="transaction-filters">
        <div class="transaction-top-row-filters">
          <div class="form-group w-input-20">
            <label>Search</label>
            <div class="input-group">
              <input
                v-model="transaction_filters.search"
                class="form-control selector"
                placeholder="Search"
                type="text"
              />
              <span
                class="input-group-text no-border right dark"
                id="search-form-icon"
              >
                <img :src="search_icon" alt="" />
              </span>
            </div>
          </div>
          <div class="form-group w-input-20">
            <label>Select range</label>
            <BaseDateRangePicker
              style="width: 100%"
              v-model="transaction_filters.date"
              format="YYYY-MM-DD"
              :showLabel="false"
              :icon="calendar_icon"
              label="Date Range"
              :dateDefault="dateRange"
              placeholder="From - To"
              :timepicker="true"
              :everydayMinDate="everydayMinDate"
              :everydayMaxDate="everydayMaxDate"
            />
          </div>
          <div class="form-group w-input-20">
            <label>Connection</label>
            <Multiselect
              v-model="transaction_filters.exchange_connection"
              :hideSelected="true"
              :options="connection_options"
              class="form-control selector"
              label="name"
              placeholder="Select a connection"
              valueProp="token"
            >
              <template v-slot:caret>
                <span class="multiselect-caret">
                  <img :src="icon_caret" alt="" />
                </span>
              </template>
            </Multiselect>
          </div>
        </div>
        <div class="transaction-bottom-row-filters">
          <div class="form-group w-input-20">
            <label>Select type</label>
            <div class="status-box">
              <div
                :class="{ active: transaction_filters.event_type == 'DEPOSIT' }"
                @click="setTransactionType('DEPOSIT')"
              >
                Deposit
              </div>
              <div
                :class="{
                  active: transaction_filters.event_type == 'TRANSFERS',
                }"
                @click="setTransactionType('TRANSFERS')"
              >
                Transfers
              </div>
              <div
                :class="{
                  active: transaction_filters.event_type == 'WITHDRAW',
                }"
                @click="setTransactionType('WITHDRAW')"
              >
                Withdraw
              </div>
              <div
                :class="{ active: transaction_filters.event_type == null }"
                @click="setTransactionType(null)"
              >
                All
              </div>
            </div>
          </div>
          <div class="form-group w-input-20">
            <label>Items per page</label>
            <Multiselect
              v-model="transaction_filters.limit"
              :canClear="false"
              :canDeselect="false"
              :hideSelected="true"
              :options="item_per_page_options"
              class="form-control selector"
              label="name"
              placeholder="Max per page: 10"
              valueProp="value"
            >
              <template v-slot:caret>
                <span class="multiselect-caret">
                  <img :src="icon_caret" alt="" />
                </span>
              </template>
            </Multiselect>
          </div>
        </div>
      </div>

      <div class="transaction-table">
        <ProcessingLoader v-if="transactions_on_loading" />
        <AlertErrorInformation v-else-if="transactions_status.error" />
        <div v-else-if="transactions.length > 0" class="table-responsive">
          <Table
            :actions="true"
            :data="transactions"
            :headers="columnHeaders"
            class="transactions-table"
            title="Transactions"
          >
            <template v-slot:event_type="{ text }">
              <span v-if="text == 'DEPOSIT'" class="event-type">Deposit</span>
              <span v-else-if="text == 'TRANSFERS'" class="event-type"
                >Transfers</span
              >
              <span v-else-if="text == 'WITHDRAW'" class="event-type"
                >Withdraw</span
              >
              <span v-else class="event-type">{{ text }}</span>
            </template>
            <template v-slot:created_at="{ text }">
              <span v-if="text != ''">{{ fomatterDate(text) }}</span>
              <span v-else class="no-data">no data</span>
            </template>
            <template v-slot:note="{ text }">
              <span v-if="text != ''">{{ text }}</span>
              <span v-else class="no-data">no data</span>
            </template>
            <template v-slot:change="{ text }">
              <span v-if="text != ''" class="d-flex value"
                ><span class="icon"><img :src="dollar" /></span>
                {{ formatter(text) }}
              </span>
              <span v-else class="no-data">no data</span>
            </template>
            <template v-slot:actions="id">
              <div class="dropdown">
                <span
                  class="action-button"
                  data-toggle="dropdown"
                  data-reference="parent"
                  data-offset="-135,0"
                >
                  <img :src="menu_icon" />
                </span>
                <ul class="dropdown-menu">
                  <li @click="editTransaction(id)">
                    <img :src="edit_icon" />Edit
                  </li>
                  <li @click="deleteTransaction(id)">
                    <img :src="trash_icon" />Delete
                  </li>
                </ul>
              </div>
            </template>
            <template #pagination>
              <Pagination
                :pagination="paginate"
                :go-first-page-callback="goFirstPage"
                :go-last-page-callback="goLastPage"
                :go-next-page-callback="goNextPage"
                :go-prev-page-callback="goPrevPage"
              />
            </template>
          </Table>
        </div>
        <AlertNoInformation v-else />
      </div>
    </div>
    <CreateTransactionModal
      :show="show_transaction_modal"
      :is-edit="is_edit_transaction"
      @set_transaction_edit="is_edit_transaction = false"
    />
  </div>
</template>
<script>
import AlertInformation from "../../../commons/alerts/AlertInformation.vue";
import AlertErrorInformation from "../../../commons/alerts/AlertErrorInformation.vue";
import AlertNoInformation from "../../../commons/alerts/AlertNoInformation.vue";
import BaseDateRangePicker from "@/components/commons/BaseDateRangePicker";
import calendarIcon from "@/assets/images/calendar.svg";
import CreateTransactionModal from "./modals/CreateTransactionModal.vue";
import Dollar from "../../../../../public/common/dollar.png";
import EditWhiteIcon from "../../../../../public/common/edit-white-icon.svg";
import IconCaret from "../../../../../public/common/select-arrow-down.svg";
import MenuOptions from "../../../../../public/common/menu-dots-vertical.png";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2";
import NumberFormatter from "../../../../helpers/formatters/number_formatter";
import SearchIcon from "../../../../../public/common/search.png";
import Table from "../../../commons/table/Table.vue";
import TrashIcon from "../../../../../public/common/trash-icon.svg";
import Pagination from "../../../commons/Pagination.vue";
import ProcessingLoader from "../../../commons/alerts/ProcessingLoader.vue";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "TransactionsTab",
  data() {
    return {
      calendar_icon: calendarIcon,
      columnHeaders: [
        { name: "event_type", title: "Type", align: "left" },
        { name: "connection_name", title: "Connection", align: "left" },
        { name: "change", title: "Amount", align: "left" },
        { name: "created_at", title: "Date", align: "left" },
        { name: "note", title: "Note", align: "left" },
        {
          title: "",
          action: true,
          id: "token",
        },
      ],
      dateRange: "",
      dollar: Dollar,
      edit_icon: EditWhiteIcon,
      icon_caret: IconCaret,
      is_edit_transaction: false,
      item_per_page_options: [
        { value: "5", name: "Max per page: 5" },
        { value: "10", name: "Max per page: 10" },
        { value: "20", name: "Max per page: 20" },
      ],
      menu_icon: MenuOptions,
      search_icon: SearchIcon,
      showCreateModal: false,
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
            <g clip-path="url(#clip0_1634_940)">
            <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
            </g>
            <defs>
            <clipPath id="clip0_1634_940">
            <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
            </clipPath>
            </defs>
            </svg> Success`,
      trash_icon: TrashIcon,
    };
  },
  components: {
    AlertErrorInformation,
    AlertInformation,
    AlertNoInformation,
    Multiselect,
    BaseDateRangePicker,
    Table,
    Pagination,
    ProcessingLoader,
    CreateTransactionModal,
  },
  computed: {
    ...mapState("connections", ["connection_list_selector"]),
    ...mapState("investor_configuration", ["show_transaction_modal"]),
    ...mapState("transactions", ["transaction_filters", "transaction_list"]),

    connection_options() {
      return this.connection_list_selector &&
        this.connection_list_selector._data
        ? this.connection_list_selector._data.data
        : null;
    },
    current_page() {
      return this.transaction_list._data.paginate
        ? this.transaction_list._data.paginate.current_page
        : null;
    },
    paginate() {
      return this.transaction_list._data.paginate
        ? this.transaction_list._data.paginate
        : null;
    },
    transactions() {
      return this.transaction_list && this.transaction_list._data
        ? this.transaction_list._data.data
        : null;
    },
    transactions_on_loading() {
      return this.transaction_list._status._on_loading;
    },
    transactions_status() {
      return this.transaction_list && this.transaction_list._data
        ? this.transaction_list._data
        : [];
    },
  },
  created() {
    this.$store.dispatch("transactions/transaction_list", {});
    this.$store.dispatch("connections/connection_list_selector");
  },
  methods: {
    deleteTransaction(id) {
      const body = {
        token: id.id,
      };

      Swal.fire({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#1c2030",
        confirmButtonText: "Delete",
        preConfirm: async (value) => {
          await this.performDeleteTransaction(body);
        },
      }).then(async (result) => {
        if (result.data) {
        }
      });
    },
    editTransaction(id) {
      const data = this.transaction_list._data.data.find(
        (transaction) => transaction.token == id.id
      );

      const form_data = {
        amount: Math.abs(data.change),
        note: data.note,
        token: data.token,
        event: data.event_type,
        exchange_connection: data.exchange_connection,
        snapshot_token: data.token,
      };

      this.$store.commit("transactions/set_creation_form", form_data);
      this.is_edit_transaction = true;
      this.$store.commit("investor_configuration/show_transaction_modal", true);
    },
    everydayMinDate() {
      return new Date();
    },
    everydayMaxDate() {
      return new Date();
    },
    formatter(number) {
      return NumberFormatter(number);
    },
    fomatterDate(date) {
      return moment(date).format("YYYY/MM/DD HH:mm:ss");
    },
    goFirstPage() {
      this.$store.dispatch("transactions/transaction_list", {
        page: 1,
      });
    },
    goLastPage() {
      this.$store.dispatch("transactions/transaction_list", {
        page: this.paginate.total_pages,
      });
    },
    goNextPage() {
      this.$store.dispatch("transactions/transaction_list", {
        page: this.current_page + 1,
      });
    },
    goPrevPage() {
      this.$store.dispatch("transactions/transaction_list", {
        page: this.current_page - 1,
      });
    },
    async performDeleteTransaction(body) {
      return new Promise((resolve) => {
        this.$store.dispatch("transactions/delete_transaction", {
          callback: ({ response }) => {
            if (response.error) {
              this.$toastr.error(`${response.message}`, `${this.error}`);
              return;
            } else {
              this.$toastr.success(
                "Transaction deleted successfully.",
                `${this.success}`
              );
              this.$store.dispatch("transactions/transaction_list", {});
            }
            resolve();
          },
          body,
        });
      });
    },
    reloadService() {
      this.$store.dispatch("transactions/transaction_list", {});
    },
    setTransactionType(type) {
      this.$store.commit("transactions/set_transaction_type", type);
    },
  },
  watch: {
    "transaction_filters.date": function (value) {
      this.reloadService();
    },
    "transaction_filters.event_type": function (value) {
      this.reloadService();
    },
    "transaction_filters.limit": function (value) {
      this.reloadService();
    },
    "transaction_filters.portfolio_token": function (value) {
      this.reloadService();
    },
    "transaction_filters.search": function (value) {
      this.reloadService();
    },
    "transaction_filters.exchange_connection": function (value) {
      this.reloadService();
    },
  },
};
</script>
<style lang="scss">
@import "../../../../styles/pages/investor_configuration/transaction_tab.scss";
</style>
