<template>
  <b-modal  size="xl" title="Open Orders" v-model="show"  hide-footer ok-only @hide="closeModal">
      
  </b-modal>
</template>

<script>
export default {
    name: 'OpenSymbolsModal',
    props: ['show'],
    methods: {
      closeModal() {
        this.$emit('closeModal')
      }
    }
}
</script>