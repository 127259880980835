<template>
<b-col cols="12" lg="6" class="p-0 sidebar__user_information nice-scroll" v-if="investor.info">
  <div class="p-2">
    <div class="col-md-6">
      <label class="text-muted">Investor Conditions</label>
    </div>
    XD
    <!--
    <template v-for="(c, index) in conditions_token">
    <Table v-if="investor && (investor.info.investor.status == 1 || investor.info.investor.status == 2) || 1"  :token ="c" :key="index" :items="items.length>0?items[index] : []" :loading="loading" />
    </template>
    <div v-if="$store.state.info_investor && conditions_token.length === 0">
      <div class="col-12">
        <b-alert class="mb-2 ml-3" show variant="dark">
          <span >This account doesn't have conditions tokens.</span>
        </b-alert>
      </div>
    </div>
    <div v-if="investor && (investor.info.investor.status !== 1 && investor.info.investor.status !== 2) && conditions_token.length > 0">
      <div class="col-12">
        <b-alert class="mb-2 ml-3" show variant="dark">
          <span >Please turn on the account if you want to see conditions.</span>
        </b-alert>
      </div>
    </div>-->
  </div>
</b-col>
</template>
<script>
import handleSocket from '@/helpers/socket';
import Table from './conditions-investor-table/Table.vue';

export default {
  name: 'ConditionsInvestorTable',
  data() {
    return {
      url_socket: 'investors_conditions_timeframe',
      items: [],
      loading: false,
    };
  },
  components: {
    Table,
  },
  computed: {
    investor() {
      if(this.$store.state.info_investor) return this.$store.state.info_investor
      return {};
    },
    conditions_token() {
      if(this.$store.state.info_investor && this.$store.state.info_investor.conditions) {
        if (this.$store.state.info_investor.conditions.length > 0 ) {
          return this.$store.state.info_investor.conditions.map((c) => c.token);
        }
      }
      return [];
    },
  },
  methods: {
    conneting() {
      if(this.$route.name.toLowerCase() !== 'investor' && !this.$store.state.expert_mode) return;
      this.websocket = handleSocket(this.url_socket, this.onOpen, this.onClose, this.onMessage, this.onError);
    },
    sendConnect() {
      if(this.$route.name.toLowerCase() !== 'investor' || !this.$store.state.expert_mode) return;
      // token_investor
      this.doSend(this.investor.info.token);      
      // condition_token
      this.doSend(JSON.stringify(this.conditions_token));
      // is_real
      this.doSend(this.investor.info.investor.is_real);
    },
    onOpen() {
      if(this.$route.name.toLowerCase() !== 'investor' && !this.$store.state.expert_mode) return;
      if (this.investor) {
        let interval = setInterval(() => {
          if (this.investor.conditions.length > 0 && (this.investor.info.investor.status == 1 || this.investor.info.investor.status == 2)) {
              this.writeToScreen("CONNECTED");
              this.sendConnect();
          }
        }, 250);
      } else {
        this.writeToScreen("CONNECTED");
        this.sendConnect();
      }
    },
    onMessage(evt) {
      if(evt.data) {
        let data = JSON.parse(evt.data);
        if (data instanceof Array) this.items = data;
        if (this.items.length > 0) this.loading = false;
        console.log(data);
      }
    },
    onClose(evt) {
      this.writeToScreen("DISCONNECTED");
      setTimeout(() => {
        if (this.$route.name.toLowerCase() === 'investor' && this.$store.state.expert_mode) {
          this.conneting();
        }
      }, 1500);
    },
    onError(evt) {
	    console.error(evt);
	  },
    doSend(message) {
      this.websocket.send(message);
    },
    writeToScreen(message) {
      console.log("writeToScreen", message);
    },
  },
  watch: {
    '$store.state.expert_mode'(value) {
      if(value){
        //this.conneting();
      }
      if (this.items.length === 0){
        // this.loading = true;
      } 
    },
  },
}
</script>