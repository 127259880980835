<template>
  <b-col cols="12" lg="2" class="p-0 admin_sidebar nice-scroll">
    <div class="d-block p-2 mb-2">
      <div class="separator">
        Backtestings filtering
      </div>
      <div class="card p-2 mb-2 text-muted small" v-if="(search || showLabelStatusSelected() || showFavorites) && !$store.state.backtest_loading">
        Applied Filters <br> 
        <span v-if="search">
          {{ "Search: "+search }} <br> 
        </span>
        <span v-if="endpoint">
          {{ "Endpoint: " }}
          <span v-if="endpoint == 'develop'">Develop</span>
          <span v-if="endpoint == 'production'">Production</span> <br>
        </span>
        <span v-if="dateStart && dateEnd">
          {{ "By date range: " }}
          <span >{{dateStart}} - {{dateEnd}} </span>
        </span>
        <span v-if="sortBy">
          {{ "Order by: "}}
          <span v-if="sortBy == 'asc.created_at'"> ↑ By Created Date </span>
          <span v-if="sortBy == 'desc.created_at'"> ↓ By Created Date </span>
          <span v-if="sortBy == 'asc.updated_at'"> ↑ By Updated Date </span>
          <span v-if="sortBy == 'desc.updated_at'"> ↓ By Updated Date </span>

        </span>
        <span v-if="showLabelStatusSelected()">
          {{ "Status: "+showLabelStatusSelected() }} <br>
        </span>
        <span v-if="showFavorites">
          {{ 'Only backtest favorites' }}
        </span>
      </div>

      <div class="card mb-2" v-if="$store.state.backtest_loading">
        <b-card>
          <b-skeleton animation="wave" width="75%"></b-skeleton>
          <b-skeleton animation="wave" width="55%"></b-skeleton>
        </b-card>
      </div>

      <div class="card p-2 mb-0" v-if="!$store.state.backtest_loading">
        <label>Search</label>
        <input type="text" class="form-control" placeholder="Enter text here." v-model="search" id="searchBacktest">
        <p class="text-muted small mb-0 mt-1">
          Search within the list of backtestings.
        </p>
      </div>
      <div class="card mb-2" v-if="$store.state.backtest_loading">
        <b-card>
          <b-skeleton animation="wave" width="75%"></b-skeleton>
          <b-skeleton animation="wave" width="55%"></b-skeleton>
        </b-card>
      </div>

      <div class="card p-2 mb-0 my-2" v-if="!$store.state.backtest_loading">
        <label for="" class="d-flex justify-content-between">By date range <b-icon-x-circle-fill @click="resetDates"></b-icon-x-circle-fill></label>
        <div class="d-flex">
          <b-form-datepicker id="datepickerStart" placeholder="Choose a date" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" v-model="dateStart" class="mb-2 text-white" dark :max="new Date()"></b-form-datepicker>
          <b-form-datepicker id="datepickerEnd" placeholder="Choose a date" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" v-model="dateEnd" class="mb-2 text-white" dark :max="new Date()" :min="dateStart" ></b-form-datepicker>
        </div>
      </div>
      <div class="card mb-2" v-if="$store.state.backtest_loading">
        <b-card>
          <b-skeleton animation="wave" width="75%"></b-skeleton>
          <b-skeleton animation="wave" width="55%"></b-skeleton>
        </b-card>
      </div>
      <div class="card p-2 mb-2" v-if="!$store.state.backtest_loading">
        <label>Endpoint</label>
        <select class="form-control float-right d-inline-block" v-model="endpoint">
          <option value="develop">Develop</option>
          <option value="production">Production</option>
        </select>
        <div class="loading" v-show="$store.state.backtest_loading">
          <b-spinner variant="light"></b-spinner>
        </div>
      </div>
      <div class="card mb-2" v-if="$store.state.backtest_loading">
        <b-card>
          <b-skeleton animation="wave" width="65%"></b-skeleton>
          <b-skeleton animation="wave" width="85%"></b-skeleton>
        </b-card>
      </div>
      <div class="card p-2 mb-2" v-if="!$store.state.backtest_loading">
        <label>Order by</label>
        <select class="form-control float-right d-inline-block" v-model="sortBy">
          <option value="asc.created_at"> ↑ By Created Date </option>
          <option value="desc.created_at"> ↓ By Created Date </option>
          <option value="asc.updated_at"> ↑ By Updated Date </option>
          <option value="desc.updated_at"> ↓ By Updated Date </option>
        </select>
        <div class="loading" v-show="$store.state.backtest_loading">
          <b-spinner variant="light"></b-spinner>
        </div>
      </div>
      <div class="card mb-2" v-if="$store.state.backtest_loading">
        <b-card>
          <b-skeleton animation="wave" width="65%"></b-skeleton>
          <b-skeleton animation="wave" width="85%"></b-skeleton>
        </b-card>
      </div>

      <div class="card p-2 mb-2" v-if="!$store.state.backtest_loading">
        <label>Status</label>
        <select class="form-control" v-model="selectStatus">
          <option v-for="status in Object.keys($store.state.count_backtest)" :value=" status == 'Pending' ? 1 :  status == 'Cancelled' ? 8 : status == 'Running' ? 2 : status == 'Close' ? 4 : 0 " :key="status"> {{status}} ({{$store.state.count_backtest[status]}}) </option>
        </select>
      </div>
      <div class="card mb-2" v-if="$store.state.backtest_loading">
        <b-card>
          <b-skeleton animation="wave" width="65%"></b-skeleton>
          <b-skeleton animation="wave" width="85%"></b-skeleton>
        </b-card>
      </div>

      <div class="card p-2 mb-2" v-if="!$store.state.backtest_loading">
        <b-form-checkbox v-model="showFavorites">
          Only backtest favorites
        </b-form-checkbox>
      </div>
      <div class="card mb-2" v-if="$store.state.backtest_loading">
        <b-card>
          <b-skeleton animation="wave" width="75%"></b-skeleton>
        </b-card>
      </div>


      <div class="card p-2 mb-2" v-if="!$store.state.backtest_loading">
        <b-button variant="dark" class="btn-sm" @click="showModalFilterValidBacktesting">
          Show filter valid backtesting
        </b-button>
      </div>
      <div class="card mb-2" v-if="$store.state.backtest_loading">
        <b-card>
          <b-skeleton animation="wave" width="75%"></b-skeleton>
        </b-card>
      </div>


      <div class="card p-2 mb-2" v-if="!$store.state.backtest_loading">
        <b-list-group>
          <b-list-group-item v-for="(backtest, key) in filtersBacktest" @click="showDetail(backtest)" :key="key" :class="[ $store.state.token_backtest_detail &&  $store.state.token_backtest_detail === backtest.token ? 'li-active' : 'li-inactive']">
            <div class="d-block">
              <span v-b-tooltip.hover :title="backtest.token">
                <span style="font-size: 14px;" v-if="backtest">
                  <b-icon-bookmark-check style="color: gray;" v-if="!isFavorites(backtest.token)" @click="addFavorite(backtest.token)"></b-icon-bookmark-check>
                  <b-icon-bookmark-check-fill v-if="isFavorites(backtest.token)" @click="deleteFavorite(backtest.token)"></b-icon-bookmark-check-fill>
                </span>
                <!--<span @click="showDetail(backtest)">-->
                <span>
                  {{ backtest.token | shortToken}}
                </span>
              </span>
              <span class="float-right badget badget-info">
                {{backtest.progress.toString().substr(0,4)}}%
              </span>
            </div>

            <div class="d-block text-muted small mt-1">
              {{backtest.note}}
            </div>
            <div class="d-block text-muted small mt-1">
             <b>Create the:</b> {{backtest.created_at}}
            </div>
            <div class="d-block text-muted small mt-1">
             <b>Execution time:</b> {{handleTimeDiff(backtest.started_at, backtest.updated_at)}}
            </div>
            <div class="d-block text-success small mt-1" v-if="backtest.its_a_good_backtests">
              Good Results
            </div>
          </b-list-group-item>
          <b-list-group-item :key="'pgination'" class="d-flex">
            <b-button variant="dark" class="btn-sm mr-auto" @click="getBacktest($store.state.backtest_paginate.current_page - 1)" :disabled="$store.state.backtest_paginate.current_page - 1 === 0 ">
              <b-icon-arrow-left></b-icon-arrow-left>
            </b-button>
            <span>{{$store.state.backtest_paginate.current_page}}/{{$store.state.backtest_paginate.total_pages}}</span>
            <b-button variant="dark" class="btn-sm ml-auto" @click="getBacktest($store.state.backtest_paginate.current_page + 1)" :disabled=" $store.state.backtest_paginate.current_page + 1 > $store.state.backtest_paginate.total_pages">
              <b-icon-arrow-right></b-icon-arrow-right>
            </b-button>
          </b-list-group-item>
          <b-list-group-item :key="'resetbtn'" class="p-0 my-2">
            <b-button variant="dark" class="btn-sm" @click="resetFilters">
              Reset filters
            </b-button>
          </b-list-group-item>
        </b-list-group>

        <b-alert class="mb-2" show variant="danger" v-if="filtersBacktest.length === 0 && !$store.state.backtest_loading">
          <span v-if="search || selectStatus || showFavorites">No backtest found with applied filters</span>
          <span v-else> No found backtest. </span>
        </b-alert>
      </div>
      <div class="card p-2 mb-2" v-if="$store.state.backtest_loading">
        <b-list-group>
          <b-list-group-item>
            <div class="d-block">
              <b-skeleton animation="wave" width="75%"></b-skeleton>
              <b-skeleton animation="wave" width="10%"></b-skeleton>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </b-col>
</template>
<script>
  import BaseDateRangePicker from '@/components/commons/BaseDateRangePicker';
  import moment from 'moment';

	export default {
		name: 'BacktestSlide',
    components: {
      BaseDateRangePicker,
    },
    created(){

      this.$store.commit('bwfs_get_count_backtest_by_status', this);
      if (this.$store.state.backtests.length === 0) {
        if (this.$route.params.hasOwnProperty('token')) {
          const payload = {
          vm: this,
          data: {
            page: 1,
            limit: 1,
            search: this.$route.params.token,
            status: 0,
            date_start: '1990-01-01',
            date_end: '2030-01-01',
            favorites: 0,
            user_token: this.$store.state.user.token,
            order_by: this.sortBy.split(".")[1],
            order_sort: this.sortBy.split(".")[0],
          }
        };
        this.$store.commit('bwfs_get_backtest_selected', payload);
        // this.search = this.$route.params.token;
        // this.selectStatus = 0;
        }
        this.getBacktest();
      }
    },
    computed: {
      filtersBacktest() {
        return this.$store.state.backtests;
        if (!this.selectStatus && !this.search && !this.showFavorites) {
          return this.$store.state.backtests;
        }
        let backtestFilters = this.$store.state.backtests;
        if (this.dateStart && this.dateEnd  && ((new Date(this.dateStart+" 00:00:00")).getTime() < (new Date(this.dateEnd+" 23:59:59")).getTime())){
          backtestFilters = backtestFilters.filter(b => {
            return (new Date(b.created_at)).getTime() >= (new Date(this.dateStart+" 00:00:00")).getTime()  &&  (new Date(b.created_at)).getTime() <= (new Date(this.dateEnd+" 23:59:59")).getTime()
          });
        }
        if (this.selectStatus && this.selectStatus !== 'all') {
          backtestFilters = backtestFilters.filter((b) => {
            return parseInt(b.status) === parseInt(this.selectStatus);
          });
        }
        if (this.search) {
         backtestFilters = backtestFilters.filter((b) => {
            if (b.token.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || b.note.toLowerCase().indexOf(this.search) > -1 || b.created_at.toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
              return true; 
            }
            return false;
          });
        }
        if (this.showFavorites) {
          let favorites = this.$store.state.backtest_favorites.map((elem) => elem.backtest_token);
          backtestFilters = backtestFilters.filter((b) => {
            return favorites.filter((token) => token == b.token).length > 0;
          });
        }
        return backtestFilters;
      },
      handleResultBacktest() {
        return $store.state.backtests.length;
      },
      countPending() {
        return this.$store.state.backtests.filter((b) => parseInt(b.status) === 1).length;
      },
      countCancelled() {
        return this.$store.state.backtests.filter((b) => parseInt(b.status) === 8).length;
      },
      countRunning() {
        return this.$store.state.backtests.filter((b) => parseInt(b.status) === 2).length;
      },
      countClose() {
        return this.$store.state.backtests.filter((b) => parseInt(b.status) === 4).length;
      },
    },
    mounted() {
      this.addEventSeach();
    },
    data() {
			return {
        backtests: [],
        selectStatus: '4',
        search: '',
        endpoint: 'production',
        showFavorites: false,
        isDateFilter: false,
        isFirst: false,
        dateStart: '',
        dateEnd: '',
        sortBy: 'desc.created_at',
			};
		},
    methods: {
      showModalFilterValidBacktesting() {
        this.$bvModal.show('modal-show-valid-success');
      },
      getBacktest(page=1) {
        const payload = {
          vm: this,
          data: {
            page: page,
            limit: 25,
            search: this.search,
            status: this.selectStatus,
            date_start: this.dateStart ? this.dateStart : '1990-01-01',
            date_end: this.dateEnd ? this.dateEnd : '2030-01-01',
            favorites: this.showFavorites ? 1 : 0,
            user_token: this.$store.state.user.token,
            order_by: this.sortBy.split(".")[1],
            order_sort: this.sortBy.split(".")[0],
          },
          endpoint: this.endpoint,
        };
        this.$store.commit('bwfs_get_backtest_favorites', this);
        this.$store.commit('bwfs_get_backtest', payload);
      },
      isFavorites(token) {
        return this.$store.state.backtest_favorites.filter(b => b.backtest_token === token).length > 0;
      },
      addFavorite(token) {
        this.$store.commit('bwfs_add_backtest_favorites', {backtestToken:token, vm: this});
      },
      deleteFavorite(token) {
        this.$store.commit('bwfs_delete_backtest_favorites', {backtestToken:token, vm: this});
      },
      showDetail(backtest){
        if (this.$store.state.token_backtest_detail === backtest.token ) {
          if (this.$route.params.hasOwnProperty('token') && this.$route.params.token && this.$route.params.token === backtest.token) {
            console.log(this.$route.params.hasOwnProperty('token'), this.$route.params.token, this.$route.params.token);
            return;
          }
        }
        this.$store.commit('bwfs_token_backtest_detail', backtest.token);
        this.$store.commit('bwfs_backtest_detail', backtest);
        this.$router.push({ name: 'BacktestingDetail', params: { token: backtest.token } });
        setTimeout(() => {
          document.querySelector(".admin_content").scrollTo(0, 1500);
        }, 1500);
      },
      showLabelStatusSelected() {
        if (parseInt(this.selectStatus) === 1) {
          return 'Pending';
        } else if (parseInt(this.selectStatus) === 2) {
          return 'Running';
        } else if (parseInt(this.selectStatus) === 3) {
          return 'Cancelled';
        } else if (parseInt(this.selectStatus) === 4) {
          return 'Ready';
        } else if (parseInt(this.selectStatus) === 0) {
          return 'All';
        }
        return false;
      },
      addLocalStorageFilter() {
        let filters = {};
        filters.search = this.search;
        filters.selectStatus = this.selectStatus;
        filters.showFavorites = this.showFavorites;
        filters.dateStart = this.dateStart;
        filters.dateEnd = this.dateEnd;
        filters.sortBy = this.sortBy;
        if (!this.endpoint) {
          this.endpoint = 'production';
        }
        filters.endpoint = this.endpoint;
        this.getBacktest(1);
        localStorage.setItem('filters_backtest', JSON.stringify(filters));
      },
      applyFilters() {
        let filters = {};
        if (localStorage.getItem('filters_backtest')) {
          filters = JSON.parse(localStorage.getItem('filters_backtest'));
        }
        if (filters.search) {
          this.search = filters.search;
        } else {
          this.search = '';
        }
        if (filters.selectStatus >= 0) {
          this.selectStatus = filters.selectStatus;
        } else {
          this.selectStatus = 4;
        }
        if (filters.showFavorites) {
          this.showFavorites = filters.showFavorites;
        } else {
          this.showFavorites = false;
        }
        if (filters.dateStart) {
          this.dateStart = filters.dateStart;
        } else {
          this.dateStart = '';
        }
        if (filters.dateEnd) {
          this.dateEnd = filters.dateEnd;
        } else {
          this.dateEnd = '';
        }
        if (filters.endpoint) {
          this.endpoint = filters.endpoint;
        } else {
          this.endpoint = '';
        }
      },
      addEventSeach() {
        const myInput = document.getElementById("searchBacktest");
        if (!myInput) {
          setTimeout(() => {
            this.addEventSeach();
          }, 500);
        } else {
          myInput.addEventListener("keyup", this.$debounce(this.addLocalStorageFilter));
        }
      },
      handleTimeDiff(created_at, close_date_at) {
        if (!created_at || !close_date_at) {
          return '---';
        }

        const updated = moment( new Date(created_at.split(".")[0]));
        const time = moment( new Date(close_date_at.split(".")[0]));

        if (time.diff(updated, 'DAYS') > 0) {
          if (time.diff(updated, 'DAYS') >= 31) { 
            let months = parseInt((time.diff(updated, 'DAYS') / 31));
            if (months >= 12 ) {
              return `${parseInt((months / 12))} Years`;
            }
            return `${months} Motnhs`;
          }
        }
        if (time.diff(updated, 'hours') > 0) {
          return `${time.diff(updated, 'hours')} Hours`;
        }
        if (time.diff(updated, 'MINUTES') > 0) {
          return `${time.diff(updated, 'MINUTES')} Minutes`;
        }
        if (time.diff(updated, 'SECONDS') > 0) {
         return `${time.diff(updated, 'SECONDS')} Seconds`;
        }
        return '---';
      },
      resetDates() {
        this.dateStart = '';
        this.dateEnd = '';
        this.addLocalStorageFilter();
      },
      resetFilters() {
        this.dateStart = '';
        this.dateEnd = '';
        this.search = '';
        this.endpoint = 'production';
        this.selectStatus = 4;
        this.addLocalStorageFilter();
      },
    },
    filters: {
      shortToken(token) {
        return token.substr(0,13)+"...";
      },
    },
    watch: {
      selectStatus() {
        this.addLocalStorageFilter();
      },
      showFavorites() {
        this.addLocalStorageFilter();
      },
      '$store.state.backtests'() {
        this.applyFilters();
      },
      dateStart() {
        if (!this.dateEnd) {
          return;
        }
        this.addLocalStorageFilter();
      },
      dateEnd() {
        if (!this.dateStart) {
          return;
        }
        this.addLocalStorageFilter();
      },
      '$store.state.backtest_loading'(value) {
        if(!value) {
          this.addEventSeach();
        }
      },
      sortBy() {
        this.addLocalStorageFilter();
      },
      endpoint() {
        this.addLocalStorageFilter();
        this.$store.commit('bwfs_get_count_backtest_by_status', this);
        if (this.isFirst) {
          setTimeout(() => {
            this.$router.push({ name: 'Backtestings'});
            window.location.reload();
          }, 500);
        } else {
          this.isFirst = true;
        }
      },
    },
  };
</script>
<style>
  .li-active{
    border-left: 2px solid #19c9f8
  }
  .li-inactive{
    border-left: 2px transparent #19c9f8
  }
  svg{
    color: #fff !important;
    fill: #fff !important;
  }

  #datepickerEnd__dialog_{
    zoom: 0.8;
  }
  #datepickerStart__dialog_{
    zoom: 0.8;
    transform: translate3d(5px, 45px, 0px);
  }
  .b-form-btn-label-control.form-control > label {
    font-size: 11px;
    line-height: 1;
  }
  div#datepickerStart__outer_, div#datepickerEnd__outer_{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 35px !important;
  }
</style>
