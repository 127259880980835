<template>
  <div
    class="p-2"
    v-if="
      !$route.params.view_for_admin && $store.state.investors_view_mode == 0
    "
    id="notifications_container"
  >
    <div class="separator">
      Notifications
      <span class="float-right mono">({{ notifications.length }})</span>
    </div>

    <div
      class="form-group d-flex aling-item-center justify-content-end mb-2"
      v-if="$store.state.user.token || $route.name.toLowerCase() == 'investors'"
    >
      <input
        type="text"
        class="form-control float-right d-inline-block"
        id="searchNotificationInput"
        placeholder="Search by message and date..."
        v-model="search_notification"
      />
    </div>

    <transition name="fade">
      <div
        class="item-data mb-2"
        :class="[animate ? 'animate__pulse' : null]"
        v-if="new_notification.msg"
      >
        <div class="description" style="overflow-wrap: break-word">
          {{ new_notification.msg ? new_notification.msg : new_notification }}
        </div>
        <div class="label small">
          {{
            new_notification.date
              ? new_notification.date.substr(0, 19)
              : new_notification.date
          }}
        </div>
      </div>
    </transition>

    <div
      class="item-data mb-2"
      v-for="notification in notifications"
      v-if="
        notifications.length > 0 && !$store.state.investor_notifications_loading
      "
    >
      <div class="description">
        {{ notification.msg ? notification.msg : notification }}
      </div>
      <div class="label small">
        {{
          notification.date
            ? notification.date.substr(0, 19)
            : notification.date
        }}
      </div>
      <div
        class="label small"
        v-show="$route.name.toLowerCase() == 'investors'"
      >
        {{ notification.investor_name ? notification.investor_name : "" }}
      </div>
    </div>
    <b-skeleton-table
      :rows="2"
      :columns="1"
      :table-props="{ striped: true }"
      v-if="$store.state.investor_notifications_loading"
    ></b-skeleton-table>

    <b-list-group-item
      :key="'pgination'"
      class="d-flex p-0"
      v-if="
        !$store.state.investor_notifications_loading && notifications.length > 0
      "
    >
      <b-button
        variant="dark"
        class="btn-sm mr-auto"
        @click="
          getNotifications(
            $store.state.investor_notifications_paginate.current_page - 1
          )
        "
        :disabled="
          $store.state.investor_notifications_paginate.current_page - 1 === 0
        "
      >
        <b-icon-arrow-left></b-icon-arrow-left>
      </b-button>
      <span
        >{{ $store.state.investor_notifications_paginate.current_page }}/{{
          $store.state.investor_notifications_paginate.total_pages
        }}</span
      >
      <b-button
        variant="dark"
        class="btn-sm ml-auto"
        @click="
          getNotifications(
            $store.state.investor_notifications_paginate.current_page + 1
          )
        "
        :disabled="
          $store.state.investor_notifications_paginate.current_page + 1 >
          $store.state.investor_notifications_paginate.total_pages
        "
      >
        <b-icon-arrow-right></b-icon-arrow-right>
      </b-button>
    </b-list-group-item>
    <div class="col-12 p-0">
      <b-alert
        class="mb-2"
        show
        variant="dark"
        v-if="
          !$store.state.investor_notifications_loading &&
          $store.state.investor_notifications_paginate.records == 0 &&
          !new_notification.msg
        "
      >
        <span>No recent notifications.</span>
      </b-alert>
    </div>
  </div>
</template>
<script>
import handleSocket from "@/helpers/socket";
export default {
  name: "CardNotifications",
  mounted() {
    this.addEventSearch();
  },
  created() {
    if (this.$route.name.toLowerCase() == "investor") {
      if (!this.$store.state.info_investor.info) {
        let interval = setInterval(() => {
          if (this.$store.state.info_investor.info) {
            clearInterval(interval);
            this.getNotifications(1);
          }
        }, 250);
      } else {
        this.getNotifications(1);
      }
    }
  },
  computed: {
    notifications() {
      let cc = [];
      if (this.$store.state.investor_notifications.length > 0) {
        for (
          let i = 0;
          i < this.$store.state.investor_notifications.length;
          i++
        ) {
          let msg = this.$store.state.investor_notifications[i].message;
          try {
            cc.push({
              msg: JSON.parse(msg).msg ? JSON.parse(msg).msg : msg,
              date: JSON.parse(msg).date ? JSON.parse(msg).date : "--",
            });
          } catch (e) {
            let msg = this.$store.state.investor_notifications[i].message;
            if (!msg) {
              continue;
            }
            msg = msg.replace("{", "").replace("}", "").split(",");
            let newmsg = {
              msg: msg[0].replace('"msg":', ""),
              date: msg[2].replace('"date":', ""),
              token: msg[1].replace('"token":', ""),
            };
            cc.push(newmsg);
          }
        }
      }
      return cc;
    },
  },
  data() {
    return {
      token: "",
      websocket: undefined,
      new_notification: {},
      animate: false,
      search_notification: "",
      notificationsBK: [],
    };
  },
  methods: {
    getNotifications(page = 1) {
      if (this.$route.name.toLowerCase() == "investors") {
        const payload = {
          vm: this,
          data: {
            page: parseInt(page),
            limit: 15,
            investors_tokens: JSON.stringify(
              this.$store.state.investors_tokens
            ),
            search: this.search_notification,
          },
        };
        this.$store.dispatch("bwfs_get_investors_notifications", payload);
        return;
      }
      const payload = {
        vm: this,
        data: {
          page: page,
          limit: 15,
          investor_token: this.$store.state.info_investor.info.token,
          search: this.search_notification,
        },
      };
      this.$store.commit("get_notification_investor", payload);
    },
    sendConnect() {
      if (this.$route.name.toLowerCase() == "investor") {
        this.doSend(this.$store.state.info_investor.info.token);
        this.doSend(
          this.$store.state.info_investor.info.investor.is_real.toString()
        );
      } else {
        if (!this.$store.state.order_tracking_mode) return;
        if (this.$route.name.toLowerCase() == "investors") {
          this.doSend(
            JSON.stringify(this.$store.state.investors_orders_tokens_socket)
          );
          return;
        }
      }
    },
    onOpen() {
      if (this.$route.name.toLowerCase() == "investor") {
        if (!this.$store.state.info_investor.info) {
          let interval = setInterval(() => {
            if (this.$store.state.info_investor.info) {
              clearInterval(interval);
              this.writeToScreen("CONNECTED");
              this.sendConnect();
            }
          }, 250);
        } else {
          this.writeToScreen("CONNECTED");
          this.sendConnect();
        }
        return;
      }
      if (!this.$store.state.info_investor.info) {
        let interval = setInterval(() => {
          if (this.$store.state.info_investor.info) {
            clearInterval(interval);
            this.writeToScreen("CONNECTED");
            //this.sendConnect();
            this.doSend(this.$store.state.info_investor.info.token);
            this.doSend(
              this.$store.state.info_investor.info.investor.is_real.toString()
            );
          }
        }, 250);
      } else {
        this.writeToScreen("CONNECTED");
        this.doSend(this.$store.state.info_investor.info.token);
        this.doSend(
          this.$store.state.info_investor.info.investor.is_real.toString()
        );
        // this.sendConnect();
      }
    },
    onClose(evt) {
      this.writeToScreen("DISCONNECTED");
      /*setTimeout(() => {
	      	this.websocket = handleSocket('investors_notifications', this.onOpen, this.onClose, this.onMessage, this.onError);
	      }, 1500);*/
    },
    onMessage(evt) {
      if (evt.data) {
        this.handleAppendNotification(JSON.parse(evt.data));
      }
    },
    onError(evt) {
      console.log(evt);
      // this.writeToScreen('<span style="color: red;">ERROR:</span> ' + evt.data);
    },
    doSend(message) {
      this.websocket.send(message);
    },
    writeToScreen(message) {
      console.log("writeToScreen", message);
    },
    handleAppendNotification(data) {
      if (data == null) {
        return;
      }
      let payload = {
        msg: data.msg,
        date: data.date,
      };

      if (
        data.msg.toLowerCase().indexOf("open by") > -1 ||
        data.msg.toLowerCase().indexOf("closing by") > -1
      ) {
        this.$emit("refresh_order", true);
      }

      if (this.new_notification.msg) {
        // this.notifications.splice(0, 0, {...this.new_notification});
      } else {
        this.new_notification = {};
      }
      this.animate = true;
      this.new_notification = payload;
      setTimeout(() => {
        this.animate = false;
      }, 3000);
    },
    filterNotifications() {
      if (!this.search_notification) {
        if (this.notificationsBK.length > -1) {
          this.notifications = this.notificationsBK;
        }
        return;
      }
      this.notificationsBK = this.notifications;
      this.notifications = this.notifications.filter(
        (item) => item.msg.toLowerCase().indexOf(this.search_notification) > -1
      );
    },
    addEventSearch() {
      try {
        const myInput = document.getElementById("searchNotificationInput");
        myInput.addEventListener(
          "keyup",
          this.$debounce(this.getNotifications)
        );
      } catch (e) {
        setTimeout(() => {
          this.addEventSearch();
        }, 500);
      }
    },
  },
  watch: {
    "$store.state.investor_notifications_orders_search"(value) {
      if (value) {
        this.getNotifications();
        this.$store.commit("set_investor_notifications_orders_search", false);
      }
    },
    "$store.state.investors_notifications_searched_tracking"(value) {
      if (value && this.$route.name.toLowerCase() == "investors") {
        try {
          this.websocket = handleSocket(
            "investors_notifications_several",
            this.onOpen,
            this.onClose,
            this.onMessage,
            this.onError
          );
        } catch (e) {
          this.websocket = handleSocket(
            "investors_notifications_several",
            this.onOpen,
            this.onClose,
            this.onMessage,
            this.onError
          );
        }
        this.$store.commit(
          "set_investors_notifications_searched_tracking",
          false
        );
      }
    },
    "$store.state.info_investor.info.token"() {
      if (this.$route.name.toLowerCase() == "investor") {
        this.websocket = handleSocket(
          "investors_notifications",
          this.onOpen,
          this.onClose,
          this.onMessage,
          this.onError
        );
      }
    },
  },
  beforeDestroy() {
    this.websocket.close();
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.animate__pulse {
  -webkit-animation: pulse 4s infinite alternate;
}

@-webkit-keyframes pulse {
  0% {
    border: 1px solid #ffb802;
  }
  25% {
    border: 0px solid #1d1e25;
  }
  50% {
    border: 1px solid #ffb802;
  }
  75% {
    border: 0px solid #1d1e25;
  }
  100% {
    border: 1px solid #ffb802;
  }
}
</style>
