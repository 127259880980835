<template>
  <div class="panel page__supervisors">
    <b-container fluid>
      <b-row class="p-0">
        <PanelControl
          :loading="loading"
          @searchForm="getSearchForm"
        />
        <b-col cols="12" lg="10" class="p-0 admin_content nice-scroll">
          <div class="content p-2">
            <div class="row pl-3 pr-3">
              <div class="col-md-6 p-1 d-flex align-items-center">
                Total records found: {{records}}
              </div>
            </div>
            <div class="mt-3">
               <TableLMDD
                @loadingLMDD="getLoading"
                @records="getRecords"
                :searchForm="searchForm"
               />
            </div> 
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import TableLMDD from '../components/pages/lmdd/account/table.vue'
import PanelControl from '../components/pages/lmdd/account/searchPanel.vue'
import moment from 'moment'

export default {
  name:'LMDDAccount',
  components: {
    TableLMDD,
    PanelControl,
  },
  data () {
    return {
      loading: false,
      records: 0,
      searchForm: {}
    }
  },
  methods: {
    getLoading(loading) {
      this.loading = loading
    },
    getRecords(records) {
      this.records = records
    },
    getSearchForm(searchForm) {
      this.searchForm = searchForm
      console.log('search')
    }
  }
}
</script>