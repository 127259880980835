<template>
  <b-col cols="12" lg="2" class="p-0 admin_sidebar nice-scroll">
    <div class="d-block p-2 mb-2">
      <div class="separator">Dateframe Panel Control</div>
      <div class="card p-2 mb-2">
        <label>Start Date</label>
        <input v-model="startDate" class="form-control" type="date" />
      </div>
      <div class="card p-2 mb-2">
        <label>End Date</label>
        <input v-model="endDate" class="form-control" type="date" />
      </div>
      <div class="card p-2 mb-2">
        <label>LMDD SYMBOL MODE</label>
        <select v-model="symbolMode" class="form-control">
          <option :value="0">Select symbol</option>
          <option :value="1">All symbols</option>
        </select>
        <span class="text-muted small mt-1">
          Select one specific symbol or all symbols for the table...
        </span>
      </div>
      <div class="card p-2 mb-2" v-show="!loadingSymbol && symbolMode === 0">
        <label>Search symbol</label>
        <input
          type="search"
          v-model="search"
          class="form-control"
          placeholder="Enter text here."
          id="searchSymbolLMDD"
        />
      </div>
      <div class="card p-2 mb-2" v-if="!loadingSymbol && symbolMode === 0">
        <label>Symbols</label>
        <select v-model="symbol" class="form-control" id="searchSymbols">
          <option v-for="symbols in binanceOptions" :value="symbols">
            {{ symbols }}
          </option>
        </select>
      </div>
      <!-- <div class="card p-2 mb-2" v-if="!loadingSymbol && symbolMode === 0">
        <b-list-group-item :key="'pgination_binance'" class="d-flex">
          <b-button variant="dark" class="btn-sm mr-auto" @click="fetchOptions(paginate_binance.current_page - 1)" :disabled="paginate_binance.current_page - 1 === 0 ">
            <b-icon-arrow-left></b-icon-arrow-left>
          </b-button>
          <span>{{paginate_binance.current_page}}/{{paginate_binance.total_pages}}</span>
          <b-button variant="dark" class="btn-sm ml-auto" @click="fetchOptions(paginate_binance.current_page + 1)" :disabled="paginate_binance.current_page + 1 > paginate_binance.total_pages">
            <b-icon-arrow-right></b-icon-arrow-right>
          </b-button>
        </b-list-group-item>
     </div> !-->
      <div class="card p-2 mb-2" v-if="!loadingSymbol && symbolMode === 1">
        <label>Order By</label>
        <select v-model="orderBy" class="form-control">
          <option :value="'asc_sendTimestamp'">↑ TimeStamp</option>
          <option :value="'desc_sendTimestamp'">↓ TimeStamp</option>
          <!-- <option  :value="'asc_period'">↑ Period</option>
          <option  :value="'desc_period'">↓ Period</option> !-->
        </select>
      </div>
      <div class="card p-2 mb-2" v-if="!loadingSymbol && symbolMode === 1">
        <label>Filter By</label>
        <select v-model="filterType" class="form-control">
          <option :value="''">Select a filter/No filter</option>
          <option :value="'eventType'">Event Type</option>
          <option :value="'notice_types'">Notice Types</option>
        </select>
        <div v-if="filterType !== ''" class="mt-3">
          <select v-model="filterValue" class="form-control">
            <option
              v-for="filter in filterValueOptions"
              :value="filter.value"
              :key="filter.value"
            >
              {{ filter.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="card p-2 mb-2" v-if="!loadingSymbol && symbolMode === 1">
        <label>Select symbol from the table</label>
        <select v-model="symbolForAllLMDD" class="form-control">
          <option :value="''">ALL</option>
          <option v-for="symbol in tableSymbols" :key="symbol" :value="symbol">
            {{ symbol }}
          </option>
        </select>
      </div>

      <div class="card mb-2" v-if="loadingSymbol && symbolMode === 1">
        <b-card>
          <b-skeleton animation="wave" width="75%"></b-skeleton>
          <b-skeleton animation="wave" width="55%"></b-skeleton>
        </b-card>
      </div>
      <div class="card mb-2" v-if="loadingSymbol && symbolMode === 0">
        <b-card>
          <b-skeleton animation="wave" width="75%"></b-skeleton>
          <b-skeleton animation="wave" width="55%"></b-skeleton>
        </b-card>
      </div>
      <div class="card mb-2" v-if="loadingSymbol && symbolMode === 0">
        <b-card>
          <b-skeleton animation="wave" width="75%"></b-skeleton>
          <b-skeleton animation="wave" width="55%"></b-skeleton>
        </b-card>
      </div>
      <div class="card p-2 mb-2">
        <label>Period Time:</label>
        <span>{{ period }} days</span>
        <span class="text-muted small mt-1" v-if="period == 0">
          It have to be a period of 1 day minimum
        </span>
        <span class="text-muted small mt-1" v-if="symbolMode === 1">
          For all the symbols mode, the maximum period is of 7 days
        </span>
      </div>
      <div class="card p-2 mb-2" v-if="!loadingSymbol">
        <b-button v-if="symbolMode === 1" @click="setLMDDAll(1)"
          >Set Table</b-button
        >
        <b-button v-else @click="setLMDD(1)">Set Dateframe</b-button>
      </div>
    </div>
  </b-col>
</template>

<script>
import moment from "moment";
import TableSymbols from "./tableSymbols.vue";

export default {
  name: "DateframeControl",
  props: ["paginate"],
  components: {
    TableSymbols,
  },
  async created() {
    this.startDate = moment().subtract(7, "d").format("YYYY-MM-DD");
    this.endDate = moment().format("YYYY-MM-DD");
    await this.fetchOptions();
    const fourHours = 60 * 60 * 4;
    const start = new Date(this.startDate) / 1000 + fourHours;
    const end = new Date(this.endDate) / 1000 + fourHours;
    const response = await this.$http.server1("bwfs/lmdd/all_symbols", {
      start,
      end,
      search: "",
    });
    this.tableSymbols = response.data.filter((symbol) => symbol.length > 1);
  },
  data() {
    return {
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
        <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
        </svg> Error`,
      startDate: null,
      endDate: null,
      symbolsOptions: [],
      symbol: null,
      symbols: [],
      filterType: "",
      filterValue: "",
      symbolMode: 0,
      search: "",
      paginate_binance: {},
      binanceOptions: [],
      loadingSymbol: false,
      paginate_all: {},
      orderBy: "asc_sendTimestamp",
      tableSymbols: [],
      symbolForAllLMDD: "",
    };
  },
  mounted() {
    this.addEventSearch();
  },
  computed: {
    filterValueOptions() {
      if (this.filterType === "eventType") {
        return [
          { value: "DOWN_BREAKTHROUGH", label: "DOWN BREAKTHROUGH" },
          { value: "UP_BREAKTHROUGH", label: "UP BREAKTHROUGH" },
          { value: "UP_1", label: "UP 1" },
          { value: "DOWN_1", label: "DOWN 1" },
          { value: "RISE_AGAIN", label: "RISE AGAIN" },
          { value: "DROP_BACK", label: "DROP BACK" },
        ];
      }
      if (this.filterType === "notice_types") {
        return [
          { value: "PRICE_BREAKTHROUGH", label: "PRICE BREAKTHROUGH" },
          { value: "PRICE_CHANGE", label: "PRICE CHANGE" },
          { value: "PRICE_FLUCTUATION", label: "PRICE FLUCTUATION" },
        ];
      }
      return [];
    },
    period() {
      if (
        this.startDate === null ||
        this.endDate === null ||
        (this.startDate &&
          this.endDate &&
          new Date(this.endDate) / 1000 - new Date(this.startDate) / 1000 === 0)
      ) {
        return 0;
      }
      const diffDays = this.$helpers.getDaysDiff(this.startDate, this.endDate);
      return diffDays;
    },
  },
  methods: {
    async fetchOptions() {
      this.loadingSymbol = true;
      this.$emit("handleLoadingSymbol", true);
      const search = this.search.toUpperCase();
      const fourHours = 60 * 60 * 4;
      const start = new Date(this.startDate) / 1000 + fourHours;
      const end = new Date(this.endDate) / 1000 + fourHours;
      const response = await this.$http.server1("bwfs/lmdd/all_symbols", {
        start,
        end,
        search,
      });

      this.binanceOptions = response.data.filter((symbol) => symbol.length > 1);

      this.loadingSymbol = false;
      this.$emit("handleLoadingSymbol", false);
    },
    addEventSearch() {
      const myInput = document.getElementById("searchSymbolLMDD");
      console.log(myInput);
      if (!myInput) {
        setTimeout(() => {
          this.addEventSearch();
        }, 500);
      } else {
        console.log(myInput);
        myInput.addEventListener("keyup", this.$debounce(this.fetchOptions));
      }
    },
    async setLMDD() {
      if (
        this.startDate === null ||
        this.endDate === null ||
        (this.startDate &&
          this.endDate &&
          new Date(this.endDate) / 1000 - new Date(this.startDate) / 1000 === 0)
      ) {
        this.$toastr.error(
          "Please add a start and/or end date, and from different days",
          `${this.error}`
        );
      } else {
        this.$emit("loading", true);
        this.$emit("setTable");
        const fourHours = 60 * 60 * 4;
        const start = new Date(this.startDate) / 1000 + fourHours;
        const end = new Date(this.endDate) / 1000 + fourHours;
        let symbol = this.symbol;
        if (this.symbolMode === 1) symbol = "btcusdt";
        const data = {
          start,
          end,
          symbol,
        };
        const response = await this.$http.server1("bwfs/lmdd", data);
        const rows = response.data.map((row) => {
          return {
            sendTimestamp: row.fecha,
            eventType: row.eventType,
            notice_types: row.notice_types,
            priceChange: row.priceChange,
            symbol: this.symbol,
            period: `${row.period} days`,
          };
        });
        this.$emit("mode", {
          mode: 0,
          symbol: [{ value: this.symbol, label: this.symbol, selected: false }],
        });
        this.$emit("symbol", symbol);
        this.$emit("rows", rows);
        this.$emit("loading", false);
      }
    },
    async setLMDDAll(page_symbol_set = 1, page_records = 1) {
      if (
        this.startDate === null ||
        this.endDate === null ||
        (this.startDate &&
          this.endDate &&
          new Date(this.endDate) / 1000 - new Date(this.startDate) / 1000 === 0)
      ) {
        this.$toastr.error(
          "Please add a start and/or end date, and from different days",
          `${this.error}`
        );
        return;
      }
      if (this.period <= 7) {
        this.loadingSymbol = true;
        this.$emit("loading", true);
        this.$emit("setTable");
        let page = page_records;
        let page_symbol = page_symbol_set;
        const sortByOrder = this.orderBy.split("_");
        const orderBy = sortByOrder[0];
        const sortBy = sortByOrder[1];
        const filterValue = this.filterValue;
        const filterType = this.filterType;
        const symbol = this.symbolForAllLMDD;
        const fourHours = 60 * 60 * 4;
        const start = new Date(this.startDate) / 1000 + fourHours;
        const end = new Date(this.endDate) / 1000 + fourHours;
        const data = {
          start,
          end,
          page,
          page_symbol,
          limit: 20,
          sortBy,
          orderBy,
          filterType,
          filterValue,
          symbol,
        };
        const response2 = await this.$http.server1("bwfs/lmdd/all", data);
        const rows = response2.data.map((row) => {
          return {
            sendTimestamp: row.fecha,
            eventType: row.eventType,
            notice_types: row.notice_types,
            priceChange: row.priceChange,
            symbol: row.symbol,
            period: `${row.period} days`,
          };
        });
        let symbols = [];
        if (response2.data.length > 0) {
          symbols = response2.data.map((data) => data.symbol);
          this.symbols = response2.symbols.map((symbol) => {
            return { symbol };
          });
        }
        if (symbol) {
          this.$emit("isSymbol", true);
        } else {
          this.$emit("isSymbol", false);
        }
        if (symbol || filterValue) {
          this.$emit("isFilter", true);
        } else {
          this.$emit("isFilter", false);
        }
        this.paginate_all = response2.paginate;
        this.$emit("pagination", this.paginate_all);
        this.loadingSymbol = false;
        this.$emit("mode", {
          mode: 1,
          symbol: this.symbols.map((symbol) => {
            return {
              value: symbol.symbol,
              label: symbol.symbol,
              selected: false,
            };
          }),
        });
        this.$emit("symbol", symbols);
        this.$emit("rows", rows);
        this.$emit("loading", false);
      } else {
        this.$toastr.error(
          "The maximum period is of 7 seven days for all the symbols",
          `${this.error}`
        );
      }
    },
    setSymbol() {
      const symbolForAllLMDD = this.symbolForAllLMDD;
      const tableSymbols = this.tableSymbols;
      this.$emit("selectedSymbolForAll", { symbolForAllLMDD, tableSymbols });
    },
  },
  watch: {
    symbolMode(value) {
      if (value === 1) {
        this.setLMDDAll();
      }
    },
    paginate(value) {
      this.setLMDDAll(value.pageSymbol, value.pageRecord);
    },
    filterType(value) {
      if (value !== "") this.filterValue = this.filterValueOptions[0].value;
    },
    search(value) {
      if (value.length === 0) {
        this.fetchOptions();
      }
    },
  },
};
</script>
