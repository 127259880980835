<template>
  <div class="filters-health">
    <div class="filters">
      <div class="filter-row">
        <div class="form-group w-input-20">
          <label>Search</label>
          <div class="input-group">
            <input
              v-model="systems_heartbeat_filters.search"
              class="form-control selector"
              placeholder="Search by name"
              type="text"
            />
            <span
              class="input-group-text no-border right dark"
              id="search-form-icon"
            >
              <img :src="search_icon" alt="" />
            </span>
          </div>
        </div>

        <div class="form-group w-input-20 input-order-by">
          <label>Order by</label>
          <div class="container-select-order-by">
            <Multiselect
              v-model="systems_heartbeat_filters.sort_by"
              :hideSelected="true"
              :options="ordering_options"
              class="form-control selector order-by-select"
              label="name"
              placeholder="Select ordering"
              valueProp="value"
            >
              <template v-slot:caret>
                <span class="multiselect-caret">
                  <img :src="icon_caret" alt="" />
                </span>
              </template>
            </Multiselect>
            <Multiselect
              v-model="systems_heartbeat_filters.sort"
              :hideSelected="true"
              :options="sort_options"
              class="form-control selector type-select"
              label="name"
              placeholder="Type"
              valueProp="value"
            >
              <template v-slot:caret>
                <span class="multiselect-caret">
                  <img :src="icon_caret" alt="" />
                </span>
              </template>
            </Multiselect>
          </div>
        </div>
        <div class="form-group w-input-20">
          <label>Algorithm</label>
          <Multiselect
            v-model="systems_heartbeat_filters.algorithm_token"
            :hideSelected="true"
            :options="algorithms_options"
            class="form-control selector"
            label="name"
            placeholder="Select an algorithm"
            valueProp="token"
          >
            <template v-slot:caret>
              <span class="multiselect-caret">
                <img :src="icon_caret" alt="" />
              </span>
            </template>
          </Multiselect>
        </div>
        <div class="form-group w-input-20">
          <label>Tag</label>
          <Multiselect
            v-model="systems_heartbeat_filters.tags"
            :hideSelected="true"
            :options="tag_options"
            class="form-control selector"
            label="name"
            placeholder="Select tag"
          >
            <template v-slot:caret>
              <span class="multiselect-caret">
                <img :src="icon_caret" alt="" />
              </span>
            </template>
          </Multiselect>
        </div>
      </div>
      <div class="filter-row">
        <div class="form-group w-input-20">
          <label>Active</label>
          <div class="status-box">
            <div
              :class="{ active: systems_heartbeat_filters.is_active == 1 }"
              @click="setHealthActive(1)"
            >
              Active
            </div>
            <div
              :class="{ active: systems_heartbeat_filters.is_active == 0 }"
              @click="setHealthActive(0)"
            >
              Inactive
            </div>
            <div
              :class="{
                active: systems_heartbeat_filters.is_active == null,
              }"
              @click="setHealthActive(null)"
            >
              All
            </div>
          </div>
        </div>
        <div class="form-group w-input-20">
          <label>Status</label>
          <div class="status-box">
            <div
              :class="{ active: systems_heartbeat_filters.is_health_ok == 1 }"
              @click="setHealthStatus(1)"
            >
              Live
            </div>
            <div
              :class="{ active: systems_heartbeat_filters.is_health_ok == 0 }"
              @click="setHealthStatus(0)"
            >
              Dead
            </div>
            <div
              :class="{
                active: systems_heartbeat_filters.is_health_ok == null,
              }"
              @click="setHealthStatus(null)"
            >
              All
            </div>
          </div>
        </div>
        <div class="form-group w-input-20">
          <label>Items per page</label>
          <Multiselect
            v-model="systems_heartbeat_filters.limit"
            :canClear="false"
            :canDeselect="false"
            :hideSelected="true"
            :options="item_per_page_options"
            class="form-control selector"
            label="name"
            placeholder="Select an algorithm"
            valueProp="value"
          >
            <template v-slot:caret>
              <span class="multiselect-caret">
                <img :src="icon_caret" alt="" />
              </span>
            </template>
          </Multiselect>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconCaret from "../../../../public/common/select-arrow-down.svg";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2";
import SearchIcon from "../../../../public/common/search.png";
import { mapState } from "vuex";

export default {
  name: "Filters",
  data() {
    return {
      timer: undefined,
      icon_caret: IconCaret,
      investor_type_options: [
        { value: "54445", name: "Palm" },
        { value: "545454541", name: "Holy" },
        { value: "5445", name: "Marasi" },
      ],
      item_per_page_options: [
        { value: "5", name: "Max per page: 5" },
        { value: "10", name: "Max per page: 10" },
        { value: "20", name: "Max per page: 20" },
      ],
      ordering_options: [
        { value: "name", name: "Instance name" },
        { value: "updated_at", name: "Updated at" },
      ],
      search_icon: SearchIcon,
      sort_options: [
        { value: "asc", name: "Asc" },
        { value: "desc", name: "Desc" },
      ],
      status_options: [
        { value: 1, name: "Live" },
        { value: 0, name: "Dead" },
      ],
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    ...mapState("systems_heartbeat", [
      "systems_heartbeat_filters",
      "tags_list",
    ]),
    ...mapState("groups", ["algorithm_selector"]),
    algorithms_options() {
      return this.algorithm_selector && this.algorithm_selector._data
        ? this.algorithm_selector._data.data
        : [];
    },
    tag_options() {
      return this.tags_list._data && this.tags_list._data.data
        ? this.tags_list._data.data
        : [];
    },
  },
  created() {
    this.$store.dispatch("groups/algorithm_selector");
    this.$store.dispatch("systems_heartbeat/tags_list");
  },
  methods: {
    reloadService() {
      this.$store.dispatch("systems_heartbeat/systems_heartbeat_list", {});
    },
    setHealthActive(value) {
      this.systems_heartbeat_filters.is_active = value;
    },
    setHealthStatus(value) {
      this.systems_heartbeat_filters.is_health_ok = value;
    },
  },
  watch: {
    "systems_heartbeat_filters.algorithm_token": function (value) {
      this.reloadService();
    },
    "systems_heartbeat_filters.is_active": function (value) {
      this.reloadService();
    },
    "systems_heartbeat_filters.is_health_ok": function (value) {
      this.reloadService();
    },
    "systems_heartbeat_filters.limit": function (value) {
      this.reloadService();
    },
    "systems_heartbeat_filters.search": function (value) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.reloadService();
      }, 1000);
    },
    "systems_heartbeat_filters.sort": function (value) {
      this.reloadService();
    },
    "systems_heartbeat_filters.sort_by": function (value) {
      this.reloadService();
    },
    "systems_heartbeat_filters.tags": function (value) {
      this.reloadService();
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/pages/systems_heartbeat/filters.scss";
</style>
