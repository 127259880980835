<template>
    <div :class="`component_template__servers_info`">
        <div class="d-block p-2 mb-2">
          <div class="separator">
              Server statistics
            </div>

            <div class="card p-2 server-information mb-2">
                <label class="d-block server_name">Backtesting server</label>
                
                <b-progress height="5px" :value="60" :max="100" class="mt-2" v-b-tooltip.hover title="CPU ~60%"></b-progress>
                <b-progress height="5px" :value="70" :max="100" class="mt-2 mb-1" v-b-tooltip.hover title="RAM ~70%"></b-progress>
            </div>
            
            <div class="card p-2 server-information mb-2">
                <label class="d-block server_name">Develop server</label>
                
                <b-progress height="5px" :value="90" :max="100" class="mt-2 danger" v-b-tooltip.hover title="CPU ~90%"></b-progress>
                <b-progress height="5px" :value="55" :max="100" class="mt-2 mb-1" v-b-tooltip.hover title="RAM ~55%"></b-progress>
            </div>
            
            <div class="card p-2 server-information">
                <label class="d-block server_name">Production server</label>
                
                <b-progress height="5px" :value="20" :max="100" class="mt-2" v-b-tooltip.hover title="CPU ~20%"></b-progress>
                <b-progress height="5px" :value="35" :max="100" class="mt-2 mb-1" v-b-tooltip.hover title="RAM ~35%"></b-progress>
            </div>
        </div>

    </div>
  </template>
  
  <script>
  export default {
    name: 'ServerInfo',
    components: {
  
    }
  }
  </script>
  