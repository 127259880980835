export default {
  date: null,
  event_type: null,
  from_date: null,
  investor_token: null,
  limit: 10,
  portfolio_token: null,
  to_date: null,
  search: null,
  exchange_connection: null,
};
