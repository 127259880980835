import CreateConnectionService from "../../../services/create_connection.service";
export default ({ commit, state }, { callback, error }) => {
  commit("set", {
    _state: "create_connection",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });
  const { credentials, exchange, leverage, name } = state.form_connection;

  var credentials_obj = JSON.parse(JSON.stringify(credentials));
  let body = {};

  if (Object.keys(credentials).length != 0) {
    body = {
      credentials: JSON.stringify(credentials_obj),
      exchange: exchange.name,
      investor_token: state.investor_token,
      leverage,
      name,
    };
  } else {
    body = {
      exchange: exchange.name,
      investor_token: state.investor_token,
      leverage,
      name,
    };
  }

  CreateConnectionService({
    body,
    callback: ({ response }) => {
      commit("set", {
        _state: "create_connection",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "create_connection",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
