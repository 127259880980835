<template>
	<div class="table-responsive">
		<div class="col-md-12 d-flex px-0">
			<label for="">Contributors</label>
      <b-button variant="success" class="small ml-auto" v-if="$props.isEdit" @click="$emit('handleCreateContributor')">Add contributor</b-button>
		</div>
    <b-table 
      id="table"
      :items="$store.state.contributors" 
      :fields="fields" 
      striped
      hover
      small
      borderless
      :busy="$store.state.get_contributors_loading"
      v-if="$store.state.contributors.length > 0 || $store.state.get_contributors_loading"
    >

    	<template #cell(name)="row" #cell(index)="row">
    		<div class="description pointer">
    			<span class="d-block mono small">
           {{row.item.contributor.name}}
          </span>
      	</div>
    	</template>
      <template #cell(note)="row" #cell(index)="row">
        <div class="description pointer">
          <span class="d-block mono small">
           {{row.item.contributor.note}}
          </span>
        </div>
      </template>
      <template #cell(options)="row" #cell(index)="row">
        <span v-b-tooltip.hover title="Edit contributor" style="font-size: 14px !important;" class="mr-2 pointer" @click.prevent="$emit('handleEditContributor', row.item)">
          <b-icon-gear></b-icon-gear>
        </span>
        <a href="javascript:;" style="font-size: 14px !important;">
          <span  v-b-tooltip.hover class="mr-2 pointer-2" title="Open new tab">
            <router-link :to="`/contributor/${row.item.contributor.token_user}`" target="_blank">
              <b-icon-box-arrow-up-right></b-icon-box-arrow-up-right>
            </router-link>
          </span>
        </a>
        <span v-b-tooltip.hover title="Delete Contributors" class="mr-2 pointer" style="font-size: 14px !important;" @click.prevent="handleDelete(row.item.contributor)" v-if="$store.state.user && $store.state.user.role_id == 1"> 
          <b-icon-x-circle></b-icon-x-circle>
        </span>
      </template>
  		<template #table-busy>
        <b-skeleton-table
          :rows="3"
          :columns="4"
          :table-props="{  striped: true }"
        ></b-skeleton-table>
      </template>
    </b-table>
    <b-alert class="my-2" show variant="dark" v-if="$store.state.contributors.length == 0 && !$store.state.get_contributors_loading">No contributors available.</b-alert>
  </div>
</template>
<script>
	export default {
		name: 'ContainerContributors',
		props: {
			isEdit: {
				required: true,
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				fields: [
          {key: 'token',}, 
          {key: 'name',}, 
          {key: 'note',}, 
          {key: 'options',}, 
        ],
			};
		},
    methods: {
      handleDelete(contributor)  {
        const data = {token: contributor.token_user};
        if(confirm('are you sure?')) {
          const payload = {
            vm: this,
            data,
          };
          const payload2 = {
            vm: this,
            data: {
              investor_token: contributor.parent_token_user,
            }
          }
          this.$store.commit('bwfs_delete_contributors', payload);
          this.$store.commit('get_contributor', payload2);
        }
      },
    },
	};
</script>