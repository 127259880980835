<template>
  <div class="col-md-6 p-1 text-right">
    <b-modal
      id="modal-create-contributor"
      hide-footer
      hide-header
      size="xl"
      ok-only
    >
      <div class="modal-create-update-user">
        <h3 class="modal-title">
          <span v-if="!isEdit">Create Contributor</span>
          <span v-else>Edit Contributor</span>
        </h3>
        <div class="row px-2">
          <div class="col-md-6 px-2">
            <div class="row">
              <div class="col-md-12">
                <label class="text-muted"> Account info </label>
              </div>
            </div>
            <div class="row px-2">
              <div class="col-md-6 px-2">
                <div class="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    placeholder="Name"
                    class="form-control"
                    v-model="investorForm.name"
                    :disabled="disabled"
                    @input="$v.investorForm.name.$touch()"
                  />
                  <div v-if="$v.investorForm.name.$dirty">
                    <b-form-invalid-feedback
                      :state="$v.investorForm.name.required"
                    >
                      Required Field
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
              <div class="col-md-6 px-2">
                <div class="form-group">
                  <label>Token</label>
                  <input
                    type="text"
                    placeholder="Token"
                    class="form-control"
                    v-model="investorForm.token"
                    :disabled="disabled || true"
                    @input="$v.investorForm.token.$touch()"
                  />
                  <span class="icon" v-if="!isEdit && !isShow">
                    <b-icon-shuffle @click="generateToken"></b-icon-shuffle>
                  </span>
                  <div v-if="$v.investorForm.token.$dirty">
                    <b-form-invalid-feedback
                      :state="$v.investorForm.token.required"
                    >
                      Required Field
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="false">
              <div class="col-md-12">
                <label class="text-muted"> Account type </label>
              </div>
            </div>

            <div class="row px-2 pt-2" v-if="false">
              <div class="col-md-4 px-2">
                <div class="form-group">
                  <label>Demo</label>
                  <b-form-checkbox
                    :value="true"
                    :unchecked-value="false"
                    :disabled="disabled"
                    v-model="investorForm.demo"
                  >
                    <span v-if="!investorForm.demo">
                      It will <u>not</u> be a demo account
                    </span>
                    <span v-if="investorForm.demo">
                      <u>Yes</u>, it will be a demo account
                    </span>
                  </b-form-checkbox>
                </div>
              </div>
              <div class="col-md-4 px-2">
                <div class="form-group">
                  <label>Futures</label>
                  <b-form-checkbox
                    :value="true"
                    :unchecked-value="false"
                    :disabled="disabled"
                    v-model="investorForm.future"
                  >
                    <span v-if="!investorForm.future">
                      It will <u>not</u> be a future account
                    </span>
                    <span v-if="investorForm.future">
                      <u>Yes</u>, it will be a future account
                    </span>
                  </b-form-checkbox>
                </div>
              </div>
            </div>

            <div class="row" v-if="!investorForm.demo">
              <div class="col-md-3">
                <div class="content px-0 item-active-config">
                  <label>Order type</label>
                  <div class="row px-2" style="margin-top: 0px">
                    <div class="col-md-12 px-2">
                      <select
                        class="form-control"
                        v-model="investorForm.order_type"
                        :disabled="disabled"
                        @input="$v.investorForm.order_type.$touch()"
                      >
                        <option value="limit">Limit</option>
                        <option value="market">Market</option>
                      </select>
                      <div v-if="$v.investorForm.order_type.$dirty">
                        <b-form-invalid-feedback
                          :state="$v.investorForm.order_type.required"
                        >
                          Required Field
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="content px-0 item-active-config">
                  <label>Exchange</label>
                  <div class="row px-2" style="margin-top: 0px">
                    <div class="col-md-12 px-2">
                      <select
                        class="form-control"
                        v-model="investorForm.exchange"
                        :disabled="disabled"
                        @input="$v.investorForm.exchange.$touch()"
                      >
                        <option value="binance">Binance</option>
                      </select>
                      <div v-if="$v.investorForm.exchange.$dirty">
                        <b-form-invalid-feedback
                          :state="$v.investorForm.exchange.required"
                        >
                          Required Field
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="content px-0 item-active-config">
                  <label>Order open close tolerance</label>
                  <input
                    type="number"
                    pattern="[0-9]"
                    min="1"
                    max="100"
                    class="form-control"
                    v-model="investorForm.order_open_close_tolerance"
                    @input="$v.investorForm.order_open_close_tolerance.$touch()"
                  />
                  <div v-if="$v.investorForm.order_open_close_tolerance.$dirty">
                    <b-form-invalid-feedback
                      :state="
                        $v.investorForm.order_open_close_tolerance.required
                      "
                    >
                      Required Field
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <label class="text-muted"> Comments </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group m-0">
                  <label>Additional note</label>
                  <textarea
                    placeholder="Additional note"
                    class="form-control"
                    :disabled="disabled"
                    v-model="investorForm.note"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 px-2">
            <div class="row balances">
              <div class="col-md-12">
                <TableBalancesInvestors
                  :showUpdateBalance="false"
                  :investorForm="investorForm"
                  :balances="investorForm.balances"
                  :loading="loading"
                  :isShow="false"
                  :showOptions="$route.name.toLowerCase() != 'contributor'"
                  :isContributor="true"
                  @handleDeleteBalance="deleteBalance"
                  @handleAddBalance="handleAddBalance"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12" v-if="!isShow && !isEdit">
          <b-button variant="success" v-if="!loading" @click="handleSave"
            >Create contributor</b-button
          >
          <b-button
            v-else
            class="mr-2 form-group pr-5"
            variant="success"
            :disabled="true"
          >
            Create contributor
            <div class="loading">
              <b-spinner variant="light"></b-spinner>
            </div>
          </b-button>
        </div>
        <div class="col-md-12" v-if="isEdit">
          <b-button variant="success" v-if="!loading" @click="handleEditSave"
            >Edit contributor</b-button
          >
          <b-button
            v-else
            class="mr-2 form-group pr-5"
            variant="success"
            :disabled="true"
          >
            Edit contributor
            <div class="loading">
              <b-spinner variant="light"></b-spinner>
            </div>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ConditionGenerator from "@/components/commons/ConditionGenerator";
import TableBalancesInvestors from "@/components/pages/investors/TableBalancesInvestors.vue";
import Helpers from "@/helpers/commons.js";
import { required, minLength } from "vuelidate/lib/validators";
import { EventBus } from "@/helpers/event-bus.js";

export default {
  name: "CreateAndEditContributor",
  props: {
    investor_token: {
      required: true,
      type: String,
    },
    investor: {
      required: true,
      type: Object,
      default: () => {},
    },
    investor_balances: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  components: {
    ConditionGenerator,
    TableBalancesInvestors,
  },
  validations: {
    investorForm: {
      name: { required },
      token: { required },
      configurations: { required },
      order_open_close_tolerance: { required },
      order_type: { required },
      exchange: { required },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  data() {
    return {
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
      <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
      </svg> Error`,
      investorForm: {
        name: null,
        token: null,
        balances: [],
        configurations: [],
        note: "",
        demo: true,
        future: false,
        order_type: "market",
        exchange: "binance",
        order_open_close_tolerance: 5,
      },
      show: false,
      disabled: false,
      isShow: false,
      isEdit: false,
      maximunCondition: 50,
      colorID: {},
      loading: false,
      isAddBalance: false,
      all_balances: [],
    };
  },
  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId == "modal-create-contributor" && !this.isAddBalance) {
        setTimeout(() => {
          this.resetForm();
        }, 500);
      }
    });
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (modalId == "modal-create-contributor" && !this.isAddBalance) {
        this.getBalancesAvailable();
      }
    });
  },
  methods: {
    generateToken() {
      this.investorForm.token = Helpers.MD5(Helpers.generateRandomString(10));
    },
    setDataFormAndDisable(data) {
      this.investorForm.name = data.name;
      this.investorForm.token = data.token;
      this.investorForm.note = data.note;
      this.investorForm.balances = data.balances;
      this.disabled = true;
      this.isShow = true;
      this.isEdit = false;
    },
    setEditInvestor(data) {
      let balances = [];
      if (data.balances) {
        for (let i = 0; i < data.balances.length; i++) {
          balances.push({
            asset: data.balances[i].asset.toUpperCase(),
            balance: data.balances[i].balance,
          });
        }
      }
      this.investorForm.name = data.contributor.name;
      this.investorForm.token = data.token;
      this.investorForm.note = data.contributor.note;
      this.investorForm.api_key = data.contributor.api_key;
      this.investorForm.secret_key = data.contributor.secret_key;
      this.investorForm.demo = data.contributor.is_real ? false : true;
      // let configuration = JSON.parse(data.investor.configurations);
      // this.investorForm.future = configuration.market_type == 'futures';
      // this.investorForm.order_type = configuration.order_type;
      // this.investorForm.exchange = configuration.exchange;
      this.investorForm.order_open_close_tolerance =
        this.investorForm.order_type == "limit"
          ? Number(configuration.order_open_close_tolerance) * 100
          : 0;
      /*if (data.conditions[0].base_configuration.is_compound) {
        for (let i = 0; i < data.conditions.length; i++) {
          const color = this.$helpers.getRandomColor();
          if (i % 2 !== 0) {
            this.colorID[i] = color;
            this.colorID[i - 1] = color;
          }
        }
      }*/
      this.investorForm.configurations = data.conditions;
      this.investorForm.balances = [];
      this.investorForm.balances = [...balances];
      this.disabled = false;
      this.isShow = false;
      this.isEdit = true;
    },
    setAutoCompletenvestor(data) {
      let balances = [];
      for (let i = 0; i < data.balances.length; i++) {
        balances.push({
          asset: data.balances[i].asset.toUpperCase(),
          balance: data.balances[i].balance,
        });
      }
      this.investorForm.name = data.name;
      // this.investorForm.token = data.token;
      this.investorForm.note = data.investor.note;
      this.investorForm.api_key = data.investor.api_key;
      this.investorForm.secret_key = data.investor.secret_key;
      this.investorForm.demo = data.investor.is_real ? false : true;
      let configuration = JSON.parse(data.investor.configurations);
      this.investorForm.future = configuration.market_type == "futures";
      this.investorForm.order_type = configuration.order_type;
      this.investorForm.exchange = configuration.exchange;
      this.investorForm.order_open_close_tolerance =
        this.investorForm.order_type == "limit"
          ? Number(configuration.order_open_close_tolerance) * 100
          : 0;
      if (data.conditions[0].base_configuration.is_compound) {
        for (let i = 0; i < data.conditions.length; i++) {
          const color = this.$helpers.getRandomColor();
          if (i % 2 !== 0) {
            this.colorID[i] = color;
            this.colorID[i - 1] = color;
          }
        }
      }
      this.investorForm.configurations = data.conditions;
      this.investorForm.balances = [];
      this.investorForm.balances = [...balances];
      this.disabled = false;
      this.isShow = false;
      this.isEdit = false;
    },
    handleAddBalance() {
      const input =
        '<input id="swal-input111" class="swal2-input" placeholder="Amount" type="number" style="display: flex; height:45px;max-width: 11.5em;">';
      const inputCustom =
        '<input id="swal-input1222" class="swal2-input" placeholder="Custom Symbol" type="text" style="display: none; height:45px;max-width: 11.5em;">';
      let select =
        '<select style="border: 1px solid #d9d9d9;border-radius: .1875em;font-size: 1.125em;height: 45px; display: flex;max-width: 11.5em;" class="swal2-select" id="swal-input266" placeholder="Custom Symbol">';
      const userDataBk = { ...this.investorForm };
      const balancesInvestors = this.$props.investor_balances;
      select += '<option value="">Select Symbol</option>';
      for (let i = 0; i < this.$props.investor_balances.length; i++) {
        let b = this.$props.investor_balances[i];
        select += `<option value="${b.asset.toUpperCase()}">${b.asset.toUpperCase()}</option>`;
      }
      // select += '<option value="custom">Custom</option>';
      select += "</select>";

      const isEditBk = this.isEdit;
      this.isAddBalance = true;
      this.$bvModal.hide("modal-create-contributor");

      let htmlForm = `${select}${input}${inputCustom}`;
      if (!this.investorForm.demo) {
        htmlForm = `${select}`;
      }

      Swal.fire({
        title: "Add balance",
        html: htmlForm,
        showCancelButton: true,
        background: "#0e1019",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#1c2030",
        confirmButtonText: "Save",
        reverseButtons: true,
        preConfirm: () => {
          let selectSymbol = document.getElementById("swal-input266").value;
          let isCustom = false;

          if (this.investorForm.demo) {
            if (!selectSymbol) {
              Swal.showValidationMessage("Please select a symbol!");
              return;
            }
            if (selectSymbol.toLowerCase() === "custom") {
              isCustom = true;
              selectSymbol = document.getElementById("swal-input1222").value;
            }
            if (!Number(document.getElementById("swal-input111").value)) {
              Swal.showValidationMessage(
                `You must enter the value of ${selectSymbol}!`
              );
              return;
            }

            let selectedBalance = balancesInvestors.filter(
              (b) => b.asset.toLowerCase() === selectSymbol.toLowerCase()
            );
            let currentBalances = userDataBk.balances.filter(
              (b) => b.asset.toLowerCase() === selectSymbol.toLowerCase()
            );
            let availableBalance = this.all_balances.filter(
              (b) => b.asset.toLowerCase() === selectSymbol.toLowerCase()
            );

            if (currentBalances.length > 0) {
              Swal.showValidationMessage(
                `This symbol has already been selected.`
              );
              return;
            }
            if (!isCustom) {
              if (
                Number(document.getElementById("swal-input111").value) >
                availableBalance[0].balance
              ) {
                Swal.showValidationMessage(
                  `The balance of ${selectSymbol} must be less than or equal to that of the investor!`
                );
                return;
              }
            }
            return {
              balance: document.getElementById("swal-input111").value,
              asset: selectSymbol,
            };
          }
          let payload = {
            api_key: this.investorForm.api_key,
            secret_key: this.investorForm.secret_key,
            symbol: selectSymbol,
            market: !this.investorForm.future ? 1 : 0,
          };
          return new Promise((resolve) => {
            this.$http
              .server1("bwfs/admin/balance/get", payload)
              .then((res) => {
                if (res.error) {
                  Swal.showValidationMessage(`${res.message}a`);
                }
                resolve({
                  balance: res.data,
                  asset: selectSymbol,
                });
              })
              .catch((e) => {
                Swal.showValidationMessage(`${e.message}b`);
              });
          });
        },
      })
        .then((result) => {
          if (result.value) {
            userDataBk.balances.push(result.value);
          }
          this.userData = userDataBk;
          this.isEdit = isEditBk;
          this.$bvModal.show("modal-create-contributor");
          this.isAddBalance = false;
        })
        .catch(() => {});
      document
        .querySelector("#swal-input266")
        .addEventListener("change", function (event) {
          event.preventDefault();
          /* Act on the event */
          if (event.target.value.toLowerCase() === "custom") {
            document.querySelector("#swal-input1222").style.display = "flex";
          } else {
            document.querySelector("#swal-input1222").style.display = "none";
          }
        });
    },
    deleteBalance(selectBalance) {
      let indexSelected;
      this.investorForm.balances.map((item, index) => {
        if (item.asset === selectBalance.asset) {
          indexSelected = index;
        }
        return item;
      });
      const { balances } = this.investorForm;
      delete balances.splice(indexSelected, 1);
      this.investorForm.balances = balances;
    },
    handleSave() {
      if (!this.handleValidInvestor()) {
        return;
      }
      const data = { ...this.investorForm };
      data.demo = data.demo ? 1 : 0;
      data.note = data.note;
      data.parent_token_user = this.$props.investor_token;
      data.balances = JSON.stringify(data.balances);
      data.configurations = JSON.stringify({
        market_type: this.investorForm.future ? "futures" : "spot",
        order_type: this.investorForm.order_type,
        order_open_close_tolerance:
          this.investorForm.order_type == "limit"
            ? Number(this.investorForm.order_open_close_tolerance) / 100
            : 0,
        exchange: this.investorForm.exchange,
      });
      this.loading = true;
      this.$http
        .server1("bwfs/admin/contributors/create", data)
        .then((res) => {
          this.loading = false;
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.$bvModal.hide("modal-create-contributor");
          this.resetForm();
          this.$emit("handleReload");
        })
        .catch((e) => {
          this.loading = false;
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    handleValidInvestor() {
      if (
        this.$v.investorForm.name.$invalid ||
        this.$v.investorForm.token.$invalid
      ) {
        this.$toastr.error("Please complete the fields.", `${this.error}`);
        this.$v.$touch();
        return false;
      }
      if (this.investorForm.balances.length === 0) {
        this.$toastr.error("Please add balance.", `${this.error}`);
        return false;
      }
      if (
        this.investorForm.order_type == "limit" &&
        (Number(this.investorForm.order_open_close_tolerance) <= 0 ||
          Number(this.investorForm.order_open_close_tolerance) > 100)
      ) {
        this.$toastr.error(
          "Please valid order open close tolerance.",
          `${this.error}`
        );
        return false;
      }
      return true;
    },
    resetForm() {
      this.investorForm = {
        name: null,
        token: null,
        balances: [],
        configurations: [],
        note: "",
        api_key: "",
        secret_key: "",
        demo: true,
        future: false,
        order_type: "market",
        exchange: "binance",
        order_open_close_tolerance: 5,
      };
      this.show = false;
      this.disabled = false;
      this.isEdit = false;
      this.isShow = false;
      this.isCompound = false;
      this.is_compound = "";
      this.currentTypeCondition = "buy";
      this.activeSell = false;
      this.activeBuy = true;
      this.priority = "buy";
      this.maximunCondition = 50;
      this.colorID = {};
      this.indexConditionModify = 0;
      this.loading = false;
      this.$v.$reset();
    },
    handleEditSave() {
      if (!this.handleValidInvestor()) {
        return;
      }
      const data = { ...this.investorForm };
      data.demo = data.demo ? 1 : 0;
      data.note = data.note;
      data.balances = JSON.stringify(data.balances);
      data.configurations = JSON.stringify({
        market_type: this.investorForm.future ? "futures" : "spot",
        order_type: this.investorForm.order_type,
        order_open_close_tolerance:
          this.investorForm.order_type == "limit"
            ? Number(this.investorForm.order_open_close_tolerance) / 100
            : 0,
        exchange: this.investorForm.exchange,
      });
      this.loading = true;
      this.$http
        .server1("bwfs/admin/contributors/update", data)
        .then((res) => {
          this.loading = false;
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.$bvModal.hide("modal-create-contributor");
          this.resetForm();
          this.$emit("handleReload");
        })
        .catch((e) => {
          this.loading = false;
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    getBalancesAvailable() {
      const data = {
        investor_token: this.$props.investor_token,
      };
      this.$http
        .server1("bwfs/admin/investors/all/balances/available", data)
        .then((res) => {
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.all_balances = res.data;
        })
        .catch((e) => {
          this.loading = false;
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
  },
  watch: {
    "investorForm.demo"(value) {
      if (!this.isEdit) {
        this.investorForm.balances = [];
      }
    },
  },
};
</script>

<style>
#modal-shows-condition .edit-conditions span {
  font-size: 18px;
  display: flex;
  width: 20px;
  text-align: center;
  margin-bottom: 10px;
}

#modal-shows-condition .conditions {
  font-family: "Roboto Mono", monospace;
  font-size: 11px;
  line-height: 1.1;
}
</style>
