<template>
  <div :class="`panel page__${$route.name.toLowerCase()}`">
    <b-container fluid>
      <b-row class="p-0">
        <b-col cols="12" lg="2" class="p-0 admin_sidebar nice-scroll">
          <div class="d-block p-2 mb-2">
            <div class="separator">Notificatiopns filtering</div>
            <div class="card p-2 mb-2 d-block">
              <div class="form-group">
                <label>Investor</label>
                <select
                  name="token_user"
                  id=""
                  class="form-control"
                  v-model="search_token_user"
                >
                  <option
                    :value="inv.value"
                    v-for="inv in investors"
                    :key="inv.token + '00'"
                    v-if="inv.value"
                  >
                    {{ inv.text }}
                  </option>
                </select>
                <div class="loading" v-if="isLoading">
                  <b-spinner variant="light"></b-spinner>
                </div>
              </div>
            </div>
            <div class="card p-2 mb-2 d-block">
              <div class="form-group">
                <label>By condition</label>
                <select
                  name="token_user"
                  id=""
                  class="form-control"
                  v-model="search_condition"
                >
                  <option value="drawdown >">Drawdown ></option>
                  <option value="drawdown >=">Drawdown >=</option>
                  <option value="drawdown <">Drawdown <</option>
                  <option value="drawdown <=">Drawdown <=</option>
                  <option value="drawdown ==">Drawdown ==</option>
                  <option value="open order">Open order</option>
                  <option value="close order">Close order</option>
                  <option value="cancelled order">Cancelled order</option>
                  <option value="cancelled order during the opening">
                    Cancelled order during the opening
                  </option>
                  <option value="cancelled order during the closing">
                    Cancelled order during the closing
                  </option>
                  <option value="total open order">Total open order</option>
                  <option value="current performance report">
                    Current performance report
                  </option>
                  <option value="Is alive">Investor is alive 1 hour</option>
                </select>
                <div class="loading" v-if="isLoading">
                  <b-spinner variant="light"></b-spinner>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" lg="10" class="p-0 admin_content nice-scroll">
          <div class="content p-2">
            <div class="row pl-3 pr-3">
              <div class="col-md-6 p-1 d-flex align-items-center">
                Total records found: {{ paginate.records }}
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <b-button
                  variant="danger"
                  style="margin-right: 8px"
                  @click="handleDelete"
                  >Delete</b-button
                >
                <b-button
                  variant="dark"
                  style="margin-right: 8px"
                  v-b-modal.modal-create-notification
                  >Create notifications</b-button
                >
                <b-button
                  variant="dark"
                  style="margin-right: 8px"
                  @click.prevent="sendNotifications"
                  >Send notifications</b-button
                >
              </div>
            </div>
            <div class="row pl-3 pr-3">
              <div class="col-md-12 p-1">
                <div class="table-responsive">
                  <b-table
                    id="table"
                    :items="notifications"
                    :fields="fields"
                    striped
                    hover
                    small
                    borderless
                    :busy="isLoading"
                  >
                    <template #table-busy>
                      <div class="text-center my-2">
                        <b-spinner type="grow" label="Spinning"></b-spinner>
                      </div>
                    </template>

                    <template #cell(check)="row">
                      <b-form-checkbox
                        v-model="selected_delete"
                        :value="row.item.token_user + '.' + row.item.condition"
                      ></b-form-checkbox>
                    </template>

                    <template #cell(id)="row">
                      {{ row.item.token_user }}
                    </template>

                    <template #cell(option)="row">
                      <span
                        v-b-tooltip.hover
                        title="Delete Notification"
                        style="font-size: 18px"
                        @click.prevent="deleteNotification(row.item)"
                      >
                        <b-icon-x-circle variant="danger"></b-icon-x-circle>
                      </span>
                    </template>
                  </b-table>
                  <b-pagination
                    id="table"
                    v-model="currentPage"
                    :total-rows="paginate.records"
                    :limit="20"
                    class="mt-2"
                    @page-click="linkGen"
                    v-if="paginate.total_pages > 0"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-modal id="modal-create-notification" title="Create Notifications">
      <form action="">
        <div class="row px-2">
          <div class="col px-2">
            <div class="form-group">
              <label>Investor</label>
              <b-form-select
                id="select-investors"
                v-model="notificationForm.token_user"
                :options="investors"
                multiple
                :select-size="4"
                style="font-size: 13px"
              ></b-form-select>
              <div v-if="$v.notificationForm.token_user.$dirty">
                <b-form-invalid-feedback
                  :state="$v.notificationForm.token_user.required"
                >
                  Required Field
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-2">
          <div class="col px-2">
            <div class="form-group">
              <label>Event</label>
              <select
                name="token_user"
                id=""
                class="form-control"
                v-model="notificationForm.condition"
              >
                <option value="drawdown >">
                  Drawdown > (When this value is higher or lower depending on
                  the condition, the notification will be sent.)
                </option>
                <option value="drawdown >=">
                  Drawdown >= (When this value is higher or lower depending on
                  the condition, the notification will be sent.)
                </option>
                <option value="drawdown <">
                  Drawdown < (When this value is higher or lower depending on
                  the condition, the notification will be sent.)
                </option>
                <option value="drawdown <=">
                  Drawdown <= (When this value is higher or lower depending on
                  the condition, the notification will be sent.)
                </option>
                <option value="drawdown ==">
                  Drawdown == (When this value is higher or lower depending on
                  the condition, the notification will be sent.)
                </option>
                <option value="open order">
                  Open order (Notifies when the order pass through this status)
                </option>
                <option value="close order">
                  Close order (Notifies when the order pass through this status)
                </option>
                <option value="cancelled order">
                  Cancelled order (Notifies when the order pass through this
                  status)
                </option>
                <option value="cancelled order during the opening">
                  Cancelled order during the opening
                </option>
                <option value="cancelled order during the closing">
                  Cancelled order during the closing
                </option>
                <option value="total open order">Total open order</option>
                <option value="report hours">Report hours</option>
                <option value="current performance report">
                  Current performance report
                </option>
                <option value="Is alive">Investor is alive 1 hour</option>
              </select>
              <div v-if="$v.notificationForm.condition.$dirty">
                <b-form-invalid-feedback
                  :state="$v.notificationForm.condition.required"
                >
                  Required Field
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
          <div class="col-md px-2" v-show="showTrigger">
            <div class="form-group">
              <label>Trigger</label>
              <input
                type="text"
                placeholder="Trigger"
                class="form-control"
                v-model="notificationForm.trigger"
                @input="$v.notificationForm.trigger.$touch()"
                :style="{ paddingRight: '40px' }"
              />
              <div v-if="$v.notificationForm.trigger.$dirty">
                <b-form-invalid-feedback
                  :state="$v.notificationForm.trigger.required"
                >
                  Required Field
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-2">
          <div class="col-md-12 px-2" v-show="showTrigger">
            <div class="form-group">
              <label>Wait Time (Minutes)</label>
              <input
                type="text"
                placeholder="Wait time"
                class="form-control"
                v-model.number="notificationForm.wait_time"
                @input="$v.notificationForm.wait_time.$touch()"
                :style="{ paddingRight: '40px' }"
              />
              <div v-if="$v.notificationForm.wait_time.$dirty">
                <b-form-invalid-feedback
                  :state="$v.notificationForm.wait_time.required"
                >
                  Required Field
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
        </div>
      </form>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button
          size="sm"
          variant="success"
          @click="saveNotification"
          v-if="!saving"
        >
          Save
        </b-button>
        <b-button size="sm" variant="success" disabled v-if="saving">
          Save
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { required, minLength, email } from "vuelidate/lib/validators";
export default {
  created() {
    this.getNotifications();
    this.getInvestors();
  },
  validations: {
    notificationForm: {
      token_user: { required },
      condition: { required },
      trigger: { required },
      wait_time: {},
    },
  },
  data() {
    return {
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
        <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
        </svg> Error`,
      notificationForm: {
        token_user: [],
        condition: "",
        trigger: "",
        wait_time: "",
      },
      investors: [],
      selected_delete: [],
      notifications: [],
      saving: false,
      isLoading: false,
      fields: [
        { key: "check", sortable: false },
        { key: "id", sortable: true },
        { key: "investor", sortable: true },
        { key: "condition", sortable: true },
        { key: "trigger", sortable: true },
        { key: "wait_time", sortable: true },
        { key: "option", sortable: false },
      ],
      paginate: {
        current_page: 1,
        per_page: 15,
        total_pages: 0,
        records: 0,
      },
      currentPage: 1,
      search_token_user: "",
      search_condition: "",
      loading_send_notification: false,
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
          <g clip-path="url(#clip0_1634_940)">
          <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
          </g>
          <defs>
          <clipPath id="clip0_1634_940">
          <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
          </clipPath>
          </defs>
          </svg> Success`,
    };
  },
  computed: {
    showTrigger() {
      return (
        this.notificationForm.condition.indexOf("drawdown") > -1 ||
        this.notificationForm.condition == "total open order" ||
        this.notificationForm.condition == "report hours" ||
        this.notificationForm.condition == "current performance report"
      );
    },
    filterInvestors() {
      if (!this.filterInvestor) {
        return this.investors;
      }
      let i = [...this.investors];
      return i.filter(
        (e) =>
          e.text.toLowerCase().indexOf(this.filterInvestor.toLowerCase()) >= 0
      );
    },
  },
  methods: {
    sendNotifications() {
      this.loading_send_notification = true;
      this.$http
        .server1("bwfs/reset/dayli_report", {})
        .then((res) => {
          this.loading_send_notification = false;
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.$toastr.success(`${res.message}`, `${this.success}`);
        })
        .catch((e) => {
          this.$toastr.error(`${e.message}`, `${this.error}`);
          this.loading_send_notification = false;
        });
    },
    getInvestors() {
      this.$http
        .server1("bwfs/notifications/investors", {})
        .then((res) => {
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          let data = res.data.filter((item) => item.token);
          data = data.map((item) => {
            return {
              value: item.token,
              text: `${item.name} - ${item.token}`,
            };
          });
          this.investors = data;
        })
        .catch((e) => {
          this.loadingReport = false;
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    getNotifications(page = 1) {
      this.isLoading = true;
      let data = { page: page, limit: 20 };
      if (this.search_token_user) {
        data["search_token_user"] = this.search_token_user;
      }
      if (this.search_condition) {
        data["search_condition"] = this.search_condition;
      }
      this.$http
        .server1("bwfs/notifications", data)
        .then((res) => {
          this.isLoading = false;
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.notifications = res.data;
          this.paginate = res.paginate;
        })
        .catch((e) => {
          this.isLoading = false;
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    linkGen(event, pageNum) {
      this.currentPage = pageNum;
      this.getNotifications(pageNum);
    },
    saveNotification() {
      this.saving = true;
      let data = this.notificationForm;
      data["token_user"] = JSON.stringify(data["token_user"]);
      if (!data["wait_time"]) {
        data["wait_time"] = 0;
      }
      this.$http
        .server1("bwfs/notifications/create", data)
        .then((res) => {
          this.saving = false;
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.$toastr.success("Success add notification", `${this.success}`);
          this.notificationForm.token_user = [];
          this.notificationForm.condition = "";
          this.notificationForm.trigger = "";
          this.getNotifications(1);
          this.$bvModal.hide("modal-create-notification");
        })
        .catch((e) => {
          this.saving = false;
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    handleDelete() {
      if (this.selected_delete.length === 0) {
        this.$toastr.error("Please select notification.", `${this.error}`);

        return;
      }
      if (!confirm("Are you sure?")) {
        return;
      }

      for (let i = 0; i < this.selected_delete.length; i++) {
        let notifications = this.selected_delete[i].split(".");
        let data = {
          token_user: notifications[0],
          condition: notifications[1],
        };
        this.$http
          .server1("bwfs/notifications/delete", data)
          .then((res) => {
            if (res.error) {
              this.$toastr.error(`${res.message}`, `${this.error}`);
              return;
            }
          })
          .catch((e) => {
            this.$toastr.error(`${e.message}`, `${this.error}`);
          });
      }
      setTimeout(() => {
        this.selected_delete = [];
        this.getNotifications(1);
      }, 2000);
    },
    deleteNotification(notification) {
      if (!confirm("Are you sure?")) {
        return;
      }
      let data = {
        token_user: notification.token_user,
        condition: notification.condition,
      };
      this.$http
        .server1("bwfs/notifications/delete", data)
        .then((res) => {
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.$toastr.success(
            "Success delete notification",
            `${this.success}`
          );
          this.notificationForm.token_user = "";
          this.notificationForm.condition = "";
          this.notificationForm.trigger = "";
          this.getNotifications(1);
        })
        .catch((e) => {
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
  },
  watch: {
    search_token_user() {
      this.getNotifications(1);
    },
    search_condition() {
      this.getNotifications(1);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/commons/admin.scss";
@import "@/styles/pages/users.scss";
</style>

<style lang="scss" scoped>
::v-deep .input-group-text {
  background-color: #0e1019 !important;
  // border: 1px solid #495057!important;
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.loading-space {
  position: absolute;
  right: 12px;
  bottom: 11px;
  zoom: 0.6;
}
::v-deep .btn-group-vertical {
  background-color: #1d1e25 !important;
  color: #f5f5f5;
  border: 0;
  font-size: 14px;
}
#select-investors {
  height: 150px !important;
}
::v-deep .custom-select {
  background-color: #1d1e25 !important;
  color: #f5f5f5 !important;
  border: 0 !important;
  font-size: 16px;
}
@media (min-width: 768px) and (max-width: 1200px) {
  ::v-deep .custom-select {
    font-size: 0.85rem;
  }
}

::v-deep .modal-title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}
::v-deep .custom-select,
.swal2-select,
.swal2-input {
  background-color: #1d1e25 !important;
  color: #f5f5f5 !important;
  border: 0 !important;
  font-size: 13px !important;
  height: 38px !important;
}

.item-profits-blended {
  line-height: 1.2;
  display: block;
  margin-bottom: 5px;
  border-radius: 4px;
  background-color: #1d1e25;
  padding: 8px 12px;
  font-size: 12px;
}
</style>
