import random_number from "../../../helpers/generators/random_number";
import model from "../models/performance_account";

export default () => {
  const num = random_number(1, 20);
  let performances = [];
  for (let i = 0; i < num; i++) {
    const performance = model();
    performances.push(performance);
  }
  return {
    data: performances,
  };
};
