export default (state, { _state, _status, _data }) => {
  if (_data) {
    state[_state]._data = Array.isArray(_data)
      ? _data
      : {
          ...state[_state]._data,
          ..._data,
        };
  }

  if (_status) {
    state[_state]._status = {
      ...state[_state]._status,
      ..._status,
    };
  }
};
