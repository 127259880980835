import axios from "axios";
import descompress_base64 from "./compressing/descompress_base64";
import random_number from "./generators/random_number";
import Crypto from "./crypto";

export const execute = ({
  method,
  microservice,
  body,
  params,
  callback,
  error,
  mockdata,
  adapter,
  debug,
  auth_headers = null,
  is_content_type_json = false,
  zipjson = false,
}) => {
  if (debug) {
    setTimeout(() => {
      new Promise((resolve) => {
        resolve(
          callback({
            response: { ...adapter, ...mockdata },
          })
        );
      });
    }, random_number(0 * 1000, 3 * 1000));
  }
  if (!debug) {
    const url = new URL(microservice);
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach((key) => {
        const value = params[key];
        if (typeof value == "number" || value) {
          url.searchParams.append(key, value);
        }
      });
    }
    if (!is_content_type_json) {
      var form_data = new FormData();
      for (var key in body) {
        form_data.append(key, body[key]);
      }
    } else {
      var form_data = body;
    }
    let access_token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    let cookieAuth = localStorage.getItem("cookie_autorized");

    if (access_token) {
      headers.Authorization = Crypto.decrypt(access_token);
      headers.Cookie_Auth = cookieAuth;
    }

    if (zipjson) {
      headers.zipjson = 1;
    }

    if (auth_headers && Object.keys(auth_headers).length > 0) {
      Object.keys(auth_headers).forEach((key) => {
        const value = auth_headers[key];
        headers[key] = value;
      });
    }

    const response = axios({
      method,
      url: url.href,
      responseType: "json",
      data: form_data,
      headers,
    });

    response.then((response) => {
      let res = null;

      if (zipjson) {
        res = descompress_base64(response.data);
      } else {
        res = response.data;
      }

      let data = null;

      if (res.success && res.data && !res.paginate && adapter) {
        data = Array.isArray(res.data)
          ? [...adapter, ...res.data]
          : { ...adapter, ...res.data };
      } else {
        data = res;
      }

      callback && callback({ response: data });
    });

    response.catch((err) => {
      let res = {};

      if (zipjson) {
        res = descompress_base64(err.response);
      } else {
        res = err.response;
      }

      error && error({ response: res });
    });
  }
};
