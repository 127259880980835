<template>
  <div :class="`page__${$route.name.toLowerCase()}`">
    <b-container fluid>
      <b-row class="p-0">
        <CardInvestorInfo />
        <ChartPerfomance v-show="!$store.state.expert_mode"/>
        <ConditionsInvestorTable v-show="$store.state.expert_mode"/>
        
        <b-col cols="12" lg="2" class="p-0 sidebar__user_open_orders nice-scroll">
          <div class="row" v-if="false">
            <div class="col-md-12">
              <BalancesDetailInvestors />
            </div>
          </div>
          <PieChart :labels="dataPie.labels" :seriesData="dataPie.data" v-if="dataPie.data.length > 1 && dataPie.labels.length > 1 " v-show="showChartPie && false"/>
          <OpenOrdersTableResume v-if="!maintenanceMode || $store.state.user.token" /> 

          <div class="p-2" v-if="!maintenanceMode">
            <div class="separator d-flex flex-row">
              ORDERS OPENED
               <span style="margin-left:auto;">
                  <b-icon style="cursor:pointer" icon="arrows-angle-expand" @click="showOpenOrders = !showOpenOrders"></b-icon>
             </span>
              <ModalOpenOrders 
                :show="showOpenOrders"
                @closeModal="showOpenOrders = false"
              />
            </div>
            <CardOpenedOrdersContainer @refresh_order="refresh_order" ref="CardOpenedOrdersContainer" />

            <div class="table-responsive px-1 mb-2 mt-3" v-if="$store.state.user.token && $store.state.user.role_id == 1">
              <div class="row">
                <div class="col-12 mb-2">
                  <div class="d-flex flex-row">
                  <label class="mb-0">OPEN ORDER BY SYMBOL</label>
                    <span style="margin-left:auto;">
                      <b-icon style="cursor:pointer" icon="arrows-angle-expand" @click="showOpenSymbols = !showOpenSymbols"></b-icon>
                  </span>
                  </div>
                </div>
                <div class="col-6 mb-2">
                  <select  class="form-control" v-model="sort_open_order_by_symbol">
                    <option value="asc_symbol">↑ Symbol </option>
                    <option value="desc_symbol">↓ Symbol </option>
                    <option value="asc_perfomance">↑ Perfomance </option>
                    <option value="desc_perfomance">↓ Perfomance </option>
                    <option value="asc_lot">↑ Lot </option>
                    <option value="desc_lot">↓ Lot </option>
                  </select>
                </div>
                <div class="col-6 mb-2">
                  <select  class="form-control" v-model="pagination_open_order_by_symbol">
                    <option value="5">5</option>
                    <option value="25">25</option>
                    <option value="99999999999">Todos</option>
                  </select>
                </div>
                <div class="col-12 mb-2">
                  <input type="text" class="form-control float-right d-inline-block" id="searchOpenOrderBySymbolPerfomance" placeholder="Search by symbol" v-model="search_open_perfomance_by_symbol" v-show="!$store.state.orders_opened_loading">
                </div>    
              </div>
              <b-table 
                id="table"
                :items="open_ordres" 
                :fields="fields" 
                striped
                hover
                small
                borderless
                :busy="loading_open_orders"
                v-if="(open_ordres.length > 0 && !loading_open_orders ) || loading_open_orders"
              >
                <template #cell(symbol)="row">
                  <span>
                    {{row.item.symbol.toUpperCase()}}
                  </span>
                </template>

                <template #cell(balance)="row" v-if="$store.state.info_investor.info && $store.state.info_investor.info.investor.is_real == 1">
                  <span>
                    {{row.item.balance ? row.item.balance : '---'}}
                  </span>
                </template>

                <template #cell(perfomance)="row" v-if="">
                  <span>
                    {{row.item.lot ? $helpers.toParseNumber($store.state.info_investor.symbol_base, 'open_price', row.item.perfomance) : 0}}
                  </span>
                </template>

                <template #cell(lot)="row">
                  <span v-b-tooltip.hover :title="'Conversion lot: '+row.item.conversion_lot">
                    {{row.item.lot ? $helpers.toParseNumber($store.state.info_investor.symbol_base, 'open_price', row.item.lot) : 0}} ({{row.item.total_open}})
                  </span>
                </template>

                <template #table-busy>
                  <b-skeleton-table
                    :rows="5"
                    :columns="4"
                    :table-props="{  striped: true }"
                  ></b-skeleton-table>
                </template>
              </b-table>
              <b-alert class="my-2" show variant="dark" v-if="open_ordres.length === 0 && !loading_open_orders">
                <span> No found orders. </span>
              </b-alert>
            </div>
            <b-pagination
              v-model="currentPage"
              :total-rows="paginate.records"
              :limit="4"
              :per-page="paginate.per_page"
              class="px-1 mb-2"
              @page-click="getOpenOrderPerfomance"
              v-if="paginate.total_pages > 1 && $store.state.user.token">
            </b-pagination>
          </div>        
          <b-modal id="modal-show-json-order" hide-footer hide-header>
            <div class="modal-show-json-order--json-content">
              JSON ORDER
            </div>
          </b-modal>

          <b-modal id="modal-edit-order" size="sm" hide-footer hide-header>
            <h3 class="modal-title">
              Update the order
            </h3>

            <div class="form-group mb-2">
              <label class="mb-1">
                Take Profit
              </label>
              <input 
                type="text" 
                placeholder="Take Profit" 
                class="form-control">
            </div>

            <div class="form-group mb-2">
              <label class="mb-1">
                Stop Loss
              </label>
              <input 
                type="text" 
                placeholder="Stop Loss" 
                class="form-control">
            </div>

            <div class="form-group mb-2">
              <label class="mb-1">
                Trailing Activation
              </label>
              <input 
                type="text" 
                placeholder="Trailing Activation" 
                class="form-control">
            </div>

            <div class="form-group mb-2">
              <label class="mb-1">
                Trailing Step
              </label>
              <input 
                type="text" 
                placeholder="Trailing Step" 
                class="form-control">
            </div>

            <div class="form-group mb-2">
              <label class="mb-0">
                Immediate Closure
              </label>
              <b-form-checkbox
                v-model="modal__change_order_data__immediate_closure"
                value="1"
                unchecked-value="0"
              >
                {{ (modal__change_order_data__immediate_closure == 1) ? 'Yes' : 'Not'}}
              </b-form-checkbox>
            </div>

            <div class="form-group mb-0">
              <b-button class="" variant="dark">
                Save changes <b-icon-check></b-icon-check>
              </b-button>
            </div>
          </b-modal>
        </b-col>

        <b-col cols="12" lg="2" class="p-0 sidebar__user_manual_orders nice-scroll">

          <!-- Esta seccion de la pantalla esta destinado
          para ir mostrando las notificaciones historicas
          y en tiempo real de la cuenta como tal
          cuando abrio operaciones cuando 
          y que esta sucediendo  
          Este caso se aplica para el investor -->
          <CardNotifications @refresh_order="refresh_order" />
          
        </b-col>
      </b-row>
      <b-row class="px-3 py-0 sidebar__user_closed_orders nice-scroll">
        <b-col cols="12 p-0" lg="12">
          <OrdersTable ref="OrdersTable"/>
        </b-col>
      </b-row>
    </b-container>
    <b-modal  size="xl" title="Open Orders Symbols" v-model="showOpenSymbols"  hide-footer ok-only>
      <div class="row">
       <div class="col-6 mb-2">
          <select  class="form-control" v-model="sort_open_order_by_symbol">
            <option value="asc_symbol">↑ Symbol </option>
            <option value="desc_symbol">↓ Symbol </option>
            <option value="asc_perfomance">↑ Perfomance </option>
            <option value="desc_perfomance">↓ Perfomance </option>
            <option value="asc_lot">↑ Lot </option>
            <option value="desc_lot">↓ Lot </option>
          </select>
        </div>
        <div class="col-6 mb-2">
          <select  class="form-control" v-model="pagination_open_order_by_symbol">
            <option value="5">5</option>
            <option value="25">25</option>
            <option value="99999999999">Todos</option>
          </select>
        </div>
        <div class="col-12 mb-2">
          <input type="text" class="form-control float-right d-inline-block" id="searchOpenOrderBySymbolPerfomance" placeholder="Search by symbol" v-model="search_open_perfomance_by_symbol" v-show="!$store.state.orders_opened_loading">
        </div>    
        </div>
       <b-table 
                id="table"
                :items="open_ordres" 
                :fields="fields" 
                striped
                hover
                small
                borderless
                :busy="loading_open_orders"
                v-if="(open_ordres.length > 0 && !loading_open_orders ) || loading_open_orders"
              >
                <template #cell(symbol)="row">
                  <span>
                    {{row.item.symbol.toUpperCase()}}
                  </span>
                </template>

                <template #cell(balance)="row" v-if="$store.state.info_investor.info && $store.state.info_investor.info.investor.is_real == 1">
                  <span>
                    {{row.item.balance ? row.item.balance : '---'}}
                  </span>
                </template>

                <template #cell(perfomance)="row" v-if="">
                  <span>
                    {{row.item.lot ? $helpers.toParseNumber($store.state.info_investor.symbol_base, 'open_price', row.item.perfomance) : 0}}
                  </span>
                </template>

                <template #cell(lot)="row">
                  <span v-b-tooltip.hover :title="'Conversion lot: '+row.item.conversion_lot">
                    {{row.item.lot ? $helpers.toParseNumber($store.state.info_investor.symbol_base, 'open_price', row.item.lot) : 0}} ({{row.item.total_open}})
                  </span>
                </template>

                <template #table-busy>
                  <b-skeleton-table
                    :rows="5"
                    :columns="4"
                    :table-props="{  striped: true }"
                  ></b-skeleton-table>
                </template>
              </b-table>
    </b-modal>
  </div>
</template>

<script>
import OrdersTable from '@/components/commons/OrdersTable';
import PieChart from '@/components/commons/PieChart';
import CardOpenedOrdersContainer from '@/components/pages/investors/CardOpenedOrdersContainer';
import CardInvestorInfo from '@/components/pages/investors/CardInvestorInfo';
import ChartPerfomance from '@/components/pages/investors/ChartPerfomance';
import CardNotifications from '@/components/pages/investors/CardNotifications';
import BalancesDetailInvestors from '@/components/pages/investors/BalancesDetailInvestors';
import OpenOrdersTableResume from '@/components/pages/investors/OpenOrdersTable';
import ConditionsInvestorTable from '@/components/pages/investors/trading-expert/ConditionsInvestorTable';
import ModalOpenOrders from '@/components/pages/investors/investor_details_modals/OpenOrdersModal.vue';
import ModalOpenOrdersSymbols from '@/components/pages/investors/investor_details_modals/OpenSymbolsModal.vue';
import handleSocket from '@/helpers/socket';

export default {
  name: 'Investor',
  components: {
    OrdersTable,
    CardOpenedOrdersContainer,
    CardInvestorInfo,
    ChartPerfomance,
    CardNotifications,
    BalancesDetailInvestors,
    ConditionsInvestorTable,
    PieChart,
    ModalOpenOrders,
    ModalOpenOrdersSymbols,
    OpenOrdersTableResume,
  },
  created() {
    
    if (!this.$store.state.user.token) {
      this.getMaintenanceMode();
    }
    
    this.$store.commit('set_investor_loading', true);
    /* if (localStorage.getItem('endpoint')) {
      const endpoint = localStorage.getItem('endpoint')
      console.log(endpoint)
      this.$store.commit('set_investor_api_url',  endpoint)
    }*/
    if (this.$route.params.hasOwnProperty('token')) {
      this.token = this.$route.params.token;
      if (Number(this.token) == 1) {
        window.location.href = "/#/admin/backtestings";
        window.location.reload();
        return;
      }
      const payload = {
        vm: this,
        data: {
          investor_token: this.token,
        },
      }
      this.$store.commit('get_info_investor', payload);
      this.getOpenOrderPerfomance(null, 1);

    } else {
      window.location.href = "/#/admin/backtestings";
      window.location.reload();
      return;
    }
  },
  mounted() {
    this.addEventSearch();
  },
  data () {
    return {
      maintenanceMode: null,
      modal__change_order_data__immediate_closure: 0,
      token: '',
      websocket: undefined,
      new_notification: {},
      animate: false,
      search_notification: '',
      search_open_perfomance_by_symbol: '',
      paginate: {
        per_page: 15,
        records: 0,
        total_pages: 1
      },
      open_ordres: [],
      loading_open_orders: false,
      currentPage: 1,
      fields: [
        {key: 'symbol', label: 'Symbol',},
        {key: 'perfomance', label: 'Per.',},
        {key: 'balance', label: 'Balance',},
        {key: 'lot', label: 'Lot',},
      ],
      dataPie: {
        data: [],
        labels: [],
      },
      showChartPie: false,
      showOpenOrders: false,
      showOpenSymbols: false,
      pagination_open_order_by_symbol: 25,
      sort: "DESC",
      sort_by: "",
      sort_open_order_by_symbol: "asc_symbol"
    }
  },
  methods: {
    getMaintenanceMode() {
      this.$http.server1('bwfs/admin/maintenance/mode', {}).then((res) => {
        if (res.error) {
          return;
        }
        this.maintenanceMode = res.data
      }).catch(() => {

      });
    },
    addEventSearch() {
      try {
        const myInput = document.getElementById("searchOpenOrderBySymbolPerfomance");
        if (!myInput) {
          setTimeout(() => {
            this.addEventSearch();
          }, 500);
        } else {
          myInput.addEventListener("keyup",  this.$debounce(() => {
            this.getOpenOrderPerfomance(null, 1);
          }));
        }
      } catch(e) {
        setTimeout(() => {
          this.addEventSearch();
        }, 500);
      }
    },
    refresh_order(byNotification=false) {
      this.$refs.OrdersTable.getOrders();
      this.getOpenOrderPerfomance();
      if (byNotification) {
        this.$refs.CardOpenedOrdersContainer.getOpened(1);
      }
    },
    toggleChartPie() {
      this.showChartPie = !this.showChartPie;
    },
    getOpenOrderPerfomance(event, pageNum) {
      if (this.loading_open_orders) {
        return;
      }
      this.currentPage = pageNum ? pageNum : 1;
      let sort = this.sort_open_order_by_symbol.replace("_", " ").split(" ");
      let data = {
        investor_token: this.token,
        limit: this.pagination_open_order_by_symbol,
        page: this.currentPage,
        filter: this.search_open_perfomance_by_symbol,
        sort: sort[0],
        sort_by: sort[1],
      }
      this.loading_open_orders = true;
      this.$http.server1('bwfs/admin/investors/orders/open/perfomance', data).then(res => {
        this.loading_open_orders = false;
        if (res.error) {
          return;
        }
        this.dataPie.data = [];
        this.dataPie.labels = [];
        let open_ordres = res.data;
        if (res.balances_spot && res.balances_spot.length > 0) {
          for (let i = 0; i < open_ordres.length; i++) {
            let symbol = open_ordres[i].symbol.toLowerCase() != 'btcusdt' ? open_ordres[i].symbol.toLowerCase().replace("usdt","").replace("btc") : open_ordres[i].symbol.toLowerCase()
            for (let j = 0; j < res.balances_spot.length; j++) {
              if (symbol == res.balances_spot[j].asset.toLowerCase()) {
                open_ordres[i]["balance"] = parseFloat(res.balances_spot[j].balance);
                break;
              }
            }
          }
        }
        this.open_ordres = open_ordres;
        this.paginate = res.paginate;
      }).catch(e => {
        console.log(e);
        this.loading_open_orders = false;
      })
    },
  },
  watch: {
    pagination_open_order_by_symbol() {
      this.getOpenOrderPerfomance();
    },
    sort_open_order_by_symbol() {
      this.getOpenOrderPerfomance();
    },
    '$store.state.info_investor'(value) {
      if (value.info && value.info.investor.is_real == 0) {
        this.fields =  [
          {key: 'symbol', label: 'Symbol', sortable: true},
          {key: 'perfomance', label: 'Per.'},
          {key: 'lot', label: 'Lot'},
        ];
      }
    },
    maintenanceMode(value) {
      if (value) {
        window.location.href = "/#/maintenance-mode";
        window.location.reload();
        return;
      }
      if (this.$route.params.hasOwnProperty('token')) {
        this.token = this.$route.params.token;
        if (Number(this.token) == 1) {
          window.location.href = "/#/admin/backtestings";
          window.location.reload();
          return;
        }
        const payload = {
          vm: this,
          data: {
            investor_token: this.token,
          },
        };
        //this.$store.commit('get_info_investor', payload);
        //this.getOpenOrderPerfomance(null, 1);
      
      } else {
        window.location.href = "/#/admin/backtestings";
        window.location.reload();
        return;
      }
    }
  },
  filters:{
    parseMsg(msg, type) {
      let m = JSON.parse(msg);
      if (type == 'msg') {
        return m.msg;
      }
      if (m.date) {
        return m.date.substr(0,19);
      }
      return '---';
    },
  }
}
</script>

<style lang="scss">
  @import '@/styles/pages/investor.scss';
</style>

<style scoped>
  .table-sm tbody tr td {
    font-size: 11px;
  }
  ::v-deep .modal-title {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0px;
  }
   @media (min-width: 1200px) {

    ::v-deep .modal-xl {
      max-width: 1200px;
    }
  }
</style>