import UpdateAlgorithmHealthService from "../../../services/update_systems_heartbeat.service";
export default ({ commit, state }, { callback, error }) => {
  commit("set", {
    _state: "update_systems_heartbeat",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });

  const {
    algorithm_token,
    algorihtm_name,
    algorithm_note,
    is_active,
    priority,
    tags,
    token,
    timeframe,
    system_name,
  } = state.form_systems_heartbeat;

  const body = {
    is_active,
    name: algorihtm_name,
    note: algorithm_note,
    priority,
    tags: tags ? tags : [],
    token,
    timeframe,
  };

  if (algorithm_token != "other") {
    body.algorithm_token = algorithm_token;
  } else if (system_name) {
    body.system_name = system_name;
  }

  UpdateAlgorithmHealthService({
    body,
    callback: ({ response }) => {
      commit("set", {
        _state: "update_systems_heartbeat",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "update_systems_heartbeat",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
