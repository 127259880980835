<template>
  <div class="component-resolved-errors-table max-width">
    <p>RESOLVED ERRORS</p>
    <AlertErrorInformation v-if="onError" />
    <div
      class="table-resolved-errors table-responsive"
      v-else-if="active_errors.length > 0"
    >
      <Table
        :actions="false"
        :data="active_errors"
        :headers="columnHeaders"
        class="orders-table"
        title="Orders"
      >
        <template v-slot:comments="{ text }">
          <span v-if="text">{{ text }}</span>
          <span v-else>No comments...</span>
        </template>
        <template v-slot:level="{ text }">
          <span
            class="d-flex"
            :class="[
              text == 'high' ? 'red' : text == 'medium' ? 'yellow' : 'green',
            ]"
            ><span class="icon"
              ><img
                :src="
                  text == 'high'
                    ? arrow_up
                    : text == 'low'
                    ? arrow_down
                    : minus_icon
                "
                alt="" /></span
            >{{ text }}
          </span></template
        >
        <template v-slot:actions="id">
          <div class="dropdown">
            <span class="action-button" data-toggle="dropdown">
              <img :src="menu_icon" alt="" />
            </span>
            <ul class="dropdown-menu">
              <li><img :src="json_icon" />View JSON</li>
              <li><img :src="logs_icon" />Mark as unsolved</li>
              <li><img :src="comments_icon" />Comments</li>
            </ul>
          </div>
        </template>
        <template #pagination>
          <Pagination
            :pagination="paginate"
            :go-next-page-callback="goNextPage"
            :go-prev-page-callback="goPrevPage"
          />
        </template>
      </Table>
    </div>
    <AlertNoInformation v-else />
  </div>
</template>

<script>
import AlertNoInformation from "../../commons/alerts/AlertNoInformation.vue";
import ArrowUp from "../../../../public/common/arrow-up-red.svg";
import ArrowDown from "../../../../public/common/arrow-down-green.svg";
import CommentsIcon from "../../../../public/common/comments-icon.png";
import jsonIcon from "../../../assets/images/brackets-curly.svg";
import logsIcon from "../../../assets/images/logs.svg";
import MenuOptions from "../../../../public/common/menu-dots-vertical.png";
import MinusIcon from "../../../../public/common/minus-yellow.svg";
import Pagination from "../../commons/Pagination.vue";
import Table from "../../commons/table/Table.vue";

export default {
  name: "ResolvedErrors",
  data() {
    return {
      active_errors: [
        {
          id: "bb28fc069eec427da9e0adfdd9e39e88",
          error_type: "check if the account is online",
          level: "low",
          account: "Creed and Bear - Blended",
          date: "2023-01-25 18:00:04",
          comments: "",
        },
        {
          id: "a0bbd281ff64408d8b359044be62fcc7",
          error_type: "check if the account is online",
          level: "high",
          account: "Flavio Villa",
          date: "2023-01-25 18:00:04",
          comments:
            "This error is going through xxx and has not yet been resolved.",
        },
        {
          id: "2688e6d57b574ff39c94d6952f91ae97",
          error_type: "check if we have positions",
          level: "medium",
          account: "Alex Widmer",
          date: "2023-01-25 18:00:04",
          comments: "",
        },
        {
          id: "b2df62633d7749e0b3f6ecea6cd02f12",
          error_type: "validate local and binance side is equal",
          level: "medium",
          account: "Deep Horizon Test 01",
          date: "2023-01-25 18:00:04",
          comments: "",
        },
        {
          id: "e55f762a5516415b8adf29a8bef9437b",
          error_type: "check if the loting is equal",
          level: "high",
          account: "Twilight V2",
          date: "2023-01-25 18:00:04",
          comments: "We are working to resolve this error.",
        },
      ],
      arrow_up: ArrowUp,
      arrow_down: ArrowDown,
      comments_icon: CommentsIcon,
      json_icon: jsonIcon,
      logs_icon: logsIcon,
      menu_icon: MenuOptions,
      minus_icon: MinusIcon,
      columnHeaders: [
        { name: "id", title: "ID", align: "left" },
        { name: "error_type", title: "Error type", align: "left" },
        { name: "level", title: "Level", align: "left" },
        { name: "account", title: "Account", align: "left" },
        { name: "date", title: "Date", align: "left" },
        { name: "comments", title: "Comments", align: "left" },
        { title: "", action: true, id: "id" },
      ],
      onError: false,
      paginate: {
        current_page: 1,
        per_page: 10,
        records: 0,
        total_pages: 0,
      },
    };
  },
  components: {
    AlertNoInformation,
    Pagination,
    Table,
  },
  computed: {},
  methods: {
    goNextPage() {
      this.$store.dispatch("dashboard/open_orders", {
        page: this.current_page + 1,
      });
    },
    goPrevPage() {
      this.$store.dispatch("dashboard/open_orders", {
        page: this.current_page - 1,
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/pages/health_audit/resolved_errors.scss";
</style>
