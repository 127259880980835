<template>
  <div id="app">
    <TopbarComponent />
    <router-view />
    <div
      v-show="$route.name !== 'login' && refresh"
      v-for="(condition, index) in conditions"
      :key="index"
      :style="{
        position: 'absolute',
        left: '12px',
        zIndex: 999999,
        bottom: handleMargin(index),
      }"
    >
      <span class="d-flex justify-content-between">
        <b-button variant="dark" @click="showCondition(condition, index)">
          #{{ index + 1 }} Token:
          {{
            condition && condition.token
              ? condition.token.toString().substr(0, 13) + "..."
              : "---"
          }}
          <br />
          <span
            v-if="
              condition.base_configuration && condition.base_configuration.note
            "
            >{{ condition.base_configuration.note.substr(0, 13) + "..." }}</span
          >
        </b-button>
        <span style="margin-left: -10px; margin-top: -10px; z-index: 9999999">
          <b-icon-x-circle-fill
            @click="handleDeleteCondition(condition, index)"
          ></b-icon-x-circle-fill>
        </span>
      </span>
    </div>
    <b-modal
      ref="modal-add-condition-app"
      id="modal-add-condition-app"
      hide-footer
      hide-header
      size="xl"
      ok-only
    >
      <div class="modal-create-conditions">
        <h3 class="modal-title d-flex justify-content-between">
          <span> Condition generator </span>
          <div v-if="isCompound && !show">
            <a
              href="#"
              style="font-size: 13px; color: #fff"
              class="pr-2"
              @click.prevent="activeTabCondition('buy')"
            >
              <span
                class="btn btn-dark small"
                :style="{
                  background: activeBuy ? '#11131b' : '#232429',
                  fontWeight: '400',
                }"
              >
                Condition #1
              </span>
            </a>
            <a
              href="#"
              style="font-size: 13px; color: #fff"
              @click.prevent="activeTabCondition('sell')"
            >
              <span
                class="btn btn-dark small"
                :style="{
                  background: activeSell ? '#11131b' : '#232429',
                  fontWeight: '400',
                }"
              >
                Condition #2
              </span>
            </a>
          </div>
        </h3>
        <ConditionGenerator
          ref="conditionGeneratorBuyApp"
          @handleCompound="handleCompoundCondition"
          @handleAddCompound="handleAddCompoundCondition"
          @handleSetType="handletSeType"
          v-show="activeBuy"
          :isCompound="isCompound"
          :backtest="{}"
          :is_investor="is_investor"
        />
        <ConditionGenerator
          ref="conditionGeneratorSellApp"
          @handleCompound="handleCompoundCondition"
          :tabSell="true"
          :isCompound="isCompound"
          :valueCompound="is_compound"
          v-show="activeSell"
          :backtest="{}"
          :is_investor="is_investor"
          :type="currentTypeCondition === 'buy' ? 'sell' : 'buy'"
        />
      </div>
    </b-modal>
  </div>
</template>
<script>
import TopBar from "./components/template/topbar.vue";
import ConditionGenerator from "@/components/commons/ConditionGenerator";
import Crypto from "@/helpers/crypto.js";
import { EventBus } from "@/helpers/event-bus.js";
import { mapState } from "vuex";

export default {
  name: "Investor",
  components: {
    TopbarComponent: TopBar,
    ConditionGenerator,
  },

  data() {
    return {
      activeBuy: true,
      activeSell: false,
      conditions: [],
      currentTypeCondition: "buy",
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
      <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
      </svg> Error`,
      isCompound: false,
      isCompound: false,
      is_compound: "",
      is_investor: false,
      selectedConditionJson: {},
      show: false,
      refresh: true,
    };
  },
  created() {
    if (this.$route.name.toLowerCase() == "investor") {
      this.handleChangeRoute();
    }

    let user = localStorage.getItem("userData");
    let token = localStorage.getItem("token");
    if (user && token) {
      user = JSON.parse(Crypto.decrypt(user));
      token = Crypto.decrypt(token);
      this.$store.commit("auth_user", user);
      this.$store.commit("token", token);
    }
    this.showCopyCondition();
    EventBus.$on("handleCopyCondition", () => {
      console.log("copy created");
      this.showCopyCondition();
    });
  },
  computed: {
    ...mapState("dashboard", ["basic_information"]),
    investor_name() {
      return this.basic_information._data.data.investor_name;
    },
    is_dashboard() {
      return this.$route.name.toLowerCase() == "dashboard" ? true : false;
    },
  },
  methods: {
    activeTabCondition(type) {
      if (type === "buy") {
        this.activeBuy = true;
        this.activeSell = false;
      } else {
        this.activeBuy = false;
        this.activeSell = true;
      }
    },
    handleMargin(index) {
      let m = index * 55;
      if (index == 0) {
        m = 10;
      }
      return `${m}px`;
    },

    handleDeleteCondition(c, index) {
      let conditions = JSON.parse(
        this.$crypto.decrypt(localStorage.getItem("copyCondition"))
      );
      conditions.splice(index, 1);
      localStorage.setItem(
        "copyCondition",
        this.$crypto.encrypt(JSON.stringify(conditions))
      );
      this.showCopyCondition();
    },
    handleAddCompoundCondition(condition, type) {
      let c = {};
      if (this.activeSell) {
        c = this.$refs.conditionGeneratorBuyApp.addCondition(true);
      } else {
        c = this.$refs.conditionGeneratorSellApp.addCondition(true);
      }
      if (!c) {
        return;
      }
      if (c.base_configuration.type == condition.base_configuration.type) {
        this.$toastr.error(
          "The compound conditions must be sell and buy.",
          `${this.error}`
        );
        return;
      }

      let existOtherCondition = this.backtest.configurations.filter(
        (i) => i.base_configuration.is_compound == false
      );
      if (existOtherCondition.length > 0) {
        this.$toastr.error(
          "You only have to add compound or simple conditions.",
          `${this.error}`
        );
        return;
      }
      c.base_configuration.configuration_compound.token = condition.token;
      c.base_configuration.configuration_compound.method = parseInt(
        c.base_configuration.is_compound.replace("method", "")
      );
      condition.base_configuration.configuration_compound.token = c.token;
      condition.base_configuration.configuration_compound.method = parseInt(
        condition.base_configuration.is_compound.replace("method", "")
      );
      this.$bvModal.hide("modal-add-condition");
      if (this.backtest.configurations.length < this.maximunCondition) {
        let index = this.backtest.configurations.length;
        const color = this.$helpers.getRandomColor();
        this.colorID[index] = color;
        this.colorID[index + 1] = color;
        this.backtest.configurations.push(condition);
        this.backtest.configurations.push(c);
      } else {
        this.$toastr.error(
          "Has exceeded the limit of allowed conditions",
          `${this.error}`
        );
      }
    },
    handleCompoundCondition(value) {
      this.isCompound = value != "false";
      this.is_compound = value;
    },

    handletSeType(type) {
      this.currentTypeCondition = type;
    },
    handleChangeRoute() {
      let title = "BWFS";
      let current_host = window.location.hostname;
      if (current_host == "develop.bwfs.creedandbear.com") {
        title = "BWFS (develop)";
      }
      if (current_host == "production.bwfs.creedandbear.com") {
        title = "BWFS (production)";
      }
      if (this.is_dashboard) {
        document.title = `${title} - ${this.investor_name}`;
      } else if (this.$store.state.SubInvestorInfo.info) {
        document.title = `${title} - ${this.$store.state.SubInvestorInfo.info.name}`;
      } else {
        setTimeout(() => {
          this.handleChangeRoute();
        }, 200);
      }
    },
    showCopyCondition(self) {
      if (localStorage.getItem("copyCondition")) {
        let conditions = JSON.parse(
          this.$crypto.decrypt(localStorage.getItem("copyCondition"))
        );
        for (var i = 0; i < conditions.length; i++) {
          try {
            if (typeof conditions[i] == "object") {
              if (conditions[i][0]) {
                conditions[i] = JSON.parse(conditions[i][0]);
              } else {
                conditions[i] = conditions[i];
              }
            } else {
              conditions[i] = JSON.parse(conditions[i]);
            }
          } catch (e) {
            console.log(e.message);
          }
        }
        this.conditions = conditions;
      } else {
        return [];
      }
    },
    showCondition(condition, index) {
      if (!condition.backtest) {
        this.is_investor = true;
      } else {
        this.is_investor = false;
      }
      this.$bvModal.hide("modal-add-condition-app");
      let conditions = JSON.parse(
        this.$crypto.decrypt(localStorage.getItem("copyCondition"))
      );
      let cc = {};
      if (
        typeof conditions[index] == "object" &&
        conditions[index].length == 2
      ) {
        cc = [conditions[index][0], conditions[index][1]];
        if (typeof cc[0] == "string") {
          cc[0] = JSON.parse(cc[0]);
        }
        if (typeof cc[1] == "string") {
          cc[1] = JSON.parse(cc[1]);
        }
        cc[0].base_configuration.is_compound = `method ${cc[0].base_configuration.configuration_compound.method}`;
        cc[1].base_configuration.is_compound = `method ${cc[1].base_configuration.configuration_compound.method}`;
        this.isCompound = true;
        this.activeSell = false;
        this.activeBuy = true;
        this.show = false;
        setTimeout(() => {
          this.$refs.conditionGeneratorBuyApp.showCondition(cc[0]);
          this.$refs.conditionGeneratorSellApp.showCondition(cc[1]);
        }, 500);
        this.$bvModal.show("modal-add-condition-app");
        return;
      } else {
        if (typeof conditions[index] === "string") {
          cc = JSON.parse(conditions[index]);
        } else {
          cc = conditions[index];
        }
      }
      this.$bvModal.show("modal-add-condition-app");
      this.isCompound = false;
      this.activeSell = false;
      this.activeBuy = true;
      this.show = true;
      setTimeout(() => {
        this.$refs.conditionGeneratorBuyApp.showCondition(cc);
      }, 500);
    },
  },
  watch: {
    investor_name(value) {
      if (this.is_dashboard && value) {
        this.handleChangeRoute();
      }
    },
    $route() {
      let nameRoute = this.$route.name.toLowerCase();
      if (
        nameRoute == "investor" ||
        nameRoute == "supervisors" ||
        nameRoute == "subinvestor"
      ) {
        this.handleChangeRoute();
      } else {
        let current_host = window.location.hostname;
        let title = "BWFS";
        if (current_host == "develop.bwfs.creedandbear.com") {
          title = "BWFS (develop)";
        }
        if (current_host == "production.bwfs.creedandbear.com") {
          title = "BWFS (production)";
        }
        if (document.title.toLowerCase() !== "bwfs") {
          document.title = title;
        }
      }
    },
  },
};
</script>
<style lang="scss">
/* Estilos para el funcionamiento base de vue-bootstrap  */
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap-vue/src/index.scss";

@import "toastr";

/* Estilos global */
@import "@/styles/global.scss";
</style>
