<template>
  <div>
    <div class="exposure-chart-loading" v-if="onLoading">
      <ProcessingLoader />
    </div>
    <div class="component-exposure-chart" v-else-if="series.length > 0">
      <p>Exposure</p>
      <div class="chart-wrapper">
        <apexchart
          type="pie"
          width="100%"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>
    <div v-else class="exposure-graph-no-data">
      <div class="no-data-title">Exposure</div>
      <div class="no-data-graph">
        <div class="no-data-label">No data</div>
        <img :src="exposure_no_data" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ExposureNoData from "../../../assets/images/exposure-graph-no-data.svg";
import ProcessingLoader from "../../commons/alerts/ProcessingLoader.vue";
import number_formatter from "../../../helpers/formatters/number_formatter";

export default {
  name: "ExposureChart",
  data() {
    return {
      exposure_no_data: ExposureNoData,
      freeColors: [
        "#02c8df",
        "#3F4165",
        "#4CAF50",
        "#FF4081",
        "#795548",
        "#FFC107",
        "#9C27B0",
        "#607D8B",
        "#E65100",
      ],
    };
  },
  computed: {
    ...mapState("dashboard", ["exposure_chart"]),
    chartOptions() {
      const labels =
        this.exposure_chart._data.data.length > 0
          ? this.exposure_chart._data.data.map((item) => item.symbol)
          : [];
      const data =
        this.exposure_chart._data.data.length > 0
          ? this.exposure_chart._data.data
          : [];
      return {
        plotOptions: {
          pie: {
            expandOnClick: false,
            states: {
              normal: {
                filter: {
                  type: "none",
                  value: 0,
                },
              },
              hover: {
                filter: {
                  type: "none",
                  value: 0,
                },
              },
              active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                  type: "none",
                  value: 0,
                },
              },
            },
          },
        },
        chart: {
          width: 380,
          type: "pie",
          background: "#1D1E25",
          hover: false,
        },
        stroke: {
          show: false,
        },
        colors: this.colors,
        expandOnClick: false,
        dataLabels: {
          enabled: false,
        },
        labels: labels,
        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          fontSize: "12px",
          fontFamily: "Roboto",
          fontWeight: 400,
          color: "rgba(255, 255, 255, 0.5)",
          itemMargin: {
            horizontal: 10,
            vertical: 0,
          },
          onItemClick: {
            toggleDataSeries: false,
          },
          onItemHover: {
            highlightDataSeries: false,
          },
          markers: {
            width: 9,
            height: 9,
            radius: 2,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        tooltip: {
          enabled: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            let exposure_value = "";
            if (data[seriesIndex].side == "SHORT") {
              exposure_value = `<div class="right red">- $ ${this.formatter(
                data[seriesIndex].exposure
              )}<span class="dash"> / </span>- ${this.formatter(
                data[seriesIndex].exposure_percentage
              )} %</div>`;
            } else if (data[seriesIndex].side == "LONG") {
              exposure_value = `<div class="right green">$ ${this.formatter(
                data[seriesIndex].exposure
              )}<span class="dash"> / </span>${this.formatter(
                data[seriesIndex].exposure_percentage
              )} %</div>`;
            } else {
              exposure_value = `<div class="right">$ ${this.formatter(
                data[seriesIndex].exposure
              )}<span class="dash"> / </span>${this.formatter(
                data[seriesIndex].exposure_percentage
              )} %</div>`;
            }
            return `<div class="exposure-graph-tooltip">
              <div class="tooltip-row"><div class="left"><span class="dot" style="background-color:${w.config.colors[seriesIndex]}"></span>Symbol</div><div class="right">${data[seriesIndex].symbol}</div></div>
              <div class="tooltip-row"><div class="left">Exposure</div>${exposure_value}</div>
            </div>`;
          }.bind(this),
        },
      };
    },
    onLoading() {
      return this.exposure_chart._status._on_loading;
    },
    series() {
      return this.exposure_chart._data.data.length > 0
        ? this.exposure_chart._data.data.map((item) => item.exposure_percentage)
        : [];
    },
    colors() {
      return this.exposure_chart._data.data.length > 0
        ? this.exposure_chart._data.data.map((item) =>
            this.replaceByColor(item.symbol)
          )
        : [];
    },
  },
  methods: {
    formatter(number) {
      return number_formatter(number);
    },
    pickFreeColors() {
      if (this.freeColors.length === 0) {
        return "#FF0000"; 
      }

      const randomIndex = Math.floor(Math.random() * this.freeColors.length);
      const color = this.freeColors[randomIndex];
      this.freeColors.splice(randomIndex, 1);
      return color; 
    },
    replaceByColor(symbol) {
      const color_codes = {
        "BTC-USDT": "#FF9300",
        BTCUSDT: "#FF9300",
        USDT: "#02c8df",
        "ETH-USDT": "#3F4165",
        ETHUSDT: "#3F4165",
        BNBUSDT: "#FF9300",
        "BNB-USDT": "#FF9300",
      };
      const color = color_codes[symbol] || this.pickFreeColors();
      return color;
    },
  },
  components: { ProcessingLoader },
};
</script>

<style lang="scss">
@import "../../../styles/pages/dashboard/exposure_chart.scss";
</style>
