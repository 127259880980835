import number_formatter from "../../../helpers/formatters/number_formatter";
import random_number from "../../../helpers/generators/random_number";

export default () => {
  const fee_since_inception = number_formatter(random_number(0, 100000), 2);
  const fee_since_inception_percentage = number_formatter(
    random_number(0, 100000),
    2
  );
  const fee_year_to_date = number_formatter(random_number(0, 100000), 2);
  const fee_year_to_date_percentage = number_formatter(
    random_number(0, 100000),
    2
  );
  const fee_month_to_date = number_formatter(random_number(0, 100000), 2);
  const fee_month_to_date_percentage = number_formatter(
    random_number(0, 100000),
    2
  );

  return {
    fee_since_inception,
    fee_since_inception_percentage,
    fee_year_to_date,
    fee_year_to_date_percentage,
    fee_month_to_date,
    fee_month_to_date_percentage,
  };
};
