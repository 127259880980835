import state from "@/helpers/store/state";
import set from "@/helpers/store/set";

import exchange_list from "./actions/exchange_list";

import exchange_list_adapter from "../../services/adapters/exchange_list";

export default {
  namespaced: true,
  state: () => ({
    exchange_list: state(exchange_list_adapter),
  }),
  actions: {
    exchange_list,
  },
  mutations: { set },
};
