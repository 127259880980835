<template>
  <div class="col-lg-10">
    <div v-if="setTable">
      <div v-if="!loading" >
        <!-- <div style="width: 100%; height: 800px;">
        <v-grid
          theme='material'
          :source="rows"
          :columns="columns"
          :autoSizeColumn="true"
        ></v-grid>
        </div> !-->
        <div class="mt-2">
        <ve-table :columns="columnsEasy" :table-data="source" :sort-option="sortOption" /> 
        <div v-show="source.length === 0" class="mt-2 empty-data">No records were found.</div>
        <div class="d-flex flex-column mt-2" v-if="!isFilter">
          <div class="mb-2"><span>Records page</span></div>
          <b-pagination
            v-model="pageRecord"
            @change="pageNumberRecordsChange"
            :per-page="200"
            :total-rows="pagination.records && pagination ? pagination.records : 0"
          ></b-pagination>
        </div>
        <div class="d-flex flex-column mt-2" v-if="!isSymbol">
          <div class="mb-2"><span>Symbol set page</span></div>
          <b-pagination
            v-model="pageSymbol"
            :per-page="5"
            :total-rows="20"
            @change="pageNumberSymbolChange"
          ></b-pagination>
        </div>
        </div>
      </div>
      <div v-if="loading">
        <b-skeleton-table
          :rows="5"
          :columns="4"
          :table-props="{  striped: true }"
        ></b-skeleton-table>
      </div>
    </div>
    <div v-else>
      <div class="mt-2">
        <span>
          Please, set a Dateframe and select a symbol to show the LMDD Table Data
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import VGrid from "@revolist/vue-datagrid";
import moment from 'moment';

export default {
  name: 'LMDDTable',
  components: {
    VGrid
  },
  props: ['loading', 'rows', 'setTable', 'refreshTable', 'symbol', 'mode', 'symbolLMDDSearch', 'pagination', 'isFilter', 'isSymbol'],
  data() {
    return {
      searchValue: "",
      source: this.rows,
      pageRecord: 1,
      pageSymbol: 1,
      sortOption: {
        multipleSort: true,
        sortChange: (params) => {
          console.log("sortChange::", params);
          this.sortChange(params);
        },
      },
      columns: [
        {
          prop: 'eventType',
          name: 'eventType'
        },
        {
          prop: 'notice_types',
          name: 'Notice Types'
        },
        {
          prop: 'priceChange',
          name: 'Price Change'
        },
        {
          prop: 'period',
          name: 'Period'
        },
        {
          prop: 'sendTimestamp',
          name: 'sendTimestamp',
          filter: 'string'
        },
      ],
      columnsEasy: [
         {
          field: 'eventType',
          key: 'a',
          title: 'eventType',
          filter: {
            filterList: [
                { value: 'DOWN_BREAKTHROUGH', label: 'DOWN BREAKTHROUGH', selected: false },
                { value: 'UP_BREAKTHROUGH', label: "UP BREAKTHROUGH", selected: false },
                { value: 'UP_1', label: "UP 1", selected: false },
                { value: 'DOWN_1', label: "DOWN 1", selected: false },
                { value: 'RISE_AGAIN', label: "RISE AGAIN", selected: false },
                { value: 'DROP_BACK', label: "DROP BACK", selected: false },
              ],
              // filter confirm hook
              filterConfirm: (filterList) => {
                const labels = filterList.filter((x) => x.selected).map((x) => x.value);
                this.searchByEventTypeField(labels);
              },
              // filter reset hook
              filterReset: (filterList) => {
                this.searchByEventTypeField([]);
              },
          }
        },
        {
          field: 'notice_types',
          key: 'b',
          title: 'Notice Types',
          filter: {
            filterList: [
                { value: 'PRICE_BREAKTHROUGH', label: 'PRICE BREAKTHROUGH', selected: false },
                { value: 'PRICE_CHANGE', label: "PRICE CHANGE", selected: false },
                { value: 'PRICE_FLUCTUATION', label: "PRICE FLUCTUATION", selected: false },
              ],
              // filter confirm hook
              filterConfirm: (filterList) => {
                const labels = filterList.filter((x) => x.selected).map((x) => x.value);
                this.searchByNoticeTypeField(labels);
              },
              // filter reset hook
              filterReset: (filterList) => {
                this.searchByNoticeTypeField([]);
              },
          }
        },
        {
          field: 'priceChange',
          key: 'c',
          title: 'Price Change',
          sortBy: ''
        },
        {
          field: 'symbol',
          key: 'd',
          title: 'Symbol',
          /* filter: {
             filterList: [
                
              ],
              // filter confirm hook
              filterConfirm: (filterList) => {
                const labels = filterList.filter((x) => x.selected).map((x) => x.value);
                this.searchBySymbolField(labels);
              },
              // filter reset hook
              filterReset: (filterList) => {
                this.searchBySymbolField([]);
              },
          } */
        },
        {
          field: 'period',
          key: 'e',
          title: 'Period',
          // sortBy: ''
        },
        {
          field: 'sendTimestamp',
          key: 'f',
          title: 'sendTimestamp',
          // sortBy: ''
          /* filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
                return (
                  <div class="custom-name-filter">
                    <input
                      value={this.searchValue}
                      on-input={(e) => (this.searchValue = e.target.value)}
                      placeholder="Search name"
                    />
                    <div class="custom-name-filter-operation mr-2">
                      <span class="name-filter-cancel" style="color:black;" on-click={() => this.searchCancel(closeFn)}>
                        Cancel
                      </span>
                      <span
                        class="name-filter-confirm"
                        style="color:black;"
                        on-click={() => this.searchConfirm(closeFn)}
                      >
                        Search
                      </span>
                    </div>
                  </div>
                );
              },
            filterIcon: () => {
              return <ve-icon name="search" />;
            },
          } */
        },
      ]
    }
  },
  methods: {
    comparePos(a, b) {
      if (a.xpos < b.xpos)
          return -1;
      if (a.xpos > b.xpos)
          return 1;
      return 0;
    },
    sortChange(params) {
        let data = this.source.slice(0);
        
        if (params && params.sendTimestamp) {
          let sendTimestamp = [...this.source]
          sendTimestamp.sort((a,b) => { 
            return moment(a.sendTimestamp, 'DD/MM/YYYY, hh:mm:ss a').unix() - moment(b.sendTimestamp, 'DD/MM/YYYY, hh:mm:ss a').unix()}
          )
          if (params === "desc") sendTimestamp.reverse()
          console.log('time', sendTimestamp[0].sendTimestamp)
          this.source = sendTimestamp
          return
        }
    
        if (params && params.period) {
          let period = this.source.slice(0)
          period.sort((a, b)=> (a.period > b.period) ? 1 : ((b.period > a.period) ? -1 : 0))
          if (params === "desc") period.reverse()
          console.log('period', period)
          this.source = period
          return
        }

        if (params && params.symbol) {
          const symbol = this.source.slice(0)
          symbol.sort((a,b)=> { return a.symbol - b.symbol} )
          if (params === "desc") symbol.reverse()
          this.source = symbol
          return
        }

       
       
        
        if (params && params.priceChange) {
          data.sort((a, b) => {
            if (params.priceChange) {
              if (params.priceChange === "asc") {
                return a.priceChange - b.priceChange;
              } else if (params.priceChange === "desc") {
                return b.priceChange - a.priceChange;
              } else {
                return 0;
              }
            }
          });
        }

        this.source = data;
      },
    search() {
        const searchValue = this.searchValue;
        this.rows = this.sourceData.filter(
          (x) => !searchValue.length || x.name.toLowerCase().includes(searchValue.toLowerCase())
        );
      },
      searchByEventTypeField(labels) {
        this.source = this.rows.filter(
          (x) => labels.length === 0 || (x.eventType === labels[0] && labels.length > 0)
        );
      },
      searchByNoticeTypeField(labels) {
        this.source = this.rows.filter(
          (x) => labels.length === 0 || (x.notice_types === labels[0] && labels.length > 0)
        );
      },
      searchBySymbolField(labels) {
        this.source = this.rows.filter(
          (x) => labels.length === 0 || (x.symbol === labels[0] && labels.length > 0)
        );
      },
      // search cancel
      searchCancel(closeFn) {
        closeFn();
      },

      // search cancel
      searchConfirm(closeFn) {
        this.search();
        closeFn();
      },
      pageNumberRecordsChange(pageIndex) {
        this.pageRecord = pageIndex
        const data = {
          pageSymbol: this.pageSymbol,
          pageRecord: pageIndex,
        }
        this.$emit('setPagination', data)
      },
      pageNumberSymbolChange(pageIndex) {
        this.pageSymbol = pageIndex
        this.pageRecord = 1;
        const data = {
          pageSymbol: pageIndex,
          pageRecord: 1,
        }
        this.$emit('setPagination', data)
      },
  },
  watch: {
    rows(value) {
      this.source = value
    },
    symbolLMDDSearch(value) {
      this.searchBySymbolField(value.symbolForAllLMDD);
    },
    pagination(value) {
      this.pageRecord = value.current_page
      this.pageSymbol = value.current_symbol_page
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .ve-icon {
    color: white;
    display: inline-block;
}
::v-deep revogr-viewport-scroll .vertical-inner {
    overflow-y: auto;
    position: relative;
    width: 100%;
    flex-grow: 1;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: white;
}
.custom-name-filter {
    padding: 10px;

    .custom-name-filter-operation {
      cursor: pointer;

      margin: 10px 10px 0px 0;
      .name-filter-cancel,
      .name-filter-confirm {
        &:hover {
          color: #1890ff;
        }
      }

      .name-filter-cancel {
        float: left;
      }

      .name-filter-confirm {
        float: right;
      }
    }
  }
  .empty-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
    font-size: 16px;
    border-top: 0;
  }
</style>

