<template>
  <b-modal id="modal-show-investors" size="xl" hide-footer hide-header v-model="show">
    <h3 class="modal-title">
        Details linked users
    </h3>
      <div class="row pl-3 pr-3" v-if="is_load">
        <div class="col-md-3 p-1" v-for="i in investors">
          <UserComponent
            :value="i"
            :sup_has_investor="true"
            @handleShowEdit="handleShowEdit"
            @handleShowDuplicate="handleShowDuplicate"
          ></UserComponent> 
        </div>
      </div>
     
  </b-modal>
</template>

<script>
import UserComponent from '../investors//User.vue';

export default {
  name: 'ModalSupervisorInvestors',
  props: ['value', 'number'],
  components: {
    UserComponent,
  },
  data() {
    return{
      investors: [],
      show: this.value,
      linked_users: [],
      is_load: false,
      skeletons: this.number,
    };
  },
  async created() {
    this.fetchingInvestors();
  },
  methods: {
    async fetchingInvestors() {
      this.investors = [];
      this.is_load = false;
      if (this.$store.state.supervisor_token) {
        await this.$store.dispatch('bwfs_get_investors_from_supervisors', {vm: this, data: {token_supervisor: this.$store.state.supervisor_token}});
        this.investors = this.$store.state.investors_from_supervisors;
        this.is_load = true;
      } if (this.$store.state.investors && !this.$store.state.supervisor_token) {
        this.is_load = true;
        this.investors = this.$store.state.investors;
      }
    },
    handleShowEdit(investor) {
      let user = investor.info;
      user.name = investor.info.investor.name;
      user.conditions = investor.conditions;
      user.balances = investor.balances;
      user.on = investor.on;
      console.log(user);
      this.$store.commit('set_info_investor', user);
    },
    handleShowDuplicate(investor) {
      let user = investor.info;
      user.name = investor.info.investor.name;
      user.conditions = investor.conditions;
      user.balances = investor.balances;
      this.$store.commit('set_info_investor', user);
    },
  },
  watch: {
    value(newValue) {
      this.show = newValue;
      if (newValue === 0) {
        this.show = 1;
      }
    },
    number(newValue) {
      this.skeletons = newValue;
    },
    '$store.state.linked_users': function() {
      if(this.linked_users !== this.$store.state.linked_users) {
        // this.fetchingInvestors();
      }
    },
    '$store.state.supervisor_has_investor_deleted': function() {
      if (this.$store.state.supervisor_has_investor_deleted) {
        this.fetchingInvestors();
      }
    },
    show() {
      if(this.show) {
        this.fetchingInvestors();
      }
      if(!this.show) {
        this.is_load = false;
      }
    },
  },
  mounted() {
    // 
    this.fetchingInvestors();
  },
}
</script>

<style lang="scss" scoped>
@media (min-width: 768px) and (max-width: 1200px) {
  ::v-deep .col-md-3 {
      flex: 0 0 25%;
      max-width: 45%;
  }
}

</style>