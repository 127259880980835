import random_number from "../../../helpers/generators/random_number";
import model from "../models/group";

export default () => {
  const num = random_number(1, 5);
  let groups = [];
  for (let i = 0; i < num; i++) {
    const group = model();
    groups.push(group);
  }
  return {
    data: groups,
  };
};
