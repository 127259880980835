<template>
  <div>
    <div
      class="table-responsive"
      v-if="$store.state.supervisors.length > 0 || first_searched"
    >
      <b-table
        :fields="fields"
        :items="items"
        striped
        hover
        small
        borderless
        :busy="loadingTable"
      >
        <template #cell(actions)="data">
          <div class="ml-3">
            <a href="javascript:;" @click="deleteSupervisor(data.item.Token)">
              <b-icon icon="trash" />
            </a>
            <a href="javascript:;" @click="editSupervisor(data.item)">
              <b-icon icon="pencil-square" />
            </a>
            <a href="javascript:;" @click="showQR(data.item.Token)">
              <b-icon icon="app" />
            </a>
          </div>
        </template>
        <template #cell(linked_users)="data">
          <div>
            <b-link
              v-if="data.item.linked_users >= 0"
              @click="showModalInvestors(data.item)"
            >
              {{ data.item.linked_users }}
            </b-link>
            <b-spinner v-else variant="light" small></b-spinner>
          </div>
        </template>
        <template #cell(status)="data">
          <span
            :class="data.item.status === 0 ? 'text-success' : 'text-danger'"
          >
            {{ data.item.status === 0 ? "Active" : "Inactive" }}
          </span>
        </template>
        <template #table-busy>
          <b-skeleton-table
            :rows="5"
            :columns="4"
            :table-props="{ striped: true }"
          ></b-skeleton-table>
        </template>
      </b-table>
      <ModalSupervisorInvestors :value="showInvestors" :number="numbers" />
      <div class="mt-2">
        <b-pagination
          v-model="currentPage"
          :total-rows="count"
          :per-page="pageSize"
          @change="handlePageChange"
        />
      </div>
    </div>
    <div
      v-else-if="
        $store.state.supervisors.length == 0 &&
        $store.state.supervisor_is_searched
      "
    >
      <b-alert class="mb-2" show variant="danger"
        >No supervisor found with applied filters</b-alert
      >
    </div>
    <b-modal id="modal-code-qr" title="2FA QR Code">
      <div style="text-align: center">
        <vue-qr :text="codeQR" :size="300"></vue-qr>
      </div>
      <template #modal-footer="{ ok, cancel }"> </template>
    </b-modal>
  </div>
</template>

<script>
// import ModalDeleteSupervisor from './ModalDeleteSupervisor.vue';
import ModalSupervisorInvestors from "./ModalSupervisorInvestors.vue";
import Helpers from "../../../helpers/commons.js";
import VueQr from "vue-qr";

export default {
  name: "SupervisorList",
  components: {
    // ModalDeleteSupervisor,
    ModalSupervisorInvestors,
    VueQr,
  },
  async created() {
    this.fetchingSupervisors();
    this.getQrData();
  },
  data() {
    return {
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
        <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
        </svg> Error`,
      fields: [
        { key: "Token" },
        { key: "Email" },
        { key: "Last Login" },
        { key: "linked_users", label: "Linked Users" },
        { key: "status", label: "Status" },
        { key: "actions" },
      ],
      linked_users: null,
      showModal: false,
      showInvestors: false,
      supervisors: null,
      supervisor: null,
      currentPage: 1,
      pageSize: 25,
      loaded: false,
      first_searched: true,
      numbers: 1,
      codesQR: [],
      codeQR: "",
    };
  },
  computed: {
    items() {
      if (this.supervisors) {
        return this.supervisors.map((sup) => {
          return {
            Token: sup.token,
            Email: sup.email,
            "Last Login": Helpers.formatToUTC(sup.last_login),
            linked_users: this.linked_users
              ? this.linked_users.filter(
                  (c) => c.supervisor_user_token === sup.token
                ).length
              : "-",
            status: sup.status,
          };
        });
      }
      return [];
    },
    loadingTable() {
      if (
        this.$store.state.supervisors_loading &&
        !this.$store.state.supervisor_has_investor_loaded
      ) {
        return true;
      }
      if (this.$store.state.supervisor_has_investor_loaded) {
        return false;
      }
    },
    count() {
      if (
        this.$store.state.supervisor_paginate &&
        this.$store.state.supervisor_paginate.records
      ) {
        return this.$store.state.supervisor_paginate.records;
      }
      return 25;
    },
  },
  methods: {
    async showModalInvestors(value) {
      this.$store.commit("set_supervisor_token", value.Token);
      this.numbers = value.linked_users;
      this.showInvestors = !this.showInvestors;
    },
    editSupervisor(item) {
      this.$store.commit("set_supervisor_data", item);
      this.$store.commit("set_edit_supervisor", true);
    },
    async deleteSupervisor(value) {
      console.log(value);
      const data = value;
      if (confirm("are you sure?")) {
        await this.$store.dispatch("bwfs_delete_supervisor", {
          vm: this,
          data,
        });
        if (this.$store.state.supervisor_is_deleted);
        {
          this.fetchingSupervisors();
        }
      }
    },
    async handlePageChange(value) {
      this.currentPage = value;
      await this.$store.dispatch("bwfs_get_supervisors", {
        vm: this,
        page: value,
        search: this.$store.state.search_supervisor,
        status: this.$store.state.search_supervisor_status,
      });
      if (this.$store.state.supervisors) {
        await this.$store.dispatch("bwfs_get_supervisor_has_investor", this);
        this.supervisors = this.$store.state.supervisors;
        this.first_searched = false;
      }
      if (this.$store.state.linked_users) {
        this.linked_users = this.$store.state.linked_users;
      }
    },
    async fetchingSupervisors() {
      await this.$store.dispatch("bwfs_get_supervisor", {
        vm: this,
        data: {
          page: 1,
          search: this.$store.state.search_supervisor,
          status: this.$store.state.search_supervisor_status,
        },
      });
      if (this.$store.state.supervisors) {
        await this.$store.dispatch("bwfs_get_supervisor_has_investor", this);
        this.supervisors = this.$store.state.supervisors;
        this.first_searched = false;
      }
      if (this.$store.state.linked_users) {
        this.linked_users = this.$store.state.linked_users;
      }
    },
    getQrData() {
      this.$http
        .server1("bwfs/admin/getTOTPURI", {})
        .then((res) => {
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.codesQR = res.data;
        })
        .catch((e) => {
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    showQR(token) {
      let user = this.codesQR.find(
        (user) => user.token === token && user.has_2fa
      );
      if (!user) {
        this.$toastr.error(
          "This user does not have a qr code",
          `${this.error}`
        );
      }
      this.codeQR = user.uri;
      this.$bvModal.show("modal-code-qr");
    },
  },
  watch: {
    "$store.state.supervisor_is_created": async function () {
      if (this.$store.state.supervisor_is_created) {
        this.fetchingSupervisors();
        this.$store.commit("set_supervisor_is_created", false);
      }
    },
    "$store.state.supervisor_is_searched": function () {
      if (
        this.$store.state.supervisor_is_searched &&
        !this.$store.state.supervisor_is_loaded
      ) {
        this.supervisors = this.$store.state.supervisors;
      }
    },
    "$store.state.supervisor_has_investor_loaded": function () {
      if (this.$store.state.supervisor_has_investor_loaded) {
        this.$store.commit("set_supervisor_has_investor_loaded", false);
      }
    },
    "$store.state.supervisor_has_investor_deleted": async function () {
      if (this.$store.state.supervisor_has_investor_deleted) {
        this.fetchingSupervisors();
      }
    },
    showInvestors() {
      if (
        this.showInvestors &&
        this.$store.state.supervisor_has_investor_deleted
      ) {
        this.supervisors = this.$store.state.supervisors;
      }
    },
  },
};
</script>
