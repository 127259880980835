import TransactionListService from "../../../services/transaction_list.service";
import moment from "moment";

export default ({ commit, state }, { page }) => {
  commit("set", {
    _state: "transaction_list",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });

  const { exchange_connection, event_type, limit, date, search } =
    state.transaction_filters;

  const params = {
    event_type,
    from_date: date
      ? moment(date.startDate).format("YYYY-MM-DD HH:MM:SS")
      : null,
    investor_token: state.investor_token,
    limit,
    page,
    to_date: date ? moment(date.endDate).format("YYYY-MM-DD HH:MM:SS") : null,
    // search,
    // exchange_connection,
  };

  TransactionListService({
    params,
    callback: ({ response }) => {
      commit("set", {
        _state: "transaction_list",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "transaction_list",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
