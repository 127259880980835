<template>
	<textarea
		:class="[ 'form-control' ]"
		@input="handleInput"
		v-model="content"
		:disabled="disabled"
	></textarea>
</template>
<script>
	export default {
		name: 'Textarea',
		props: {
	    placeholder: {
	      required: false,
	      type: String,
	    },
	    id: {
	      required: true,
	      type: String,
	    },
	    messageError: {
	      required: false,
	      type: String,
	    },
	    error: {
	      required: false,
	      type: Boolean,
	      default: false,
	    },
	    value: {
	      required: false,
	    },
	    disabled: {
	      required: false,
	      default: false,
	    },
		},
		data() {
	    return {
	      content: this.value,
	    };
	  },
		methods: {
	    handleInput() {
	      this.$emit('input', this.content);
	    },
	  },
	  watch: {
	    value(newValue) {
	      this.content = newValue;
	    },
	  },
	}
</script>