<template>
  <div class="col-md-12 p-0 balances-table">
    <div class="col-md-12 mt-1">Preview final distribution</div>

    <b-table
      :items="simulated_balances"
      :fields="fields_balances_table"
      :currentPage="1"
      :per-page="100"
      responsive
      show-empty
      empty-text="Please add balance distibution."
    >
      <template #cell(currency)="data">
        {{ data.item.name.toUpperCase() }}
      </template>

      <template #cell(total_withdrawal)="data">
        {{ data.item.total_withdrawal }}
      </template>

      <template #cell(total_deposit)="data">
        {{ data.item.total_deposit }}
      </template>

      <template #cell(initial_balance)="data">
        {{
          data.item.initial_balance
            ? $helpers.numberFormat(data.item.initial_balance)
            : "0"
        }}<br />
      </template>
      <template #cell(current_balance)="data">
        {{
          data.item.current_balance
            ? $helpers.numberFormat(data.item.current_balance)
            : "0"
        }}<br />
      </template>
      <template #table-busy>
        <b-skeleton-table
          :rows="3"
          :columns="3"
          :table-props="{ striped: true }"
        >
        </b-skeleton-table>
      </template>
    </b-table>
  </div>
</template>
<script>
export default {
  name: "TablePreviewTransaction",
  props: {
    balances: {
      type: Array,
      required: true,
      default: () => [],
    },
    type_transaction: {
      type: String,
      required: true,
      default: "deposit",
    },
    type_distribution: {
      type: String,
      required: true,
      default: "individual",
    },
    balance_distribution_token: {
      type: String,
      required: true,
      default: "",
    },
    amount: {
      required: true,
    },
  },
  created() {
    let options = [
      { key: "name", label: "Algorithm" },
      { key: "total_deposit", label: "N° deposit" },
      { key: "total_withdrawal", label: "N° withdraw" },
      { key: "initial_balance", label: "Initial balance" },
      { key: "current_balance", label: "Current balance" },
    ];
    this.fields_balances_table = options;
  },
  data() {
    return {
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
      <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
      </svg> Error`,
      fields_balances_table: [],
      simulated_balances: JSON.parse(JSON.stringify(this.$props.balances)),
    };
  },
  methods: {
    simulateWithdrawDistributed() {
      let algorithms = JSON.parse(JSON.stringify(this.$props.balances));
      let withdraw = parseFloat(this.$props.amount);
      let amount_withdraw_filled = withdraw;
      let amount_by_algorithm = withdraw / algorithms.length;

      let total = algorithms.reduce((previous, current) => {
        let p = previous;
        if (typeof previous == "object") {
          p = previous["current_balance"];
        }

        return p + current["current_balance"];
      });

      if (total < withdraw) {
        this.$toastr.error(
          "Amount is greater that current balance.",
          `${this.error}`
        );
        return;
      }

      for (let i = 0; i < algorithms.length; i++) {
        let algorithm = { ...algorithms[i] };

        let r = amount_by_algorithm;
        if (algorithm["current_balance"] < amount_by_algorithm) {
          r = algorithm["current_balance"];
          amount_withdraw_filled -= r;
          amount_by_algorithm =
            amount_withdraw_filled / (algorithms.length - (i + 1));
        }
        algorithm["current_balance"] -= r;
        this.$set(this.simulated_balances, i, algorithm);
      }
    },
    simulateDepositDistributed() {
      let algorithms = JSON.parse(JSON.stringify(this.$props.balances));
      let deposit = parseFloat(this.$props.amount);
      let total_algorithm_available = algorithms.length;
      let deposit_by_algorithm = deposit / total_algorithm_available;

      for (let i = 0; i < algorithms.length; i++) {
        let algorithm = algorithms[i];
        algorithm["current_balance"] += deposit_by_algorithm;
        this.$set(this.simulated_balances, i, algorithm);
      }
    },
    simulateTransactionIndividual() {
      let index_selected;
      let algorithm = {
        ...this.$props.balances.find(
          (e) => e.token === this.$props.balance_distribution_token
        ),
      };
      let current_balance = parseFloat(algorithm["current_balance"]);

      this.$props.balances.map((item, index) => {
        if (item.token === this.$props.balance_distribution_token) {
          index_selected = index;
        }
        return item;
      });

      if (
        this.$props.type_transaction == "withdraw" &&
        parseFloat(this.$props.amount) > current_balance
      ) {
        this.$toastr.error(
          "Amount is greater that current balance.",
          `${this.error}`
        );

        return;
      }

      if (this.$props.type_transaction == "withdraw") {
        current_balance -= parseFloat(this.$props.amount);
      } else if (this.$props.type_transaction == "deposit") {
        current_balance += parseFloat(this.$props.amount);
      }

      algorithm["current_balance"] = current_balance;
      this.$set(this.simulated_balances, index_selected, algorithm);
    },
    reset_table() {
      let all_balances = this.$props.balances;
      for (let i = 0; i < all_balances.length; i++) {
        all_balances[i];
        this.$set(
          this.simulated_balances,
          i,
          JSON.parse(JSON.stringify(all_balances[i]))
        );
      }
    },
  },
  watch: {
    type_distribution(value) {
      let type_transaction = this.$props.type_transaction;
      if (
        value.toLowerCase() == "distributed" &&
        type_transaction == "withdraw" &&
        this.$props.amount > 0
      ) {
        this.simulateWithdrawDistributed();
      } else if (
        value.toLowerCase() == "distributed" &&
        type_transaction == "deposit" &&
        this.$props.amount > 0
      ) {
        this.simulateDepositDistributed();
      } else if (value == "individual" && this.$props.amount > 0) {
        this.simulateTransactionIndividual();
      }
    },
    type_transaction(value) {
      let type_distribution = this.$props.type_distribution;

      if (
        type_distribution == "distributed" &&
        value == "withdraw" &&
        this.$props.amount > 0
      ) {
        this.simulateWithdrawDistributed();
      } else if (
        type_distribution == "distributed" &&
        value == "deposit" &&
        this.$props.amount > 0
      ) {
        this.simulateDepositDistributed();
      } else if (type_distribution == "distributed" && this.$props.amount > 0) {
        this.simulateTransactionIndividual();
      } else if (type_distribution == "individual" && this.$props.amount > 0) {
        this.simulateTransactionIndividual();
      }
    },
    amount(value) {
      if (!value) {
        this.reset_table();
        return;
      }
      let type_distribution = this.$props.type_distribution;
      let type_transaction = this.$props.type_transaction;

      if (
        type_distribution == "distributed" &&
        type_transaction == "withdraw" &&
        value > 0
      ) {
        this.simulateWithdrawDistributed();
      } else if (
        type_distribution == "distributed" &&
        type_transaction == "deposit" &&
        value > 0
      ) {
        this.simulateDepositDistributed();
      } else if (type_distribution == "individual" && this.$props.amount > 0) {
        this.simulateTransactionIndividual();
      }
    },
  },
};
</script>
