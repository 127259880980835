import SystemsHearbeatListService from "../../../services/systems_heartbeat_list.service";
export default ({ commit, state }, { page }) => {
  commit("set", {
    _state: "systems_heartbeat_list",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });

  const {
    algorithm_token,
    limit,
    is_active,
    is_health_ok,
    search,
    sort,
    sort_by,
    tags,
  } = state.systems_heartbeat_filters;

  const params = {
    algorithm_token,
    is_active,
    is_health_ok,
    limit,
    page,
    search,
    sort: sort == "" ? "asc" : sort,
    sort_by: sort_by == "" ? "name" : sort_by,
    tags,
  };

  SystemsHearbeatListService({
    params,
    callback: ({ response }) => {
      commit("set", {
        _state: "systems_heartbeat_list",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "systems_heartbeat_list",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
