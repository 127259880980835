import number_formatter from "../../../helpers/formatters/number_formatter";
import random_float from "../../../helpers/generators/random_float";

export default () => {
  const max_drawdown_since_inception_percentage = number_formatter(
    random_float(-10, 10),
    2
  );
  const max_drawdown_year_to_date_percentage = number_formatter(
    random_float(-10, 10),
    2
  );
  const max_drawdown_month_to_date_percentage = number_formatter(
    random_float(-10, 10),
    2
  );

  return {
    max_drawdown_since_inception_percentage,
    max_drawdown_year_to_date_percentage,
    max_drawdown_month_to_date_percentage,
  };
};
