import moment from "moment";
const error_icon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
<path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
</svg> Error`;

const success_icon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
<g clip-path="url(#clip0_1634_940)">
<path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
</g>
<defs>
<clipPath id="clip0_1634_940">
<rect width="10" height="10" fill="white" transform="translate(5 5)"/>
</clipPath>
</defs>
</svg> Success`;

const Helpers = {
  formatDateYMD: (date) => moment(date).locale("es").format("YYYY-MM-DD"),
  formatDateYMDHMS: (date) =>
    moment(date).locale("es").format("YYYY-MM-DD HH:mm:ss"),
  getRandomInt: (min = 9999999, max = 99999999) =>
    Math.floor(Math.random() * (max - min)) + min,
  observerClean: (obj) =>
    Object.keys(obj).reduce(
      (res, e) => Object.assign(res, { [e]: obj[e] }),
      {}
    ),
  numberToUSD: (number) =>
    Number(number).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    }),
  numberToFixed: (number, fixed = 5) =>
    number ? number.toFixed(fixed) : number,
  monthDiff: (dateFrom, dateTo) =>
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear()),
  formatToUTC: (date) =>
    moment.utc(date).format("YYYY-MM-DD h:mm:ss A") + " UTC",
  generateRandomString: (max) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < max; i += 1) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  valid1To100: (value, positive = true) => {
    if (value.toString().length >= 3 && Number(value) > 0) {
      if (value.toString().indexOf(".") > -1) {
        value = Number(value.toString().slice(0, 4));
      } else {
        value = Number(value.toString().slice(0, 3));
      }
      if (value > 100) {
        value = 100;
      }
    } else if (Number(value) < 0 && positive) {
      value = Number(value) * -1;
    } else if (Number(value) < 0 && !positive) {
      if (value.toString().indexOf(".") > -1) {
        if (value.toString().substr(-1) !== ".") {
          value = Number(value.toString().slice(0, 5));
        } else {
          return value;
        }
      } else {
        value = Number(value.toString().slice(0, 4));
      }
      value = Number(value.toString().slice(0, 4));
      if (-100 > value) {
        value = -100;
      }
    }
    return value;
  },
  toNumberValuesCondition: (condition) => {
    const validKeyNumber = [
      // base_configuration
      "base_configuration.lot",
      "base_configuration.balance",
      // 'base_configuration.laverga',
      "base_configuration.orders_maximum",
      "base_configuration.orders_time_to_wait_for_new_orders",
      "base_configuration.investment_maximum",
      // 'base_configuration.comission',

      // signal_condition
      "signal_condition.condition_ema.period",
      "signal_condition.condition_001.divisor",
      "signal_condition.condition_001.multiplicator",
      "signal_condition.condition_002.divisor",
      "signal_condition.condition_002.multiplicator",
      "signal_condition.condition_003.divisor",
      "signal_condition.condition_003.multiplicator",
      "signal_condition.condition_004.divisor",
      "signal_condition.condition_004.multiplicator",
      "signal_condition.condition_clients_short.validator",
      "signal_condition.condition_clients_medium.validator",
      // open_condition
      "open_condition.max_time_to_wait",
      "open_condition.open_trailing",
      "open_condition.micro_pump.movement_percentage",
      "open_condition.micro_pump.time_to_validate",

      "open_condition.max_min_trigger.min_avg",
      "open_condition.max_min_trigger.max_avg",

      // close_condition
      "close_condition.take_profit",
      "close_condition.stop_loss",
      "close_condition.trailing_activation",
      "close_condition.trailing_step",

      // close_condition_dynamic
      "close_condition_dynamic.activation_percentage",
      "close_condition_dynamic.max_open_time",
      "close_condition_dynamic.condition_ema.period",
      "close_condition_dynamic.condition_001.divisor",
      "close_condition_dynamic.condition_001.multiplicator",
      "close_condition_dynamic.condition_002.divisor",
      "close_condition_dynamic.condition_002.multiplicator",
      "close_condition_dynamic.condition_003.divisor",
      "close_condition_dynamic.condition_003.multiplicator",
      "close_condition_dynamic.condition_004.divisor",
      "close_condition_dynamic.condition_004.multiplicator",
      "close_condition_dynamic.condition_clients_short.validator",
      "close_condition_dynamic.condition_clients_medium.validator",
    ];

    validKeyNumber.forEach((keyOption) => {
      let keys = keyOption.split(".");
      try {
        if (keys.length == 2) {
          if (condition[keys[0]][keys[1]] !== undefined) {
            condition[keys[0]][keys[1]] = Number(
              condition[keys[0]][keys[1]].toString().replace(/[a-z]/gi, "")
            );
          }
        } else if (keys.length == 3) {
          condition[keys[0]][keys[1]][keys[2]] = Number(
            condition[keys[0]][keys[1]][keys[2]]
              .toString()
              .replace(/[a-z]/gi, "")
          );
        } else if (keys.length == 4) {
          condition[keys[0]][keys[1]][keys[2]][keys[3]] = Number(
            condition[keys[0]][keys[1]][keys[2]][keys[3]]
              .toString()
              .replace(/[a-z]/gi, "")
          );
        } else if (keys.length == 5) {
          condition[keys[0]][keys[1]][keys[2]][keys[3]][keys[5]] = Number(
            condition[keys[0]][keys[1]][keys[2]][keys[3]][keys[5]]
              .toString()
              .replace(/[a-z]/gi, "")
          );
        }
      } catch (e) {}
    });

    return condition;
  },
  toPercentageValuescondition: (condition) => {
    const validKeyPercentage = [
      "base_configuration.lot",
      // 'base_configuration.comission',
      "open_condition.open_trailing",
      "open_condition.micro_pump.movement_percentage",
      "open_condition.max_min_trigger.min_avg",
      "open_condition.max_min_trigger.max_avg",
      "close_condition.take_profit",
      "close_condition.stop_loss",
      "close_condition.trailing_activation",
      "close_condition.trailing_step",
      "close_condition_dynamic.activation_percentage",
    ];

    validKeyPercentage.forEach((keyOption) => {
      let keys = keyOption.split(".");
      try {
        if (keys.length == 2) {
          if (condition[keys[0]][keys[1]] !== undefined) {
            condition[keys[0]][keys[1]] = condition[keys[0]][keys[1]] / 100;
          }
        } else if (keys.length == 3) {
          condition[keys[0]][keys[1]][keys[2]] =
            condition[keys[0]][keys[1]][keys[2]] / 100;
        } else if (keys.length == 4) {
          condition[keys[0]][keys[1]][keys[2]][keys[3]] =
            condition[keys[0]][keys[1]][keys[2]][keys[3]] / 100;
        }
      } catch (e) {}
    });
    return condition;
  },
  fallbackCopyTextToClipboard(text, vm) {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
      vm.$toastr.success("Copying to clipboard was successful!", success_icon);
    } catch (err) {
      vm.$toastr.error("Could not copy", error_icon);
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  },
  copyTextToClipboard(text, vm) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text, vm);
      return;
    }
    navigator.clipboard.writeText(text).then(
      () => {
        vm.$toastr.success(
          "Copying to clipboard was successful!",
          success_icon
        );
      },
      () => {
        vm.$toastr.error("Could not copy.", error_icon);
      }
    );
  },
  toParseNumber(symbol, field, value) {
    if (symbol == undefined) {
      symbol = "";
    }
    if (field == "perfomance_close" || field == "perfomance_open") {
      return Number(value).toFixed(6);
    }
    if (value && value.toString().indexOf("e") > -1) {
      value = Number(value.toString().substr(0, 10));
    }

    if (
      symbol.toString().toLowerCase() == "usdt" ||
      symbol.toString().substr(-4).toLowerCase() == "usdt"
    ) {
      if (field.indexOf("lot") > -1) {
        return Number(value).toFixed(4);
      }
      return Number(value).toFixed(2);
    } else if (symbol.toLowerCase() == "btc") {
      return Number(value).toFixed(6);
    } else if (symbol.toString() === "bnb" || symbol.toString() === "eth") {
      return Number(value).toFixed(4);
    }
    if (
      symbol.toLowerCase() == "btcusdt" &&
      field != "close_lot" &&
      field != "open_lot"
    ) {
      return Number(value).toFixed(2);
    }
    if (symbol.toString().toLowerCase().substr(0, 4) === "usdt") {
      return Number(value).toFixed(2);
    } else if (
      symbol.toString().toLowerCase().substr(0, 3) === "bnb" ||
      symbol.toString().toLowerCase().substr(0, 3) === "eth"
    ) {
      return Number(value).toFixed(4);
    } else if (
      symbol.toString().toLowerCase().substr(0, 3) === "btc" ||
      symbol.toString().toLowerCase().substr(-3) == "btc"
    ) {
      if (field.indexOf("lot") > -1 || field.indexOf("profit_coin") > -1) {
        return Number(value).toFixed(6);
      } else {
        return Number(value).toFixed(4);
      }
    } else {
      return Number(value).toFixed(2);
    }
  },
  numberFormat(value) {
    return new Intl.NumberFormat("en-US").format(parseFloat(value));
  },
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i += 1) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  },
  MD5(string) {
    function RotateLeft(lValue, iShiftBits) {
      return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
    }

    function AddUnsigned(lX, lY) {
      var lX4, lY4, lX8, lY8, lResult;
      lX8 = lX & 0x80000000;
      lY8 = lY & 0x80000000;
      lX4 = lX & 0x40000000;
      lY4 = lY & 0x40000000;
      lResult = (lX & 0x3fffffff) + (lY & 0x3fffffff);
      if (lX4 & lY4) {
        return lResult ^ 0x80000000 ^ lX8 ^ lY8;
      }
      if (lX4 | lY4) {
        if (lResult & 0x40000000) {
          return lResult ^ 0xc0000000 ^ lX8 ^ lY8;
        } else {
          return lResult ^ 0x40000000 ^ lX8 ^ lY8;
        }
      } else {
        return lResult ^ lX8 ^ lY8;
      }
    }

    function F(x, y, z) {
      return (x & y) | (~x & z);
    }
    function G(x, y, z) {
      return (x & z) | (y & ~z);
    }
    function H(x, y, z) {
      return x ^ y ^ z;
    }
    function I(x, y, z) {
      return y ^ (x | ~z);
    }

    function FF(a, b, c, d, x, s, ac) {
      a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
      return AddUnsigned(RotateLeft(a, s), b);
    }

    function GG(a, b, c, d, x, s, ac) {
      a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
      return AddUnsigned(RotateLeft(a, s), b);
    }

    function HH(a, b, c, d, x, s, ac) {
      a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
      return AddUnsigned(RotateLeft(a, s), b);
    }

    function II(a, b, c, d, x, s, ac) {
      a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
      return AddUnsigned(RotateLeft(a, s), b);
    }

    function ConvertToWordArray(string) {
      var lWordCount;
      var lMessageLength = string.length;
      var lNumberOfWords_temp1 = lMessageLength + 8;
      var lNumberOfWords_temp2 =
        (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64;
      var lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16;
      var lWordArray = Array(lNumberOfWords - 1);
      var lBytePosition = 0;
      var lByteCount = 0;
      while (lByteCount < lMessageLength) {
        lWordCount = (lByteCount - (lByteCount % 4)) / 4;
        lBytePosition = (lByteCount % 4) * 8;
        lWordArray[lWordCount] =
          lWordArray[lWordCount] |
          (string.charCodeAt(lByteCount) << lBytePosition);
        lByteCount++;
      }
      lWordCount = (lByteCount - (lByteCount % 4)) / 4;
      lBytePosition = (lByteCount % 4) * 8;
      lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
      lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
      lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
      return lWordArray;
    }

    function WordToHex(lValue) {
      var WordToHexValue = "",
        WordToHexValue_temp = "",
        lByte,
        lCount;
      for (lCount = 0; lCount <= 3; lCount++) {
        lByte = (lValue >>> (lCount * 8)) & 255;
        WordToHexValue_temp = "0" + lByte.toString(16);
        WordToHexValue =
          WordToHexValue +
          WordToHexValue_temp.substr(WordToHexValue_temp.length - 2, 2);
      }
      return WordToHexValue;
    }

    function Utf8Encode(string) {
      string = string.replace(/\r\n/g, "\n");
      var utftext = "";

      for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);

        if (c < 128) {
          utftext += String.fromCharCode(c);
        } else if (c > 127 && c < 2048) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
        } else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          utftext += String.fromCharCode((c & 63) | 128);
        }
      }

      return utftext;
    }

    var x = Array();
    var k, AA, BB, CC, DD, a, b, c, d;
    var S11 = 7,
      S12 = 12,
      S13 = 17,
      S14 = 22;
    var S21 = 5,
      S22 = 9,
      S23 = 14,
      S24 = 20;
    var S31 = 4,
      S32 = 11,
      S33 = 16,
      S34 = 23;
    var S41 = 6,
      S42 = 10,
      S43 = 15,
      S44 = 21;

    string = Utf8Encode(string);

    x = ConvertToWordArray(string);

    a = 0x67452301;
    b = 0xefcdab89;
    c = 0x98badcfe;
    d = 0x10325476;

    for (k = 0; k < x.length; k += 16) {
      AA = a;
      BB = b;
      CC = c;
      DD = d;
      a = FF(a, b, c, d, x[k + 0], S11, 0xd76aa478);
      d = FF(d, a, b, c, x[k + 1], S12, 0xe8c7b756);
      c = FF(c, d, a, b, x[k + 2], S13, 0x242070db);
      b = FF(b, c, d, a, x[k + 3], S14, 0xc1bdceee);
      a = FF(a, b, c, d, x[k + 4], S11, 0xf57c0faf);
      d = FF(d, a, b, c, x[k + 5], S12, 0x4787c62a);
      c = FF(c, d, a, b, x[k + 6], S13, 0xa8304613);
      b = FF(b, c, d, a, x[k + 7], S14, 0xfd469501);
      a = FF(a, b, c, d, x[k + 8], S11, 0x698098d8);
      d = FF(d, a, b, c, x[k + 9], S12, 0x8b44f7af);
      c = FF(c, d, a, b, x[k + 10], S13, 0xffff5bb1);
      b = FF(b, c, d, a, x[k + 11], S14, 0x895cd7be);
      a = FF(a, b, c, d, x[k + 12], S11, 0x6b901122);
      d = FF(d, a, b, c, x[k + 13], S12, 0xfd987193);
      c = FF(c, d, a, b, x[k + 14], S13, 0xa679438e);
      b = FF(b, c, d, a, x[k + 15], S14, 0x49b40821);
      a = GG(a, b, c, d, x[k + 1], S21, 0xf61e2562);
      d = GG(d, a, b, c, x[k + 6], S22, 0xc040b340);
      c = GG(c, d, a, b, x[k + 11], S23, 0x265e5a51);
      b = GG(b, c, d, a, x[k + 0], S24, 0xe9b6c7aa);
      a = GG(a, b, c, d, x[k + 5], S21, 0xd62f105d);
      d = GG(d, a, b, c, x[k + 10], S22, 0x2441453);
      c = GG(c, d, a, b, x[k + 15], S23, 0xd8a1e681);
      b = GG(b, c, d, a, x[k + 4], S24, 0xe7d3fbc8);
      a = GG(a, b, c, d, x[k + 9], S21, 0x21e1cde6);
      d = GG(d, a, b, c, x[k + 14], S22, 0xc33707d6);
      c = GG(c, d, a, b, x[k + 3], S23, 0xf4d50d87);
      b = GG(b, c, d, a, x[k + 8], S24, 0x455a14ed);
      a = GG(a, b, c, d, x[k + 13], S21, 0xa9e3e905);
      d = GG(d, a, b, c, x[k + 2], S22, 0xfcefa3f8);
      c = GG(c, d, a, b, x[k + 7], S23, 0x676f02d9);
      b = GG(b, c, d, a, x[k + 12], S24, 0x8d2a4c8a);
      a = HH(a, b, c, d, x[k + 5], S31, 0xfffa3942);
      d = HH(d, a, b, c, x[k + 8], S32, 0x8771f681);
      c = HH(c, d, a, b, x[k + 11], S33, 0x6d9d6122);
      b = HH(b, c, d, a, x[k + 14], S34, 0xfde5380c);
      a = HH(a, b, c, d, x[k + 1], S31, 0xa4beea44);
      d = HH(d, a, b, c, x[k + 4], S32, 0x4bdecfa9);
      c = HH(c, d, a, b, x[k + 7], S33, 0xf6bb4b60);
      b = HH(b, c, d, a, x[k + 10], S34, 0xbebfbc70);
      a = HH(a, b, c, d, x[k + 13], S31, 0x289b7ec6);
      d = HH(d, a, b, c, x[k + 0], S32, 0xeaa127fa);
      c = HH(c, d, a, b, x[k + 3], S33, 0xd4ef3085);
      b = HH(b, c, d, a, x[k + 6], S34, 0x4881d05);
      a = HH(a, b, c, d, x[k + 9], S31, 0xd9d4d039);
      d = HH(d, a, b, c, x[k + 12], S32, 0xe6db99e5);
      c = HH(c, d, a, b, x[k + 15], S33, 0x1fa27cf8);
      b = HH(b, c, d, a, x[k + 2], S34, 0xc4ac5665);
      a = II(a, b, c, d, x[k + 0], S41, 0xf4292244);
      d = II(d, a, b, c, x[k + 7], S42, 0x432aff97);
      c = II(c, d, a, b, x[k + 14], S43, 0xab9423a7);
      b = II(b, c, d, a, x[k + 5], S44, 0xfc93a039);
      a = II(a, b, c, d, x[k + 12], S41, 0x655b59c3);
      d = II(d, a, b, c, x[k + 3], S42, 0x8f0ccc92);
      c = II(c, d, a, b, x[k + 10], S43, 0xffeff47d);
      b = II(b, c, d, a, x[k + 1], S44, 0x85845dd1);
      a = II(a, b, c, d, x[k + 8], S41, 0x6fa87e4f);
      d = II(d, a, b, c, x[k + 15], S42, 0xfe2ce6e0);
      c = II(c, d, a, b, x[k + 6], S43, 0xa3014314);
      b = II(b, c, d, a, x[k + 13], S44, 0x4e0811a1);
      a = II(a, b, c, d, x[k + 4], S41, 0xf7537e82);
      d = II(d, a, b, c, x[k + 11], S42, 0xbd3af235);
      c = II(c, d, a, b, x[k + 2], S43, 0x2ad7d2bb);
      b = II(b, c, d, a, x[k + 9], S44, 0xeb86d391);
      a = AddUnsigned(a, AA);
      b = AddUnsigned(b, BB);
      c = AddUnsigned(c, CC);
      d = AddUnsigned(d, DD);
    }

    var temp = WordToHex(a) + WordToHex(b) + WordToHex(c) + WordToHex(d);

    return temp.toLowerCase();
  },
  setIconDanger() {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = "/redicon.png";
  },
  resetFavIcon() {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = "/favicon.ico";
  },
  copyConditions(condition, vue, EventBus) {
    let conditions = [];
    let conditionCurrent = condition;

    conditions.push(condition);
    if (localStorage.getItem("copyCondition")) {
      let c = localStorage.getItem("copyCondition");
      c = JSON.parse(vue.$crypto.decrypt(c));
      conditions = [...conditions, ...c];
    }

    if (typeof conditionCurrent == "string") {
      vue.$helpers.copyTextToClipboard(conditionCurrent, vue);
    } else {
      vue.$helpers.copyTextToClipboard(JSON.stringify(conditionCurrent), vue);
    }

    localStorage.setItem(
      "copyCondition",
      vue.$crypto.encrypt(JSON.stringify(conditions))
    );
    EventBus.$emit("handleCopyCondition", true);
    // vue.$toastr.success("Condition copied with successful.");
  },
  copyValues(value, vue) {
    vue.$helpers.copyTextToClipboard(value, vue);
  },
  setWindowName() {
    let current_host = window.location.hostname;
    if (current_host == "develop.bwfs.creedandbear.com") {
      return (window.document.title = "BWFS (develop)");
    }
    if (current_host == "production.bwfs.creedandbear.com") {
      return (window.document.title = "BWFS (production)");
    }
    return (window.document.title = "BWFS");
  },
  getDaysDiff(startDate, endDate) {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  },
  filterJson(json, search_value) {
    function add(object_type) {
      return (val, key) => {
        return object_type ? [...matched, val] : { ...matched, [key]: val };
      };
    }
    function isTextMatch(obj, text) {
      return obj.toString().toLowerCase().includes(text.toLowerCase());
    }
    function hasValues(obj) {
      return Object.keys(obj).length > 0;
    }

    const objectType = Array.isArray(json);
    let matched = objectType ? [] : {};
    for (let key in json) {
      const obj = json[key];
      if (typeof obj === "object") {
        const _obj = this.filterJson(obj, search_value);
        if (hasValues(_obj)) {
          matched = add(objectType)(_obj, key);
        }
      } else if (isTextMatch(obj, search_value)) {
        matched = add(objectType)(obj, key);
      }
    }
    return matched;
  },
};

export default Helpers;
