<template>
 	<b-modal id="modal-show-backtest" hide-footer hide-header size="xl" ok-only>
    <div class="modal-create-backtest" id="modal-create-backtest">
      <h3 class="modal-title">
        Details backtest
      </h3>
      <div class="row">
        <div class="col-md-12">
          <label class="text-muted">
            basic configuration
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3 mb-2 px-2">
          <div class="content p-2 item-active-config">
            <label>Symbol</label>
            <div class="row px-2" style="margin-top: 0px;">
              <div class="col-md-12 px-2">
                <select class="form-control nice-scroll" v-model="$store.state.backtest_detail.symbol.toUpperCase()" disabled="true">
                  <option>BTCUSDT</option>
                  <option>{{$store.state.backtest_detail.symbol.toUpperCase()}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-2 px-2">
          <div class="content p-2 item-active-config">
              <label>Client confirmation</label>
              <div class="row px-2" style="margin-top: 0px;">
                  <div class="col-md-12 px-2">
                    <input type="number" pattern="[0-9]" min="0" max="999999999" class="form-control" v-model.number="backtest.client_confirmation" disabled="true">
                  </div>
              </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label class="title_">
            Timeframe configuration
          </label>
        </div>
      </div>

      <div class="row px-2">
        <div class="col-md-2 mb-2 px-2">
          <div class="content p-2 item-active-config">
            <label>Short</label>
            <div class="row px-2" style="margin-top: 0px;">
              <div class="col-md-12 px-2">
                <input type="number" pattern="[0-9]" ref="short" min="0" max="999999999" class="form-control" v-model="backtest.timeframes.short" disabled="true">
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 mb-2 px-2">
          <div class="content p-2 item-active-config">
            <label>Medium</label>
            <div class="row px-2" style="margin-top: 0px;">
              <div class="col-md-12 px-2">
                <input type="number" pattern="[0-9]" ref="medium" min="0" max="999999999" class="form-control" v-model="backtest.timeframes.medium" disabled="true">
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 mb-2 px-2">
          <div class="content p-2 item-active-config">
            <label>Long</label>
            <div class="row px-2" style="margin-top: 0px;">
              <div class="col-md-12 px-2">
                <input type="number" pattern="[0-9]" ref="long" min="0" max="999999999" class="form-control" value="1440" v-model="backtest.timeframes.long" disabled="true">
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 mb-2 px-2">
          <div class="content p-2 item-active-config">
            <label>Mega Long</label>
            <div class="row px-2" style="margin-top: 0px;">
              <div class="col-md-12 px-2">
                <input type="number" pattern="[0-9]" ref="mega_long" min="0" max="999999999" class="form-control" value="1440" v-model="backtest.timeframes.mega_long" disabled="true">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <label class="text-muted">
            notes
          </label>
          <textarea class="form-control" v-model="$store.state.backtest_detail.note" disabled="true"></textarea>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <label class="text-muted">
            Date range
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <input type="text" disabled="true" v-model="dateRange" class="form-control" style="width: 300px !important;">
        </div>
      </div>
      <div class="row mt-2" v-if="$store.state.backtest_conditions.length > 0">
        <div class="col-md-12">
          <label class="text-muted">
            Conditions
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10">
          <input type="text" class="form-control d-block mb-2" v-model="filterConditionsValue" placeholder="Filter conditions..." id="filterConditions">
        </div>
      </div>
      <div v-for="(i, key) in $store.state.backtest_conditions" class="row" v-if="$store.state.backtest_conditions.length > 0" style="border-radius: 0px !important;">
        <div class="col-md-10 mb-2">
          <ViewJson :jsonData="i.condition | handleCondition" :filter="filterConditions" :key="key" />
        </div>
        <div class="col-md-2">
          <div class="d-flex" style="font-size: 22px;">
            <span v-b-tooltip.hover class="" title="View detail condition" @click="handleShowCondition(i.condition, key)">
              <b-icon-eye-fill></b-icon-eye-fill>
            </span>
            <span v-b-tooltip.hover class="ml-2" title="View detail condition" @click="copyCondition(i.condition.condition ? i.condition.condition : i.condition)">
              <b-icon-paperclip></b-icon-paperclip>
            </span>
          </div>
        </div>
      </div>
      <div class="row mt-2" v-if="$store.state.backtest_conditions.length > 0">
        <div class="col-md-12">
          <label class="text-muted">
            Resumen
          </label>
        </div>
      </div>
      <div class="row mb-2" v-if="$store.state.backtest_conditions.length > 0">
        <div class="col-md-10">
          <input type="text" class="form-control d-block mb-2" v-model="filterResumenBacktestValue" placeholder="Filter backtest resumen..." id="filterResumen">
        </div>
        <div class="col-md-10">
          <ViewJson :jsonData="backtest" :filter="filterResumen" key="backtestResumen" />
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-12">
          <label class="text-muted">
            Instance
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3 mb-2">
          <div class="content p-2 item-active-config">
            <label>Type Instance</label>
            <div class="row px-2" style="margin-top: 0px;">
              <div class="col-md-12 px-2">
                <select class="form-control" v-model="$store.state.backtest_detail.type_instance" disabled="true">
                  <option value="automatic">Automatic</option>
                  <option value="number">Select Number</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-2 px-2" v-if="$store.state.backtest_detail.type_instance === 'number'" disabled="true">
          <div class="content p-2 item-active-config">
              <label>Number instance</label>
              <div class="row px-2" style="margin-top: 0px;">
                  <div class="col-md-12 px-2">
                      <select class="form-control" v-model.number="$store.state.backtest_detail.number_instance">
                        <option v-for="n in $store.state.number_instances" :value="n+1" :key="n">{{n+1}}</option>
                      </select>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
  import ViewJson from '@/components/commons/ViewJson';
  import { EventBus } from '@/helpers/event-bus.js';

	export default {
		name: 'ModalShowBacktest',
    components: {
      ViewJson,
    },
    mounted() {
      this.addEventSeach();
      this.addEventSeachResumenBacktest();
    },
		created() {
			this.backtest = this.$store.state.backtest_detail;
      if (typeof this.backtest.dates == 'string') {
			 this.backtest.dates = JSON.parse(this.backtest.dates);
      } else {
       this.backtest.dates = this.backtest.dates;
      }
      if (this.backtest.dates.endDate) {
        this.dateRange = `${this.backtest.dates.startDate} - ${this.backtest.dates.endDate}`;
      } else {
        this.dateRange = `${this.backtest.dates[0]} - ${this.backtest.dates[1]}`;
      }

      if (this.backtest.timeframes) {
        if (typeof this.backtest.timeframes === 'string') {
  		    this.backtest.timeframes = JSON.parse(this.backtest.timeframes);
        } else {
          this.backtest.timeframes = this.backtest.timeframes;
        }
      }
		},
		data() {
			return {
				backtest: {},
				maximunCondition: 100,
        dateRange: '',
        filterConditions: '',
        filterResumen: '',
        filterConditionsValue: '',
        filterResumenBacktestValue: '',
			};
		},
    methods: {
      handleShowCondition(condition) {
        this.$emit('handleShowCondition', JSON.parse(condition.condition));
      },
      copyCondition(condition) {
        console.log(condition);
        if (typeof condition == "string") {
          condition = JSON.parse(condition)
          condition["backtest"] = this.backtest;
          condition = JSON.stringify(condition);
        } else {
          condition["backtest"] = this.backtest;
        }
        let conditions = [];
        conditions.push(condition);
        if (localStorage.getItem('copyCondition')) {
          let c = localStorage.getItem('copyCondition');
          c = JSON.parse(this.$crypto.decrypt(c));
          conditions = [...conditions, ...c];
        }
        this.$helpers.copyTextToClipboard(JSON.stringify(conditions), this);
        localStorage.setItem('copyCondition', this.$crypto.encrypt(JSON.stringify(conditions)));
        // this.$toastr.success("Condition copied with successful.");
        EventBus.$emit('handleCopyCondition', true)
      },
      addEventSeach() {
        const myInput = document.getElementById("filterConditions");
        if (!myInput) {
          setTimeout(() => {
            this.addEventSeach();
          }, 500);
        } else {
          myInput.addEventListener("keyup", this.$debounce(this.applyFilter));
        }
      },
      addEventSeachResumenBacktest() {
        const myInput = document.getElementById("filterResumen");
        if (!myInput) {
          setTimeout(() => {
            this.addEventSeachResumenBacktest();
          }, 500);
        } else {
          myInput.addEventListener("keyup", this.$debounce(this.applyFilterResumen));
        }
      },
      applyFilter() {
        console.log(this.filterConditionsValue, 'apply Filter ***');
        this.filterConditions = this.filterConditionsValue;
        // console.log('filter change', this.filterConditionsValue);
      },
      applyFilterResumen() {
        console.log(this.filterConditionsValue, 'apply Filter ***');
        this.filterResumen = this.filterResumenBacktestValue;
      },
    },
		filters: {
			handleCondition(c) {
        let cc = JSON.stringify({c: c.condition});
				let condition = JSON.stringify(c);
        condition = JSON.parse(condition);
        cc = JSON.parse(cc);
        condition.condition = JSON.parse(cc.c);
				return condition;
			},
		},
    watch: {
      '$store.state.token_backtest_detail'(value){
        this.backtest = this.$store.state.backtest_detail;
        this.backtest.dates = JSON.parse(this.backtest.dates);
        if (this.backtest.dates.endDate) {
          this.dateRange = `${this.backtest.dates.startDate} - ${this.backtest.dates.endDate}`;
        } else {
          this.dateRange = `${this.backtest.dates[0]} - ${this.backtest.dates[1]}`;
        }
        this.backtest.timeframes = JSON.parse(this.backtest.timeframes);
      },
    },
	}
</script>

<style>
  #modal-show-backtest .edit-conditions span:first-child {
    margin-top: 0;
  }
  #modal-show-backtest .edit-conditions span {
    font-size: 18px;
    display: flex;
    width: 20px;
    text-align: center;
    margin-top: 10px;
  }
</style>
