import openOrdersJsonService from "../../../services/open_orders_json.service";
export default ({ state, commit }, payload) => {
  commit("set", {
    _state: "open_orders_json",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });

  const { token_condition, token_order } = payload;
  const body = {
    token_investor: state.investor_token,
    token_condition,
    token_order,
  };
  openOrdersJsonService({
    body,
    callback: ({ response }) => {
      commit("set", {
        _state: "open_orders_json",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "open_orders_json",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
