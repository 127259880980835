export default {
  base_currency: "usdt",
  block_account: null,
  demo: 1,
  future: false,
  labeling: null,
  manual_order: 2,
  name: null,
  note: "",
  token: null,
  is_on: false,
  tags: null,
};
