import performanceAccountService from "../../../services/performance_account.service";
export default ({ commit, state }, { callback }) => {
  commit("set", {
    _state: "performance_account",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });
  const params = {
    investor_token: state.investor_token,
    portfolio_tokens: state.global_selected_algorithm,
    exchanges: state.global_selected_exchange,
    start_date: state.filter_chart ? state.filter_chart.start_date : null,
    end_date: state.filter_chart ? state.filter_chart.end_date : null,
  };
  performanceAccountService({
    params,
    callback: ({ response }) => {
      commit("set", {
        _state: "performance_account",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "performance_account",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
