<template>
  <div class="row detail menu mt-2 d-flex flex-row" style="padding-top: 10px">
    <span
      v-b-tooltip.hover
      class="mr-2"
      :title="loadingMigrate ? 'Migrating account' : 'Migrate account'"
      style="cursor: pointer"
    >
      <b-icon-share
        @click.once="migrateAccount()"
        v-if="!loadingMigrate"
      ></b-icon-share>
    </span>
    <div class="d-flex flex-row" style="margin-left: auto">
      <span
        class="small justify-content-end"
        style="margin-left: auto; margin-right: 6px; margin-top: 3px"
        v-if="loadingMigrate"
      >
        {{ loadingMessage }}
      </span>
      <b-icon
        icon="arrow-repeat"
        animation="spin-pulse"
        v-if="loadingMigrate"
      ></b-icon>
    </div>
  </div>
</template>

<script>
import CONSTANTS from "../../../helpers/constants";

export default {
  name: "MigrateAccount",
  props: ["investor"],
  data() {
    return {
      loadingMigrate: false,
      loadingMessage: "",
      disabled: false,
      investorForm: {
        name: null,
        token: null,
        balances: [],
        configurations: [],
        note: "",
        api_key: "",
        secret_key: "",
        demo: true,
        future: false,
        manual_order: false,
        signal_endpoint: false,
        send_notification: false,
        send_report: 0,
        email_frequency: 0,
        email_address_report: "",
        order_type: "market",
        exchange: "binance",
        order_open_close_tolerance: 5,
        block_account: 0,
        aggregate: 0,
        investment_maximum: 0,
        block_account_password: "",
        success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
          <g clip-path="url(#clip0_1634_940)">
          <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
          </g>
          <defs>
          <clipPath id="clip0_1634_940">
          <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
          </clipPath>
          </defs>
          </svg> Success`,
      },
    };
  },
  methods: {
    gettingPorcentage(currentPage, totalPages) {
      const percentage = (currentPage / totalPages) * 100;
      return percentage.toFixed(2);
    },
    async migrateAccount() {
      this.loadingMigrate = true;
      if (this.disabled) return;
      this.$toastr.success(
        "If you want a successfull migration",
        `${this.success}`
      );
      this.$toastr.success(
        "Please wait that the migration is done before making another action",
        `${this.success}`
      );
      await this.addAccount();
      await this.getSubInvestorsaAndContributors();
      this.loadingMigrate = false;

      setTimeout(() => {
        this.disabled = false;
      }, 500);
    },
    async getSubInvestorsaAndContributors() {
      this.$toastr.success(
        "Starting to migrate subinvestors and contributors...",
        `${this.success}`
      );
      this.$toastr.success(
        "If they have orders, it will migrate them too",
        `${this.success}`
      );
      const request = this.$http;
      request.set_url_by_filter(CONSTANTS.production_api, true);
      const data = { investor_token: this.investor.info.investor.token_user };
      const contributor = await request.server1(
        "bwfs/admin/contributors/by/user",
        data
      );
      const subInvestor = await request.server1(
        "bwfs/admin/subinvestors/by/user",
        data
      );
      const migrateRequest = this.$http;
      migrateRequest.set_url_by_filter(CONSTANTS.develop_api, true);
      for (let i = 0; i < subInvestor.data.length; i++) {
        this.$toastr.success(
          "Starting to migrate subinvestors...",
          `${this.success}`
        );
        this.loadingMessage = "Starting to migrate subinvestors...";
        const subInvestorData = {
          name: subInvestor.data[i].subinvestor.name,
          token: subInvestor.data[i].subinvestor.token_user,
          note: subInvestor.data[i].subinvestor.note,
          balances: JSON.stringify(subInvestor.data[i].balances),
          demo: 1,
          future: 0,
          parent_token_user: subInvestor.data[i].subinvestor.parent_token_user,
          configurations: subInvestor.data[i].subinvestor.configurations,
          condition:
            subInvestor.data[i].conditions.length > 0
              ? subInvestor.data[i].conditions[0].condition
              : "{}",
          api_key: "",
          secret_key: "",
          created_at: subInvestor.data[i].subinvestor.created_at,
          updated_at: subInvestor.data[i].subinvestor.updated_at,
        };
        await migrateRequest.server1(
          "bwfs/admin/subinvestors/migrate",
          subInvestorData
        );
        await this.migrateOrders(subInvestor.data[i].subinvestor.token_user, 1);
      }
      for (let j = 0; j < contributor.data.length; j++) {
        this.$toastr.success(
          "Starting to migrate contributors...",
          `${this.success}`
        );
        this.loadingMessage = "Starting to migrate subinvestors...";
        const contributorData = {
          name: contributor.data[j].contributor.name,
          token: contributor.data[j].contributor.token_user,
          note: contributor.data[j].contributor.note,
          balances: JSON.stringify(contributor.data[j].balances),
          demo: 1,
          future: 0,
          parent_token_user: contributor.data[j].contributor.parent_token_user,
          configurations: "{}",
          created_at: contributor.data[j].contributor.created_at,
          updated_at: contributor.data[j].contributor.updated_at,
        };
        await migrateRequest.server1(
          "bwfs/admin/contributors/migrate",
          contributorData
        );
        await this.migrateOrders(contributor.data[j].contributor.token_user, 2);
      }
    },
    async addAccount() {
      /* Add investor account */

      this.$toastr.success(
        "Investor account migration starting...",
        `${this.success}`
      );
      this.loadingMessage = "Investor account migration starting...";
      const data = { ...this.investor.info.investor };
      data.token = data.token_user;
      data.demo = 1;
      data.future = 0;
      data.api_key = "";
      data.secret_key = "";
      data.send_report = data.email_report;
      data.email_frequency = data.email_report_frequency;
      if (this.investor.conditions.length === 0) {
        data.conditions = JSON.stringify([]);
      } else {
        const conditions = [];
        for (let i = 0; i < this.investor.conditions.length; i++) {
          const condition = this.investor.conditions[i];
          conditions.push({
            token: condition.token,
            token_user: data.token_user,
            condition: JSON.stringify(condition.condition),
            created_at: condition.created_at,
            updated_at: condition.updated_at,
          });
        }

        data.conditions = JSON.stringify(conditions);
      }
      if (this.investor.balances) {
        data.balances = JSON.stringify(this.investor.balances);
      }
      const sending = this.$http;
      sending.set_url_by_filter(CONSTANTS.develop_api, true);
      await sending.server1("bwfs/admin/investor/migrate_investor", data);
      this.disabled = true;
      this.$toastr.success(
        "Investor account is migrated...",
        `${this.success}`
      );
      await this.migrateOrders(
        this.investor.info.investor.token_user,
        0,
        this.investor.info.investor.is_manual
      );
    },
    async migrateOrders(token, accountType = 0, isManual = 0) {
      /*
        accountType = 0 (Investor)
        accountType = 1 (Subinvestor)
        accountType = 2 (Contributor)
      */
      this.$toastr.success("Starting to migrate orders...", `${this.success}`);
      const request = this.$http;
      let type = "Investor";
      if (accountType === 1) type = "Subinvestor";
      if (accountType === 2) type = "Contributor";
      let url = "bwfs/admin/investors/orders";
      if (accountType === 1) {
        url = "bwfs/admin/subinvestors/orders";
      }
      if (accountType === 2) {
        url = "bwfs/admin/contributors/orders";
      }
      const url1 = "bwfs/admin/investor/get_orders";
      request.set_url_by_filter(this.$store.state.investor_api_url, true);
      const data = {
        search: "",
        investor_token: token,
        condition_token: "",
        limit: 100,
        page: 1,
        is_compound: 0,
        is_manual: isManual,
        status: JSON.stringify([2, 1, 8, 4]),
        comissions: 0,
        conditions: "",
        sort_by: "close_date",
        order: "DESC",
        backtest_token: "",
        token: accountType === 1 || accountType === 2 ? token : "",
      };
      //
      const response = await request.server1(url, data);
      const paginate = response.paginate;
      const orders = { orders: JSON.stringify(response.data) };
      const sending = this.$http;
      sending.set_url_by_filter(CONSTANTS.develop_api, true);
      await sending.server1("bwfs/admin/investor/migrate", orders);
      this.$toastr.success(
        `Getting orders of the account for migration... ${this.gettingPorcentage(
          paginate.current_page,
          paginate.total_pages
        )}%`,
        `${this.success}`
      );
      this.loadingMessage = `${type} migration: ${this.gettingPorcentage(
        paginate.current_page,
        paginate.total_pages
      )}%`;
      for (let i = 0; i < paginate.total_pages - 1; i++) {
        data.page = data.page + 1;
        const request = this.$http;
        request.set_url_by_filter(this.$store.state.investor_api_url, true);
        const res = await request.server1(url, data);
        const paginate = res.paginate;
        if (res.data && !res.error) {
          const sending = this.$http;
          sending.set_url_by_filter(CONSTANTS.develop_api, true);
          await sending.server1("bwfs/admin/investor/migrate", {
            orders: JSON.stringify(res.data),
          });
        }
        this.$toastr.success(
          `Getting orders of the account for migration... ${this.gettingPorcentage(
            paginate.current_page,
            paginate.total_pages
          )}%`,
          `${this.success}`
        );
        this.loadingMessage = `${type} migration: ${this.gettingPorcentage(
          paginate.current_page,
          paginate.total_pages
        )}%`;
      }
    },
  },
};
</script>
