import NotificationConfigListService from "../../../services/notification_configuration_list.service";

export default ({ commit, state }, { page }) => {
  commit("set", {
    _state: "notification_configuration_list",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });

  const { search } = state.form_notification_configuration;

  const params = {
    limit: 5,
    page,
    search,
    algorithm_instance_token: state.algorithm_token,
  };

  NotificationConfigListService({
    params,
    callback: ({ response }) => {
      commit("set", {
        _state: "notification_configuration_list",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "notification_configuration_list",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
