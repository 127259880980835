import random_number from "../../../helpers/generators/random_number";
import random_date from "../../../helpers/generators/random_date";

export default () => {
  const names = [
    "Jonathan Cuotto",
    "Pedro Carvajal",
    "Alonso Perez",
    "Carmen Betancourt",
    "María Ramirez",
    "Jesus Martínez",
    "Yalçin Fawn",
    "Joaquina Ciriaco",
    "Nikhil Calixta",
    "Reed Agostina",
    "Bonaccorso Maybelline",
    "Tristen Rifat",
    "Beau Sherrie",
    "James Thorley",
    "Ala al-Din 'Abbas",
    "Edmondo Piety",
    "Ayokunle Qismat",
    "Ethan Beniamino",
    "Anselm Mani",
    "Shubham Amardeep",
    "Leoluca Abhijeet",
    "Grayson Eli",
    "Alaina Uduakobong",
    "Lino Tochukwu",
    "Meena Briar",
    "Prue Danila",
    "Berko Gianpaolo",
  ];
  const investor_name = names[random_number(0, names.length - 1)];
  const current_nav = random_number(4000, 100000);
  const initial_deposit = random_number(4000, 100000);
  const total_deposits = random_number(4000, 100000);
  const total_withdawal = random_number(4000, 100000);
  const performance_to_date = random_number(4000, 100000);
  const performance_percentage = random_number(0, 100);
  const created_at = random_date("MM/DD/YYYY, h:mm:ss");

  return {
    investor_name,
    current_nav,
    initial_deposit,
    total_deposits,
    total_withdawal,
    performance_to_date,
    performance_percentage,
    created_at,
  };
};
