import TransactionListService from "../../../services/transaction_list.service";

export default ({ commit, state }, { page }) => {
  commit("set", {
    _state: "transaction_list_preview",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });

  const params = {
    investor_token: state.investor_token,
    limit: 2,
  };

  TransactionListService({
    params,
    callback: ({ response }) => {
      commit("set", {
        _state: "transaction_list_preview",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "transaction_list_preview",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
