<template>
	<b-modal :id="`${unique}_orders_blended_accum_${$props.order_token}`" :title="`Blended orders`" size="xl">

		<div class="separator d-flex flex-row">LOGS</div>
	    <b-table
	        :items="orders_blended"
	        :fields="fields_orders_blended"
	        :per-page="100"
	        responsive
	        sticky-header
	        show-empty
	    >
	        <template  #cell(type)="data">
	          <div
	          	class="d-flex flex-row"
	          	:class="[
	          		'description mono',
	          		data.item.type ? 'text-success' : 'text-danger',
	          		data.index == 0 ? 'is_parent' : ''
	          	]"
	          >
	            {{data.item.type ? 'BUY' : 'SELL'}} 
	          </div>
	        </template>

	        <template  #cell(open_price)="data">
	          <div class="d-flex flex-row">
	            {{$helpers.numberFormat(data.item.open_price.toFixed(2))}}
	          </div>
	        </template>

	        <template  #cell(open_lot)="data">
	          <div class="d-flex flex-row">
	            {{data.item.open_lot.toFixed(4)}}
	          </div>
	        </template>

	        <template  #cell(blended_var)="data">
	          <div class="d-flex flex-row">
	            {{data.item.blended_var ? data.item.blended_var.toFixed(4) : data.item.blended_var}}
	          </div>
	        </template>


	        <template  #cell(open_price_x_open_lot)="data">
	          <div class="d-flex flex-row">
	            {{data.item.open_price_x_open_lot ? $helpers.numberFormat(data.item.open_price_x_open_lot.toFixed(3)) : '--'}}
	          </div>
	        </template>


	        <template  #cell(open_lot_accum)="data">
	          <div class="d-flex flex-row">
	            {{$helpers.numberFormat(data.item.open_lot_accum.toFixed(3))}}
	          </div>
	        </template>


	        <template  #cell(open_price_x_open_lot_acum)="data">
	          <div class="d-flex flex-row">
	            {{$helpers.numberFormat(data.item.open_price_x_open_lot_acum.toFixed(3))}}
	          </div>
	        </template>

	        <template  #cell(open_price_equivalent)="data">
	          <div class="d-flex flex-row">
	            {{$helpers.numberFormat(data.item.open_price_equivalent.toFixed(3))}}
	          </div>
	        </template>

			<template  #cell(option)="data">
	          <div class="d-flex flex-row">
	          	<JsonOrderChildBlended :logs="data.item.json" :order_token="data.order_id"/>
	          </div>
	        </template>	        
	      </b-table>
		
		<div class="separator d-flex flex-row">PARTIAL ORDERS</div>
	    
	    <b-table
	        :items="orders_blended_profits"
	        :fields="partial_orders_fields"
	        :per-page="100"
	        responsive
	        sticky-header
	        show-empty
	      >

	      	<template  #cell(open_price_equivalent)="data">
	          <div class="d-flex flex-row">
	            {{$helpers.numberFormat(data.item.open_price_equivalent.toFixed(2))}}
	          </div>
	        </template>

	        <template  #cell(open_lot_equivalent)="data">
	          <div class="d-flex flex-row">
	            {{data.item.open_lot_equivalent.toFixed(4)}}
	          </div>
	        </template>

	        <template  #cell(close_price_equivalent)="data">
	          <div class="d-flex flex-row">
	            {{$helpers.numberFormat(data.item.close_price_equivalent.toFixed(2))}}
	          </div>
	        </template>

	        <template  #cell(close_lot_equivalent)="data">
	          <div class="d-flex flex-row">
	            {{data.item.close_lot_equivalent.toFixed(4)}}
	          </div>
	        </template>


	        <template  #cell(close_profit_perc)="data">
	          <div
	          	class="d-flex flex-row"
	          	:class="[data.item.close_profit_perc > 0 ? 'text-success': 'text-danger' ]"
	          >
	            {{data.item.close_profit_perc.toFixed(2)}}%
	          </div>
	        </template>
	        <template  #cell(close_profit_coin)="data">
	          <div
	          	class="d-flex flex-row"
	          	:class="[data.item.close_profit_coin > 0 ? 'text-success': 'text-danger' ]"
	          >
	            {{$helpers.numberFormat(data.item.close_profit_coin.toFixed(2))}}
	          </div>
	        </template>
	    </b-table>

	    <template #modal-footer="{ ok, cancel, hide }">
	        <b-button size="sm" variant="success" @click="hideModal">
	          Ok
	        </b-button>
	    </template>
    </b-modal>
</template>
<script>
  	import JsonOrderChildBlended from '@/components/commons/JsonOrderChildBlended';

	export default {
		name: "TableBlendedOrderAccum",
		components: {
			JsonOrderChildBlended
		},
		props: {
			order_token: {
				type: String,
				required: true,
			},
			orders_blended: {
				type: Array,
				required: true
			},
			orders_blended_profits: {
				type: Array,
				required: true
			},
		},
		created() {
			this.unique = new Date().getTime()
		},
		data() {
			return {
		        fields_orders_blended: [
		        	{
		        		"key": "type",
		        		"label": "Type"
		        	},
		        	{
		        		"key": "open_price",
		        		"label": "Open Price"
		        	},
		        	{
		        		"key": "open_lot", 
		        		"label": "Open Lot",
		        	},
		        	{
		        		"key": "blended_var", 
		        		"label": "Blended var",
		        	},
		        	{
		        		"key": "open_price_x_open_lot", 
		        		"label": "Open Price X Open Lot",
		        	},
		        	{
		        		"key": "open_lot_accum",
		        		"label": "Open Lot Accum"
		        	},
		        	{
		        		"key": "open_price_x_open_lot_acum",
		        		"label": "Open Price X Open Lot Acum"
		        	},
		        	{
		        		"key":"open_price_equivalent",
		        		"label": "Open Price Equiv.",
		        	},
		        	{
		        		"key":"option",
		        		"label": "",
		        	}
		        ],
		        partial_orders_fields: [
		        	{
		        		"key": "open_price_equivalent",
		        		"label": "Open Price Equiv."
		        	},
		        	{
		        		"key": "open_lot_equivalent", 
		        		"label": "Open Lot Equiv.",
		        	},
		        	{
		        		"key": "close_price_equivalent",
		        		"label": "Close Price equiv."
		        	},
		        	{
		        		"key":"close_lot_equivalent",
		        		"label": "Close Lot Equiv.",
		        	},
		        	{
		        		"key":"close_profit_perc",
		        		"label": "Profit Perc.",
		        	},
		        	{
		        		"key":"close_profit_coin",
		        		"label": "Profit Coin",
		        	},
		        ],
		        partial_orders: [],
		        unique: 0
			}
		},
		methods: {
			hideModal() {
				this.$bvModal.hide(`${this.unique}_orders_blended_accum_${this.$props.order_token}`)
			},
			showModal(){
				this.$bvModal.show(`${this.unique}_orders_blended_accum_${this.$props.order_token}`)
			}
		}
	}
</script>

<style type="text/css">
	.is_parent {
		text-decoration: underline;
	}
</style>