<template>
  <div>
    <div class="form-group d-flex aling-item-center justify-content-end mb-2">
      <input
        type="text"
        class="form-control float-right d-inline-block"
        id="searchOpenOrder"
        placeholder="Search by symbol or token..."
        v-model="search_open"
        v-show="!$store.state.orders_opened_loading"
      />
    </div>

    <CardOpenedOrder
      v-for="(order, i) in $store.state.orders_opened"
      v-if="!$store.state.orders_opened_loading"
      :key="i"
      :order="order"
      :setAnimate="setAnimate"
      :inClosingLoading="inClosingLoading"
      @handleCloseInmediateOrder="handleCloseInmediateOrder"
      @handleShowOrders="showOrders"
    />
    <div class="row">
      <div class="col-md-12">
        <b-pagination
          :total-rows="$store.state.open_orders_paginate.records"
          :limit="4"
          :per-page="$store.state.open_orders_paginate.per_page"
          v-model="$store.state.open_orders_paginate.current_page"
          class="mt-2"
          @page-click="handleNextPage"
          v-if="
            $store.state.open_orders_paginate.total_pages > 0 &&
            !$store.state.orders_opened_loading
          "
        ></b-pagination>
      </div>
    </div>

    <div class="text-center my-2" v-if="$store.state.orders_opened_loading">
      <b-skeleton-table
        :rows="5"
        :columns="1"
        :table-props="{ striped: true }"
      ></b-skeleton-table>
    </div>

    <div
      v-if="
        $store.state.orders_opened.length === 0 &&
        !$store.state.orders_opened_loading
      "
    >
      <b-alert class="mb-2" show variant="dark">
        <span>There are no open orders.</span>
      </b-alert>
    </div>

    <b-modal
      ref="modal-show-json"
      id="modal-show-json-opened"
      hide-footer
      hide-header
      size="xl"
      ok-only
    >
      <div class="modal-create-conditions">
        <h3 class="modal-title d-flex justify-content-between">
          <span v-if="!showLog"> Show order </span>
          <span v-else>Show logs</span>
        </h3>
        <input
          type="text"
          class="form-control d-block mb-2"
          v-model="filter_values_json"
          placeholder="Filter logs..."
          id="filterValuesJson"
        />
        <div class="row">
          <div class="col-md-11 pr-0">
            <ViewJson :jsonData="json_order" :filter="filter" />
          </div>

          <div class="col-md-1">
            <span
              class="pointer"
              style="font-size: 22px"
              v-b-tooltip.hover
              title="Copy one or more conditions"
              @click.prevent="
                $helpers.copyValues(JSON.stringify(json_order), _vue, null)
              "
            >
              <b-icon-paperclip></b-icon-paperclip>
            </span>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import handleSocket from "@/helpers/socket";
import ViewJson from "@/components/commons/ViewJson";
import CardOpenedOrder from "@/components/pages/investors/CardOpenedOrder";
import moment from "moment";
export default {
  name: "CardOpenedOrdersContainer",
  components: {
    ViewJson,
    CardOpenedOrder,
  },
  props: {},
  created() {
    this._vue = this;
    let routeName = this.$route.name.toLowerCase();
    if (
      (routeName === "investor" ||
        routeName === "subinvestor" ||
        routeName === "contributor") &&
      this.$route.params.token
    ) {
      this.investor_token = this.$route.params.token;
      this.getOpened(1);
    }
  },
  mounted() {
    this.addEventSeach();
    this.addEventSeachOpen();
  },
  data() {
    return {
      investor_token: "",
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
      <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
      </svg> Error`,
      filter_values_json: "",
      filter: "",
      baseSymbol: "btcusdt",
      json_order: {},
      showLog: false,
      isSpot: true,
      inClosingLoading: false,
      url_socket: "investors_open_orders",
      idsInterval: {},
      play: true,
      setAnimate: false,
      currentPage: 1,
      socketConnected: false,
      filter_search_open: "",
      search_open: "",
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
          <g clip-path="url(#clip0_1634_940)">
          <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
          </g>
          <defs>
          <clipPath id="clip0_1634_940">
          <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
          </clipPath>
          </defs>
          </svg> Success`,
    };
  },
  methods: {
    resetSocket() {
      let keys = Object.keys(this.idsInterval);
      for (let i = 0; i < keys.length; i++) {
        clearInterval(this.idsInterval[keys[i]]);
        delete this.idsInterval[keys[i]];
      }
      this.socketConnected = false;
      this.$helpers.resetFavIcon();
      this.websocket.close();
    },
    addEventSeach() {
      const myInput = document.getElementById("filterValuesJson");
      const showOpenInput = document.getElementById("searchOpenOrder");
      if (!myInput || !showOpenInput) {
        setTimeout(() => {
          this.addEventSeach();
        }, 500);
      } else {
        myInput.addEventListener(
          "keyup",
          this.$debounce(() => {
            this.filter = this.filter_values_json;
          })
        );
        showOpenInput.addEventListener(
          "keyup",
          this.$debounce(() => this.getOpened)
        );
      }
    },
    addEventSeachOpen() {
      const showOpenInput = document.getElementById("searchOpenOrder");
      if (!showOpenInput) {
        setTimeout(() => {
          this.addEventSeachOpen();
        }, 500);
      } else {
        showOpenInput.addEventListener("keyup", this.$debounce(this.getOpened));
      }
    },
    conneting() {
      this.socketConnected = true;
      this.websocket = handleSocket(
        this.url_socket,
        this.onOpen,
        this.onClose,
        this.onMessage,
        this.onError
      );
    },
    handleNextPage(event, pageNum) {
      this.getOpened(pageNum);
      if (this.$store.state.orders_opened_loading) {
        return;
      }
      this.currentPage = pageNum;
      if (this.socketConnected) {
        this.resetSocket();
      }
    },
    getOpened(page = 1) {
      let condition_token = null;

      const payload = {
        vm: this,
        data: {
          investor_token: this.investor_token,
          search: this.search_open,
          page,
        },
      };

      if (this.$store.state.algorithm_token_selected) {
        condition_token = this.$store.state.algorithm_token_selected;
        payload.data.condition_token = condition_token;
      }

      if (this.socketConnected) {
        this.resetSocket();
      }

      if (this.$route.name.toLowerCase() == "subinvestor") {
        payload.data.token = payload.data.investor_token;
        payload.is_subinvestor = true;
        delete payload.data.investor_token;
      }

      if (this.$route.name.toLowerCase() == "contributor") {
        payload.data.token = payload.data.investor_token;
        payload.is_contributor = true;
        delete payload.data.investor_token;
      }

      this.$store.commit("get_orders_opened", payload);
      if (!this.socketConnected) {
        let interval = setInterval(() => {
          if (!this.$store.state.orders_opened_loading) {
            clearInterval(interval);
            this.conneting();
          }
        }, 250);
      }
    },
    getOpenedRefresh(tokens) {
      const payload = {
        vm: this,
        data: {
          investor_token: this.investor_token,
          page: this.currentPage,
        },
      };
      let in_tokens = this.$store.state.orders_opened.map((o) => o.token);
      if (tokens.length != in_tokens.length) {
        this.resetSocket();
        this.getOpened(1);
        this.$emit("refresh_order");
        this.$store.commit("get_orders_opened_refresh", payload);
      } else {
        let isDiff = false;
        if (tokens.length == this.$store.state.orders_opened.length) {
          for (let i = 0; i < this.$store.state.orders_opened.length; i++) {
            if (
              tokens.indexOf(this.$store.state.orders_opened[i].token) === -1
            ) {
              isDiff = true;
            }
          }
        }
        if (isDiff) {
          this.getOpened(1);
          this.resetSocket();
          this.$emit("refresh_order");
          this.$store.commit("get_orders_opened_refresh", payload);
        }
      }
    },
    showOrders(token, condition_token, showLog = false) {
      this.showLog = showLog;
      let routeName = this.$route.name.toLowerCase();
      const data = {
        token_backtest: "",
        token_condition: condition_token,
        token_order: token,
        token_investor:
          routeName === "investor"
            ? this.$store.state.info_investor.info.token
            : "",
      };

      if (routeName === "subinvestor") {
        data.token_investor =
          this.$store.state.SubInvestorInfo.info.parent_token_user;
      } else if (routeName === "contributor") {
        data.token_investor =
          this.$store.state.contributorInfo.info.parent_token_user;
      }

      this.$http
        .server1("bwfs/admin/backtest/order/json", data)
        .then((res) => {
          if (res.error) {
            this.json_order = {};
            this.$toastr.error(res.message, `${this.error}`);
            return;
          }
          let json = res.data;
          if (!json) {
            this.$toastr.error("No results found.", `${this.error}`);
            return;
          }
          if (showLog) {
            json = json.log;
          } else {
            delete json.log;
          }
          this.json_order = json;
          this.$bvModal.show("modal-show-json-opened");
        })
        .catch((e) => {
          this.json_order = {};
          this.$toastr.error(e.message, `${this.error}`);
        });
    },
    handleCloseInmediateOrder(order) {
      if (!confirm("Are you sure to do this?")) {
        return;
      }
      if (this.inClosingLoading) {
        return;
      }
      const data = {
        investor_token: order.investor_token,
        condition_token: order.condition_token,
        order_token: order.token,
      };
      this.inClosingLoading = true;
      this.$http
        .server1("bwfs/admin/investor/inmediate/closing", data)
        .then((res) => {
          this.inClosingLoading = false;
          if (res.error) {
            this.$toastr.error(res.message, `${this.error}`);
            return;
          }
          this.getOpened(this.currentPage);
          this.$toastr.success(res.message, `${this.success}`);
        })
        .catch((e) => {
          this.inClosingLoading = false;
          this.$toastr.error(e.message, `${this.error}`);
        });
    },
    sendConnect() {
      let configuration = JSON.parse(
        this.$store.state.info_investor.info.investor.configurations
      );
      let is_spot = 0;
      if (configuration.market_type.toLowerCase() == "spot") {
        is_spot = 1;
      }

      let tokens = this.$store.state.orders_opened.map((o) => o.token);
      if (tokens.length == 0) {
        setTimeout(this.sendConnect, 500);
        return;
      }

      this.doSend(this.$store.state.info_investor.info.token);
      this.doSend(JSON.stringify(tokens));
      this.socketConnected = true;
    },
    onOpen() {
      if (!this.$store.state.info_investor.info) {
        let interval = setInterval(() => {
          if (
            this.$store.state.info_investor.info &&
            this.$store.state.open_orders_paginate.records > 0
          ) {
            let status = this.$store.state.info_investor.info.investor.status;
            this.market_type();
            clearInterval(interval);
            if (status == 1 || status == 2) {
              this.writeToScreen("CONNECTED");
              this.sendConnect();
            }
          }
        }, 250);
      } else {
        let status = this.$store.state.info_investor.info.investor.status;
        this.market_type();
        if (status == 1 || status == 2) {
          this.writeToScreen("CONNECTED");
          this.sendConnect();
        }
      }
    },
    onClose(evt) {
      this.writeToScreen("DISCONNECTED");
      /*setTimeout(() => {
		      	if (this.$route.name.toLowerCase() === 'investor') {
		      		this.conneting();
		      	}
		      }, 1500);*/
    },
    onMessage(evt) {
      if (evt.data) {
        let orders = JSON.parse(evt.data);
        let in_tokens = this.$store.state.orders_opened.map((o) => o.token);
        this.setAnimate = true;
        let tokens = [];

        for (let i = 0; i < orders.length; i++) {
          if (in_tokens.indexOf(orders[i].token) > -1) {
            tokens.push(orders[i].token);
          }
        }

        if (orders.length != tokens.length) {
          this.getOpenedRefresh(tokens);
        }

        for (let i = 0; i < orders.length; i++) {
          if (this.idsInterval[orders[i].token]) {
            clearInterval(this.idsInterval[orders[i].token]);
          }
          let date = new Date(
            moment.utc().format().replace("T", " ").replace("Z", "")
          );
          this.idsInterval[orders[i].token] = setInterval(
            this.handleUpdateLastUpdated,
            1000,
            orders[i],
            date
          );
        }
        setTimeout(() => {
          this.setAnimate = false;
        }, 1200);
      } else {
        let keys = Object.keys(this.idsInterval);
        for (let i = 0; i < keys.length; i++) {
          clearInterval(this.idsInterval[keys[i]]);
          delete this.idsInterval[keys[i]];
        }
      }
    },
    handleUpdateLastUpdated(data, start) {
      let now = moment(
        new Date(moment.utc().format().replace("T", " ").replace("Z", ""))
      );
      let duration = moment.duration(now.diff(start));
      let sec = duration.asSeconds();
      data.last_update_time = parseInt(sec) > 0 ? parseInt(sec) : 1;
      this.$store.commit("update_order_on_open", data);
      // this.loading_time(sec);
    },
    onError(evt) {
      console.error(evt);
    },
    doSend(message) {
      this.websocket.send(message);
    },
    writeToScreen(message) {
      console.log("writeToScreen", message);
    },
    loading_time(sec) {
      if (!window.has_focus) {
        if (
          sec / 60 >= 5 &&
          this.$store.state.user.role_id === 1 &&
          this.$route.name.toLowerCase() == "investor"
        ) {
          if (this.play) {
            document.getElementById("alarm").play();
            this.$helpers.setIconDanger();
            this.play = false;
            setTimeout(() => {
              console.log("play on opened");
              this.play = true;
            }, 1000 * 60 * 5);
          }
        }
      }
    },
    market_type() {
      if (
        this.$store.state.info_investor.info.investor.configurations == "{}" ||
        this.$store.state.info_investor.info.investor.configurations == ""
      ) {
        this.isSpot = false;
      } else {
        if (
          typeof this.$store.state.info_investor.info.investor.configurations ==
          "string"
        ) {
          let configuration = JSON.parse(
            this.$store.state.info_investor.info.investor.configurations
          );
          this.isSpot = configuration.market_type == "spot";
        } else {
          this.isSpot = false;
        }
      }
    },
  },
  watch: {
    "$store.state.algorithm_token_selected"(value) {
      this.getOpened();
    },
  },
  beforeDestroy() {
    this.resetSocket();
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.animate__pulse_text {
  -webkit-animation: pulse_text 4s infinite alternate;
}

@-webkit-keyframes pulse_text {
  0% {
    color: #ffb802;
  }
  25% {
    color: #fff;
  }
  50% {
    color: #ffb802;
  }
  75% {
    color: #fff;
  }
  100% {
    color: #ffb802;
  }
}
</style>
