<template>
  <div class="processing-loader">
    <img :src="spinner_icon" />
    Processing...
  </div>
</template>

<script>
import SpinnerIcon from "../../../assets/images/spinner.svg";
export default {
  name: "ProcessingLoader",
  data() {
    return {
      spinner_icon: SpinnerIcon,
    };
  },
};
</script>

<style lang="scss">
.processing-loader {
  border-radius: 4px;
  background: #1d1e25;
  color: #f5f5f5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  img {
    animation: rotation 2s infinite linear;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
</style>
