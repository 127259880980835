<template>
  <b-col cols="12 p-0 mt-2">
  	<b-card header-tag="header">
	    <template #header>
        <h6 class="mb-0">Orders</h6>
      </template>
	    <div class="table-responsive">
	      <b-table 
	        id="table"
	        :items="$store.state.backtest_orders" 
	        :fields="fields" 
	        striped
	        hover
	        small
	        :busy="$store.state.backtest_orders_loading"
	        :sort-by.sync="sortBy"
	        :sort-desc.sync="sortDesc"
	      >
	      	<template #cell(token)="row">
            {{ row.item.token.substr(0,13)+"..." }}
          </template>
          <template #cell(backtest_token)="row">
            {{ row.item.backtest_token.substr(0,13)+"..." }}
          </template>
          <template #cell(condition_token)="row">
            {{ row.item.condition_token.substr(0,13)+"..." }}
          </template>
          <template #cell(open_date)="row">
            {{ (new Date(row.item.open_date)).toGMTString() }}
          </template>
          <template #cell(status)="row">
            <div class="d-block">
              <span class="badget badget-info" v-if="row.item.status === 1">
                Open
              </span>
              <span class="badget badget-success" v-if="row.item.status === 2">
              	Close
              </span>
              <span class="badget badget-danger" v-if="row.item.status === 3">
                Cancelled
              </span>
            </div>
	        </template>
	        <template #table-busy>
	          <div class="text-center my-2">
	          	<b-spinner type="grow" label="Spinning"></b-spinner>
	          </div>
	        </template>
	      </b-table>
	      <b-pagination
	        id="table" 
	        v-model="currentPage"
	        :total-rows="20"
	        :limit="perPage"
	        :per-page="perPage"
	        class="mt-2"
	        @page-click="linkGen"
	        v-if="paginate.total_pages > 0"
	      ></b-pagination>
	    </div>
  	</b-card>
  </b-col>
</template>
<script>
	export default {
		name: 'Orders',
		props: {
			backtest_token: {
				required: true,
	      type: String,
			},
			condition_token: {
				required: true,
	      type: String,
			},
		},
		created() {
			this.getOrders(1);
		},
		data(){
			return {
				sortBy: 'created_at',
        sortDesc: false,
        search: '',
        fields: [
          {key: 'token', sortable: true}, 
          {key: 'backtest_token', sortable: true}, 
          {key: 'condition_token', sortable: true}, 
          {key: 'open_date', sortable: true}, 
          {key: 'open_lot', sortable: true}, 
          {key: 'open_price', sortable: true}, 
          {key: 'signal_price', sortable: true}, 
          {key: 'status', sortable: true}, 
        ],
        items: [],
        perPage: 20,
        currentPage: 1,
        isLoading: false,
        paginate: {
          current_page: 1,
          per_page: 20,
          total_pages: 0,
          records: 0,
        },
			};
		},
		methods: {
			getOrders(page=1) {
				const data = {
					vm: this,
          page: page,
          limit: this.perPage,
          sort_by: this.sortBy,
          order: this.sortDesc ? 'DESC' : 'ASC',
          backtest_token: this.$props.backtest_token,
          condition_token: this.$props.condition_token,
        };
        if (this.search) {
          data['search'] = this.search;
        }
        this.$store.commit('bwfs_get_condtion_orders_backtest', data);
			},
		},
		watch: {
			condition_token(value) {
				if (value && this.$props.backtest_token) {
					this.getOrders();
				}
			},
			backtest_token(value) {
				if (value && this.$props.condition_token) {
					this.getOrders();
				}
			},
		},

	}
</script>