<template>
  <b-col cols="12" lg="2" class="p-0 admin_sidebar nice-scroll">
    <div class="d-block p-2 mb-2">
      <div class="separator">LMDD Accounts</div>      
        <div class="card p-2 mb-2" v-if="!loading">
          <b-form-group label="Search">
            <b-input-group size="sm">
              <b-form-input
                id="searchAccount"
                type="search"
                placeholder="Type to Search"
                @change="searchLMDDAccount"
                v-model="search"
              ></b-form-input>
            </b-input-group>
                <span class="text-muted small mt-1">
                  Search by token, or name, or asset 
                </span>
          </b-form-group>
        </div>
        <div class="card mb-2" v-if="loading">
          <b-card>
            <b-skeleton animation="wave" width="75%"></b-skeleton>
            <b-skeleton animation="wave" width="55%"></b-skeleton>
          </b-card>
        </div>
        <div class="card p-2 mb-2" v-if="!loading">
          <b-form-group label="User Types">
            <select v-model="userType" @change="searchLMDDAccount" class="form-control">
              <option :value="10">All</option>
              <option :value="1">Real</option>
              <option :value="0">Demo</option>
            </select>
          </b-form-group>
        </div>
        <div class="card mb-2" v-if="loading">
          <b-card>
            <b-skeleton animation="wave" width="75%"></b-skeleton>
            <b-skeleton animation="wave" width="55%"></b-skeleton>
          </b-card>
      </div>
    </div>
  </b-col>
</template>

<script>
export default {
  name: 'searchLMDDAccount',
  props: ['loading'],
  data () {
    return {
      search: '',
      userType: 10,
    }
  },
  methods: {
    searchLMDDAccount() {
        this.$emit('searchForm', {
          search: this.search,
          user_type: this.userType,
          page: 1,
        })
    },
    addEventSearch() {
      const myInput = document.getElementById("searchAccount");
      if (!myInput) {
        setTimeout(() => {
          this.addEventSearch();
        }, 500);
      } else {
        myInput.addEventListener("keyup", this.$debounce(this.searchLMDDAccount));
      }
    },
  },
  mounted() {
    this.addEventSearch();
  },
  watch: {
    search(value) {
      if (value == '') this.searchLMDDAccount()
    }
  }
}
</script>