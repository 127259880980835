<template>
  <div class="comments-component">
    <div class="title">
      <h1>Comments</h1>
    </div>

    <div class="container-info">
      <div class="form-group">
        <label>Additional note</label>
        <textarea
          v-model="form_investor.note"
          name=""
          id=""
          class="form-control"
        ></textarea>
      </div>
      <div class="form-group">
        <label>Labeling</label>
        <Multiselect
          v-model="form_investor.tags"
          :close-on-select="false"
          :searchable="true"
          :create-option="true"
          :options="labeling_options"
          class="form-control selector"
          label="name"
          mode="multiple"
          placeholder="Add tag"
          valueProp="name"
        >
          <template v-slot:caret>
            <span class="multiselect-caret">
              <img :src="icon_caret" alt="" />
            </span>
          </template>
        </Multiselect>

        <div v-if="form_investor.tags" class="badge-container">
          <div v-for="(tag, index) in form_investor.tags">
            <span>
              <label class="badge">
                <span>{{ tag }}</span>
                <span role="button" @click="removeTag(index)"
                  ><img :src="remove_badge" alt=""
                /></span>
              </label>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconCaret from "../../../../../public/common/select-arrow-down.svg";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2";
import RemoveBadgeIcon from "../../../../../public/common/remove-badge.svg";
import { mapState } from "vuex";

export default {
  name: "Comments",
  data() {
    return {
      icon_caret: IconCaret,
      remove_badge: RemoveBadgeIcon,
      tags: [],
    };
  },
  components: { Multiselect },
  computed: {
    ...mapState("investor_configuration", ["investor_info", "form_investor"]),
    ...mapState("investors_v2", ["labeling_list"]),

    investor() {
      return this.investor_info && this.investor_info._data
        ? this.investor_info._data.data
        : null;
    },
    labeling_options() {
      return this.labeling_list && this.labeling_list._data
        ? this.labeling_list._data.data
        : [];
    },
  },
  created() {
    setTimeout(() => {
      const form_data = {
        name: this.investor.name,
        note: this.investor.note,
        base_currency: this.investor.base_currency,
        demo: this.investor.is_real == 1 ? false : true,
        future: false,
        manual_order: this.investor.manual_order,
        tags: this.investor.tags,
      };

      this.$store.commit("investor_configuration/set_investor_form", form_data);
    }, 1000);
    this.$store.dispatch("investors_v2/labeling_list");
  },
  methods: {
    removeTag(index) {
      this.investor.tags.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
@import "../../../../styles/pages/investor_configuration/comments.scss";
</style>
