<template>
  <div class="investor-preview-transaction-component">
    <div class="transaction-container">
      <h1>Transactions</h1>
      <div v-if="transactions.length">
        <div v-for="(transaction, index) in transactions">
          <div class="transaction">
            <div class="info">
              <h2>{{ textFormat(transaction.event_type) }}</h2>
              <h3>{{ formatDate(transaction.created_at) }}</h3>
            </div>
            <div
              :class="{
                red: transaction.change < 0,
                green: transaction.change > 0,
              }"
            >
              <span v-if="transaction.change < 0">
                - $ {{ formatChange(transaction.change) }}</span
              >
              <span v-else> + $ {{ formatChange(transaction.change) }}</span>
            </div>
          </div>
        </div>
      </div>
      <AlertNoInformation v-else :isDark="false" />
    </div>
  </div>
</template>

<script>
import AlertNoInformation from "../../../commons/alerts/AlertNoInformation.vue";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "InvestorPreviewTransaction",
  components: { AlertNoInformation },
  computed: {
    ...mapState("transactions", ["transaction_list_preview"]),
    transactions() {
      return this.transaction_list_preview &&
        this.transaction_list_preview._data
        ? this.transaction_list_preview._data.data
        : null;
    },
  },
  methods: {
    formatChange(num) {
      return num < 0 ? num * -1 : num;
    },
    formatDate(date) {
      return date ? moment(date).format("DD MMM, YYYY") : "No data";
    },
    textFormat(text) {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    },
  },
};
</script>
