<template>
  <div class="panel page__investors">
    <b-container fluid>
      <b-row class="p-0">
        <ExchangeOptions />
        <CorrelationChart v-if="false"/>
        <FunctionCharts />
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ExchangeOptions from '@/components/pages/correlator/ExchangeOptions';
import CorrelationChart from '@/components/pages/correlator/CorrelationChart'
import FunctionCharts from '@/components/pages/correlator/FunctionCharts';

export default {
  name: 'Correlator',
  components: {
    ExchangeOptions,
    CorrelationChart,
    FunctionCharts,
  },
  created() {
    this.$helpers.setWindowName();
  },
  mounted() {
    this.$helpers.setWindowName();
  },
}
</script>

 <style lang="scss">
    @import '@/styles/commons/admin.scss';
    @import '@/styles/pages/users.scss';
    @import '@/styles/pages/investors.scss';
  </style>