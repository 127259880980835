import UpdatePortfolioService from "../../../services/update_portfolio.service";
export default ({ commit, state }, { callback, error }) => {
  commit("set", {
    _state: "update_portfolio",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });

  const {
    exchange_token,
    ignore_transaction,
    group_token,
    name,
    type,
    token,
    amount,
  } = state.form_portfolio;

  const body = {
    exchange_token,
    ignore_transaction: ignore_transaction ? 1 : 0,
    group_token,
    name,
    is_amount_percentage: type == "percentage" ? 1 : 0,
  };
  if (amount) {
    body.amount = amount;
  }

  UpdatePortfolioService({
    body,
    token,
    callback: ({ response }) => {
      commit("set", {
        _state: "update_portfolio",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "update_portfolio",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
