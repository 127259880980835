var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"form-group d-flex aling-item-center justify-content-end mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_open),expression:"search_open"},{name:"show",rawName:"v-show",value:(!_vm.$store.state.orders_opened_loading),expression:"!$store.state.orders_opened_loading"}],staticClass:"form-control float-right d-inline-block",attrs:{"type":"text","id":"searchOpenOrder","placeholder":"Search by symbol or token..."},domProps:{"value":(_vm.search_open)},on:{"input":function($event){if($event.target.composing)return;_vm.search_open=$event.target.value}}})]),_vm._l((_vm.$store.state.orders_opened),function(order,i){return (!_vm.$store.state.orders_opened_loading)?_c('CardOpenedOrder',{key:i,attrs:{"order":order,"setAnimate":_vm.setAnimate,"inClosingLoading":_vm.inClosingLoading},on:{"handleCloseInmediateOrder":_vm.handleCloseInmediateOrder,"handleShowOrders":_vm.showOrders}}):_vm._e()}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(
          _vm.$store.state.open_orders_paginate.total_pages > 0 &&
          !_vm.$store.state.orders_opened_loading
        )?_c('b-pagination',{staticClass:"mt-2",attrs:{"total-rows":_vm.$store.state.open_orders_paginate.records,"limit":4,"per-page":_vm.$store.state.open_orders_paginate.per_page},on:{"page-click":_vm.handleNextPage},model:{value:(_vm.$store.state.open_orders_paginate.current_page),callback:function ($$v) {_vm.$set(_vm.$store.state.open_orders_paginate, "current_page", $$v)},expression:"$store.state.open_orders_paginate.current_page"}}):_vm._e()],1)]),(_vm.$store.state.orders_opened_loading)?_c('div',{staticClass:"text-center my-2"},[_c('b-skeleton-table',{attrs:{"rows":5,"columns":1,"table-props":{ striped: true }}})],1):_vm._e(),(
      _vm.$store.state.orders_opened.length === 0 &&
      !_vm.$store.state.orders_opened_loading
    )?_c('div',[_c('b-alert',{staticClass:"mb-2",attrs:{"show":"","variant":"dark"}},[_c('span',[_vm._v("There are no open orders.")])])],1):_vm._e(),_c('b-modal',{ref:"modal-show-json",attrs:{"id":"modal-show-json-opened","hide-footer":"","hide-header":"","size":"xl","ok-only":""}},[_c('div',{staticClass:"modal-create-conditions"},[_c('h3',{staticClass:"modal-title d-flex justify-content-between"},[(!_vm.showLog)?_c('span',[_vm._v(" Show order ")]):_c('span',[_vm._v("Show logs")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter_values_json),expression:"filter_values_json"}],staticClass:"form-control d-block mb-2",attrs:{"type":"text","placeholder":"Filter logs...","id":"filterValuesJson"},domProps:{"value":(_vm.filter_values_json)},on:{"input":function($event){if($event.target.composing)return;_vm.filter_values_json=$event.target.value}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-11 pr-0"},[_c('ViewJson',{attrs:{"jsonData":_vm.json_order,"filter":_vm.filter}})],1),_c('div',{staticClass:"col-md-1"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"pointer",staticStyle:{"font-size":"22px"},attrs:{"title":"Copy one or more conditions"},on:{"click":function($event){$event.preventDefault();_vm.$helpers.copyValues(JSON.stringify(_vm.json_order), _vm._vue, null)}}},[_c('b-icon-paperclip')],1)])])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }