<template>
  <div>
    <div class="card p-2 mb-2">
      <b-table
        :items="items"
        :fields = fields
        responsive
        show-empty
        empty-text="Please add a symbol."
      >
      <template #cell(symbol)="value">
        <div class ="d-flex flex-row">
          <span class="mr-2">{{value.item.symbol}}</span>
          <span class="ml-auto"><b-icon-trash @click="deleteSymbol(value.item)"></b-icon-trash></span>
        </div>
      </template>
      </b-table>
    </div>
    <div class="card p-2 mb-2">
      <button class="btn btn-dark" type="button" @click="makeCorrelation()">Make Correlation</button>  
      <button class="btn btn-danger mt-2" type="button" @click="stopCorrelation()" v-if="false">Stop Stream</button>  
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableSymbols',
  data() {
    return {
      fields: ['exchange', 'symbol']
    }
  },
  computed: {
    items() {
      if (this.$store.state.symbols_correlator) {
        return this.$store.state.symbols_correlator.map((c) => {
          return {
            exchange: c.exchange,
            symbol: c.symbol,
          }
        })
      }
    },
  },
  methods: {
    deleteSymbol(value) {
      this.$store.commit('remove_symbols_correlator', value);
      setTimeout(() => {
        if (this.$store.state.symbols_correlator.length == 0) {
          this.$store.commit('set_make_correlation', false);
        }
      }, 250);
    },
    makeCorrelation() {
      this.$store.commit('set_make_correlation', false);
      setTimeout(() => {
        this.$store.commit('set_make_correlation', true);
      }, 250);
    },
    stopCorrelation() {
      this.$store.commit('set_make_correlation', false);
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .table tbody tr td {
  padding: 3px;
  font-size: 12px;
}
::v-deep .table thead tr th {
  padding: 3px;
}
</style>