<template>
  <div class="component-opened-orders">
    <ul class="pagination" role="menubar" aria-label="Pagination">
      <li>
        <a @click="goFirstPage"
          ><span><img :src="double_arrow_left" alt="" /></span
        ></a>
      </li>
      <li v-if="pagination.current_page > 1">
        <a @click="goPrevPage"
          ><span><img :src="arrow_left" alt="" /></span
        ></a>
      </li>
      <li v-else>
        <a
          ><span><img :src="arrow_left" alt="" /></span
        ></a>
      </li>
      <li class="current">
        <a>{{ pagination.current_page }}</a>
      </li>

      <li v-if="pagination.current_page < pagination.total_pages">
        <a @click="goNextPage"
          ><span><img :src="arrow_right" alt="" /></span
        ></a>
      </li>
      <li v-else>
        <a
          ><span><img :src="arrow_right" alt="" /></span
        ></a>
      </li>
      <li @click="goLastPage">
        <a
          ><span><img :src="double_arrow_right" alt="" /></span
        ></a>
      </li>
    </ul>
  </div>
</template>

<script>
import ArrowLeft from "../../../public/common/arrow-left.png";
import ArrowRight from "../../../public/common/arrow-right.png";
import DoubleArrowLeft from "../../../public/common/double-arrow-left.png";
import DoubleArrowRight from "../../../public/common/double-arrow-right.png";

export default {
  name: "OpenedOrders",
  data() {
    return {
      arrow_left: ArrowLeft,
      arrow_right: ArrowRight,
      double_arrow_left: DoubleArrowLeft,
      double_arrow_right: DoubleArrowRight,
    };
  },
  props: {
    goFirstPageCallback: {
      default: function () {},
      type: Function,
    },
    goLastPageCallback: {
      default: function () {},
      type: Function,
    },
    goNextPageCallback: {
      default: function () {},
      type: Function,
    },
    goPrevPageCallback: {
      default: function () {},
      type: Function,
    },
    pagination: {
      default: {
        current_page: 1,
        per_page: 10,
        records: 0,
        total_pages: 0,
      },
      type: Object,
    },
  },
  methods: {
    goFirstPage() {
      if (typeof this.goFirstPageCallback !== "undefined") {
        this.goFirstPageCallback();
      }
    },
    goLastPage() {
      if (typeof this.goLastPageCallback !== "undefined") {
        this.goLastPageCallback();
      }
    },
    goNextPage() {
      if (typeof this.goNextPageCallback !== "undefined") {
        this.goNextPageCallback();
      }
    },
    goPrevPage() {
      if (typeof this.goPrevPageCallback !== "undefined") {
        this.goPrevPageCallback();
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/commons/pagination.scss";
</style>
