<template>
  <div
    ref="tagsModal"
    id="tagsModal"
    class="modal fade modal-tags"
    tabindex="-1"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Tags</h5>
          <span class="view-logs-modal-close" @click="closeModal"
            ><img :src="close_icon"
          /></span>
        </div>
        <div class="modal-body">
          <div class="badge-container">
            <div v-for="(tag, index) in tags.tags">
              <span>
                <label class="badge">
                  <span>{{ tag }}</span>
                </label>
              </span>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button @click="closeModal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import closeIcon from "../../../../assets/images/close.svg";
import { mapState } from "vuex";
import { Modal } from "bootstrap";
import $ from "jquery";

export default {
  name: "tagsModal",
  components: {},
  props: ["show", "isEdit", "tags"],
  data() {
    return {
      close_icon: closeIcon,
    };
  },

  mounted() {
    $("#tagsModal").modal("show");
    const self = this;

    $("#tagsModal").on("hide.bs.modal", function (event) {
      self.$emit("closed");
      self.$store.commit("systems_heartbeat/set_systems_heartbeat_form");
    });
  },
  computed: {
    ...mapState("systems_heartbeat", ["form_systems_heartbeat"]),
  },
  methods: {
    closeModal() {
      $("#tagsModal").modal("hide");
    },
  },
};
</script>

<style lang="scss">
@import "../../../../styles/pages/systems_heartbeat/tags_modal.scss";
</style>
