<template>
  <b-col cols="12" lg="2" class="p-0 sidebar__user_information nice-scroll">
    <div class="p-2" v-if="!$store.state.info_investor_loading">
      <div class="separator">Basic information</div>

      <div class="item-data">
        <div class="label">Name:</div>
        <div class="description">
          {{
            $store.state.info_investor.info
              ? $store.state.info_investor.info.investor.name
              : "--"
          }}
        </div>
      </div>

      <div class="item-data">
        <div class="label">Token:</div>
        <div
          class="description"
          @click="
            copyToken(
              $store.state.info_investor.info
                ? $store.state.info_investor.info.token
                : '---'
            )
          "
        >
          {{
            $store.state.info_investor.info
              ? $store.state.info_investor.info.token
              : "---"
          }}
        </div>
      </div>
      <div class="item-data">
        <div class="label">Market type:</div>
        <div class="description">
          {{ $store.state.info_investor.info | getMarketType }}
        </div>
      </div>
      <div class="item-data" v-if="$store.state.user.token">
        <div class="label">Total distribution:</div>
        <div class="description">
          {{
            $helpers.numberFormat(
              $store.state.info_investor.info
                ? $store.state.info_investor.info.investor.balance
                : 0
            )
          }}
        </div>
      </div>
      <div class="item-data">
        <div class="label">Realized PNL:</div>
        <div
          class="description mono"
          :class="[
            $store.state.info_investor &&
            $store.state.info_investor.resumen &&
            $store.state.info_investor.resumen.profit_percentage >= 0
              ? 'text-success'
              : 'text-danger',
          ]"
        >
          {{
            $helpers.numberFormat(
              $store.state.info_investor.resumen
                ? $store.state.info_investor.resumen.profit_net
                : 0
            )
          }}
          <span
            class="badget-number"
            v-b-tooltip.hover
            title="Last month's performance"
            v-if="false"
          >
            {{
              $store.state.info_investor.resumen
                ? $store.state.info_investor.resumen.profit_percentage.toFixed(
                    2
                  )
                : 0
            }}%
          </span>
        </div>
      </div>
      <div class="item-data">
        <div class="label">Unrealized PNL:</div>
        <div
          class="description mono"
          :class="[
            $store.state.info_investor &&
            $store.state.info_investor.resumen &&
            $store.state.info_investor.resumen.unrealized_pnl >= 0
              ? 'text-success'
              : 'text-danger',
          ]"
        >
          {{
            $helpers.numberFormat(
              $store.state.info_investor.resumen
                ? $store.state.info_investor.resumen.unrealized_pnl
                : 0
            )
          }}

          <span
            class="badget-number"
            v-b-tooltip.hover
            title="Last month's performance"
            v-if="false"
          >
            {{
              $store.state.info_investor.resumen
                ? $store.state.info_investor.resumen.profit_percentage.toFixed(
                    2
                  )
                : 0
            }}%
          </span>
        </div>
      </div>
      <div
        class="item-data"
        v-if="
          $store.state.info_investor.info &&
          $store.state.info_investor.info.investor &&
          $store.state.info_investor.info.investor.is_real
        "
      >
        <div class="label">Available USDT Balance:</div>
        <div class="description">
          {{
            $store.state.info_investor.balances
              | currentBalance("usdt", $helpers.numberFormat)
          }}
        </div>
      </div>
      <div
        class="item-data"
        v-if="
          $store.state.info_investor.info &&
          $store.state.info_investor.info.investor &&
          $store.state.info_investor.info.investor.is_real
        "
      >
        <div class="label">Balance BNB:</div>
        <div class="description">
          {{
            $store.state.info_investor.balances
              | currentBalance("bnb", $helpers.numberFormat)
          }}
        </div>
      </div>
      <div class="item-data">
        <div class="label">Operation time:</div>
        <div class="description">
          {{
            $store.state.info_investor.info &&
            $store.state.info_investor.info.investor
              ? $store.state.info_investor.info.investor.time_operation
              : "--"
          }}
        </div>
      </div>
      <div class="item-data">
        <div class="label">Initial Date:</div>
        <div class="description">
          {{
            $store.state.info_investor.info &&
            $store.state.info_investor.info.investor
              ? $store.state.info_investor.info.investor.time_start
              : "--"
          }}
        </div>
      </div>
      <div
        class="item-data"
        v-if="
          $store.state.user.role_id &&
          ($store.state.user.role_id === 1 || $store.state.user.role_id === 2)
        "
      >
        <div class="label">Status:</div>
        <div class="description" v-if="on">
          Last update:
          <span class="badget-number mono" v-if="new_update > 0">
            {{ new_update | parseSeconds }}
          </span>
          <span class="badget-number mono" v-else>---</span>
        </div>
        <div class="description" v-else>
          <b-alert class="mb-1 mt-2" show variant="danger"
            >This account is off.</b-alert
          >
        </div>
      </div>

      <div
        class="item-data"
        v-if="conditions.length > 0 && on"
        v-for="(condition, index) in conditions"
        :key="index"
      >
        <div class="label" v-b-tooltip.hover :title="condition">
          {{ condition.substr(0, 32) }}...
        </div>
        <div class="description">
          Last update:
          <span class="badget-number mono" v-if="new_update > 0">
            {{ new_update | parseSeconds }}
          </span>
          <span class="badget-number mono" v-else>---</span>
        </div>
      </div>

      <div
        class="item-data"
        v-if="!$store.state.info_investor_loading && false"
      >
        <div class="label mb-1">Mode</div>
        <select
          v-if="this.$store.state.user.role_id == 1"
          v-model="viewMode"
          class="form-control"
        >
          <option :value="0">View Mode</option>
          <option :value="1">Trading Mode</option>
        </select>
      </div>

      <!-- Esto sirve para solo para el administrador
    funciones adicionales -->
      <div
        class="d-block mt-2 admin-menu"
        v-if="
          $store.state.user.role_id &&
          ($store.state.user.role_id === 1 || $store.state.user.role_id === 2)
        "
      >
        <span v-b-modal.modal-options class="float-right">
          <b-icon-grid class="text-success"></b-icon-grid>
        </span>
      </div>

      <!-- Esto sirve para solo para inversores -->
      <OptionsRoleInvestor
        v-if="isInvestor"
        :investor_token="$route.params.token"
        :is_on="on"
        @handleOffInvestor="handleOffInvestor"
      />
    </div>

    <div class="p-2" v-if="$store.state.info_investor_loading">
      <b-skeleton-table
        :rows="5"
        :columns="1"
        :table-props="{ striped: true }"
      ></b-skeleton-table>
    </div>

    <CreateEditInvestor ref="CreateEditInvestor" />
    <TradingExpertModeInvestor ref="TradingExpertModeInvestor" />

    <b-modal
      id="modal-show-conditions"
      hide-footer
      hide-header
      size="xl"
      ok-only
    >
      <div class="modal-create-backtest">
        <h3 class="modal-title d-flex justify-content-between">
          <span>Show conditions</span>
        </h3>
        <div class="row">
          <div class="col-md-12">
            <div
              v-for="(i, key) in $store.state.info_investor.conditions"
              class="row mb-2"
              v-if="$store.state.info_investor.conditions.length > 0"
            >
              <div class="col-md-10">
                <textarea
                  class="form-control nice-scroll conditions"
                  style="height: 100px"
                  >{{ i }}</textarea
                >
              </div>
              <div class="col-md-2">
                <div class="d-block edit-conditions">
                  <span
                    v-b-tooltip.hover
                    title="View and modify"
                    @click="handleShowCondition({ ...i }, key)"
                  >
                    <b-icon-gear></b-icon-gear>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-add-condition-v"
      hide-footer
      hide-header
      size="xl"
      ok-only
    >
      <div class="modal-create-conditions">
        <h3 class="modal-title">Condition generator</h3>
        <ConditionGenerator ref="conditionGeneratorShow" />
      </div>
    </b-modal>

    <b-modal
      id="modal-date-range-report"
      hide-footer
      hide-header
      size="sm"
      ok-only
    >
      <div class="modal-create-backtest">
        <h3 class="modal-title d-flex justify-content-between">
          <span>Select a date range</span>
        </h3>
        <div class="row">
          <div class="col-md-12 text-center mb-2">
            <select class="form-control" v-model="algorithm_token">
              <option :value="null">All algorithms</option>
              <option :value="algorithm.token" v-for="algorithm in algorithms">
                {{ algorithm.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <BaseDateRangePicker
              style="width: 100%"
              v-model="dateRange"
              format="YYYY-MM-DD"
              :showLabel="false"
              label="Date Range"
              :dateDefault="dateRange"
              :timepicker="true"
              :everydayMinDate="everydayMinDate"
              :everydayMaxDate="everydayMaxDate"
            />
          </div>
          <div class="col-md-12 text-center mt-2">
            <b-button variant="success" class="w-100" @click="downloadReport"
              >Download</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-options"
      title="Options"
      hide-footer
      size="sm"
      v-if="
        $store.state.user.role_id &&
        ($store.state.user.role_id === 1 || $store.state.user.role_id === 2)
      "
    >
      <div class="row">
        <div
          class="col-12"
          v-if="
            $store.state.user.role_id &&
            ($store.state.user.role_id === 1 || $store.state.user.role_id === 2)
          "
        >
          <b-button
            v-b-tooltip.hover
            title="Turn off or on the account"
            class="float-right"
            @click="handleOffInvestor($store.state.info_investor.info.token, 0)"
            v-if="on"
            style="font-size: 25px"
          >
            <b-icon-toggle-on class="text-success"></b-icon-toggle-on>
          </b-button>

          <b-button
            v-b-tooltip.hover
            title="Turn off or on the account"
            class="float-right"
            @click="handleOffInvestor($store.state.info_investor.info.token, 1)"
            v-else
            style="font-size: 25px"
          >
            <b-icon-toggle-off class="text-success"></b-icon-toggle-off>
          </b-button>
        </div>

        <div class="col-md-12">
          <b-button
            class="mr-2 my-1 btn-lg d-block w-100 text-left"
            v-b-tooltip.hover
            title="Download report"
            @click="handleReportDownload($store.state.info_investor.info.token)"
            v-if="!loadingReport"
          >
            Download report
          </b-button>
          <b-button
            class="mr-2 my-1 btn-lg d-block w-100 text-left"
            v-b-tooltip.hover
            title="Download report"
            v-if="loadingReport"
          >
            <b-icon icon="arrow-repeat" animation="spin-pulse"></b-icon>
            Downloading report
          </b-button>
          <b-button
            class="mr-2 my-1 btn-lg d-block w-100 text-left"
            v-b-tooltip.hover
            title="Trade expert mode"
            v-if="$store.state.expert_mode"
            @click.prevent="showModalTrandinExpertModel"
          >
          </b-button>

          <b-button
            v-b-tooltip.hover
            class="mr-2 my-1 btn-lg d-block w-100 text-left"
            title="View"
            @click="showAccount"
            v-if="
              $store.state.user.role_id &&
              ($store.state.user.role_id === 1 ||
                $store.state.user.role_id === 2)
            "
          >
            View configuration
          </b-button>

          <b-button
            v-b-tooltip.hover
            class="mr-2 my-1 btn-lg d-block w-100 text-left"
            title="All inmediate close"
            v-if="
              !loadingAllclosing &&
              $store.state.info_investor.info &&
              $store.state.info_investor.info.investor &&
              $store.state.info_investor.info.investor.manual_order == 1
            "
            @click="
              handleAllClosing(
                $store.state.info_investor.info
                  ? $store.state.info_investor.info.token
                  : '---'
              )
            "
          >
            Close all operations
          </b-button>
          <b-button
            class="mr-2 my-1 btn-lg d-block w-100 text-left"
            v-b-tooltip.hover
            title="All inmediate close"
            v-if="
              loadingAllclosing &&
              $store.state.info_investor.info.investor &&
              $store.state.info_investor.info.investor.manual_order == 1
            "
          >
            <b-icon icon="arrow-repeat" animation="spin-pulse"></b-icon> Close
            all
          </b-button>

          <b-button
            v-b-tooltip.hover
            class="mr-2 my-1 btn-lg d-block w-100 text-left"
            v-if="!loadingAllclosing"
            title="Close all by symbol"
            @click="showModalOptionSymbol"
            :disabled="!on"
          >
            Close all by symbol
          </b-button>

          <b-button
            v-b-tooltip.hover
            class="mr-2 my-1 btn-lg d-block w-100 text-left"
            title="Close all by profit"
            @click="showModalOptionSymbolByProfit"
            :disabled="!on"
          >
            Close all by profit
          </b-button>

          <b-button
            v-b-tooltip.hover
            class="mr-2 my-1 btn-lg d-block w-100 text-left"
            title="Close all by profit"
            @click="showModalOptionCloseAllSymbolByProfit"
            :disabled="!on"
          >
            Close all symbol by profit
          </b-button>

          <b-button
            v-b-tooltip.hover
            class="mr-2 my-1 btn-lg d-block w-100 text-left"
            title="Convert small balances to BNB"
            @click="showModalConverSmallBalances"
            v-if="
              ($store.state.user.role_id && $store.state.user.role_id === 2) ||
              $store.state.user.role_id === 1
            "
          >
            Convert small balances to BNB
          </b-button>

          <b-button
            v-b-tooltip.hover
            class="mr-2 my-1 btn-lg d-block w-100 text-left"
            title="Sell surplus"
            @click="handleSellSurplus"
            v-if="
              ($store.state.user.role_id && $store.state.user.role_id === 2) ||
              $store.state.user.role_id === 1
            "
          >
            Sell surplus
          </b-button>

          <b-button
            v-b-tooltip.hover
            class="mr-2 my-1 btn-lg d-block w-100 text-left"
            v-if="!loadingAllclosing"
            title="Close orders by inverted symbol range"
            @click="showModalTotalInvestedBySymbol"
            :disabled="!on"
          >
            Close invested by symbol
          </b-button>

          <b-button
            v-b-tooltip.hover
            class="mr-2 my-1 btn-lg d-block w-100 text-left"
            title="Send new order"
            @click="showModalSendOrder"
            v-if="
              $store.state.info_investor.info &&
              $store.state.info_investor.info.investor &&
              $store.state.info_investor.info.investor.manual_order == 1 &&
              $store.state.user.role_id &&
              ($store.state.user.role_id === 1 ||
                $store.state.user.role_id === 2)
            "
          >
            Send new order
          </b-button>

          <b-button
            class="mr-2 my-1 btn-lg d-block w-100 text-left"
            v-b-tooltip.hover
            title="Close all by symbol"
            v-if="loadingAllclosing"
            :disabled="on"
          >
            <b-icon icon="arrow-repeat" animation="spin-pulse"></b-icon> Close
            all by symbol
          </b-button>

          <b-button
            class="mr-2 my-1 btn-lg d-block w-100 text-left"
            v-b-tooltip.hover
            title="Inmediate close"
            :disabled="!on"
            @click.prevent="handleInmediateClose"
          >
            Inmediate close
          </b-button>
          <b-button
            class="mr-2 my-1 btn-lg d-block w-100 text-left"
            v-b-tooltip.hover
            title="Cancel pending order"
            :disabled="!on"
            @click.prevent="handleCancelPendingOrder"
          >
            Cancel pending order
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-options-symbols"
      title="Close by symbol"
      hide-footer
      size="lg"
      v-if="
        $store.state.user.role_id &&
        ($store.state.user.role_id === 1 || $store.state.user.role_id === 2)
      "
    >
      <div class="row">
        <div class="col-md-9">
          <b-form-select
            v-model="selected_symbol"
            multiple
            :options="open_symbols"
            id="options-symbols"
          ></b-form-select>
        </div>
        <div class="col-md-3">
          <b-button
            class="ml-auto"
            v-b-tooltip.hover
            title="Close by symbol"
            @click="handleAllClosingBySymbol"
            :disabled="selected_symbol == null"
          >
            <b-icon icon="check"></b-icon> Close
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-options-symbols-by-profit"
      title="Close orders by profit"
      hide-footer
      size="lg"
      v-if="
        $store.state.user.role_id &&
        ($store.state.user.role_id === 1 || $store.state.user.role_id === 2)
      "
    >
      <div class="row">
        <div class="col-md-12">
          <b-form-select
            v-model="selected_symbol_by_profit"
            :options="open_symbols"
          ></b-form-select>
        </div>

        <div class="col-md-12 my-4">
          <label for="range-1" style="margin-bottom: 0px"
            >Select max profit</label
          >
          <b-form-input
            id="range-1"
            v-model="total_close_by_profit"
            type="range"
            min="-100"
            max="100"
            step="0.01"
          ></b-form-input>
          <div class="mt-0">
            Select percentage: {{ total_close_by_profit }}%
          </div>
        </div>

        <div class="col-md-3">
          <b-button
            class="ml-auto"
            v-b-tooltip.hover
            title="Close by symbol"
            @click="handleAllClosingSymbolByProfit"
            :disabled="
              selected_symbol_by_profit == null || total_close_by_profit == 0
            "
          >
            <b-icon icon="check"></b-icon> Close
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-options-all-symbols-by-profit"
      title="Close orders by profit"
      hide-footer
      size="lg"
      v-if="
        $store.state.user.role_id &&
        ($store.state.user.role_id === 1 || $store.state.user.role_id === 2)
      "
    >
      <div class="row">
        <div class="col-md-12 my-4">
          <label for="range-1" style="margin-bottom: 0px"
            >Select max profit</label
          >
          <b-form-input
            id="range-1"
            v-model="total_close_by_profit"
            type="range"
            min="-100"
            max="100"
            step="0.01"
          ></b-form-input>
          <div class="mt-0">
            Select percentage: {{ total_close_by_profit }}%
          </div>
        </div>

        <div class="col-md-3">
          <b-button
            class="ml-auto"
            v-b-tooltip.hover
            title="Close by symbol"
            @click="handleAllClosingAllSymbolByProfit"
            :disabled="
              selected_symbol_by_profit == null || total_close_by_profit == 0
            "
          >
            <b-icon icon="check"></b-icon> Close
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-total-invested-by-symbols"
      title="Total invested by symbol"
      hide-footer
      size="lg"
      v-if="
        $store.state.user.role_id &&
        ($store.state.user.role_id === 1 || $store.state.user.role_id === 2)
      "
    >
      <div class="row">
        <div class="col-md-12 my-1">
          <b-form-select
            v-model="selected_symbol_invested"
            :options="total_invested_symbols"
          ></b-form-select>
        </div>
        <div class="col-md-12 my-1" v-if="selected_symbol_invested">
          <label for="range-1" style="margin-bottom: 0px"
            >Select max total invested (<span>{{
              max_total_invested.toFixed(2)
            }}</span
            >)</label
          >
          <b-form-input
            id="range-1"
            v-model="total_invested"
            type="range"
            min="0"
            :max="max_total_invested"
            step="0.00000001"
          ></b-form-input>
          <div class="mt-0">Select invested: {{ total_invested }}</div>
        </div>
        <div class="col-md-12 d-flex">
          <b-button
            class="ml-auto"
            v-b-tooltip.hover
            title="Close invested by symbol"
            @click="handleCloseInvestedBySymbol"
            :disabled="total_invested == 0"
          >
            <b-icon icon="check"></b-icon> Close invested by symbol
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-convert-small-balance"
      title="Convert Small Balances"
      hide-footer
      size="lg"
      v-if="($store.state.user.role_id && $store.state.user.role_id === 2) || 1"
    >
      <div class="row">
        <div class="col-md-12 mt-2">
          <label>Select asset</label>
          <b-form-select
            v-model="asset"
            :options="[
              { text: 'BTC', value: 'BTC' },
              { text: 'USDT', value: 'USDT' },
              { text: 'BNB', value: 'BNB' },
            ]"
          ></b-form-select>
        </div>

        <div class="col-md-12 mt-2">
          <div class="form-group">
            <label>Except asset</label>
            <div>
              <b-form-select
                multiple
                :select-size="16"
                v-model="except_symbols"
                :options="open_symbols"
                id="except_asset"
              ></b-form-select>
            </div>
          </div>
        </div>
        <div class="col-md-12 my-2">
          <b-button
            class="d-flex ml-auto"
            v-b-tooltip.hover
            title="Close by symbol"
            @click="handleConvertSmallBalance"
          >
            Close
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal id="modal-send-orders" title="Send order" hide-footer size="lg">
      <InvestorOrdersForm />
    </b-modal>
  </b-col>
</template>
<script>
import CreateEditInvestor from "@/components/pages/investors/CreateEditInvestor";
import TradingExpertModeInvestor from "@/components/pages/investors/TradingExpertModeInvestor";
import ConditionGenerator from "@/components/commons/ConditionGenerator";
import handleSocket from "@/helpers/socket";
import moment from "moment";
import BaseDateRangePicker from "@/components/commons/BaseDateRangePicker";
import InvestorOrdersForm from "@/components/pages/investors/InvestorOrdersForm";
import OptionsRoleInvestor from "@/components/pages/investors/investor_details_modals/OptionsRoleInvestor";

export default {
  name: "CardInvestorInfo",
  components: {
    CreateEditInvestor,
    ConditionGenerator,
    TradingExpertModeInvestor,
    BaseDateRangePicker,
    InvestorOrdersForm,
    OptionsRoleInvestor,
  },
  created() {
    if (
      this.$store.state.info_investor &&
      this.$store.state.info_investor.info &&
      parseInt(this.$store.state.info_investor.info.investor.manual_order == 1)
    ) {
      if (
        this.$store.state.info_investor &&
        this.$store.state.info_investor.info &&
        this.$store.state.info_investor.info.investor.status == 8
      ) {
        this.on = false;
      } else {
        this.on = true;
      }
    } else {
      if (
        this.$store.state.info_investor &&
        this.$store.state.info_investor.info &&
        (this.$store.state.info_investor.info.investor.status == 2 ||
          this.$store.state.info_investor.info.investor.status == 1)
      ) {
        this.on = true;
      } else {
        this.on = false;
      }
    }
    let maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 1);
    this.maxDate = maxDate;
  },
  data() {
    return {
      algorithms: [],
      algorithm_token: null,
      loadingReport: false,
      loadingAllclosing: false,
      url_socket: "investors_status",
      new_update: null,
      idsInterval: "",
      play: true,
      animated: false,
      on: false,
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
      <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
      </svg> Error`,
      first: true,
      conditions: [],
      investorConfiguration: {},
      date_report_star: "",
      date_report_end: "",
      token: "",
      dateRange: "",
      maxDate: "",
      everydayMinDate: "",
      everydayMaxDate: "",
      open_symbols: [],
      total_invested_symbols: [],
      invested_symbols: [],
      selected_symbol: null,
      selected_symbol_by_profit: null,
      asset: null,
      except_symbols: [],
      selected_symbol_invested: null,
      total_invested: 0,
      total_close_by_profit: 0,
      max_total_invested: 0,
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
          <g clip-path="url(#clip0_1634_940)">
          <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
          </g>
          <defs>
          <clipPath id="clip0_1634_940">
          <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
          </clipPath>
          </defs>
          </svg> Success`,
    };
  },
  computed: {
    viewMode: {
      get() {
        return this.$store.state.investors_view_mode;
      },
      set(value) {
        this.$store.commit("set_investors_view_mode", value);
      },
    },
    isInvestor() {
      if (!this.$store.state.user.token) {
        return false;
      }
      return (
        this.$store.state.user.token &&
        this.$route.params.token &&
        this.$store.state.user.role_id === 0
      );
    },
  },
  methods: {
    showModalTotalInvestedBySymbol() {
      this.$bvModal.hide("modal-options-symbols");
      this.$bvModal.show("modal-total-invested-by-symbols");
    },
    showModalConverSmallBalances() {
      this.$bvModal.hide("modal-options-symbols");
      this.$bvModal.show("modal-convert-small-balance");
    },
    handleInmediateClose() {
      if (!confirm("Are sure?")) {
        return;
      }

      const data = {
        investor_token: this.$store.state.info_investor.info.token,
      };
      this.$http
        .server1("bwfs/admin/investor/inmediate/closing_all", data)
        .then((res) => {
          this.loadingAllclosing = false;
          if (res.error) {
            this.$toastr.error(res.message, `${this.error}`);

            return;
          }
          this.$toastr.success(res.message, `${this.success}`);
        })
        .catch((e) => {
          this.$toastr.error(e.message, `${this.error}`);
        });
    },
    handleCancelPendingOrder() {
      if (!confirm("Are sure?")) {
        return;
      }

      const data = {
        investor_token: this.$store.state.info_investor.info.token,
      };
      this.$http
        .server1("bwfs/admin/investor/cancel/pending_order", data)
        .then((res) => {
          this.loadingAllclosing = false;
          if (res.error) {
            this.$toastr.error(res.message, `${this.error}`);
            return;
          }
          this.$toastr.success(res.message, `${this.success}`);
        })
        .catch((e) => {
          this.$toastr.error(e.message, `${this.error}`);
        });
    },
    handleConvertSmallBalance() {
      if (!confirm("Are sure?")) {
        return;
      }
      if (!this.asset) {
        this.$toastr.error("Please select one asset", `${this.error}`);
        return;
      }
      const data = {
        investor_token: this.$store.state.info_investor.info.token,
        asset: this.asset,
        except_asset: JSON.stringify(this.except_symbols),
      };
      this.$http
        .server1("/bwfs/sell_dust_balances_to_bnb", data)
        .then((res) => {
          if (res.error) {
            this.$toastr.error(res.message, `${this.error}`);
            return;
          }
          this.$toastr.success(res.message, `${this.success}`);
        })
        .catch((e) => {
          this.$toastr.error(e.message, `${this.error}`);
        });
    },
    handleSellSurplus() {
      if (!confirm("Are sure?")) {
        return;
      }
      const data = {
        investor_token: this.$store.state.info_investor.info.token,
      };
      this.$http
        .server1("/bwfs/empty_account", data)
        .then((res) => {
          this.loadingAllclosing = false;
          if (res.error) {
            this.$toastr.error(res.message, `${this.error}`);
            return;
          }
          this.$toastr.success(res.message, `${this.success}`);
        })
        .catch((e) => {
          this.$toastr.error(e.message, `${this.error}`);
        });
    },
    showModalSendOrder() {
      this.$bvModal.hide("modal-options-symbols");
      this.$bvModal.show("modal-send-orders");
    },
    handleAllClosing(token) {
      if (!confirm("Are sure?")) {
        return;
      }
      const data = {
        investor_token: token,
      };
      this.loadingAllclosing = true;

      this.$http
        .server1("bwfs/admin/investors/total_close", data)
        .then((res) => {
          //this.$http.server1('bwfs/admin/investor/inmediate/closing_all', data).then((res) => {
          this.loadingAllclosing = false;
          if (res.error) {
            this.$toastr.error(res.message, `${this.error}`);
            return;
          }
          this.$toastr.success(
            "Success closing open orders.",
            `${this.success}`
          );
        })
        .catch((e) => {
          this.loadingAllclosing = false;
          this.$toastr.error(e.message, `${this.error}`);
        });
    },
    handleCloseInvestedBySymbol() {
      if (!confirm("Are sure?")) {
        return;
      }
      const data = {
        investor_token: this.$store.state.info_investor.info.token,
        total_invested: this.total_invested,
        symbol: this.selected_symbol_invested,
      };
      this.$http
        .server1("bwfs/admin/investor/close/total_invested_by_symbol", data)
        .then((res) => {
          if (res.error) {
            this.$toastr.error(res.message, `${this.error}`);
            return;
          }
          console.log(res.data);
          this.$toastr.success(
            "Success closing invested by symbol.",
            `${this.success}`
          );
        })
        .catch((e) => {
          this.$toastr.error(e.message, `${this.error}`);
        });
    },
    getTotalOpenOrdersBySymbol() {
      const data = {
        investor_token: this.$store.state.info_investor.info.token,
      };
      this.$http
        .server1("bwfs/admin/investors/total_open_orders", data)
        .then((res) => {
          if (res.error) {
            this.$toastr.error(res.message, `${this.error}`);
            return;
          }

          this.open_symbols.push({
            value: null,
            text: "Please select an symbol",
          });
          res.data.map((elem) => {
            this.open_symbols.push({
              text: `${elem.symbol.toUpperCase()}(${elem.total})`,
              value: elem.symbol,
            });
          });
        })
        .catch((e) => {
          this.$toastr.error(e.message, `${this.error}`);
        });
    },
    getTotalInvestedBySymbol() {
      const data = {
        investor_token: this.$store.state.info_investor.info.token,
      };
      this.$http
        .server1("bwfs/admin/investor/total_invested_by_symbol", data)
        .then((res) => {
          if (res.error) {
            this.$toastr.error(res.message, `${this.error}`);
            return;
          }

          this.total_invested_symbols.push({
            value: null,
            text: "Please select an symbol",
          });
          this.invested_symbols = res.data;
          res.data.map((elem) => {
            this.total_invested_symbols.push({
              text: `${elem.symbol.toUpperCase()}(${elem.total_invested.toFixed(
                2
              )})`,
              value: elem.symbol,
            });
          });
        })
        .catch((e) => {
          this.$toastr.error(e.message, `${this.error}`);
        });
    },
    showModalOptionSymbol() {
      this.$bvModal.show("modal-options-symbols");
    },
    showModalOptionSymbolByProfit() {
      this.$bvModal.show("modal-options-symbols-by-profit");
    },
    showModalOptionCloseAllSymbolByProfit() {
      this.$bvModal.show("modal-options-all-symbols-by-profit");
    },
    async handleAllClosingBySymbol() {
      if (!confirm("Are sure?")) {
        return;
      }
      const data = {
        investor_token: this.$store.state.info_investor.info.token,
        symbol: this.selected_symbol.join(","),
      };
      console.log(data);
      await this.$store.dispatch("bwfs_total_close_by_symbol", {
        vm: this,
        data,
      });
      this.$bvModal.hide("modal-options-symbols");
    },
    async handleAllClosingAllSymbolByProfit() {
      if (!confirm("Are sure?")) {
        return;
      }
      const data = {
        investor_token: this.$store.state.info_investor.info.token,
        profit: this.total_close_by_profit,
      };
      this.$bvModal.hide("modal-options");
      this.$bvModal.hide("modal-options-all-symbols-by-profit");
      this.$http
        .server1("bwfs/admin/investor/close/all_symbol/by_profit", data)
        .then((res) => {
          this.loadingReport = false;
          if (res.error) {
            this.$toastr.error(res.message, `${this.error}`);

            return;
          }
          this.$toastr.success(res.message, `${this.success}`);
        })
        .catch((e) => {
          this.$toastr.error(e.message, `${this.error}`);
        });
    },
    async handleAllClosingSymbolByProfit() {
      if (!confirm("Are sure?")) {
        return;
      }
      const data = {
        investor_token: this.$store.state.info_investor.info.token,
        symbol: this.selected_symbol_by_profit,
        profit: this.total_close_by_profit,
      };
      this.$bvModal.hide("modal-options");
      this.$bvModal.hide("modal-options-symbols-by-profit");
      this.$http
        .server1("bwfs/admin/investor/close/by_profit", data)
        .then((res) => {
          this.loadingReport = false;
          if (res.error) {
            this.$toastr.error(res.message, `${this.error}`);

            return;
          }
          this.$toastr.success(res.message, `${this.success}`);
        })
        .catch((e) => {
          this.$toastr.error(e.message, `${this.error}`);
        });
    },
    copyToken(token) {
      this.$helpers.copyTextToClipboard(token, this);
    },
    handleReportDownload(token) {
      this.token = token;
      this.$bvModal.show("modal-date-range-report");
    },
    downloadReport() {
      if (this.date_report_star == "" || this.date_report_end == "") {
        this.$toastr.error("Please select date range", `${this.error}`);

        return;
      }
      const data = {
        investor_token: this.token,
        start: this.date_report_star,
        end: this.date_report_end,
      };

      if (this.algorithm_token) {
        data["algorithm_token"] = this.algorithm_token;
      }

      this.$bvModal.hide("modal-date-range-report");
      this.loadingReport = true;
      this.$http
        .server1("bwfs/admin/investors/report", data)
        .then((res) => {
          this.loadingReport = false;
          if (res.error) {
            this.$toastr.error(res.message, `${this.error}`);
            return;
          }
          window.open(this.$http.url_api(true) + "/bwfs/static/" + res.message);
        })
        .catch((e) => {
          this.loadingReport = false;
          this.$toastr.error(e.message, `${this.error}`);
        });
    },
    handleOffInvestor(token, on_off) {
      const payload = {
        vm: this,
        data: {
          investor_token: token,
          on: on_off,
        },
      };
      this.$store.commit("handle_toggle_account", payload);
      this.$toastr.success("Request sent please wait.", `${this.success}`);
    },
    showAccount() {
      let user = this.$store.state.info_investor.info;
      user.name = this.$store.state.info_investor.info.investor.name;
      user.conditions = this.$store.state.info_investor.conditions;
      user.balances = this.$store.state.info_investor.balances;
      this.$refs.CreateEditInvestor.setDataFormAndDisable(user);
      this.$bvModal.show("modal-create-user");
    },
    showConditions() {
      this.$bvModal.show("modal-show-conditions");
    },
    handleShowCondition(condition, i) {
      this.$bvModal.show("modal-add-condition-v");
      setTimeout(() => {
        this.$refs.conditionGeneratorShow.showCondition(condition.condition);
      }, 200);
    },
    sendConnect() {
      this.doSend(this.$store.state.info_investor.info.token);
      this.doSend(
        this.$store.state.info_investor.info.investor.is_real.toString()
      );
    },
    onOpen() {
      if (!this.$store.state.info_investor.info) {
        let interval = setInterval(() => {
          if (this.$store.state.info_investor.info) {
            clearInterval(interval);
            this.sendConnect();
          }
        }, 250);
      } else {
        this.sendConnect();
      }
    },
    onClose(evt) {
      this.writeToScreen("DISCONNECTED");
    },
    onMessage(evt) {
      if (evt.data) {
        let payload = JSON.parse(evt.data);
        if (!this.first) {
          if (!payload.status && this.investorConfiguration.manual_order == 1) {
            this.on = true;
          } else if (this.on && !payload.status) {
            this.$toastr.success("Success account off.", `${this.success}`);
            this.on = false;
          } else if (!this.on && payload.status) {
            this.$toastr.success("Success account on.", `${this.success}`);
            this.on = true;
          }
        } else {
          this.first = false;
          if (!payload.status && this.investorConfiguration.manual_order == 0) {
            this.on = false;
          } else if (
            !payload.status &&
            this.investorConfiguration.manual_order == 1
          ) {
            this.on = true;
          } else {
            this.on = false;
          }
        }
        if (payload.status && Object.keys(payload.conditions).length > 0) {
          let token = Object.keys(payload.conditions)[0];
          let timestamp = new Date(
            parseInt(
              (payload.conditions[token].last_update * 1000)
                .toString()
                .split(".")[0]
            )
          );
          let date = new Date();
          if (this.idsInterval) {
            clearInterval(this.idsInterval);
          }
          this.animated = true;
          this.idsInterval = setInterval(
            this.handleUpdateLastUpdated,
            1000,
            date
          );
          setTimeout(() => {
            this.animated = false;
          }, 1500);

          if (Object.keys(payload.conditions).length > 0) {
            let keys = Object.keys(payload.conditions);
            this.conditions = [];
            for (let i = 0; i < keys.length; i++) {
              this.conditions.push(keys[i]);
            }
          }
        } else {
          let date = new Date();
          let timestamp = new Date(date.getTime()).toString().split(".")[0];
          if (this.idsInterval) {
            clearInterval(this.idsInterval);
          }
          this.animated = true;
          this.idsInterval = setInterval(
            this.handleUpdateLastUpdated,
            1000,
            date
          );
          setTimeout(() => {
            this.animated = false;
          }, 1500);
        }
      } else {
        clearInterval(this.idsInterval);
        this.idsInterval = "";
      }
    },
    handleUpdateLastUpdated(now) {
      let end = moment(new Date()); //todays date
      let duration = moment.duration(end.diff(now));
      let sec = duration.asSeconds();
      this.new_update = parseInt(sec);
      this.loading_time(sec);
    },
    onError(evt) {
      console.error(evt);
    },
    doSend(message) {
      this.websocket.send(message);
    },
    writeToScreen(message) {
      console.log("writeToScreen", message);
    },
    loading_time(sec) {
      if (!window.has_focus) {
        if (
          (sec / 60 >= 5 && this.$store.state.user.role_id === 1) ||
          this.$store.state.user.role_id === 2
        ) {
          if (this.play && this.on) {
            console.log("play on info");
            document.getElementById("alarm").play();
            this.$helpers.setIconDanger();
            this.play = false;
            setTimeout(() => {
              this.play = true;
            }, 1000 * 60 * 5);
          }
        }
      }
    },
    showModalTrandinExpertModel() {
      this.$bvModal.show("modal-trading-expert-mode-investor");
    },
  },
  watch: {
    "$store.state.info_investor.info.token"() {
      this.getTotalOpenOrdersBySymbol();
      this.getTotalInvestedBySymbol();
      // this.websocket = handleSocket(this.url_socket, this.onOpen, this.onClose, this.onMessage, this.onError);
      this.investorConfiguration =
        this.$store.state.info_investor.info.investor;
      this.algorithms = this.$store.state.info_investor.conditions.map(
        (condition) => condition.condition
      );

      if (
        this.$store.state.info_investor &&
        this.$store.state.info_investor.info &&
        parseInt(
          this.$store.state.info_investor.info.investor.manual_order == 1
        )
      ) {
        if (
          this.$store.state.info_investor &&
          this.$store.state.info_investor.info &&
          this.$store.state.info_investor.info.investor.status == 8
        ) {
          this.on = false;
        } else {
          this.on = true;
        }
      } else {
        if (
          this.$store.state.info_investor &&
          this.$store.state.info_investor.info &&
          (this.$store.state.info_investor.info.investor.status == 2 ||
            this.$store.state.info_investor.info.investor.status == 1)
        ) {
          this.on = true;
        } else {
          this.on = false;
        }
      }
      this.everydayMaxDate = new Date();
      let startDate = new Date(
        this.$store.state.info_investor.info.investor.time_start
      );
      startDate.setDate(startDate.getDate() - 7);
      this.everydayMinDate = startDate;
    },
    dateRange: function (newDates) {
      this.date_report_star = newDates.startDate.substr(0, 10);
      this.date_report_end = newDates.endDate.substr(0, 10);
    },
    selected_symbol_invested(symbol) {
      console.log(symbol);
      if (!symbol) {
        this.max_total_invested = 0;
        return;
      }

      let select_symbol = this.invested_symbols.find(
        (element) => element.symbol == symbol
      );
      this.max_total_invested = select_symbol.total_invested;
    },
  },
  filters: {
    parseSeconds(seconds) {
      if (seconds > 60) {
        if (seconds >= 3600) {
          seconds /= 3600;
          return `${parseInt(seconds)} Hour(s)`;
        }
        seconds /= 60;
        return `${parseInt(seconds)} Minute(s)`;
      }
      return `${seconds} Second(s)`;
    },
    currentBalance(value, symbol, numberFormat) {
      let filter = value.find(
        (e) => e.asset.toLowerCase() == symbol.toLowerCase()
      );
      if (filter) {
        if (filter.asset.toLowerCase() == "usdt") {
          return numberFormat(filter.balance);
        }
        return filter.balance;
      }
      return "---";
    },
    getMarketType(info) {
      if (!info) {
        return "--";
      }
      let config = JSON.parse(info.investor.configurations);
      if (config.market_type.toLowerCase() == "spot") {
        return "Spot";
      }
      return "Futures";
    },
  },
  beforeDestroy() {
    clearInterval(this.idsInterval);
    this.idsInterval = "";
    if (this.websocket) {
      this.websocket.close();
    }
  },
};
</script>
<style>
.animate__pulse_text {
  -webkit-animation: pulse_text 4s infinite alternate;
}

@-webkit-keyframes pulse_text {
  0% {
    color: #ffb802;
  }
  25% {
    color: #fff;
  }
  50% {
    color: #ffb802;
  }
  75% {
    color: #fff;
  }
  100% {
    color: #ffb802;
  }
}
.vue-daterange-picker {
  width: 100% !important;
}

#except_asset {
  height: 150px !important;
}

#options-symbols {
  height: 150px !important;
}
</style>
