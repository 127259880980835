var render = function render(){var _vm=this,_c=_vm._self._c;return (
    !_vm.$route.params.view_for_admin && _vm.$store.state.investors_view_mode == 0
  )?_c('div',{staticClass:"p-2",attrs:{"id":"notifications_container"}},[_c('div',{staticClass:"separator"},[_vm._v(" Notifications "),_c('span',{staticClass:"float-right mono"},[_vm._v("("+_vm._s(_vm.notifications.length)+")")])]),(_vm.$store.state.user.token || _vm.$route.name.toLowerCase() == 'investors')?_c('div',{staticClass:"form-group d-flex aling-item-center justify-content-end mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_notification),expression:"search_notification"}],staticClass:"form-control float-right d-inline-block",attrs:{"type":"text","id":"searchNotificationInput","placeholder":"Search by message and date..."},domProps:{"value":(_vm.search_notification)},on:{"input":function($event){if($event.target.composing)return;_vm.search_notification=$event.target.value}}})]):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.new_notification.msg)?_c('div',{staticClass:"item-data mb-2",class:[_vm.animate ? 'animate__pulse' : null]},[_c('div',{staticClass:"description",staticStyle:{"overflow-wrap":"break-word"}},[_vm._v(" "+_vm._s(_vm.new_notification.msg ? _vm.new_notification.msg : _vm.new_notification)+" ")]),_c('div',{staticClass:"label small"},[_vm._v(" "+_vm._s(_vm.new_notification.date ? _vm.new_notification.date.substr(0, 19) : _vm.new_notification.date)+" ")])]):_vm._e()]),_vm._l((_vm.notifications),function(notification){return (
      _vm.notifications.length > 0 && !_vm.$store.state.investor_notifications_loading
    )?_c('div',{staticClass:"item-data mb-2"},[_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(notification.msg ? notification.msg : notification)+" ")]),_c('div',{staticClass:"label small"},[_vm._v(" "+_vm._s(notification.date ? notification.date.substr(0, 19) : notification.date)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name.toLowerCase() == 'investors'),expression:"$route.name.toLowerCase() == 'investors'"}],staticClass:"label small"},[_vm._v(" "+_vm._s(notification.investor_name ? notification.investor_name : "")+" ")])]):_vm._e()}),(_vm.$store.state.investor_notifications_loading)?_c('b-skeleton-table',{attrs:{"rows":2,"columns":1,"table-props":{ striped: true }}}):_vm._e(),(
      !_vm.$store.state.investor_notifications_loading && _vm.notifications.length > 0
    )?_c('b-list-group-item',{key:'pgination',staticClass:"d-flex p-0"},[_c('b-button',{staticClass:"btn-sm mr-auto",attrs:{"variant":"dark","disabled":_vm.$store.state.investor_notifications_paginate.current_page - 1 === 0},on:{"click":function($event){return _vm.getNotifications(
          _vm.$store.state.investor_notifications_paginate.current_page - 1
        )}}},[_c('b-icon-arrow-left')],1),_c('span',[_vm._v(_vm._s(_vm.$store.state.investor_notifications_paginate.current_page)+"/"+_vm._s(_vm.$store.state.investor_notifications_paginate.total_pages))]),_c('b-button',{staticClass:"btn-sm ml-auto",attrs:{"variant":"dark","disabled":_vm.$store.state.investor_notifications_paginate.current_page + 1 >
        _vm.$store.state.investor_notifications_paginate.total_pages},on:{"click":function($event){return _vm.getNotifications(
          _vm.$store.state.investor_notifications_paginate.current_page + 1
        )}}},[_c('b-icon-arrow-right')],1)],1):_vm._e(),_c('div',{staticClass:"col-12 p-0"},[(
        !_vm.$store.state.investor_notifications_loading &&
        _vm.$store.state.investor_notifications_paginate.records == 0 &&
        !_vm.new_notification.msg
      )?_c('b-alert',{staticClass:"mb-2",attrs:{"show":"","variant":"dark"}},[_c('span',[_vm._v("No recent notifications.")])]):_vm._e()],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }