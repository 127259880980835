<template>
  <div>
    <div
      ref="depositWithdrwalModal"
      id="depositWithdrwalModal"
      class="modal fade modal-deposit-withdrawl"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Transactions</h5>
            <span class="modal-close" @click="closeModal"
              ><img :src="close_icon"
            /></span>
          </div>
          <div class="modal-body">
            <div v-if="connection_options.length" class="row">
              <div v-if="user_role != 2" class="col-12">
                <div class="d-flex justify-content-end align-items-center">
                  <button @click="openCreationModal">
                    <span style="font-size: 22px">
                      <b-icon-plus></b-icon-plus> </span
                    >Add new
                  </button>
                </div>
              </div>
            </div>
            <div v-else class="mb-2">
              <AlertInformation
                description="You must add a connection to be able to add a new deposit or withdrawl"
              />
            </div>
            <div class="row">
              <div class="col-12">
                <div class="filters">
                  <div class="form-group">
                    <label>Select type</label>
                    <Multiselect
                      v-model="transaction_filters.event_type"
                      :hideSelected="true"
                      :options="type_options"
                      class="form-control selector"
                      label="name"
                      placeholder="Select an option"
                      valueProp="value"
                    >
                      <template v-slot:caret>
                        <span class="multiselect-caret">
                          <img :src="icon_caret" alt="" />
                        </span>
                      </template>
                    </Multiselect>
                  </div>
                  <div class="form-group">
                    <label>Select type</label>
                    <BaseDateRangePicker
                      style="width: 100%"
                      v-model="transaction_filters.date"
                      format="YYYY-MM-DD"
                      :showLabel="false"
                      :icon="calendar_icon"
                      label="Date Range"
                      :dateDefault="dateRange"
                      placeholder="From - To"
                      :timepicker="true"
                      :everydayMinDate="everydayMinDate"
                      :everydayMaxDate="everydayMaxDate"
                    />
                  </div>
                  <div class="form-group">
                    <label>Items per page</label>
                    <Multiselect
                      v-model="transaction_filters.limit"
                      :canClear="false"
                      :canDeselect="false"
                      :hideSelected="true"
                      :options="item_per_page_options"
                      class="form-control selector"
                      label="name"
                      placeholder="Max per page: 10"
                      valueProp="value"
                    >
                      <template v-slot:caret>
                        <span class="multiselect-caret">
                          <img :src="icon_caret" alt="" />
                        </span>
                      </template>
                    </Multiselect>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <ProcessingLoader v-if="transactions_on_loading" />
                <AlertErrorInformation v-else-if="transactions_status.error" />
                <div
                  class="table-operations table-responsive"
                  v-else-if="transactions.length > 0"
                >
                  <Table
                    :actions="false"
                    :data="transactions"
                    :headers="columnHeaders"
                    class="operations-table"
                    title="operations"
                  >
                    <template v-slot:note="{ text }">
                      <span v-if="text != ''">{{ text }}</span>
                      <span v-else class="no-data">no data</span>
                    </template>

                    <template v-slot:change="{ text }">
                      <span v-if="text != ''" class="d-flex value"
                        ><span class="icon"><img :src="dollar" /></span>
                        {{ formatter(text) }}
                      </span>
                      <span v-else class="no-data">no data</span>
                    </template>
                    <template v-slot:actions="id" v-if="user_role != 2">
                      <div class="dropleft">
                        <span class="action-button" data-toggle="dropdown">
                          <img :src="menu_icon" />
                        </span>
                        <ul class="dropdown-menu">
                          <li @click="editOperation(id)">Edit transaction</li>
                          <li @click="deleteOperation(id)">
                            Delete transaction
                          </li>
                        </ul>
                      </div>
                    </template>
                    <template #pagination>
                      <Pagination
                        :pagination="paginate"
                        :go-first-page-callback="goFirstPage"
                        :go-last-page-callback="goLastPage"
                        :go-next-page-callback="goNextPage"
                        :go-prev-page-callback="goPrevPage"
                      />
                    </template>
                  </Table>
                </div>
                <AlertNoInformation v-else />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CreateEditTransaction
      :is-edit="is_edit_transaction"
      ref="create_edit_deposit_modal"
      @showDepositModal="show"
    />
  </div>
</template>

<script>
import AlertErrorInformation from "../../../commons/alerts/AlertErrorInformation.vue";
import AlertInformation from "../../../commons/alerts/AlertInformation.vue";
import AlertNoInformation from "../../../commons/alerts/AlertNoInformation.vue";
import BaseDateRangePicker from "@/components/commons/BaseDateRangePicker";
import calendarIcon from "@/assets/images/calendar.svg";
import closeIcon from "../../../../assets/images/close.svg";
import CreateEditTransaction from "./CreateEditTransaction.vue";
import Dollar from "../../../../../public/common/dollar.png";
import IconCaret from "../../../../../public/common/select-arrow-down.svg";
import MenuOptions from "../../../../../public/common/menu-dots-vertical.png";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2";
import NumberFormatter from "../../../../helpers/formatters/number_formatter";
import Pagination from "../../../commons/Pagination.vue";
import ProcessingLoader from "../../../commons/alerts/ProcessingLoader.vue";
import Table from "../../../commons/table/Table.vue";
import { mapState } from "vuex";
import $ from "jquery";
import { Modal } from "bootstrap";

export default {
  name: "DepositWithdrawlModal",
  props: ["modalId"],
  components: {
    AlertErrorInformation,
    AlertInformation,
    AlertNoInformation,
    BaseDateRangePicker,
    CreateEditTransaction,
    Multiselect,
    Pagination,
    ProcessingLoader,
    Table,
  },
  data() {
    return {
      close_icon: closeIcon,
      columnHeaders: [
        { name: "event_type", title: "Type", align: "left" },
        { name: "connection_name", title: "Connection", align: "left" },
        { name: "change", title: "Amount", align: "left" },
        { name: "created_at", title: "Date", align: "left" },
        { name: "note", title: "Note", align: "left" },
        {
          title: "",
          action: true,
          id: "token",
        },
      ],
      calendar_icon: calendarIcon,
      dateRange: "",
      dollar: Dollar,
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
          <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
          </svg> Error`,
      icon_caret: IconCaret,
      is_edit_transaction: false,
      item_per_page_options: [
        { value: "5", name: "Max per page: 5" },
        { value: "10", name: "Max per page: 10" },
        { value: "20", name: "Max per page: 20" },
      ],
      menu_icon: MenuOptions,
      operation_on_loading: false,
      operations_status: { error: false },
      showDepositWithdrwalModal: false,
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
            <g clip-path="url(#clip0_1634_940)">
            <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
            </g>
            <defs>
            <clipPath id="clip0_1634_940">
            <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
            </clipPath>
            </defs>
            </svg> Success`,
      type_options: [
        { name: "Deposit", value: "DEPOSIT" },
        { name: "Withdraw", value: "WITHDRAW" },
      ],
    };
  },
  mounted() {
    this.modal = new Modal(this.$refs.depositWithdrwalModal);
    const self = this;
    $("#depositWithdrwalModal").on("hide.bs.modal", function (event) {});
  },
  computed: {
    ...mapState("connections", ["connection_list_selector"]),
    ...mapState("transactions", [
      "investor_token",
      "transaction_filters",
      "transaction_list",
    ]),
    connection_options() {
      return this.connection_list_selector &&
        this.connection_list_selector._data
        ? this.connection_list_selector._data.data
        : null;
    },
    current_page() {
      return this.transaction_list._data.paginate
        ? this.transaction_list._data.paginate.current_page
        : null;
    },
    everydayMinDate() {
      return new Date();
    },
    everydayMaxDate() {
      return new Date();
    },
    paginate() {
      return this.transaction_list._data.paginate
        ? this.transaction_list._data.paginate
        : null;
    },
    transactions() {
      return this.transaction_list && this.transaction_list._data
        ? this.transaction_list._data.data
        : null;
    },
    transactions_on_loading() {
      return this.transaction_list._status._on_loading;
    },
    transactions_status() {
      return this.transaction_list && this.transaction_list._data
        ? this.transaction_list._data
        : [];
    },
    user_role() {
      return this.$store.state.user ? this.$store.state.user.role_id : null;
    },
  },
  methods: {
    closeModal() {
      this.modal.hide();
    },
    deleteOperation(id) {
      const body = {
        token: id.id,
      };

      Swal.fire({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#1c2030",
        confirmButtonText: "Delete",
        preConfirm: async (value) => {
          await this.performDeleteTransaction(body);
        },
      }).then(async (result) => {
        if (result.data) {
        }
      });
    },
    editOperation(id) {
      const data = this.transaction_list._data.data.find(
        (transaction) => transaction.token == id.id
      );

      const form_data = {
        amount: Math.abs(data.change),
        note: data.note,
        token: data.token,
        event: data.event_type,
        connection_token: data.connection_token,
        snapshot_token: data.token,
      };

      this.$store.commit("transactions/set_creation_form", form_data);

      this.is_edit_transaction = true;
      this.$refs["create_edit_deposit_modal"].show();
      this.modal.hide();
    },
    formatter(number) {
      return NumberFormatter(number);
    },
    goFirstPage() {
      this.$store.dispatch("transactions/transaction_list", {
        page: 1,
      });
    },
    goLastPage() {
      this.$store.dispatch("transactions/transaction_list", {
        page: this.paginate.total_pages,
      });
    },
    goNextPage() {
      this.$store.dispatch("transactions/transaction_list", {
        page: this.current_page + 1,
      });
    },
    goPrevPage() {
      this.$store.dispatch("transactions/transaction_list", {
        page: this.current_page - 1,
      });
    },
    openCreationModal() {
      this.is_edit_transaction = false;
      this.$refs["create_edit_deposit_modal"].show();
      this.modal.hide();
    },
    async performDeleteTransaction(body) {
      return new Promise((resolve) => {
        this.$store.dispatch("transactions/delete_transaction", {
          callback: ({ response }) => {
            if (response.error) {
              this.$toastr.error(`${response.message}`, `${this.error}`);
              return;
            } else {
              this.$toastr.success(
                "Transaction deleted successfully.",
                `${this.success}`
              );
              this.$store.dispatch("transactions/transaction_list", {});
            }
            resolve();
          },
          body,
        });
      });
    },
    reloadService() {
      this.$store.dispatch("transactions/transaction_list", {});
    },
    show() {
      if (this.modal) {
        this.modal.show();
      }
    },
  },
  watch: {
    "transaction_filters.event_type": function (value) {
      this.reloadService();
    },
    "transaction_filters.date": function (value) {
      this.reloadService();
    },
    "transaction_filters.limit": function (value) {
      this.reloadService();
    },
  },
};
</script>

<style lang="scss">
@import "../../../../styles/pages/investors_list/deposit_withdrawl_modal.scss";
.reportrange-text {
  color: rgba(255, 255, 255, 0.5) !important;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px !important;
}
</style>
