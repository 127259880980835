<template>
  <div :class="`page__login page__${$route.name.toLowerCase()}`">
    <b-container>
      <b-row>
        <b-col cols="12" lg="3"> </b-col>
        <b-col cols="12" lg="6">
          <div class="login p-2">
            <div class="d-block m-0 step-content">
              <div class="form-group text-center">
                <h3>Request access to the platform</h3>
                <p class="text-input-help mt-2">
                  The access authorization request was sent, please be patient
                  while an administrator authorizes your entry to the platform.
                  try to log in in a few minutes. <br />
                  <router-link :to="{ name: 'Login', params: {} }"
                    >log in</router-link
                  >
                </p>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import toastr from "toastr";

toastr.options.timeOut = 9999999; // How long the toast will display without user interaction
toastr.options.extendedTimeOut = 9999999; // How long the toast will display after a user hovers over it
toastr.options.timeOut = 9999999;
toastr.options.extendedTimeOut = 9999999;

export default {
  name: "RequestAccess",
  components: {},
};
</script>

<style lang="scss">
@import "@/styles/pages/login.scss";
</style>
