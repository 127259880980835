<template>
  <div class="col-md-6 p-1 text-right">
    <b-modal
      id="modal-create-user"
      hide-footer
      hide-header
      :size="modal_size"
      ok-only
    >
      <div class="modal-create-update-user">
        <h3 class="modal-title">User management</h3>
        <b-alert class="mb-2" show variant="warning" v-if="on">
          <span
            >The account is currently on, please use caution when modifying the
            data.</span
          >
        </b-alert>
        <div class="row px-2">
          <div class="col-md-6 px-2" :class="{ 'col-md-12': !isEdit }">
            <div class="row">
              <div class="col-md-12">
                <label class="text-muted"> Account info </label>
              </div>
            </div>
            <div class="row px-2">
              <div class="col-md-6 px-2">
                <div class="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    placeholder="Name"
                    class="form-control"
                    v-model="investorForm.name"
                    :disabled="disabled"
                    @input="$v.investorForm.name.$touch()"
                  />
                  <div v-if="$v.investorForm.name.$dirty">
                    <b-form-invalid-feedback
                      :state="$v.investorForm.name.required"
                    >
                      Required Field
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
              <div class="col-md-6 px-2">
                <div class="form-group">
                  <label>Token</label>
                  <input
                    type="text"
                    placeholder="Token"
                    class="form-control"
                    v-model="investorForm.token"
                    :disabled="disabled || true"
                    @input="$v.investorForm.token.$touch()"
                    :style="{ paddingRight: '40px' }"
                  />
                  <span class="icon" v-if="!isEdit && !isShow">
                    <b-icon-shuffle @click="generateToken"></b-icon-shuffle>
                  </span>
                  <div v-if="$v.investorForm.token.$dirty">
                    <b-form-invalid-feedback
                      :state="$v.investorForm.token.required"
                    >
                      Required Field
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="!isShow">
              <div class="col-md-12">
                <label class="text-muted"> Account type </label>
              </div>
            </div>
            <div class="row px-2 pt-2" v-if="!isShow">
              <div class="col-md px-2">
                <div class="form-group">
                  <label
                    v-b-tooltip.hover
                    title="Create an account DEMO or REAL"
                    >Demo</label
                  >
                  <b-form-checkbox
                    :value="true"
                    :unchecked-value="false"
                    :disabled="disabled"
                    v-model="investorForm.demo"
                  >
                    <span v-if="!investorForm.demo">
                      It will <u>not</u> be a demo account
                    </span>
                    <span v-if="investorForm.demo">
                      <u>Yes</u>, it will be a demo account
                    </span>
                  </b-form-checkbox>
                </div>
              </div>
              <div class="col-md px-2" v-if="investorForm.manual_order != 2">
                <div class="form-group">
                  <label
                    v-b-tooltip.hover
                    title="Create an account Future Account or not"
                    >Futures</label
                  >
                  <b-form-checkbox
                    :disabled="disabled"
                    v-model="investorForm.future"
                  >
                    <span v-if="investorForm.future">
                      <u>Yes</u>, it will be a future account
                    </span>
                    <span v-if="!investorForm.future">
                      It will <u>not</u> be a future account
                    </span>
                  </b-form-checkbox>
                </div>
              </div>
            </div>

            <div class="row px-2 pt-2" v-if="!isShow">
              <div class="col-md-4 px-2">
                <div class="form-group">
                  <label>OMS</label>
                  <select
                    class="form-control"
                    v-model="investorForm.manual_order"
                  >
                    <option value="1">Expert mode</option>
                    <option value="2">Ultimate OMS</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 px-2">
                <div class="form-group">
                  <label>Base currency</label>
                  <select
                    class="form-control"
                    v-model="investorForm.base_currency"
                    :disabled="disabled"
                  >
                    <option value="usdt">USDT</option>
                    <option value="btc">BTC</option>
                    <option value="bnb">BNB</option>
                    <option value="eth">ETH</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row" v-if="!isShow">
              <div class="col-md-12">
                <label class="text-muted"> Comments </label>
              </div>
            </div>
            <div class="row" v-if="!isShow">
              <div class="col-md-12">
                <div class="form-group m-0">
                  <label>Additional note</label>
                  <textarea
                    placeholder="Additional note"
                    class="form-control"
                    :disabled="disabled"
                    v-model="investorForm.note"
                  >
                  </textarea>
                </div>
              </div>
            </div>
            <div class="row" v-if="isEdit && !isShow">
              <div class="col-md-8">
                <div class="form-group mt-2">
                  <label for="tags-basic">Labeling</label>
                  <b-form-tags
                    input-id="tags-basic"
                    v-model="labeling"
                    class="form-control"
                    style="color: #fff !important"
                  ></b-form-tags>
                </div>
              </div>
              <div class="col-md-4 d-flex">
                <div
                  class="form-group mt-2"
                  style="display: flex; align-items: end"
                >
                  <b-button
                    variant="dark"
                    class="btn-sm"
                    v-if="!loading_labeling"
                    @click="addLabeling"
                    >Save labeling</b-button
                  >
                  <b-button
                    variant="dark"
                    class="btn-sm"
                    v-if="loading_labeling"
                    disabled
                    >Save labeling</b-button
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 px-2">
            <TableBalanceDistribution
              :showUpdateBalance="showUpdateBalance"
              :investor_edit="isEdit"
              :investorForm="investorForm"
              :list_balances="investorForm.balance_distribution"
              :loading="loading_audit"
              :isShow="isShow"
              :open_orders="open_orders"
              :investor_on="on"
              :showOptions="!isShow"
              ref="TableBalanceDistribution"
              @handleDeleteBalance="deleteBalance"
              @handleBaseSymbol="handleBaseSymbol"
              @handleAddBalance="handleAddBalance"
              v-if="isEdit"
            />
          </div>
        </div>
      </div>
      <div class="row" v-if="isEdit">
        <div class="col-md-12">
          <label class="text-muted">Connections </label>
        </div>
      </div>
      <div class="col-md-12" v-if="isEdit">
        <div
          class="text-right d-flex align-items-center justify-content-end title-click"
          @click.prevent="handleToggleModelAddConnection(false)"
        >
          Add new connection
          <span style="font-size: 22px">
            <b-icon-plus></b-icon-plus>
          </span>
        </div>
        <div class="row">
          <div class="col-12 p-0">
            <TableConnections
              v-if="!loading && isEdit"
              :investor-token="investorForm.token"
              :isEdit="isEdit"
              :open-modal-connection-creation="handleToggleModelAddConnection"
            />
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12" v-if="!isShow && !isEdit">
          <b-button variant="success" v-if="!loading" @click="handleSave"
            >Create new user</b-button
          >
          <b-button
            v-else
            class="mr-2 form-group pr-5"
            variant="success"
            :disabled="true"
          >
            Create new user
            <div class="loading">
              <b-spinner variant="light"></b-spinner>
            </div>
          </b-button>
        </div>
        <div class="col-md-12" v-if="isEdit">
          <b-button variant="success" v-if="!loading" @click="handleEditSave"
            >Edit user</b-button
          >
          <b-button
            v-else
            class="mr-2 form-group pr-5"
            variant="success"
            :disabled="true"
          >
            Edit user
            <div class="loading">
              <b-spinner variant="light"></b-spinner>
            </div>
          </b-button>
          <b-button variant="secondary" @click="handleRemoveOrders" class="ml-2"
            >Remove orders</b-button
          >
          <b-button
            variant="secondary"
            @click="showEMCrendentials"
            class="ml-2"
            v-if="this.investorForm.manual_order"
            >Show EM Crendentials</b-button
          >
          <b-button variant="secondary" @click="show2FA" class="ml-2"
            >Show 2FA</b-button
          >
          <b-modal
            :id="`modal-code-qr-investor-${investorForm.token}`"
            title="2FA QR Code"
          >
            <div style="text-align: center">
              <vue-qr :text="codeQR" :size="300"></vue-qr>
            </div>
            <template #modal-footer="{ ok, cancel }"> </template>
          </b-modal>
        </div>
      </div>
    </b-modal>

    <b-modal id="modal-add-condition" hide-footer hide-header size="xl" ok-only>
      <div class="modal-create-conditions">
        <h3 class="modal-title d-flex justify-content-between">
          <span> Condition generator </span>
          <div v-if="isCompound && !show">
            <a
              href="#"
              style="font-size: 13px; color: #fff"
              class="pr-2"
              @click.prevent="activeTabCondition('buy')"
            >
              <span
                class="btn btn-dark small"
                :style="{
                  background: activeBuy ? '#11131b' : '#232429',
                  fontWeight: '400',
                }"
              >
                Condition #1
              </span>
            </a>
            <a
              href="#"
              style="font-size: 13px; color: #fff"
              @click.prevent="activeTabCondition('sell')"
            >
              <span
                class="btn btn-dark small"
                :style="{
                  background: activeSell ? '#11131b' : '#232429',
                  fontWeight: '400',
                }"
              >
                Condition #2
              </span>
            </a>
          </div>
        </h3>
        <ConditionGenerator
          ref="conditionGeneratorBuy"
          @addCondition="handleAddCondition"
          @handleAddModify="handleAddModify"
          @handleCompound="handleCompoundCondition"
          @handlePriority="handlePriority"
          @handleAddCompound="handleAddCompoundCondition"
          @handleSetType="handletSeType"
          @handleBaseConfiguration="handleBaseConfiguration"
          @setConditionPasteSell="setConditionPasteSell"
          v-show="activeBuy"
          :isCompound="isCompound"
          :backtest="investorForm"
          :is_investor="true"
          :isSpot="!investorForm.future"
        />
        <ConditionGenerator
          ref="conditionGeneratorSell"
          @addCondition="handleAddCondition"
          @handleAddModify="handleAddModify"
          @handleCompound="handleCompoundCondition"
          @handleAddCompound="handleAddCompoundCondition"
          :tabSell="true"
          :isCompound="isCompound"
          :valueCompound="is_compound"
          :isSpot="!investorForm.future"
          :priority="priority"
          v-show="activeSell"
          :type="currentTypeCondition === 'buy' ? 'sell' : 'buy'"
          :backtest="investorForm"
          :is_investor="true"
        />
      </div>
    </b-modal>

    <b-modal
      id="modal-shows-condition"
      hide-footer
      hide-header
      size="xl"
      ok-only
    >
      <div class="modal-create-conditions">
        <h3 class="modal-title">Shows condtions</h3>
        <div class="row">
          <div class="col-md-12">
            <input
              type="text"
              class="form-control d-block mb-2"
              v-model="filterValue3"
              placeholder="Filter condition..."
              id="filterValue3"
            />
          </div>
          <div class="col-md-12">
            <div
              v-for="(i, key) in investorForm.configurations"
              class="row mb-2"
            >
              <div class="col-md-10">
                <ViewJson
                  :jsonData="i"
                  :filter="filterValue2"
                  :key="key"
                  :style="{ borderLeft: '2px solid ' + colorID[key] }"
                />
              </div>
              <div class="col-md-2 pl-0 pt-2">
                <div class="d-flex edit-conditions">
                  <span
                    class="mr-1"
                    v-b-tooltip.hover
                    title="View and modify"
                    @click="handleEditCondition({ ...i }, key)"
                    v-if="!isShow"
                  >
                    <b-icon-gear></b-icon-gear>
                  </span>
                  <span
                    class="mr-1"
                    v-b-tooltip.hover
                    title="View and modify"
                    @click="handleEditCondition({ ...i }, key)"
                    v-else
                  >
                    <b-icon-eye></b-icon-eye>
                  </span>
                  <span
                    class="mr-1"
                    v-b-tooltip.hover
                    title="View and modify"
                    @click="handleDuplicateCondition({ ...i }, key)"
                    v-if="!isShow"
                  >
                    <b-icon-front></b-icon-front>
                  </span>
                  <span
                    class="mr-1"
                    v-b-tooltip.hover
                    title="View and modify"
                    @click="deleteCondition(key)"
                    v-if="
                      !isShow &&
                      $store.state.user &&
                      $store.state.user.role_id == 1
                    "
                  >
                    <b-icon-trash></b-icon-trash>
                  </span>

                  <span
                    class="pointer"
                    v-b-tooltip.hover
                    title="Copy one or more conditions"
                    v-if="showResumen(key)"
                    @click.prevent="copyCondition(i, key, showResumen(key))"
                  >
                    <b-icon-paperclip></b-icon-paperclip>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <CreateConnectionModal
      :show="showConnectionModal"
      :is-demo="investorForm.demo"
      :investor-token="investorForm.token"
      ref="modal_add_connection"
    />

    <!-- <CreateAndEditContributor
      :investor_token="investor_token"
      :investor="investorForm"
      :investor_balances="investor_balances"
      ref="CreateAndEditContributor"
    /> -->
    <!-- <CreateAndEditSubInvestors
      :investor_token="investor_token"
      :investor="investorForm"
      :investor_balances="investor_balances"
      ref="CreateAndEditSubInvestors"
    /> -->
  </div>
</template>

<script>
import ConditionGenerator from "@/components/commons/ConditionGenerator";
import CreateConnectionModal from "@/components/pages/investors/connections/CreateConnections.vue";
import ViewJson from "@/components/commons/ViewJson";
// import CreateAndEditContributor from "@/components/pages/investors/contributor/CreateAndEditContributor.vue";
import ContainerContributors from "@/components/pages/investors/contributor/ContainerContributors";
// import CreateAndEditSubInvestors from "@/components/pages/investors/subinvestors/CreateAndEditSubInvestors.vue";
import ContainerSubInvestors from "@/components/pages/investors/subinvestors/ContainerSubInvestors.vue";
import TableBalancesInvestors from "@/components/pages/investors/TableBalancesInvestors.vue";
import TableBalanceDistribution from "@/components/pages/investors/TableBalanceDistribution.vue";
import TableConnections from "@/components/pages/investors/TableConnections.vue";
import Helpers from "@/helpers/commons.js";
import { required, minLength, email } from "vuelidate/lib/validators";
import { EventBus } from "@/helpers/event-bus.js";
import VueQr from "vue-qr";

export default {
  name: "CreateEditInvestor",
  props: ["value"],
  components: {
    ConditionGenerator,
    // CreateAndEditContributor,
    ContainerContributors,
    // CreateAndEditSubInvestors,
    CreateConnectionModal,
    ContainerSubInvestors,
    ViewJson,
    TableBalancesInvestors,
    TableBalanceDistribution,
    TableConnections,
    VueQr,
  },
  validations: {
    investorForm: {
      name: { required },
      status: { required },
      token: { required },
      configurations: { required },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  data() {
    return {
      showConnectionModal: false,
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
      <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
      </svg> Error`,
      filterValue: "",
      filterValue1: "",
      filterValue2: "",
      filterValue3: "",
      filterValue4: "",
      investor_token: "",
      on: false,
      codeQR: "",
      investorForm: {
        name: null,
        token: null,
        base_currency: "usdt",
        note: "",
        demo: true,
        future: false,
        manual_order: 2,
      },
      labeling: [],
      show: false,
      showUpdateBalance: false,
      disabled: false,
      isShow: false,
      isEdit: false,
      isCompound: false,
      is_compound: "",
      currentTypeCondition: "buy",
      activeSell: false,
      activeBuy: true,
      priority: "buy",
      maximunCondition: 50,
      colorID: {},
      indexConditionModify: 0,
      loading: false,
      loading_labeling: false,
      loading_audit: false,
      isAddBalance: false,
      investor_balances: [],
      open_orders: 0,
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
          <g clip-path="url(#clip0_1634_940)">
          <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
          </g>
          <defs>
          <clipPath id="clip0_1634_940">
          <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
          </clipPath>
          </defs>
          </svg> Success`,
    };
  },
  computed: {
    modal_size() {
      return this.isEdit ? "xl" : "lg";
    },
  },
  mounted() {
    this.addEventSeach();
    this.addEventSeachModal();
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId == "modal-create-user" && !this.isAddBalance) {
        setTimeout(() => {
          this.resetForm();
        }, 500);
      }
    });
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (
        modalId == "modal-create-user" &&
        !this.isAddBalance &&
        !this.isEdit
      ) {
        this.generateToken();
      }
    });
  },
  methods: {
    activeTabCondition(type) {
      if (type === "buy") {
        this.activeBuy = true;
        this.activeSell = false;
      } else {
        this.activeBuy = false;
        this.activeSell = true;
      }
    },
    addLabeling() {
      if (this.loading_labeling) {
        this.$toastr.info("Please wait current update labeling");
        return;
      }
      let data = {
        investor_token: this.investorForm.token,
        labeling: this.labeling.join(","),
      };
      this.loading_labeling = true;
      this.$http
        .server1("bwfs/investors/labeling", data)
        .then((res) => {
          this.loading_labeling = false;
          if (res.error) {
            this.$toastr.error(res.message, `${this.error}`);
            return;
          }
          this.$toastr.success("Success update labeling.", `${this.success}`);
        })
        .catch((e) => {
          this.loading_labeling = false;
          this.$toastr.error(e.message, `${this.error}`);
        });
    },
    addEventSeach() {
      const myInput = document.getElementById("filterValue");
      if (!myInput) {
        setTimeout(() => {
          this.addEventSeach();
        }, 500);
      } else {
        myInput.addEventListener(
          "keyup",
          this.$debounce(() => {
            this.filterValue = this.filterValue1;
          })
        );
      }
    },
    addEventSeachModal() {
      const myInput = document.getElementById("filterValue3");
      if (!myInput) {
        setTimeout(() => {
          this.addEventSeachModal();
        }, 500);
      } else {
        myInput.addEventListener(
          "keyup",
          this.$debounce(() => {
            this.filterValue2 = this.filterValue3;
          })
        );
      }
    },
    clearShowCondition(condition) {
      if (condition.base_configuration) {
        condition.base_configuration = {
          ...this.$helpers.observerClean(condition.base_configuration),
        };
      } else {
        condition.base_configuration = {
          ...this.$helpers.observerClean(
            condition.condition.base_configuration
          ),
        };
        if (
          condition.base_configuration.configuration_compound &&
          condition.base_configuration.configuration_compound.method
        ) {
          condition.base_configuration.is_compound =
            "Method " +
            condition.base_configuration.configuration_compound.method;
        }
      }
      if (this.isShow) {
        if (condition.base_configuration.is_compound === true) {
          condition.base_configuration.is_compound =
            "method " +
            condition.base_configuration.configuration_compound.method;
        }
        delete condition.token_user;
        delete condition.created_at;
        delete condition.updated_at;
        delete condition.backtest_token;
      }
      return condition;
    },
    copyCondition(condition, index, isCompound) {
      if (condition.condition) {
        condition = condition.condition;
      }
      if (
        condition.base_configuration &&
        condition.base_configuration.is_compound != false &&
        condition.base_configuration.is_compound != "false"
      ) {
        let condition2 = null;
        let cc2 = null;

        if (this.$store.state.info_investor.conditions) {
          cc2 = this.$store.state.info_investor.conditions[index - 1];
        } else {
          cc2 = this.investorForm.configurations[index - 1];
        }
        if (cc2.condition) {
          condition2 = cc2.condition;
        } else {
          condition2 = cc2;
        }
        condition = [JSON.stringify(condition2), JSON.stringify(condition)];
      } else {
        if (typeof condition === "string") {
          condition = JSON.parse(condition);
          if (!condition.token) {
            condition.token =
              this.$store.state.info_investor.conditions[index].condition.token;
          }
          condition = JSON.stringify(condition);
        } else {
          if (!condition.token) {
            condition.token =
              this.$store.state.info_investor.conditions[index].condition.token;
          }
          condition = JSON.stringify(condition);
        }
      }
      let currentCondition = condition;
      let conditions = [];
      conditions.push(condition);
      if (localStorage.getItem("copyCondition")) {
        let c = localStorage.getItem("copyCondition");
        c = JSON.parse(this.$crypto.decrypt(c));
        conditions = [...conditions, ...c];
      }
      if (typeof currentCondition == "object") {
        let cc = [];
        if (currentCondition[0] && typeof currentCondition[0] == "string") {
          for (let i = 0; i < currentCondition.length; i++) {
            cc[i] = JSON.parse(currentCondition[i]);
          }
        }
        this.$helpers.copyTextToClipboard(JSON.stringify(cc), this);
      } else {
        this.$helpers.copyTextToClipboard(currentCondition, this);
      }
      localStorage.setItem(
        "copyCondition",
        this.$crypto.encrypt(JSON.stringify(conditions))
      );
      EventBus.$emit("handleCopyCondition", true);
    },
    deleteBalance(selectBalance) {
      let indexSelected;
      this.investorForm.balances.map((item, index) => {
        if (item.asset === selectBalance.asset) {
          indexSelected = index;
        }
        return item;
      });
      const { balances } = this.investorForm;
      delete balances.splice(indexSelected, 1);
      this.investorForm.balances = balances;
    },
    generateToken() {
      this.investorForm.token = Helpers.MD5(Helpers.generateRandomString(10));
    },
    handleEditCondition(condition, index) {
      condition = JSON.parse(JSON.stringify(condition));
      this.$bvModal.hide("modal-shows-condition");
      this.$bvModal.show("modal-add-condition");
      this.indexConditionModify = index;
      this.isModify = true;
      let is_compound = false;
      if (condition.base_configuration) {
        is_compound =
          condition.base_configuration.is_compound &&
          condition.base_configuration.is_compound != "false";
      } else if (condition.condition) {
        is_compound =
          condition.condition.base_configuration.is_compound &&
          condition.condition.base_configuration.is_compound != "false";
      }

      if (is_compound) {
        let indexCondition = 0;
        if (index % 2 !== 0) {
          this.activeSell = true;
          this.activeBuy = false;
          indexCondition = index - 1;
        } else {
          this.activeBuy = true;
          this.activeSell = false;
          indexCondition = index + 1;
        }

        setTimeout(() => {
          if (condition.condition) {
            condition = Object.assign(condition, condition.condition);
          }
          let cc = this.investorForm.configurations[indexCondition];
          cc = JSON.parse(JSON.stringify(cc));
          if (cc.condition) {
            cc = Object.assign(cc, cc.condition);
          }
          cc = this.clearShowCondition(cc);
          condition = this.clearShowCondition(condition);

          if (this.isShow) {
            cc.base_configuration = { ...cc.condition.base_configuration };
            cc.timeframes = cc.condition.timeframes;
            condition.base_configuration = {
              ...condition.condition.base_configuration,
            };
            condition.timeframes = { ...condition.condition.timeframes };
          } else {
            if (cc.base_configuration.is_compound) {
              cc.base_configuration.is_compound = `method ${cc.base_configuration.configuration_compound.method}`;
              condition.base_configuration.is_compound = `method ${condition.base_configuration.configuration_compound.method}`;
            }

            cc.base_configuration = { ...cc.base_configuration };
            cc.timeframes = cc.timeframes;
            condition.base_configuration = { ...condition.base_configuration };
            condition.timeframes = { ...condition.timeframes };
          }

          let method = "modifyCondition";
          if (this.isShow) {
            method = "showCondition";
          }

          if (indexCondition > index) {
            this.$refs.conditionGeneratorBuy[method](condition);
            this.$refs.conditionGeneratorSell[method](cc);
          } else {
            this.$refs.conditionGeneratorBuy[method](cc);
            this.$refs.conditionGeneratorSell[method](condition);
          }
        }, 300);
      } else {
        setTimeout(() => {
          if (this.isShow) {
            this.$refs.conditionGeneratorBuy.showCondition(condition);
          } else {
            this.$refs.conditionGeneratorBuy.modifyCondition(condition);
          }
        }, 300);
      }
    },
    handleAddCondition(condition) {
      if (this.investorForm.configurations.length < this.maximunCondition) {
        let existOtherCondition = this.investorForm.configurations.filter(
          (i) => i.base_configuration.is_compound != false
        );
        if (existOtherCondition.length > 0) {
          this.$toastr.error(
            "You only have to add compound or simple conditions.",
            `${this.error}`
          );
          return;
        }
        this.investorForm.configurations.push(condition);
        this.$bvModal.hide("modal-add-condition");
      } else {
        this.$toastr.error(
          "has exceeded the limit of allowed conditions",
          `${this.error}`
        );
      }
    },
    handleAddModify(condition, compoundSell) {
      if (
        condition.base_configuration.is_compound &&
        condition.base_configuration.is_compound != "false"
      ) {
        let condition1 = {};
        let condition2 = {};
        if (compoundSell) {
          condition1 = this.$refs.conditionGeneratorBuy.addCondition(true);
          condition2 = this.$refs.conditionGeneratorSell.addCondition(true);
        } else {
          condition2 = this.$refs.conditionGeneratorSell.addCondition(true);
          condition1 = this.$refs.conditionGeneratorBuy.addCondition(true);
        }

        if (
          condition1.base_configuration.type ===
          condition2.base_configuration.type
        ) {
          this.$toastr.error(
            "The compound conditions must be sell and buy.",
            `${this.error}`
          );

          return;
        }

        condition1.base_configuration.configuration_compound.method = parseInt(
          condition1.base_configuration.is_compound.replace("method", "")
        );
        condition2.base_configuration.configuration_compound.method = parseInt(
          condition2.base_configuration.is_compound.replace("method", "")
        );
        condition1.base_configuration.configuration_compound.token =
          condition2.token;
        condition2.base_configuration.configuration_compound.token =
          condition1.token;

        let indexOtherCondition = 0;
        if (this.indexConditionModify % 2 === 0) {
          indexOtherCondition = this.indexConditionModify + 1;
        } else {
          indexOtherCondition = this.indexConditionModify;
          this.indexConditionModify -= 1;
        }

        const configurations = this.investorForm.configurations;
        this.investorForm.configurations = [];

        setTimeout(() => {
          configurations[this.indexConditionModify] = {};
          configurations[this.indexConditionModify] = condition1;

          configurations[indexOtherCondition] = {};
          configurations[indexOtherCondition] = condition2;

          this.investorForm.configurations = configurations;
          if (!this.colorID[this.indexConditionModify]) {
            const color = this.$helpers.getRandomColor();
            this.colorID[this.indexConditionModify] = color;
            this.colorID[indexOtherCondition] = color;
          }

          this.$bvModal.hide("modal-add-condition");
          this.isModify = false;
        }, 250);
        return;
      }
      const configurations = this.investorForm.configurations;
      this.investorForm.configurations = [];
      setTimeout(() => {
        configurations[this.indexConditionModify] = {};
        configurations[this.indexConditionModify] = condition;
        this.colorID = {};
        for (let i = 0; i < configurations.length; i++) {
          if (configurations[i].base_configuration.configuration_compound) {
            configurations.splice(i, 1);
          }
        }
        this.investorForm.configurations = configurations;
        this.$bvModal.hide("modal-add-condition");
        this.isModify = false;
      }, 250);
    },
    handleCompoundCondition(value) {
      if (!value || value == "false") {
        this.isCompound = false;
        return;
      }
      this.isCompound = value != "false";
      this.is_compound = value;
    },
    handlePriority(value) {
      this.priority = value;
    },
    handleAddCompoundCondition(condition, type) {
      let c = {};
      if (this.activeSell) {
        c = this.$refs.conditionGeneratorBuy.addCondition(true);
      } else {
        c = this.$refs.conditionGeneratorSell.addCondition(true);
      }
      if (!c) {
        return;
      }
      if (c.base_configuration.type == condition.base_configuration.type) {
        this.$toastr.error(
          "The compound conditions must be sell and buy.",
          `${this.error}`
        );
        return;
      }
      let existOtherCondition = this.investorForm.configurations.filter(
        (i) => i.base_configuration.is_compound == false
      );
      if (existOtherCondition.length > 0) {
        this.$toastr.error(
          "You only have to add compound or simple conditions.",
          `${this.error}`
        );

        return;
      }
      c.base_configuration.configuration_compound.token = condition.token;
      c.base_configuration.configuration_compound.method = parseInt(
        c.base_configuration.is_compound.replace("method", "")
      );
      condition.base_configuration.configuration_compound.token = c.token;
      condition.base_configuration.configuration_compound.method = parseInt(
        condition.base_configuration.is_compound.replace("method", "")
      );
      this.$bvModal.hide("modal-add-condition");
      if (this.investorForm.configurations.length < this.maximunCondition) {
        let index = this.investorForm.configurations.length;
        const color = this.$helpers.getRandomColor();
        this.colorID[index] = color;
        this.colorID[index + 1] = color;
        if (this.activeSell) {
          this.investorForm.configurations.push(c);
          this.investorForm.configurations.push(condition);
        } else {
          this.investorForm.configurations.push(condition);
          this.investorForm.configurations.push(c);
        }
      } else {
        this.$toastr.error(
          "Has exceeded the limit of allowed conditions",
          `${this.error}`
        );
      }
    },
    handletSeType(type) {
      this.currentTypeCondition = "";
      setTimeout(() => {
        this.currentTypeCondition = type;
      }, 150);
    },
    handleBaseConfiguration(field, value) {
      this.$refs.conditionGeneratorSell.setData(field, value);
    },
    handleAddBalance() {
      if (!this.investorForm.demo && !this.isEdit) {
        if (!this.investorForm.secret_key || !this.investorForm.api_key) {
          this.$toastr.error(
            "Please add api key and secret key.",
            `${this.error}`
          );

          return;
        }
      }
      const input =
        '<input id="swal-input111" class="swal2-input" placeholder="Amount" type="number" style="display: flex; height:45px;max-width: 11.5em;">';
      const inputCustom =
        '<input id="swal-input1222" class="swal2-input" placeholder="Custom Symbol" type="text" style="display: none; height:45px;max-width: 11.5em;">';
      let select =
        '<select style="border: 1px solid #d9d9d9;border-radius: .1875em;font-size: 1.125em;height: 45px; display: flex;max-width: 11.5em;" class="swal2-select" id="swal-input266" placeholder="Custom Symbol">';
      const userDataBk = { ...this.investorForm };
      select += '<option value="">Select Symbol</option>';
      if (userDataBk.balances.filter((i) => i.asset === "BTC").length === 0) {
        select += '<option value="BTC">BTC</option>';
      }
      if (userDataBk.balances.filter((i) => i.asset === "USDT").length === 0) {
        select += '<option value="USDT">USDT</option>';
      }
      if (userDataBk.balances.filter((i) => i.asset === "BNB").length === 0) {
        select += '<option value="BNB">BNB</option>';
      }
      if (userDataBk.balances.filter((i) => i.asset === "ETH").length === 0) {
        select += '<option value="ETH">ETH</option>';
      }
      select += '<option value="custom">Custom</option>';
      select += "</select>";

      const isEditBk = this.isEdit;
      this.isAddBalance = true;
      this.$bvModal.hide("modal-create-user");

      let htmlForm = `${select}${input}${inputCustom}`;
      if (!this.investorForm.demo) {
        htmlForm = `${select}`;
      }

      Swal.fire({
        title: "Add balances",
        html: htmlForm,
        showCancelButton: true,
        background: "#0e1019",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#1c2030",
        confirmButtonText: "Save",
        reverseButtons: true,
        preConfirm: () => {
          let selectSymbol = document.getElementById("swal-input266").value;
          if (this.investorForm.demo) {
            if (!selectSymbol) {
              Swal.showValidationMessage("Please select a symbol!");
            }
            if (selectSymbol.toLowerCase() === "custom") {
              selectSymbol = document.getElementById("swal-input1222").value;
            }
            if (!Number(document.getElementById("swal-input111").value)) {
              Swal.showValidationMessage(
                `You must enter the value of ${selectSymbol}!`
              );
            }
            return {
              balance: document.getElementById("swal-input111").value,
              asset: selectSymbol,
              locked: 0,
              is_base: userDataBk.balances.length == 0 ? 1 : 0,
            };
          }
          let payload = {
            api_key: this.investorForm.api_key,
            secret_key: this.investorForm.secret_key,
            symbol: selectSymbol,
            market: !this.investorForm.future ? 1 : 0,
          };
          return new Promise((resolve) => {
            this.$http
              .server1("bwfs/admin/balance/get", payload)
              .then((res) => {
                if (res.error) {
                  Swal.showValidationMessage(`${res.message}`);
                }
                resolve({
                  balance: res.data,
                  asset: selectSymbol,
                  locked: 0,
                  is_base: userDataBk.balances.length == 0 ? 1 : 0,
                });
              })
              .catch((e) => {
                Swal.showValidationMessage(`${e.message}b`);
              });
          });
        },
      })
        .then((result) => {
          if (result.value) {
            userDataBk.balances.push(result.value);
          }
          this.userData = userDataBk;
          this.isEdit = isEditBk;
          this.$bvModal.show("modal-create-user");
          this.isAddBalance = false;
        })
        .catch(() => {});
      document
        .querySelector("#swal-input266")
        .addEventListener("change", function (event) {
          event.preventDefault();
          if (event.target.value.toLowerCase() === "custom") {
            document.querySelector("#swal-input1222").style.display = "flex";
          } else {
            document.querySelector("#swal-input1222").style.display = "none";
          }
        });
    },
    handleBaseSymbol(selectBalance) {
      let balances = this.investorForm.balances;
      for (let i = 0; i < this.investorForm.balances.length; i++) {
        if (this.investorForm.balances[i].asset === selectBalance.asset) {
          this.investorForm.balances[i].is_base = 1;
        } else {
          this.investorForm.balances[i].is_base = 0;
        }
      }
    },
    handleSave() {
      this.$v.$reset();
      if (!this.handleValidInvestor()) {
        return;
      }

      this.investorForm.future = this.investorForm.future ? 1 : 0;

      const data = {
        base_currency: this.investorForm.base_currency,
        future:
          this.investorForm.manual_order == 2 ? 1 : this.investorForm.future,
        is_real: this.investorForm.demo ? 0 : 1,
        manual_order: this.investorForm.manual_order,
        name: this.investorForm.name,
        note: this.investorForm.note ? this.investorForm.note : null,
        token: this.investorForm.token,
      };

      this.$http
        .server1("bwfs/v2/investor/add", data)
        .then((res) => {
          this.loading = false;
          if (res.error) {
            this.$toastr.error(res.message, `${this.error}`);
            return;
          }
          this.$bvModal.hide("modal-create-user");
          this.resetForm();
          this.$emit("handleReload");
        })
        .catch((e) => {
          this.loading = false;
          this.$toastr.error(e.message, `${this.error}`);
        });

      return;
    },
    handleValidInvestor() {
      let validData =
        this.$v.investorForm.name.$invalid ||
        this.$v.investorForm.token.$invalid ||
        this.$v.investorForm.configurations.$invalid;
      if (this.investorForm.manual_order) {
        validData =
          this.$v.investorForm.name.$invalid ||
          this.$v.investorForm.token.$invalid;
      }

      if (validData) {
        this.$toastr.error("Please complete the fields.", `${this.error}`);
        this.$v.$touch();
        return false;
      }

      if (!this.investorForm.base_currency) {
        this.$toastr.error("Please select base currency.", `${this.error}`);
        return false;
      }

      if (
        !this.investorForm.base_currency &&
        this.investorForm.balances.length === 0
      ) {
        this.$toastr.error("Please add balance.", `${this.error}`);
        return false;
      }

      if (
        !this.investorForm.demo &&
        !this.investorForm.base_currency &&
        !this.isEdit &&
        (!this.investorForm.api_key || !this.investorForm.secret_key)
      ) {
        this.$toastr.error(
          "Please add api key and secret key",
          `${this.error}`
        );
        return false;
      }

      return true;
    },
    handleDuplicateCondition(condition, index) {
      if (condition.condition) {
        condition = { ...condition.condition };
      }
      if (
        condition.base_configuration.is_compound &&
        condition.base_configuration.is_compound !== "false"
      ) {
        let cpy1 = this.handleCopyCondition(condition);
        let cpy2 = {};
        if (index % 2 !== 0) {
          cpy2 = this.handleCopyCondition(
            this.investorForm.configurations[index - 1]
          );
          let bkcpy1 = cpy1;
          cpy1 = cpy2;
          cpy2 = bkcpy1;
        } else {
          cpy2 = this.handleCopyCondition(
            this.investorForm.configurations[index + 1]
          );
        }
        cpy2.base_configuration.configuration_compound.token = cpy1.token;
        cpy1.base_configuration.configuration_compound.token = cpy2.token;
        const color = this.$helpers.getRandomColor();
        this.colorID[this.investorForm.configurations.length] = color;
        this.colorID[this.investorForm.configurations.length + 1] = color;
        this.investorForm.configurations.push(cpy1);
        this.investorForm.configurations.push(cpy2);
        return;
      }
      this.investorForm.configurations.push(
        this.handleCopyCondition(condition)
      );
    },
    handleCopyCondition(condition) {
      let cpy = { ...condition };
      const token = this.$helpers.getRandomInt();
      const conditionClear = {};

      let symbol = cpy.symbol;
      let investment_minimun = 0;
      if (cpy.investment_minimun) {
        investment_minimun = cpy.investment_minimun;
      } else {
        if (cpy.condition) {
          investment_minimun =
            cpy.condition.base_configuration.investment_minimun;
        } else {
          if (cpy.base_configuration.investment_minimun) {
            investment_minimun = cpy.base_configuration.investment_minimun;
          }
        }
      }

      let client_confirmation = cpy.client_confirmation;
      const options = Object.keys(cpy); //
      for (let i = 0; i < options.length; i += 1) {
        let key = cpy[options[i]];
        let keysInternos = Object.keys(cpy[options[i]]); // obtenemos los keys dentro de los principales (condiiton_ema, conditionClear_001, etc)
        let isAdd = false;
        conditionClear[options[i]] = {};
        for (let j = 0; j < keysInternos.length; j += 1) {
          let value = cpy[options[i]][keysInternos[j]];
          conditionClear[options[i]][keysInternos[j]] = undefined;
          if (typeof value === "object") {
            value = this.$helpers.observerClean(value);
          }
          conditionClear[options[i]][keysInternos[j]] = value;
        }
      }
      conditionClear.token = token;
      conditionClear.symbol = symbol;
      conditionClear.investment_minimun = investment_minimun;
      conditionClear.client_confirmation = client_confirmation;
      return conditionClear;
    },
    deleteCondition(index) {
      if (
        this.investorForm.configurations[index].base_configuration.is_compound
      ) {
        this.investorForm.configurations.splice(index, 1);
        delete this.colorID[index];
        if (index % 2 !== 0) {
          this.investorForm.configurations.splice(index - 1, 1);
          delete this.colorID[index - 1];
        } else {
          this.investorForm.configurations.splice(index, 1);
          delete this.colorID[index];
        }
      } else {
        this.investorForm.configurations.splice(index, 1);
      }
    },
    handleUpdateBalance() {
      const data = {
        api_key: this.investorForm.api_key,
        secret_key: this.investorForm.secret_key,
        spot: this.investorForm.future ? 0 : 1,
      };
      if (!this.investorForm.api_key || !this.investorForm.secret_key) {
        this.$toastr.error(
          "Please add api key and secret key",
          `${this.error}`
        );
        return false;
      }
      this.loading_audit = true;
      this.$http
        .server1("bwfs/admin/balance/all/get", data)
        .then((res) => {
          this.loading_audit = false;
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
          }
          let exits = false;
          this.investorForm.balances = [];
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i][1] == 0) {
              continue;
            }
            exits = true;
            this.investorForm.balances.push({
              balance: res.data[i][1],
              asset: res.data[i][0],
              is_base: 0,
            });
          }
          if (!exits) {
            this.$toastr.error("No balances available", `${this.error}`);
          }
        })
        .catch((e) => {
          this.loading_audit = false;
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    handleEditSave() {
      if (!this.handleValidInvestor()) {
        return;
      }

      const portafolio = [];

      if (this.$refs.TableBalanceDistribution.balances) {
        this.$refs.TableBalanceDistribution.balances.forEach((element) => {
          portafolio.push({
            token: element.token ? element.token : null,
            exchange_token: element.exchange_token
              ? element.exchange_token
              : null,
            group_token: element.group_token,
            initial_balance: element.initial_balance,
            current_balance: element.current_balance,
            name: element.name,
          });
        });
      }

      this.investorForm.future = this.investorForm.future ? 1 : 0;

      const data = {
        base_currency: this.investorForm.base_currency,
        future:
          this.investorForm.manual_order == 2 ? 1 : this.investorForm.future,
        is_real: this.investorForm.demo ? 0 : 1,
        manual_order: this.investorForm.manual_order,
        name: this.investorForm.name,
        note: this.investorForm.note ? this.investorForm.note : null,
        token: this.investorForm.token,
        portfolios: JSON.stringify(portafolio),
      };

      this.$http
        .server1(
          `bwfs/v2/investor/update/${this.investorForm.token}`,
          data,
          {},
          null,
          "PUT"
        )
        .then((res) => {
          this.loading = false;
          if (res.error) {
            this.$toastr.error(res.message, `${this.error}`);
            return;
          }
          this.$bvModal.hide("modal-create-user");
          this.resetForm();
          this.$emit("handleReload");
        })
        .catch((e) => {
          this.loading = false;
          this.$toastr.error(e.message, `${this.error}`);
        });

      return;
    },
    handleRemoveOrders() {
      if (!confirm("Are sure?")) {
        return;
      }
      const data = {
        investor_token: this.investorForm.token,
      };
      this.$http
        .server1("bwfs/admin/investor/remove/orders", data)
        .then((res) => {
          this.loading = false;
          if (res.error) {
            this.$toastr.error(res.message, `${this.error}`);
            return;
          }
          this.$toastr.success(res.message, `${this.success}`);
          this.$emit("handleReload");
          this.$bvModal.hide("modal-create-user");
        })
        .catch((e) => {
          this.loading = false;
          this.$toastr.error(e.message, `${this.error}`);
        });
    },
    handleToggleModelAddConnection() {
      this.$bvModal.show("modal_add_connection");
    },
    resetForm() {
      this.investorForm = {
        name: null,
        token: null,
        balances: [],
        configurations: [],
        note: "",
        demo: true,
        future: false,
        manual_order: 2,
        base_currency: "usdt",
      };
      this.show = false;
      this.disabled = false;
      this.isEdit = false;
      this.isShow = false;
      this.showUpdateBalance = false;
      this.isCompound = false;
      this.is_compound = "";
      this.currentTypeCondition = "buy";
      this.activeSell = false;
      this.on = false;
      this.priority = "buy";
      this.maximunCondition = 50;
      this.colorID = {};
      this.indexConditionModify = 0;
      this.loading = false;
      this.$v.$reset();
    },
    setDataFormAndDisable(data) {
      this.investorForm.name = data.name;
      this.investorForm.email = data.email;
      this.investorForm.token = data.token;
      this.investor_token = data.token;
      this.investorForm.send_report = data.email_report;
      this.investorForm.email_frequency = data.email_report_frequency;
      this.investorForm.email_report_order_frequency =
        data.email_report_order_frequency;
      this.investorForm.balances = data.balances;
      this.investorForm.email_address_report = data.email_address_report;
      this.investorForm.block_account = data.block_account;
      this.investorForm.aggregate = data.aggregate;
      this.investorForm.block_account_password = data.block_account_password;
      if (
        data.conditions.lenght > 0 &&
        data.conditions[0].condition.base_configuration &&
        data.conditions[0].condition.base_configuration.is_compound
      ) {
        for (let i = 0; i < data.conditions.length; i++) {
          const color = this.$helpers.getRandomColor();
          if (i % 2 !== 0) {
            this.colorID[i] = color;
            this.colorID[i - 1] = color;
          }
        }
      }
      if (data.conditions.length > 0) {
        this.investorForm.configurations = data.conditions;
      }
      this.disabled = true;
      this.isShow = true;
      this.isEdit = false;
    },
    async setEditInvestor(data) {
      this.on = data.on;
      let balances = [];
      if (data.balances) {
        for (let i = 0; i < data.balances.length; i++) {
          balances.push({
            asset: data.balances[i].asset.toUpperCase(),
            balance: data.balances[i].balance,
            locked: data.balances[i].locked,
            is_base: data.balances[i].is_base,
          });
        }
      }

      this.investor_balances = balances;
      this.investor_token = data.token;
      this.investorForm.name = data.name;
      this.investorForm.base_currency = data.investor.base_currency;
      this.investorForm.email = data.email;
      this.investorForm.send_notification = data.investor.send_notification
        ? true
        : false;
      this.investorForm.manual_order = data.investor.manual_order;
      this.investorForm.token = data.token;
      this.investorForm.note = data.investor.note;
      this.investorForm.api_key = data.investor.api_key;
      this.investorForm.send_report = data.email_report;
      this.investorForm.email_frequency = data.email_report_frequency;
      this.investorForm.email_address_report = data.email_address_report;
      this.investorForm.block_account = data.block_account;
      this.investorForm.aggregate = data.investor.aggregate;
      this.investorForm.block_account_password = data.block_account_password;
      this.investorForm.secret_key = data.investor.secret_key;
      this.investorForm.demo = data.investor.is_real ? false : true;
      let configuration =
        data.investor.configurations != ""
          ? JSON.parse(data.investor.configurations)
          : {};
      this.investorForm.future = configuration.market_type == "futures";
      this.investorForm.exchange = configuration.exchange;

      this.labeling = [];
      if (data.investor.labeling) {
        this.labeling = data.investor.labeling.split(",");
      }
      if (parseInt(data.investor.manual_order) == 2) {
        this.investorForm.balance_distribution = data.conditions;
      } else if (parseInt(data.investor.manual_order) == 1) {
        if (
          data.conditions.length > 0 &&
          data.conditions[0].base_configuration &&
          data.conditions[0].base_configuration.is_compound
        ) {
          for (let i = 0; i < data.conditions.length; i++) {
            const color = this.$helpers.getRandomColor();
            if (i % 2 !== 0) {
              this.colorID[i] = color;
              this.colorID[i - 1] = color;
            }
          }
        }
        this.investorForm.configurations = data.conditions;
      } else {
        this.investorForm.configurations = data.conditions;
      }

      this.investorForm.balances = [];
      this.investorForm.balances = [...balances];
      this.disabled = false;
      this.isShow = false;
      this.isEdit = true;
      const payload = {
        vm: this,
        data: {
          investor_token: this.investor_token,
        },
      };
      this.$store.commit("get_subinvestor", payload);
      this.$store.commit("get_contributor", payload);
    },
    setAutoCompletenvestor(data) {
      if (data.balances_futures) {
        for (let i = 0; i < data.balances_futures.length; i++) {
          balances.push({
            asset: data.balances_futures[i].asset.toUpperCase(),
            balance: data.balances_futures[i].balance,
          });
        }
      }
      if (data.balances_spot) {
        for (let i = 0; i < data.balances_spot.length; i++) {
          balances.push({
            asset: data.balances_spot[i].asset.toUpperCase(),
            balance: data.balances_spot[i].balance,
            locked: data.balances_spot[i].locked,
          });
        }
      }
      this.investorForm.name = data.name;
      this.investorForm.email = data.email;
      this.investorForm.note = data.investor.note;
      this.investorForm.api_key = data.investor.api_key;
      this.investorForm.secret_key = data.investor.secret_key;
      this.investorForm.demo = data.investor.is_demo ? false : true;
      if (data.investor.manual_order == 2) {
        this.investorForm.balance_distribution = data.conditions;
        this.investorForm.manual_order = true;
      }

      let configuration = JSON.parse(data.investor.configurations);
      this.investorForm.future = configuration.market_type == "futures";
      this.investorForm.exchange = configuration.exchange;
      this.investorForm.send_report = data.email_report;
      this.investorForm.email_frequency = data.email_report_frequency;
      this.investorForm.email_report_order_frequency =
        data.email_report_order_frequency;
      this.investorForm.block_account = data.block_account;
      this.investorForm.aggregate = data.investor.aggregate;
      this.investorForm.block_account_password = data.block_account_password;

      if (data.conditions[0]) {
        for (let i = 0; i < data.conditions.length; i++) {
          data.conditions[i].token = this.$helpers.getRandomInt();
        }

        if (
          data.conditions[0].base_configuration &&
          data.conditions[0].base_configuration.is_compound
        ) {
          for (let i = 0; i < data.conditions.length; i++) {
            const color = this.$helpers.getRandomColor();
            if (i % 2 !== 0) {
              this.colorID[i] = color;
              this.colorID[i - 1] = color;
              data.conditions[
                i
              ].base_configuration.configuration_compound.token =
                data.conditions[i - 1].token;
              data.conditions[
                i - 1
              ].base_configuration.configuration_compound.token =
                data.conditions[i].token;
            }
          }
        }
      }

      let balances = [];
      for (let i = 0; i < data.balances.length; i++) {
        balances.push({
          asset: data.balances[i].asset.toUpperCase(),
          balance: data.balances[i].balance,
          is_base: data.balances[i].is_base,
        });
      }
      this.investorForm.configurations = data.conditions;
      this.investorForm.balances = balances;
      this.disabled = false;
      this.isShow = false;
      this.isEdit = false;
      this.loading = false;
      this.$bvModal.show("modal-create-user");
      setTimeout(() => {
        this.investorForm.balances = balances;
      }, 250);
    },
    setConditionPasteSell(condition) {
      setTimeout(() => {
        this.$refs.conditionGeneratorSell.setCondition(condition);
      }, 500);
    },
    showModalAddCondition() {
      this.isCompound = false;
      this.activeSell = false;
      this.isModify = false;
      this.show = false;
      this.activeBuy = true;
      this.currentTypeCondition = "buy";
    },
    show2FA() {
      let payload = {
        investor_token: this.investorForm.token,
      };

      this.$http
        .server1("bwfs/admin/getTOTPURIInvestor", payload)
        .then((res) => {
          if (res.error) {
            this.$toastr.error(res.message, `${this.error}`);
            return;
          }

          this.codeQR = res.data.uri;
          this.$bvModal.show(
            `modal-code-qr-investor-${this.investorForm.token}`
          );
        })
        .catch((e) => {
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    showEMCrendentials() {
      this.$bvModal.hide("modal-create-user");
      let investor = this.investorForm;
      Swal.fire({
        title: "2FA Token",
        input: "text",
        showCancelButton: true,
        background: "#0e1019",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#1c2030",
        confirmButtonText: "Send",
        reverseButtons: true,
        preConfirm: (fa2) => {
          if (fa2.length < 6) {
            Swal.showValidationMessage(`The 2FA code must be 6 characters.`);
          }
          let payload = {
            investor_token: investor.token,
            "2fa_token": fa2,
          };
          return new Promise((resolve) => {
            this.$http
              .server1(
                "bwfs/admin/InvestorExpertPanel/get_em_user_credential",
                payload
              )
              .then((res) => {
                if (res.error) {
                  Swal.showValidationMessage(`${res.message}`);
                }
                resolve(res);
              })
              .catch((e) => {
                Swal.showValidationMessage(`${e.message}b`);
              });
          });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      })
        .then((result) => {
          if (result.value) {
            let response = result.value;
            Swal.fire({
              width: 600,
              padding: "3em",
              html: `<span style="color:#fff; font-weight: 500;">Api key:</span><br> ${response.api_key}<br><span style="color:#fff; font-weight: 500;">Secret key:</span> ${response.secret_key}`,
            });
            setTimeout(() => {
              document.getElementById("swal2-content").style.textAlign = "left";
            }, 200);
          }
        })
        .catch(() => {});
    },
    showResumen(i) {
      if (!this.isShow) {
        // return false;
      }
      return;
      let condition = {};
      if (this.isShow) {
        if (!this.$store.state.info_investor.conditions[i].condition) {
          condition = this.$store.state.info_investor.conditions[i];
        } else {
          condition = this.$store.state.info_investor.conditions[i].condition;
        }
      } else {
        condition = this.investorForm.configurations[i];
      }
      let is_compound =
        this.$store.state.info_investor &&
        this.$store.state.info_investor.conditions &&
        ((condition &&
          condition.base_configuration &&
          condition.base_configuration.is_compound) ||
          (condition && condition.is_compound));
      return (
        is_compound == false ||
        is_compound == "false" ||
        is_compound == undefined ||
        (i % 2 !== 0 && (is_compound != "false" || is_compound != false))
      );
    },
  },
  watch: {
    "investorForm.demo"(value) {
      if (!this.isEdit) {
        this.investorForm.balances = [];
      }
    },
    "investorForm.email_address_report"(value) {
      if (value === "None") {
        this.investorForm.email_address_report = "";
      }
    },
    "investorForm.future"(value) {
      // if (!value) {
      //   for (let i = 0; i < this.investorForm.configurations.length; i++) {
      //     this.investorForm.configurations[i].base_configuration.laverage = 1;
      //   }
      // }
    },
    // "investorForm.use_virtual_balance"(value) {},
  },
};
</script>

<style>
#modal-shows-condition .edit-conditions span {
  font-size: 18px;
  display: flex;
  width: 20px;
  text-align: center;
  margin-bottom: 10px;
}

#modal-shows-condition .conditions {
  font-family: "Roboto Mono", monospace;
  font-size: 11px;
  line-height: 1.1;
}
.balances-table .table tbody tr {
  padding: 2px;
}
.balances-table .table tbody td {
  padding: 0px;
}
.b-form-tags-input {
  color: #fff !important;
}
.title-click {
  cursor: pointer;
}
</style>
