<template>
  <div class="portfolio-tab">
    <div class="portfolio-tab-title">Portfolios</div>
    <div class="portfolio-tab-content">
      <ProcessingLoader v-if="portfolio_on_loading" />
      <AlertErrorInformation v-else-if="portfolios_status.error" />
      <div class="portfolio-table" v-else-if="portfolios.length > 0">
        <Table
          :actions="true"
          :data="portfolios"
          :headers="columnHeaders"
          class="portfolios-table"
          title="portfolios"
        >
          <template v-slot:initial_balance="{ text }">
            <span class="value"><img :src="dollar_icon" />{{ text ? formatter(text) : "0" }}</span>
          </template>
          <template v-slot:current_balance="{ text }">
            <span class="value"><img :src="dollar_icon" />{{ text ? formatter(text) : "0" }}</span>
          </template>
          <template v-slot:nav="{ text }">
            <span class="value"><img :src="dollar_icon" />{{ text ? formatter(text) : "0" }}</span>
          </template>
          <template v-slot:actions="id">
            <div class="dropdown">
              <span
                class="action-button"
                data-toggle="dropdown"
                data-reference="parent"
                data-offset="-135,0"
              >
                <img :src="menu_icon" />
              </span>
              <ul class="dropdown-menu">
                <li @click="editPortfolio(id)"><img :src="edit_icon" />Edit</li>
                <li @click="deletePortfolio(id)">
                  <img :src="trash_icon" />Delete
                </li>
              </ul>
            </div>
          </template>
          <template #pagination>
            <Pagination
              :pagination="paginate"
              :go-first-page-callback="goFirstPage"
              :go-last-page-callback="goLastPage"
              :go-next-page-callback="goNextPage"
              :go-prev-page-callback="goPrevPage"
            />
          </template>
        </Table>
      </div>
      <AlertNoInformation v-else />
    </div>
    <CreatePortfolioModal
      :show="show_portfolio_modal"
      :is-edit="is_edit_portfolio"
      @set_portfolio_edit="is_edit_portfolio = false"
    />
  </div>
</template>
<script>
import SearchIcon from "../../../../../public/common/search.png";
import IconCaret from "../../../../../public/common/select-arrow-down.svg";
import EditWhiteIcon from "../../../../../public/common/edit-white-icon.svg";
import TrashIcon from "../../../../../public/common/trash-icon.svg";
import Dollar from "../../../../../public/common/dollar.png";
import calendarIcon from "@/assets/images/calendar.svg";
import MenuOptions from "../../../../../public/common/menu-dots-vertical.png";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2";
import Table from "../../../commons/table/Table.vue";
import Pagination from "../../../commons/Pagination.vue";
import CreatePortfolioModal from "./modals/CreatePortfolioModal.vue";
import ProcessingLoader from "../../../commons/alerts/ProcessingLoader.vue";
import AlertErrorInformation from "../../../commons/alerts/AlertErrorInformation.vue";
import AlertNoInformation from "../../../commons/alerts/AlertNoInformation.vue";
import { mapState } from "vuex";
import number_formatter from "../../../../helpers/formatters/number_formatter";

export default {
  name: "PortfoliosTab",
  data() {
    return {
      search_icon: SearchIcon,
      icon_caret: IconCaret,
      calendar_icon: calendarIcon,
      menu_icon: MenuOptions,
      edit_icon: EditWhiteIcon,
      trash_icon: TrashIcon,
      dollar_icon: Dollar,
      showCreateModal: false,
      is_edit_portfolio: false,
      columnHeaders: [
        {
          name: "name",
          title: "Name",
          align: "left",
          secondary_name: "token",
        },
        { name: "initial_balance", title: "Initial balance", align: "left" },
        { name: "current_balance", title: "Current balance", align: "left" },
        { name: "nav", title: "NAV", align: "left" },
        {
          title: "",
          action: true,
          id: "token",
        },
      ],
    };
  },
  components: {
    Multiselect,
    Table,
    Pagination,
    CreatePortfolioModal,
    ProcessingLoader,
    AlertErrorInformation,
    AlertNoInformation,
  },
  computed: {
    ...mapState("investor_configuration", ["show_portfolio_modal"]),
    ...mapState("portfolios", ["form_portfolio", "portfolio_list"]),
    portfolios() {
      return this.portfolio_list && this.portfolio_list._data
        ? this.portfolio_list._data.data
        : null;
    },
    paginate() {
      return this.portfolio_list && this.portfolio_list._data.paginate
        ? this.portfolio_list._data.paginate
        : {
            current_page: 1,
            per_page: 10,
            records: 0,
            total_pages: 0,
          };
    },
    portfolio_on_loading() {
      return this.portfolio_list._status._on_loading;
    },
    portfolios_status() {
      return this.portfolio_list && this.portfolio_list._data
        ? this.portfolio_list._data
        : [];
    },
  },
  methods: {
    formatter(number) {
      return number_formatter(number);
    },
    deletePortfolio(id) {
      const body = {
        token: id.id,
      };

      Swal.fire({
        title: "Are you sure?",
        input: "checkbox",
        inputValue: 0,
        inputPlaceholder: "Ignore transaction",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#1c2030",
        confirmButtonText: "Delete",
        preConfirm: async (value) => {
          body.ignore_transaction = value;
          await this.performDeletePortfolio(body);
        },
      }).then(async (result) => {
        if (result.data) {
        }
      });
    },
    editPortfolio(id) {
      this.$store.dispatch("connections/connection_list_selector");
      const data = this.portfolios.find(
        (portfolio) => portfolio.token == id.id
      );
      this.$store.commit("portfolios/set_creation_form", data);
      this.$store.commit(
        "portfolios/set_current_balance",
        data.current_balance
      );
      this.is_edit_portfolio = true;
      this.$store.commit("investor_configuration/show_portfolio_modal", true);
    },
    async performDeletePortfolio(body) {
      return new Promise((resolve) => {
        this.$store.dispatch("portfolios/delete_portfolio", {
          callback: ({ response }) => {
            if (response.error) {
              this.$toastr.error(`${response.message}`, `${this.error}`);
              return;
            } else {
              this.$toastr.success(
                "Portfolio deleted successfully.",
                `${this.success}`
              );
              this.$store.dispatch("portfolios/portfolio_list");
            }
            resolve();
          },
          body,
        });
      });
    },
    goFirstPage() {},
    goLastPage() {},
    goNextPage() {},
    goPrevPage() {},
  },
  mounted() {
    this.$store.dispatch("portfolios/portfolio_list");
  },
};
</script>
<style lang="scss">
@import "../../../../styles/pages/investor_configuration/portfolio_tab.scss";
</style>
