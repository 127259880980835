<template>
  <div
    ref="downloadReport"
    id="downloadReport"
    class="modal fade modal-download-report"
    tabindex="-1"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Download report</h5>
          <span class="view-logs-modal-close" @click="modal.hide()"
            ><img :src="close_icon"
          /></span>
        </div>
        <div class="modal-body">
          <AlertInformation
            :title="'Impact of Filters on the Report'"
            :description="'Please note that the report will reflect data affected by the selected filters.'"
          />

          <div class="container-form">
            <div class="form-group">
              <label>Select a date range</label>
              <BaseDateRangePicker
                style="width: 100%"
                v-model="dateRange"
                format="YYYY-MM-DD"
                :showLabel="false"
                :icon="calendar_icon"
                label="Date Range"
                :dateDefault="dateRange"
                placeholder="From - To"
                :timepicker="true"
                :everydayMinDate="everydayMinDate"
                :everydayMaxDate="everydayMaxDate"
              />
            </div>
          </div>

          <div
            class="form-check d-flex p-0 w-100 checkbox-container align-items-center"
          >
            <input class="cb-filter" type="checkbox" id="id" />
            <label class="label-checkbox w-100" for="id">
              Include solved incidents in the report.
            </label>
          </div>

          <div class="container-button">
            <button>
              <span><img :src="download_icon" alt="" /></span>
              <span>Download</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseDateRangePicker from "@/components/commons/BaseDateRangePicker";
import calendarIcon from "@/assets/images/calendar.svg";
import closeIcon from "../../../../assets/images/close.svg";
import DownloadIcon from "../../../../../public/common/download-white.png";
import { mapState } from "vuex";
import { Modal } from "bootstrap";
import $ from "jquery";
import AlertInformation from "../../../commons/alerts/AlertInformation.vue";

export default {
  name: "DownloadReport",
  components: { AlertInformation, BaseDateRangePicker },
  props: ["show"],
  data() {
    return {
      calendar_icon: calendarIcon,
      close_icon: closeIcon,
      dateRange: "",
      download_icon: DownloadIcon,
    };
  },
  mounted() {
    this.modal = new Modal(this.$refs.downloadReport);
    const self = this;
    $("#downloadReport").on("hide.bs.modal", function (event) {
      self.$emit("closed");
    });
  },

  computed: {},
  methods: {
    everydayMinDate() {
      return new Date();
    },
    everydayMaxDate() {
      return new Date();
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.modal.show();
      } else {
        this.modal.hide();
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../../styles/pages/health_audit/download_report.scss";
</style>
