<template>
  <div class="col-lg-10 py-4">
  	<div class="row mb-2">
  		<div class="col-md-12" v-if="loadingData && false">
  			Loading data....
  		</div>
  		<div class="col-3">
  			<label for="" class="d-block">Time Range</label>
  			<b-form-select v-model="selectedTimeRange" :options="timeRange"></b-form-select>
  		</div>
  		<div class="col-3">
  			<label for="" class="d-block">Time Frame</label>
  			<b-form-select v-model="selectedTimeFrame" :options="timeFrame"></b-form-select>
  		</div>
  	</div>
		<div id="chart-container">FusionCharts will render here</div>
	</div>
</template>
<script>
	import handleSocket from '@/helpers/socket';
	import TimeFramesMenu from './correlation-chart/TImeframesMenu.vue';
	import dataExample  from './testingData.js';
	import moment from 'moment';


	export default {
		name: "FunctionCharts",
		components: {},
		props: {},
		created() {
			window.__vue = this;
			/*this.createdChart(this.selectedSymbolsBinance);
			this.streamData['binance'] = dataExample;
			setTimeout(() => {
				this.updatedChartOptions();
			},2000);*/
		},
		data() {
			return {
      	url_websocket: 'stream_ticker',
      	randomString: "",
      	selectedSymbols: [],
      	selectedSymbolsBinance: ["binance-DOGEUSDT","binance-DOGEBTC"],
      	selectedSymbolsKuCoin: [],
      	selectedTimeRange: 5,
      	timeRange: [
      		{text: '1M', value: 1 },
      		{text: '5M', value: 5 },
      		{text: '10M', value: 10 },
      		{text: '30M', value: 30 },
      		{text: '1H', value: 60 },
      		{text: '6H', value: 360 },
      	],
      	selectedTimeFrame: 1,
      	timeFrame: [
      		{text: 'Bid ticks', value: 'bid_ticks' },
      		{text: '1M', value: 1 },
      		{text: '2M', value: 2 },
      		{text: '5M', value: 5 },
      		{text: '15M', value: 15 },
      		{text: '30M', value: 30 },
      		{text: '1H', value: 60 },
      		{text: '3H', value: 180 },
      		{text: '6H', value: 360 },
      	],
      	streamData: {
					"binance": {},
					"kucoin": {}
      	},
      	loadingData: false,
      	loadingUpdate: false,

      	lastClosetimeframe: 0,
      	lastLabel: "",

      	toTimeFrame: 0,
      	currentLabel: "",
			};
		},
		methods: {
			createdChart(symbols) {
				let opts =  {
					"chart": {
						"toolTipBorderColor": "#666666",
    				"toolTipBgColor": "#efefef",
    				"toolTipBgAlpha": "80",
    				"showToolTipShadow": "1",
    				"plotToolText": "Symbol: $seriesName{br}Correlation: $dataValue{br}Time: $label",

        		"caption": "Real-time",
        		"subCaption": "symbols correlation",
        		"xAxisName": "Symbols",
        		"yAxisName": "Symbols correlation",
        		"refreshinterval": "1",
        		
        		"setAdaptiveYMin": "1",

        		"numdisplaysets": parseInt(this.selectedTimeFrame == 'bid_ticks' ? 60 *  this.selectedTimeRange : this.selectedTimeRange / this.selectedTimeFrame),
        		"labeldisplay": "rotate",
        		"rotateLabels": "0",
        		"showRealTimeValue": "0",
        		
        		"decimals": "8",
        		"forceDecimals": "8",
    				"forceYAxisValueDecimals": "8",
    				"forceXAxisValueDecimals": "8",
        		
        		"theme": "fusion",

        		"drawAnchors": 1,
        		"showlabels": 0,

        		//Cosmetics
	          "paletteColors": "#0075c2,#1aaf5d",
	          "baseFontColor": "#333333",
	          "baseFont": "Helvetica Neue,Arial",
	          "captionFontSize": "14",
	          "subcaptionFontSize": "14",
	          "subcaptionFontBold": "0",
	          "showBorder": "0",
	          "bgColor": "#ffffff",
	          "showShadow": "0",
	          "usePlotGradientColor": "0",
	          "showPlotBorder": "0",
	          "canvasBgColor": "#ffffff",
	          "canvasBorderAlpha": "0",
	          "divlineAlpha": "100",
	          "divlineColor": "#999999",
	          "divlineThickness": "1",
	          "divLineIsDashed": "1",
	          "divLineDashLen": "1",
	          "divLineGapLen": "1",
	          "showXAxisLine": "1",
	          "xAxisLineThickness": "1",
	          "xAxisLineColor": "#999999",
	          "showAlternateHGridColor": "0",
	          "legendBgAlpha": "0",
	          "legendBorderAlpha": "0",
	          "legendShadow": "0",
	          "legendItemFontSize": "10",
	          "legendItemFontColor": "#666666",
		      },
		      "categories": [{"category": []}],
			    "dataset": []
				};

				for (let i = 0; i < symbols.length; i++) {
					let symbol = symbols[i];
					opts["categories"][0]["category"].push({"label": symbol});
					opts["dataset"].push({"seriesName": symbol, "data": []});
				}

				this.randomString = this.$helpers.generateRandomString(6);
				let randomString = this.randomString;
			
				FusionCharts.ready(function() {
		  		var stockPriceChart = new FusionCharts({
				    id: "stockRealTimeChart"+randomString,
				    type: 'realtimeline',
				    renderAt: 'chart-container',
				    width: '100%',
				    height: '400',
				    dataFormat: 'json',
				    dataSource: opts,
					  "events": {
					    "initialized": function(e) {}
					  }
					})
		    	.render();
				});
			},
			updatedChartData(label, values) {
				console.log('updatedChartData');
				if (values.length > 0) {
        	let strData = "&label="+label+"&value="+values.join("|")
					let chartRef = FusionCharts("stockRealTimeChart"+this.randomString);
					let currenData = chartRef.getData();
					let labeExist = currenData.find(i => i[0] == label);
					if (labeExist) {
						console.log("------ LabelExist");
						for (let i = 0; i < currenData.length; i++) {
							if (label === currenData[i][0]) {
								console.log("aqui esta el lebal existente");
								console.log("label ", label, " values " ,values);
								strData = "&label="+label+"&value="+values.join("|")
							} else {

								let oldData =  currenData[i];
								let oldLabel = oldData[0]
								let v = oldData.slice(1);
								if (oldLabel.indexOf("binance") >-1 || oldLabel.indexOf("kucoin") >-1 || oldLabel == "") {
									oldLabel = "";
									if (oldData.indexOf(null) > -1) {
										v = values;
									}
								}

								/*if (oldLabel.indexOf("binance") >-1 || oldLabel.indexOf("kucoin") >-1 || oldLabel=="") {
									strData = "&label="+oldLabel+"&value=undefined";
								} else {
								}*/
								strData = "&label="+oldLabel+"&value="+v.join("|");
							}
							console.log(strData);
      				chartRef.feedData(strData);
						}
					} else {
						strData = "&label="+label+"&value="+values.join("|")
      			chartRef.feedData(strData);
					}
        	// let strData = "&label="+label+"&value="+values.join("|")
      		// chartRef.feedData(strData);
      		window.chartRef = chartRef
				}
			},
			updatedChartOptions() {
				let chartRef = FusionCharts("stockRealTimeChart"+this.randomString);
      	window.chartRef = chartRef

				if (window.chartRef !== undefined) {
					this.loadingUpdate = true;

					let numdisplaysets = parseInt(this.selectedTimeFrame == 'bid_ticks' ? 60 * this.selectedTimeRange : this.selectedTimeRange / this.selectedTimeFrame);
	    		let opt = window.chartRef.options.dataSource;
	    		opt.chart.numdisplaysets = numdisplaysets;

					let symbolsBinance = Object.keys(this.streamData['binance']);
					let symbolsKucoin = Object.keys(this.streamData['kucoin']);
					let correlationValues = {};

					let timeStart = 0
					if (symbolsKucoin.length > 0) {
						timeStart = this.streamData['kucoin'][symbolsKucoin[0]][0][0];
					} else if (symbolsBinance.length > 0) {
						timeStart = this.streamData['binance'][symbolsBinance[0]][0][0];
					}

					if (timeStart === 0) {
						return;
						console.log("no hay datos");
					}


					let lasttime = 0;
					for (let i = 0; i < numdisplaysets; i++) {
						let label = "";
						if (i == 0) {
							// label = (new Date(timeStart)).toISOString().replace(/[A-Z]/g, " ").substr(0,19);
							label = (new Date(timeStart)).toISOString();
							lasttime = timeStart;
						} else {
							let fromTimeFrame = this.addMinutes(lasttime);
							lasttime = fromTimeFrame;
							// label = (new Date(lasttime)).toISOString().replace(/[A-Z]/g, " ").substr(0,19);
							label = (new Date(lasttime)).toISOString();
						}
						correlationValues[label] = [];
					}


					let keysTimeFrames = Object.keys(correlationValues);
					let exchanges = ['kucoin', 'binance'];

					for (let j = 0; j < exchanges.length; j++) {
						let exchange = exchanges[j];
						let symbols = Object.keys(this.streamData[exchange])
						for (let i = 0; i < keysTimeFrames.length; i++) {
							let fromYMD = keysTimeFrames[i];
							let from = (new Date(fromYMD)).getTime();
							let toYMD = "";
							let to = 0;
							let searchFromTo = false;

							if (i < keysTimeFrames.length - 1) {
								toYMD = keysTimeFrames[i+1];
								to = ( new Date(toYMD)).getTime();
								searchFromTo = true;
							}
							for (let j = 0; j < symbols.length; j++) {
								let currentSymbol = symbols[j];
								let valores = this.streamData[exchange][currentSymbol].filter(values => 	{
									if (searchFromTo){
										return values[0] >= from && values[0] <= to
									} else {
										return values[0] >= from;
									}
								});
								if (valores.length > 0) {
									if (!correlationValues[fromYMD]) {
										correlationValues[fromYMD] = [];
									}
									correlationValues[fromYMD].push(valores[valores.length - 1][1]);
								}
							}
						}
					}

					let timeFrames = Object.keys(correlationValues);
					window.chartRef.setJSONData(opt);
					let setEmptyValues = true;

					if (timeFrames.length > numdisplaysets) {
						setEmptyValues = false;
					}

					if (setEmptyValues) {
    				for (let i = 0; i < numdisplaysets - timeFrames.length; i++) {
    					let strDate = "label=&value=undefined|undefined";
	    				window.chartRef.feedData(strDate);
    				}
    			}
					
    			timeFrames = timeFrames.filter(key => correlationValues[key].length > 0);
					for (let i = 0; i < timeFrames.length; i++) {
						let timeframe = timeFrames[i];
						let label = timeframe.substr(0,19).replace(/[A-Z]/g, " ");

						if (correlationValues[timeframe].length > 0) {
							let strDate = "&label="+label+"&value="+correlationValues[timeframe].join("|");
		    			window.chartRef.feedData(strDate);
						}

		    		if (i == timeFrames.length - 1) {
	    				this.currentLabel = label;
	    				this.toTimeFrame = this.addMinutes((new Date(timeframe)).getTime());
	    				console.log(this.currentLabel, this.toTimeFrame);
	    			}
					}
	    		setTimeout(() => {
						this.loadingUpdate = false;
	    		}, 2000);
	    	}
			},
			// -- socket
			sendConnect() {
	      let kucoinSymbols = this.$store.state.symbols_correlator.filter((s) => s.exchange === 'kucoin').map((s) => s.symbol);
	      let binanceSymbols = this.$store.state.symbols_correlator.filter((s) => s.exchange === 'binance').map((s) => s.symbol);
      	this.selectedSymbols = [...kucoinSymbols, ...binanceSymbols];
      	this.selectedSymbolsBinance = binanceSymbols;
      	this.selectedSymbolsKuCoin = kucoinSymbols;
	      this.doSend(JSON.stringify(kucoinSymbols));
	      this.doSend(JSON.stringify(binanceSymbols));
	      this.doSend(this.$store.state.timeframe);

	      for (let i = 0; i < kucoinSymbols.length; i++) {
      		kucoinSymbols[i] = "kucoin-"+kucoinSymbols[i]
      	}
      	for (let i = 0; i < binanceSymbols.length; i++) {
      		binanceSymbols[i] = "binance-"+binanceSymbols[i]
      	}
      	let symbols = [...kucoinSymbols, ...binanceSymbols];
      	this.createdChart(symbols);
      	this.loadingData = true;
	    },
	    onOpen() {
	      if(this.$store.state.symbols_correlator.length > 0) {
	        let interval = setInterval(() => {
	          if (this.$store.state.symbols_correlator.length > 0) {
	            clearInterval(interval);
	              this.sendConnect();
	            }
	          }, 250);
	      } else {
	        this.sendConnect();
	      }
	    }, 
	    onClose(evt) {
	      this.writeToScreen("DISCONNECTED");
	    },
	    onMessage(evt) {
	      if (evt.data) {
	      	if (this.loadingUpdate) {
	      		return;
	      	}
	        let data = JSON.parse(evt.data)
	        let names = [];
	        let currentTimeServer = data['time'] * 1000;
	        let label = "";


	        // Indeces
	        let indexLabelTimeServe = 0;
	        let indexCurrentTimeServe = 1;
	        let indexNextTimeServe = 2;

	        if (data && data.bid_ticks) {
	        	let symbolKucoin = Object.keys(data.prices.kucoin);
	        	let streamDataBinance = data.prices.binance;
	        	let streamDataKucoin = data.prices.kucoin;
	        	let totalValuesBinance = 0;
	        	let totalValuesKucoin = 0;
	        	let label = "";
	        	
	        	for (let i = 0; i < this.selectedSymbolsBinance.length; i++) {
	        		let symbol = this.selectedSymbolsBinance[i];
	        		symbol = symbol.replace("binance-", "");
	        		if (streamDataBinance[symbol] && streamDataBinance[symbol].correlation > 0) {
	        			totalValuesBinance++;
	        		}
	        	}

	        	for (let i = 0; i < this.selectedSymbolsKuCoin.length; i++) {
	        		let symbol = this.selectedSymbolsKuCoin[i];
	        		symbol = symbol.replace("kucoin-", "");
	        		symbol = symbol.replace("-", "");
	        		if (streamDataKucoin[symbol] && streamDataKucoin[symbol].correlation > 0) {
	        			totalValuesKucoin++;
	        		}
	        	}
	        	let valuesCorrelation = [];


	        	if ((totalValuesBinance+totalValuesKucoin) != this.selectedSymbols.length) {
	        		return;
		        }
	        	

	        	console.log(new Date(currentTimeServer).toISOString().replace(/[A-Z]/g, " ")+" > "+new Date(this.toTimeFrame).toISOString().replace(/[A-Z]/g, " "));

	        	// datos de kucoin
	        	if (totalValuesKucoin == this.selectedSymbolsKuCoin.length) {
		        	for (let i = 0; i < this.selectedSymbolsKuCoin.length; i++) {
		        		let currentSymbol = this.selectedSymbolsKuCoin[i];
		        		currentSymbol = currentSymbol.replace("kucoin-", "");
		        		currentSymbol = currentSymbol.replace("-", "");
	        			let correlationValue = data.prices.kucoin[currentSymbol].correlation;
	        			valuesCorrelation.push(correlationValue);


	        			if (this.streamData['kucoin'][currentSymbol] == undefined) {
									this.streamData['kucoin'][currentSymbol] = [];	        				
	        			}

	        			if (this.streamData['kucoin'][currentSymbol].length > 0) {

	        				if (currentTimeServer >= this.toTimeFrame || this.selectedTimeFrame == 'bid_ticks') {
	        					let nextTimeServe = this.addMinutes(currentTimeServer);
	        					label = new Date(currentTimeServer).toISOString().replace(/[A-Z]/g, " ").substr(0,19)
	        					
	        					this.streamData['kucoin'][currentSymbol].push([
	        						currentTimeServer,
	        						correlationValue
	        					]);
	        					// this.lastClosetimeframe = nextTimeServe;
	        					this.toTimeFrame = nextTimeServe;
	        					this.currentLabel = label;
	        				} else {
        						// label = new Date(this.streamData['kucoin'][currentSymbol][lastIndex][0]).toISOString().replace(/[A-Z]/g, " ").substr(0,19);
        						label = this.currentLabel;
	        					this.streamData['kucoin'][currentSymbol].push([
	        						currentTimeServer, // Fecha para el label(fecha de apertura del timeframe)
	        						correlationValue // valor para la correlacion
	        					]);
	        				}
	        			} else {
	        				let nextTimeServe = this.addMinutes(currentTimeServer);
	        				label = new Date(currentTimeServer).toISOString().replace(/[A-Z]/g, " ").substr(0,19)
	        				this.toTimeFrame = nextTimeServe;
	        				this.currentLabel = label;
	        				this.streamData['kucoin'][currentSymbol].push([
	        					currentTimeServer, // Fecha para el label (fecha de apertura del timeframe)
	        					correlationValue // valor para la correlacion
	        				]);
	        			}
		        	}
		        }
	        	
	        	// datos de binances
	        	if (totalValuesBinance == this.selectedSymbolsBinance.length) {
		        	for (let i = 0; i < this.selectedSymbolsBinance.length; i++) {
		        		let currentSymbol = this.selectedSymbolsBinance[i];
		        		currentSymbol = currentSymbol.replace("binance-", "");
	        			let correlationValue = data.prices.binance[currentSymbol].correlation;
	        			valuesCorrelation.push(correlationValue);


	        			if (this.streamData['binance'][currentSymbol] == undefined) {
									this.streamData['binance'][currentSymbol] = [];	        				
	        			}

	        			if (this.streamData['binance'][currentSymbol].length > 0) {
	        				if (currentTimeServer >= this.toTimeFrame || this.selectedTimeFrame == 'bid_ticks') {
	        					let nextTimeServe = this.addMinutes(currentTimeServer);
	        					label = new Date(currentTimeServer).toISOString().replace(/[A-Z]/g, " ").substr(0,19)
	        					
	        					this.streamData['binance'][currentSymbol].push([
	        						currentTimeServer,
	        						correlationValue
	        					]);

	        					this.toTimeFrame = nextTimeServe;
	        					this.currentLabel = label;
	        				} else {
	        					console.log("no es mayor que el actual");
        						label = this.currentLabel;
	        					console.log(label, valuesCorrelation);
	        					this.streamData['binance'][currentSymbol].push([
	        						currentTimeServer, // Fecha para el label(fecha de apertura del timeframe)
	        						correlationValue // valor para la correlacion
	        					]);
	        				}
	        			} else {
	        				let nextTimeServe = this.addMinutes(currentTimeServer);
	        				label = new Date(currentTimeServer).toISOString().replace(/[A-Z]/g, " ").substr(0,19)
	        				
	        				this.toTimeFrame = nextTimeServe;
	        				this.currentLabel = label;

	        				this.streamData['binance'][currentSymbol].push([
	        					currentTimeServer, // Fecha para el label (fecha de apertura del timeframe)
	        					correlationValue // valor para la correlacion
	        				]);
	        			}
		        	}
		        }

		        if ((totalValuesBinance+totalValuesKucoin) == this.selectedSymbols.length) {
	        		this.loadingData = false;
	        		this.updatedChartData(label, valuesCorrelation);
		        }
	        }
	      }
	    },
	    onError(evt) {
	      console.log(evt);
	    },
	    doSend(message) {
	      this.websocket.send(message);
	    },
	    writeToScreen(message) {
	      console.log("writeToScreen", message);
	    },
	    addMinutes(time) {
	    	if (this.selectedTimeFrame == 'bid_ticks') {
			  	return time;
	    	}
			  return time + (this.selectedTimeFrame*60000);
			}
		},
		watch: {
	    '$store.state.add_symbol_correlator'(value) {
	      if (value) {
	        const symbols = this.$store.state.symbols_correlator;
	        for (let i = 0; i < symbols.length; i++) {
	          let symbol = symbols[i].symbol;
	          if (symbols[i].exchange === 'kucoin') symbol = symbol.split('-').join('')
	        }
	        this.$store.commit('set_add_symbol_correlator', false);
	      }
	    },
	    '$store.state.remove_symbol_correlator'(value) {
	      if(value) {
	        const symbols = this.$store.state.symbols_correlator;
	        let index = 0;
	        this.$store.commit('set_remove_symbol_correlator', false);
	      }
	    },
	    '$store.state.make_correlation'(value) {
	      if (value) {
	        if (this.$route.name.toLowerCase() == 'correlator') {
	          this.websocket = handleSocket(this.url_websocket, this.onOpen, this.onClose, this.onMessage, this.onError);
	        }
	      } else {
	        if(this.websocket) {
	          this.websocket.close();
	        }
	      }
	    },
	    '$store.state.timeframe_clicked'(value) {
	      if (value) {
	        this.chartOptions.xaxis.range = this.DateTimeRange;
	        this.$store.commit('set_timeframe_clicked', false);
	      }
	    },
	    selectedTimeFrame() {
	    	this.updatedChartOptions();
	    },
	    selectedTimeRange() {
	    	this.updatedChartOptions();
	    }
	  },
	}
</script>
