import CryptoJS from "react-native-crypto-js";
let Crypto = {
  encrypt (data) {
    let key = localStorage.getItem('key');
    if (!key) {
    	key = 'IQYYzgDfVkt38s6e7ox5xMgZ7sGkOA';
    }
    return CryptoJS.AES.encrypt(data, key).toString()
  },
  decrypt (data) {
  	let key = localStorage.getItem('key');
    if (!key) {
    	key = 'IQYYzgDfVkt38s6e7ox5xMgZ7sGkOA';
    }
    var r = CryptoJS.AES.decrypt(data, key)
    var r = r.toString(CryptoJS.enc.Utf8)
    return r
  }
}
export default Crypto;