<template>
  <div :class="`component_template__condition_generator`">
    <div class="row pxr-2 conditions">
      <div class="col-md-3 mb-2 pxr-2">
        <label v-if="showSignal" class="title_">Signal conditions</label>
        <b-list-group v-if="showSignal">
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.signal_condition.ema"
              :disabled="disabled"
            >
              EMA
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.signal_condition.condition_001"
              :disabled="disabled"
            >
              BTC IN_OUT
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.signal_condition.condition_002"
              :disabled="disabled"
            >
              BTC IN/OUT
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.signal_condition.condition_003"
              :disabled="disabled"
            >
              AVG IN/OUT
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.signal_condition.condition_004"
              :disabled="disabled"
            >
              TICKS IN/OUT
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.signal_condition.condition_clients_short"
              :disabled="disabled"
            >
              C.C. - Short
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.signal_condition.condition_clients_medium"
              :disabled="disabled"
            >
              C.C. - Medium
            </b-form-checkbox>
          </b-list-group-item>
        </b-list-group>

        <label class="title_ mt-3">Open conditions</label>
        <b-list-group>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.open_condition.max_time_to_wait"
              :disabled="true"
            >
              Max time to wait
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.open_condition.open_trailing"
              :disabled="disabled"
            >
              Open trailing
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.open_condition.micro_pump"
              :disabled="disabled"
            >
              Micro pump
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.open_condition.max_min_trigger"
              :disabled="disabled"
            >
              Max/min trigger
            </b-form-checkbox>
            <b-list-group-item style="border: 0px; margin-top: -8px">
              <b-form-checkbox
                v-model="condition.open_condition.max_min_trigger.min_is_active"
                :disabled="disabled"
              >
                Min trigger
              </b-form-checkbox>
              <b-form-checkbox
                v-model="condition.open_condition.max_min_trigger.max_is_active"
                :disabled="disabled"
                style="margin-top: 2px"
              >
                Max trigger
              </b-form-checkbox>
            </b-list-group-item>
          </b-list-group-item>
        </b-list-group>

        <label class="title_ mt-3">Close conditions</label>
        <b-list-group>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.close_condition.take_profit"
              :disabled="true"
            >
              Take profit
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.close_condition.stop_loss"
              :disabled="true"
            >
              Stop loss
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.close_condition.trailing_activation"
              :disabled="true"
            >
              Trailing activation
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.close_condition.trailing_step"
              :disabled="true"
            >
              Trailing step
            </b-form-checkbox>
          </b-list-group-item>
        </b-list-group>

        <label class="title_ mt-3">
          <b-form-checkbox
            v-model="options.close_condition_dynamic.active"
            :disabled="disabled"
          >
            Close conditions (Dynamic)
          </b-form-checkbox>
        </label>
        <b-list-group>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.close_condition_dynamic.activation_percentage"
              :disabled="true"
            >
              Activation percentage
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.close_condition_dynamic.max_open_time"
              :disabled="true"
            >
              Max open time
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.close_condition_dynamic.ema"
              :disabled="disabled"
            >
              EMA
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.close_condition_dynamic.condition_001"
              :disabled="disabled"
            >
              BTC IN_OUT
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.close_condition_dynamic.condition_002"
              :disabled="disabled"
            >
              BTC IN/OUT
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.close_condition_dynamic.condition_003"
              :disabled="disabled"
            >
              AVG IN/OUT
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.close_condition_dynamic.condition_004"
              :disabled="disabled"
            >
              TICKS IN/OUT
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.close_condition_dynamic.condition_clients_short"
              :disabled="disabled"
            >
              C.C. - Short
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-checkbox
              v-model="options.close_condition_dynamic.condition_clients_medium"
              :disabled="disabled"
            >
              C.C. - Medium
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item v-if="is_investor">
            <b-form-checkbox
              v-model="options.close_condition_dynamic.ema_trailing"
              :disabled="disabled"
            >
              Ema trailing
            </b-form-checkbox>
          </b-list-group-item>
        </b-list-group>
      </div>
      <div class="col-md-9 pxr-2">
        <div class="row">
          <div class="col-md-12">
            <label class="title_">Current configuration</label>
          </div>
        </div>

        <div class="row" v-if="is_investor && showSignal">
          <div class="col-md-12">
            <label class="title_"> Investor configuration </label>
          </div>
        </div>

        <div class="row pxr-2" v-if="is_investor && showSignal">
          <div class="col-md-3 mb-2 pxr-2">
            <div class="content p-2 item-active-config">
              <label>Symbol</label>
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    type="text"
                    class="form-control"
                    :disabled="disabled || tabSell"
                    v-model="symbol"
                    id="investor_configuration_symbol"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 mb-2 pxr-2">
            <div class="content p-2 item-active-config">
              <label>Client confirmation</label>
              <input
                type="number"
                pattern="[0-9]"
                min="0"
                max="999999999"
                class="form-control"
                v-model="client_confirmation"
                :disabled="disabled"
                id="client_confirmation"
              />
            </div>
          </div>
          <div class="col-md-3 mb-2 pxr-2">
            <div class="content p-2 item-active-config">
              <label>Investment minimun</label>
              <input
                type="number"
                pattern="[0-9]"
                min="15"
                max="999999999"
                class="form-control"
                v-model="investment_minimun"
                :disabled="disabled"
                id="investment_minimun"
              />
            </div>
          </div>
        </div>
        <div class="row" v-if="is_investor && showSignal">
          <div class="col-md-12">
            <label class="title_"> Timeframe configuration </label>
          </div>
        </div>

        <div class="row pxr-2" v-if="is_investor && showSignal">
          <div class="col-md-2 mb-2 pxr-2">
            <div class="content p-2 item-active-config">
              <label>Short</label>
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    type="number"
                    pattern="[0-9]"
                    ref="short"
                    min="0"
                    max="999999999"
                    class="form-control"
                    v-model="timeframes.short"
                    :disabled="disabled"
                    id="timeframe_configuration_short"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2 mb-2 pxr-2">
            <div class="content p-2 item-active-config">
              <label>Medium</label>
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    type="number"
                    pattern="[0-9]"
                    ref="medium"
                    min="0"
                    max="999999999"
                    class="form-control"
                    v-model="timeframes.medium"
                    :disabled="disabled"
                    id="timeframe_configuration_mediun"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2 mb-2 pxr-2">
            <div id="long-x" class="content p-2 item-active-config">
              <label>Long</label>
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    type="number"
                    pattern="[0-9]"
                    ref="long"
                    min="0"
                    max="999999999"
                    class="form-control"
                    value="1440"
                    v-model="timeframes.long"
                    :disabled="disabled"
                    id="timeframe_configuration_long"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2 mb-2 pxr-2">
            <div id="mega-long-x" class="content p-2 item-active-config">
              <label id="mega-long-label-x">Mega Long</label>
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    type="number"
                    pattern="[0-9]"
                    ref="mega_long"
                    min="0"
                    max="999999999"
                    class="form-control"
                    value="1440"
                    v-model="timeframes.mega_long"
                    :disabled="disabled"
                    id="timeframe_configuration_mega_long"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="showBaseConfiguration">
          <div class="col-md-12">
            <label class="title_"> Base configuration </label>
          </div>
        </div>

        <div class="row pxr-2" v-if="showBaseConfiguration">
          <div class="col-md-3 mb-2 pxr-2" v-if="showBalances">
            <div class="content p-2 item-active-config">
              <label>Balance</label>
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    type="text"
                    class="form-control"
                    v-model="condition.base_configuration.balance"
                    :disabled="disabled || tabSell"
                    id="base_configuration_balance"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 mb-2 pxr-2">
            <div class="content p-2 item-active-config">
              <label>Investment maximum</label>
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    type="text"
                    class="form-control"
                    v-model="condition.base_configuration.investment_maximum"
                    :disabled="disabled || tabSell"
                    id="base_configuration_investment_maximun"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 mb-2 pxr-2">
            <div class="content p-2 item-active-config">
              <label>Type</label>
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <select
                    :id="!$props.tabSell ? 'type_buy' : 'type_sell'"
                    class="form-control"
                    v-model="condition.base_configuration.type"
                    :disabled="disabled || tabSell"
                    id="base_configuration_type"
                  >
                    <option value="buy">Buy</option>
                    <option value="sell">Sell</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 mb-2 pxr-2">
            <div class="content p-2 item-active-config">
              <label>Loting <span class="small text-muted">(%)</span></label>
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    type="text"
                    max="100"
                    min="0"
                    step="1"
                    class="form-control"
                    v-model.number="condition.base_configuration.lot"
                    :disabled="disabled || tabSell"
                    id="base_configuration_loting"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3 mb-2 pxr-2">
            <div class="content p-2 item-active-config">
              <label
                v-b-tooltip.hover
                title="Maximum orders open at the same time"
                >Orders (M)</label
              >
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    type="text"
                    value="5"
                    class="form-control"
                    v-model.number="condition.base_configuration.orders_maximum"
                    :disabled="disabled || tabSell"
                    id="base_configuration_order_m"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 mb-2 pxr-2">
            <div class="content p-2 item-active-config">
              <label
                v-b-tooltip.hover
                title="Waiting time for opening between orders"
                >Orders (TTM) <span class="text-muted small">(Minutes)</span>
              </label>
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    type="text"
                    value="60"
                    class="form-control"
                    v-model="
                      condition.base_configuration
                        .orders_time_to_wait_for_new_orders
                    "
                    :disabled="disabled || tabSell"
                    id="base_configuration_ttm"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3 mb-2 pxr-2">
            <div class="content p-2 item-active-config">
              <label>Invert orders</label>
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <select
                    class="form-control"
                    v-model="condition.base_configuration.invert_orders"
                    :disabled="disabled || tabSell"
                    id="base_configuration_invert_orders"
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 mb-2 pxr-2">
            <div class="content p-2 item-active-config">
              <label>Comission</label>
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    type="text"
                    class="form-control"
                    v-model.number="condition.base_configuration.comission"
                    :disabled="disabled || tabSell"
                    id="base_configuration_conmission"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 mb-2 pxr-2">
            <div class="content p-2 item-active-config">
              <label>Laverage</label>
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    type="text"
                    class="form-control"
                    v-model.number="condition.base_configuration.laverage"
                    :disabled="disabled || tabSell || isSpot"
                    id="base_configuration_laverage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="showSignal">
          <div class="col-md-12">
            <label class="title_"> Compound configuration </label>
          </div>
        </div>

        <div class="row pxr-2" v-if="showSignal">
          <div class="col-md-3 mb-2 pxr-2">
            <div class="content p-2 item-active-config">
              <label>Is compound</label>
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <select
                    class="form-control"
                    v-model="condition.base_configuration.is_compound"
                    :disabled="disabled || tabSell"
                    id="base_configuration_compound"
                  >
                    <option value="false">No</option>
                    <option value="method 1">Method 1</option>
                    <option value="method 2">Method 2</option>
                    <option value="method 3">Method 3</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 mb-2 pxr-2"
            v-show="
              (condition.base_configuration.is_compound === 'method 1' ||
                condition.base_configuration.is_compound === 'method 2') &&
              condition.base_configuration.configuration_compound
            "
          >
            <div class="content p-2 item-active-config">
              <label>Priority</label>
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <select
                    :id="!$props.tabSell ? 'priority_buy' : 'priority_sell'"
                    class="form-control"
                    v-model="
                      condition.base_configuration.configuration_compound
                        .priority
                    "
                    :disabled="disabled || tabSell"
                    id="base_configuration_priority"
                  >
                    <option value="buy">Buy</option>
                    <option value="sell">Sell</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 mb-2 pxr-2"
            v-show="condition.base_configuration.is_compound === 'method 2'"
          >
            <div class="content p-2 item-active-config">
              <label>Max time to wait</label>
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    :id="
                      !$props.tabSell
                        ? 'configuration_compound-max_time_to_wait_buy'
                        : 'configuration_compound-max_time_to_wait_sell'
                    "
                    type="text"
                    class="form-control"
                    pattern="[0-9]"
                    v-model="
                      condition.base_configuration.configuration_compound
                        .max_time_to_wait
                    "
                    :disabled="disabled || tabSell"
                    id="base_configuration_max_time_to_wait"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="showSignalCondition">
          <div class="col-md-12">
            <label class="title_"> Signal conditions </label>
          </div>
        </div>
        <div class="row pxr-2">
          <div class="col-md-3 mb-2 pxr-2" v-if="options.signal_condition.ema">
            <div class="content p-2 item-active-config">
              <label>EMA</label>
              <div class="row mb-2 pxr-2" style="margin-top: -10px">
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Period</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model.number="condition.signal_condition.ema.period"
                    :disabled="disabled"
                    id="signal_configuration_ema_period"
                  />
                </div>
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Timeframe</label>
                  <select
                    class="form-control"
                    v-model.number="condition.signal_condition.ema.timeframe"
                    :disabled="disabled"
                    id="signal_configuration_ema_timeframe"
                  >
                    <option value="short">Short</option>
                    <option value="medium">Medium</option>
                    <option value="long">Long</option>
                    <option value="mega_long">Mega-Long</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 mb-2 pxr-2"
            v-if="options.signal_condition.condition_001"
          >
            <div class="content p-2 item-active-config">
              <label>BTC IN_OUT</label>
              <div class="row mb-2 pxr-2" style="margin-top: -10px">
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Divisor</label>
                  <input
                    type="text"
                    value="24"
                    class="form-control"
                    v-model="condition.signal_condition.condition_001.divisor"
                    :disabled="disabled"
                    id="signal_configuration_btc_in_out_divisor"
                  />
                </div>
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Multiplicator</label>
                  <input
                    type="text"
                    value="1.8"
                    class="form-control"
                    v-model="
                      condition.signal_condition.condition_001.multiplicator
                    "
                    :disabled="disabled"
                    id="signal_configuration_btc_in_out_multiplicador"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 mb-2 pxr-2"
            v-if="options.signal_condition.condition_002"
          >
            <div class="content p-2 item-active-config">
              <label>BTC in/out.</label>
              <div class="row mb-2 pxr-2" style="margin-top: -10px">
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Divisor</label>
                  <input
                    type="text"
                    value="24"
                    class="form-control"
                    v-model="condition.signal_condition.condition_002.divisor"
                    :disabled="disabled"
                    id="signal_configuration_btc_inout_divisor"
                  />
                </div>
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Multiplicator</label>
                  <input
                    type="text"
                    value="1.8"
                    class="form-control"
                    v-model="
                      condition.signal_condition.condition_002.multiplicator
                    "
                    :disabled="disabled"
                    id="signal_configuration_btc_inout_multiplicador"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 mb-2 pxr-2"
            v-if="options.signal_condition.condition_003"
          >
            <div class="content p-2 item-active-config">
              <label>AVG in/out.</label>
              <div class="row mb-2 pxr-2" style="margin-top: -10px">
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Divisor</label>
                  <input
                    type="text"
                    value="24"
                    class="form-control"
                    v-model="condition.signal_condition.condition_003.divisor"
                    :disabled="disabled"
                    id="signal_configuration_avg_in_out_divisor"
                  />
                </div>
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Multiplicator</label>
                  <input
                    type="text"
                    value="1.8"
                    class="form-control"
                    v-model="
                      condition.signal_condition.condition_003.multiplicator
                    "
                    :disabled="disabled"
                    id="signal_configuration_avg_in_out_multiplicator"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 mb-2 pxr-2"
            v-if="options.signal_condition.condition_004"
          >
            <div class="content p-2 item-active-config">
              <label>TICKS in/out.</label>
              <div class="row mb-2 pxr-2" style="margin-top: -10px">
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Divisor</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="condition.signal_condition.condition_004.divisor"
                    :disabled="disabled"
                    id="signal_configuration_ticks_in_out_divisor"
                  />
                </div>
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Multiplicator</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      condition.signal_condition.condition_004.multiplicator
                    "
                    :disabled="disabled"
                    id="signal_configuration_ticks_in_out_multiplicador"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-md-3 mb-2 pxr-2"
            v-if="options.signal_condition.condition_clients_short"
          >
            <div class="content p-2 item-active-config">
              <label>C.C. - SHORT.</label>
              <div class="row mb-2 pxr-2" style="margin-top: -10px">
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Validator</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      condition.signal_condition.condition_clients_short
                        .validator
                    "
                    :disabled="disabled"
                    id="signal_configuration_cc_short_validator"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 mb-2 pxr-2"
            v-if="options.signal_condition.condition_clients_medium"
          >
            <div class="content p-2 item-active-config">
              <label>C.C. - MEDIUM.</label>
              <div class="row mb-2 pxr-2" style="margin-top: -10px">
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Validator</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      condition.signal_condition.condition_clients_medium
                        .validator
                    "
                    :disabled="disabled"
                    id="signal_configuration_cc_medium_validator"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="showOpenCondition">
          <div class="col-md-12">
            <label class="title_"> Open condition </label>
          </div>
        </div>

        <div class="row pxr-2">
          <div
            class="col-md-2 mb-2 pxr-2"
            v-if="options.open_condition.max_time_to_wait"
          >
            <div class="content p-2 item-active-config">
              <label>Max time to wait</label>
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    type="text"
                    class="form-control"
                    v-model="condition.open_condition.max_time_to_wait"
                    :disabled="disabled"
                    id="open_configuration_max_time_to_wait"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-2 mb-2 pxr-2"
            v-if="options.open_condition.open_trailing"
          >
            <div class="content p-2 item-active-config">
              <label>Open Trailing (%)</label>
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    type="text"
                    class="form-control"
                    v-model="condition.open_condition.open_trailing"
                    :disabled="disabled"
                    id="open_configuration_open_trailing"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 mb-2 pxr-2"
            v-if="options.open_condition.micro_pump"
          >
            <div class="content p-2 item-active-config">
              <label>Micro Pump (%)</label>
              <div class="row mb-2 pxr-2" style="margin-top: -10px">
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Movement</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      condition.open_condition.micro_pump.movement_percentage
                    "
                    :disabled="disabled"
                    id="open_configuration_micro_pump_movement"
                  />
                </div>
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Time</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      condition.open_condition.micro_pump.time_to_validate
                    "
                    :disabled="disabled"
                    id="open_configuration_micro_pump_time"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-4 mb-2 pxr-2"
            v-if="options.open_condition.max_min_trigger"
          >
            <div class="content p-2 item-active-config">
              <label>Max/min trigger</label>
              <div class="row mb-2 pxr-2" style="margin-top: -10px">
                <div class="col-md-4 pxr-2">
                  <label class="small text-muted">Timeframe</label>
                  <select
                    class="form-control"
                    v-model.number="
                      condition.open_condition.max_min_trigger.timeframe
                    "
                    :disabled="disabled"
                    id="open_configuration_max_min_trigger"
                  >
                    <option value="short">Short</option>
                    <option value="medium">Medium</option>
                    <option value="long">Long</option>
                  </select>
                </div>
                <div
                  class="col-md-4 pxr-2"
                  v-if="condition.open_condition.max_min_trigger.min_is_active"
                >
                  <label class="small text-muted">Min avg (%)</label>
                  <input
                    type="text"
                    value="1.1"
                    class="form-control"
                    v-model="condition.open_condition.max_min_trigger.min_avg"
                    :disabled="disabled"
                    id="open_configuration_max_min_trigger_min"
                  />
                </div>
                <div
                  class="col-md-4 pxr-2"
                  v-if="condition.open_condition.max_min_trigger.max_is_active"
                >
                  <label class="small text-muted">Max avg (%)</label>
                  <input
                    type="text"
                    value="1.1"
                    class="form-control"
                    v-model="condition.open_condition.max_min_trigger.max_avg"
                    :disabled="disabled"
                    id="open_configuration_max_min_trigger_max"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label class="title_">Close conditions</label>
          </div>
        </div>
        <div class="row pxr-2">
          <div
            class="col-md-3 mb-2 pxr-2"
            v-if="options.close_condition.take_profit"
          >
            <div class="content p-2 item-active-config">
              <label
                >Take profit <span class="small text-muted">(%)</span></label
              >
              <div class="row mb-2 pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    type="text"
                    class="form-control"
                    v-model.number="condition.close_condition.take_profit"
                    :disabled="disabled"
                    id="close_configuration_take_profit"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 mb-2 pxr-2"
            v-if="options.close_condition.stop_loss"
          >
            <div class="content p-2 item-active-config">
              <label>Stop loss <span class="small text-muted">(%)</span></label>
              <div class="row mb-2 pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    type="text"
                    class="form-control"
                    v-model.number="condition.close_condition.stop_loss"
                    :disabled="disabled"
                    id="close_configuration_stop_loss"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 mb-2 pxr-2"
            v-if="options.close_condition.trailing_activation"
          >
            <div class="content p-2 item-active-config">
              <label
                >Trailing Activation
                <span class="small text-muted">(%)</span></label
              >
              <div class="row mb-2 pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    type="text"
                    class="form-control"
                    v-model.number="
                      condition.close_condition.trailing_activation
                    "
                    :disabled="disabled"
                    id="close_configuration_trailing_activation"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 mb-2 pxr-2">
            <div
              class="content p-2 item-active-config"
              v-if="options.close_condition.trailing_step"
            >
              <label
                >Trailing Step <span class="small text-muted">(%)</span></label
              >
              <div class="row mb-2 pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    type="text"
                    class="form-control"
                    v-model.number="condition.close_condition.trailing_step"
                    :disabled="disabled"
                    id="close_configuration_trailing_step"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="showCloseConditionDynamic">
          <div class="col-md-12">
            <label class="title_"> CLOSE CONDITIONS (DYNAMIC) </label>
          </div>
        </div>
        <div class="row pxr-2">
          <div
            class="col-md-2 mb-2 pxr-2"
            v-if="options.close_condition_dynamic.activation_percentage"
          >
            <div class="content p-2 item-active-config">
              <label>Activation percentage</label>
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      condition.close_condition_dynamic.activation_percentage
                    "
                    :disabled="disabled"
                    id="close_dynamic_configuration_activation_percentage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-2 mb-2 pxr-2"
            v-if="options.close_condition_dynamic.max_open_time"
          >
            <div class="content p-2 item-active-config">
              <label>Max open time</label>
              <div class="row pxr-2" style="margin-top: 0px">
                <div class="col-md-12 pxr-2">
                  <input
                    type="text"
                    class="form-control"
                    v-model="condition.close_condition_dynamic.max_open_time"
                    :disabled="disabled"
                    id="close_dynamic_configuration_max_open_time"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-md-3 mb-2 pxr-2"
            v-if="options.close_condition_dynamic.ema"
          >
            <div class="content p-2 item-active-config">
              <label>EMA</label>
              <div class="row mb-2 pxr-2" style="margin-top: -10px">
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Period</label>
                  <input
                    type="text"
                    value="10"
                    class="form-control"
                    v-model.number="
                      condition.close_condition_dynamic.ema.period
                    "
                    :disabled="disabled"
                    id="close_dynamic_configuration_ema_period"
                  />
                </div>
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Timeframe</label>
                  <select
                    class="form-control"
                    v-model.number="
                      condition.close_condition_dynamic.ema.timeframe
                    "
                    :disabled="disabled"
                    id="close_dynamic_configuration_timeframe"
                  >
                    <option value="short">Short</option>
                    <option value="medium">Medium</option>
                    <option value="long">Long</option>
                    <option value="mega_long">Mega-Long</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 mb-2 pxr-2"
            v-if="options.close_condition_dynamic.condition_001"
          >
            <div class="content p-2 item-active-config">
              <label>BTC IN_OUT</label>
              <div class="row mb-2 pxr-2" style="margin-top: -10px">
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Divisor</label>
                  <input
                    type="text"
                    value="24"
                    class="form-control"
                    v-model="
                      condition.close_condition_dynamic.condition_001.divisor
                    "
                    :disabled="disabled"
                    id="close_dynamic_configuration_condition001_divisor"
                  />
                </div>
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Multiplicator</label>
                  <input
                    type="text"
                    value="1.8"
                    class="form-control"
                    v-model="
                      condition.close_condition_dynamic.condition_001
                        .multiplicator
                    "
                    :disabled="disabled"
                    id="close_dynamic_configuration_condition001_multiplicator"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 mb-2 pxr-2"
            v-if="options.close_condition_dynamic.condition_002"
          >
            <div class="content p-2 item-active-config">
              <label>BTC in/out.</label>
              <div class="row mb-2 pxr-2" style="margin-top: -10px">
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Divisor</label>
                  <input
                    type="text"
                    value="24"
                    class="form-control"
                    v-model="
                      condition.close_condition_dynamic.condition_002.divisor
                    "
                    :disabled="disabled"
                    id="close_dynamic_configuration_condition002_divisor"
                  />
                </div>
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Multiplicator</label>
                  <input
                    type="text"
                    value="1.8"
                    class="form-control"
                    v-model="
                      condition.close_condition_dynamic.condition_002
                        .multiplicator
                    "
                    :disabled="disabled"
                    id="close_dynamic_configuration_condition002_multiplicator"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 mb-2 pxr-2"
            v-if="options.close_condition_dynamic.condition_003"
          >
            <div class="content p-2 item-active-config">
              <label>AVG in/out.</label>
              <div class="row mb-2 pxr-2" style="margin-top: -10px">
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Divisor</label>
                  <input
                    type="text"
                    value="24"
                    class="form-control"
                    v-model="
                      condition.close_condition_dynamic.condition_003.divisor
                    "
                    :disabled="disabled"
                    id="close_dynamic_configuration_condition003_divisor"
                  />
                </div>
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Multiplicator</label>
                  <input
                    type="text"
                    value="1.8"
                    class="form-control"
                    v-model="
                      condition.close_condition_dynamic.condition_003
                        .multiplicator
                    "
                    :disabled="disabled"
                    id="close_dynamic_configuration_condition003_multiplicador"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 mb-2 pxr-2"
            v-if="options.close_condition_dynamic.condition_004"
          >
            <div class="content p-2 item-active-config">
              <label>TICKS in/out.</label>
              <div class="row mb-2 pxr-2" style="margin-top: -10px">
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Divisor</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      condition.close_condition_dynamic.condition_004.divisor
                    "
                    :disabled="disabled"
                    id="close_dynamic_configuration_condition004_divisor"
                  />
                </div>
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Multiplicator</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      condition.close_condition_dynamic.condition_004
                        .multiplicator
                    "
                    :disabled="disabled"
                    id="close_dynamic_configuration_condition004_multiplicator"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 mb-2 pxr-2"
            v-if="options.close_condition_dynamic.condition_clients_short"
          >
            <div class="content p-2 item-active-config">
              <label>C.C. - SHORT.</label>
              <div class="row mb-2 pxr-2" style="margin-top: -10px">
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Validator</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      condition.close_condition_dynamic.condition_clients_short
                        .validator
                    "
                    :disabled="disabled"
                    id="close_dynamic_configuration_client_short"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 mb-2 pxr-2"
            v-if="options.close_condition_dynamic.condition_clients_medium"
          >
            <div class="content p-2 item-active-config">
              <label>C.C. - MEDIUM.</label>
              <div class="row mb-2 pxr-2" style="margin-top: -10px">
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Validator</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      condition.close_condition_dynamic.condition_clients_medium
                        .validator
                    "
                    :disabled="disabled"
                    id="close_dynamic_configuration_client_medium"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 mb-2 pxr-2"
            v-if="options.close_condition_dynamic.ema_trailing"
          >
            <div class="content p-2 item-active-config">
              <label>Trailing EMA</label>
              <div class="row mb-2 pxr-2" style="margin-top: -10px">
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Period</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model.number="
                      condition.close_condition_dynamic.ema_trailing.period
                    "
                    :disabled="disabled"
                    id="close_dynamic_configuration_ema_trailing_period"
                  />
                </div>
                <div class="col-md-6 pxr-2">
                  <label class="small text-muted">Timeframe</label>
                  <select
                    class="form-control"
                    v-model.number="
                      condition.close_condition_dynamic.ema_trailing.timeframe
                    "
                    :disabled="disabled"
                    id="close_dynamic_configuration_ema_trailing_timeframe"
                  >
                    <option value="short">Short</option>
                    <option value="medium">Medium</option>
                    <option value="long">Long</option>
                    <option value="mega_long">Mega-Long</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <label class="title_"> NOTES </label>
            <textarea
              class="form-control"
              v-model="condition.base_configuration.note"
              :disabled="disabled"
              id="conditions_notes"
            ></textarea>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <label class="title_"> Resumen </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <input
              type="text"
              class="form-control d-block mb-2"
              v-model="filterValue"
              placeholder="Filter condition..."
              :id="`filterValue${$props.tabSell ? 'Sell' : 'Buy'}`"
            />
          </div>
        </div>
        <div class="row pxr-2">
          <div class="col-md-12 mb-2 pxr-2">
            <div class="row">
              <div class="col-md-10">
                <ViewJson
                  :jsonData="showString"
                  :filter="filter"
                  v-if="showJson"
                />
              </div>
              <div class="col-md-2">
                <span
                  class="pointer"
                  style="font-size: 22px"
                  v-b-tooltip.hover
                  title="Copy one or more conditions"
                  @click.prevent="
                    $helpers.copyConditions(showString, _vue, EventBus)
                  "
                >
                  <b-icon-paperclip></b-icon-paperclip>
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 px-0">
                  <b-button
                    class="mt-3"
                    variant="success"
                    @click.prevent="addCondition(false)"
                    v-if="!disabled"
                    >Save configuration</b-button
                  >
                </div>
                <div class="col-md-6 px-0 d-flex">
                  <b-button
                    class="mt-3 ml-auto"
                    variant="dark"
                    @click.prevent="handleSelectPasteCondition"
                    v-if="showBtnPasteCondition && !disabled"
                    >Paste configuration</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewJson from "@/components/commons/ViewJson";
import { EventBus } from "@/helpers/event-bus.js";

export default {
  name: "ConditionGenerator",
  components: {
    ViewJson,
  },
  props: {
    tabSell: {
      required: false,
      default: false,
      type: Boolean,
    },
    isCompound: {
      required: false,
      default: false,
      type: Boolean,
    },
    backtest: {
      required: false,
      default: () => {},
      type: Object,
    },
    valueCompound: {
      required: false,
      default: "",
      type: String,
    },
    type: {
      required: false,
      default: "",
      type: String,
    },
    priority: {
      required: false,
      default: "",
      type: String,
    },
    is_investor: {
      required: false,
      default: false,
      type: Boolean,
    },
    showSignal: {
      type: Boolean,
      default: true,
    },
    showBaseConfiguration: {
      type: Boolean,
      default: true,
    },
    isSpot: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this._vue = this;
    if (
      this.$props.isCompound &&
      this.$props.backtest.configurations &&
      this.$props.backtest.configurations.length > 0
    ) {
      this.condition.base_configuration.is_compound = "method 1";
    }
    if (this.$props.tabSell) {
      this.condition.base_configuration.type = "sell";
      this.condition.base_configuration.is_compound = "method 1";
    }

    if (this.$props.valueCompound) {
      if (this.$props.valueCompound == "false") {
        this.condition.base_configuration.is_compound = false;
      } else {
        this.condition.base_configuration.is_compound =
          this.$props.valueCompound;
      }
    }
    let keysClose = Object.keys(this.options.close_condition_dynamic);
    keysClose.forEach((key) => {
      this.$watch("options.close_condition_dynamic." + key, (val) => {
        if (
          val &&
          !this.options.close_condition_dynamic.activation_percentage &&
          !this.options.close_condition_dynamic.max_open_time
        ) {
          this.options.close_condition_dynamic.activation_percentage = true;
          this.options.close_condition_dynamic.max_open_time = true;
          this.options.close_condition_dynamic.active = true;
        }
      });
    });
    const validKeyPercentage = [
      "condition.base_configuration.lot",
      // 'condition.base_configuration.comission',
      "condition.open_condition.open_trailing",
      "condition.open_condition.micro_pump.movement_percentage",
      "condition.open_condition.max_min_trigger.min_avg",
      "condition.open_condition.max_min_trigger.max_avg",
      // 'condition.close_condition.take_profit',
      "condition.close_condition.stop_loss",
      // 'condition.close_condition.trailing_activation',
      // 'condition.close_condition.trailing_step',
      "condition.close_condition_dynamic.activation_percentage",
    ];

    this.keysPercentage = validKeyPercentage;
    validKeyPercentage.forEach((keyOption) => {
      this.$watch(keyOption, (value) => {
        let keys = keyOption.split(".");
        if (keys.length == 3) {
          if (keys[2] === "activation_percentage") {
            this[keys[0]][keys[1]][keys[2]] = this.$helpers.valid1To100(
              value,
              false
            );
          } else {
            this[keys[0]][keys[1]][keys[2]] = this.$helpers.valid1To100(value);
          }
        } else if (keys.length == 4) {
          this[keys[0]][keys[1]][keys[2]][keys[3]] = this.$helpers.valid1To100(
            value,
            false
          );
        } else if (keys.length == 5) {
          this[keys[0]][keys[1]][keys[2]][keys[3]][keys[5]] =
            this.$helpers.valid1To100(value, false);
        }
      });
    });
  },
  mounted() {
    this.addEventSeach();
    if (!this.$props.tabSell) {
      if (
        !document.querySelector("#configuration_compound-max_time_to_wait_buy")
      ) {
        return;
      }
      document
        .querySelector("#configuration_compound-max_time_to_wait_buy")
        .addEventListener("change", (event) => {
          event.preventDefault();
          let value = event.target.value;
          if (Number(value) < 0 || isNaN(Number(value))) {
            event.target.value = 0;
            value = 0;
          }
          if (!this.$props.tabSell) {
            this.$emit(
              "handleBaseConfiguration",
              "configuration_compound.max_time_to_wait",
              value
            );
          }
        });
      document
        .querySelector("#priority_buy")
        .addEventListener("change", (event) => {
          event.preventDefault();
          if (!this.$props.tabSell) {
            this.$emit(
              "handleBaseConfiguration",
              "configuration_compound.priority",
              event.target.value
            );
          }
        });
    }
  },
  data() {
    return {
      _vue: this,
      EventBus: EventBus,
      showJson: true,
      symbol: "BTCUSDT",
      client_confirmation: 1,
      investment_minimun: 15,
      filter: "",
      filterValue: "",
      timeframes: {
        short: 5,
        medium: 60,
        long: 1440,
        mega_long: 2880,
      },
      condition: {
        base_configuration: {
          type: "buy",
          lot: 1,
          balance: 10000,
          invert_orders: false,
          is_compound: false,
          orders_maximum: 1,
          orders_time_to_wait_for_new_orders: 60,
          investment_maximum: 10000,
          note: "",
          comission: 0.04,
          laverage: 1,
          configuration_compound: {
            priority: "buy",
            max_time_to_wait: 60,
          },
        },
        signal_condition: {
          ema: {
            period: 10,
            timeframe: "short",
          },
          condition_001: {
            divisor: 24,
            multiplicator: 1.8,
          },
          condition_002: {
            divisor: 24,
            multiplicator: 1.8,
          },
          condition_003: {
            divisor: 24,
            multiplicator: 1.8,
          },
          condition_004: {
            divisor: 24,
            multiplicator: 1.5,
          },
          condition_clients_short: {
            validator: 100,
          },
          condition_clients_medium: {
            validator: 100,
          },
        },
        open_condition: {
          max_time_to_wait: 0,
          open_trailing: 1.5,
          micro_pump: {
            movement_percentage: -5,
            time_to_validate: 30,
          },
          max_min_trigger: {
            timeframe: "short",
            max_is_active: false,
            min_is_active: false,
            max_avg: 1.1,
            min_avg: 1.1,
          },
        },
        close_condition: {
          take_profit: 100,
          stop_loss: 3,
          trailing_activation: 1,
          trailing_step: 1,
        },
        close_condition_dynamic: {
          activation_percentage: -1,
          max_open_time: 720,
          ema: {
            period: 10,
            timeframe: "short",
          },
          condition_001: {
            divisor: 24,
            multiplicator: 1.8,
          },
          condition_002: {
            divisor: 12,
            multiplicator: 2,
          },
          condition_003: {
            divisor: 12,
            multiplicator: 1.8,
          },
          condition_004: {
            divisor: 12,
            multiplicator: 0.5,
          },
          condition_clients_short: {
            validator: 100,
          },
          condition_clients_medium: {
            validator: 100,
          },
          ema_trailing: {
            period: 10,
            timeframe: "short",
          },
        },
      },
      options: {
        signal_condition: {
          ema: false,
          condition_001: false,
          condition_002: false,
          condition_003: false,
          condition_004: false,
          condition_clients_short: false,
          condition_clients_medium: false,
          max_time_to_wait: false,
        },
        open_condition: {
          max_time_to_wait: true,
          open_trailing: false,
          micro_pump: false,
          max_min_trigger: false,
        },
        close_condition: {
          take_profit: true,
          stop_loss: true,
          trailing_activation: true,
          trailing_step: true,
        },
        close_condition_dynamic: {
          active: false,
          activation_percentage: false,
          max_open_time: false,
          ema: false,
          condition_001: false,
          condition_002: false,
          condition_003: false,
          condition_004: false,
          condition_clients_short: false,
          condition_clients_medium: false,
          ema_trailing: false,
        },
      },
      conditionString: null,
      isModify: false,
      isShow: false,
      disabled: false,
      oldToken: null,
      keysPercentage: [],
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
      <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
      </svg> Error`,
    };
  },
  computed: {
    showSignalCondition() {
      let keys = Object.keys(this.options.signal_condition);
      let show = false;
      if (!this.showSignal) {
        return false;
      }
      for (let i = 0; i < keys.length; i += 1) {
        if (this.options.signal_condition[keys[i]]) {
          show = true;
          break;
        }
      }
      return show;
    },
    showOpenCondition() {
      let keys = Object.keys(this.options.open_condition);
      let show = false;
      for (let i = 0; i < keys.length; i += 1) {
        if (this.options.open_condition[keys[i]]) {
          show = true;
          break;
        }
      }
      return show;
    },
    showCloseConditionDynamic() {
      let keys = Object.keys(this.options.close_condition_dynamic);
      let show = false;
      for (let i = 0; i < keys.length; i += 1) {
        if (this.options.close_condition_dynamic[keys[i]]) {
          show = true;
          break;
        }
      }
      return show;
    },
    showString() {
      this.showJson = false;
      setTimeout(() => {
        // esto es para que actualice la informacion del json
        this.showJson = true;
      }, 300);
      return this.$helpers.toPercentageValuescondition(this.getJson());
    },
    showBtnPasteCondition() {
      if (localStorage.getItem("copyCondition")) {
        return (
          JSON.parse(
            this.$crypto.decrypt(localStorage.getItem("copyCondition"))
          ).length > 0
        );
      }
      return false;
    },
    showBalances() {
      return !this.$props.is_investor;
    },
  },
  methods: {
    addEventSeach() {
      let myInput = "";
      if (this.$props.tabSell) {
        myInput = document.getElementById("filterValueSell");
      } else {
        myInput = document.getElementById("filterValueBuy");
      }
      if (!myInput) {
        setTimeout(() => {
          this.addEventSeachResumenBacktest();
        }, 500);
      } else {
        myInput.addEventListener(
          "keyup",
          this.$debounce(() => {
            this.filter = this.filterValue;
          })
        );
      }
    },
    addCondition(returnCondition = false) {
      if (!this.validJSON()) {
        console.log("json no valido");
        return false;
      }
      let c = this.getJson();
      let condition = this.$helpers.toPercentageValuescondition(c);
      condition = this.$helpers.toNumberValuesCondition(condition);
      if (
        condition.base_configuration.is_compound == false ||
        condition.base_configuration.is_compound == "false"
      ) {
        delete condition.base_configuration.configuration_compound;
      }
      if (this.$props.is_investor) {
        condition.base_configuration.investment_minimun =
          condition.investment_minimun;
        condition.timeframes = { ...this.timeframes };
        delete condition.investment_minimun;
      }

      if (condition.base_configuration.is_compound == "false") {
        condition.base_configuration.is_compound = false;
      }

      if (returnCondition) {
        return condition;
      }

      if (this.isModify && this.$props.showBaseConfiguration) {
        let not_compound = false;
        if (this.$props.backtest.configurations.length > 0) {
          not_compound =
            this.$props.backtest.configurations[0].base_configuration
              .is_compound == false;
        }
        if (
          not_compound &&
          condition.base_configuration.is_compound != false &&
          condition.base_configuration.is_compound != "false" &&
          this.$props.backtest.configurations.length >= 2
        ) {
          this.$toastr.error(
            "You only have to add compound or simple conditions.",
            `${this.error}`
          );

          return;
        }
        if (
          !not_compound &&
          (condition.base_configuration.is_compound == false ||
            condition.base_configuration.is_compound == "false")
        ) {
          if (this.$props.backtest.configurations.length > 2) {
            this.$toastr.error(
              "You only have to add compound or simple conditions.",
              `${this.error}`
            );
            return;
          }
        }
        this.$emit("handleAddModify", condition, this.tabSell);
      } else {
        if (this.$props.isCompound) {
          this.$emit(
            "handleAddCompound",
            condition,
            this.condition.base_configuration.type
          );
        } else {
          this.$emit("addCondition", condition);
        }
      }
    },
    handleSelectPasteCondition() {
      /*
        Aqui se muestran las  condiciones diponibles para pegar en caso de que hayan mas 1 una condicion
        en caso contrario se selecciona automaticamente la unica condicion.
      */
      if (!localStorage.getItem("copyCondition")) {
        this.$toastr.error("There is no condition to paste.", `${this.error}`);

        return;
      }
      let index = 0;
      let conditions = JSON.parse(
        this.$crypto.decrypt(localStorage.getItem("copyCondition"))
      );
      if (conditions.length > 1) {
        let message = `Select the number of the condition you want to paste 1 - ${conditions.length} \n`;
        let indexC = 1;
        for (var i = 0; i < conditions.length; i++) {
          if (typeof conditions[i] === "object") {
            let token = "";
            token = JSON.parse(conditions[i][0]).token;
            message += `\n #${indexC} Token: ${token}`;
          } else {
            message += `\n #${indexC} Token: ${
              JSON.parse(conditions[i]).token
            }`;
          }
          indexC++;
        }
        index = parseInt(window.prompt(message, 1));
        if (index > conditions.length) {
          index = 0;
        } else {
          index -= 1;
        }
      }

      if (
        typeof conditions[index] === "object" &&
        conditions[index].length == 2
      ) {
        // Si es una condicion compuesta
        let cpy1 = conditions[index][0];
        let cpy2 = conditions[index][1];
        if (typeof cpy1 == "string") {
          cpy1 = JSON.parse(cpy1);
        }
        if (typeof cpy2 == "string") {
          cpy2 = JSON.parse(cpy2);
        }

        if (cpy1.backtest && !cpy2.backtest) {
          cpy2.backtest = cpy1.backtest;
        } else if (!cpy1.backtest && cpy2.backtest) {
          cpy1.backtest = cpy2.backtest;
        }
        if (cpy1.base_configuration.is_compound == true) {
          cpy1.base_configuration.is_compound = `method ${cpy1.base_configuration.configuration_compound.method}`;
          cpy2.base_configuration.is_compound = `method ${cpy1.base_configuration.configuration_compound.method}`;
        }
        if (!cpy1.base_configuration.laverage) {
          cpy1.base_configuration.laverage = 1;
        }
        if (!cpy2.base_configuration.laverage) {
          cpy2.base_configuration.laverage = 1;
        }

        this.handlePasteConditionCompound(cpy1, cpy2);
      } else {
        // si es una condicion normal
        let cpy = conditions[index];
        if (typeof conditions[index] == "string") {
          cpy = JSON.parse(cpy);
        }
        if (cpy.condition) {
          cpy = cpy.condition;
        }
        this.handlePasteConditionNormal(cpy);
      }
    },
    handlePasteConditionNormal(condition) {
      if (
        (condition.base_configuration.configuration_compound &&
          condition.base_configuration.configuration_compound.method) ||
        condition.base_configuration.is_compound == false ||
        condition.base_configuration.is_compound == "false"
      ) {
        if (
          condition.base_configuration.is_compound == false ||
          condition.base_configuration.is_compound == "false"
        ) {
          delete condition.base_configuration.configuration_compound;
        } else {
          //delete condition.base_configuration.configuration_compound.method;
        }
      }
      if (condition.backtest) {
        if (this.$props.is_investor) {
          this.client_confirmation = condition.backtest.client_confirmation;
          this.symbol = condition.backtest.symbol.toUpperCase();
          this.timeframes = condition.backtest.timeframes;
        }
        // delete condition.backtest;
      }
      if (
        condition.base_configuration.configuration_compound &&
        condition.base_configuration.configuration_compound.method
      ) {
        // delete condition.base_configuration.configuration_compound.method;
      }
      if (this.$props.tabSell) {
        delete condition.base_configuration;
      }
      if (!condition.base_configuration.laverage) {
        condition.base_configuration.laverage = 1;
      }
      this.setCondition(condition);
    },
    handlePasteConditionCompound(condition1, condition2) {
      /*Aqui se pegan las condiciones compuestas*/
      if (
        condition2.base_configuration.configuration_compound &&
        condition2.base_configuration.configuration_compound.method
      ) {
        delete condition1.base_configuration.configuration_compound.method;
      }
      if (
        condition2.base_configuration.configuration_compound &&
        condition2.base_configuration.configuration_compound.method
      ) {
        delete condition2.base_configuration.configuration_compound.method;
      }
      this.setCondition(condition1);
      this.$emit("setConditionPasteSell", condition2);
    },
    getJson() {
      const cpy = { ...this.condition };
      const condition = {};
      if (!this.isModify) {
        condition.token = this.$helpers.getRandomInt();
        condition.base_configuration = {};
      } else {
        condition.token = this.oldToken;
        condition.base_configuration = this.condition.base_configuration;
      }

      delete cpy.base_configuration.timeframes;
      condition.base_configuration = {
        ...this.$helpers.observerClean(cpy.base_configuration),
      };
      delete cpy.base_configuration;

      //Obtenemos los key principales (signal_condition, open_condition, close_condition, close_condition_dynamic)
      const options = Object.keys(cpy); //
      for (let i = 0; i < options.length; i += 1) {
        // recorremos los keys principales
        let key = cpy[options[i]];
        // console.log(key);
        let keysInternos = Object.keys(cpy[options[i]]); // obtenemos los keys dentro de los principales (condiiton_ema, condition_001, etc)
        let isAdd = false;

        for (let j = 0; j < keysInternos.length; j++) {
          if (this.options[options[i]][keysInternos[j]]) {
            isAdd = true;
            break;
          }
        }

        /*keysInternos.forEach((ele) => {
          console.log(options[i], this.options[options[i]][ele]);
          if (this.options[options[i]][ele]) {
            break;
            isAdd = true;
          }
        });*/
        if (!isAdd) {
          continue;
        }
        condition[options[i]] = {};
        for (let j = 0; j < keysInternos.length; j += 1) {
          if (i == 0) {
            // console.log(options[i], keysInternos[j], !this.options[options[i]][keysInternos[j]]);
          }
          if (!this.options[options[i]][keysInternos[j]]) {
            continue;
          }
          let value = cpy[options[i]][keysInternos[j]];
          if (typeof value === "object") {
            value = this.$helpers.observerClean(value);
          }
          condition[options[i]][keysInternos[j]] = value;
        }
      }
      if (this.$props.is_investor) {
        condition.symbol = this.symbol;
        condition.client_confirmation = Number(this.client_confirmation);
        condition.timeframes = this.timeframes;
        condition.investment_minimun =
          Number(this.investment_minimun) <= 0
            ? 15
            : Number(this.investment_minimun);
      }

      if (this.isModify) {
        condition.token = this.oldToken;
      }
      return condition;
    },
    validJSON() {
      let base_configuration = this.condition.base_configuration;
      let signal_condition = this.condition.signal_condition;
      let open_condition = this.condition.open_condition;
      let close_condition = this.condition.close_condition;
      let close_condition_dynamic = this.condition.close_condition_dynamic;
      if (this.$props.is_investor) {
        if (!this.symbol) {
          this.$toastr.error("Please Add symbol", `${this.error}`);

          return false;
        }

        if (
          (this.symbol.toLowerCase() === "btcusdt" &&
            this.investment_minimun < 15) ||
          (this.symbol.toLowerCase() !== "btcusdt" &&
            this.investment_minimun < 1)
        ) {
          if (this.symbol.toLowerCase() === "btcusdt") {
            this.$toastr.error(
              "The minimum investment only has to be greater than or equal to 15",
              `${this.error}`
            );
          } else {
            this.$toastr.error(
              "The minimum investment only has to be greater than or equal to 1",
              `${this.error}`
            );
          }
          return;
        }

        if (this.timeframes.client_confirmation < 0) {
          this.$toastr.error(
            "Client confirmation only allows positive values",
            `${this.error}`
          );

          return false;
        }

        if (this.timeframes.short <= 0) {
          this.$toastr.error(
            "Timeframes short only allows positive values",
            `${this.error}`
          );

          return false;
        }
        if (this.timeframes.medium <= 0) {
          this.$toastr.error(
            "Timeframes medium only allows positive values",
            `${this.error}`
          );

          return false;
        }
        if (this.timeframes.long <= 0) {
          this.$toastr.error(
            "Timeframes long only allows positive values",
            `${this.error}`
          );

          return false;
        }
        if (this.timeframes.mega_long <= 0) {
          this.$toastr.error(
            "Timeframes mega long only allows positive values",
            `${this.error}`
          );

          return false;
        }
      }

      if (this.$props.showBaseConfiguration) {
        // valid base_configuration
        if (!base_configuration.balance || base_configuration.balance < 0) {
          this.$toastr.error(
            "Balance only allows positive values",
            `${this.error}`
          );

          return false;
        }

        if (!base_configuration.comission || base_configuration.comission < 0) {
          this.$toastr.error(
            "Comission only allows positive values",
            `${this.error}`
          );

          return false;
        }

        if (!base_configuration.lot || base_configuration.lot < 0) {
          this.$toastr.error(
            "Lot only allows positive values",
            `${this.error}`
          );

          return false;
        }

        if (
          !base_configuration.orders_maximum ||
          base_configuration.orders_maximum < 0
        ) {
          this.$toastr.error(
            "Orders Maximum only allows positive values",
            `${this.error}`
          );

          return false;
        }

        if (Number(base_configuration.orders_time_to_wait_for_new_orders) < 0) {
          this.$toastr.error(
            "Orders time to wait for new orders only allows positive values",
            `${this.error}`
          );

          return false;
        }

        if (
          !base_configuration.investment_maximum ||
          base_configuration.investment_maximum < 0
        ) {
          this.$toastr.error(
            "Investment maximum allows positive values",
            `${this.error}`
          );

          return false;
        }

        if (!base_configuration.laverage || base_configuration.laverage < 0) {
          this.$toastr.error(
            "Laverage allows positive values",
            `${this.error}`
          );

          return false;
        }
      }

      // valid signal_condition
      if (!signal_condition.ema.period || signal_condition.ema.period < 0) {
        this.$toastr.error(
          "Ema period only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (
        !signal_condition.condition_001.divisor ||
        signal_condition.condition_001.divisor < 0
      ) {
        this.$toastr.error(
          "BTC IN_OUT divisor only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (
        !signal_condition.condition_001.multiplicator ||
        signal_condition.condition_001.multiplicator < 0
      ) {
        this.$toastr.error(
          "BTC IN_OUT multiplicator only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (
        !signal_condition.condition_002.divisor ||
        signal_condition.condition_002.divisor < 0
      ) {
        this.$toastr.error(
          "BTC IN/OUT divisor only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (
        !signal_condition.condition_002.multiplicator ||
        signal_condition.condition_002.multiplicator < 0
      ) {
        this.$toastr.error(
          "BTC IN/OUT multiplicator only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (
        !signal_condition.condition_003.divisor ||
        signal_condition.condition_003.divisor < 0
      ) {
        this.$toastr.error(
          "AVG IN/OUT divisor only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (
        !signal_condition.condition_003.multiplicator ||
        signal_condition.condition_003.multiplicator < 0
      ) {
        this.$toastr.error(
          "AVG IN/OUT multiplicator only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (
        !signal_condition.condition_004.divisor ||
        signal_condition.condition_004.divisor < 0
      ) {
        this.$toastr.error(
          "TICKS IN/OUT divisor only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (
        !signal_condition.condition_004.multiplicator ||
        signal_condition.condition_004.multiplicator < 0
      ) {
        this.$toastr.error(
          "TICKS IN/OUT multiplicator only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (signal_condition.condition_clients_short.validator === "") {
        this.$toastr.error(
          "The C.C. - Short validator field is required",
          `${this.error}`
        );

        return false;
      }

      if (signal_condition.condition_clients_medium.validator === "") {
        this.$toastr.error(
          "The C.C. - Medium validator field is required",
          `${this.error}`
        );

        return false;
      }

      // valid open condition
      if (open_condition.max_time_to_wait < 0) {
        this.$toastr.error(
          "The Max time to wait field is required",
          `${this.error}`
        );

        return false;
      }

      if (open_condition.open_trailing < 0) {
        this.$toastr.error(
          "The Open Trailing field is required",
          `${this.error}`
        );

        return false;
      }

      if (!open_condition.micro_pump.movement_percentage) {
        this.$toastr.error(
          "The Movement Percentage field is required",
          `${this.error}`
        );

        return false;
      }

      if (!open_condition.micro_pump.time_to_validate) {
        this.$toastr.error(
          "The time to validate field is required",
          `${this.error}`
        );

        return false;
      }

      if (
        !open_condition.max_min_trigger.min_avg ||
        open_condition.max_min_trigger.min_avg < 0
      ) {
        this.$toastr.error(
          "The min avg only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (
        !open_condition.max_min_trigger.max_avg ||
        open_condition.max_min_trigger.max_avg < 0
      ) {
        this.$toastr.error(
          "The max avg only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (close_condition.take_profit < 0) {
        this.$toastr.error(
          "Take profit only allows positive values",
          `${this.error}`
        );

        return false;
      }
      if (close_condition.stop_loss < 1 || close_condition.stop_loss > 100) {
        this.$toastr.error(
          "Stop Loss only allows positive values ​​and less than 100.",
          `${this.error}`
        );

        return false;
      }

      if (close_condition.trailing_activation < 0) {
        this.$toastr.error(
          "Trailing activation only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (close_condition.trailing_step < 0) {
        this.$toastr.error(
          "Trailing step only allows positive values",
          `${this.error}`
        );

        return false;
      }

      // valid close condition dinamyc
      if (close_condition_dynamic.ema.period < 0) {
        this.$toastr.error(
          "Ema period only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (
        !close_condition_dynamic.max_open_time ||
        close_condition_dynamic.max_open_time < 0
      ) {
        this.$toastr.error(
          "Max open time only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (
        !close_condition_dynamic.condition_001.divisor ||
        close_condition_dynamic.condition_001.divisor < 0
      ) {
        this.$toastr.error(
          "BTC IN_OUT divisor only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (
        !close_condition_dynamic.condition_001.multiplicator ||
        close_condition_dynamic.condition_001.multiplicator < 0
      ) {
        this.$toastr.error(
          "BTC IN_OUT multiplicator only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (
        !close_condition_dynamic.condition_002.divisor ||
        close_condition_dynamic.condition_002.divisor < 0
      ) {
        this.$toastr.error(
          "BTC IN/OUT divisor only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (
        !close_condition_dynamic.condition_002.multiplicator ||
        close_condition_dynamic.condition_002.multiplicator < 0
      ) {
        this.$toastr.error(
          "BTC IN/OUT multiplicator only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (
        !close_condition_dynamic.condition_003.divisor ||
        close_condition_dynamic.condition_003.divisor < 0
      ) {
        this.$toastr.error(
          "AVG IN/OUT divisor only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (
        !close_condition_dynamic.condition_003.multiplicator ||
        close_condition_dynamic.condition_003.multiplicator < 0
      ) {
        this.$toastr.error(
          "AVG IN/OUT multiplicator only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (
        !close_condition_dynamic.condition_004.divisor ||
        close_condition_dynamic.condition_004.divisor < 0
      ) {
        this.$toastr.error(
          "TICKS IN/OUT divisor only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (
        !close_condition_dynamic.condition_004.multiplicator ||
        close_condition_dynamic.condition_004.multiplicator < 0
      ) {
        this.$toastr.error(
          "TICKS IN/OUT multiplicator only allows positive values",
          `${this.error}`
        );

        return false;
      }

      if (close_condition_dynamic.condition_clients_short.validator === "") {
        this.$toastr.error(
          "The close condition dynamic C.C. - Short validator field is required",
          `${this.error}`
        );

        return false;
      }
      if (close_condition_dynamic.condition_clients_medium.validator === "") {
        this.$toastr.error(
          "The close condition dynamic C.C. - Medium validator field is required",
          `${this.error}`
        );

        return false;
      }
      if (
        close_condition_dynamic.ema_trailing &&
        close_condition_dynamic.ema_trailing.period < 1
      ) {
        this.$toastr.error(
          "Ema trailing only allows positive values",
          `${this.error}`
        );

        return false;
      }
      return true;
    },
    setCondition(condition) {
      if (condition.backtest) {
        if (this.$props.is_investor) {
          this.client_confirmation = condition.backtest.client_confirmation;
          this.symbol = condition.backtest.symbol.toUpperCase();
          this.timeframes = condition.backtest.timeframes;
        }
        delete condition.backtest;
      } else {
        if (
          condition.client_confirmation != undefined &&
          condition.client_confirmation != null
        ) {
          this.client_confirmation = condition.client_confirmation;
        }
        if (condition.timeframes) {
          this.timeframes = { ...condition.timeframes };
        }
      }
      // para cuando se copie una condicion
      if (condition.base_configuration) {
        if (!condition.base_configuration.configuration_compound) {
          condition.base_configuration.configuration_compound = {
            priority: "buy",
            max_time_to_wait: 60,
          };
        }
        if (condition.base_configuration.timeframes) {
          this.timeframes = condition.base_configuration.timeframes;
          delete condition.base_configuration.timeframes;
        }
        if (condition.base_configuration.symbol) {
          this.symbol = condition.base_configuration.symbol;
          delete condition.base_configuration.symbol;
        }
        if (condition.base_configuration.client_confirmation) {
          this.client_confirmation =
            condition.base_configuration.client_confirmation;
          delete condition.base_configuration.client_confirmation;
        }
        this.condition.base_configuration = condition.base_configuration;
      } else {
        condition.base_configuration = this.condition.base_configuration;
      }
      this.handleConvertKeysPercentage(condition);
    },
    modifyCondition(condition) {
      this.isModify = true;
      this.oldToken = condition.token;
      if (this.$props.is_investor) {
        this.symbol = condition.symbol;
        this.client_confirmation = condition.client_confirmation;
        if (condition.timeframes) {
          this.timeframes = condition.timeframes;
        }

        if (
          condition.base_configuration &&
          condition.base_configuration.investment_minimun
        ) {
          this.investment_minimun =
            condition.base_configuration.investment_minimun;
          delete condition.base_configuration.investment_minimun;
        }
        if (!this.investment_minimun) {
          this.investment_minimun = 15;
        }
        delete condition.symbol;
        delete condition.client_confirmation;
        delete condition.timeframes;
        delete condition.investment_minimun;
      }

      let not_configured_compound =
        (condition.base_configuration &&
          condition.base_configuration.configuration_compound == undefined) ||
        (condition.base_configuration &&
          condition.base_configuration.configuration_compound
            .max_time_to_wait === undefined) ||
        (condition.base_configuration &&
          condition.base_configuration.configuration_compound.priority ===
            undefined);

      if (not_configured_compound) {
        // se setea los datos de condiciones compuesta para evitar errores
        // en el formulario ya que los inputs estan con v-show
        condition.base_configuration.configuration_compound = {
          priority: "buy",
          max_time_to_wait: 60,
        };
      }
      this.condition.base_configuration = { ...condition.base_configuration };
      this.handleConvertKeysPercentage(condition);
    },
    showCondition(condition) {
      if (condition.condition) {
        condition = condition.condition;
      }

      if (Number(condition.base_configuration.lot) > 1) {
        condition.base_configuration.lot =
          condition.base_configuration.lot / 100;
      }

      let not_configured_compound =
        condition.base_configuration.configuration_compound == undefined ||
        condition.base_configuration.configuration_compound.max_time_to_wait ===
          undefined ||
        condition.base_configuration.configuration_compound.priority ===
          undefined;

      if (not_configured_compound) {
        // se setea los datos de condiciones compuesta para evitar errores
        // en el formulario ya que los inputs estan con v-show
        condition.base_configuration.configuration_compound = {
          priority: "buy",
          max_time_to_wait: 60,
        };
      }

      if (condition.timeframes) {
        this.timeframes = condition.timeframes;
      } else {
        condition.timeframes = this.timeframes;
      }
      if (condition.signal_condition) {
        this.condition.signal_condition = { ...condition.signal_condition };
      }
      if (condition.close_condition) {
        this.condition.close_condition = { ...condition.close_condition };
      }
      if (condition.close_condition_dynamic) {
        this.condition.close_condition_dynamic = {
          ...condition.close_condition_dynamic,
        };
      }

      if (this.$props.is_investor) {
        this.symbol = condition.symbol;
        this.client_confirmation = condition.client_confirmation;
        this.timeframes = condition.timeframes;
        if (condition.base_configuration.investment_minimun) {
          this.investment_minimun =
            condition.base_configuration.investment_minimun;
        }
        delete condition.base_configuration.investment_minimun;
        if (!this.investment_minimun) {
          this.investment_minimun = 15;
        }
        delete condition.symbol;
        delete condition.client_confirmation;
        delete condition.timeframes;
        delete condition.investment_minimun;
      }
      this.isModify = true;
      this.disabled = true;
      this.oldToken = condition.token;
      this.condition.base_configuration = condition.base_configuration;
      // this.showJson = false;
      this.handleConvertKeysPercentage(condition);
    },
    setData(field, value) {
      if (field === "configuration_compound.priority") {
        this.condition.base_configuration.configuration_compound.priority =
          value;
        document.querySelector("#priority_sell").value = value;
      } else if (field === "configuration_compound.max_time_to_wait") {
        if (
          this.condition.base_configuration.configuration_compound ==
            undefined ||
          this.condition.base_configuration.configuration_compound
            .max_time_to_wait === undefined
        ) {
          let cc = this.condition.base_configuration.configuration_compound;
          if (cc == undefined) {
            cc = {};
          }
          cc.max_time_to_wait = value;
          this.condition.base_configuration.configuration_compound = null;
          this.condition.base_configuration.configuration_compound = {};
          this.condition.base_configuration.configuration_compound = cc;
        } else {
          let cc = this.condition.base_configuration.configuration_compound;
          if (cc == undefined) {
            cc = {};
          }
          cc.max_time_to_wait = value;
          if (!cc.priority) {
            cc.priority = "";
          }
          this.condition.base_configuration.configuration_compound = null;
          this.condition.base_configuration.configuration_compound = {};
          this.condition.base_configuration.configuration_compound = cc;
          this.condition.base_configuration.configuration_compound.max_time_to_wait =
            value;
        }
      } else if (
        field == "symbol" ||
        field == "client_confirmation" ||
        field == "investment_minimun"
      ) {
        this[field] = value;
      } else {
        this.condition.base_configuration[field] = value;
      }
    },
    handleConvertKeysPercentage(condition) {
      if (condition.backtest) {
        delete condition.backtest;
      }
      /*
        Formatea los campos que son tipos porcentajes
        EJ: 0.001 => 1
      */
      const ignoreKeys = [
        "token",
        "condition",
        "token_user",
        "created_at",
        "updated_at",
        "note",
        "symbol",
        "timeframes",
      ];
      const keys = Object.keys(condition);
      keys.forEach((keyName) => {
        if (keyName !== "base_configuration") {
          if (condition[keyName] == null) {
            return;
          }
          const values = Object.keys(condition[keyName]);
          if (keyName === "close_condition_dynamic ") {
            this.options.close_condition_dynamic.active = true;
          }
          if (ignoreKeys.indexOf(keyName) > -1) {
            return;
          }
          values.forEach((valueName) => {
            if (keyName == "symbol" && (valueName == 0 || valueName == 1)) {
              return;
            }

            this.options[keyName][valueName] = true;
            if (typeof condition[keyName][valueName] === "object") {
              this.condition[keyName][valueName] = {
                ...condition[keyName][valueName],
              };
            } else {
              this.condition[keyName][valueName] =
                condition[keyName][valueName];
            }
          });
        }
      });

      let keysPercentage = [...this.keysPercentage];
      keysPercentage.push("condition.close_condition.take_profit");
      keysPercentage.push("condition.close_condition.trailing_activation");
      keysPercentage.push("condition.close_condition.trailing_step");
      keysPercentage.forEach((keyOption) => {
        let keys = keyOption.split(".");
        if (keys.length == 3) {
          this[keys[0]][keys[1]][keys[2]] = Number(
            this[keys[0]][keys[1]][keys[2]] * 100
          );
        } else if (keys.length == 4) {
          this[keys[0]][keys[1]][keys[2]][keys[3]] = Number(
            this[keys[0]][keys[1]][keys[2]][keys[3]] * 100
          );
        } else if (keys.length == 5) {
          this[keys[0]][keys[1]][keys[2]][keys[3]][keys[5]] = Number(
            this[keys[0]][keys[1]][keys[2]][keys[3]][keys[5]] * 100
          );
        }
      });
    },
    resetConfig() {
      this.condition.base_configuration = {
        type: "buy",
        lot: 1,
        balance: 10000,
        invert_orders: false,
        is_compound: false,
        orders_maximum: 1,
        orders_time_to_wait_for_new_orders: 60,
        investment_maximum: 10000,
        note: "",
        comission: 0.04,
        laverage: 1,
        configuration_compound: {
          priority: "buy",
          max_time_to_wait: 60,
        },
      };
    },
  },
  watch: {
    symbol(value) {
      if (!this.$props.tabSell) {
        this.$emit("handleBaseConfiguration", "symbol", value);
      }
    },
    client_confirmation(value) {
      if (!this.$props.tabSell) {
        // this.$emit('handleBaseConfiguration','client_confirmation',value);
      }
    },
    investment_minimun(value) {
      if (!this.$props.tabSell) {
        // this.$emit('handleBaseConfiguration','investment_minimun',value);
      }
    },
    "condition.base_configuration.lot"(value) {
      if (!this.$props.tabSell) {
        this.$emit("handleBaseConfiguration", "lot", value);
      }
    },
    "condition.base_configuration.balance"(value) {
      if (!this.$props.tabSell) {
        this.$emit("handleBaseConfiguration", "balance", value);
      }
    },
    "condition.base_configuration.invert_orders"(value) {
      if (!this.$props.tabSell) {
        this.$emit("handleBaseConfiguration", "invert_orders", value);
      }
    },
    "condition.base_configuration.orders_maximum"(value) {
      if (!this.$props.tabSell) {
        this.$emit("handleBaseConfiguration", "orders_maximum", value);
      }
    },
    "condition.base_configuration.orders_time_to_wait_for_new_orders"(value) {
      if (!this.$props.tabSell) {
        this.$emit(
          "handleBaseConfiguration",
          "orders_time_to_wait_for_new_orders",
          value
        );
      }
    },
    "condition.base_configuration.investment_maximum"(value) {
      if (!this.$props.tabSell) {
        this.$emit("handleBaseConfiguration", "investment_maximum", value);
      }
    },
    "condition.base_configuration.comission"(value) {
      if (!this.$props.tabSell) {
        this.$emit("handleBaseConfiguration", "comission", value);
      }
    },
    "condition.base_configuration.laverage"(value) {
      if (!this.$props.tabSell) {
        this.$emit("handleBaseConfiguration", "laverage", value);
      }
    },
    "condition.open_condition.max_time_to_wait"(value) {
      if (Number(value) < 0 || isNaN(Number(value))) {
        this.condition.open_condition.max_time_to_wait = 0;
      }
    },
    "condition.base_configuration.is_compound"(value) {
      if (!this.condition.base_configuration.configuration_compound) {
        this.condition.base_configuration.configuration_compound = {};
        this.condition.base_configuration.configuration_compound.max_time_to_wait = 60;
        this.condition.base_configuration.configuration_compound.priority =
          "buy";
        this.$emit("handlePriority", "buy");
        this.$emit(
          "handleBaseConfiguration",
          "configuration_compound.max_time_to_wait",
          60
        );
      }
      if (
        value === "method 2" &&
        !this.condition.base_configuration.configuration_compound
          .max_time_to_wait
      ) {
        if (
          this.condition.base_configuration.configuration_compound &&
          this.condition.base_configuration.configuration_compound.priority
        ) {
          this.condition.base_configuration.configuration_compound.max_time_to_wait = 60;
        } else {
          this.condition.base_configuration.configuration_compound.max_time_to_wait = 60;
          this.condition.base_configuration.configuration_compound.priority =
            "buy";
        }
        this.$emit("handlePriority", "buy");
        this.$emit(
          "handleBaseConfiguration",
          "configuration_compound.max_time_to_wait",
          60
        );
      }
      if (
        value === "method 1" &&
        !this.condition.base_configuration.configuration_compound.priority
      ) {
        this.condition.base_configuration.configuration_compound = {};
        this.condition.base_configuration.configuration_compound.priority =
          "buy";
        this.$emit("handlePriority", "buy");
        this.$emit(
          "handleBaseConfiguration",
          "configuration_compound.max_time_to_wait",
          60
        );
      }

      /* if (value == 'false' || !value) {
        if (this.condition.base_configuration.configuration_compound) {
          localStorage.setItem('configuration_compound', JSON.stringify(this.condition.base_configuration));
        }
      } */
      if (!this.$props.tabSell) {
        this.$emit("handleCompound", value);
        this.$emit("handleSetType", this.condition.base_configuration.type);
      }
    },
    "condition.base_configuration.type"(value) {
      if (!value) {
        return;
      }
      if (
        !this.$props.tabSell &&
        this.condition.base_configuration.is_compound != false &&
        this.condition.base_configuration.is_compound != "false"
      ) {
        this.$emit("handleSetType", value);
      }
    },
    "options.close_condition_dynamic.active"(value) {
      if (value) {
        this.options.close_condition_dynamic.activation_percentage = true;
        this.options.close_condition_dynamic.max_open_time = true;
      } else {
        let keysClose = Object.keys(this.options.close_condition_dynamic);
        keysClose.forEach((key) => {
          this["options"]["close_condition_dynamic"][key] = false;
        });
      }
    },
    "options.close_condition_dynamic.activation_percentage"(value) {
      if (value) {
        this.options.close_condition_dynamic.active = true;
        this.options.close_condition_dynamic.max_open_time = true;
      }
    },
    "options.close_condition_dynamic.max_open_time"(value) {
      if (value) {
        this.options.close_condition_dynamic.active = true;
        this.options.close_condition_dynamic.activation_percentage = true;
      }
    },
    "options.open_condition.max_min_trigger"(value) {
      if (!value) {
        this.options.open_condition.max_min_trigger = false;
        this.condition.open_condition.max_min_trigger.min_is_active = false;
        this.condition.open_condition.max_min_trigger.max_is_active = false;
      }
    },
    "condition.open_condition.max_min_trigger.max_is_active"(value) {
      if (value) {
        this.options.open_condition.max_min_trigger = true;
      } else {
        if (!this.condition.open_condition.max_min_trigger.min_is_active) {
          this.options.open_condition.max_min_trigger = false;
        }
      }
    },
    "condition.open_condition.max_min_trigger.min_is_active"(value) {
      if (value) {
        this.options.open_condition.max_min_trigger = true;
      } else {
        if (!this.condition.open_condition.max_min_trigger.max_is_active) {
          this.options.open_condition.max_min_trigger = false;
        }
      }
    },
    "$props.valueCompound"(value) {
      this.condition.base_configuration.is_compound = value;
    },
    "$props.type"(value) {
      this.condition.base_configuration.type = value;
    },
    "$props.priority"(value) {
      this.condition.base_configuration.configuration_compound.priority = value;
    },
    "condition.base_configuration.configuration_compound.max_time_to_wait"(
      value
    ) {
      if (Number(value) < 0 || isNaN(Number(value))) {
        this.condition.base_configuration.configuration_compound.max_time_to_wait = 0;
      }
    },
    "$props.isSpot"(value) {
      if (value) {
        this.condition.base_configuration.laverage = 1;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/commons/condition_generator.scss";
@media (max-width: 480px) {
  .modal-content {
    width: 90%;
    left: 2%;
  }
}
</style>

<style lang="scss" scoped>
.pxr-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media screen and (min-width: 600x) and (max-width: 1200px) {
  .pxr-2 {
    padding-left: 0.35rem;
    padding-right: 0.3rem;
  }
  label {
    font-size: 0.75rem;
  }
  ::v-deep .form-control {
    font-size: 12px;
  }
  #mega-long {
    width: 75px;
    margin-left: 20px;
  }
  #long {
    width: 75px;
  }
  #mega-long-label {
    padding-left: 15px;
  }
}
</style>
