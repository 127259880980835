<template>
  <div>
    <div class="module-content module-investor-configuration">
      <div class="container-fluid">
        <div class="row">
          <HeaderTopBar />
        </div>
      </div>

      <div class="module-container">
        <div class="left-side"><Sidebar /></div>
        <div class="center-side">
          <AccountInfo v-if="sidebar_option == 1" />
          <Comments v-if="sidebar_option == 2" />
          <ConnectionsTab v-if="sidebar_option == 4 && isAllowedConnections" />
          <PortfoliosTab v-if="sidebar_option == 5 && isAllowedPortfolios" />
          <TransactionsTab
            v-if="sidebar_option == 3 && isAllowedTransactions"
          />
          <NotificationsTab v-if="sidebar_option == 6" />
        </div>
        <div class="right-side"><InvestorPreview /></div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountInfo from "../components/pages/investor-configuration/tabs/AccountInfo.vue";
import Comments from "../components/pages/investor-configuration/tabs/Comments.vue";
import ConnectionsTab from "../components/pages/investor-configuration/tabs/ConnectionsTab.vue";
import HeaderTopBar from "../components/pages/investor-configuration/HeaderTopBar.vue";
import InvestorPreview from "../components/pages/investor-configuration/investor_preview/InvestorPreview.vue";
import NotificationsTab from "../components/pages/investor-configuration/tabs/NotificationsTab.vue";
import PortfoliosTab from "../components/pages/investor-configuration/tabs/PortfoliosTab.vue";
import Sidebar from "../components/pages/investor-configuration/Sidebar.vue";
import TransactionsTab from "../components/pages/investor-configuration/tabs/TransactionsTab.vue";

import { mapState } from "vuex";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "InvestorConfiguration",
  components: {
    AccountInfo,
    Comments,
    ConnectionsTab,
    HeaderTopBar,
    InvestorPreview,
    NotificationsTab,
    Sidebar,
    PortfoliosTab,
    TransactionsTab,
  },
  created() {
    this.$store.dispatch("transactions/transaction_list_preview", {});
    this.$store.dispatch("connections/connection_list_preview", {});
    this.$store.dispatch("portfolios/portfolio_list_preview");
    this.$store.dispatch("investor_configuration/investor_info");
  },
  mounted() {},
  computed: {
    ...mapState("investor_configuration", ["sidebar_option", "investor_info"]),
    investor() {
      return this.investor_info && this.investor_info._data
        ? this.investor_info._data.data
        : null;
    },
    isAllowedTransactions() {
      return this.investor && this.investor.has_connections;
    },
    isAllowedConnections() {
      return true;
    },
    isAllowedPortfolios() {
      return (
        this.investor &&
        this.investor.has_connections &&
        this.investor.has_transactions
      );
    },
  },
  methods: {},
  watch: {},
};
</script>
<style lang="scss">
@import "../styles/pages/investor_configuration/index.scss";
</style>
