<template>
  <b-col cols="12" lg="6" class="p-0 sidebar__user_resume nice-scroll">
  
    <div class="p-2" v-if="showGraficBoolean">
       <b-row class="mb-2">
        <b-col class="d-flex justify-content-end" cols="4" lg="4" align-self="end" offset-lg="8">
          <select class="form-control mr-2" v-model="algorithm_token">
            <option :value="null">All algorithms</option>
            <option :value="algorithm.token" v-for="algorithm in algorithms"> {{algorithm.name}} </option>
          </select>
        </b-col>

      </b-row>

      <b-row class="text-right">
        <b-col cols="12" lg="12">
          <b-button class="mr-2" :variant="activeLastMonth ? 'info' : 'dark'" size="sm" @click="showGrafic('month')">Last month</b-button>
          <b-button class="mr-2" :variant="activeLastYear ? 'info' : 'dark'" size="sm" @click="showGrafic('year')">Last years</b-button>
          <b-button class="mr-2" :variant="activeAllHistory ? 'info' : 'dark'" size="sm" @click="showGrafic('all')">All history</b-button>
        </b-col>
      </b-row>
      <apexchart 
        width="100%" 
        height="200px" 
        type="line"  
        :options="chart__performance_options" 
        :series="chart__performance_series"
        v-if="activeAllHistory"
      >
      </apexchart>
      <apexchart 
        width="100%" 
        height="200px" 
        type="line"  
        :options="chart__performance_options_last_month" 
        :series="chart__performance_series_last_month"
        v-if="activeLastMonth"
      >
      </apexchart>
      <apexchart 
        width="100%" 
        height="200px" 
        type="line"  
        :options="chart__performance_options_last_year" 
        :series="chart__performance_series_last_year"
        v-if="activeLastYear"
      >
      </apexchart>
    </div>
    <b-row class="resume px-4" v-if="showGraficBoolean">
      <b-col cols="12" lg="3" class="px-1">
        <div class="content p-2 text-center" style="background-color: #4bd4ea;color: #16276e;">
          <div class="number mono">
            {{$helpers.numberFormat(resumen.global_investment ? resumen.global_investment : 0)}}
          </div>
          <div class="description">
            Net asset <br> Value
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="3" class="px-1">
        <div class="content p-2 text-center">
          <div class="number mono">
            {{$helpers.numberFormat(resumen.current_investment ? resumen.current_investment : 0)}}
          </div>
          <div class="description">
            Current  <br> investment
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="3" class="px-1">
        <div class="content p-2 text-center">
          <div class="number mono">
            {{resumen.total_operation ? resumen.total_operation : 0}}
          </div>
          <div class="description">
            Total  <br> operations
          </div>
        </div>
      </b-col>

      <b-col cols="12" lg="3" class="px-1">
        <div class="content p-2 text-center">
          <div class="number mono">
            {{resumen.total_operation ? resumen.total_operation : 0}}
          </div>
          <div class="description">
            Operations <br> closed
          </div>
        </div>
      </b-col>

      <b-col cols="12" lg="3" class="px-1">
        <div class="content p-2 text-center">
          <div class="number mono" :class="[resumen.profit_percentage >= 0 ? 'text-success' : 'text-danger']">
            {{$helpers.numberFormat(resumen.profit_net ? resumen.profit_net : 0)}}
          </div>
          <div class="description">
            Earnings <br> achieved
          </div>
        </div>
      </b-col>

      <b-col cols="12" lg="3" class="px-1">
        <div class="content p-2 text-center">
          <div class="number mono">
            {{$helpers.numberFormat(resumen.total_commission ? resumen.total_commission : 0)}}
          </div>
          <div class="description">
            Total <br> Commissions
          </div>
        </div>
      </b-col>

       <b-col cols="12" lg="3" class="px-1 ">
       <div class="content p-2 text-center">
          <div class="number mono">
            {{resumen_order.max_open_order ? resumen_order.max_open_order : 0}}
          </div>
          <div class="description">
            Maximum <br> open orders
          </div>
        </div>
      </b-col>

       <b-col cols="12" lg="3" class="px-1">
        <div class="content p-2 text-center">
          <div class="number mono">
            {{$helpers.numberFormat(resumen.maximum_profit_losses ? resumen.maximum_profit_losses : 0)}} ({{resumen.maximum_consecutive_losses ? resumen.maximum_consecutive_losses : 0}})
          </div>
          <div class="description">
            Maximum consecutive <br> losses
          </div>
        </div>
      </b-col>

      <b-col cols="12" lg="3" class="px-1">
        <div class="content p-2 text-center">
          <div class="number mono">
            {{$helpers.numberFormat(resumen.maximum_profit_winnings ? resumen.maximum_profit_winnings : 0)}} ({{resumen.maximum_consecutive_winnings ? resumen.maximum_consecutive_winnings : 0}})
          </div>
          <div class="description">
            Maximum consecutive <br> winnings
          </div>
        </div>
      </b-col>

      <b-col cols="12" lg="3" class="px-1">
       <div class="content p-2 text-center">
          <div
            class="number mono"
            :class="[
              handleEditMonthlyYieldBoolean(monthly_yield, balance, resumen.total_operation) && resumen.profit_net >= 0 ? 'text-success' : 'text-danger' ]"
          >
            {{monthly_yield ? monthly_yield : 0 | handleEditMonthlyYield(balance ? balance : 0)}} ({{ monthly_yield ? monthly_yield : 0 | handleEditMonthlyYieldPercentaje(balance ? balance : 0)}})
          </div>
          <div class="description">
            Monthly <br> yield
          </div>
        </div>
      </b-col>

      
      <b-col cols="12" lg="3" class="px-1 ">
       <div class="content p-2 text-center">
          <div
            class="number mono"
            v-b-tooltip.hover
            :title="`Last update: ${resumen_order.last_update}`"
            :class="[resumen_order.dropdown_current >= 0 ? 'text-success' : 'text-danger' ]"
          >
           {{ $helpers.numberFormat(resumen_order.dropdown_current ? resumen_order.dropdown_current : 0)}}
            {{resumen_order.porc_current ? resumen_order.porc_current.toFixed(2):resumen_order.porc_current}}%
          </div>
          <div class="description">
            Current relative <br> drawdown
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="3" class="px-1">
       <div class="content p-2 text-center">
          <div
            class="number mono"
            v-b-tooltip.hover
            :title="`Last update: ${resumen_order.last_update}`"
          >
            {{$helpers.numberFormat(resumen_order.dropdown_maximun ? resumen_order.dropdown_maximun : 0)}}
            {{resumen_order.porc ? resumen_order.porc.toFixed(2) : resumen_order.porc}}%
          </div>
          <div class="description">
            Maximun relative <br>drawdown
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="3" class="px-1">
       <div class="content p-2 text-center">
          <div
            class="number mono"
            v-b-tooltip.hover
            :title="`Last update: ${resumen_order.last_update}`"
            :class="[resumen_order.dropdown_current >= 0 ? 'text-success' : 'text-danger' ]"
          >
            {{$helpers.numberFormat(resumen_order.dropdown_current ? resumen_order.dropdown_current : 0)}}
            {{resumen_order.drawdown_adsolute_current? resumen_order.drawdown_adsolute_current.toFixed(2) : resumen_order.drawdown_adsolute_current}}%
          </div>
          <div class="description">
            Current absolute <br>drawdown
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="3" class="px-1">
       <div class="content p-2 text-center">
          <div
            class="number mono"
            v-b-tooltip.hover
            :title="`Last update: ${resumen_order.last_update}`"
          >
            {{$helpers.numberFormat(resumen_order.drawdown_adsolute_current_maximum_coin ? resumen_order.drawdown_adsolute_current_maximum_coin : 0)}}
            {{resumen_order.drawdown_adsolute_current_maximum ? resumen_order.drawdown_adsolute_current_maximum.toFixed(2) : resumen_order.drawdown_adsolute_current_maximum}}%
          </div>
          <div class="description">
            Maximun absolute <br>drawdown
          </div>
        </div>
      </b-col>
      <b-col cols="12" class="px-1">
        <DrawdownHistory :historyData="historyData" v-if="historyData.length > 0" />
      </b-col>

    </b-row>
    <b-row class="resume p-2" v-if="(!$store.state.info_investor_loading ||  !$store.loadingInfoSubInvestor ||  !$store.loadingContributor) && 
      (($store.state.info_investor.grafic && $store.state.info_investor.grafic.length == 1) || 
      ($store.state.SubInvestorInfo.grafic && $store.state.SubInvestorInfo.grafic.length == 1) ||
      ($store.state.contributorInfo.grafic && $store.state.contributorInfo.grafic.length == 1))"
    >
      <div class="col-12">
        <b-alert class="mb-2 ml-3" show variant="dark">
          <span >No information available to display.</span>
        </b-alert>
      </div>
    </b-row>


    <div class="p-2">
      <b-skeleton-img no-aspect height="300px" v-if="$store.state.info_investor_loading || $store.state.loadingInfoSubInvestor ||  $store.state.loadingContributor"></b-skeleton-img>
      <span class="mx-2"></span>
      <b-skeleton-table
        v-if="$store.state.info_investor_loading || $store.state.loadingInfoSubInvestor || $store.state.loadingContributor"
        :rows="3"
        :columns="3"
        :table-props="{  striped: true }"
      ></b-skeleton-table>
    </div>

    <div class="row resume px-4 d-none">
      <div class="table-responsive px-1 mb-2">
        <label for="">Open order by symbol</label>
        <b-table 
          id="table"
          :items="open_ordres" 
          :fields="fields" 
          striped
          hover
          small
          borderless
          :busy="loading_open_orders"
          v-if="(open_ordres.length > 0 && !loading_open_orders ) || loading_open_orders"
        >
          <template #cell(symbol)="row">
            {{row.item.symbol.toUpperCase()}}
          </template>

          <template #cell(perfomance)="row">
            {{row.item.perfomance ? row.item.perfomance.toFixed(2) : 0}}
          </template>

          <template #cell(total_open_lot)="row">
            {{row.item.total_open_lot ? row.item.total_open_lot.toFixed(2) : 0}}
          </template>

          <template #table-busy>
            <b-skeleton-table
              :rows="5"
              :columns="4"
              :table-props="{  striped: true }"
            ></b-skeleton-table>
          </template>
        </b-table>
        <b-alert class="my-2" show variant="dark" v-if="open_ordres.length === 0 && !loading_open_orders">
          <span> No found orders. </span>
        </b-alert>

      </div>
      <b-pagination
        v-model="currentPage"
        :total-rows="paginate.records"
        :limit="paginate.per_page"
        :per-page="paginate.per_page"
        class="px-1 mb-2"
        @page-click="getOpenOrderPerfomance"
        v-if="paginate.total_pages > 1" >
      </b-pagination>
    </div>
    <div class="row resume px-4" v-if="fields_closed_orders && false">
      <div class="table-responsive px-1 mb-2">
        <label class="text-muted">Closed Orders Performance</label>
        <b-table 
          id="table"
          :items=" perfomance_closed_orders" 
          :fields="fields_closed_orders" 
          striped
          hover
          small
          borderless
          :busy="loading_closed_orders"
        >
          <template #cell(year)="value">
            <span>{{value.item.year == 0? '': value.item.year }}</span>
          </template>
          <template v-for="field in fields_closed_orders"  v-slot:[toCellName(field)]="value">
           <div v-if="value.item.year !==0" :key="field">
              <span v-if="field !== 'year'" :class="[getPerformanceValue(field, value.item) > 0 ? 'text-success' : getPerformanceValue(field, value.item) < 0? 'text-danger': '']">
                {{getPerformanceValue(field, value.item) == 0? '--': `${getPerformanceValue(field, value.item).toFixed(2)}%`}}</span>
              <span v-else >{{getPerformanceValue(field, value.item)}}</span>
           </div>
           <div v-else class="d-flex flex-row">
             <span v-if="field == 'Dec'" >TOTAL </span>
              <span v-if="field == 'Total'" :class="[getPerformanceValue(field, value.item) > 0 ? 'text-success' : getPerformanceValue(field, value.item) < 0? 'text-danger': '']">
                {{getPerformanceValue(field, value.item) == 0? '--': `${getPerformanceValue(field, value.item).toFixed(2)}%`}}
              </span>
              <span v-if="field.key =='year'">{{''}}</span>
              <span v-else></span>
           </div>
          </template>
          <template #table-busy>
            <b-skeleton-table
              :rows="5"
              :columns="4"
              :table-props="{  striped: true }"
            ></b-skeleton-table>
          </template>
        </b-table>
       
      </div>
    </div>
  </b-col>
</template>
<script>
  import handleSocket from '@/helpers/socket';
  import DrawdownHistory from '@/components/pages/investors/DrawdownHistory';
  import moment from 'moment'

	export default {
		name: 'ChartPerfomance',
    components: {
      DrawdownHistory,
    },
		computed: {
      showGraficBoolean() {
        let isLoading = this.$store.state.info_investor_loading || this.$store.state.loadingInfoSubInvestor || this.$store.state.loadingContributor;
        
        let investor = this.$store.state.info_investor && this.$store.state.info_investor.grafic && this.$store.state.info_investor.grafic.length > 1;
        
        if (this.$store.state.info_investor) {
          const { grafic } = this.$store.state.info_investor;
          investor = grafic && grafic.all
         
        } else {
          investor = false
        }


        let subInvestor = false;
        let contributor = false;
        
        try {
          const { grafic } = this.$store.state.SubInvestorInfo;
          subInvestor = grafic && grafic.all && grafic.all.length
        } catch(e) {
          subInvestor = false;
        }


        try {
          const { grafic } = this.$store.state.contributorInfo;
          contributor = grafic && grafic.all
        } catch(e) {
          contributor = false;
        }
        return !isLoading && (investor || (subInvestor || contributor));
      }
    },
    created() {
      this.token = this.$route.params.token;
      this.getResumen();
      this.getResumenHistory();
      // this.getOpenOrderPerfomance(1);
      const years = [new Date().getFullYear()]
      for(let i=1; i<5; i++) years.push(years[0] - i)
      years.reverse()
      if (this.$route.name.toLowerCase() == 'investor') {
        this.getClosedOrdersPerfomance(years);
      }
    },
		data() {
      let vm = this;
      return {
        algorithms: [],
  			symbol: 'BTCUSDT',
        url_socket: 'investor_dropdown',
        algorithm_token: null,
        fields_closed_orders: [{ label: '',key: 'year', visible: false },'Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec', 'Total'],
        modal__change_order_data__immediate_closure: 0,
        perfomance_closed_orders: [],
        total_performance: 0,
        chart__performance_options: {
          tooltip: {
            borderRadius: 0,
            x: {
              // format: 'dd/MM/yy HH:mm'
            },
            custom: function({series, seriesIndex, dataPointIndex, w}) {
              let date = vm.grafic[dataPointIndex].close_date_at;
              let profit = vm.grafic[dataPointIndex].perfomance;
              let profit_day = vm.grafic[dataPointIndex].perfomance_day;
              let ppb = vm.grafic[dataPointIndex].profit_percentage_balance;
              let ppbd = vm.grafic[dataPointIndex].profit_percentage_day;
              
              let class_ccs_ppb = 'text-success';
              if (ppb < 0) {
                class_ccs_ppb = 'text-danger';
              }

              let class_ccs_ppbd = 'text-success';
              if (ppbd < 0) {
                class_ccs_ppbd = 'text-danger';
              }
              
              return `
                <div style="background: #232429;" class="p-2">
                  <span class="d-block small">
                    From: <span class="text-muted">${date}</span><br>
                    To: <span class="text-muted">${date.substr(0, 10)} 23:59:59</span>
                  </span>
                  <span class="d-block small">
                    Daily N.A.V: <span class="mono ${class_ccs_ppb}">${vm.$helpers.numberFormat( profit)} (${ppb.toFixed(2)}%)</span>
                  </span>
                  <span class="d-block small">
                    Daily closed performance: <span class="mono ${class_ccs_ppbd}">${vm.$helpers.numberFormat( profit_day)} (${ppbd.toFixed(2)}%)</span>
                  </span>
                </div>
              `;
            }
          },
          annotations: {
            xaxis: [
            ]
          },
          chart: {
            animations: {
              enabled: false,
            },
            toolbar: {
              show: false
            },
          },
          grid: {
            show: false,
            padding: {
              left: -10,
              right: 0
            }
          },
          fill: {
            type: 'line',
            gradient: {
              shade: 'dark',
              gradientToColors: [ '#0D141D'],
              shadeIntensity: 1,
              type: 'vertical',
              opacityFrom: 0.5,
              opacityTo: 0.4,
              stops: [0, 100]
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            colors: ['#50E7FF'],
            width: 2,
            dashArray: 0,      
            curve: 'straight'
          },
          xaxis: {
            type: 'datetime',
            categories: [],
            labels: {
              show: false
            },
            tooltip: {
              enabled: false
            },
            axisBorder: {
              show: false,
              color: '#50E7FF',
            },
            axisTicks: {
              show: false,
              color: '#50E7FF',
            },
          },
          yaxis: {
            tooltip: {
              enabled: false
            },
            labels: {
              enabled: false,
              show: false,
              style: {
                  colors: ['#50E7FF'],
              }
            },
            axisBorder: {
              show: false,
              color: '#50E7FF',
            },
            axisTicks: {
              show: false,
              color: '#50E7FF',
            },
          },
        },
        chart__performance_series: [{
          name: 'series-1',
          data: []
        }],
        chart__performance_options_last_month: {
          tooltip: {
            borderRadius: 0,
            x: {
              // format: 'dd/MM/yy HH:mm'
            },
            custom: function({series, seriesIndex, dataPointIndex, w}) {

              let date = vm.grafic[dataPointIndex].close_date_at;
              let profit = vm.grafic[dataPointIndex].perfomance;
              let profit_day = vm.grafic[dataPointIndex].perfomance_day;
              let ppb = vm.grafic[dataPointIndex].profit_percentage_balance;
              let ppbd = vm.grafic[dataPointIndex].profit_percentage_day;
              
              let class_ccs_ppb = 'text-success';
              if (ppb < 0) {
                class_ccs_ppb = 'text-danger';
              }

              let class_ccs_ppbd = 'text-success';
              if (ppbd < 0) {
                class_ccs_ppbd = 'text-danger';
              }
              
              return `
                <div style="background: #232429;" class="p-2">
                  <span class="d-block small">
                    From: <span class="text-muted">${date}</span><br>
                    To: <span class="text-muted">${date.substr(0, 10)} 23:59:59</span>
                  </span>
                  <span class="d-block small">
                    Daily N.A.V: <span class="mono ${class_ccs_ppb}">${vm.$helpers.numberFormat( profit)} (${ppb.toFixed(2)}%)</span>
                  </span>
                  <span class="d-block small">
                    Daily closed performance: <span class="mono ${class_ccs_ppbd}">${vm.$helpers.numberFormat( profit_day)} (${ppbd.toFixed(2)}%)</span>
                  </span>
                </div>
              `;
            }
          },
          annotations: {
            xaxis: [
            ]
          },
          chart: {
            animations: {
              enabled: false,
            },
            toolbar: {
              show: false
            },
          },
          grid: {
            show: false,
            padding: {
              left: -10,
              right: 0
            }
          },
          fill: {
            type: 'line',
            gradient: {
              shade: 'dark',
              gradientToColors: [ '#0D141D'],
              shadeIntensity: 1,
              type: 'vertical',
              opacityFrom: 0.5,
              opacityTo: 0.4,
              stops: [0, 100]
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            colors: ['#50E7FF'],
            width: 2,
            dashArray: 0,      
            curve: 'straight'
          },
          xaxis: {
            type: 'datetime',
            categories: [],
            labels: {
              show: false
            },
            tooltip: {
              enabled: false
            },
            axisBorder: {
              show: false,
              color: '#50E7FF',
            },
            axisTicks: {
              show: false,
              color: '#50E7FF',
            },
          },
          yaxis: {
            tooltip: {
              enabled: false
            },
            labels: {
              enabled: false,
              show: false,
              style: {
                  colors: ['#50E7FF'],
              }
            },
            axisBorder: {
              show: false,
              color: '#50E7FF',
            },
            axisTicks: {
              show: false,
              color: '#50E7FF',
            },
          },
        },
        chart__performance_series_last_month: [{
          name: 'series-1',
          data: []
        }],
        chart__performance_options_last_year: {
          tooltip: {
            borderRadius: 0,
            x: {
              // format: 'dd/MM/yy HH:mm'
            },
            custom: function({series, seriesIndex, dataPointIndex, w}) {
              let date = vm.grafic[dataPointIndex].close_date_at;
              let profit = vm.grafic[dataPointIndex].perfomance;
              let profit_day = vm.grafic[dataPointIndex].perfomance_day;
              let ppb = vm.grafic[dataPointIndex].profit_percentage_balance;
              let ppbd = vm.grafic[dataPointIndex].profit_percentage_day;
              
              let class_ccs_ppb = 'text-success';
              if (ppb < 0) {
                class_ccs_ppb = 'text-danger';
              }

              let class_ccs_ppbd = 'text-success';
              if (ppbd < 0) {
                class_ccs_ppbd = 'text-danger';
              }
              
              return `
                <div style="background: #232429;" class="p-2">
                  <span class="d-block small">
                    From: <span class="text-muted">${date}</span><br>
                    To: <span class="text-muted">${date.substr(0, 10)} 23:59:59</span>
                  </span>
                  <span class="d-block small">
                    Daily N.A.V: <span class="mono ${class_ccs_ppb}">${vm.$helpers.numberFormat( profit)} (${ppb.toFixed(2)}%)</span>
                  </span>
                  <span class="d-block small">
                    Daily closed performance: <span class="mono ${class_ccs_ppbd}">${vm.$helpers.numberFormat( profit_day)} (${ppbd.toFixed(2)}%)</span>
                  </span>
                </div>
              `;
            }
          },
          annotations: {
            xaxis: [
            ]
          },
          chart: {
            animations: {
              enabled: false,
            },
            toolbar: {
              show: false
            },
          },
          grid: {
            show: false,
            padding: {
              left: -10,
              right: 0
            }
          },
          fill: {
            type: 'line',
            gradient: {
              shade: 'dark',
             // gradientToColors: [ '#0D141D'],
              shadeIntensity: 1,
              type: 'vertical',
              opacityFrom: 0.5,
              opacityTo: 0.4,
              stops: [0, 100]
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            colors: ['#50E7FF'],
            width: 2,
            dashArray: 0,      
            curve: 'straight'
          },
          xaxis: {
            type: 'datetime',
            categories: [],
            labels: {
              show: false
            },
            tooltip: {
              enabled: false
            },
            axisBorder: {
              show: false,
              color: '#50E7FF',
            },
            axisTicks: {
              show: false,
              color: '#50E7FF',
            },
          },
          yaxis: {
            tooltip: {
              enabled: false
            },
            labels: {
              enabled: false,
              show: false,
              style: {
                  colors: ['#50E7FF'],
              }
            },
            axisBorder: {
              show: false,
              color: '#50E7FF',
            },
            axisTicks: {
              show: false,
              color: '#50E7FF',
            },
          },
        },
        chart__performance_series_last_year: [{
          name: 'series-1',
          data: []
        }],
        token: '',
        grafic: [],
        grafic_last_month: [],
        grafic_last_year: [],
        resumen: {},
        monthly_yield: {},
        balance: 0,
        activeAllHistory: true,
        activeLastMonth: false,
        activeLastYear: false,
        paginate: {
          per_page: 15,
          records: 0,
          total_pages: 1
        },
        open_ordres: [],
        loading_open_orders: false,
        loading_closed_orders: false,
        currentPage: 1,
        fields: [
          {key: 'symbol', label: 'Symbol', sortable: true},
          {key: 'perfomance', label: 'Perfomance'},
          {key: 'total_open', label: 'Quantity', sortable: true},
          {key: 'total_open_lot', label: 'Lot'},
        ],
        resumen_order: {
          /*dropdown_maximun: 0,
          max_open_order: 0,
          porc: 0,
          token_user: "",*/
          current_investment: 0,
          total_commission: 0,
          total_operation: 0,
          dropdown_current: 0,
          dropdown_maximun: 0,
          max_open_order: 0,
          porc: 0,
          porc_current: 0,
          drawdown_adsolute_current_porc: 0,
          drawdown_adsolute_current_maximum_porc: 0,
          token_user: "",
          profit_net: 0,
        },
        decimals: 2,
        historyData: [],
      };
		},
		methods: {
      getPerformanceValue(field, value) {
        return value[field]
      },
      toCellName(slot) {
        return `cell(${slot})`
      },
      async getClosedOrdersPerfomance(years = []) {
        this.loading_closed_orders = true
        for (let i = 0; i<years.length; i++) {
          const year = years[i]
          let  investor_token = this.$route.params.token;
          const data = {
            investor_token,
            year
          }
          const response = await this.$http.server1('bwfs/admin/investor/orders/closed/perfomance', data)
          let orders = response.data;
          if (orders == undefined) {
            orders = [];
          }
          let order = {}
          for(let i = 0; i<orders.length; i++) {
            order[moment(orders[i].date, 'DD-MM').format('MMM')] = orders[i].profit_percentage
          }
          order.Total = response.total
          this.total_performance = this.total_performance + response.total
          order.year = year
          this.perfomance_closed_orders.push(order)
        }
        const totalOrders = {...this.perfomance_closed_orders[0]}
        totalOrders.Total = this.total_performance
        totalOrders.year = 0
        this.perfomance_closed_orders.push(totalOrders)
        this.loading_closed_orders = false
      },
      showGrafic(type) {
        if (type == 'all') {
          this.activeAllHistory = true;
          this.activeLastMonth = false;
          this.activeLastYear = false;
        } else if (type == 'month') {
          this.activeAllHistory = false;
          this.activeLastMonth = true;
          this.activeLastYear = false;
        } else if (type == 'year') {
          this.activeAllHistory = false;
          this.activeLastMonth = false;
          this.activeLastYear = true;
        }
      },
      handleEditMonthlyYieldBoolean(data, balance_inicial, total_operations) {
        let keys = Object.keys(data);
        let total_p = 0;
        for (let i = 0; i < keys.length; i++) {
          let mes_anterior = 0;
          if (i == 0) {
            mes_anterior = ((data[keys[i]].balance - balance_inicial) / balance_inicial) * 100;
          } else {
            mes_anterior = ((data[keys[i]].balance - data[keys[i - 1]].balance) / data[keys[i - 1]].balance) * 100;
          }
          if (((data[keys[i]].balance > balance_inicial && mes_anterior >= 0) || (data[keys[i]].balance === 0)) && data[keys[i]].perfomance >= 0) {
            total_p += 1;
          }
        }
        let p = ((total_p * 100) / keys.length);
        return (total_p === keys.length || p >= 80) && total_operations > 0; 
      },
      getOpenOrderPerfomance(event, pageNum) {
        if (this.loading_open_orders) {
          return;
        }
        this.currentPage = pageNum;
        let token = "";
        if (this.$route.name == 'Investor') {
          token = this.$store.state.info_investor.info.token;
        } else if (this.route.name == 'Subinvestor') {
          token = this.$store.state.SubInvestorInfo.info.token_user;
        }
        let data = {
          investor_token: token,
          limit: 15,
          page: this.currentPage
        }
        this.loading_open_orders = true;
        this.$http.server1('bwfs/admin/investors/orders/open/perfomance', data).then(res => {
          this.loading_open_orders = false;
          if (res.error) {
            return;
          }
          this.open_ordres = res.data;
          this.paginate = res.paginate;
        }).catch(e => {
          this.loading_open_orders = false;
        })
      },
      getResumen() {
        let data = {
          token_user: this.token,
        }

        if (this.algorithm_token) {
          data["algorithm_token"] = this.algorithm_token;
        }
        this.loading_open_orders = true;
        this.$http.server1('bwfs/admin/resumen', data).then(res => {
          this.loading_open_orders = false;
          if (res.error) {
            return;
          }
          if (res.data == 'Resumen not found') {
            return;
          }
          this.resumen_order = res.data;
        }).catch(e => {
          this.loading_open_orders = false;
        })
      },
      getResumenHistory() {
        let data = {
          token_user: this.token,
        }
        this.loading_open_orders = true;
        this.$http.server1('bwfs/admin/resumen/history', data).then(res => {
          this.loading_open_orders = false;
          if (res.error) {
            return;
          }
          this.historyData = res.data;
        }).catch(e => {
          this.loading_open_orders = false;
        })
      },
      market_type() {
        if (this.$store.state.info_investor.info.investor.configurations == '{}' || this.$store.state.info_investor.info.investor.configurations == '') {
          return false;
        } else {
          if (typeof this.$store.state.info_investor.info.investor.configurations == 'string') {
            let configuration = JSON.parse(this.$store.state.info_investor.info.investor.configurations);
            return configuration.market_type == 'spot';
          } else {
            return false;
          }
        }
      },
      //-- Socket
      conneting() {
        this.socketConnected = true;
        this.websocket = handleSocket(this.url_socket, this.onOpen, this.onClose, this.onMessage, this.onError);
      },
      sendConnect() {
        let market_type = this.market_type();
        this.doSend(this.$store.state.info_investor.info.token);
        this.doSend(market_type ? 1 : 0);
        this.socketConnected = true;
      },
      onOpen() {
        if (!this.$store.state.info_investor.info) {
          let interval = setInterval(() => {

            if (this.$store.state.info_investor.info && this.$store.state.open_orders_paginate.records > 0) {
              let status = this.$store.state.info_investor.info.investor.status;
              clearInterval(interval);
              if (status == 1 || status == 2) {
                this.writeToScreen("CONNECTED");
                this.sendConnect();
              }
            }
          }, 250);
        } else {
          let status = this.$store.state.info_investor.info.investor.status;
          if (status == 1 || status == 2) {
            this.writeToScreen("CONNECTED");
            this.sendConnect();
          }
        }
      },
      onClose(evt) {
        this.writeToScreen("DISCONNECTED");
      },
      onMessage(evt) {
        if (evt.data) {
          let dropdown = JSON.parse(evt.data);
          if (dropdown.length > 0) {
            this.$store.commit("set_dropdown", dropdown);
          }
        }
      },
      onError(evt) {
        console.error(evt);
      },
      doSend(message) {
        this.websocket.send(message);
      },
      writeToScreen(message) {
        console.log("writeToScreen", message);
      },
      setDecimals() {
        this.decimals = 2;
        if (this.$store.state.info_investor.info) {
          try {
            if (this.$store.state.info_investor.info.base_symbol == 'btc') {
              this.decimals = 8
            } else if (this.$store.state.info_investor.info.base_symbol == 'bnb' || this.$store.state.info_investor.info.base_symbol == 'eth') {
              this.decimals = 4
            }
          } catch(e) {
            console.log(e.message);
          }
        }
      }
		},
    watch:{
      '$store.state.info_investor'(value) {
        this.setDecimals();
        // this.getOpenOrderPerfomance(null, 1);
        let perfomance = [];
        let perfomance_last_month = [];
        let perfomance_last_year = [];
        let categories = [];
        let categories_last_month = [];
        let categories_last_year = [];

        let last_month = new Date();
        last_month.setMonth(last_month.getMonth() - 1);
        let last_month_time = last_month.getTime();

        let last_year = new Date();
        last_year.setFullYear(last_year.getFullYear() - 1);
        let last_year_time = last_year.getTime();

        this.grafic_last_year = [];
        this.grafic_last_month = [];
        this.grafic = [];


        this.grafic = value.grafic;
        this.resumen = value.resumen;
        this.symbol = value.info.investor.symbol;
        this.balance = value.info.investor.balance;
        this.algorithms = value.conditions.map(condition => condition.condition);
        
        this.chart__performance_series[0].data = [];
        this.chart__performance_options.xaxis.categories = [];

        this.chart__performance_series_last_month[0].data = [];
        this.chart__performance_options_last_month.xaxis.categories = [];

        this.chart__performance_series_last_year[0].data = [];
        this.chart__performance_options_last_year.xaxis.categories = [];

        if (value.grafic.all.length == 0) {
          return;
        }

        let tm = {};
        let current_date = new Date()
        let start_month = new Date(value.grafic.all[1].close_date_at.substr(0,10))
        let next_month = new Date(value.grafic.all[1].close_date_at.substr(0,10))
        next_month.setMonth(start_month.getMonth() + 1)


        let fechaInicio = new Date(value.info.investor.created_at.substr(0,10));
        fechaInicio = new Date(fechaInicio.getUTCFullYear(), fechaInicio.getUTCMonth(),
                fechaInicio.getUTCDate(), fechaInicio.getUTCHours(),
                fechaInicio.getUTCMinutes(), fechaInicio.getUTCSeconds()).getTime();
        
        let fechaFin    = new Date();
        fechaFin    = new Date(fechaFin.getUTCFullYear(), fechaFin.getUTCMonth(),
                fechaFin.getUTCDate(), fechaFin.getUTCHours(),
                fechaFin.getUTCMinutes(), fechaFin.getUTCSeconds()).getTime();
        
        let diff =  fechaFin - fechaInicio;
        
        let total_month = parseInt(diff/(1000*60*60*24) / 30)
        let total_month_add = 0

        for (let i = 0; i < value.grafic.all.length; i++) {
          let data = value.grafic.all[i];
          //set all history
          perfomance.push(data.perfomance);
          categories.push(i+1);
          let date_month = data.close_date_at.substr(0,7);

          // si el mes es el que esta en curso se omite
          if (date_month.substr(5) == (new Date()).getMonth()+1) {
            continue;
          }

          if (date_month && total_month_add <= total_month) {
            
            let current_month_data = (new Date(data.close_date_at.substr(0,10))).getTime()
              
            if (!tm[date_month]) {
              total_month_add += 1
              tm[date_month] = {};
              tm[date_month].perfomance = 0;
              tm[date_month].balance = 0;
            }

            tm[date_month].perfomance += Number(data.profit_percentage);
            tm[date_month].balance = Number(data.perfomance);
          }
        }

        this.grafic_last_year =  value.grafic.last_year
        this.grafic_last_month =  value.grafic.last_month
        this.grafic =  value.grafic.all

        this.monthly_yield = tm;
        this.chart__performance_series[0].data = value.grafic.all.map(e => e.perfomance);
        this.chart__performance_options.xaxis.categories = value.grafic.all.map((e, i) => i);

        this.chart__performance_series_last_month[0].data = value.grafic.last_month.map(e => e.perfomance);
        this.chart__performance_options_last_month.xaxis.categories = value.grafic.last_month.map((e, i) => i);

        this.chart__performance_series_last_year[0].data = value.grafic.last_year.map(e => e.perfomance);
        this.chart__performance_options_last_year.xaxis.categories = value.grafic.last_year.map((e, i) => i);
      },
      '$store.state.SubInvestorInfo'(value) {
        this.setDecimals();
        // this.getOpenOrderPerfomance(null, 1);
        let perfomance = [];
        let perfomance_last_month = [];
        let perfomance_last_year = [];
        let categories = [];
        let categories_last_month = [];
        let categories_last_year = [];

        let last_month = new Date();
        last_month.setMonth(last_month.getMonth() - 1);
        let last_month_time = last_month.getTime();

        let last_year = new Date();
        last_year.setFullYear(last_year.getFullYear() - 1);
        let last_year_time = last_year.getTime();


        this.grafic_last_year = [];
        this.grafic_last_month = [];
        this.grafic = value.grafic;
        this.resumen = value.resumen;
        this.symbol = value.symbol;
        this.balance = value.balance;

        let tm = {};
        for (let i = 0; i < value.grafic.length; i++) {
          let data = value.grafic[i];
          //set all history
          perfomance.push(data.accum);
          categories.push(i+1);

          if (data && data.close_date_at  == "") {
            let data_next = this.grafic[i+1];
            if (data_next && last_year_time >= data_next.close_date) {
              this.grafic_last_year.push(data);
              perfomance_last_year.push(data.accum);
              categories_last_year.push(i+1);
            }

            if (data_next && (new Date(data_next.close_date_at).getTime()) >= last_month_time) {
              this.grafic_last_month.push(data);
              perfomance_last_month.push(data.accum);
              categories_last_month.push(i+1);
            }
            continue
          }

          // console.log(last_month_time, last_month, data.close_date, data.close_date_at);
          // set last month
          if ((new Date(data.close_date_at).getTime()) >= last_month_time  && data.close_date_at != "") {
            this.grafic_last_month.push(data);
            perfomance_last_month.push(data.accum);
            categories_last_month.push(i+1);
          }
          // set last year
          if (last_year_time >= data.close_date && data.close_date_at != "") {
            this.grafic_last_year.push(data);
            perfomance_last_year.push(data.accum);
            categories_last_year.push(i+1);
          }

          let date_month = data.close_date_at.substr(0,7);
          if (data.profit_percentage == undefined) {
            data.profit_percentage  = 0;
          }
          if (date_month) {
            if (tm[date_month]) {
              tm[date_month].perfomance += Number(data.profit_percentage);
              tm[date_month].balance = Number(data.accum);
            } else {
              tm[date_month] = {};
              tm[date_month].perfomance = Number(data.profit_percentage);
              tm[date_month].balance = Number(data.accum);
            }
          }
        }
        this.monthly_yield = tm;
        this.chart__performance_series[0].data = perfomance;
        this.chart__performance_options.xaxis.categories = categories;

        this.chart__performance_series_last_month[0].data = perfomance_last_month;
        this.chart__performance_options_last_month.xaxis.categories = categories_last_month;

        this.chart__performance_series_last_year[0].data = perfomance_last_year;
        this.chart__performance_options_last_year.xaxis.categories = categories_last_year;
      },
      '$store.state.contributorInfo'(value) {
        this.setDecimals();
        // this.getOpenOrderPerfomance(null, 1);
        let perfomance = [];
        let perfomance_last_month = [];
        let perfomance_last_year = [];
        let categories = [];
        let categories_last_month = [];
        let categories_last_year = [];

        let last_month = new Date();
        last_month.setMonth(last_month.getMonth() - 1);
        let last_month_time = last_month.getTime();

        let last_year = new Date();
        last_year.setFullYear(last_year.getFullYear() - 1);
        let last_year_time = last_year.getTime();


        this.grafic_last_year = [];
        this.grafic_last_month = [];
        this.grafic = value.grafic;
        this.resumen = value.resumen;
        this.symbol = value.symbol;
        this.balance = value.balance;

        let tm = {};
        for (let i = 0; i < value.grafic.length; i++) {
          let data = value.grafic[i];
          //set all history
          perfomance.push(data.accum);
          categories.push(i+1);

          if (data && data.close_date_at  == "") {
            let data_next = this.grafic[i+1];
            if (data_next && last_year_time >= data_next.close_date) {
              this.grafic_last_year.push(data);
              perfomance_last_year.push(data.accum);
              categories_last_year.push(i+1);
            }

            if (data_next && (new Date(data_next.close_date_at).getTime()) >= last_month_time) {
              this.grafic_last_month.push(data);
              perfomance_last_month.push(data.accum);
              categories_last_month.push(i+1);
            }
            continue
          }

          // console.log(last_month_time, last_month, data.close_date, data.close_date_at);
          // set last month
          if ((new Date(data.close_date_at).getTime()) >= last_month_time  && data.close_date_at != "") {
            this.grafic_last_month.push(data);
            perfomance_last_month.push(data.accum);
            categories_last_month.push(i+1);
          }
          // set last year
          if (last_year_time >= data.close_date && data.close_date_at != "") {
            this.grafic_last_year.push(data);
            perfomance_last_year.push(data.accum);
            categories_last_year.push(i+1);
          }

          let date_month = data.close_date_at.substr(0,7);
          if (data.profit_percentage == undefined) {
            data.profit_percentage  = 0;
          }
          if (date_month) {
            if (tm[date_month]) {
              tm[date_month].perfomance += Number(data.profit_percentage);
              tm[date_month].balance = Number(data.accum);
            } else {
              tm[date_month] = {};
              tm[date_month].perfomance = Number(data.profit_percentage);
              tm[date_month].balance = Number(data.accum);
            }
          }
        }
        this.monthly_yield = tm;
        

        this.chart__performance_series[0].data = perfomance;
        this.chart__performance_options.xaxis.categories = categories;

        this.chart__performance_series_last_month[0].data = perfomance_last_month;
        this.chart__performance_options_last_month.xaxis.categories = categories_last_month;

        this.chart__performance_series_last_year[0].data = perfomance_last_year;
        this.chart__performance_options_last_year.xaxis.categories = categories_last_year;
      },
      algorithm_token(value){
        let investor_token = this.$store.state.info_investor.info.token;
        const payload = {
          vm: this,
          data: {
            investor_token: investor_token,
          },
        }
        if (value) {
          payload["data"]["algorithm_token"] = value;
        }
        this.$store.commit('get_info_investor', payload);
        this.$store.commit('bwfs_selected_algorithm_token', value);
        this.getResumen();
        this.getResumenHistory();
        // añadir filtro para obtener las ordenes abiertas 
        // this.getOpenOrderPerfomance(null, 1);
      }
    },
    filters: {
      handleEditMonthlyYield(data, balance_inicial) {
        let keys = Object.keys(data);
        let total_p = 0;
        for (let i = 0; i < keys.length; i++) {
          let mes_anterior = 0;
          if (i == 0) {
            mes_anterior = ((data[keys[i]].balance - balance_inicial) / balance_inicial) * 100;
          } else {
            mes_anterior = ((data[keys[i]].balance - data[keys[i - 1]].balance) / data[keys[i - 1]].balance) * 100;
          }

          if (((data[keys[i]].balance > balance_inicial && mes_anterior >= 0) || (data[keys[i]].balance === 0)) && data[keys[i]].perfomance >= 0) {
            total_p += 1;
          }
        }
        return `${total_p}/${keys.length}`;
      },
      handleEditMonthlyYieldPercentaje(data, balance_inicial) {
        let keys = Object.keys(data);
        let total_p = 0;
        for (let i = 0; i < keys.length; i++) {
          let mes_anterior = 0;
          if (i == 0) {
            mes_anterior = ((data[keys[i]].balance - balance_inicial) / balance_inicial) * 100;
          } else {
            mes_anterior = ((data[keys[i]].balance - data[keys[i - 1]].balance) / data[keys[i - 1]].balance) * 100;
          }
          if (((data[keys[i]].balance > balance_inicial && mes_anterior >= 0) || (data[keys[i]].balance === 0)) && data[keys[i]].perfomance >= 0) {
            total_p += 1;
          }
        }
        if (isNaN(((total_p * 100) / keys.length))) {
          return "0%";
        }
        return `${((total_p * 100) / keys.length).toFixed(2)}%`;
      },
      maximum_open_orders(state) {
        if (state.info_investor.info) {
          return state.info_investor.info.investor.maximum_open_orders;
        } else if (state.SubInvestorInfo.info) {
          return state.SubInvestorInfo.resumen.maximum_open_orders;
        } else if (state.contributorInfo.info) {
          return state.contributorInfo.resumen.maximum_open_orders;
        }
      },
      current_dropdown_profits(state) {
        if (state.info_investor.info && state.info_investor.info.investor.current_dropdown_profits) {
          return state.info_investor.info.investor.current_dropdown_profits.toFixed(2);
        } else if (state.SubInvestorInfo.info && state.SubInvestorInfo.info.current_dropdown_profits) {
          return state.SubInvestorInfo.info.current_dropdown_profits.toFixed(2);
        } else if (state.contributorInfo.info && state.contributorInfo.info.current_dropdown_profits) {
          return state.contributorInfo.info.current_dropdown_profits.toFixed(2);
        }
        return 0;
      },
      current_dropdown(state) {
        if (state.info_investor.info && state.info_investor.info.investor.current_dropdown) {
          return state.info_investor.info.investor.current_dropdown.toFixed(2);
        } else if (state.SubInvestorInfo.info && state.SubInvestorInfo.info.current_dropdown) {
          return state.SubInvestorInfo.info.current_dropdown.toFixed(2);
        } else if (state.contributorInfo.info && state.contributorInfo.info.current_dropdown) {
          return state.contributorInfo.info.current_dropdown.toFixed(2);
        }
        return 0;
      },
      maximun_dropdown_profits(state) {
        if (state.info_investor.info && state.info_investor.info.investor.maximun_dropdown_profits) {
          return state.info_investor.info.investor.maximun_dropdown_profits.toFixed(2);
        } else if (state.SubInvestorInfo.info && state.SubInvestorInfo.info.maximun_dropdown_profits) {
          return state.SubInvestorInfo.info.maximun_dropdown_profits.toFixed(2);
        } else if (state.contributorInfo.info && state.contributorInfo.info.maximun_dropdown_profits) {
          return state.contributorInfo.info.maximun_dropdown_profits.toFixed(2);
        }
        return 0;
      },
      maximun_dropdown(state) {
        if (state.info_investor.info && state.info_investor.info.investor.maximun_dropdown) {
          return state.info_investor.info.investor.maximun_dropdown.toFixed(2);
        } else if (state.SubInvestorInfo.info && state.SubInvestorInfo.info.maximun_dropdown) {
          return state.SubInvestorInfo.info.maximun_dropdown.toFixed(2);
        } else if (state.contributorInfo.info && state.contributorInfo.info.maximun_dropdown) {
          return state.contributorInfo.info.maximun_dropdown.toFixed(2);
        }
        return 0;
      },


    },
	};
</script>