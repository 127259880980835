<template>
  <b-modal
    id="modal-show-investors"
    size="md"
    hide-footer
    hide-header
    v-model="showModal"
    centered
    @hide="closeModal()"
  >
    <div class="groups-investors-list">Group's Investors list</div>
    <b-table
      id="table-group-investors"
      :items="investors"
      :fields="fields"
      striped
      hover
      small
      borderless
    >
    </b-table>
    <b-button
      class="btn btn-success ml-2 btn-group-investors"
      @click="closeModal"
    >
      Ok
    </b-button>
  </b-modal>
</template>

<script>
export default {
  name: "GroupInvestorListModal",
  props: ["show", "investors"],
  data() {
    return {
      showModal: false,
      fields: [
        { key: "token", sortable: true },
        { key: "name", sortable: true },
      ],
    };
  },
  watch: {
    show(newValue) {
      this.showModal = newValue;
      if (newValue === 0) {
        this.showModal = 1;
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit("hide");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/pages/groups.scss";
</style>
