var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$route.name && _vm.$route.name.toLowerCase() == 'dashboard')?_c('HeaderTopSliders'):_vm._e(),_c('div',{class:`component_template__topbar`},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"p-0"},[_c('b-col',{staticClass:"p-0",attrs:{"cols":"12","lg":"12"}},[_c('b-navbar',{staticClass:"main-navbar",attrs:{"toggleable":"lg","type":"dark","variant":"dark"}},[_c('b-navbar-brand',{attrs:{"href":"#"}},[_c('img',{staticClass:"logo",attrs:{"src":"/logo_white.png","alt":"logo"}})]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),(_vm.$route.name && _vm.$route.name.toLowerCase() == 'investor')?_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('PriceRealTime'),(
                  _vm.$store.state.user.token &&
                  _vm.$store.state.user.role_id !== 1 &&
                  _vm.$store.state.user.role_id !== 2
                )?_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-navbar-nav',{staticClass:"users_menu"},[_c('b-nav-item',{attrs:{"href":"#"}},[_c('b-button',{attrs:{"variant":"dark"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_vm._v(" Sign off "),_c('b-icon-power',{staticClass:"ml-1"})],1)],1)],1)],1):_vm._e()],1):_vm._e(),(_vm.$store.state.token && _vm.$store.state.user.role_id === 1)?_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-navbar-nav',[(
                      _vm.$route.name.toLowerCase() !== 'investor' &&
                      _vm.$route.name.toLowerCase() != 'subinvestor' &&
                      _vm.$route.name.toLowerCase() !== 'contributor'
                    )?_c('li',{staticClass:"nav-item"},[_c('b-dropdown',{staticClass:"m-md-2",attrs:{"id":"dropdown-1","text":"Management"}},[_c('b-dropdown-item',{attrs:{"to":{ name: 'Supervisors', params: {} }}},[(
                            _vm.$route.name.toLowerCase() !== 'investor' &&
                            _vm.$route.name.toLowerCase() != 'subinvestor' &&
                            _vm.$route.name.toLowerCase() !== 'contributor'
                          )?_c('li',{staticClass:"nav-item",staticStyle:{"justify-content":"left","margin-left":"2px"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'Supervisors', params: {} }}},[_vm._v(" Supervisors ")])],1):_vm._e()]),_c('b-dropdown-item',{attrs:{"to":{ name: 'Investors', params: {} }}},[_c('li',{staticClass:"nav-item",staticStyle:{"justify-content":"left","margin-left":"2px"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'Investors', params: {} }}},[_vm._v(" Investors ")])],1)]),_c('b-dropdown-item',{attrs:{"to":{ name: 'InvestorsList', params: {} }}},[_c('li',{staticClass:"nav-item",staticStyle:{"justify-content":"left","margin-left":"2px"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'InvestorsList', params: {} }}},[_vm._v(" Investors v2 ")])],1)]),_c('b-dropdown-item',{attrs:{"to":{ name: 'Authorizations', params: {} }}},[(
                            _vm.$route.name.toLowerCase() !== 'investor' &&
                            _vm.$route.name.toLowerCase() != 'subinvestor' &&
                            _vm.$route.name.toLowerCase() !== 'contributor'
                          )?_c('li',{staticClass:"nav-item",staticStyle:{"justify-content":"left","margin-left":"2px"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'Authorizations', params: {} }}},[_vm._v(" Authorizations "),(_vm.totalPendingAuthorization > 0)?_c('span',{staticClass:"badget badget-info"},[_vm._v(_vm._s(_vm.totalPendingAuthorization))]):_vm._e()])],1):_vm._e()]),_c('b-dropdown-item',{attrs:{"to":{ name: 'notifications', params: {} }}},[(
                            _vm.$route.name.toLowerCase() !== 'investor' &&
                            _vm.$route.name.toLowerCase() != 'subinvestor' &&
                            _vm.$route.name.toLowerCase() !== 'contributor'
                          )?_c('li',{staticClass:"nav-item",staticStyle:{"justify-content":"left","margin-left":"2px"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'notifications', params: {} }}},[_vm._v(" Notifications ")])],1):_vm._e()]),_c('b-dropdown-item',{attrs:{"to":{ name: 'scripts/authorization', params: {} }}},[(
                            _vm.$route.name.toLowerCase() !== 'investor' &&
                            _vm.$route.name.toLowerCase() != 'subinvestor' &&
                            _vm.$route.name.toLowerCase() !== 'contributor'
                          )?_c('li',{staticClass:"nav-item",staticStyle:{"justify-content":"left","margin-left":"2px"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                              name: 'scripts/authorization',
                              params: {},
                            }}},[_vm._v(" Authorization Script ")])],1):_vm._e()]),_c('b-dropdown-item',{attrs:{"to":{ name: 'request/logs', params: {} }}},[(
                            _vm.$route.name.toLowerCase() !== 'investor' &&
                            _vm.$route.name.toLowerCase() != 'subinvestor' &&
                            _vm.$route.name.toLowerCase() !== 'contributor'
                          )?_c('li',{staticClass:"nav-item",staticStyle:{"justify-content":"left","margin-left":"2px"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'request/logs', params: {} }}},[_vm._v(" Request logs ")])],1):_vm._e()]),_c('b-dropdown-item',{attrs:{"to":{ name: 'Groups', params: {} }}},[_c('li',{staticClass:"nav-item",staticStyle:{"justify-content":"left","margin-left":"2px"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'Groups', params: {} }}},[_vm._v(" Groups ")])],1)]),_c('b-dropdown-item',{attrs:{"to":{ name: 'HealthAudit', params: {} }}},[_c('li',{staticClass:"nav-item",staticStyle:{"justify-content":"left","margin-left":"2px"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'HealthAudit', params: {} }}},[_vm._v(" Health Audit ")])],1)]),_c('b-dropdown-item',{attrs:{"to":{ name: 'SystemsHeartbeat', params: {} }}},[_c('li',{staticClass:"nav-item",staticStyle:{"justify-content":"left","margin-left":"2px"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'SystemsHeartbeat', params: {} }}},[_vm._v(" Systems heartbeat ")])],1)])],1)],1):_vm._e(),(
                      _vm.$route.name.toLowerCase() !== 'investor' &&
                      _vm.$route.name.toLowerCase() != 'subinvestor' &&
                      _vm.$route.name.toLowerCase() !== 'contributor'
                    )?_c('li',{staticClass:"nav-item"},[_c('b-dropdown',{staticClass:"m-md-2",attrs:{"id":"dropdown-1","text":"Systems"}},[_c('b-dropdown-item',{attrs:{"to":{ name: 'LMDD', params: {} }}},[(
                            _vm.$route.name.toLowerCase() !== 'investor' &&
                            _vm.$route.name.toLowerCase() != 'subinvestor' &&
                            _vm.$route.name.toLowerCase() !== 'contributor'
                          )?_c('li',{staticClass:"nav-item",staticStyle:{"justify-content":"left","margin-left":"2px"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'LMDD', params: {} }}},[_vm._v(" LMDD (Collector) ")])],1):_vm._e()]),_c('b-dropdown-item',{attrs:{"to":{ name: 'LMDD', params: {} }}},[(
                            _vm.$route.name.toLowerCase() !== 'investor' &&
                            _vm.$route.name.toLowerCase() != 'subinvestor' &&
                            _vm.$route.name.toLowerCase() !== 'contributor'
                          )?_c('li',{staticClass:"nav-item",staticStyle:{"justify-content":"left","margin-left":"2px"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'LMDDAccount', params: {} }}},[_vm._v(" LMDD (Account) ")])],1):_vm._e()]),_c('b-dropdown-item',{attrs:{"to":{ name: 'ServersManagement', params: {} }}},[(
                            _vm.$route.name.toLowerCase() !== 'investor' &&
                            _vm.$route.name.toLowerCase() != 'subinvestor' &&
                            _vm.$route.name.toLowerCase() !== 'contributor'
                          )?_c('li',{staticClass:"nav-item",staticStyle:{"justify-content":"left","margin-left":"2px"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'ServersManagement', params: {} }}},[_vm._v(" Backup ")])],1):_vm._e()]),_c('b-dropdown-item',{attrs:{"to":{ name: 'Backtestings', params: {} }}},[(
                            _vm.$route.name.toLowerCase() !== 'investor' &&
                            _vm.$route.name.toLowerCase() != 'subinvestor' &&
                            _vm.$route.name.toLowerCase() !== 'contributor'
                          )?_c('li',{staticClass:"nav-item",staticStyle:{"justify-content":"left","margin-left":"2px"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'Backtestings', params: {} }}},[_vm._v(" Backtestings ")])],1):_vm._e()]),_c('b-dropdown-item',[(
                            _vm.$route.name.toLowerCase() !== 'investor' &&
                            _vm.$route.name.toLowerCase() != 'subinvestor' &&
                            _vm.$route.name.toLowerCase() !== 'contributor'
                          )?_c('li',{staticClass:"nav-item",staticStyle:{"justify-content":"left","margin-left":"2px"}},[_c('span',{staticClass:"nav-link",staticStyle:{"display":"flex","align-items":"center"},on:{"click":function($event){$event.preventDefault();return _vm.toggleMaintenanceMode.apply(null, arguments)}}},[_c('span',{class:[
                                'maintenance_mode',
                                _vm.maintenanceMode ? 'active' : null,
                              ]}),_vm._v("Maintenance mode")])]):_vm._e()])],1)],1):_vm._e(),(_vm.$route.name.toLowerCase() === 'investor')?_c('li',{staticClass:"nav-item",staticStyle:{"font-size":"24px"}},[_c('a',[(!_vm.$store.state.expert_mode)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"turn-button",attrs:{"title":"Activate expert mode"},on:{"click":function($event){return _vm.$store.commit('set_expert_mode', true)}}},[_c('b-icon-toggle-off',{staticClass:"text-success"})],1):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"turn-button",attrs:{"title":"Deactivate expert mode"},on:{"click":function($event){return _vm.$store.commit('set_expert_mode', false)}}},[_c('b-icon-toggle-on',{staticClass:"text-success"})],1)])]):_vm._e(),(_vm.$route.name.toLowerCase() === 'investor')?_c('li',{staticClass:"nav-item"},[_c('span',{staticClass:"nav-link"},[_vm._v(" Expert mode ")])]):_vm._e(),(_vm.$route.name.toLowerCase() === 'investor')?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'Investors', params: {} }}},[_vm._v(" Investors ")])],1):_vm._e(),(
                      _vm.$store.state.user.token &&
                      _vm.$store.state.user.role_id === 1 &&
                      !_vm.$route.params.view_for_admin &&
                      _vm.$store.state.investors_view_mode == 0 &&
                      _vm.is_dashboard
                    )?_c('b-nav-item',{attrs:{"href":"#"},on:{"click":_vm.openNotificationsModal}},[_c('div',[_c('img',{staticClass:"position-absolute",attrs:{"src":_vm.bell_icon,"alt":""}}),_c('div',{staticClass:"alert-notification"},[_vm._v(" "+_vm._s(_vm.notification_records)+" ")])])]):_vm._e(),(
                      _vm.$store.state.user.token &&
                      _vm.$store.state.user.role_id === 1
                    )?_c('b-nav-item',{attrs:{"href":"#"}},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-code-qr-user",modifiers:{"modal-code-qr-user":true}}],attrs:{"variant":"dark"}},[_vm._v(" QR ")])],1):_vm._e(),(
                      (_vm.$route.name.toLowerCase() !== 'investor' &&
                        _vm.$route.name.toLowerCase() != 'subinvestor' &&
                        _vm.$route.name.toLowerCase() !== 'contributor') ||
                      _vm.$route.name.toLowerCase() !== 'supervisor'
                    )?_c('b-nav-item',{attrs:{"href":"#"}},[_c('b-button',{attrs:{"variant":"dark"}},[_vm._v(" Server time: "+_vm._s(_vm.dateTime)+" UTC ")])],1):_vm._e(),_c('b-nav-item',{attrs:{"href":"#"}},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_vm._v(" Sign off "),_c('b-icon-power',{staticClass:"ml-1"})],1)],1)],1)],1)],1):_vm._e(),(_vm.$store.state.token && _vm.$store.state.user.role_id === 2)?_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[(
                  _vm.$store.state.user.token && _vm.$store.state.user.role_id !== 1
                )?_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-dropdown',{staticClass:"m-md-2",attrs:{"id":"dropdown-2","text":"Management"}},[(
                        _vm.$route.name.toLowerCase() !== 'investors' &&
                        _vm.$route.name.toLowerCase() != 'subinvestor' &&
                        _vm.$route.name.toLowerCase() !== 'contributor'
                      )?_c('li',{staticClass:"nav-item"},[_c('b-dropdown-item',{attrs:{"to":{ name: 'InvestorsList', params: {} }}},[_c('li',{staticClass:"nav-item",staticStyle:{"justify-content":"left","margin-left":"2px"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'InvestorsList', params: {} }}},[_vm._v(" Investors ")])],1)])],1):_vm._e()])],1),_c('b-navbar-nav',{staticClass:"users_menu"},[_c('b-nav-item',{attrs:{"href":"#"}},[_c('b-button',{attrs:{"variant":"dark"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_vm._v(" Sign off "),_c('b-icon-power',{staticClass:"ml-1"})],1)],1)],1)],1):_vm._e()],1):_vm._e()],1)],1)],1),_c('b-modal',{attrs:{"id":"modal-code-qr-user","title":"2FA QR Code"},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ ok, cancel }){return undefined}}])},[_c('div',{staticStyle:{"text-align":"center"}},[_c('vue-qr',{attrs:{"text":_vm.codeQR,"size":300}})],1)])],1),_c('NotificationsModal',{attrs:{"notifications":_vm.notifications,"show":_vm.show_notifications_modal},on:{"closed":function($event){_vm.show_notifications_modal = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }