const pako = require("pako");

export default (str) => {
  const str_data = atob(str);
  const data = str_data.split("").map(function (x) {
    return x.charCodeAt(0);
  });
  const bin_data = new Uint8Array(data);
  return pako.inflate(bin_data, { to: "string" });
};
