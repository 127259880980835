<template>
  <div class="json-visualizer">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-12">
          <div class="position-relative">
            <div class="json-linenumbers"></div>
            <div class="json-container">
              <div :class="{ 'max-height': maxHeight }">
                <vue-json-pretty :data="json" :deep="1" :showLine="false" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

export default {
  name: "json_visualizer",
  components: {
    VueJsonPretty,
  },
  props: {
    backgroundJsonContainer: {
      default: "",
      type: String,
    },
    backgroundLines: {
      default: "",
      type: String,
    },
    json: {
      required: true,
    },
    maxHeight: {
      default: "",
      type: String,
    },
  },
};
</script>

<style lang="scss">
@import "./styles/json_visualizer.scss";
</style>

<style lang="scss">
.json-container {
  background: v-bind("backgroundJsonContainer");
}
.json-linenumbers {
  background: v-bind("backgroundLines");
}
.json-visualizer .json-container > div.max-height {
  max-height: v-bind("maxHeight");
}
</style>
