<template>
  <div class="header-bar-container">
    <div class="header-bar max-width">
      <div>
        <span class="breadcrumb-home" v-if="isLogged"
          ><router-link :to="{ name: 'Investors', params: {} }"
            >Investors</router-link
          >
          /
        </span>
        <span class="breadcrumb-current" v-if="isLogged"> Health audit </span>
      </div>
      <div class="filter-bars">
        <Multiselect
          v-model="investor"
          label="name"
          mode="multiple"
          placeholder="Select your character"
          valueProp="token"
          :clearOnSelect="false"
          :closeOnSelect="false"
          :hideSelected="false"
          :limit="-1"
          :object="true"
          :options="investors_options"
        >
          <template v-slot:caret>
            <span class="multiselect-caret">
              <img :src="icon_caret" alt="" />
            </span>
          </template>
          <template v-slot:multiplelabel>
            <div class="multiselect-multiple-label">
              <span>{{ investors_selected }} </span>
              <span @click="removeInvestor"
                ><img :src="close_icon" alt=""
              /></span>
            </div>
            <span class="cant"> {{ cant_investor }}</span>
          </template>
          <template v-slot:option="{ option }">
            <div class="form-check d-flex p-0 mb-12px w-100">
              <input
                class="cb-filter"
                type="checkbox"
                :checked="isSelected(option)"
                :id="option.token"
                @change="toggleSelection(option)"
              />
              <label
                v-if="option.name.length > 30"
                v-b-tooltip.hover
                :title="option.name"
                class="label-checkbox w-100"
              >
                {{ optionName(option.name) }}</label
              >
              <label v-else class="label-checkbox w-100" :for="option.token">
                {{ option.name }}
              </label>
            </div>
          </template>
        </Multiselect>
        <button @click="openDownloadReportModal">
          <span><img :src="download_icon" alt="" /></span>Download report
        </button>
      </div>
    </div>
    <DownloadReport
      :show="showDownloadReportModal"
      @closed="showDownloadReportModal = false"
    />
  </div>
</template>

<script>
import CloseIcon from "../../../../public/common/close-badge.svg";
import DownloadIcon from "../../../../public/common/download-white.png";
import IconCaret from "../../../../public/common/select-arrow-down.svg";
import IconCaretN from "../../../../public/common/select-arrow-down.svg";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2";
import DownloadReport from "./modals/DownloadReport.vue";

export default {
  name: "HeaderTopBar",
  data() {
    return {
      cant_investor: null,
      close_icon: CloseIcon,
      download_icon: DownloadIcon,
      icon_caret: IconCaret,
      index: 0,
      investor: [],
      investors_options: [
        {
          token: "all",
          name: "All investors",
        },
        {
          token: "23423432434234324324",
          name: "BWFS test",
        },
        {
          token: "dfdstgstgd",
          name: "OKX - Test Account",
        },
        {
          token: "45435435",
          name: "Master - Blended BTC 18",
        },
        {
          token: "453345345",
          name: "test edwar",
        },
        {
          token: "54545435345",
          name: "MultiExchange TEST",
        },
        {
          token: "45435454354",
          name: "Twilight V2",
        },
        {
          token: "spidermannn",
          name: "DeepHorizon TEST 01 - SCALPINGmbjjkkhjghjhjh",
        },
        {
          token: "ironmannnn",
          name: "Test Holy Grail",
        },
      ],
      isLogged: false,
      selectedAlgorithm: "",
      showDownloadReportModal: false,
    };
  },
  components: {
    IconCaretN,
    CloseIcon,
    Multiselect,
    DownloadReport,
  },
  computed: {
    investors_selected() {
      const res = this.investor.filter((inv) => inv.token != "all");

      var investors_name = res.map(function (investor) {
        return investor.name;
      });

      const main = investors_name.slice(0, 1);
      var content = main.toString().substring(0, 20) + "";

      if (res.length > 1) {
        this.cant_investor = ` +${res.length - 1} more`;
      }

      return content;
    },
  },
  created() {
    const session = localStorage.getItem("userData");
    if (session && session !== "") {
      this.isLogged = true;
    }
  },
  methods: {
    isSelected(option) {
      return this.investor.some(
        (selectedOption) => selectedOption.token === option.token
      );
    },
    openDownloadReportModal() {
      this.showDownloadReportModal = true;
    },
    optionName(name) {
      return name.length > 30 ? name.substring(0, 25) + "..." : name;
    },
    removeInvestor(index) {
      this.investor.splice(index, 1);
    },
    toggleSelection(option) {
      if (option.token === "all") {
        if (this.isSelected(option)) {
          this.investor = [];
        } else {
          this.investor = this.investor.concat(this.investors_options);
        }
      } else {
        const index = this.investor.findIndex(
          (selectedOption) => selectedOption.token === option.token
        );

        if (index === -1) {
          this.investor.push(option);
        } else {
          this.investor.splice(index, 1);
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/pages/health_audit/header_top_bar.scss";
</style>
