<template>
  <div class="account-info-component">
    <div class="title">
      <h1>Account Info</h1>
    </div>

    <div class="container-info">
      <div class="input-container">
        <div class="form-group">
          <label>Name</label>
          <input
            v-model="form_investor.name"
            type="text"
            class="form-control"
            placeholder="Name"
          />
        </div>
        <div class="form-group">
          <label>Token</label>
          <input
            v-model="investor_token"
            type="text"
            class="form-control"
            placeholder="Token"
            disabled
          />
          <span class="icon no-pointer">
            <img :src="refresh" alt="" />
          </span>
        </div>
      </div>
      <div class="input-container">
        <div class="form-group">
          <label>Demo</label>
          <div class="form-check d-flex">
            <input
              v-model="form_investor.demo"
              class="checkbox"
              type="checkbox"
              id="type"
              disabled
            />
            <label for="type" class="label-checkbox">
              <span v-if="!form_investor.demo">
                It will <u>not</u> be a demo account
              </span>
              <span v-if="form_investor.demo">
                <u>Yes</u>, it will be a demo account
              </span>
            </label>
          </div>
        </div>
      </div>
      <div class="input-container">
        <div class="form-group">
          <label> OMS</label>
          <Multiselect
            v-model="form_investor.manual_order"
            :options="oms_options"
            class="form-control selector"
            label="name"
            placeholder="Select an option"
            valueProp="value"
          >
            <template v-slot:caret>
              <span class="multiselect-caret">
                <img :src="icon_caret" alt="" />
              </span>
            </template>
          </Multiselect>
        </div>
        <div class="form-group">
          <label>Base currency</label>
          <Multiselect
            v-model="form_investor.base_currency"
            :options="base_currency_options"
            class="form-control selector"
            label="name"
            placeholder="Select an option"
            valueProp="value"
          >
            <template v-slot:caret>
              <span class="multiselect-caret">
                <img :src="icon_caret" alt="" />
              </span>
            </template>
          </Multiselect>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconCaret from "../../../../../public/common/select-arrow-down.svg";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2";
import RefreshIcon from "../../../../../public/common/refresh-gray.svg";
import { mapState } from "vuex";

export default {
  name: "AccountInfo",
  data() {
    return {
      base_currency_options: [
        { value: "usdt", name: "USDT" },
        { value: "btc", name: "BTC" },
        { value: "bnb", name: "BNB" },
        { value: "eth", name: "ETH" },
      ],
      icon_caret: IconCaret,
      oms_options: [
        { value: "1", name: "Expert mode" },
        { value: "2", name: "Ultimate OMS" },
      ],
      refresh: RefreshIcon,
    };
  },
  components: { Multiselect },
  computed: {
    ...mapState("investor_configuration", [
      "investor_info",
      "investor_token",
      "form_investor",
    ]),
    investor() {
      return this.investor_info && this.investor_info._data
        ? this.investor_info._data.data
        : null;
    },
  },
  created() {
    setTimeout(() => {
      const form_data = {
        name: this.investor.name,
        note: this.investor.note,
        base_currency: this.investor.base_currency,
        demo: this.investor.is_real == 1 ? false : true,
        future: false,
        manual_order: this.investor.manual_order,
      };

      this.$store.commit("investor_configuration/set_investor_form", form_data);
    }, 1000);
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
@import "../../../../styles/pages/investor_configuration/account_info.scss";
</style>
