import number_formatter from "../../../helpers/formatters/number_formatter";
import random_float from "../../../helpers/generators/random_float";
import random_number from "../../../helpers/generators/random_number";
import random_string from "../../../helpers/generators/random_string";
import random_date from "../../../helpers/generators/random_date";

export default () => {
  const market_types = ["Funding", "Futures", "Margin", "Spot"];
  const sides = ["Buy", "Sell"];
  const symbols = [
    "USDT",
    "BNB",
    "ETH",
    "ADA",
    "BTC",
    "SOL",
    "XRP",
    "DOGE",
    "LTC",
    "UNI",
  ];

  const allocation_exposure_percentage = number_formatter(
    random_number(0, 100),
    2
  );
  const created_at = random_date("MM/DD/YYYY h:mm:ss");
  const market_type = market_types[random_number(0, market_types.length - 1)];
  const open_time = random_date("MM/DD/YYYY h:mm:ss");
  const open_price = number_formatter(random_number(0, 100000), 2);
  const portfolio_name = "Port name";
  const portfolio_token = random_string(10);
  const position_size = number_formatter(random_number(0, 100000), 2);
  const realized_pnl = number_formatter(random_float(-10, 10), 10);
  const side = sides[random_number(0, sides.length - 1)];
  const symbol = symbols[random_number(0, symbols.length - 1)];
  const status = 1;
  const unrealized_pnl = number_formatter(random_float(-10, 10), 10);

  return {
    allocation_exposure_percentage,
    created_at,
    market_type,
    open_time,
    open_price,
    position_size,
    portfolio_name,
    portfolio_token,
    realized_pnl,
    side,
    symbol,
    status,
    unrealized_pnl,
  };
};
