export default {
  algorithm_token: null,
  algorihtm_name: null,
  algorithm_note: "",
  is_active: null,
  priority: null,
  system_name: "",
  tags: [],
  timeframe: "",
  token: null,
};
