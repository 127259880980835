<template>
  <div>
    <div class="card p-2 mb-2" v-show="!loadingSymbol">
        <label>Search Symbol</label>
        <input 
          id="searchSymbols" 
          v-model="search" 
          type="search" 
          class="form-control" 
          placeholder="Enter text here."
        >
    </div>
    <div class="card mb-2" v-if="loadingSymbol">
      <b-card>
        <b-skeleton animation="wave" width="75%"></b-skeleton>
        <b-skeleton animation="wave" width="55%"></b-skeleton>
      </b-card>
    </div>

    <div class="card p-2 mb-2" v-if="!loadingSymbol">
      <label>Symbols of {{exchange? exchange: ''}}</label>
      <select v-model="symbol" class="form-control">
        <option :value="null">Select a symbol</option>
        <option v-show="exchange === 'kucoin'" v-for = "option in kucoinOptions" :value="option">{{option}}</option>
        <option v-show="exchange === 'binance'" v-for = "option in binanceOptions" :value="option">{{option}}</option>
      </select>
    </div>

    <div class="card mb-2" v-if="loadingSymbol">
      <b-card>
        <b-skeleton animation="wave" width="75%"></b-skeleton>
        <b-skeleton animation="wave" width="55%"></b-skeleton>
      </b-card>
    </div>

    
    <div class="card p-2 mb-2" v-show="exchange === 'binance'" v-if="!loadingSymbol">
      <b-list-group-item :key="'pgination_binance'" class="d-flex">
            <b-button variant="dark" class="btn-sm mr-auto" @click="fetchOptions(paginate_kucoin.current_page - 1, paginate_binance.current_page - 1)" :disabled="paginate_binance.current_page - 1 === 0 ">
              <b-icon-arrow-left></b-icon-arrow-left>
            </b-button>
            <span>{{paginate_binance.current_page}}/{{paginate_binance.total_pages}}</span>
            <b-button variant="dark" class="btn-sm ml-auto" @click="fetchOptions(paginate_kucoin.current_page + 1, paginate_binance.current_page + 1)" :disabled="paginate_binance.current_page + 1 > paginate_binance.total_pages">
              <b-icon-arrow-right></b-icon-arrow-right>
            </b-button>
          </b-list-group-item>
    </div> 

    <div class="card p-2 mb-2" v-show="exchange === 'kucoin'" v-if="!loadingSymbol">
      <b-list-group-item :key="'pgination_binance'" class="d-flex">
            <b-button variant="dark" class="btn-sm mr-auto" @click="fetchOptions(paginate_kucoin.current_page - 1, paginate_binance.current_page - 1)" :disabled="paginate_kucoin.current_page - 1 === 0 ">
              <b-icon-arrow-left></b-icon-arrow-left>
            </b-button>
             <span>{{paginate_kucoin.current_page}}/{{paginate_kucoin.total_pages}}</span>
            <b-button variant="dark" class="btn-sm ml-auto" @click="fetchOptions(paginate_kucoin.current_page + 1, paginate_binance.current_page + 1)" :disabled="paginate_kucoin.current_page + 1 > paginate_kucoin.total_pages">
              <b-icon-arrow-right></b-icon-arrow-right>
            </b-button>
      </b-list-group-item>
    </div> 
     
    <div class="card p-2 mb-2" v-if="!loadingSymbol">
      <button class="btn btn-dark" type="button" @click="addSymbol()">Add symbol</button>  
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'SymbolOptions',
  props: ['exchange'],
  created() {
    this.fetchOptions();
  },
  data() {
    return {
      symbol: null,
      loadingSymbol: false,
      kucoinOptions: [],
      binanceOptions: [],
      paginate_kucoin: {
        current_page: 1,
        per_page: 100,
        records: 0,
        total_pages: 1
      },
      paginate_binance: {
        current_page: 1,
        per_page: 100,
        records: 0,
        total_pages: 1
      },
    };
  },
  computed:{
    search: {
      get() {
        return this.$store.state.search_symbols
      },
      set(value) {
        this.$store.commit('set_search', value);
      },
    }
  },
  methods: {
    async fetchOptions(pageKucoin = 1, pageBinance = 1) {
      this.loadingSymbol = true;
      this.$emit("handleLoadingSymbol", true);
      const payload = {
        page_kucoin: pageKucoin,
        page_binance: pageBinance ,
        search: this.search.toUpperCase(),
      };
      const response = await this.$http.server1('bwfs/admin/symbols/correlator', payload);
      if (response && response.paginate && response.paginate.binance_paginate && response.paginate.kucoin_paginate) {
        this.kucoinOptions = response.data.kucoin;
        this.binanceOptions = response.data.binance_response;
        this.paginate_binance = response.paginate.binance_paginate;
        this.paginate_kucoin = response.paginate.kucoin_paginate
      }

      this.$emit('payload', payload);
      if (response) {
        this.$emit('response', response);
      }
      this.loadingSymbol = false;
      this.$emit("handleLoadingSymbol", false);
    },
    addEventSearch() {
      const myInput = document.getElementById("searchSymbols");
      if (!myInput) {
        setTimeout(() => {
          this.addEventSearch();
        }, 500);
      } else {
        myInput.addEventListener("keyup", this.$debounce(this.fetchOptions));
      }
    },
    addSymbol() {
      if(this.symbol) {
        const payload = {
          symbol: this.symbol,
          exchange: this.exchange,
        }
        this.$store.commit('add_symbols_correlator', payload);
        const kucoinSymbols = [...this.$store.state.symbols_correlator].filter((s) => s.exchange === 'kucoin').map((s) => s.symbol);
        const binanceSymbols = [...this.$store.state.symbols_correlator].filter((s) => s.exchange === 'binance').map((s) => s.symbol);
      }
    }
  },
  mounted() {
    this.addEventSearch();
  },
  watch: {
    exchange(value) {
      if (value) {
        this.symbol = null;
      }
    },
  },
}
</script>