import Request from "../helpers/request";
const url_api = Request.url_api(true);

const error_icon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
<path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
</svg> Error`;

const success_icon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
<g clip-path="url(#clip0_1634_940)">
<path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
</g>
<defs>
<clipPath id="clip0_1634_940">
<rect width="10" height="10" fill="white" transform="translate(5 5)"/>
</clipPath>
</defs>
</svg> Success`;

export default {
  state: {
    investor: {},
    investors: [],
    investor_api_url: url_api,
    migration_mode: false,
    migration_loading: false,
    migration_authorized: true,
    migration_password: "",
    investors_orders: [],
    investors_sorted: [],
    investors_tokens: [],
    investors_orders_token: [],
    investors_orders_tokens_socket: [],
    investors_paginate: {
      current_page: 1,
      per_page: 8,
      total_pages: 1,
      records: 0,
    },
    select_accounts: [],
    investor_search: "",
    download_reports: false,
    archive_accounts: [],
    turn_accounts: false,
    delete_acounts: false,
    investor_limit: 10,
    investor_status: 10,
    investor_error: false,
    user_type: 10,
    user_type_order_tracking: 0,
    investor_is_archived: 0,
    investors_sort_by: 1,
    show_modal_audit: false,
    order_tracking_mode: false,
    first_searched_investor: true,
    investor_supervisor_searched: false,
    investors_loading: null,
    investors_orders_loading: null,
    investor_is_searched: null,
    investor_is_sorted: false,
    investor_sorting: false,
    investor_is_deleted: null,
    investor_delete_token: null,
    investor_deleting: null,
    orders_refresh: null,
    orders_tracking_search: false,
    investor_has_been_archived: null,
    investor_archived_token: {},
    duplicate_investor: false,
    orders_opened_loading: false,
    info_investor_loading: false,
    info_investor: {},
    orders_opened: [],
    expert_mode: false,
    investors_socket_searched: false,
    investor_notifications_loading: true,
    investor_notifications_orders_search: null,
    investors_notifications_searched_tracking: false,
    investors_view_mode: 0,
    investor_notifications: [],
    investor_notifications_paginate: {
      current_page: 1,
      per_page: 25,
      records: 0,
      total_pages: 5,
    },
    investor_orders_paginate: {
      current_page: 1,
      per_page: 50,
      records: 0,
      total_pages: 5,
    },
    show_modal_open_orders: false,
    get_subinvestors_loading: false,
    get_contributors_loading: false,
    contributors: [],
    subinvestors: [],
    subinvestor: {},
    open_orders_paginate: {
      current_page: 1,
      per_page: 8,
      total_pages: 1,
      records: 0,
    },
    current_maximun_dropdown_profits: 0,
    current_maximun_dropdown_perc: 0,
  },
  mutations: {
    // Sets
    set_investor(state, { investor }) {
      state.investor = investor;
    },
    set_investors(state, { investors }) {
      state.investors = investors;
    },
    set_subinvestor(state, { subinvestor }) {
      state.subinvestor = subinvestor;
    },
    set_investors_orders_token(state, investors_orders_token) {
      state.investors_orders_token = investors_orders_token;
    },
    set_investors_orders(state, investors_orders) {
      state.investors_orders = investors_orders;
    },
    set_investors_tokens(state, investors_tokens) {
      state.investors_tokens = investors_tokens;
    },
    set_investor_notifications(state, investor_notifications) {
      state.investor_notifications = investor_notifications;
    },
    set_investors_sorted(state, investors_sorted) {
      state.investors_sorted = investors_sorted;
    },
    set_investors_sort_by(state, investors_sort_by) {
      state.investors_sort_by = investors_sort_by;
    },
    set_investors_paginate(state, { investors_paginate }) {
      state.investors_paginate = investors_paginate;
    },
    set_investors_page(state, page) {
      state.investors_paginate.current_page = page;
    },
    set_investor_search(state, investor_search) {
      state.investor_search = investor_search;
    },
    set_investor_limit(state, investor_limit) {
      state.investor_limit = investor_limit;
    },
    set_investor_status(state, investor_status) {
      state.investor_status = investor_status;
    },
    set_user_type(state, user_type) {
      state.user_type = user_type;
    },
    set_first_searched_investor(state, first_searched_investor) {
      state.first_searched_investor = first_searched_investor;
    },
    set_investors_view_mode(state, investors_view_mode) {
      state.investors_view_mode = investors_view_mode;
    },
    set_show_modal_audit(state, show_modal_audit) {
      state.show_modal_audit = show_modal_audit;
    },
    set_show_modal_open_orders(state, show_modal_open_orders) {
      state.show_modal_open_orders = show_modal_open_orders;
    },
    set_investor_is_archived(state, investor_is_archived) {
      state.investor_is_archived = investor_is_archived;
    },
    set_investor_archived_token(state, investor_archived_token) {
      state.investor_archived_token = investor_archived_token;
    },
    set_investor_is_deleted(state, investor_is_deleted) {
      state.investor_is_deleted = investor_is_deleted;
    },
    set_investor_delete_token(state, investor_delete_token) {
      state.investor_delete_token = investor_delete_token;
    },
    set_investor_error(state, investor_error) {
      state.investor_error = investor_error;
    },
    set_investor_deleting(state, investor_deleting) {
      state.investor_deleting = investor_deleting;
    },
    set_investor_loading(state, investors_loading) {
      state.investors_loading = investors_loading;
    },
    set_investor_is_searched(state, investor_is_searched) {
      state.investor_is_searched = investor_is_searched;
    },
    set_investor_is_sorted(state, investor_is_sorted) {
      state.investor_is_sorted = investor_is_sorted;
    },
    set_duplicate_investor(state, duplicate_investor) {
      state.duplicate_investor = duplicate_investor;
    },
    set_investor_has_been_archived(state, investor_has_been_archived) {
      state.investor_has_been_archived = investor_has_been_archived;
    },
    set_investor_supervisor_searched(state, investor_supervisor_searched) {
      state.investor_supervisor_searched = investor_supervisor_searched;
    },
    set_order_tracking_mode(state, order_tracking_mode) {
      state.order_tracking_mode = order_tracking_mode;
    },
    set_investor_notifications_loading(state, investor_notifications_loading) {
      state.investor_notifications_loading = investor_notifications_loading;
    },
    set_investor_notifications(state, investor_notifications) {
      state.investor_notifications = investor_notifications;
    },
    set_investor_notifications_paginate(
      state,
      investor_notifications_paginate
    ) {
      state.investor_notifications_paginate = investor_notifications_paginate;
    },
    set_orders_tracking_search(state, orders_tracking_search) {
      state.orders_tracking_search = orders_tracking_search;
    },
    set_user_type_order_tracking(state, user_type_order_tracking) {
      state.user_type_order_tracking = user_type_order_tracking;
    },
    set_investors_orders_loading(state, investors_orders_loading) {
      state.investors_orders_loading = investors_orders_loading;
    },
    set_investor_orders_paginate(state, investor_orders_paginate) {
      state.investor_orders_paginate = investor_orders_paginate;
    },
    set_investor_notifications_orders_search(
      state,
      investor_notifications_orders_search
    ) {
      state.investor_notifications_orders_search =
        investor_notifications_orders_search;
    },
    set_orders_opened(state, orders_opened) {
      state.orders_opened = orders_opened;
    },
    set_investors_orders_tokens_socket(state, investors_orders_tokens_socket) {
      state.investors_orders_tokens_socket = investors_orders_tokens_socket;
    },
    set_investors_socket_searched(state, investors_socket_searched) {
      state.investors_socket_searched = investors_socket_searched;
    },
    set_investors_notifications_searched_tracking(
      state,
      investors_notifications_searched_tracking
    ) {
      state.investors_notifications_searched_tracking =
        investors_notifications_searched_tracking;
    },
    set_expert_mode(state, expert_mode) {
      state.expert_mode = expert_mode;
    },
    //Sets y funciones para acciones de multiples cuentas
    set_download_reports(state, download_reports) {
      state.download_reports = download_reports;
    },
    set_archive_account(state, archive_account) {
      state.archive_accounts.push(archive_account);
    },
    unset_archive_account(state, token) {
      const investors = [...state.archive_accounts]
        .map((investor) => investor.info.investor.token_user)
        .indexOf(token);
      if (investors > -1) {
        state.archive_accounts.splice(investors, 1);
      }
    },
    set_turn_accounts(state, turn_accounts) {
      state.turn_accounts = turn_accounts;
    },
    set_delete_acounts(state, delete_acounts) {
      state.delete_acounts = delete_acounts;
    },
    set_investor_api_url(state, investor_api_url) {
      state.investor_api_url = investor_api_url;
    },
    set_migration_mode(state, migration_mode) {
      state.migration_mode = migration_mode;
    },
    set_migration_loading(state, migration_loading) {
      state.migration_loading = migration_loading;
    },
    set_migration_password(state, migration_password) {
      state.migration_password = migration_password;
    },
    set_migration_authorized(state, migration_authorized) {
      state.migration_authorized = migration_authorized;
    },
    select_investor_for_select(state, investor) {
      state.select_accounts.push(investor);
    },
    unselect_all_investors_from_select(state) {
      state.select_accounts = [];
      state.archive_accounts = false;
      state.turn_accounts = false;
      state.delete_acounts = false;
      // state.download_reports = false;
    },
    unselect_investor_from_select(state, token) {
      const investors = [...state.select_accounts]
        .map((investor) => investor.info.investor.token_user)
        .indexOf(token);
      if (investors > -1) state.select_accounts.splice(investors, 1);
      if (state.select_accounts.length === 0) {
        state.archive_accounts = false;
        state.turn_accounts = false;
        state.delete_acounts = false;
        state.download_reports = false;
      }
    },
    // Otras mutaciones
    get_orders_opened(state, payload) {
      state.orders_opened_loading = true;
      let data_request = payload.data;
      if (!data_request.page) {
        data_request.page = state.open_orders_paginate.current_page;
      }
      if (!data_request.limit) {
        data_request.limit = state.open_orders_paginate.per_page;
      }
      let url = "bwfs/admin/investors/orders/open";
      if (payload.is_subinvestor) {
        url = "bwfs/admin/subinvestors/orders/open";
      } else if (payload.is_contributor) {
        url = "bwfs/admin/contributors/orders/opened";
      }
      payload.vm.$http
        .server1(url, data_request)
        .then((res) => {
          state.orders_opened_loading = false;
          if (res.error) {
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }

          state.open_orders_paginate = res.paginate;
          let data = [];
          for (let i = 0; i < res.data.length; i++) {
            let o = res.data[i];
            o.last_updated_socket = 0;
            data.push(o);
          }

          state.orders_opened = data;
        })
        .catch((e) => {
          state.orders_opened_loading = false;
          payload.vm.$toastr.error(
            e.message ? e.message : "An error has occurred",
            error_icon
          );
        });
    },
    get_orders_opened_refresh(state, payload) {
      let data_request = payload.data;
      if (!data_request.page) {
        data_request.page = state.open_orders_paginate.current_page;
      }
      if (!data_request.limit) {
        data_request.limit = state.open_orders_paginate.per_page;
      }
      if (payload.vm) {
        payload.vm.$http
          .server1("bwfs/admin/investors/orders/open", data_request)
          .then((res) => {
            if (res.error) {
              payload.vm.$toastr.error(
                res.message ? res.message : "An error has occurred",
                error_icon
              );
              return;
            }
            let data = [];
            for (let i = 0; i < res.data.length; i++) {
              let o = res.data[i];
              o.last_updated_socket = 0;
              data.push(o);
            }
            state.orders_opened = data;
          })
          .catch((e) => {
            payload.vm.$toastr.error(
              e.message ? e.message : "An error has occurred",
              error_icon
            );
          });
      } else {
        let newOrdes = state.orders_opened.filter(
          (o) => payload.indexOf(o.token) >= -1
        );
        state.orders_opened = newOrdes;
      }
    },
    update_order_opened(state, payload) {
      for (let i = 0; i < state.orders_opened.length; i++) {
        for (let j = 0; j < payload.length; j++) {
          if (state.orders_opened[i].token == payload[j].token) {
            let timestamp = new Date(
              parseInt((payload[j].updated_at * 1000).toString().split(".")[0])
            );
            state.orders_opened[i].profit_coin = payload[j].profit;
            state.orders_opened[i].profit_percentage = payload[j].profit;
            state.orders_opened[i].animated = true;
            // state.orders_opened[i].update_at = timestamp.toISOString().replace("T", " ").substr(0,19);
            state.orders_opened[i].last_updated_socket =
              payload.last_update_time;
            break;
          }
        }
      }
    },
    update_order_on_open(state, payload) {
      for (let i = 0; i < state.orders_opened.length; i++) {
        if (state.orders_opened[i].token == payload.token) {
          let timestamp = new Date(
            parseInt((payload.updated_at * 1000).toString().split(".")[0])
          );
          state.orders_opened[i].profit_coin = payload.profit[3];
          state.orders_opened[i].profit_percentage = parseFloat(
            payload.profit[2]
          );
          state.orders_opened[i].last_updated_socket = payload.last_update_time;
          state.orders_opened[i].animated = true;
          break;
        }
      }

      for (let i = 0; i < state.backtest_orders.length; i++) {
        if (state.backtest_orders[i].token == payload.token) {
          let timestamp = new Date(
            parseInt((payload.updated_at * 1000).toString().split(".")[0])
          );
          state.backtest_orders[i].profit_coin = payload.profit;
          state.backtest_orders[i].profit_percentage = payload.profit;
          state.backtest_orders[i].last_updated_socket =
            payload.last_update_time;
          break;
          // state.orders_opened[i].animated = true;
        }
      }
      setTimeout(() => {
        for (let i = 0; i < state.backtest_orders.length; i++) {
          if (state.orders_opened[i]) {
            state.orders_opened[i].animated = false;
          }
        }
      }, 100);
    },
    update_several_orders_on_open(state, payload) {
      for (let i = 0; i < state.backtest_orders.length; i++) {
        if (state.backtest_orders[i].token == payload.token) {
          state.backtest_orders[i].profit_coin = payload.profit[0];
          state.backtest_orders[i].profit_percentage = payload.profit[1];
          break;
          // state.orders_opened[i].animated = true;
        }
      }
    },
    update_several_order_opened(state, payload) {
      for (let i = 0; i < state.orders_opened.length; i++) {
        for (let j = 0; j < payload.length; j++) {
          if (state.orders_opened[i].token == payload[j].token) {
            let timestamp = new Date(
              parseInt((payload[j].updated_at * 1000).toString().split(".")[0])
            );
            state.orders_opened[i].profit_coin = payload[j].profit[0];
            state.orders_opened[i].profit_percentage = payload[j].profit[1];
            state.orders_opened[i].animated = true;
            // state.orders_opened[i].update_at = timestamp.toISOString().replace("T", " ").substr(0,19);
            state.orders_opened[i].last_updated_socket =
              payload.last_update_time;
            break;
          }
        }
      }
    },
    get_info_investor(state, payload) {
      state.info_investor_loading = true;
      payload.vm.$http
        .server1("bwfs/admin/investors/info", payload.data)
        .then((res) => {
          state.info_investor_loading = false;
          if (res.error) {
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          state.info_investor = res.data;
        })
        .catch((e) => {
          state.info_investor_loading = false;
          payload.vm.$toastr.error(
            e.message ? e.message : "An error has occurred",
            error_icon
          );
        });
    },
    set_info_investor(state, payload) {
      state.info_investor = payload;
    },
    get_download_report_investor(state, payload) {
      payload.vm.$http
        .server2("bwfs/admin/investors/report", payload.data)
        .then((res) => {
          if (res.error) {
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          window.open(request.url_api(true) + "/bwfs/static/" + res.message);
        })
        .catch((e) => {
          payload.vm.$toastr.error(
            e.message ? e.message : "An error has occurred",
            error_icon
          );
        });
    },
    handle_toggle_account(state, payload) {
      payload.vm.$http
        .server1("bwfs/admin/toogle/account", payload.data)
        .then((res) => {
          if (res.error) {
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
        })
        .catch((e) => {
          payload.vm.$toastr.error(
            e.message ? e.message : "An error has occurred",
            error_icon
          );
        });
    },
    get_notification_investor(state, payload) {
      state.investor_notifications_loading = true;
      payload.vm.$http
        .server1("bwfs/admin/investor/notifications", payload.data)
        .then((res) => {
          state.investor_notifications_loading = false;
          if (res.error) {
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          state.investor_notifications = res.data;
          state.investor_notifications_paginate = res.paginate;
        })
        .catch((e) => {
          state.investor_notifications_loading = false;
          payload.vm.$toastr.error(
            e.message ? e.message : "An error has occurred",
            error_icon
          );
        });
    },
    /*set_notification_investor(state, payload) {
      state.investor_notifications.push(payload);
      // state.investor_notifications_paginate = res.paginate;
    },*/
    set_notification_investor(state, payload) {
      state.investor_notifications[0] = {};
      setTimeout(() => {
        state.investor_notifications[0] = payload;
      }, 500);
    },
    get_update_order_opened(state, payload) {
      payload.vm.$http
        .server1("bwfs/admin/investors/info", payload.data)
        .then((res) => {
          if (res.error) {
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          state.orders_opened = res.data;
        })
        .catch((e) => {
          payload.vm.$toastr.error(
            e.message ? e.message : "An error has occurred",
            error_icon
          );
        });
    },
    get_update_info_investor(state, payload) {
      payload.vm.$http
        .server1("bwfs/admin/investors/info", payload.data)
        .then((res) => {
          if (res.error) {
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          state.info_investor.info = res.data.info;
          state.info_investor.resumen = res.data.resumen;
        })
        .catch((e) => {
          payload.vm.$toastr.error(
            e.message ? e.message : "An error has occurred",
            error_icon
          );
        });
    },
    get_balance_investor(state, payload) {
      payload.vm.$http
        .server1("bwfs/admin/balance/get", payload.data)
        .then((res) => {
          if (res.error) {
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
        })
        .catch((e) => {
          payload.vm.$toastr.error(
            e.message ? e.message : "An error has occurred",
            error_icon
          );
        });
    },
    get_subinvestor(state, payload) {
      state.subinvestors = [];
      state.get_subinvestors_loading = true;
      // payload.vm.$http
      //   .server1("bwfs/admin/subinvestors/by/user", payload.data)
      //   .then((res) => {
      //     state.get_subinvestors_loading = false;
      //     if (res.error) {
      //       payload.vm.$toastr.error(
      //         res.message ? res.message : "An error has occurred",
      //         error_icon
      //       );

      //       return;
      //     }
      //     state.subinvestors = res.data;
      //   })
      //   .catch((e) => {
      //     state.get_subinvestors_loading = false;

      //     payload.vm.$toastr.error(
      //       e.message ? e.message : "An error has occurred",
      //       error_icon
      //     );
      //   });
    },
    get_contributor(state, payload) {
      state.contributors = [];
      state.get_contributors_loading = true;
      payload.vm.$http
        .server1("bwfs/admin/contributors/by/user", payload.data)
        .then((res) => {
          state.get_contributors_loading = false;
          if (res.error) {
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          state.contributors = res.data;
        })
        .catch((e) => {
          state.get_contributors_loading = false;
          payload.vm.$toastr.error(
            e.message ? e.message : "An error has occurred",
            error_icon
          );
        });
    },
    bwfs_delete_contributors(state, payload) {
      payload.vm.$http
        .server1("bwfs/admin/contributors/delete", payload.data)
        .then((res) => {
          if (res.error) {
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          payload.vm.$toastr.success(
            res.message ? res.message : "Deleted successfully",
            success_icon
          );
        })
        .catch((e) => {
          payload.vm.$toastr.error(
            e.message ? e.message : "An error has occurred",
            error_icon
          );
        });
    },
    bwfs_delete_subinvestor(state, payload) {
      payload.vm.$http
        .server1("bwfs/admin/subinvestors/delete", payload.data)
        .then((res) => {
          if (res.error) {
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          payload.vm.$toastr.success(
            res.message ? res.message : "Deleted successfully",
            success_icon
          );
        })
        .catch((e) => {
          payload.vm.$toastr.error(
            e.message ? e.message : "An error has occurred",
            error_icon
          );
        });
    },
    set_dropdown(state, payload) {
      state.current_maximun_dropdown_profits = parseFloat(payload[0]);
      state.current_maximun_dropdown_perc = parseFloat(payload[1]);
    },
  },
  actions: {
    async bwfs_get_investors({ commit, state }, payload) {
      commit("set_investor_loading", true);
      commit("set_investor_is_searched", false);
      const request = payload.vm.$http;
      request.set_url_by_filter(state.investor_api_url, true);
      const response = await request.server1(
        "bwfs/admin/investors",
        payload.data
      );
      const investors = response.data
        .map((t) => t.token)
        .filter((t) => t.length > 0);
      commit("set_investors", { investors });
      if (state.user_type != 10) {
        commit("set_investors_orders_token", response.data);
      } else {
        let data = payload.data;
        data.user_type = state.user_type_order_tracking;
        const responseOrders = await payload.vm.$http.server1(
          "bwfs/admin/investors",
          data
        );
        commit("set_investors_orders_token", responseOrders.data);
      }
      commit("set_investors_paginate", {
        investors_paginate: response.paginate,
      });
      commit("set_investor_loading", false);
      commit("set_investor_is_searched", true);
    },
    async bwfs_get_investor_info_user({ commit }, payload) {
      const request = payload.vm.$http;
      request.set_url_by_filter(state.investor_api_url, true);
      const response = await request.server1(
        "bwfs/admin/investors/usercard",
        payload.data
      );
      commit("set_investor", { investor: response.data });
    },
    async bwfs_delete_investor({ commit }, payload) {
      commit("set_investor_is_deleted", false);
      commit("set_investor_deleting", true);
      const response = await payload.vm.$http.server1(
        "bwfs/admin/investors/delete",
        payload.data
      );
      if (response.error) {
        payload.vm.$toastr.error(
          response.message ? response.message : "An error has occurred",
          error_icon
        );
        return;
      }
      payload.vm.$toastr.success("Investor successfully deleted", success_icon);
      commit("set_investor_delete_token", payload.data.investor_token);
      commit("set_investor_is_deleted", true);
      commit("set_investor_deleting", false);
    },
    async bwfs_archive_investor({ commit }, payload) {
      const request = payload.vm.$http;
      commit("set_investor_has_been_archived", false);
      const response = await request.server1(
        "bwfs/admin/investor/archive",
        payload.data
      );
      if (response.error) {
        payload.vm.$toastr.error(
          response.message ? response.message : "An error has occurred",
          error_icon
        );
        return;
      }
      payload.vm.$toastr.success(
        response.message ? response.message : "Archived successfully",
        success_icon
      );
      commit("set_investor_archived_token", payload.data.investor_token);
      commit("set_investor_has_been_archived", true);
    },
    async bwfs_get_investors_orders_opened({ commit, state }, payload) {
      const request = payload.vm.$http;
      const vm = payload.vm;
      delete payload.vm;
      delete payload.is_investor;
      state.backtest_orders_loading = true;
      // const response = await request.server1(
      //   "bwfs/admin/investors/orders_investors",
      //   payload
      // );
      state.backtest_orders_loading = false;
      state.backtest_orders = response.data;
      commit("set_orders_opened", response.data);
      commit("set_investor_orders_paginate", response.paginate);
    },
    async bwfs_get_investors_sockets({ commit, state }, payload) {
      const request = payload.vm.$http;
      request.set_url_by_filter(state.investor_api_url, true);
      const response = await request.server1(
        "bwfs/admin/investors/investors_trackings",
        payload.data
      );
      let investors = response.data.map((investor) => {
        return {
          token_user: investor.token,
          comission: investor.condition.base_configuration.comission,
          market_type: state.user_type_order_tracking,
        };
      });
      commit("set_investors_orders_tokens_socket", investors);
      commit("set_investors_socket_searched", true);
    },
    async bwfs_get_investors_notifications({ commit }, payload) {
      commit("set_investor_notifications_loading", true);
      const request = payload.vm.$http;
      request.set_url_by_filter(state.investor_api_url, true);

      const response = await request.server1(
        "bwfs/admin/investors/notifications_investors",
        payload.data
      );
      commit("set_investor_notifications", response.data);
      commit("set_investor_notifications_paginate", response.paginate);
      commit("set_investor_notifications_loading", false);
      commit("set_investors_notifications_searched_tracking", true);
    },
    async bwfs_get_orders_opened_refresh({ state }, payload) {
      if (payload.vm) {
        const request = payload.vm.$http;
        request.set_url_by_filter(state.investor_api_url, true);
        request
          .server1("bwfs/admin/investors/orders/open", payload.data)
          .then((res) => {
            if (res.error) {
              payload.vm.$toastr.error(
                res.message ? res.message : "An error has occurred",
                error_icon
              );
              return;
            }
            let data = [];
            for (let i = 0; i < res.data.length; i++) {
              let o = res.data[i];
              o.last_updated_socket = 0;
              data.push(o);
            }
            state.orders_opened = data;
          })
          .catch((e) => {
            payload.vm.$toastr.error(
              e.message ? e.message : "An error has occurred",
              error_icon
            );
          });
      } else {
        let newOrdes = state.orders_opened.filter(
          (o) => payload.indexOf(o.token) >= -1
        );
        state.orders_opened = newOrdes;
      }
    },
    async bwfs_total_close({ commit }, payload) {
      const response = await payload.vm.$http.server1(
        "bwfs/admin/investors/total_close",
        payload.data
      );
      if (response.error) {
        payload.vm.$toastr.error(
          response.message ? response.message : "An error has occurred",
          error_icon
        );
        return;
      }
      payload.vm.$toastr.success(
        "Investor successfully total close",
        success_icon
      );
    },
    async bwfs_total_close_by_symbol({ commit }, payload) {
      const response = await payload.vm.$http.server1(
        "bwfs/admin/investors/total_close/by_symbol",
        payload.data
      );
      if (response.error) {
        payload.vm.$toastr.error(
          response.message ? response.message : "An error has occurred",
          error_icon
        );
        return;
      }
      payload.vm.$toastr.success(
        "Investor successfully total close by symbol",
        success_icon
      );
    },
  },
};
