<template>
  <div class="row">
    <div class="col-md-12">
      <label class="text-muted">Portfolios distribution balance</label>
    </div>
    <div class="col-md-12 mb-3" v-if="!connections.length">
      <AlertInformation
        description="You must add a connection to be able to add a new portfolio and a transaction"
      />
    </div>
    <div class="col-12 btn-distribution">
      <div
        v-if="investor_edit && !investor_on && connections.length"
        class="d-flex align-items-center"
        @click.prevent="handleToggleModelAddTransaction"
      >
        Add transaction
        <span style="font-size: 22px">
          <b-icon-plus></b-icon-plus>
        </span>
      </div>
      <div
        v-if="!investor_on && investor_edit && connections.length"
        class="d-flex align-items-center"
        @click.prevent="handleToggleModelAddBalance(false)"
      >
        Add new portfolio
        <span style="font-size: 22px">
          <b-icon-plus></b-icon-plus>
        </span>
      </div>
    </div>

    <div class="col-md-12 balances-table">
      <b-table
        :items="portfolios"
        :fields="fields_balances_table"
        :currentPage="1"
        :per-page="100"
        :busy="loading"
        responsive
        show-empty
        empty-text="Please add balance distibution."
      >
        <template #cell(name)="data">
          <span
            @click.prevent="copyToken(data.item.token)"
            style="cursor: pointer"
          >
            {{ data.item.name.toUpperCase() }}
          </span>
        </template>

        <template #cell(initial_balance)="data">
          {{
            data.item.initial_balance
              ? formatter(data.item.initial_balance)
              : "0"
          }}<br />
        </template>
        <template #cell(current_balance)="data">
          {{
            data.item.current_balance
              ? formatter(data.item.current_balance)
              : "0"
          }}<br />
        </template>
        <template #cell(nav)="data">
          {{ data.item.nav ? formatter(data.item.current_balance) : "0" }}<br />
        </template>
        <template #cell(options)="data">
          <span v-if="!investor_on">
            <span
              style="font-size: 15px"
              class="mr-1"
              @click="editBalance(data.index)"
              v-if="$store.state.user && $store.state.user.role_id == 1"
            >
              <b-icon-pencil-square></b-icon-pencil-square>
            </span>
          </span>
          <span v-if="!investor_on">
            <span
              style="font-size: 15px"
              class="mr-1"
              @click="deleteBalance(data)"
              v-if="$store.state.user && $store.state.user.role_id == 1"
            >
              <b-icon-trash></b-icon-trash>
            </span>
          </span>
        </template>

        <template #table-busy>
          <b-skeleton-table
            :rows="3"
            :columns="3"
            :table-props="{ striped: true }"
          >
          </b-skeleton-table>
        </template>
      </b-table>
    </div>

    <b-modal
      id="modal-shows-add-balance-distibrution"
      ref="modal-shows-add-balance-distibrution"
      hide-footer
      hide-header
      size="md"
      ok-only
    >
      <div class="modal-create-conditions">
        <h3 class="modal-title">
          {{ is_edit ? "Update" : "Add" }} distribution balance
        </h3>
        <div class="row px-2">
          <div class="col-md-6 px-2">
            <div class="form-group">
              <label>Name of algorithm</label>
              <input
                type="text"
                placeholder="Name"
                class="form-control"
                v-model="form_portfolio.name"
                @input="$v.form_portfolio.name.$touch()"
              />
              <div v-if="$v.form_portfolio.name.$dirty">
                <b-form-invalid-feedback
                  :state="$v.form_portfolio.name.required"
                >
                  Required Field
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>

          <div class="col-md-6 px-2">
            <div class="form-group">
              <label>Select group</label>
              <select
                class="form-control mr-2"
                v-model="form_portfolio.group_token"
              >
                <option :value="group.token" v-for="group in groups">
                  {{ group.name }}
                </option>
              </select>

              <div v-if="$v.form_portfolio.group_token.$dirty">
                <b-form-invalid-feedback
                  :state="$v.form_portfolio.group_token.required"
                >
                  Required Field
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>

          <div class="col-md-6 px-2">
            <div class="form-group">
              <label>Connection</label>
              <select
                class="form-control mr-2 selector"
                v-model="form_portfolio.exchange_token"
              >
                <option
                  :value="connection.token"
                  v-for="connection in connection_options"
                >
                  {{ connection.name }}
                </option>
              </select>
              <div v-if="$v.form_portfolio.exchange_token.$dirty">
                <b-form-invalid-feedback
                  :state="$v.form_portfolio.exchange_token.required"
                >
                  Required Field
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
          <div v-if="is_edit" class="col-12 col-md-6">
            <div class="form-group">
              <label>Portfolio NAV</label>
              <input
                :value="form_portfolio.nav"
                type="text"
                placeholder="Connection NAV"
                class="form-control"
                readonly
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>Type</label>
              <Multiselect
                v-model="form_portfolio.type"
                :hideSelected="true"
                :options="typesOptions"
                class="form-control selector"
                label="name"
                placeholder="Select an option"
                valueProp="value"
              >
                <template v-slot:caret>
                  <span class="multiselect-caret">
                    <img :src="icon_caret" alt="" />
                  </span>
                </template>
              </Multiselect>
              <div v-if="$v.form_portfolio.type.$dirty">
                <b-form-invalid-feedback
                  :state="$v.form_portfolio.type.required"
                >
                  Required Field
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>Amount</label>
              <input
                v-model="form_portfolio.amount"
                type="text"
                placeholder="Amount"
                class="form-control"
              />
              <div v-if="$v.form_portfolio.amount.$dirty">
                <b-form-invalid-feedback
                  :state="$v.form_portfolio.amount.required"
                >
                  Required Field
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-2">
          <AlertInformation
            v-if="is_edit && dif_initial_current != 0"
            class="col-12 mb-2"
            title="Hey"
            :description="description_alert"
          />
          <AlertInformation
            v-if="showTransferAlert"
            class="col-12 mb-2"
            title="Hey"
            :description="transferMessage"
          />
          <div class="col-md-12 px-2">
            <div class="form-group">
              <b-form-checkbox v-model="form_portfolio.ignore_transaction">
                <span> Ignore transaction </span>
              </b-form-checkbox>
            </div>
          </div>

          <div class="col-md-12 px-2">
            <div class="form-group d-flex">
              <b-button
                variant="dark"
                class="btn-sm ml-auto"
                @click.prevent="handleAddBalance"
                >Save</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-shows-add-transaction"
      ref="modal-shows-add-transaction"
      hide-footer
      hide-header
      size="md"
      ok-only
    >
      <div class="modal-create-conditions">
        <h3 class="modal-title">Add transaction balance</h3>
        <div class="row px-2">
          <div class="col-md-6 px-2">
            <div class="form-group">
              <label>Type transaction</label>
              <select
                class="form-control"
                v-model="transaction.type_transaction"
              >
                <option value="deposit">Deposit</option>
                <option value="withdraw">Withdraw</option>
              </select>
              <div v-if="$v.transaction.type_transaction.$dirty">
                <b-form-invalid-feedback
                  :state="$v.transaction.type_transaction.required"
                >
                  Required Field
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
          <div class="col-md-6 px-2">
            <div class="form-group">
              <label>Type distribution</label>
              <select
                class="form-control"
                v-model="transaction.type_distribution"
              >
                <option value="individual">Individual by algorithm</option>
                <option value="distributed">
                  Distributed for all algorithms
                </option>
              </select>

              <span v-if="transaction.type_distribution == 'individual'">
                <label class="text-muted" style="font-size: 11px">
                  You must select the algorithm with which you wish to make the
                  transaction
                </label>
              </span>
              <span v-if="transaction.type_distribution == 'distributed'">
                <label class="text-muted" style="font-size: 11px">
                  The amount of the transaction will be shared equally among all
                  algorithms.
                </label>
              </span>

              <div v-if="$v.transaction.type_distribution.$dirty">
                <b-form-invalid-feedback
                  :state="$v.transaction.type_distribution.required"
                >
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>

          <div
            class="col-md-6 px-2"
            v-if="transaction.type_distribution == 'individual'"
          >
            <div class="form-group">
              <label>Distribution balance</label>
              <select
                class="form-control"
                v-model="transaction.balance_distribution_token"
              >
                <option v-for="balance in balances" :value="balance.token">
                  {{ balance.name }}
                </option>
              </select>
              <div v-if="$v.transaction.balance_distribution_token.$dirty">
                <b-form-invalid-feedback
                  :state="$v.transaction.balance_distribution_token.required"
                >
                  Required Field
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
          <div class="col-md-6 px-2">
            <div class="form-group">
              <label>Amount</label>
              <input
                type="number"
                placeholder="1000"
                class="form-control"
                v-model="transaction.amount"
              />
              <div v-if="$v.transaction.amount.$dirty">
                <b-form-invalid-feedback
                  :state="$v.transaction.amount.required"
                >
                  Required Field
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>

          <div class="col-md-12 px-2">
            <TableBalancesDistributionPreview
              :balances="balances"
              :type_transaction="transaction.type_transaction"
              :type_distribution="transaction.type_distribution"
              :balance_distribution_token="
                transaction.balance_distribution_token
              "
              :amount="transaction.amount"
            />
          </div>

          <div class="col-md-12 px-2">
            <div class="form-group d-flex">
              <b-button
                variant="dark"
                class="btn-sm ml-auto"
                @click.prevent="handleAddTransaction"
                :disabled="sending_transaction"
                v-if="transaction.type_distribution == 'individual'"
              >
                <b-icon
                  icon="arrow-repeat"
                  animation="spin-pulse"
                  v-if="sending_transaction"
                ></b-icon>
                Save
              </b-button>
              <b-button
                variant="dark"
                class="btn-sm ml-auto"
                @click.prevent="handleAddTransactionDistributed"
                :disabled="sending_transaction"
                v-if="transaction.type_distribution == 'distributed'"
              >
                <b-icon
                  icon="arrow-repeat"
                  animation="spin-pulse"
                  v-if="sending_transaction"
                ></b-icon>
                Save
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import AlertInformation from "../../commons/alerts/AlertInformation.vue";
import {
  required,
  minLength,
  email,
  requiredIf,
} from "vuelidate/lib/validators";
import TableBalancesDistributionPreview from "@/components/pages/investors/balance-distribution/TablePreviewTransaction.vue";
import { mapState } from "vuex";
import number_formatter from "../../../helpers/formatters/number_formatter";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2";
import IconCaret from "../../../../public/common/select-arrow-down.svg";

export default {
  name: "TableBalancesDistribution",
  components: {
    AlertInformation,
    TableBalancesDistributionPreview,
    Multiselect,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    investorForm: {
      type: Object,
      required: true,
      default: () => {
        return {
          name: null,
          token: null,
          balances: [],
          configurations: [],
          note: "",
          api_key: "",
          secret_key: "",
          demo: true,
          future: false,
          manual_order: false,
          signal_endpoint: false,
          send_notification: false,
          order_type: "market",
          exchange: "binance",
          order_open_close_tolerance: 5,
        };
      },
    },
    list_balances: {
      type: Array,
      required: false,
      default: () => [],
    },
    open_orders: {
      type: Number,
      required: false,
      default: 0,
    },
    investor_on: {
      type: Boolean,
      required: true,
      default: false,
    },
    investor_edit: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  validations: {
    balance: {
      initial_balance: { required },
      name: { required },
      current_balance: { required },
      group_token: { required },
    },
    form_portfolio: {
      name: { required },
      group_token: { required },
      exchange_token: { required },
      type: {
        required: requiredIf(function (nestedModel) {
          return !this.isEdit();
        }),
      },
      amount: {
        required: requiredIf(function (nestedModel) {
          return !this.isEdit();
        }),
      },
    },
    transaction: {
      amount: { required },
      type_transaction: { required },
      balance_distribution_token: { required },
      type_distribution: { required },
    },
  },
  data() {
    return {
      assignment_groups: [],
      balance: {
        initial_balance: "",
        name: "",
        current_balance: "",
        group_token: "",
      },
      balances: [],
      connection: {},
      dif_initial_current: null,
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
      <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
      </svg> Error`,
      fields_balances_table: [],
      groups: [],
      is_edit: false,
      index_selected: 0,
      old_initial_balance: null,
      old_current_balance: null,
      sending_transaction: false,
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
          <g clip-path="url(#clip0_1634_940)">
          <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
          </g>
          <defs>
          <clipPath id="clip0_1634_940">
          <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
          </clipPath>
          </defs>
          </svg> Success`,
      transaction: {
        amount: "",
        type_transaction: "",
        balance_distribution_token: "",
        type_distribution: "",
      },
      icon_caret: IconCaret,
    };
  },
  created() {
    this.getAllGroups();
    let options = [{ key: "name", label: "Algorithm" }];
    options.push({ key: "initial_balance", label: "Initial balance" });
    options.push({ key: "current_balance", label: "Current balance" });
    options.push({ key: "nav", label: "NAV" });
    options.push({ key: "options", label: "Options" });
    this.fields_balances_table = options;
    this.$store.commit(
      "portfolios/set_investor_token",
      this.$props.investorForm.token
    );
    this.$store.dispatch("portfolios/portfolio_list");
  },
  computed: {
    ...mapState("dashboard", ["exchange_selector_list"]),
    ...mapState("connections", ["connection_list_selector", "connection_list"]),
    ...mapState("portfolios", ["form_portfolio", "portfolio_list"]),

    connections() {
      return this.connection_list && this.connection_list._data
        ? this.connection_list._data.data
        : null;
    },
    connection_options() {
      return this.connection_list_selector &&
        this.connection_list_selector._data
        ? this.connection_list_selector._data.data
        : null;
    },
    description_alert() {
      return this.dif_initial_current > 0
        ? "This is going to be registered as transfer in a for " +
            this.formatter(this.dif_initial_current)
        : "This is going to be registered as a  transfer out for " +
            this.formatter(this.dif_initial_current);
    },
    portfolios() {
      return this.portfolio_list && this.portfolio_list._data
        ? this.portfolio_list._data.data
        : null;
    },
    isDemo() {
      return this.investorForm.demo;
    },
    isRemaining() {
      return this.form_portfolio.is_remaining;
    },
    isNotDemoNotRemaining() {
      return !this.isDemo && !this.isRemaining;
    },
    typesOptions() {
      const types = [];
      this.addFixedType(types)({ name: "Fixed", value: "fixed" });
      this.addPercentageType(types)({
        name: "Percentage",
        value: "percentage",
      });
      return types;
    },
    showTransferAlert() {
      return (
        this.form_portfolio.amount && !this.form_portfolio.ignore_transaction
      );
    },
    transferMessage() {
      const transferAmount = this.getTransferAmount();
      return this.getTransferMessage(transferAmount);
    },
  },
  methods: {
    getTransferMessage(amount) {
      return (
        "This is going to be registered as transfer " +
        (amount < 0 ? " in a " : " out ") +
        " for " +
        this.formatter(Math.abs(amount))
      );
    },
    getTransferAmount() {
      if (this.isPercentage && !this.form_portfolio.exchange_token) {
        return 0;
      }
      const oldNav = this.isEdit() ? this.form_portfolio.nav : 0;
      const newNav = this.isPercentage
        ? this.calculated_amount
        : this.form_portfolio.amount;
      return this.findNavDifference(oldNav, newNav);
    },
    findNavDifference(a, b) {
      return a - b;
    },
    isEdit() {
      return this.is_edit;
    },
    addFixedType(types) {
      return (type) => {
        types.push(type);
      };
    },
    addPercentageType(types) {
      return (type) => {
        if (this.isNotDemoNotRemaining) {
          types.push(type);
        }
      };
    },
    setDefaultType() {
      if (this.isDemo || this.isRemaining) {
        this.form_portfolio.type = "fixed";
      } else if (!this.isDemo && !this.isRemaining) {
        this.form_portfolio.type = "percentage";
      }
    },
    copyToken(token) {
      this.$helpers.copyTextToClipboard(token, this);
    },
    createPortfolio() {
      this.$store.dispatch("portfolios/create_portfolio", {
        callback: ({ response }) => {
          if (response.error) {
            if (response.message) {
              this.$toastr.error(`${response.message}`, `${this.error}`);
            }
          } else {
            this.$toastr.success(
              "Portfolio created successfully.",
              `${this.success}`
            );
            this.$store.dispatch("portfolios/portfolio_list");
            this.balances = this.portfolios;
            this.handleToggleModelAddBalance();
          }
        },
        error: ({ response }) => {},
      });
    },
    deleteBalance(data) {
      if (!this.validExecuteAction()) {
        return;
      }

      this.deletePortfolio(data);
    },
    deletePortfolio(data) {
      const body = {
        token: data.item.token,
      };

      Swal.fire({
        title: "Are you sure?",
        input: "checkbox",
        inputValue: 0,
        inputPlaceholder: "Register this as a transaction",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#1c2030",
        confirmButtonText: "Delete",
        preConfirm: async (value) => {
          body.ignore_transaction = value;
          await this.performDeleteRequest(body, data.index);
        },
      }).then(async (result) => {
        if (result.data) {
        }
      });
    },
    editBalance(index_selected) {
      if (!this.validExecuteAction()) {
        return;
      }
      this.index_selected = index_selected;
      this.is_edit = true;
      this.$store.commit(
        "portfolios/set_creation_form",
        this.portfolios[index_selected]
      );

      this.handleToggleModelAddBalance(true);
    },
    getAllGroups() {
      this.$http
        .server1("bwfs/admin/groups/all", {})
        .then((res) => {
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);

            return;
          }
          this.groups = res.data;
        })
        .catch((e) => {
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    getAssignmentGroups() {
      this.$http
        .server1(
          "bwfs/admin/groups/assignment/" + this.$props.investorForm.token,
          {}
        )
        .then((res) => {
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.assignment_groups = res.data;
        })
        .catch((e) => {
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    formatter(number) {
      return number_formatter(number);
    },
    handleToggleModelAddBalance(is_edit = false) {
      this.$v.form_portfolio.$reset();
      this.setDefaultType();
      this.$store.commit(
        "connections/set_investor_token",
        this.$props.investorForm.token
      );
      this.$store.commit(
        "portfolios/set_investor_token",
        this.$props.investorForm.token
      );
      this.$store.dispatch("connections/connection_list_selector");
      this.is_edit = is_edit;

      if (!is_edit) {
        this.form_portfolio.initial_balance = "";
        this.form_portfolio.name = "";
        this.form_portfolio.current_balance = "";
        this.form_portfolio.group_token = "";
        this.form_portfolio.exchange_token = "";
        this.form_portfolio.is_remaining = false;
      }

      if (!this.validExecuteAction()) {
        return;
      }
      this.$refs["modal-shows-add-balance-distibrution"].toggle(
        "#modal-shows-add-balance-distibrution"
      );

      this.old_initial_balance = this.form_portfolio.initial_balance;
      this.old_current_balance = this.form_portfolio.current_balance;
    },
    handleToggleModelAddTransaction() {
      if (!this.validExecuteAction()) {
        return;
      }
      this.$refs["modal-shows-add-transaction"].toggle(
        "#modal-shows-add-transaction"
      );
    },
    handleAddBalance() {
      this.$v.form_portfolio.$touch();
      let validData =
        this.$v.form_portfolio.name.$invalid ||
        this.$v.form_portfolio.group_token.$invalid ||
        this.$v.form_portfolio.exchange_token.$invalid ||
        this.$v.form_portfolio.type.$invalid ||
        this.$v.form_portfolio.amount.$invalid;
      if (validData) {
        this.$toastr.error("Please complete the fields.", `${this.error}`);
        return;
      } else {
        if (this.is_edit) {
          this.updatePortfolio();
        } else {
          this.createPortfolio();
        }
      }

      this.is_edit = false;

      return;
    },
    handleAddTransaction() {
      if (this.sending_transaction) {
        this.$toastr.error(
          "Please wait while saving current transction.",
          `${this.error}`
        );
        return;
      }

      if (this.$v.transaction.balance_distribution_token.$invalid) {
        this.$toastr.error("Distribution balance is invalid.", `${this.error}`);
        return;
      }

      let validData =
        this.$v.transaction.type_transaction.$invalid ||
        this.$v.transaction.amount.$invalid;
      if (validData) {
        this.$toastr.error("Please complete the fields.", `${this.error}`);
        return;
      }

      this.sending_transaction = true;
      let payload = { ...this.transaction };
      payload["investor_token"] = this.$props.investorForm.token;
      payload["amount"] = parseFloat(payload["amount"]);
      delete payload["type_distribution"];

      let index_selected;

      this.balances.map((item, index) => {
        if (item.token === payload["balance_distribution_token"]) {
          index_selected = index;
        }
        return item;
      });

      this.$http
        .server1("bwfs/admin/balance_distribution/add/individual", payload)
        .then((res) => {
          this.sending_transaction = false;
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.$set(this.balances, index_selected, res.data.condition);
          this.handleToggleModelAddTransaction();
          this.transaction.type_transaction = "";
          this.transaction.balance_distribution_token = "";
          this.transaction.amount = "";
          this.$v.$reset();
          this.$toastr.success("Success add transaction", `${this.success}`);
        })
        .catch((e) => {
          this.sending_transaction = false;
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    handleAddTransactionDistributed() {
      if (this.sending_transaction) {
        this.$toastr.error(
          "Please wait while saving current transction.",
          `${this.error}`
        );
        return;
      }

      let validData =
        this.$v.transaction.type_transaction.$invalid ||
        this.$v.transaction.amount.$invalid;
      if (validData) {
        this.$toastr.error("Please complete the fields.", `${this.error}`);
        return;
      }

      let payload = { ...this.transaction };
      payload["investor_token"] = this.$props.investorForm.token;
      payload["amount"] = parseFloat(payload["amount"]);

      this.sending_transaction = true;
      this.$http
        .server1(
          `bwfs/admin/balance_distribution/${this.transaction.type_transaction}/distributed`,
          payload
        )
        .then((res) => {
          this.sending_transaction = false;
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }

          this.balances = res.data;
          this.handleToggleModelAddTransaction();
          this.transaction.type_transaction = "";
          this.transaction.balance_distribution_token = "";
          this.transaction.amount = "";
          this.$v.$reset();
          this.$toastr.success("Success add transaction", `${this.success}`);
        })
        .catch((e) => {
          this.sending_transaction = false;
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    async performDeleteRequest(body, index) {
      return new Promise((resolve) => {
        this.$store.dispatch("portfolios/delete_portfolio", {
          callback: ({ response }) => {
            if (response.error) {
              this.$toastr.error(`${response.message}`, `${this.error}`);
              return;
            } else {
              this.$toastr.success(
                "Portfolio deleted successfully.",
                `${this.success}`
              );
              const balances = this.balances;
              delete balances.splice(index, 1);
              this.balances = balances;
              this.$store.dispatch("portfolios/portfolio_list");
            }
            resolve();
          },
          body,
        });
      });
    },
    setBaseSymbol(balance) {
      this.$emit("handleBaseSymbol", balance);
    },
    updateCurrentBalance(new_value) {
      this.dif_initial_current = new_value - this.old_current_balance;
    },
    updatePortfolio() {
      this.$store.dispatch("portfolios/update_portfolio", {
        callback: ({ response }) => {
          if (response.error) {
            if (response.message) {
              this.$toastr.error(`${response.message}`, `${this.error}`);
            }
          } else {
            this.handleToggleModelAddBalance();
            this.$toastr.success(
              "Portfolio updated successfully.",
              `${this.success}`
            );
            this.$store.dispatch("portfolios/portfolio_list");
          }
        },
        error: ({ response }) => {},
      });
    },
    validExecuteAction() {
      if (this.$props.open_orders > 0 || this.$props.investor_on) {
        this.$toastr.error(
          "Is not posible edit or add new balance, because investor is on or has open orders",
          `${this.error}`
        );
        return false;
      }

      return true;
    },
  },
  watch: {
    list_balances: {
      handler(value) {
        this.balances = value;
      },
      deep: true,
      immediate: true,
    },
    "form_portfolio.group_token"(value) {
      if (!value || this.is_edit) {
        return;
      }

      let group = this.groups.find((e) => e.token == value);
      this.form_portfolio.name = group.name;
    },
    "form_portfolio.current_balance"(new_value, old_value) {
      if (this.is_edit) {
        this.updateCurrentBalance(new_value);
      }
    },
  },
};
</script>
<style scoped>
.balances-table .table tbody tr {
  padding: 2px;
}
.balances-table .table tbody td {
  padding: 0px;
}
.btn-distribution {
  display: flex;
  justify-content: end;
  gap: 15px;
}
.editing-current-balance {
  opacity: 0.6;
}
.editing-current-balance:focus {
  opacity: 1;
}
</style>
