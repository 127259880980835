<template>
  <div class="action-tab-component">
    <div class="title">
      <span><img :src="action" alt="" width="20" height="20px" /></span>
      <h1>Action</h1>
    </div>
    <div class="action-container">
      <div :class="{ act: !form_action }" class="box" @click="setAction(0)">
        <span
          ><img :src="!form_action ? envelope_act : envelope" alt=""
        /></span>
        <h2>Notification</h2>
      </div>
      <div :class="{ act: form_action }" class="box" @click="setAction(1)">
        <span><img :src="form_action ? event_act : event" alt="" /></span>
        <h2>Request</h2>
      </div>
    </div>
  </div>
</template>

<script>
import ActionIcon from "../../../../../../../public/common/action-sidebar.svg";
import EnvelopeIcon from "../../../../../../../public/common/envelope-action.svg";
import EnvelopeActIcon from "../../../../../../../public/common/envelope-act.svg";
import EventIcon from "../../../../../../../public/common/event-sidebar.svg";
import EventActIcon from "../../../../../../../public/common/event-sidebar-act.svg";
import { mapState } from "vuex";

export default {
  name: "ActionTab",
  data() {
    return {
      action: ActionIcon,
      envelope: EnvelopeActIcon,
      envelope_act: EnvelopeIcon,
      event: EventIcon,
      event_act: EventActIcon,
    };
  },
  computed: {
    ...mapState("investor_configuration", ["form_action"]),
  },
  methods: {
    setAction(opt) {
      this.$store.commit("investor_configuration/set_form_action", opt);
    },
  },
};
</script>

<style lang="scss">
@import "../../../../../../styles/pages/investor_configuration/action_tab.scss";
</style>
