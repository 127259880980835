<template>
  <div class="card p-2 mb-2">
      <label>Timeframes</label>
      <select v-model="timeframe" class="form-control">
        <option v-for = "t in timeframes" :value="t">{{t}}</option>
      </select>     
  </div>
</template>

<script>
export default {
  name: 'TimeframeMenuMobile',
  data() {
    return {
      timeframes: ['realtime'],
      // timeframes: ['realtime', '1m','2m','3m','5m','15m','30m','1h','2h','4h', '6h', '8h', '12h','1d'],
    }
  },
  computed: {
    timeframe: {
      set(value) {
        if (value === 'realtime') return this.$store.commit('set_realtime_chart', true)
        this.$store.commit('set_realtime_chart', false);
        this.$store.commit('set_timeframe', value);
        console.log('real time', this.$store.state.realtime_chart);
      },
      get() {
        if (this.$store.state.realtime_chart) return 'realtime';
        return this.$store.state.timeframe;
      }
    }
  },
}
</script>

