<template>
  <div class="key-selector-component">
    <div
      class="variable-selector"
      :class="{ two: type == 'two' }"
      @click="showList"
    >
      <img :src="variable_icon" alt="" />
      <img v-if="type == 'one'" :src="icon_caret" alt="" />
    </div>
    <div
      class="variable-list"
      :class="{ two: type == 'two' }"
      v-click-outside="hideList"
      v-if="showVariableList"
    >
      <div class="title">Add variable</div>
      <ul class="list">
        <li
          v-for="(variable, index) in variableList"
          :key="index"
          @click="selectVariable(variable)"
        >
          <div><img :src="variable_list_icon" alt="" /></div>
          <div>{{ variable }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import IconCaret from "../../../../../../../public/common/angle-down-icon.png";
import VariableIcon from "../../../../../../../public/common/variable-brace-icon.png";
import VariableBraceListIcon from "../../../../../../../public/common/variable-brace-list-icon.png";
import ClickOutside from "vue-click-outside";

export default {
  name: "KeySelector",
  directives: {
    ClickOutside,
  },
  props: {
    type: {
      type: String,
      default: "one",
    },
  },
  data() {
    return {
      icon_caret: IconCaret,
      variable_icon: VariableIcon,
      variable_list_icon: VariableBraceListIcon,
      showVariableList: false,
      variableList: ["EventName", "Amount", "Side", "Symbol"],
    };
  },
  methods: {
    showList() {
      setTimeout(() => {
        this.showVariableList = true;
      }, 100);
    },
    hideList() {
      this.showVariableList = false;
    },
    selectVariable(variable) {
      this.$emit("variableSelected", variable);
      this.hideList();
    },
  },
};
</script>
<style lang="scss">
@import "../../../../../../styles/pages/investor_configuration/key_selector.scss";
</style>
