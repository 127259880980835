<template>
  <div class="col-md-8">
    <div id="backupHistory" class="p-1 d-flex align-items-center mb-2">
      BACKUP HISTORY
    </div>
    <div class="table-responsive">
      <b-table
        :fields="fields"
        :items ="items"
        striped 
        hover
        small
        borderless
        :busy="loading"
      >
      <template #table-busy>
          <b-skeleton-table
            :rows="5"
            :columns="4"
            :table-props="{  striped: true }"
          ></b-skeleton-table>
      </template>
      <template #cell(name)='data'>
        <span style="cursor: pointer;" @click="downloadFile(data.item)">{{data.item.name}}</span>
      </template>
      </b-table>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BackupTable',
  async created() {
    this.loading = true
    let current_host = window.location.hostname;
    let data = { server: 'Develop'}
    if (current_host == 'production.bwfs.creedandbear.com') {
      data.server = 'Production'
    }
    const response = await this.$http.server1('bwfs/admin/backup/list', data);
    this.items = response.files.reverse().map( res => {
      return {
        date: new Date(res.date*1000).toLocaleString(),
        name: res.name,
        path: res.path
      }
    })
    this.loading = false
  },
  data() {
    return {
      loading: false,
      fields: [
        { key: 'date', label: 'Date'},
        { key: 'name', label: 'Name' }
      ],
      items: []
    }
  },
  methods: {
    async downloadFile(path) {
      console.log(path)
      const data = {
        file_name: path.name,
        path: path.path
      }
      const response = await this.$http.server1('bwfs/admin/backup/download', data);
      window.open(response.download_file.link, '_blank').focus();
    }
  },
}
</script>

<style lang="scss" scoped>
#backupHistory {
  opacity: 0.5;
  color: #f5f5f5;
  text-transform: uppercase;
  display: block;
  font-size: 14px;
}
::v-deep revogr-viewport-scroll .vertical-inner {
    overflow-y: auto;
    position: relative;
    width: 100%;
    flex-grow: 1;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: white;
}
</style>