import CurrentPositionsService from "../../../services/download_report.service";
export default (
  { state, commit },
  { callback, start_date, end_date, portfolio_token }
) => {
  commit("set", {
    _state: "download_report",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });
  const body = {
    investors_token: state.investor_token,
    start_date,
    end_date,
  };
  if (portfolio_token) {
    body.portfolio_token = portfolio_token;
  }
  CurrentPositionsService({
    body,
    callback: ({ response }) => {
      commit("set", {
        _state: "download_report",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "download_report",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
