<template>
  <div
    class="table-container d-flex flex-column"
    :class="{ 'fixed-table': fixed }"
  >
    <div class="table-body table-responsive mb-0">
      <table class="table table-striped">
        <TableColumnHeader>
          <template #checkboxx>
            <slot name="checkboxx"></slot>
          </template>
        </TableColumnHeader>
        <tbody>
          <tr v-for="(row, index) in data" :key="index">
            <td v-if="row.__title__">{{ row.__title__ }}</td>
            <template v-else>
              <td
                v-for="(header, j) in headers"
                :key="j"
                :class="
                  header.align == 'right'
                    ? 'text-right'
                    : header.align == 'left'
                    ? 'text-left'
                    : 'text-center'
                "
                :style="
                  header.color_options
                    ? {
                        color: findColor(header, row),
                      }
                    : ''
                "
              >
                <slot
                  v-if="!header.action"
                  :name="header.name"
                  :text="
                    header.secondary_name || header.third_name
                      ? [
                          row[header.name],
                          row[header.secondary_name],
                          row[header.third_name],
                        ]
                      : row[header.name]
                  "
                >
                  <span v-if="header.name">{{
                    printCellData(header, row)
                  }}</span>
                </slot>
                <span v-else class="action-buttons">
                  <slot
                    name="actions"
                    :id="header.id == false ? row : row[header.id]"
                  />
                </span>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
    <TableFooter>
      <template #pagination>
        <slot name="pagination"></slot>
      </template>
    </TableFooter>
  </div>
</template>

<script setup>
import { provide, computed } from "vue";
import TableFooter from "./TableFooter.vue";
import TableColumnHeader from "./TableColumnHeader.vue";

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  headers: {
    type: Array,
    required: true,
  },
  title: {
    type: String,
    default: "",
  },
  fixed: {
    type: Boolean,
    default: false,
  },
});

const printCellData = (header, row) => {
  const h = header.name.split(".");
  let cell = row[h[0]];
  let i = 1;
  while (i < h.length) {
    cell = cell ? cell[h[i]] : "";
    i++;
  }
  return header.formatter ? header.formatter(cell) : cell;
};

const findColor = (header, row) => {
  if (!header.color_by_cell_value)
    return header.color_options[row[header.name]];
  if (typeof row[header.name] == "string")
    return header.color_options.__default__;
  else if (row[header.name] > 0) return header.color_options.__positive__;
  else return header.color_options.__negative__;
};

const headers = computed(() => {
  return props.headers;
});

provide("title", props.title);
provide("headers", headers);
</script>
