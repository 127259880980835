<template>
  <div class="component-algorithms-table">
    <ProcessingLoader v-if="onLoading" />
    <AlertErrorInformation v-else-if="algorithms_status.error" />
    <div
      :class="{ 'overflow-table': algorithms.length < 3 }"
      class="algorithms-table table-responsive"
      v-else-if="algorithms.length > 0"
    >
      <Table
        :actions="false"
        :data="algorithms"
        :headers="columnHeaders"
        class="orders-table"
        title="Orders"
      >
        <template v-slot:token="{ text }">
          <span v-b-tooltip.hover :title="text[0]" @click="copyToken(text[0])">
            {{ text[0] }}
          </span>
        </template>
        <template v-slot:name="{ text }">
          <span>
            {{ text[0] }}
          </span>
        </template>
        <template v-slot:priority="{ text }">
          <span
            v-if="text"
            class="d-flex"
            :class="[
              text == 'high' ? 'red' : text == 'medium' ? 'yellow' : 'green',
            ]"
            ><span class="icon"
              ><img
                :src="
                  text == 'high'
                    ? arrow_up
                    : text == 'low'
                    ? arrow_down
                    : minus_icon
                "
                alt="" /></span
            >{{ text }}
          </span>
          <span v-else class="no-data">no data</span></template
        >
        <template v-slot:updated_at="{ text }">
          <span
            v-if="text"
            class="value"
            v-b-tooltip.hover
            :title="formatDate(text)"
            >{{ timeElapsed(text) }}</span
          >
          <span v-else class="no-data">no data</span>
        </template>
        <template v-slot:timeframe="{ text }">
          <span class="value">{{ timeFrame(text) }}</span>
        </template>
        <template v-slot:tags="{ text }">
          <span v-if="text && text[0].length" class="label-container">
            <span class="label">{{ getTags(text[0]).to_show }}</span>
            <span
              v-if="getTags(text[0]).rest > 0"
              class="label"
              role="button"
              @click="openTagsModal(text)"
              >+ {{ getTags(text[0]).rest }}</span
            >
          </span>
          <span v-else class="no-data">no data</span>
        </template>
        <template v-slot:is_active="{ text }">
          <span v-if="text" class="green">Yes</span>
          <span v-else class="red">No</span>
        </template>
        <template v-slot:is_health_ok="{ text }">
          <span v-if="text" class="green">Live</span>
          <span v-else class="red">Dead</span>
        </template>
        <template v-slot:note="{ text }">
          <span v-if="text">{{ text }}</span>
          <span v-else>No comments...</span>
        </template>
        <template v-slot:actions="id">
          <div class="dropup">
            <span class="action-button" data-toggle="dropdown">
              <img :src="menu_icon" alt="" />
            </span>
            <ul class="dropdown-menu">
              <li @click="openEditAlgorithmModal(id)">
                <img :src="edit_icon" />Edit
              </li>
              <li @click="deleteAlgorithm(id)">
                <img :src="trash_icon" />Delete
              </li>
              <li @click="openFollowModal(id)">
                <img :src="comments_icon" />Follow
              </li>
              <li @click="openTrackRecordModal(id)">
                <img :src="histogram_icon" />Track
              </li>
            </ul>
          </div>
        </template>
        <template #pagination>
          <Pagination
            :pagination="paginate"
            :go-first-page-callback="goFirstPage"
            :go-last-page-callback="goLastPage"
            :go-next-page-callback="goNextPage"
            :go-prev-page-callback="goPrevPage"
          />
        </template>
      </Table>
    </div>
    <AlertNoInformation v-else />

    <SystemsHeartbeatInstance
      v-if="showSystemsHeartbeatModal"
      :isEdit="true"
      @closed="showSystemsHeartbeatModal = false"
    />

    <FollowModal
      v-if="showFollowModal"
      :contacts="contacts"
      :isEdit="true"
      @closed="showFollowModal = false"
    />

    <TagsModal
      v-if="showTagsModal"
      :isEdit="true"
      :tags="tags"
      @closed="showTagsModal = false"
    />
    <TrackRecordModal
      v-if="showTrackRecordModal"
      :token="tokenSelected"
      @closed="showTrackRecordModal = false"
    />
    <ConfirmDeleteModal
      v-if="showConfirmDeleteModal"
      @confirm-delete="confirmDelete"
      @closed="showConfirmDeleteModal = false"
    />
  </div>
</template>

<script>
import AlertNoInformation from "../../commons/alerts/AlertNoInformation.vue";
import AlertErrorInformation from "../../commons/alerts/AlertErrorInformation.vue";
import CommentsIcon from "../../../../public/common/comments-icon.png";
import HistogramIcon from "../../../../public/common/histogram-icon.png";
import FollowModal from "../systems_heartbeat/modals/FollowModal.vue";
import ArrowUp from "../../../../public/common/arrow-up-red.svg";
import ArrowDown from "../../../../public/common/arrow-down-green.svg";
import EditWhiteIcon from "../../../../public/common/edit-white-icon.svg";
import TagsModal from "../systems_heartbeat/modals/TagsModal.vue";
import MenuOptions from "../../../../public/common/menu-dots-vertical.png";
import MinusIcon from "../../../../public/common/minus-yellow.svg";
import Pagination from "../../commons/Pagination.vue";
import ProcessingLoader from "../../commons/alerts/ProcessingLoader.vue";
import SystemsHeartbeatInstance from "./modals/SystemsHeartbeatInstance.vue";
import TrashIcon from "../../../../public/common/trash-icon.svg";
import Table from "../../commons/table/Table.vue";
import { mapState } from "vuex";
import moment from "moment";
import TrackRecordModal from "./modals/TrackRecordModal.vue";
import ConfirmDeleteModal from "./modals/ConfirmDeleteModal.vue";

export default {
  name: "ResolvedErrors",
  data() {
    return {
      arrow_up: ArrowUp,
      arrow_down: ArrowDown,
      showSystemsHeartbeatModal: false,
      showTrackRecordModal: false,
      showConfirmDeleteModal: false,
      tokenSelected: null,
      columnHeaders: [
        {
          name: "token",
          title: "Token",
          align: "left",
          secondary_name: "token",
        },
        { name: "name", title: "Name", align: "left", secondary_name: "token" },
        { name: "priority", title: "Priority", align: "left" },
        { name: "system_name", title: "System", align: "left" },
        { name: "updated_at", title: "Last check", align: "left" },
        { name: "timeframe", title: "Time frame", align: "left" },
        {
          name: "tags",
          title: "Tags",
          align: "left",
          secondary_name: "token",
        },
        { name: "is_active", title: "Active", align: "left" },
        { name: "is_health_ok", title: "Status", align: "left" },
        { name: "note", title: "Notes", align: "left" },
        { title: "", action: true, id: "token" },
      ],
      comments_icon: CommentsIcon,
      histogram_icon: HistogramIcon,
      contacts: [],
      edit_icon: EditWhiteIcon,
      tags: null,
      menu_icon: MenuOptions,
      minus_icon: MinusIcon,
      onError: false,
      showFollowModal: false,
      showTagsModal: false,
      showSystemsHeartbeatModal: false,
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
          <g clip-path="url(#clip0_1634_940)">
          <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
          </g>
          <defs>
          <clipPath id="clip0_1634_940">
          <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
          </clipPath>
          </defs>
          </svg> Success`,
      trash_icon: TrashIcon,
    };
  },
  components: {
    AlertNoInformation,
    AlertErrorInformation,
    ArrowUp,
    ArrowDown,
    ConfirmDeleteModal,
    FollowModal,
    MinusIcon,
    SystemsHeartbeatInstance,
    Pagination,
    ProcessingLoader,
    Table,
    TagsModal,
    TrackRecordModal,
  },
  created() {
    this.$store.dispatch("systems_heartbeat/systems_heartbeat_list", {});
  },
  computed: {
    ...mapState("systems_heartbeat", ["systems_heartbeat_list"]),
    current_page() {
      return this.systems_heartbeat_list._data.paginate
        ? this.systems_heartbeat_list._data.paginate.current_page
        : null;
    },
    algorithms() {
      return this.systems_heartbeat_list && this.systems_heartbeat_list._data
        ? this.systems_heartbeat_list._data.data
        : [];
    },
    algorithms_status() {
      return this.systems_heartbeat_list && this.systems_heartbeat_list._data
        ? this.systems_heartbeat_list._data
        : [];
    },
    onLoading() {
      return this.systems_heartbeat_list._status._on_loading;
    },
    paginate() {
      return this.systems_heartbeat_list._data.paginate
        ? this.systems_heartbeat_list._data.paginate
        : null;
    },
  },
  methods: {
    copyToken(token) {
      navigator.clipboard.writeText(token);
      this.$toastr.success(
        "The token has been copied correctly.",
        `${this.success}`
      );
    },
    async confirmDelete(id) {
      await this.performDeleteRequest(this.tokenSelected);
    },
    deleteAlgorithm(id) {
      this.tokenSelected = id.id;
      this.showConfirmDeleteModal = true;
    },
    formatDate(text) {
      return moment.utc(text).format("YYYY-MM-DD HH:mm:ss");
    },
    getTags(text) {
      var to_show = [];
      var rest = 0;

      if (text.length > 0) {
        to_show = text.slice(0, 1).toString();
        rest = text.length - 1;
      }

      return {
        to_show,
        rest,
      };
    },
    goFirstPage() {
      this.$store.dispatch("systems_heartbeat/systems_heartbeat_list", {
        page: 1,
      });
    },
    goLastPage() {
      this.$store.dispatch("systems_heartbeat/systems_heartbeat_list", {
        page: this.paginate.total_pages,
      });
    },
    goNextPage() {
      this.$store.dispatch("systems_heartbeat/systems_heartbeat_list", {
        page: this.current_page + 1,
      });
    },
    goPrevPage() {
      this.$store.dispatch("systems_heartbeat/systems_heartbeat_list", {
        page: this.current_page - 1,
      });
    },
    openTrackRecordModal(id) {
      this.tokenSelected = id.id;
      this.showTrackRecordModal = true;
    },
    openEditAlgorithmModal(id) {
      const selected_algorithm = this.systems_heartbeat_list._data.data.find(
        (algorithm) => algorithm.token == id.id
      );

      const form_data = {
        algorihtm_name: selected_algorithm.name,
        algorithm_note: selected_algorithm.note,
        algorithm_token:
          selected_algorithm.algorithm_token == ""
            ? "other"
            : selected_algorithm.algorithm_token,
        is_active: selected_algorithm.is_active,
        priority: selected_algorithm.priority,
        system_name: selected_algorithm.system_name,
        token: selected_algorithm.token,
        timeframe: selected_algorithm.timeframe,
        tags: selected_algorithm.tags,
      };

      this.$store.commit(
        "systems_heartbeat/set_systems_heartbeat_form",
        form_data
      );
      this.showSystemsHeartbeatModal = true;
    },
    openTagsModal(text) {
      this.tags = {
        tags: text[0],
        id: text[1],
      };

      this.showTagsModal = true;
    },
    async performDeleteRequest(token) {
      return new Promise((resolve) => {
        this.$store.dispatch("systems_heartbeat/delete_systems_heartbeat", {
          callback: ({ response }) => {
            if (response.error) {
              this.$toastr.error(`${response.message}`, `${this.error}`);
              return;
            } else {
              this.$toastr.success(
                "Algorithm instance deleted successfully.",
                `${this.success}`
              );
              this.$store.dispatch(
                "systems_heartbeat/systems_heartbeat_list",
                {}
              );
            }
            resolve();
          },
          token,
        });
      });
    },
    openFollowModal(id) {
      this.$store.commit("systems_heartbeat/set_algorithm_token", id.id);
      this.showFollowModal = true;
    },
    timeElapsed(text) {
      const now = moment();
      const timestamp = moment.utc(text);
      const duration = moment.duration(now.diff(timestamp));

      const days = Math.floor(duration.asDays());
      const hours = Math.floor(duration.asHours() % 24);
      const minutes = Math.floor(duration.asMinutes() % 60);

      let elapsed = "";
      if (days > 0) {
        elapsed = `${days}d ago`;
      } else if (hours > 0) {
        elapsed = `${hours}h ago`;
      } else {
        elapsed = `${minutes}min ago`;
      }
      return elapsed;
    },
    timeFrame(value) {
      const duration = moment.duration(value, "minutes");
      const hours = duration.hours();
      const minutes = duration.minutes();
      return `${hours != 0 ? hours + "h" : ""} ${
        minutes != 0 ? minutes + "m" : ""
      }`;
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/pages/systems_heartbeat/algorithms_table.scss";
</style>
