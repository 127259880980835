import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import BacktestStore from "@/store/backtest.js";
import SupervisorStore from "@/store/supervisor.js";
import InvestorStore from "@/store/investor.js";
import socketActions from "@/store/socketActions.js";
import CorrelatorStore from "@/store/correlator.js";
import SubInvestorStore from "@/store/subinvestor.js";
import ContributorStore from "@/store/contributor.js";
import systems_heartbeat from "./systems_heartbeat";
import connections from "./connections";
import dashboard from "./dashboard";
import exchange from "./exchange";
import groups from "./groups";
import health_audit from "./health_audit";
import investors_v2 from "./investors_list";
import investor_configuration from "./investor_configuration";
import portfolios from "./portfolios";
import transactions from "./transactions";
import CONSTANTS from "@/helpers/constants.js";

export default new Vuex.Store({
  state: {
    user: {},
    token: "",
    backtests: [],
    backtest_favorites: [],
    supervisors: [],
    supervisor: [],
    ...BacktestStore.state,
    ...SupervisorStore.state,
    ...InvestorStore.state,
    ...CorrelatorStore.state,
    ...SubInvestorStore.state,
    ...ContributorStore.state,
  },
  mutations: {
    ...BacktestStore.mutations,
    ...SupervisorStore.mutations,
    ...InvestorStore.mutations,
    ...CorrelatorStore.mutations,
    ...SubInvestorStore.mutations,
    ...ContributorStore.mutations,
    auth_user(state, user) {
      state.user = user;
    },
    token(state, token) {
      state.token = token;
    },
  },
  actions: {
    ...SupervisorStore.actions,
    ...InvestorStore.actions,
    ...socketActions.actions,
    ...CorrelatorStore.actions,
  },
  modules: {
    dashboard,
    connections,
    exchange,
    groups,
    health_audit,
    investors_v2,
    investor_configuration,
    portfolios,
    systems_heartbeat,
    transactions,
  },
});
