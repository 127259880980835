import CreateNotificationConfigService from "../../../services/create_notification_configuration.service";

export default ({ commit, state }, { callback, error }) => {
  commit("set", {
    _state: "create_notification_configuration",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });

  const { channel_type, channel_identifier } =
    state.form_notification_configuration;
  const body = {
    algorithm_instance_token: state.algorithm_token,
    channel_type,
    channel_identifier,
  };

  CreateNotificationConfigService({
    body,
    callback: ({ response }) => {
      commit("set", {
        _state: "create_notification_configuration",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "create_notification_configuration",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
