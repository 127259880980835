<template>
  <div :class="[containerClassName]">
    <label class="col-12 p-0" v-if="showLabel">{{ label }}</label>

    <date-range-picker
      ref="picker"
      id="dark"
      opens="right"
      :locale-data="{ firstDay: 1, format: format }"
      :singleDatePicker="false"
      :timePicker="timepicker"
      :timePicker24Hour="timepicker"
      :showWeekNumbers="false"
      :showDropdowns="false"
      :autoApply="true"
      :linked-calendars="true"
      :minDate="$props.minDate"
      :maxDate="$props.maxDate"
      v-model="dateRange"
      @update="updateValues"
      :ranges="ranges"
    >
      <template
        v-if="placeholder && dateRange.startDate == null"
        v-slot:input="picker"
      >
        {{ placeholder }}
        <img v-if="icon" class="date-range-icon" :src="icon" />
      </template>
      <template v-else v-slot:input="picker">
        {{ dateRange.startDate | date(format) }} -
        {{ dateRange.endDate | date(format) }}
        <img v-if="icon" class="date-range-icon" :src="icon" />
      </template>
    </date-range-picker>
  </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {
  name: "BaseDateRangePicker",
  components: {
    DateRangePicker,
  },
  props: {
    format: {
      type: String,
      required: false,
      default: "DD-MM-YYYY",
    },
    showLabel: {
      required: false,
      default: false,
    },
    label: {
      required: false,
      default: "",
      type: String,
    },
    minDate: {
      required: false,
      default: null,
    },
    maxDate: {
      required: false,
      default: null,
    },
    containerClassName: {
      required: false,
      type: String,
      default: "",
    },
    timepicker: {
      required: false,
      type: Boolean,
      default: false,
    },
    dateDefault: {
      required: false,
    },
    everydayMinDate: {
      required: false,
      default: null,
    },
    everydayMaxDate: {
      required: false,
      default: null,
    },
    placeholder: {
      required: false,
      default: null,
    },
    icon: {
      required: false,
      default: null,
    },
  },
  created() {
    if (this.placeholder == null) {
      const startDate = new Date();
      startDate.setUTCDate(startDate.getUTCDate() - 7);
      this.dateRange.startDate = startDate;
      this.updateValues();
    }
  },
  data() {
    let date = new Date();
    return {
      dateRange: {
        startDate: null,
        endDate: new Date(),
      },
      ranges: {
        Today: [new Date(), new Date()],
        Yesterday: [
          new Date(new Date().setDate(new Date().getDate() - 1)),
          new Date(new Date().setDate(new Date().getDate() - 1)),
        ],
        "Last 7 Days": [
          new Date(new Date().setDate(new Date().getDate() - 6)),
          new Date(),
        ],
        "Last 30 Days": [
          new Date(new Date().setDate(new Date().getDate() - 29)),
          new Date(),
        ],
        "This Month": [
          new Date(date.getFullYear(), date.getMonth(), 1),
          new Date(date.getFullYear(), date.getMonth() + 1, 0),
        ],
        "Last Month": [
          new Date(date.getFullYear(), date.getMonth() - 1, 1),
          new Date(date.getFullYear(), date.getMonth(), 0),
        ],
        "This Year": [
          new Date(date.getFullYear(), 0, 1),
          new Date(date.getFullYear(), date.getMonth() + 1, 0),
        ],
        Everyday: [
          this.$props.everydayMinDate
            ? this.$props.everydayMinDate
            : this.$props.minDate,
          this.$props.everydayMaxDate
            ? this.$props.everydayMaxDate
            : this.$props.maxDate,
        ],
      },
    };
  },
  methods: {
    updateValues() {
      const dateRangeFormated = { ...this.dateRange };
      dateRangeFormated.startDate = moment(dateRangeFormated.startDate)
        .locale("es")
        .format(this.format);
      dateRangeFormated.endDate = moment(dateRangeFormated.endDate)
        .locale("es")
        .format(this.format);
      this.$emit("input", dateRangeFormated);
    },
  },
  watch: {
    "$props.dateDefault"(value) {
      if (value.startDate) {
        this.dateRange.startDate = new Date(value.startDate);
        this.dateRange.endDate = new Date(value.endDate);
      } else if (value[0]) {
        this.dateRange.startDate = new Date(value[0]);
        this.dateRange.endDate = new Date(value[1]);
      }
    },
  },
  filters: {
    date(date, formatDate) {
      return moment(date).locale("es").format(formatDate);
    },
  },
};
</script>
<style>
.daterangepicker .calendar-table {
  border: 0 !important;
  border-radius: 4px !important;
  background-color: #1c2030 !important;
}
.daterangepicker.show-calendar .drp-buttons,
.daterangepicker.show-calendar .drp-calendar {
  display: block !important;
  text-align: left !important;
  border: 0 !important;
  background: #0e1019 !important;
}
.form-control,
.reportrange-text {
  background: #0e1019 !important;
}
.daterangepicker {
  background-color: #1d1e25 !important;
}
.daterangepicker .calendar-table {
  background-color: #1d1e25 !important;
}

.daterangepicker .calendar-table td,
th {
  background-color: #1d1e25;
}

.daterangepicker td.active,
.daterangepicker td.active:hover,
.daterangepicker td.in-range {
  background-color: #0e1019 !important;
  color: #fff !important;
}

.daterangepicker td.off,
.daterangepicker td.off.end-date,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date {
  color: #747b96 !important;
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}
.daterangepicker tr,
th {
  background: #1d1e25 !important;
}
.calendars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: inherit !important;
}
</style>
