<template>
	<b-nav-text>
    <div class="resume row">
      <div class="content col-4 col-sm-2 col-md-2">
        <span class="label">
          Symbol
        </span>
        <span class="label inf mono">
          BTCUSDT
        </span>
      </div>
      <div class="content col-4 col-sm-2 col-md-2">
        <span class="label">
          Price
        </span>
        <span class="label inf mono">
          {{price}}
        </span>
      </div>
      <div class="content col-4 col-sm-2 col-md-2">
        <span class="label">
          24h Change
        </span>
        <span class="label inf mono" :class="[price_change_percent >= 0 ? 'text-success' : 'text-danger']">
          {{price_change_percent.toFixed(2)}}%
        </span>
      </div>
      <div class="content col-4 col-sm-2 col-md-2">
        <span class="label">
          24h High
        </span>
        <span class="label inf mono">
          {{high_price}}
        </span>
      </div>
      <div class="content col-4 col-sm-2 col-md-2">
        <span class="label">
          24h Low
        </span>
        <span class="label inf mono">
         {{low_price}}
        </span>
      </div>
    </div>
  </b-nav-text>
</template>
<script>
	import handleSocket from '@/helpers/socket';
	
	export default {
		name: 'PriceRealTime',
		mounted() {
			this.websocket = handleSocket(null, this.onOpen, this.onClose, this.onMessage, this.onError, this.url_socket);
		},
		data() {
			return {
				low_price: ' 17.000,58',
				high_price: '19.010,58',
				price: '18.756,58',
				price_change_percent: 0,
				url_socket: 'wss://stream.binance.com:9443/ws/BTCUSDT@ticker',
			};
		},
		methods:{
			sendConnect() {
				const payload = {
					"method": "SUBSCRIBE",
					"id": 1,
					"params": [
						"btcusdt@ticker",
					]
				};
				this.doSend(JSON.stringify(payload));
	    },
			onOpen() {
	      this.sendConnect();
	    },
	    onClose(evt) {
	      this.writeToScreen("DISCONNECTED");
	    },
	    onMessage(evt) {
	      if (evt.data) {
	        this.handleUpdatePrices(JSON.parse(evt.data));
	      }
	    },
	    onError(evt) {
	      console.log(evt);
	      // this.writeToScreen('<span style="color: red;">ERROR:</span>' + evt.data);
	    },
	    doSend(message) {
	      this.websocket.send(message);
	    },
	    writeToScreen(message) {
	      console.log("writeToScreen", message);
	    },
	    handleUpdatePrices(data) {
	    	if (data.c) {
	    		this.price = this.$helpers.toParseNumber('usdt','price',data.c);
	    	}
	    	if (data.l) {
	    		this.low_price = this.$helpers.toParseNumber('usdt','price',data.l);
	    	}
	    	if (data.h) {
	    		this.high_price = this.$helpers.toParseNumber('usdt','price',data.h);
	    	}
	    	if (data.P) {
	    		this.price_change_percent = Number(data.P);
	    	}
	    }
		}
	};
</script>