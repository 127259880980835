import UpdateInvestorService from "../../../services/update_investor.service";

export default ({ commit, state }, { callback, error }) => {
  commit("set", {
    _state: "update_investor",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });

  const {
    base_currency,
    demo,
    future,
    is_real,
    labeling,
    name,
    manual_order,
    note,
    tags,
  } = state.form_investor;

  const future_ = future ? 1 : 0;

  const body = {
    base_currency,
    future: manual_order == 2 ? 1 : future_,
    is_real: demo ? 0 : 1,
    labeling: tags,
    name,
    note,
    manual_order,
  };

  const id = state.investor_token;

  UpdateInvestorService({
    body,
    id,
    callback: ({ response }) => {
      commit("set", {
        _state: "update_investor",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "update_investor",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
