import random_float from "../../../helpers/generators/random_float";
import random_number from "../../../helpers/generators/random_number";

export default () => {
  const symbols = ["ADAUSDT", "BTCUSDT", "SOLUSDT", "XRPUSDT", "other"];
  const exposure = random_float(-4000, 100000);
  const exposure_percentage = random_float(-100, 100);
  const symbol = symbols[random_number(0, symbols.length - 1)];

  return {
    exposure,
    exposure_percentage,
    symbol,
  };
};
