<template>
  <div :class="`panel page__${$route.name.toLowerCase()}`">
    <b-container fluid>
      <b-row class="p-0">
        <SearchInvestor :firstLoad="firstLoad" ref="searchInvestor" />

        <b-col cols="12" lg="10" class="p-0 admin_content nice-scroll">
          <div class="content p-2">
            <div class="row pl-3 pr-3">
              <div class="col-md-4 p-1 d-flex align-items-center"></div>
              <div class="col-md-8 p-1 text-right">
                <MultiSelectAccounts />
                <b-button
                  v-if="
                    $store.state.user.role_id === 1 &&
                    $store.state.select_accounts.length === 0
                  "
                  variant="dark"
                  @click="showCreate()"
                  >New user</b-button
                >
                <b-button variant="dark" class="ml-1" @click="loadUsers"
                  >Load users</b-button
                >
                <CreateEditInvestor
                  @handleReload="handleReload"
                  ref="CreateEditInvestor"
                />
              </div>
            </div>
            <InvestorList
              :firstLoad="firstLoad"
              v-show="
                show &&
                !$store.state.order_tracking_mode &&
                $store.state.migration_authorized
              "
              ref="InvestorList"
            />
            <div
              class="row pl-1 pr-1"
              v-show="
                show &&
                !$store.state.order_tracking_mode &&
                !$store.state.migration_authorized
              "
            >
              <div class="col">
                <b-alert class="mb-2" show variant="dark">
                  Authorize the user to make the migration</b-alert
                >
              </div>
            </div>
            <div class="row pl-3 pr-3 d-flex flex-row">
              <div class="col-md-10">
                <div class="page__investors">
                  <div class="sidebar__user_closed_orders nice-scroll">
                    <InvestorOrderTable
                      v-show="$store.state.order_tracking_mode"
                      ref="OrdersTable"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-2 p-0">
                <div class="page__investors">
                  <div class="sidebar__user_manual_orders nice-scroll">
                    <CardNotifications
                      v-show="$store.state.order_tracking_mode"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- <AuditAccount/> -->
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ServersInfo from "@/components/template/sidebars/ServersInfo";
import MaintenanceMode from "@/components/template/sidebars/MaintenanceMode";
import CreateEditInvestor from "@/components/pages/investors/CreateEditInvestor.vue";
import UserComponent from "@/components/pages/investors/User";
import SearchInvestor from "@/components/pages/investors/SearchInvestor.vue";
import InvestorList from "@/components/pages/investors/InvestorList.vue";
import AuditAccount from "@/components/pages/investors/AuditAccount.vue";
import InvestorOrderTable from "@/components/commons/OrdersTable.vue";
import CardNotifications from "@/components/pages/investors/CardNotifications.vue";
import MultiSelectAccounts from "@/components/pages/investors/MultiSelectAccounts.vue";
import CONSTANTS from "../helpers/constants";
import CryptoJS from "react-native-crypto-js";

export default {
  name: "Investors",
  components: {
    SideBarServersInfo: ServersInfo,
    SideBarMaintenanceMode: MaintenanceMode,
    UserComponent,
    CreateEditInvestor,
    SearchInvestor,
    InvestorList,
    AuditAccount,
    InvestorOrderTable,
    CardNotifications,
    MultiSelectAccounts,
  },
  mounted() {
    this.$helpers.setWindowName();
  },
  created() {
    if (this.$store.state.symbols.length === 0) {
      this.$store.commit("bwfs_get_symbols", this);
    }
    this.$helpers.setWindowName();
    // if (this.$http.url_api(true) !== CONSTANTS.develop_api) this.isDevelopApi = false
  },
  data() {
    return {
      user: {
        demo: false,
        future: true,
      },
      show: true,
      isDevelopApi: true,
      firstLoad: true,
    };
  },
  methods: {
    loadUsers() {
      this.firstLoad = false;
      setTimeout(() => {
        this.$refs.searchInvestor.searchingInvestor();
      }, 100);
    },
    showCreate() {
      this.$bvModal.show("modal-create-user");
      // this.$store.commit('set_duplicate_investor', false);
    },
    handleReload() {
      this.$refs.searchInvestor.searchingInvestor();
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/commons/admin.scss";
@import "@/styles/pages/users.scss";
@import "@/styles/pages/investors.scss";
</style>
