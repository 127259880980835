<template>
  <div :class="`panel page__${$route.name.toLowerCase()}`">
    <b-container fluid>
      <b-row class="p-0">
        <b-col cols="12" lg="2" class="p-0 admin_sidebar nice-scroll">
          <div class="d-block p-2 mb-2">
            <div class="separator">Authorizations filtering</div>
            <div class="card p-2 mb-2 d-block">
              <div class="form-group">
                <label>Search</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="search"
                  id="search"
                  :disabled="isLoading"
                  placeholder="Enter text here."
                />
                <div class="loading" v-if="isLoading">
                  <b-spinner variant="light"></b-spinner>
                </div>
              </div>
              <p class="text-input-help">
                Search within the list of authorizations.
              </p>
            </div>
            <div class="card p-2 mb-2 d-block">
              <div class="form-group">
                <label>Date</label>
                <input
                  v-model="AuthDate"
                  class="form-control"
                  @change="getAuthorizations(1)"
                  type="date"
                />
                <div class="loading" v-if="isLoading">
                  <b-spinner variant="light"></b-spinner>
                </div>
              </div>
            </div>
            <div class="card p-2 mb-2 d-block">
              <div class="form-group">
                <label>Filter By Country</label>
                <select
                  @change="getAuthorizations(1)"
                  v-model="country"
                  class="form-control"
                >
                  <option :value="''">All</option>
                  <option v-for="country in countryList" :value="country">
                    {{ country }}
                  </option>
                </select>
                <div class="loading" v-if="isLoading">
                  <b-spinner variant="light"></b-spinner>
                </div>
              </div>
            </div>
            <div class="card p-2 mb-2 d-block">
              <div class="form-group">
                <label>Filter By Status</label>
                <select
                  v-model="status"
                  class="form-control"
                  @change="getAuthorizations(1)"
                >
                  <option :value="''">All</option>
                  <option value="Authorize">Authorized</option>
                  <option value="Pending">Pending</option>
                  <option value="Rejected">Rejected</option>
                </select>
                <div class="loading" v-if="isLoading">
                  <b-spinner variant="light"></b-spinner>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" lg="10" class="p-0 admin_content nice-scroll">
          <div class="content p-2">
            <div class="row pl-3 pr-3">
              <div class="col-md-6 p-1 d-flex align-items-center">
                Total records found: {{ paginate.records }}
              </div>
              <div class="col-md-6 p-1 text-right">
                <!--  -->
              </div>
            </div>

            <div class="row pl-3 pr-3">
              <div class="col-md-12 p-1">
                <div class="table-responsive">
                  <b-table
                    id="table"
                    :items="items"
                    :fields="fields"
                    striped
                    hover
                    small
                    borderless
                    :busy="isLoading"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                  >
                    <template #cell(status)="row">
                      <div class="d-block">
                        <span
                          class="badget badget-success"
                          v-if="row.item.status == 'Authorize'"
                        >
                          Authorized
                        </span>
                        <span
                          class="badget badget-info"
                          v-if="row.item.status == 'Pending'"
                        >
                          Pending
                        </span>
                        <span
                          class="badget badget-danger"
                          v-if="row.item.status == 'Rejected'"
                        >
                          Rejected
                        </span>
                        <span
                          v-b-tooltip.hover
                          title="Approve or disapprove access"
                          style="font-size: 18px"
                          @click.prevent="handleStatus(row.item)"
                        >
                          <b-icon-arrow-repeat></b-icon-arrow-repeat>
                        </span>
                      </div>
                    </template>
                    <template #cell(created_at)="row">
                      {{
                        row.item.created_at
                          ? $helpers.formatToUTC(new Date(row.item.created_at))
                          : ""
                      }}
                    </template>
                    <template #cell(user)="row">
                      {{ row.item.user.email ? row.item.user.email : "---" }}
                      <br />
                      {{ row.item.token_user }}
                    </template>
                    <template #table-busy>
                      <div class="text-center my-2">
                        <b-spinner type="grow" label="Spinning"></b-spinner>
                      </div>
                    </template>
                  </b-table>
                  <b-pagination
                    id="table"
                    v-model="currentPage"
                    :total-rows="paginate.records"
                    :limit="perPage"
                    :per-page="perPage"
                    class="mt-2"
                    @page-click="linkGen"
                    v-if="paginate.total_pages > 0"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import BaseInput from "@/components/commons/Input";
import CONSTANTS from "../helpers/constants";
import moment from "moment";

export default {
  name: "Authorizations",
  components: {
    BaseInput,
  },
  created() {
    this.getAuthorizations(this.currentPage);
    this.$helpers.setWindowName();
  },
  mounted() {
    const myInput = document.getElementById("search");
    myInput.addEventListener("keyup", this.$debounce(this.getAuthorizations));
    this.$helpers.setWindowName();
  },
  data() {
    return {
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
        <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
        </svg> Error`,
      sortBy: "created_at",
      sortDesc: false,
      AuthDate: moment().format("YYYY-MM-DD"),
      countryList: CONSTANTS.countryList,
      search: "",
      fields: [
        { key: "user", sortable: true },
        { key: "cookie_auth", sortable: true },
        { key: "country", sortable: true },
        { key: "timezone", sortable: true },
        { key: "status", sortable: true },
        { key: "created_at", sortable: true },
      ],
      items: [],
      status: "",
      country: "",
      perPage: 15,
      currentPage: 1,
      isLoading: false,
      paginate: {
        current_page: 1,
        per_page: 15,
        total_pages: 0,
        records: 0,
      },
    };
  },
  methods: {
    getAuthorizations(page = 1) {
      const data = {
        page: page,
        limit: this.perPage,
        sort_by: this.sortBy === "user" ? "token_user" : this.sortBy,
        order: this.sortDesc ? "DESC" : "ASC",
        status: this.status,
        country: this.country,
        login_date: this.AuthDate,
      };
      if (this.search) {
        data["search"] = this.search;
      }

      this.isLoading = true;
      this.items = [];
      this.$http
        .server1("bwfs/admin/access/pending", data)
        .then((res) => {
          if (res.error) {
            this.isLoading = false;
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.isLoading = false;
          this.items = res.data;
          this.paginate = res.paginate;
        })
        .catch((e) => {
          this.isLoading = false;
          this.$toastr.error(`${e}`, `${this.error}`);
        });
    },
    authorized(request) {
      this.isLoading = true;
      let data = {
        token_user: request.token_user,
        cookie_autorized: request.cookie_auth,
      };
      this.$http
        .server1("bwfs/admin/access/autorize", data)
        .then((res) => {
          if (res.error) {
            this.isLoading = false;
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.getAuthorizations(this.currentPage);
        })
        .catch((e) => {
          this.isLoading = false;
          this.$toastr.error(`${e}`, `${this.error}`);
        });
    },
    rejected(request) {
      this.isLoading = true;
      let data = {
        token_user: request.token_user,
        cookie_autorized: request.cookie_auth,
      };
      this.$http
        .server1("bwfs/admin/access/rejected", data)
        .then((res) => {
          if (res.error) {
            this.isLoading = false;
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.getAuthorizations(this.currentPage);
        })
        .catch((e) => {
          this.isLoading = false;
          this.$toastr.error(`${e}`, `${this.error}`);
        });
    },
    linkGen(event, pageNum) {
      this.currentPage = pageNum;
      this.getAuthorizations(pageNum);
    },
    handleStatus(item) {
      if (item.status === "Pending" || item.status === "Rejected") {
        this.authorized(item);
      } else {
        this.rejected(item);
      }
    },
  },
  watch: {
    sortBy() {
      this.getAuthorizations();
    },
    sortDesc() {
      this.getAuthorizations();
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/commons/admin.scss";
@import "@/styles/pages/users.scss";
</style>
