import groupsSelectorService from "../../../services/groups_selector.service";

export default ({ commit, state }) => {
  commit("set", {
    _state: "groups_selector",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });
  groupsSelectorService({
    callback: ({ response }) => {
      commit("set", {
        _state: "groups_selector",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "groups_selector",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
