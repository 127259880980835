import openOrdersService from "../../../services/open_orders.service";
export default ({ state, commit }, payload) => {
  commit("set", {
    _state: "open_orders",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });
  const investor_token = state.investor_token;
  const params = {
    status: 2,
    limit: 5,
    ...payload,
  };
  openOrdersService({
    investor_token,
    params,
    callback: ({ response }) => {
      commit("set", {
        _state: "open_orders",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "open_orders",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
