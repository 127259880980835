<template>
  <span class="turn-button" style="font-size:1.5rem;">
    <b-icon icon ="toggle-on" variant="success"></b-icon>
  </span>
</template>

<script>
export default {
  name: 'toggle-on-success'
}
</script>

<style scoped>
::v-deep svg {
  fill: #8bf125;
}
</style>