export default {
  exchange_token: null,
  investor_token: null,
  ignore_transaction: false,
  is_remaining: false,
  group_token: null,
  name: null,
  type: null,
  amount: null,
};
