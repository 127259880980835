<template>
  <div>
    <div>
      <div
        ref="closeOrdersModal"
        id="closeOrdersModal"
        class="modal fade modal-close-orders"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content max-width">
            <div class="modal-header">
              <h5 class="modal-title">Orders</h5>
              <span class="view-logs-modal-close" @click="modal.hide()"
                ><img :src="close_icon"
              /></span>
            </div>
            <div class="modal-body">
              <div class="filters">
                <div class="form-group">
                  <label>Search</label>
                  <div class="input-group">
                    <input
                      v-model="closed_orders_filters.search"
                      class="form-control selector"
                      placeholder="Search by symbol or token"
                      type="text"
                    />
                    <span
                      class="input-group-text no-border right dark"
                      id="search-form-icon"
                    >
                      <img :src="search" alt="" />
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <label>Algorithm</label>
                  <Multiselect
                    v-model="closed_orders_filters.portfolio_token"
                    :hideSelected="true"
                    :options="algorithms"
                    class="form-control selector"
                    label="name"
                    placeholder="Filter by algorithm"
                    valueProp="condition_token"
                  >
                    <template v-slot:caret>
                      <span class="multiselect-caret">
                        <img :src="icon_caret" alt="" />
                      </span>
                    </template>
                  </Multiselect>
                </div>
                <div class="form-group">
                  <label>Order by</label>

                  <div class="container-select-order-by">
                    <Multiselect
                      v-model="closed_orders_filters.sort_by"
                      :hideSelected="true"
                      :options="ordering_options"
                      class="form-control selector order-by-select"
                      label="name"
                      placeholder="Select ordering"
                      valueProp="value"
                    >
                      <template v-slot:caret>
                        <span class="multiselect-caret">
                          <img :src="icon_caret" alt="" />
                        </span>
                      </template>
                    </Multiselect>
                    <Multiselect
                      v-model="closed_orders_filters.sort"
                      :hideSelected="true"
                      :options="sort_optios"
                      class="form-control selector type-select"
                      label="name"
                      placeholder="Type"
                      valueProp="value"
                    >
                      <template v-slot:caret>
                        <span class="multiselect-caret">
                          <img :src="icon_caret" alt="" />
                        </span>
                      </template>
                    </Multiselect>
                  </div>
                </div>
                <div class="form-group">
                  <label>Status</label>
                  <Multiselect
                    v-model="closed_orders_filters.status"
                    :hideSelected="true"
                    :options="status_options"
                    class="form-control selector"
                    label="name"
                    placeholder="Select status"
                    valueProp="value"
                  >
                    <template v-slot:caret>
                      <span class="multiselect-caret">
                        <img :src="icon_caret" alt="" />
                      </span>
                    </template>
                  </Multiselect>
                </div>
                <div class="form-group">
                  <label>Items per page</label>
                  <Multiselect
                    v-model="closed_orders_filters.limit"
                    :hideSelected="true"
                    :options="page_limits_options"
                    class="form-control selector"
                    label="name"
                    placeholder="Select items per page"
                    valueProp="value"
                  >
                    <template v-slot:caret>
                      <span class="multiselect-caret">
                        <img :src="icon_caret" alt="" />
                      </span>
                    </template>
                  </Multiselect>
                </div>
              </div>
              <div
                :class="{ 'overflow-table': closed_orders.length < 3 }"
                v-if="closed_orders.length"
                class="table-orders-closed-container table-responsive"
              >
                <TableComponent
                  :actions="false"
                  :data="closed_orders"
                  :headers="tableColumnHeaders"
                  class="ordrs-table"
                  title="Orders"
                >
                  <template v-slot:token="{ text }">
                    <span
                      v-b-tooltip.hover
                      :title="text"
                      class="copy"
                      @click="copyTokenTable(text)"
                      >{{ tokenTable(text) }}
                    </span></template
                  >
                  <template v-slot:status="{ text }">
                    <span
                      class="d-flex"
                      :class="{
                        red: text == 8,
                        gray: text == 4,
                        yellow: text == 1,
                        green: text == 2,
                      }"
                    >
                      {{ status_order(text) }}
                    </span></template
                  >
                  <template v-slot:side="{ text }">
                    <span
                      class="d-flex"
                      :class="[text == 'Buy' ? 'green' : 'red']"
                      ><span class="icon"
                        ><img
                          :src="text == 'Buy' ? arrow_up : arrow_down"
                          alt="" /></span
                      >{{ text }}
                    </span></template
                  >
                  <template v-slot:open_time="{ text }">
                    <span class="value">{{ text }} </span>
                  </template>
                  <template v-slot:open_price="{ text }">
                    <span class="d-flex value"
                      ><span class="icon"><img :src="dollar" alt="" /></span>
                      {{ formatter(text) }}
                    </span>
                  </template>
                  <template v-slot:position_size="{ text }">
                    <span class="d-flex value"
                      ><span class="icon"><img :src="dollar" alt="" /></span>
                      {{ formatter(text) }}
                    </span>
                  </template>
                  <template v-slot:close_price="{ text }">
                    <span v-if="text != ''" class="d-flex value"
                      ><span class="icon"><img :src="dollar" alt="" /></span>
                      {{ formatter(text) }}
                    </span>
                    <span v-else>No data</span>
                  </template>
                  <template v-slot:close_time="{ text }">
                    <span v-if="text != ''" class="value">{{ text }}</span
                    ><span v-else>No data</span>
                  </template>
                  <template v-slot:time_running="{ text }">
                    <span v-if="text != ''" class="value">{{ text }}</span
                    ><span v-else>No data</span>
                  </template>
                  <template v-slot:open_lot="{ text }">
                    <span class="d-flex value">
                      {{ text }}
                    </span>
                  </template>
                  <template v-slot:realized_pnl="{ text }">
                    <span
                      class="d-flex value"
                      :class="[
                        text[0] > 0 ? 'green' : text[0] < 0 ? 'red' : '',
                      ]"
                      ><span class="icon"
                        ><img
                          :src="
                            text[0] > 0
                              ? dollar_green
                              : text[0] == 0
                              ? dollar
                              : dollar_red
                          "
                          alt="" /></span
                      >{{ formatter(text[0]) }} <span class="dash">/</span>
                      {{ formatter(text[1]) }}
                      <span class="icon"
                        ><img
                          :src="
                            text[0] > 0
                              ? percentage_green
                              : text[0] == 0
                              ? percentage
                              : percentage_red
                          "
                          alt="" /></span></span
                  ></template>
                  <template v-slot:unrealized_pnl="{ text }">
                    <span
                      class="d-flex value"
                      :class="[
                        text[0] > 0 ? 'green' : text[0] < 0 ? 'red' : '',
                      ]"
                      ><span class="icon"
                        ><img
                          :src="
                            text[0] > 0
                              ? dollar_green
                              : text[0] == 0
                              ? dollar
                              : dollar_red
                          "
                          alt="" /></span
                      >{{ formatter(text[0]) }} <span class="dash">/</span>
                      {{ formatter(text[1]) }}
                      <span class="icon"
                        ><img
                          :src="
                            text[0] > 0
                              ? percentage_green
                              : text[0] == 0
                              ? percentage
                              : percentage_red
                          "
                          alt="" /></span></span
                  ></template>
                  <template v-slot:stop_loss="{ text }">
                    <span class="value">{{ formatter(text) }}</span>
                  </template>
                  <template v-slot:take_profit="{ text }">
                    <span class="value">{{ formatter(text) }}</span>
                  </template>
                  <template v-slot:signal_price="{ text }">
                    <span class="d-flex value">{{ text[0] - text[1] }} </span>
                  </template>
                  <template v-slot:actions="id">
                    <div v-if="is_logged" class="dropup">
                      <span class="action-button" data-toggle="dropdown">
                        <img :src="menu_icon" alt="" />
                      </span>
                      <ul class="dropdown-menu">
                        <li @click="copyToken(id)">
                          <img :src="copy_icon" />Copy token
                        </li>
                        <li
                          @click="immediateClose(id)"
                          v-if="id.id.status == 2"
                        >
                          <img style="width: 9px" :src="close_icon" />Immediate
                          close
                        </li>
                        <li @click="showViewDetails(id)">
                          <img :src="eye_icon" />View details
                        </li>
                        <li @click="showJsonDetails(id)">
                          <img :src="json_icon" />View JSON
                        </li>
                        <li @click="showLogDetails(id)">
                          <img :src="logs_icon" />View logs
                        </li>
                        <!-- <li><img :src="remove_icon" />Remove order</li>
                        <li><img :src="rebalance_icon" />Rebalancing</li> -->
                      </ul>
                    </div>
                  </template>

                  <template #pagination>
                    <Pagination
                      :pagination="paginate"
                      :go-first-page-callback="goFirstPage"
                      :go-last-page-callback="goLastPage"
                      :go-next-page-callback="goNextPage"
                      :go-prev-page-callback="goPrevPage"
                    />
                  </template>
                </TableComponent>
              </div>
              <div v-else class="alert-container"><AlertNoInformation /></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ViewDetailsModal
      v-if="showViewDetailsModal"
      :data="selectedOrderDetails"
      :type-of-order="'Closed'"
      @closed="showViewDetailsModal = false"
      @showClosedOrdersModal="show = true"
      @openOrdersModalProp="openOrdersModalProp"
    />
    <ViewLogsModal
      v-if="showViewLogsModal"
      @closed="showViewLogsModal = false"
    />
    <ViewJsonModal
      v-if="showViewJsonModal"
      @closed="showViewJsonModal = false"
    />
  </div>
</template>

<script>
import $ from "jquery";
import AlertNoInformation from "../../../commons/alerts/AlertNoInformation.vue";
import ArrowUp from "../../../../../public/common/arrow-up.png";
import ArrowDown from "../../../../../public/common/arrow-down.png";
import CloseIcon from "../../../../assets/images/close.svg";
import CopyIcon from "../../../../../public/common/copy_white.png";
import Dollar from "../../../../../public/common/dollar.png";
import DollarGreen from "../../../../../public/common/dollar-green.png";
import DollarRed from "../../../../../public/common/dollar-red.png";
import EyeIcon from "../../../../assets/images/eye.svg";
import IconCaret from "../../../../../public/common/select-arrow-down.svg";
import JsonIcon from "../../../../assets/images/brackets-curly.svg";
import LogsIcon from "../../../../assets/images/logs.svg";
import MenuOptions from "../../../../../public/common/menu-dots-vertical.png";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2";
import number_formatter from "../../../../helpers/formatters/number_formatter";
import Pagination from "../../../commons/Pagination.vue";
import Percentage from "../../../../../public/common/percentage.png";
import PercentageGreen from "../../../../../public/common/percentage-green.png";
import PercentageRed from "../../../../../public/common/percentage-red.png";
import RebalancingIcon from "../../../../../public/common/rebalancing.png";
import RemoveIcon from "../../../../../public/common/cross_white.png";
import Search from "../../../../../public/common/search.png";
import StatusOrderFormatter from "../../../../helpers/formatters/status_order_formatter";
import TableComponent from "../../../commons/table/Table.vue";
import ViewDetailsModal from "./ViewDetailsModal.vue";
import ViewLogsModal from "./ViewLogsModal";
import ViewJsonModal from "./ViewJsonModal";

import { mapState } from "vuex";
import { Modal } from "bootstrap";

export default {
  name: "CloseOrdersModal",
  props: {
    hideOptionsModal: {
      type: Function,
      default: () => {},
    },
    show: {
      type: Boolean,
    },
    showOptionsModal: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    AlertNoInformation,
    ArrowUp,
    ArrowDown,
    Dollar,
    DollarGreen,
    DollarRed,
    Multiselect,
    Pagination,
    Percentage,
    PercentageGreen,
    PercentageRed,
    TableComponent,
    ViewDetailsModal,
    ViewLogsModal,
    ViewJsonModal,
  },
  data() {
    return {
      arrow_up: ArrowUp,
      arrow_down: ArrowDown,
      close_icon: CloseIcon,
      copy_icon: CopyIcon,
      dollar: Dollar,
      dollar_green: DollarGreen,
      dollar_red: DollarRed,
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
      <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
      </svg> Error`,
      eye_icon: EyeIcon,
      icon_caret: IconCaret,
      is_logged: false,
      json_icon: JsonIcon,
      logs_icon: LogsIcon,
      menu_icon: MenuOptions,
      ordering_options: [
        { value: "close_date", name: "Close date" },
        { value: "open_date", name: "Open date" },
        { value: "order_type", name: "Order type" },
        { value: "order_status", name: "Order Status" },
      ],
      page_limits_options: [
        { value: "5", name: "Max per page: 5" },
        { value: "10", name: "Max per page: 10" },
        { value: "20", name: "Max per page: 20" },
      ],
      percentage: Percentage,
      percentage_green: PercentageGreen,
      percentage_red: PercentageRed,
      rebalance_icon: RebalancingIcon,
      remove_icon: RemoveIcon,
      search: Search,
      selectedOrder: null,
      showViewDetailsModal: false,
      showViewLogsModal: false,
      showViewJsonModal: false,
      sort_optios: [
        { value: "asc", name: "Asc" },
        { value: "desc", name: "Desc" },
      ],
      status_options: [
        { value: "4", name: "Closed" },
        { value: "2", name: "Open" },
        { value: "8", name: "Cancelled" },
        { value: "1", name: "Pending" },
      ],
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
          <g clip-path="url(#clip0_1634_940)">
          <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
          </g>
          <defs>
          <clipPath id="clip0_1634_940">
          <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
          </clipPath>
          </defs>
          </svg> Success`,
      timer: undefined,
    };
  },
  computed: {
    tableColumnHeaders() {
      const pnl =
        this.closed_orders_filters.status == 2
          ? {
              name: "unrealized_pnl",
              title: "Unrealized PnL",
              align: "left",
              secondary_name: "unrealized_pnl_percentage",
            }
          : {
              name: "realized_pnl",
              title: "Realized PnL",
              align: "left",
              secondary_name: "realized_pnl_percentage",
            };
      return [
        { name: "token", title: "Token", align: "left" },
        { name: "portfolio_name", title: "Strategy", align: "left" },
        { name: "symbol", title: "Symbol", align: "left" },
        { name: "side", title: "Side", align: "left" },
        { name: "status", title: "Status", align: "left" },
        { name: "open_time", title: "Open date", align: "left" },
        { name: "open_price", title: "Open price", align: "left" },
        { name: "position_size", title: "Lot open ($)", align: "left" },
        { name: "open_lot", title: "Lot open", align: "left" },
        { name: "close_price", title: "Close price", align: "left" },
        { name: "close_time", title: "Close date", align: "left" },
        { name: "time_running", title: "Time running", align: "left" },
        pnl,
        { name: "stop_loss", title: "Stop Loss", align: "left" },
        { name: "take_profit", title: "Take Profit", align: "left" },
        {
          name: "signal_price",
          title: "Slippage",
          align: "left",
          secondary_name: "open_price",
        },
        { title: "", action: true, id: false },
      ];
    },
    ...mapState("dashboard", [
      "algorithm_selector_list",
      "closed_orders_list",
      "closed_orders_filters",
      "investor_token",
    ]),
    algorithms() {
      return this.algorithm_selector_list && this.algorithm_selector_list._data
        ? this.algorithm_selector_list._data.data
        : [];
    },
    closed_orders() {
      return this.closed_orders_list && this.closed_orders_list._data
        ? this.closed_orders_list._data.data
        : [];
    },
    current_page() {
      return this.closed_orders_list._data.paginate
        ? this.closed_orders_list._data.paginate.current_page
        : null;
    },
    orders_loading() {
      return this.closed_orders_list._status
        ? this.closed_orders_list._status._on_loading
        : false;
    },
    paginate() {
      return this.closed_orders_list._data.paginate
        ? this.closed_orders_list._data.paginate
        : null;
    },
    selectedOrderDetails() {
      const selected_order = this.closed_orders_list._data.data.find(
        (order) => order.token == this.selectedOrder
      );

      return selected_order ? selected_order : {};
    },
  },
  created() {
    const session = localStorage.getItem("userData");
    if (session && session !== "") {
      this.is_logged = true;
    }
    this.$store.commit(
      "dashboard/set_investor_token",
      this.$store.state.dashboard.investor_token
    );

    this.$store.dispatch("dashboard/closed_orders_list", {});
  },
  mounted() {
    this.modal = new Modal(this.$refs.closeOrdersModal);
    const self = this;

    $("#closeOrdersModal").on("hide.bs.modal", function (event) {
      self.showOptionsModal();
      self.$emit("closed");
    });
  },
  methods: {
    copyToken(token) {
      navigator.clipboard.writeText(token.id.token);
      this.$toastr.success(
        "The token has been copied correctly.",
        `${this.success}`,
        { closeButton: true }
      );
    },
    copyTokenTable(token) {
      navigator.clipboard.writeText(token);
      this.$toastr.success(
        "The token has been copied correctly.",
        `${this.success}`,
        { closeButton: true }
      );
    },
    closeModal() {
      this.modal.hide();
    },
    formatter(number) {
      return number_formatter(number);
    },
    goFirstPage() {
      this.$store.dispatch("dashboard/closed_orders_list", {
        page: 1,
      });
    },
    goLastPage() {
      this.$store.dispatch("dashboard/closed_orders_list", {
        page: this.paginate.total_pages,
      });
    },
    goNextPage() {
      this.$store.dispatch("dashboard/closed_orders_list", {
        page: this.current_page + 1,
      });
    },
    goPrevPage() {
      this.$store.dispatch("dashboard/closed_orders_list", {
        page: this.current_page - 1,
      });
    },
    openOrdersModalProp() {
      this.$emit("openOrdersModalProp");
    },
    reloadService() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$store.dispatch("dashboard/closed_orders_list", {});
      }, 1000);
    },
    showModal() {
      this.modal.show();
    },
    showViewDetails(id) {
      this.showViewDetailsModal = true;
      this.selectedOrder = id.id.token;
      this.closeModal();
      this.hideOptionsModal();
    },
    showLogDetails(id) {
      this.selectedOrder = id.id.token;
      this.$store.dispatch("dashboard/open_orders_json", {
        token_condition: this.selectedOrderDetails.portfolio_token,
        token_order: this.selectedOrderDetails.token,
      });
      this.showViewLogsModal = true;
    },
    showJsonDetails(id) {
      this.selectedOrder = id.id.token;
      this.$store.dispatch("dashboard/open_orders_json", {
        token_condition: this.selectedOrderDetails.portfolio_token,
        token_order: this.selectedOrderDetails.token,
      });
      this.showViewJsonModal = true;
    },
    status_order(status) {
      return StatusOrderFormatter(status);
    },
    tokenTable(token) {
      return token ? token.substring(0, 30) + "..." : null;
    },
    formatPercentage(number) {
      const formatted = Math.round(parseFloat(number) * 100) / 100;
      return isNaN(formatted) ? number : formatted;
    },
    immediateClose(item) {
      Swal.fire({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#1c2030",
        confirmButtonText: "Confirm",
        preConfirm: async () => {
          const body = {
            portfolio_token: item.id.portfolio_token,
            symbol: item.id.symbol,
            loting: 0,
            mode: "EXPOSURE",
            timestamp: Date.now(),
            investor_token: this.investor_token,
          };
          await this.performImmediateClose(body);
        },
      }).then(async (result) => {
        if (result.value) {
        }
      });
    },
    async performImmediateClose(body) {
      return new Promise((resolve) => {
        this.$store.dispatch("dashboard/immediate_close", {
          callback: ({ response }) => {
            if (response.error) {
              this.$toastr.error(response.message, `${this.error}`);
              return;
            } else {
              this.$toastr.success(response.message, `${this.success}`);
              setTimeout(() => {
                this.$store.dispatch("dashboard/open_orders", {});
                this.$store.dispatch("dashboard/closed_orders_list", {});
              }, 2000);
            }
            resolve();
          },
          error: ({ response }) => {
            this.$toastr.error(response.message, `${this.error}`);
            return;
          },
          body,
        });
      });
    },
  },
  watch: {
    "closed_orders_filters.limit": function (value) {
      this.reloadService();
    },
    "closed_orders_filters.portfolio_token": function (value) {
      this.reloadService();
    },
    "closed_orders_filters.search": function (value) {
      this.reloadService();
    },
    "closed_orders_filters.status": function (value) {
      this.reloadService();
    },
    "closed_orders_filters.sort": function (value) {
      this.reloadService();
    },
    "closed_orders_filters.sort_by": function (value) {
      this.reloadService();
    },
    show(newValue) {
      if (newValue) {
        this.modal.show();
      } else {
        this.modal.hide();
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../../styles/pages/dashboard/close_orders_modal.scss";
// @import "../../../../styles/pages/dashboard/opened_orders_table.scss";
.table-orders-closed-container {
  &.overflow-table {
    overflow-x: visible !important;
    .table-responsive {
      overflow-x: visible !important;
    }
  }
}
</style>
