<template>
  <div class="header-bar-container">
    <div class="header-bar max-width">
      <div>
        <span class="breadcrumb-home" v-if="isLogged"
          ><router-link :to="{ name: 'Investors', params: {} }"
            >Investors</router-link
          >
          <span v-if="basicInformation.investor_name != null"> / </span>
        </span>
        <span class="breadcrumb-current">
          {{ basicInformation.investor_name }}
        </span>
      </div>
      <div class="filter-bars">
        <Multiselect
          v-model="selectedExchange"
          :can-clear="false"
          :clearOnSelect="true"
          :close-on-select="true"
          :hideSelected="true"
          :options="connection_options"
          label="name"
          placeholder="Select connection"
          valueProp="exchange"
          @select="exchangeSelected"
        >
          <template v-slot:caret>
            <span class="multiselect-caret">
              <img :src="icon_caret" alt="" />
            </span>
          </template>
        </Multiselect>

        <Multiselect
          v-model="selectedAlgorithm"
          :hideSelected="true"
          :options="algorithms_options"
          label="name"
          placeholder="Select your algorithm"
          valueProp="condition_token"
          @select="algorithmSelected"
        >
          <template v-slot:caret>
            <span class="multiselect-caret">
              <img :src="icon_caret" alt="" />
            </span>
          </template>
        </Multiselect>
      </div>
    </div>
  </div>
</template>

<script>
import IconCaret from "../../../../public/common/select-arrow-down.svg";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2";
import { mapState } from "vuex";

export default {
  name: "HeaderTopBar",
  data() {
    return {
      icon_caret: IconCaret,
      isLogged: false,
      selectedAlgorithm: "",
      selectedExchange: "All connections",
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    ...mapState("dashboard", [
      "algorithm_selector_list",
      "exchange_selector_list",
      "basic_information",
      "connection_list_selector",
      "selected_exchange_token",
    ]),
    algorithms() {
      return this.algorithm_selector_list && this.algorithm_selector_list._data
        ? this.algorithm_selector_list._data.data
        : null;
    },
    algorithms_options() {
      let all_algorithms = { name: "All algorithms", condition_token: "" };
      let options = this.selected_exchange_token
        ? this.algorithms.filter(
            (objeto) =>
              objeto.exchange_connection_token === this.selected_exchange_token
          )
        : Object.values(this.algorithms);
      options.unshift(all_algorithms);
      return options.length ? options : [];
    },
    connections() {
      return this.connection_list_selector &&
        this.connection_list_selector._data
        ? this.connection_list_selector._data.data
        : null;
    },
    exchanges() {
      return this.exchange_selector_list && this.exchange_selector_list._data
        ? this.exchange_selector_list._data.data
        : null;
    },
    connection_options() {
      let all_exchanges = {
        name: "All connections",
        token: "",
        exchange: "All connections",
      };
      let options = Object.values(this.connections);
      options.unshift(all_exchanges);
      return options.length ? options : [];
    },
    basicInformation() {
      return this.basic_information && this.basic_information._data
        ? this.basic_information._data.data
        : null;
    },
  },
  created() {
    const session = localStorage.getItem("userData");
    if (session && session !== "") {
      this.isLogged = true;
    }
  },
  methods: {
    algorithmSelected(option) {
      this.$store.commit("dashboard/set_global_selected_algorithm", option);
    },
    exchangeSelected(option) {
      const connection = this.connection_options.find(
        (objeto) => objeto.exchange === option
      );

      this.$store.commit(
        "dashboard/set_selected_exchange_token",
        connection.token
      );
      this.$store.commit("dashboard/set_global_selected_exchange", option);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/pages/dashboard/header_top_bar.scss";
</style>
