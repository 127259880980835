<template>
  <div
    class="alert-no-information"
    :class="{
      'icon-top': iconPosition == 'top',
      'icon-top-responsive': iconPositionResponsive == 'top',
      'is-dark': isDark === true,
    }"
  >
    <div class="message">
      <p class="alert-title">
        <span> <img :src="info_icon" alt="Info" /></span> No data available at
        the moment!
      </p>
      <p class="subtitle-1">
        It seems there is no information to display in this table yet.
      </p>
    </div>
  </div>
</template>
<script>
import InfoIcon from "../../../../public/common/info-icon.png";

export default {
  name: "InformationTable",
  props: {
    iconPosition: {
      default: "left",
      type: String,
    },
    iconPositionResponsive: {
      type: String,
    },
    isDark: {
      default: true,
      type: Boolean,
    },
    type: {
      default: "horizontal",
      type: String,
    },
  },
  data() {
    return {
      info_icon: InfoIcon,
    };
  },
};
</script>

<style lang="scss">
.alert-no-information {
  border-radius: 4px;
  display: flex;
  padding: 20px;
  background: #232429 !important;

  &.icon-top {
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .icon {
      margin-bottom: 7px;
      margin-right: 0 !important;
    }
  }

  &.is-dark {
    background: #1d1e25 !important;
  }
  &:not(.is-dark) {
    background: #232429;
  }

  .icon {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 14px;
  }

  .message {
    color: #f5f5f5;
    display: flex;
    flex-direction: column;
    gap: 5px;

    p {
      color: inherit;
      margin-bottom: 0;
    }

    .alert-title {
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 0 !important;
    }
    .subtitle-1 {
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #ffffff90;
    }
  }
}
</style>
