<template>
  <div class="col-md-6 p-1 text-right">
    <b-modal
      id="modal-create-subinvestors"
      hide-footer
      hide-header
      size="xl"
      ok-only
    >
      <div class="modal-create-update-user">
        <h3 class="modal-title" v-if="!isEdit">Create Sub investors</h3>
        <h3 class="modal-title" v-else>Edit Sub investors</h3>
        <div class="row px-2">
          <div class="col-md-6 px-2">
            <div class="row">
              <div class="col-md-12">
                <label class="text-muted"> Account info </label>
              </div>
            </div>
            <div class="row px-2">
              <div class="col-md-6 px-2">
                <div class="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    placeholder="Name"
                    class="form-control"
                    v-model="investorForm.name"
                    :disabled="disabled"
                    @input="$v.investorForm.name.$touch()"
                  />
                  <div v-if="$v.investorForm.name.$dirty">
                    <b-form-invalid-feedback
                      :state="$v.investorForm.name.required"
                    >
                      Required Field
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
              <div class="col-md-6 px-2">
                <div class="form-group">
                  <label>Token</label>
                  <input
                    type="text"
                    placeholder="Token"
                    class="form-control"
                    v-model="investorForm.token"
                    :disabled="disabled || true"
                    @input="$v.investorForm.token.$touch()"
                  />
                  <span class="icon" v-if="!isEdit && !isShow">
                    <b-icon-shuffle @click="generateToken"></b-icon-shuffle>
                  </span>
                  <div v-if="$v.investorForm.token.$dirty">
                    <b-form-invalid-feedback
                      :state="$v.investorForm.token.required"
                    >
                      Required Field
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <label class="text-muted"> Account type </label>
              </div>
            </div>

            <div class="row px-2 pt-2">
              <div class="col-md-4 px-2">
                <div class="form-group">
                  <label>Demo</label>
                  <b-form-checkbox
                    :value="true"
                    :unchecked-value="false"
                    :disabled="disabled"
                    v-model="investorForm.demo"
                  >
                    <span v-if="!investorForm.demo">
                      It will <u>not</u> be a demo account
                    </span>
                    <span v-if="investorForm.demo">
                      <u>Yes</u>, it will be a demo account
                    </span>
                  </b-form-checkbox>
                </div>
              </div>
              <div class="col-md-4 px-2">
                <div class="form-group">
                  <label>Futures</label>
                  <b-form-checkbox
                    :value="true"
                    :unchecked-value="false"
                    :disabled="disabled"
                    v-model="investorForm.future"
                  >
                    <span v-if="!investorForm.future">
                      It will <u>not</u> be a future account
                    </span>
                    <span v-if="investorForm.future">
                      <u>Yes</u>, it will be a future account
                    </span>
                  </b-form-checkbox>
                </div>
              </div>
            </div>

            <div class="row" v-if="!investorForm.demo">
              <div class="col-md-6">
                <div class="form-group">
                  <label>API Key</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="API Key"
                    v-model="investorForm.api_key"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Secret Key</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Secret Key"
                    v-model="investorForm.secret_key"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="content px-0 item-active-config">
                  <label>Order type</label>
                  <div class="row px-2" style="margin-top: 0px">
                    <div class="col-md-12 px-2">
                      <select
                        class="form-control"
                        v-model="investorForm.order_type"
                        :disabled="disabled"
                        @input="$v.investorForm.order_type.$touch()"
                      >
                        <option value="limit">Limit</option>
                        <option value="market">Market</option>
                      </select>
                      <div v-if="$v.investorForm.order_type.$dirty">
                        <b-form-invalid-feedback
                          :state="$v.investorForm.order_type.required"
                        >
                          Required Field
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="content px-0 item-active-config">
                  <label>Exchange</label>
                  <div class="row px-2" style="margin-top: 0px">
                    <div class="col-md-12 px-2">
                      <select
                        class="form-control"
                        v-model="investorForm.exchange"
                        :disabled="disabled"
                        @input="$v.investorForm.exchange.$touch()"
                      >
                        <option value="binance">Binance</option>
                      </select>
                      <div v-if="$v.investorForm.exchange.$dirty">
                        <b-form-invalid-feedback
                          :state="$v.investorForm.exchange.required"
                        >
                          Required Field
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="content px-0 item-active-config">
                  <label>Order open close tolerance</label>
                  <input
                    type="number"
                    pattern="[0-9]"
                    min="1"
                    max="100"
                    class="form-control"
                    v-model="investorForm.order_open_close_tolerance"
                    @input="$v.investorForm.order_open_close_tolerance.$touch()"
                  />
                  <div v-if="$v.investorForm.order_open_close_tolerance.$dirty">
                    <b-form-invalid-feedback
                      :state="
                        $v.investorForm.order_open_close_tolerance.required
                      "
                    >
                      Required Field
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>

              <div class="col-md-12 my-2">
                <div class="content px-0 item-active-config">
                  <b-button
                    variant="dark"
                    v-if="!loading"
                    class="small"
                    @click="auditKey"
                    >Audit key</b-button
                  >
                  <b-button
                    v-else
                    class="mr-2 form-group pr-5 small"
                    variant="dark"
                    :disabled="true"
                  >
                    Audit key
                    <div class="loading">
                      <b-spinner variant="light"></b-spinner>
                    </div>
                  </b-button>
                </div>
              </div>
            </div>

            <div class="row balances">
              <div class="col-md-12">
                <label for="" class="text-muted">Condition</label>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Lot</label>
                  <input
                    type="text"
                    placeholder="0.25"
                    class="form-control"
                    v-model="investorForm.lot"
                    :disabled="disabled"
                    @input="$v.investorForm.lot.$touch()"
                  />
                  <div v-if="$v.investorForm.lot.$dirty">
                    <b-form-invalid-feedback
                      :state="$v.investorForm.lot.required"
                    >
                      Required Field
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
              <div class="col-md-4" v-show="investorForm.future">
                <div class="form-group">
                  <label>Laverage</label>
                  <input
                    type="text"
                    placeholder="1"
                    class="form-control"
                    v-model="investorForm.laverage"
                    :disabled="disabled"
                    @input="$v.investorForm.laverage.$touch()"
                  />
                  <div v-if="$v.investorForm.laverage.$dirty">
                    <b-form-invalid-feedback
                      :state="$v.investorForm.laverage.required"
                    >
                      Required Field
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Investment maximum</label>
                  <input
                    type="text"
                    placeholder="1000"
                    class="form-control"
                    v-model="investorForm.investment_maximum"
                    :disabled="disabled"
                    @input="$v.investorForm.investment_maximum.$touch()"
                  />
                  <div v-if="$v.investorForm.investment_maximum.$dirty">
                    <b-form-invalid-feedback
                      :state="$v.investorForm.investment_maximum.required"
                    >
                      Required Field
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <label class="text-muted"> Comments </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group m-0">
                  <label>Additional note</label>
                  <textarea
                    placeholder="Additional note"
                    class="form-control"
                    :disabled="disabled"
                    v-model="investorForm.note"
                  >
                  </textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 px-2">
            <div class="row balances">
              <div class="col-md-12">
                <TableBalancesInvestors
                  :showUpdateBalance="investorForm.demo"
                  :investorForm="investorForm"
                  :balances="investorForm.balances"
                  :loading="loading"
                  :isShow="false"
                  :showOptions="
                    $route.name.toLowerCase() != 'subinvestor' &&
                    investorForm.demo
                  "
                  :isSubInvestor="true"
                  @handleDeleteBalance="deleteBalance"
                  @handleAddBalance="handleAddBalance"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12" v-if="!isShow && !isEdit">
          <b-button variant="success" v-if="!loading" @click="handleSave"
            >Create new sub investor</b-button
          >
          <b-button
            v-else
            class="mr-2 form-group pr-5"
            variant="success"
            :disabled="true"
          >
            Create new sub investor
            <div class="loading">
              <b-spinner variant="light"></b-spinner>
            </div>
          </b-button>
        </div>
        <div class="col-md-12" v-if="isEdit">
          <b-button variant="success" v-if="!loading" @click="handleEditSave"
            >Edit sub investor</b-button
          >
          <b-button
            v-else
            class="mr-2 form-group pr-5"
            variant="success"
            :disabled="true"
          >
            Edit sub investor
            <div class="loading">
              <b-spinner variant="light"></b-spinner>
            </div>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Helpers from "@/helpers/commons.js";
import { required, minLength } from "vuelidate/lib/validators";
import { EventBus } from "@/helpers/event-bus.js";
import TableBalancesInvestors from "@/components/pages/investors/TableBalancesInvestors.vue";

export default {
  name: "CreateAndEditSubInvestors",
  props: {
    investor_token: {
      required: true,
      type: String,
    },
    investor: {
      required: true,
      type: Object,
      default: () => {},
    },
    investor_balances: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  components: {
    TableBalancesInvestors,
  },
  validations: {
    investorForm: {
      name: { required },
      status: { required },
      token: { required },
      configurations: { required },
      order_open_close_tolerance: { required },
      order_type: { required },
      lot: { required },
      investment_maximum: { required },
      laverage: { required },
      exchange: { required },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  data() {
    return {
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
        <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
        </svg> Error`,
      investorForm: {
        name: null,
        token: null,
        balances: [],
        configurations: [],
        note: null,
        api_key: "",
        secret_key: "",
        demo: true,
        future: false,
        order_type: "market",
        exchange: "binance",
        order_open_close_tolerance: 5,
        lot: 100,
        investment_maximum: 1000,
        laverage: 1,
      },
      show: false,
      showUpdateBalance: false,
      disabled: false,
      isShow: false,
      isEdit: false,
      isCompound: false,
      is_compound: "",
      currentTypeCondition: "buy",
      activeSell: false,
      activeBuy: true,
      priority: "buy",
      maximunCondition: 50,
      colorID: {},
      indexConditionModify: 0,
      loading: false,
      isAddBalance: false,
      all_balances: [],
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
          <g clip-path="url(#clip0_1634_940)">
          <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
          </g>
          <defs>
          <clipPath id="clip0_1634_940">
          <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
          </clipPath>
          </defs>
          </svg> Success`,
    };
  },
  created() {
    /*this.$http.server1('bwfs/admin/balance/get', payload).then((res) => {
      if (res.error) {
        Swal.showValidationMessage(`${res.message}a`);
      }
      resolve({
        balance: res.data,
        asset: selectSymbol,
      });
    }).catch((e) => {
      Swal.showValidationMessage(`${e.message}b`);
    });*/
  },
  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId == "modal-create-subinvestors" && !this.isAddBalance) {
        setTimeout(() => {
          this.resetForm();
        }, 500);
      }
    });
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (modalId == "modal-create-subinvestors" && !this.isAddBalance) {
        // this.getBalancesAvailable();
      }
    });
  },
  methods: {
    generateToken() {
      this.investorForm.token = Helpers.MD5(Helpers.generateRandomString(10));
    },
    setDataFormAndDisable(data) {
      this.investorForm.name = data.name;
      this.investorForm.note = data.note;
      this.investorForm.token = data.token_user;
      this.investorForm.lot = data.lot * 100;
      this.investorForm.laverage = data.laverage;
      this.investorForm.investment_maximum = data.investment_maximum;
      this.investorForm.balances = data.balances;

      // this.investorForm.configurations = data.conditions;
      this.disabled = true;
      this.isShow = true;
      this.isEdit = false;
    },
    setEditInvestor(data) {
      let balances = [];
      if (data.balances) {
        for (let i = 0; i < data.balances.length; i++) {
          balances.push({
            asset: data.balances[i].asset.toUpperCase(),
            balance: data.balances[i].balance,
          });
        }
      }

      this.investorForm.name = data.subinvestor.name;
      this.investorForm.token = data.token;
      this.investorForm.note = data.subinvestor.note;
      this.investorForm.api_key = data.subinvestor.api_key;
      this.investorForm.secret_key = data.subinvestor.secret_key;
      this.investorForm.demo = data.subinvestor.is_real ? false : true;
      if (data.conditions.length > 0) {
        let condition = JSON.parse(data.conditions[0].condition);
        this.investorForm.lot = condition.lot * 100;
        this.investorForm.laverage = condition.laverage;
        this.investorForm.investment_maximum = condition.investment_maximum;
      } else {
        this.investorForm.lot = 100;
        this.investorForm.laverage = 1;
        this.investorForm.investment_maximum = 1000;
      }
      // let configuration = JSON.parse(data.configurations);
      // this.investorForm.future = configuration.market_type == 'futures';
      // this.investorForm.order_type = configuration.order_type;
      // this.investorForm.exchange = configuration.exchange;
      this.investorForm.order_open_close_tolerance =
        this.investorForm.order_type == "limit"
          ? Number(configuration.order_open_close_tolerance) * 100
          : 0;

      this.investorForm.balances = [];
      this.investorForm.balances = [...balances];
      this.disabled = false;
      this.isShow = false;
      this.isEdit = true;
    },
    setAutoCompletenvestor(data) {
      let balances = [];
      for (let i = 0; i < data.balances.length; i++) {
        balances.push({
          asset: data.balances[i].asset.toUpperCase(),
          balance: data.balances[i].balance,
        });
      }
      this.investorForm.name = data.name;
      this.investorForm.note = data.investor.note;
      this.investorForm.api_key = data.investor.api_key
        ? data.investor.api_key
        : "";
      this.investorForm.secret_key = data.investor.secret_key
        ? data.investor.secret_key
        : "";
      this.investorForm.demo = data.investor.is_real ? false : true;
      let configuration = JSON.parse(data.investor.configurations);
      this.investorForm.future = configuration.market_type == "futures";
      this.investorForm.order_type = configuration.order_type;
      this.investorForm.exchange = configuration.exchange;
      this.investorForm.order_open_close_tolerance =
        this.investorForm.order_type == "limit"
          ? Number(configuration.order_open_close_tolerance) * 100
          : 0;

      this.investorForm.balances = [];
      this.investorForm.balances = [...balances];
      this.disabled = false;
      this.isShow = false;
      this.isEdit = false;
    },
    handleAddBalance() {
      if (!this.investorForm.demo) {
        if (!this.investorForm.secret_key || !this.investorForm.api_key) {
          this.$toastr.error(
            "Please add api key and secret key.",
            `${this.error}`
          );
          return;
        }
      }
      const input =
        '<input id="swal-input111" class="swal2-input" placeholder="Amount" type="number" style="display: flex; height:45px;max-width: 11.5em;">';
      const inputCustom =
        '<input id="swal-input1222" class="swal2-input" placeholder="Custom Symbol" type="text" style="display: none; height:45px;max-width: 11.5em;">';
      let select =
        '<select style="border: 1px solid #d9d9d9;border-radius: .1875em;font-size: 1.125em;height: 45px; display: flex;max-width: 11.5em;" class="swal2-select" id="swal-input266" placeholder="Custom Symbol">';
      const userDataBk = { ...this.investorForm };
      const balancesInvestors = this.$props.investor_balances;

      select += '<option value="">Select Symbol</option>';
      if (userDataBk.balances.filter((i) => i.asset === "BTC").length === 0) {
        select += '<option value="BTC">BTC</option>';
      }
      if (userDataBk.balances.filter((i) => i.asset === "USDT").length === 0) {
        select += '<option value="USDT">USDT</option>';
      }
      if (userDataBk.balances.filter((i) => i.asset === "BNB").length === 0) {
        select += '<option value="BNB">BNB</option>';
      }
      if (userDataBk.balances.filter((i) => i.asset === "ETH").length === 0) {
        select += '<option value="ETH">ETH</option>';
      }
      select += '<option value="custom">Custom</option>';
      select += "</select>";

      const isEditBk = this.isEdit;
      this.isAddBalance = true;
      this.$bvModal.hide("modal-create-subinvestors");

      let htmlForm = `${select}${input}${inputCustom}`;
      if (!this.investorForm.demo) {
        htmlForm = `${select}`;
      }

      Swal.fire({
        title: "Add balance",
        html: htmlForm,
        showCancelButton: true,
        background: "#0e1019",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#1c2030",
        confirmButtonText: "Save",
        reverseButtons: true,
        preConfirm: () => {
          let selectSymbol = document.getElementById("swal-input266").value;
          if (this.investorForm.demo) {
            let isCustom = false;
            if (!selectSymbol) {
              Swal.showValidationMessage("Please select a symbol!");
            }
            if (selectSymbol.toLowerCase() === "custom") {
              isCustom = true;
              selectSymbol = document.getElementById("swal-input1222").value;
            }
            if (!Number(document.getElementById("swal-input111").value)) {
              Swal.showValidationMessage(
                `You must enter the value of ${selectSymbol}!`
              );
            }
            let availableBalance = this.all_balances.filter(
              (b) => b.asset.toLowerCase() === selectSymbol.toLowerCase()
            );
            let selectedBalance = this.investor_balances.filter(
              (b) => b.asset.toLowerCase() === selectSymbol.toLowerCase()
            );
            let currentBalances = userDataBk.balances.filter(
              (b) => b.asset.toLowerCase() === selectSymbol.toLowerCase()
            );
            if (currentBalances.length > 0) {
              Swal.showValidationMessage(
                `This symbol has already been selected.`
              );
            }
            return {
              balance: document.getElementById("swal-input111").value,
              asset: selectSymbol,
            };
          }
          let payload = {
            api_key: this.investorForm.api_key,
            secret_key: this.investorForm.secret_key,
            symbol: selectSymbol,
            market: !this.investorForm.future ? 1 : 0,
          };
          return new Promise((resolve) => {
            this.$http
              .server1("bwfs/admin/balance/get", payload)
              .then((res) => {
                if (res.error) {
                  Swal.showValidationMessage(`${res.message}a`);
                }
                resolve({
                  balance: res.data,
                  asset: selectSymbol,
                });
              })
              .catch((e) => {
                Swal.showValidationMessage(`${e.message}b`);
              });
          });
        },
      })
        .then((result) => {
          if (result.value) {
            userDataBk.balances.push(result.value);
          }
          this.userData = userDataBk;
          this.isEdit = isEditBk;
          this.$bvModal.show("modal-create-subinvestors");
          this.isAddBalance = false;
        })
        .catch(() => {});
      document
        .querySelector("#swal-input266")
        .addEventListener("change", function (event) {
          event.preventDefault();
          /* Act on the event */
          if (event.target.value.toLowerCase() === "custom") {
            document.querySelector("#swal-input1222").style.display = "flex";
          } else {
            document.querySelector("#swal-input1222").style.display = "none";
          }
        });
    },
    deleteBalance(selectBalance) {
      let indexSelected;
      this.investorForm.balances.map((item, index) => {
        if (item.asset === selectBalance.asset) {
          indexSelected = index;
        }
        return item;
      });
      const { balances } = this.investorForm;
      delete balances.splice(indexSelected, 1);
      this.investorForm.balances = balances;
    },
    handleSave() {
      if (!this.handleValidInvestor()) {
        return;
      }
      const data = { ...this.investorForm };
      data.demo = data.demo ? 1 : 0;
      data.parent_token_user = this.$props.investor_token;
      data.note = data.note;
      data.balances = JSON.stringify(data.balances);
      data.configurations = JSON.stringify({
        market_type: this.investorForm.future ? "futures" : "spot",
        order_type: this.investorForm.order_type,
        order_open_close_tolerance:
          this.investorForm.order_type == "limit"
            ? Number(this.investorForm.order_open_close_tolerance) / 100
            : 0,
        exchange: this.investorForm.exchange,
      });

      data.condition = JSON.stringify({
        lot: this.investorForm.lot / 100,
        laverage: parseFloat(this.investorForm.laverage),
        investment_maximum: parseFloat(this.investorForm.investment_maximum),
      });
      this.loading = true;
      this.$http
        .server1("bwfs/admin/subinvestors/create", data)
        .then((res) => {
          this.loading = false;
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.$bvModal.hide("modal-create-subinvestors");
          this.resetForm();
          this.$emit("handleReload");
        })
        .catch((e) => {
          this.loading = false;
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    handleValidInvestor() {
      if (
        this.$v.investorForm.name.$invalid ||
        this.$v.investorForm.token.$invalid
      ) {
        this.$toastr.error("Please complete the fields.", `${this.error}`);
        this.$v.$touch();
        return false;
      }
      if (this.investorForm.balances.length === 0) {
        this.$toastr.error("Please add balance.", `${this.error}`);
        return false;
      }
      if (
        this.investorForm.order_type == "limit" &&
        (Number(this.investorForm.order_open_close_tolerance) <= 0 ||
          Number(this.investorForm.order_open_close_tolerance) > 100)
      ) {
        this.$toastr.error(
          "Please valid order open close tolerance.",
          `${this.error}`
        );
        return false;
      }
      if (
        !this.investorForm.demo &&
        !this.isEdit &&
        (!this.investorForm.api_key || !this.investorForm.secret_key)
      ) {
        this.$toastr.error(
          "Please add api key and secret key",
          `${this.error}`
        );
        return false;
      }
      return true;
    },
    resetForm() {
      this.investorForm = {
        name: null,
        token: null,
        balances: [],
        configurations: [],
        note: "",
        api_key: "",
        secret_key: "",
        demo: true,
        future: false,
        order_type: "market",
        exchange: "binance",
        order_open_close_tolerance: 5,
        lot: 100,
        investment_maximum: 1000,
        laverage: 1,
      };
      this.show = false;
      this.disabled = false;
      this.isEdit = false;
      this.isShow = false;
      this.showUpdateBalance = false;
      this.isCompound = false;
      this.is_compound = "";
      this.currentTypeCondition = "buy";
      this.activeSell = false;
      this.activeBuy = true;
      this.priority = "buy";
      this.maximunCondition = 50;
      this.colorID = {};
      this.indexConditionModify = 0;
      this.loading = false;
      this.$v.$reset();
    },
    handleUpdateBalance() {
      const data = {
        api_key: this.investorForm.api_key,
        secret_key: this.investorForm.secret_key,
        spot: this.investorForm.future ? 0 : 1,
      };
      if (!this.investorForm.api_key || !this.investorForm.secret_key) {
        this.$toastr.error(
          "Please add api key and secret key",
          `${this.error}`
        );
        return false;
      }
      this.loading = true;
      this.$http
        .server1("bwfs/admin/balance/all/get", data)
        .then((res) => {
          this.loading = false;
          if (res.error) {
            this.$toastr.error(`${res.message}`);
          }
          let exits = false;
          this.investorForm.balances = [];
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i][1] == 0) {
              continue;
            }
            exits = true;
            this.investorForm.balances.push({
              balance: res.data[i][1],
              asset: res.data[i][0],
            });
          }
          if (!exits) {
            this.$toastr.error("No balances available", `${this.error}`);
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toastr.error(`${e.message}`);
        });
    },
    handleEditSave() {
      if (!this.handleValidInvestor()) {
        return;
      }
      const data = { ...this.investorForm };
      data.parent_token_user = this.$props.investor_token;

      data.demo = data.demo ? 1 : 0;
      data.note = data.note;
      data.balances = JSON.stringify(data.balances);
      data.configurations = JSON.stringify({
        market_type: this.investorForm.future ? "futures" : "spot",
        order_type: this.investorForm.order_type,
        order_open_close_tolerance:
          this.investorForm.order_type == "limit"
            ? Number(this.investorForm.order_open_close_tolerance) / 100
            : 0,
        exchange: this.investorForm.exchange,
      });
      data.condition = JSON.stringify({
        lot: this.investorForm.lot / 100,
        laverage: parseFloat(this.investorForm.laverage),
        investment_maximum: parseFloat(this.investorForm.investment_maximum),
      });
      this.loading = true;
      this.$http
        .server1("bwfs/admin/subinvestors/update", data)
        .then((res) => {
          this.loading = false;
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.$bvModal.hide("modal-create-subinvestors");
          this.resetForm();
          this.$emit("handleReload");
        })
        .catch((e) => {
          this.loading = false;
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    handleCreateContributor() {
      this.$bvModal.hide("modal-create-user");
      this.$bvModal.show("modal-create-subinvestors");
    },
    auditKey() {
      if (!this.investorForm.api_key || !this.investorForm.secret_key) {
        this.$toastr.error(
          "Please add api key and secret key",
          `${this.error}`
        );
        return false;
      }
      this.loading = true;
      const data = {
        api_key: this.investorForm.api_key,
        secret_key: this.investorForm.secret_key,
        spot: this.investorForm.future ? 0 : 1,
      };
      if (!this.investorForm.api_key || !this.investorForm.secret_key) {
        this.$toastr.error(
          "Please add api key and secret key",
          `${this.error}`
        );
        return false;
      }
      this.$http
        .server1("bwfs/admin/balance/all/get", data)
        .then((res) => {
          this.loading = false;
          if (res.error) {
            this.$toastr.error(
              "No valid api key and secret key.",
              `${this.error}`
            );
            return;
          }
          this.showUpdateBalance = true;
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i][1] > 0) {
              this.investorForm.balances = [];
              this.investorForm.balances.push({
                balance: res.data[i][1],
                asset: res.data[i][0],
              });
            }
          }
          this.$toastr.success(
            "Success valid api key and secret key",
            `${this.success}`
          );
        })
        .catch((e) => {
          this.loading = false;
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    getBalancesAvailable() {
      const data = {
        investor_token: this.$props.investor_token,
      };
      this.$http
        .server1("bwfs/admin/investors/all/balances/available", data)
        .then((res) => {
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.all_balances = res.data;
        })
        .catch((e) => {
          this.loading = false;
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
  },
  watch: {
    "investorForm.demo"(value) {
      if (!this.isEdit) {
        this.investorForm.balances = [];
      }
    },
    "investorForm.lot"(value) {
      if (Number(value) > 100 || Number(value) < 0) {
        this.investorForm.lot = 100;
      }
    },
  },
};
</script>

<style>
#modal-shows-condition .edit-conditions span {
  font-size: 18px;
  display: flex;
  width: 20px;
  text-align: center;
  margin-bottom: 10px;
}

#modal-shows-condition .conditions {
  font-family: "Roboto Mono", monospace;
  font-size: 11px;
  line-height: 1.1;
}
</style>
