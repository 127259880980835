import state from "@/helpers/store/state";
import set from "@/helpers/store/set";

import create_transaction from "./actions/create_transaction";
import delete_transaction from "./actions/delete_transaction";
import transaction_list from "./actions/transaction_list";
import transaction_list_preview from "./actions/transaction_list_preview";
import update_transaction from "./actions/update_transaction";

import set_creation_form from "./mutations/set_creation_form";
import set_investor_token from "./mutations/set_investor_token";
import set_transaction_type from "./mutations/set_transaction_type";

import common_list_adapter from "../../services/adapters/common/adapter_list";
import form_transaction_adapter from "../../services/adapters/form_transaction";
import message_response_adapter from "../../services/adapters/common/adapter_message";
import transaction_filters_adapter from "../../services/adapters/transaction_filters";

export default {
  namespaced: true,
  state: () => ({
    create_transaction: state(message_response_adapter),
    delete_transaction: state(message_response_adapter),
    form_transaction: { ...form_transaction_adapter },
    investor_token: null,
    transaction_list: state(common_list_adapter),
    transaction_list_preview: state(common_list_adapter),
    transaction_filters: { ...transaction_filters_adapter },
    update_transaction: state(message_response_adapter),
  }),
  actions: {
    create_transaction,
    delete_transaction,
    transaction_list,
    transaction_list_preview,
    update_transaction,
  },
  mutations: {
    set,
    set_creation_form,
    set_investor_token,
    set_transaction_type,
  },
};
