import * as request from "../helpers/request-new";
import adapter from "./adapters/common/adapter_data";
import response_mock from "./mockdata/responses/common/responses_message_success";
import { create_connection as microservice } from "../helpers/microservices";

export default ({ body, params, callback, error }) => {
  request.execute({
    microservice: microservice(),
    method: "POST",
    body,
    params,
    callback,
    error,
    adapter: adapter,
    mockdata: response_mock(),
    debug: false,
  });
};
