import openOrdersJsonService from "../../../services/immediate_close.service";
export default ({ state, commit }, { callback, error, body }) => {
  commit("set", {
    _state: "immediate_close",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });

  openOrdersJsonService({
    body,
    callback: ({ response }) => {
      commit("set", {
        _state: "immediate_close",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "immediate_close",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
