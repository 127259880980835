<template>
  <div class="filters-module max-width">
    <div class="filters">
      <div class="filter-row">
        <div class="form-group w-input-30">
          <label>Search</label>
          <div class="input-group">
            <input
              v-on:input="debounceSearch"
              v-model="investors_filters.search"
              class="form-control selector"
              placeholder="Search by symbol or token"
              type="text"
            />
            <span
              class="input-group-text no-border right dark"
              id="search-form-icon"
            >
              <img :src="search_icon" alt="" />
            </span>
          </div>
        </div>
        <div class="form-group w-input-20">
          <label>Investor type</label>
          <Multiselect
            v-model="investors_filters.type"
            :hideSelected="true"
            :options="investor_type_options"
            class="form-control selector"
            label="name"
            placeholder="Select a type"
            valueProp="value"
          >
            <template v-slot:caret>
              <span class="multiselect-caret">
                <img :src="icon_caret" alt="" />
              </span>
            </template>
          </Multiselect>
        </div>
        <div class="form-group w-input-20 input-order-by">
          <label>Order by</label>
          <div class="container-select-order-by">
            <Multiselect
              v-model="investors_filters.orderBy"
              :hideSelected="true"
              :options="ordering_options"
              class="form-control selector order-by-select"
              label="name"
              placeholder="Select ordering"
              valueProp="value"
            >
              <template v-slot:caret>
                <span class="multiselect-caret">
                  <img :src="icon_caret" alt="" />
                </span>
              </template>
            </Multiselect>
            <Multiselect
              v-model="investors_filters.sort"
              :hideSelected="true"
              :options="sort_options"
              class="form-control selector type-select"
              label="name"
              placeholder="Type"
              valueProp="value"
            >
              <template v-slot:caret>
                <span class="multiselect-caret">
                  <img :src="icon_caret" alt="" />
                </span>
              </template>
            </Multiselect>
          </div>
        </div>
        <div class="form-group w-input-20">
          <label>Labeling</label>
          <Multiselect
            v-model="investors_filters.label"
            :hideSelected="true"
            :options="labeling_options"
            class="form-control selector"
            label="name"
            placeholder="Select labeling..."
            valueProp="value"
          >
            <template v-slot:caret>
              <span class="multiselect-caret">
                <img :src="icon_caret" alt="" />
              </span>
            </template>
          </Multiselect>
        </div>
        <div class="w-input-10">
          <div class="input-type-investor">
            <div class="form-group">
              <label>Investors archived</label>
              <div class="container-type">
                <div
                  :class="{ container_bg: investors_filters.is_archived == 1 }"
                  class="container_no_bg"
                  @click="setInvestorArchived(1)"
                >
                  Yes
                </div>
                <div
                  :class="{ container_bg: investors_filters.is_archived == 0 }"
                  class="container_no_bg"
                  @click="setInvestorArchived(0)"
                >
                  No
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="filter-row second-row">
        <div class="form-group w-input-12">
          <label>Investors running</label>
          <Multiselect
            v-model="investors_filters.status"
            :hideSelected="true"
            :options="investor_status_options"
            class="form-control selector"
            label="name"
            placeholder="Select an option"
            valueProp="value"
          >
            <template v-slot:caret>
              <span class="multiselect-caret">
                <img :src="icon_caret" alt="" />
              </span>
            </template>
          </Multiselect>
        </div>
        <div class="form-group w-input-12">
          <label>Items per page</label>
          <Multiselect
            v-model="investors_filters.limit"
            :hideSelected="true"
            :options="item_per_page_options"
            class="form-control selector"
            label="name"
            placeholder="Max per page: 10"
            valueProp="value"
          >
            <template v-slot:caret>
              <span class="multiselect-caret">
                <img :src="icon_caret" alt="" />
              </span>
            </template>
          </Multiselect>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconCaret from "../../../../public/common/select-arrow-down.svg";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2";
import SearchIcon from "../../../../public/common/search.png";
import { mapState } from "vuex";
import { debounce } from "debounce";

export default {
  name: "Filters",
  data() {
    return {
      icon_caret: IconCaret,
      investor_archived: 1,
      investor_running: 1,
      investor_status_options: [
        { value: "2", name: "Yes" },
        { value: "8", name: "No" },
      ],
      investor_type_options: [
        { value: null, name: "All modes" },
        { value: "0", name: "Demo" },
        { value: "1", name: "Real" },
      ],
      item_per_page_options: [
        { value: "5", name: "Max per page: 5" },
        { value: "10", name: "Max per page: 10" },
        { value: "20", name: "Max per page: 20" },
      ],
      ordering_options: [
        { value: "created_date", name: "Last created" },
        { value: "last_open_date", name: "Last operation" },
        { value: "open_order_count", name: "Open orders" },
      ],
      search_icon: SearchIcon,
      sort_options: [
        { value: "asc", name: "Asc" },
        { value: "desc", name: "Desc" },
      ],
    };
  },
  props: {
    reload: {
      type: Boolean,
    },
  },
  components: {
    Multiselect,
  },
  computed: {
    ...mapState("investors_v2", ["investors_filters", "labeling_list"]),
    labeling_options() {
      return this.labeling_list && this.labeling_list._data
        ? this.labeling_list._data.data
        : [];
    },
  },
  created() {
    this.$store.dispatch("investors_v2/labeling_list");
    this.investors_filters.search = localStorage.getItem(
      "investor_list_search"
    );
  },
  methods: {
    setInvestorArchived(value) {
      this.$store.commit("investors_v2/set_investor_archived", value);
    },
    setInvestorRunning(value) {
      this.$store.commit("investors_v2/set_investor_running", value);
    },
    reloadService() {
      this.$store.dispatch("investors_v2/investors_list", {});
    },
    storeSearchValue(value) {
      localStorage.setItem("investor_list_search", value);
    },
    debounceSearch: debounce(function (e) {
      this.reloadService();
      this.storeSearchValue(this.investors_filters.search);
    }, 500),
  },
  watch: {
    "investors_filters.is_archived": function (value) {
      this.reloadService();
    },
    "investors_filters.label": function (value) {
      this.reloadService();
    },
    "investors_filters.limit": function (value) {
      this.reloadService();
    },
    "investors_filters.orderBy": function (value) {
      this.reloadService();
    },
    "investors_filters.sort": function (value) {
      this.reloadService();
    },
    "investors_filters.status": function (value) {
      this.reloadService();
    },
    "investors_filters.type": function (value) {
      this.reloadService();
    },
    reload(value) {
      if (value) {
        this.reloadService();
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/pages/investors_list/filters.scss";
</style>
