<template>
  <div class="row">
    <div class="col-md-6">
      <label class="text-muted">{{ title }}</label>
    </div>
    <div
      class="col-md-6 text-right d-flex align-items-center justify-content-end"
      v-if="showIsAdd && showOptions"
    >
      Add new balance
      <span style="font-size: 22px" @click.prevent="$emit('handleAddBalance')">
        <b-icon-plus></b-icon-plus>
      </span>
    </div>
    <div
      class="col-md-6 text-right d-flex align-items-center justify-content-end"
      v-if="showIsUpdate && showOptions"
    >
      Update balance
      <span
        style="font-size: 22px; margin-left: 2px"
        @click.prevent="$emit('handleUpdateBalance')"
      >
        <b-icon-arrow-repeat></b-icon-arrow-repeat>
      </span>
    </div>
    <div class="col-md-12 balances-table">
      <b-table
        :items="balances"
        :fields="fields_balances_table"
        :currentPage="1"
        :per-page="100"
        :busy="loading"
        responsive
        show-empty
        empty-text="Please add balance."
      >
        <template #cell(currency)="data">
          {{ data.item.asset.toUpperCase() }}
        </template>
        <template #cell(balance)="data">
          {{
            data.item.balance.toString().indexOf("e") > -1
              ? data.item.balance.toFixed(8)
              : $helpers.numberFormat(data.item.balance)
          }}<br />
        </template>
        <template #cell(locked)="data">
          {{
            data.item.locked
              ? data.item.locked.toString().indexOf("e") > -1
                ? data.item.locked.toFixed(8)
                : $helpers.numberFormat(data.item.locked)
              : 0
          }}<br />
        </template>
        <template #cell(options)="data">
          <span class="d-block" v-if="!isShow">
            <span
              style="font-size: 15px"
              class="mr-2"
              @click="deleteBalance(data.item)"
              v-if="$store.state.user && $store.state.user.role_id == 1"
            >
              <b-icon-trash></b-icon-trash>
            </span>
            <span
              style="font-size: 15px"
              class="mr-2"
              @click="setBaseSymbol(data.item)"
              v-if="parseInt(data.item.is_base) === 0"
            >
              <b-icon-check-circle
                style="fill: gray !important"
              ></b-icon-check-circle>
            </span>
            <span
              style="font-size: 15px"
              class="mr-2"
              @click="setBaseSymbol(data.item)"
              v-if="parseInt(data.item.is_base) === 1"
              v-b-tooltip.hover
              title="Is base symbol"
            >
              <b-icon-check-circle
                style="fill: #0beb09 !important"
              ></b-icon-check-circle>
            </span>
          </span>
        </template>
        <template #table-busy>
          <b-skeleton-table
            :rows="3"
            :columns="3"
            :table-props="{ striped: true }"
          >
          </b-skeleton-table>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "TableBalancesInvestors",
  props: {
    balances: {
      type: Array,
      required: true,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    isShow: {
      type: Boolean,
      required: true,
      default: false,
    },
    showOptions: {
      type: Boolean,
      required: false,
      default: true,
    },
    showUpdateBalance: {
      type: Boolean,
      required: true,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "Balances",
    },
    investorForm: {
      type: Object,
      required: true,
      default: () => {
        return {
          name: null,
          token: null,
          balances: [],
          configurations: [],
          note: "",
          api_key: "",
          secret_key: "",
          demo: true,
          future: false,
          manual_order: false,
          signal_endpoint: false,
          send_notification: false,
          order_type: "market",
          exchange: "binance",
          order_open_close_tolerance: 5,
        };
      },
    },
    isSubInvestor: {
      type: Boolean,
      required: false,
      default: false,
    },
    isContributor: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    showIsUpdate() {
      return (
        !this.$props.isShow &&
        this.$props.investorForm.api_key !== "" &&
        this.$props.investorForm.secret_key !== "" &&
        !this.$props.investorForm.is_demo &&
        this.$props.showUpdateBalance
      );
    },
    showIsAdd() {
      return (
        (!this.$props.isShow &&
          this.$props.investorForm.api_key == "" &&
          this.$props.investorForm.secret_key == "" &&
          this.$props.investorForm.is_demo) ||
        this.$props.isContributor ||
        this.$props.isSubInvestor
      );
    },
  },
  created() {
    let options = [
      { key: "currency", label: "Currency" },
      { key: "balance", label: "Balance" },
      { key: "locked", label: "Locked" },
    ];
    if (this.$props.showOptions) {
      options.push({ key: "options", label: "Options" });
    }
    this.fields_balances_table = options;
  },
  data() {
    return {
      fields_balances_table: [],
    };
  },
  methods: {
    deleteBalance(balance) {
      this.$emit("handleDeleteBalance", balance);
    },
    setBaseSymbol(balance) {
      this.$emit("handleBaseSymbol", balance);
    },
  },
};
</script>
<style scoped>
.balances-table .table tbody tr {
  padding: 2px;
}
.balances-table .table tbody td {
  padding: 0px;
}
</style>
