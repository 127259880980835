var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-col',{staticClass:"p-0 sidebar__user_information nice-scroll",attrs:{"cols":"12","lg":"2"}},[(!_vm.$store.state.loadingContributor)?_c('div',{staticClass:"p-2"},[_c('div',{staticClass:"separator"},[_vm._v("Basic information")]),_c('div',{staticClass:"item-data"},[_c('div',{staticClass:"label"},[_vm._v("Name:")]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.state.contributorInfo.info ? _vm.$store.state.contributorInfo.info.name : "--")+" ")])]),_c('div',{staticClass:"item-data"},[_c('div',{staticClass:"label"},[_vm._v("Token:")]),_c('div',{staticClass:"description",on:{"click":function($event){return _vm.copyToken(
              _vm.$store.state.contributorInfo.info
                ? _vm.$store.state.contributorInfo.info.token_user
                : '---'
            )}}},[_vm._v(" "+_vm._s(_vm.$store.state.contributorInfo.info ? _vm.$store.state.contributorInfo.info.token_user : "---")+" ")])]),_c('div',{staticClass:"item-data"},[_c('div',{staticClass:"label"},[_vm._v("Balance initial:")]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$helpers.toParseNumber( _vm.$store.state.contributorInfo.symbol, "profit", _vm.$store.state.contributorInfo.balance_initial ))+" ")])]),_c('div',{staticClass:"item-data"},[_c('div',{staticClass:"label"},[_vm._v("Equity:")]),_c('div',{staticClass:"description mono",class:[
            _vm.$store.state.contributorInfo.resumen.profit_percentage >= 0
              ? 'text-success'
              : 'text-danger',
          ]},[_vm._v(" "+_vm._s(_vm.$helpers.toParseNumber( _vm.$store.state.contributorInfo.symbol, "profit", _vm.$store.state.contributorInfo.resumen.profit_net ))+" "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"badget-number",attrs:{"title":"Last month's performance"}},[_vm._v(" "+_vm._s(_vm.$store.state.contributorInfo.resumen.profit_percentage.toFixed(2))+"% ")])])]),_c('div',{staticClass:"item-data"},[_c('div',{staticClass:"label"},[_vm._v("Operation time:")]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.state.contributorInfo.info ? _vm.$store.state.contributorInfo.info.time_operation : "--")+" ")])]),_c('div',{staticClass:"item-data"},[_c('div',{staticClass:"label"},[_vm._v("Initial Date:")]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.state.contributorInfo.info ? _vm.$store.state.contributorInfo.info.time_start : "--")+" ")])]),(_vm.$store.state.user.role_id && _vm.$store.state.user.role_id === 1)?_c('div',{staticClass:"d-block mt-2 admin-menu"},[(_vm.loadingReport)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2",attrs:{"title":"Download report"}},[_c('b-icon',{attrs:{"icon":"arrow-repeat","animation":"spin-pulse"}})],1):_vm._e(),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2",attrs:{"title":"View"},on:{"click":_vm.showAccount}},[_c('b-icon-eye')],1)]):_vm._e()]):_vm._e(),(_vm.$store.state.loadingContributor)?_c('div',{staticClass:"p-2"},[_c('b-skeleton-table',{attrs:{"rows":5,"columns":1,"table-props":{ striped: true }}})],1):_vm._e(),_c('CreateAndEditContributor',{ref:"CreateAndEditContributor",attrs:{"investor_token":_vm.investor_token,"investor":{},"investor_balances":_vm.$store.state.contributorInfo.balances
          ? _vm.$store.state.contributorInfo.balances
          : []}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }