<template>
  <div class="align-items-center algorithm-alert">
    <img class="refresh" :src="algorithm_refresh_icon" />
    <div class="row">
      <div class="col-sm-12">
        <div class="widget-header p-3">
          <img :src="algorithm_alert_icon" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="widget-content">
          <div class="alert-title">Oooops!</div>
          <p class="alert-desc">
            Algorithms, systems, and micro-services are down. Click below for
            details.
          </p>
        </div>
      </div>
    </div>
    <div class="row widget-footer">
      <button class="view-button" @click="showDeadAlgorithms">view</button>
    </div>
  </div>
</template>
<script>
import AlgorithmAlertIcon from "../../../../public/common/algorithm-alert-icon.png";
import AlgorithmRefreshIcon from "../../../../public/common/algorithm-alert-refresh.png";
export default {
  name: "AlgorithmAlert",
  data() {
    return {
      algorithm_alert_icon: AlgorithmAlertIcon,
      algorithm_refresh_icon: AlgorithmRefreshIcon,
    };
  },
  methods: {
    showDeadAlgorithms() {
      this.$store.commit("systems_heartbeat/set_systems_heartbeat_filters", 0);
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/pages/systems_heartbeat/algorithm_alert.scss";
</style>
