<template>
  <div
    ref="filterChartModal"
    id="filterChartModal"
    class="modal fade modal-algorithm-health"
    tabindex="-1"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Filter</h5>
          <span class="view-logs-modal-close" @click="closeModal"
            ><img :src="close_icon"
          /></span>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label>Select a date range</label>
            <BaseDateRangePicker
              style="width: 100%"
              v-model="dateRange"
              format="YYYY-MM-DD"
              :showLabel="false"
              :icon="calendar_icon"
              label="Date Range"
              :dateDefault="dateRange"
              placeholder="From - To"
              :timepicker="true"
              :everydayMinDate="everydayMinDate"
              :everydayMaxDate="everydayMaxDate"
            />
          </div>
          <div class="d-flex justify-content-end">
            <button @click="filterDate">Search</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseDateRangePicker from "@/components/commons/BaseDateRangePicker";
import calendarIcon from "@/assets/images/calendar.svg";
import closeIcon from "../../../../assets/images/close.svg";
import moment from "moment";
import { mapState } from "vuex";
import { Modal } from "bootstrap";
import $ from "jquery";

export default {
  name: "filterChartModal",
  components: { BaseDateRangePicker },
  data() {
    return {
      calendar_icon: calendarIcon,
      close_icon: closeIcon,
      dateRange: "",
    };
  },
  mounted() {
    $("#filterChartModal").modal("show");
    const self = this;
    $("#filterChartModal").on("hide.bs.modal", function (event) {
      self.$emit("closed");
    });
  },
  computed: {
    ...mapState("dashboard", ["basic_information"]),
    everydayMaxDate() {
      return new Date();
    },
    everydayMinDate() {
      const info = this.basic_information._data.data;
      if (!info) return null;

      return info.first_snapshot_date
        ? new Date(info.first_snapshot_date)
        : new Date(info.created_at);
    },
  },
  methods: {
    closeModal() {
      $("#filterChartModal").modal("hide");
      this.dateRange = "";
    },
    filterDate() {
      var date = {
        start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
        end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
      };

      this.$store.commit("dashboard/set_filter_chart", date);
      this.$store.dispatch("dashboard/performance_account", {
        callback: ({ response }) => {
          if (response.error) {
            this.$toastr.error(response.message, `${this.error}`);
            return;
          } else {
            this.closeModal();
          }
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../../../styles/pages/dashboard/filter_chart_modal.scss";
</style>
