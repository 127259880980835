<template>
  <div
    ref="trackRecord"
    id="trackRecord"
    class="modal fade modal-track-record"
    tabindex="-1"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Track record</h5>
          <span class="view-logs-modal-close" @click="closeModal"
            ><img :src="close_icon"
          /></span>
        </div>
        <div class="modal-body">
          <div class="date-range">
            <label class="label">Select a date range</label>
            <BaseDateRangePicker
              style="width: 100%"
              v-model="dateRange"
              format="YYYY-MM-DD"
              :showLabel="false"
              :icon="calendar_icon"
              label="Date Range"
              :dateDefault="dateRange"
              placeholder="From - To"
              :timepicker="true"
              :everydayMinDate="everydayMinDate"
              :everydayMaxDate="everydayMaxDate"
            />
          </div>
          <ProcessingLoader v-if="onLoading"/>
          <div v-show="!onLoading" class="health-bar" ref="health_bar">
            <HealthBarTimeline
              :data="timeline_data"
              :timelineOptions="['Incidents']"
            />
          </div>
          <div class="health-alert">
            <AlertInformation
              title="Impact of Filters on the Report"
              description="The dates in this report may be affected by the filter you apply."
            />
          </div>
          <div class="d-flex justify-content-end">
            <button @click="closeModal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import closeIcon from "../../../../assets/images/close.svg";
import BaseDateRangePicker from "@/components/commons/BaseDateRangePicker";
import AlertInformation from "../../../commons/alerts/AlertInformation.vue";
import calendarIcon from "@/assets/images/calendar.svg";
import HealthBarTimeline from "../../../commons/HealthBarTimeline.vue";
import ProcessingLoader from "../../../commons/alerts/ProcessingLoader.vue";
import { mapState } from "vuex";

export default {
  name: "trackRecord",
  components: {
    BaseDateRangePicker,
    AlertInformation,
    HealthBarTimeline,
    ProcessingLoader,
  },
  props: ["token"],
  data() {
    return {
      close_icon: closeIcon,
      calendar_icon: calendarIcon,
      dateRange: "",
    };
  },
  watch: {
    dateRange: function (newDateRange, oldDateRange) {
      this.loadDataOnMount(newDateRange.startDate, newDateRange.endDate);
    },
  },
  mounted() {
    $("#trackRecord").modal("show");
    const self = this;
    $("#trackRecord").on("hide.bs.modal", function (event) {
      self.$emit("closed");
    });
    this.loadDataOnMount();
  },
  methods: {
    closeModal() {
      $("#trackRecord").modal("hide");
    },
    everydayMinDate() {
      return new Date();
    },
    everydayMaxDate() {
      return new Date();
    },
    loadDataOnMount(start_date = null, end_date = null) {
      let params = { token: this.token };
      if (start_date && end_date) {
        params.start_date = start_date;
        params.end_date = end_date;
      }
      this.$store.dispatch("systems_heartbeat/invidual_token_uptime", params);
    },
  },
  computed: {
    ...mapState("systems_heartbeat", ["invidual_token_uptime"]),
    timeline_data() {
      return this.invidual_token_uptime._data.data;
    },
    onLoading() {
      return this.invidual_token_uptime._status._on_loading;
    },
  },
};
</script>

<style lang="scss">
@import "../../../../styles/pages/systems_heartbeat/track_record_modal.scss";
</style>
