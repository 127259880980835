<template>
  <div>
    <div
      class="row pl-3 pr-3"
      v-if="
        (investors.length > 0 || $store.state.first_searched_investor) &&
        !$store.state.migration_loading
      "
    >
      <div class="col-md-3 p-1" v-for="i in investors">
        <User
          :open-transaction-modal="openTransactionModal"
          :sup_has_investor="false"
          :value="i"
          @handleShowEdit="handleShowEdit"
          @handleShowDuplicate="handleShowDuplicate"
          @handleAudit="handleAudit"
        ></User>
      </div>
    </div>
    <div
      class="row pl-1 pr-1"
      v-else-if="
        $store.state.investors.length == 0 &&
        $store.state.investor_is_searched &&
        !$store.state.migration_loading
      "
    >
      <div class="col">
        <b-alert class="mb-2" show variant="dark">{{
          $store.state.migration_authorized
            ? "No investors found with applied filters"
            : "Authorize the user to make the migration"
        }}</b-alert>
      </div>
    </div>
    <div
      v-show="
        $store.state.first_searched_investor ||
        $store.state.investor_supervisor_searched ||
        $store.state.migration_loading
      "
      class="row pl-3 pr-3"
    >
      <div class="col-md-3 p-1">
        <b-card style="height: 483px; background-color: #1d1e25">
          <b-skeleton-img height="200px"></b-skeleton-img>
          <b-skeleton class="mt-2" animation="wave" width="85%"></b-skeleton>
          <b-skeleton class="mt-2" animation="wave" width="70%"></b-skeleton>
          <b-skeleton class="mt-2" animation="wave" width="70%"></b-skeleton>
          <b-skeleton class="mt-2" animation="wave" width="70%"></b-skeleton>
          <b-skeleton class="mt-2" animation="wave" width="70%"></b-skeleton>
          <div class="d-flex flex-row">
            <b-skeleton class="mt-1" type="button"></b-skeleton>
            <b-skeleton class="mt-1" type="button"></b-skeleton>
            <b-skeleton class="mt-1" type="button"></b-skeleton>
          </div>
        </b-card>
      </div>
      <div class="col-md-3 p-1">
        <b-card style="height: 483px; background-color: #1d1e25">
          <b-skeleton-img height="200px"></b-skeleton-img>
          <b-skeleton class="mt-2" animation="wave" width="85%"></b-skeleton>
          <b-skeleton class="mt-2" animation="wave" width="70%"></b-skeleton>
          <b-skeleton class="mt-2" animation="wave" width="70%"></b-skeleton>
          <b-skeleton class="mt-2" animation="wave" width="70%"></b-skeleton>
          <b-skeleton class="mt-2" animation="wave" width="70%"></b-skeleton>
          <div class="d-flex flex-row">
            <b-skeleton class="mt-1" type="button"></b-skeleton>
            <b-skeleton class="mt-1" type="button"></b-skeleton>
            <b-skeleton class="mt-1" type="button"></b-skeleton>
          </div>
        </b-card>
      </div>
      <div class="col-md-3 p-1">
        <b-card style="height: 483px; background-color: #1d1e25">
          <b-skeleton-img height="200px"></b-skeleton-img>
          <b-skeleton class="mt-2" animation="wave" width="85%"></b-skeleton>
          <b-skeleton class="mt-2" animation="wave" width="70%"></b-skeleton>
          <b-skeleton class="mt-2" animation="wave" width="70%"></b-skeleton>
          <b-skeleton class="mt-2" animation="wave" width="70%"></b-skeleton>
          <b-skeleton class="mt-2" animation="wave" width="70%"></b-skeleton>
          <div class="d-flex flex-row">
            <b-skeleton class="mt-1" type="button"></b-skeleton>
            <b-skeleton class="mt-1" type="button"></b-skeleton>
            <b-skeleton class="mt-1" type="button"></b-skeleton>
          </div>
        </b-card>
      </div>
    </div>
    <div class="row pl-3 pr-3" v-if="!$store.state.migration_loading">
      <div class="col d-flex p-1">
        <b-pagination
          v-show="investors.length > 0"
          v-model="currentPage"
          :total-rows="count"
          :per-page="pageSize"
          @change="handlePageChange"
        />
      </div>
    </div>
    <AuditAccount
      :display-modal="displayAuditModal"
      :investor="investor_for_audit"
      @close="handleAuditModelClose"
    />
    <TransactionModal ref="ref_transaction_modal" />
  </div>
</template>

<script>
import AuditAccount from "@/components/pages/investors/AuditAccount.vue";
import handleSocket from "@/helpers/socket";
import User from "./User.vue";
import TransactionModal from "../investors_list/modals/Transaction.vue";
import CONSTANTS from "@/helpers/constants.js";
import bitfrostcors from "bifrost-cors";

export default {
  name: "InvestorList",
  components: {
    AuditAccount,
    User,
    TransactionModal,
  },
  props: {
    firstLoad: {
      required: false,
      default: false,
    },
  },
  async created() {
    //
    if (localStorage.getItem("filters_investors")) {
      let filters = JSON.parse(localStorage.getItem("filters_investors"));
      this.$store.commit("set_investor_search", filters.search);
      this.$store.commit("set_user_type", parseInt(filters.user_type));
      this.$store.commit("set_investor_status", parseInt(filters.status));
      this.$store.commit(
        "set_investor_is_archived",
        parseInt(filters.is_archived)
      );
      this.$store.commit("set_investor_limit", parseInt(filters.limit));
      this.$store.commit("set_investors_sort_by", parseInt(filters.orderBy));
    }
    await this.fetchingInvestors();
    this.$store.commit("set_first_searched_investor", false);
  },
  data() {
    return {
      currentPage: 1,
      displayAuditModal: false,
      investor_for_audit: null,
      investors: [],
      idsInterval: {},
      url_socket: "investors_open_orders_several",
    };
  },
  computed: {
    pageSize() {
      if (this.$store.state.investor_limit) {
        return this.$store.state.investor_limit;
      }
      return 8;
    },
    count() {
      if (this.$store.state.investors_paginate.records) {
        return this.$store.state.investors_paginate.records;
      }
      return 0;
    },
    currentRouteName() {
      return this.$route.name.toLowerCase();
    },
  },
  methods: {
    async fetchingInvestors() {
      let routeName = this.currentRouteName;
      this.investors = [];
      if (this.$store.state.user) {
        if (this.$props.firstLoad) {
          return;
        }
        await this.$store.dispatch("bwfs_get_investors", {
          vm: this,
          data: {
            page: this.currentPage,
            limit: this.$store.state.investor_limit,
            search: this.$store.state.investor_search,
            user_type: this.$store.state.user_type,
            status: this.$store.state.investor_status,
            is_archived:
              routeName == "supervisor"
                ? 2
                : this.$store.state.investor_is_archived,
            supervisor_token:
              this.$store.state.user.role_id == 2
                ? this.$store.state.user.token
                : "",
            orderBy: this.$store.state.investors_sort_by,
          },
        });
      }
      if (this.$store.state.investors) {
        this.investors = this.$store.state.investors;
      }
    },
    async handlePageChange(value) {
      let routeName = this.currentRouteName;
      if (this.$store.state.user) {
        await this.$store.dispatch("bwfs_get_investors", {
          vm: this,
          data: {
            page: value,
            limit: this.$store.state.investor_limit,
            search: this.$store.state.investor_search,
            user_type: this.$store.state.user_type,
            status: this.$store.state.investor_status,
            is_archived:
              routeName == "supervisor"
                ? 2
                : this.$store.state.investor_is_archived,
            supervisor_token:
              this.$store.state.user.role_id == 2
                ? this.$store.state.user.token
                : "",
            orderBy: this.$store.state.investors_sort_by,
          },
        });
      }
      if (this.$store.state.investors) {
        this.investors = this.$store.state.investors;
      }
    },
    handleShowEdit(investor) {
      let user = investor.info;
      user.name = investor.info.investor.name;
      user.conditions = investor.conditions;
      user.balances = investor.balances;
      user.on = investor.on;
      user.email_report = investor.info.investor.email_report;
      user.email_report_frequency =
        investor.info.investor.email_report_frequency;
      user.email_address_report = investor.info.investor.email_address_report;
      user.email_report_order_frequency =
        investor.info.investor.email_report_order_frequency;
      user.block_account = investor.info.investor.block_account;
      user.block_account_password =
        investor.info.investor.block_account_password;
      this.$store.commit("set_info_investor", user);
      this.$parent.$refs.CreateEditInvestor.setEditInvestor(user);
      this.$bvModal.show("modal-create-user");
    },
    handleShowDuplicate(investor) {
      let user = investor.info;
      user.name = investor.info.investor.name;
      user.conditions = investor.conditions;
      user.balances = investor.balances;
      user.email_report = investor.info.investor.email_report;
      user.email_report_frequency =
        investor.info.investor.email_report_frequency;
      user.email_address_report = investor.info.investor.email_address_report;
      user.email_report_order_frequency =
        investor.info.investor.email_report_order_frequency;
      user.block_account = investor.info.investor.block_account;
      user.block_account_password =
        investor.info.investor.block_account_password;
      this.$store.commit("set_info_investor", user);
      this.$parent.$refs.CreateEditInvestor.setAutoCompletenvestor(user);
    },
    handleAudit(investor) {
      this.investor_for_audit = investor;
      this.displayAuditModal = true;
    },
    handleAuditModelClose() {
      this.displayAuditModal = false;
    },
    conneting() {
      if (!this.$store.state.order_tracking_mode) {
        this.onClose();
        return;
      }
      this.websocket = handleSocket(
        this.url_socket,
        this.onOpen,
        this.onClose,
        this.onMessage,
        this.onError
      );
    },
    sendConnect() {
      if (!this.$store.state.order_tracking_mode) {
        this.onClose();
        return;
      }
      this.doSend(
        JSON.stringify(this.$store.state.investors_orders_tokens_socket)
      );
    },
    onOpen() {
      if (!this.$store.state.order_tracking_mode) {
        this.onClose();
        return;
      }
      if (this.$store.state.investors_orders_tokens_socket == 0) {
        let interval = setInterval(() => {
          if (this.$store.state.investors_orders_tokens_socket > 0) {
            clearInterval(interval);
            this.writeToScreen("CONNECTED");
            this.sendConnect();
          }
        }, 250);
      } else {
        this.writeToScreen("CONNECTED");
        this.sendConnect();
      }
    },
    onClose(evt) {
      this.writeToScreen("DISCONNECTED");
      if (!this.$store.state.order_tracking_mode) return;
      setTimeout(() => {
        // this.conneting();
      }, 1500);
    },
    onMessage(evt) {
      if (!this.$store.state.order_tracking_mode) {
        this.onClose();
        return;
      }
      if (evt.data) {
        let data = JSON.parse(evt.data);
        let orders = [...data];
        for (let i = 0; i < orders.length; i++) {
          if (this.idsInterval[orders[i].token]) {
            clearInterval(this.idsInterval[orders[i].token]);
          }
          this.idsInterval[orders[i].token] = setInterval(
            this.refreshOrders,
            1000,
            orders[i]
          );
        }
      }
    },
    onError(evt) {
      console.error(evt);
    },
    openTransactionModal() {
      this.$refs["ref_transaction_modal"].show();
    },
    doSend(message) {
      this.websocket.send(message);
    },
    writeToScreen(message) {
      console.log("writeToScreen", message);
    },
    refreshOrders(data) {
      this.$store.commit("update_several_orders_on_open", data);
    },
  },
  watch: {
    "$store.state.investor_is_searched": function (value) {
      if (value) {
        this.investors = this.$store.state.investors;
      }
    },
    "$store.state.investor_delete_token"(value) {
      if (value) {
        const index = this.investors.indexOf(value);
        if (index > -1) {
          this.investors.splice(index, 1);
        }
      }
    },
    "$store.state.investor_archived_token"(value) {
      if (value) {
        const index = this.investors.indexOf(value);
        if (index > -1) {
          this.investors.splice(index, 1);
        }
      }
    },
    "$store.state.investor_error"(value) {
      if (value) {
        this.investors = this.$store.state.investors;
        this.$store.commit("set_investor_error", false);
      }
    },
    "$store.state.investor_supervisor_searched"(value) {
      if (value) {
        this.investors = [];
      }
    },
    "$store.state.investors_socket_searched"(value) {
      if (value) {
        try {
          if (this.$store.state.order_tracking_mode) this.conneting();
        } catch (e) {
          if (this.$store.state.order_tracking_mode) this.conneting();
        }
        this.$store.commit("set_investors_socket_searched", false);
      }
    },
    "$store.state.migration_mode"(value) {
      if (!value) {
        this.$store.commit("set_migration_mode", false);
        this.$store.commit("set_migration_authorized", true);
        this.fetchingInvestors();
      }
    },
    $route(to, from) {
      if (
        this.$route.name.toLowerCase() !== "investors" &&
        JSON.stringify(this.idsInterval) != "{}"
      ) {
        let keys = Object.keys(this.idsInterval);
        for (let i = 0; i < keys.length; i++) {
          clearInterval(this.idsInterval[keys[i]]);
          delete this.idsInterval[keys[i]];
          this.$helpers.resetFavIcon();
        }
      }
    },
  },
  beforeDestroy() {
    let keys = Object.keys(this.idsInterval);
    for (let i = 0; i < keys.length; i++) {
      clearInterval(this.idsInterval[keys[i]]);
      delete this.idsInterval[keys[i]];
    }
    this.$store.commit("set_first_searched_investor", true);
    this.$store.commit("set_migration_mode", false);
    this.$store.commit("set_migration_authorized", true);
  },
};
</script>

<style scoped>
@media (min-width: 768px) and (max-width: 1200px) {
  ::v-deep .col-md-3 {
    flex: 0 0 50%;
    max-width: 100%;
  }
}
</style>
