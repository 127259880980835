<template>
  <div
    ref="notificationsModal"
    id="notificationsModal"
    class="modal fade modal-notifications"
    tabindex="-1"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Notifications</h5>
          <span class="view-logs-modal-close" @click="modal.hide()"
            ><img :src="close_icon"
          /></span>
        </div>
        <div class="modal-body">
          <div v-if="notifications.length">
            <div v-if="$store.state.user.token" class="input-group mb-2">
              <input
                type="text"
                class="form-control float-right d-inline-block"
                id="searchNotificationInput"
                placeholder="Search by message and date..."
                v-model="search_notification"
              />
              <span
                class="input-group-text no-border right dark"
                id="search-form-icon"
              >
                <img :src="search_icon" alt="" />
              </span>
            </div>

            <b-skeleton-table
              :rows="4"
              :columns="1"
              :table-props="{ striped: true }"
              v-if="$store.state.investor_notifications_loading"
            ></b-skeleton-table>
            <div
              class="item-data mb-2"
              v-for="notification in notifications"
              v-if="
                notifications.length > 0 &&
                !$store.state.investor_notifications_loading
              "
            >
              <div class="description">
                {{ notification.msg ? notification.msg : notification }}
              </div>
              <div class="label small date">
                {{
                  notification.date
                    ? notification.date.substr(0, 19)
                    : notification.date
                }}
              </div>
            </div>
            <b-list-group-item
              :key="'pgination'"
              class="d-flex p-0"
              v-if="
                !$store.state.investor_notifications_loading &&
                notifications.length > 0
              "
            >
              <b-button
                variant="dark"
                class="btn-sm mr-auto"
                @click="
                  getNotifications(
                    $store.state.investor_notifications_paginate.current_page -
                      1
                  )
                "
                :disabled="
                  $store.state.investor_notifications_paginate.current_page -
                    1 ===
                  0
                "
              >
                <b-icon-arrow-left></b-icon-arrow-left>
              </b-button>
              <span
                >{{
                  $store.state.investor_notifications_paginate.current_page
                }}/{{
                  $store.state.investor_notifications_paginate.total_pages
                }}</span
              >
              <b-button
                variant="dark"
                class="btn-sm ml-auto"
                @click="
                  getNotifications(
                    $store.state.investor_notifications_paginate.current_page +
                      1
                  )
                "
                :disabled="
                  $store.state.investor_notifications_paginate.current_page +
                    1 >
                  $store.state.investor_notifications_paginate.total_pages
                "
              >
                <b-icon-arrow-right></b-icon-arrow-right>
              </b-button>
            </b-list-group-item>
          </div>
          <div v-else>
            <AlertNoInformation />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertNoInformation from "../../../commons/alerts/AlertNoInformation.vue";
import closeIcon from "../../../../assets/images/close.svg";
import Search from "../../../../../public/common/search.png";

import $ from "jquery";
import { Modal } from "bootstrap";

export default {
  name: "NotificationsModal",
  components: { AlertNoInformation },
  props: {
    notifications: {
      type: Array,
    },
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      close_icon: closeIcon,
      modal: null,
      search_icon: Search,
      search_notification: "",
    };
  },
  mounted() {
    this.modal = new Modal(this.$refs.notificationsModal);
    const self = this;
    $("#notificationsModal").on("hide.bs.modal", function (event) {
      self.$emit("closed");
    });
    this.addEventSearch();
  },
  computed: {},
  methods: {
    addEventSearch() {
      try {
        const myInput = document.getElementById("searchNotificationInput");
        myInput.addEventListener(
          "keyup",
          this.$debounce(this.getNotifications)
        );
      } catch (e) {
        setTimeout(() => {
          this.addEventSearch();
        }, 500);
      }
    },
    closeModal() {
      this.modal.hide();
    },
    getNotifications(page = 1) {
      const { investor_token } = this.$store.state.dashboard;
      const payload = {
        vm: this,
        data: {
          page,
          limit: 10,
          investor_token,
          search: this.search_notification,
        },
      };
      this.$store.commit("get_notification_investor", payload);
    },
    showModal() {
      this.modal.show();
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.modal.show();
      } else {
        this.modal.hide();
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../../styles/pages/dashboard/notifications_modal.scss";
</style>
