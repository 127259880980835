<template>
  <div class="sliders-module">
    <vue-marquee-slider
      id="crypto-slider"
      :speed="150000"
      v-if="crypto_prices.length > 0"
    >
      <div
        class="currencies"
        v-for="(currency, index) in crypto_prices"
        :key="index"
      >
        <img :src="currency.image" alt="" />
        <span class="name">{{ currency.name }}</span>
        <span class="name">{{ currency.symbol }}</span>
        <span :class="[currency.performance < 0 ? 'red' : 'green']"
          >{{ formatter(currency.performance) }} $</span
        >
        <span :class="[currency.performance_percentage < 0 ? 'red' : 'green']"
          >({{ formatter(currency.performance_percentage) }}%)</span
        >
        <img class="separator" :src="separator" alt="" />
      </div>
    </vue-marquee-slider>
    <!-- <div class="news-container">
      <vue-marquee-slider
        id="marquee-slider"
        :speed="150000"
        :width="360"
        v-if="news.length > 0"
      >
        <div class="news" v-for="(item, index) in news" :key="index">
          <div class="info-container">
            <div class="d-flex justify-content-between">
            
              <span class="title-container">
                <img
                  :src="item.sentiment < 0 ? negative_icon : positive_icon"
                  alt=""
                />
                <span v-if="item.sentiment < 0" class="red">Negative</span>
                <span v-else class="green">Positive</span>
                <span class="date">{{ getDate(item.date) }}</span></span
              >

              <span @click="openNewsModal(item.token)" class="link-icon"
                ><img :src="link_icon" alt=""
              /></span>
            </div>
            <span>{{ item.title }}</span>
          </div>
          <img class="big-separator" :src="separator_big" alt="" />
        </div>
      </vue-marquee-slider>
    </div> -->
    <NewsModal :show="showNewsModal" @closed="showNewsModal = false" />
  </div>
</template>

<script>
import LinkIcon from "../../../../public/common/link-icon.svg";
import NegativeIcon from "../../../../public/common/circle-red.svg";
import PositiveIcon from "../../../../public/common/circle-green.svg";
import SeparatorIcon from "../../../../public/common/separator-vertical-gray.svg";
import SeparatorBigIcon from "../../../../public/common/separator-gray-big.svg";

import { VueMarqueeSlider } from "vue-marquee-slider";
import moment from "moment";
import number_formatter from "../../../helpers/formatters/number_formatter";
import NewsModal from "./modals/NewsModal.vue";
import { mapState } from "vuex";

export default {
  name: "HeaderTopSliders",
  data() {
    return {
      intervalId: null,
      link_icon: LinkIcon,
      negative_icon: NegativeIcon,
      positive_icon: PositiveIcon,
      separator: SeparatorIcon,
      separator_big: SeparatorBigIcon,
      showNewsModal: false,
    };
  },
  components: { VueMarqueeSlider, NewsModal },
  computed: {
    ...mapState("dashboard", ["news_sliders", "crypto_slider"]),
    news() {
      return this.news_sliders && this.news_sliders._data
        ? this.news_sliders._data.data
        : [];
    },
    crypto_prices() {
      return this.crypto_slider && this.crypto_slider._data
        ? this.crypto_slider._data.data
        : [];
    },
  },
  created() {
    this.$store.dispatch("dashboard/news_sliders");
    this.$store.dispatch("dashboard/crypto_slider");
    this.intervalId = setInterval(() => {
      this.$store.dispatch("dashboard/crypto_slider");
    }, 60000);
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.intervalId);
    next();
  },
  methods: {
    getDate(date) {
      return moment(date).format("MMM DD h:ss A");
    },
    openNewsModal(token) {
      this.$store.dispatch("dashboard/news_details", token);
      this.showNewsModal = true;
    },
    formatter(number) {
      return number_formatter(number, 2);
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/pages/dashboard/header_top_sliders.scss";
</style>
