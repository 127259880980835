<template>
  <div class="investor-preview-connections-component">
    <div class="connection-container">
      <h1>Connections</h1>
      <div class="component-connections-table max-width">
        <AlertErrorInformation v-if="onError" />
        <div
          class="table-connections table-responsive"
          v-else-if="connections.length > 0"
        >
          <Table
            :actions="false"
            :data="connections"
            :headers="columnHeaders"
            class="orders-table"
            title="Orders"
          >
            <template v-slot:exchange="{ text }">
              <span>{{ text.name }}</span>
            </template>
          </Table>
        </div>
        <AlertNoInformation v-else :isDark="false" />
      </div>
    </div>
  </div>
</template>

<script>
import AlertNoInformation from "../../../commons/alerts/AlertNoInformation.vue";
import Table from "../../../commons/table/Table.vue";
import { mapState } from "vuex";

export default {
  name: "InvestorPreviewConnections",
  components: { Table, AlertNoInformation },
  data() {
    return {
      columnHeaders: [
        { name: "name", title: "Name", align: "left" },
        { name: "leverage", title: "Leverage", align: "left" },
        { name: "exchange", title: "Exchange", align: "left" },
      ],
      onError: false,
      paginate: {
        current_page: 1,
        per_page: 10,
        records: 0,
        total_pages: 0,
      },
    };
  },
  computed: {
    ...mapState("connections", ["connection_list_preview"]),
    connections() {
      return this.connection_list_preview && this.connection_list_preview._data
        ? this.connection_list_preview._data.data
        : null;
    },
  },
  methods: {
    goNextPage() {},
    goPrevPage() {},
  },
};
</script>
