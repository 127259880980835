import InvestorsListService from "../../../services/investors_list.service";

export default ({ commit, state }, { page }) => {
  commit("set", {
    _state: "investors_list",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });

  const { limit, search, sort, is_archived, status, label, orderBy, type } =
    state.investors_filters;

  const params = {
    is_archived,
    label,
    limit,
    orderBy,
    page,
    search,
    sort: sort == "" ? "desc" : sort,
    status,
    type,
  };
  InvestorsListService({
    params,
    callback: ({ response }) => {
      commit("set", {
        _state: "investors_list",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "investors_list",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
