<template>
	<div class="mt-2">
    <label class="text-muted d-block mb-2 mt-4">List of backtest conditions</label>
    <div class="card-bwfs resume_info pl-3 pr-3 pt-2 pb-2 list-conditions-desktop">
    	<div v-if="(!$store.state.condition_loading && $store.state.backtest_conditions.length > 0)">
	      <b-row></b-row>
	      <b-row class="mb-2">
			    <div class="col-md-2 text-muted d-flex small item" style="line-height: 1; align-items: flex-end">Token</div>
			    <div class="item_cm-2 text-muted d-flex small item " style="line-height: 1; align-items: flex-end">Result <br> Balance</div>
			    <div class="item_cm-2 text-muted d-flex small item " style="line-height: 1; align-items: flex-end">Performance <br>net</div>
			    <div class="item_cm-2 text-muted d-flex small item " style="line-height: 1; align-items: flex-end">
			    	Maximum consecutive <br> losses
			    </div>
			    <div class="item_cm-2 text-muted d-flex small item " style="line-height: 1; align-items: flex-end">
			    	Maximum consecutive <br> winnings
			    </div>
			    <div class="col-md-1 text-muted d-flex small item " style="line-height: 1; align-items: flex-end">Monthly<br>yield</div>
				</b-row>
	      <b-row class="pb-1" v-for="(c , i) in $store.state.backtest_conditions" :key="i">
					<div class="col-md-12">
						<div class="row"
						:style="{
							background: handleEditMonthlyYieldBoolean(c.monthly_yield, JSON.parse(c.condition.condition).base_configuration.balance, c.resumen.total_operation) && c.resumen.profit_net >= 0 ? '#001f00' : null,
							borderLeft: c.condition.token == condition_token ? '3px #2ed8ff solid' : null,
						}"
						>
							<div class="col-md-2  item">
		            <div class="d-block">
		              <span class="d-block pointer mono small"  v-b-tooltip.hover :title="getNote(c.condition)">
										{{c.condition.token.substr(0, 18)}}...
		             </span>
		            </div>
		          </div>
							<div class="item_cm-2 item">
		            <div class="d-block">
		              <span class="d-block mono small">
					    			<span v-if="showResumen(i)">
					    				{{$helpers.toParseNumber($store.state.backtest_detail.symbol, 'balance_inicial', JSON.parse(c.condition.condition).base_configuration.balance)}} <br>
					    				{{$helpers.toParseNumber($store.state.backtest_detail.symbol, 'balance_inicial', c.resumen.profit_coin)}}
					    			</span> 
					    			<span v-else>---</span>
		             </span>
		            </div>
		          </div>
		          <div class="item_cm-2 item" :class="[c.resumen.profit_net >= 0 ? 'text-success' : 'text-danger']">
		            <div class="d-block">
		              <span class="d-block mono small">
					    			<span  v-if="showResumen(i)">
					    				{{$helpers.toParseNumber($store.state.backtest_detail.symbol, 'balance_inicial', c.resumen.profit_net)}} <br>
					    				{{c.resumen.profit_percentage ? c.resumen.profit_percentage.toFixed(2)+"%" : '--' }} ({{c.resumen.total_operation}})
					    			</span> 
					    			<span v-else>---</span>
		             </span>
		            </div>
		          </div>
		          <div class="item_cm-2  item">
		            <div class="d-block">
		              <span class="d-block mono small"  v-if="showResumen(i)">
		                {{$helpers.toParseNumber($store.state.backtest_detail.symbol, 'profit_losses', c.resumen.maximum_profit_losses)}} ({{c.resumen.maximum_consecutive_losses}})
		              </span>
					    		<span v-else>---</span>
		            </div>
		          </div>
		          <div class="item_cm-2 item ">
		            <div class="d-block">
		              <span class="d-block mono small"  v-if="showResumen(i)">
		               {{$helpers.toParseNumber($store.state.backtest_detail.symbol, 'profit_winning', c.resumen.maximum_profit_winnings)}} ({{c.resumen.maximum_consecutive_winnings}})
		              </span>
					    		<span v-else>---</span>
		            </div>
		          </div>
		          <div class="item_cm-2  item">
		            <div class="d-block">
		              <span class="d-block mono small"
		              	:class="[
		              		handleEditMonthlyYieldBoolean(c.monthly_yield, JSON.parse(c.condition.condition).base_configuration.balance, c.resumen.total_operation) && c.resumen.profit_net >= 0 ? 'text-success' : 'text-danger' ]"
		              		v-if="showResumen(i)"
		              	>
		                {{c.monthly_yield | handleEditMonthlyYield(JSON.parse(c.condition.condition).base_configuration.balance,)}} ({{ c.monthly_yield | handleEditMonthlyYieldPercentaje(JSON.parse(c.condition.condition).base_configuration.balance)}})
		             	</span>
					    		<span v-else>---</span>
		            </div>
		          </div>
					    <div class="col-md-2 ml-auto item text-right">
								<!-- Para cargar el detalle de la condicion -->
								<span style="font-size: 22px" class="ml-2 pointer" v-b-tooltip.hover title="Show detail condition" @click="selectedCondition(c.condition, c.resumen, c.grafic)" v-if="showResumen(i)">
									<b-icon-eye></b-icon-eye>
								</span>

								<!-- Descargar reporte de la condicion individual -->
								<span style="font-size: 22px" class="ml-2 pointer" v-b-tooltip.hover title="Download individual report"  @click="downloadReport(c.condition, c.condition.token)" v-if="showResumen(i)">
									<b-icon-cloud-arrow-down></b-icon-cloud-arrow-down>
								</span>

								<!-- Para copiar las codiciones -->
								<span style="font-size: 22px" class="ml-2 pointer" v-b-tooltip.hover title="Copy one or more conditions" @click.prevent="copyCondition(c.condition.condition, i, showResumen(i))" v-if="showResumen(i)">
									<b-icon-paperclip></b-icon-paperclip>
								</span>

								<!-- Para ver el json de la condicion -->
								<span style="font-size: 22px" class="ml-2 pointer" v-b-tooltip.hover title="View JSON" @click.prevent="showJson(c.condition.condition, i)">
									<b-icon-braces></b-icon-braces>
								</span>

								<!-- Para ver el json de la condicion -->
								<span style="font-size: 22px" class="ml-2 pointer" v-b-tooltip.hover title="View modal information" @click.prevent="showModalCondition(c.condition.condition)">
									<b-icon-journal-medical></b-icon-journal-medical>
								</span>
		        	</div>
					  </div>
				  </div>
				</b-row>
			</div>	 
			<div v-if="($store.state.condition_loading) || ($store.state.backtest_conditions.length == 0 && $store.state.backtest_loading)">
				<b-col cols="12" class="text-center px-0">
					<b-skeleton-table
            :rows="5"
            :columns="4"
            :table-props="{  striped: true }"
          ></b-skeleton-table>
				</b-col>
			</div>
			<div v-if="!$store.state.condition_loading && !$store.state.backtest_loading && $store.state.backtest_conditions.length == 0">
				<span>No conditions found for this backtest.</span>
			</div>
	  </div>

	  <div class="list-conditions-movil">
  		<b-row>
				<b-col cols="12" lg="10" class="p-0 admin_content nice-scroll">
		      <div class="content p-2">
		        <div class="row pl-3 pr-3">
		          <div class="col-md-12 p-1">
		            <div class="table-responsive">
		              <b-table 
		                id="table"
		                :items="$store.state.backtest_conditions" 
		                :fields="fields" 
		                striped
		                hover
		                small
		                borderless
		                :busy="$store.state.condition_loading"
		              >
		             		<template #cell(token)="row">
		              		<div class="description pointer small" @click="selectedCondition(row.item)" >
		                  	{{row.item.condition.token}}
		                	</div>
		              	</template>
		              	<template #cell(initial_balance)="row" #cell(index)="row">
		              		<div class="description pointer mono small">
		              			<span v-if="showResumen(row.index)">{{$helpers.toParseNumber($store.state.backtest_detail.symbol, 'balance_inicial', JSON.parse(row.item.condition.condition).base_configuration.balance)}}</span> 
					    					<span v-else>---</span>
		                	</div>
		              	</template>
		              	<template #cell(final_balance)="row" #cell(index)="row">
		              		<div class="description pointer mono small">
		              			<span v-if="showResumen(row.index)">{{$helpers.toParseNumber($store.state.backtest_detail.symbol, 'balance_inicial', row.item.resumen.profit_coin)}}</span>
					    					<span v-else>---</span> 
		                	</div>
		              	</template>
		              	<template #cell(performance_net)="row" #cell(index)="row">
		              		<div class="description pointer mono small"  :class="[row.item.resumen.profit_net >= 0 ? 'text-success' : 'text-danger']">
		              			<span class="d-block mono small"  v-if="showResumen(row.index)">
								    			{{row.item.resumen.profit_percentage ? row.item.resumen.profit_percentage.toFixed(2)+"%" : '--' }}
					             	</span>
								    		<span v-else>---</span>
		                	</div>
		              	</template>
		              	<template #cell(performance_percentage)="row" #cell(index)="row">
		              		<div class="description pointer">
		              			<span class="d-block mono small" v-if="showResumen(row.index)">{{row.item.resumen.total_operation}}</span>
		              			<span v-else>---</span>
		                	</div>
		              	</template>
		              	<template #cell(total_operations)="row" #cell(index)="row">
		              		<div class="description pointer">
				             		<span class="d-block mono small"  v-if="showResumen(row.index)">
					                {{$helpers.toParseNumber($store.state.backtest_detail.symbol, 'profit_losses', row.item.resumen.maximum_profit_losses)}} ({{row.item.resumen.maximum_consecutive_losses}})
					              </span>
								    		<span v-else>---</span>
		                	</div>
		              	</template>
		              	<template #cell(maximum_consecutive_losses)="row" #cell(index)="row">
		              		<div class="description pointer">
		              			<span class="d-block mono small"  v-if="showResumen(row.index)">
					               {{$helpers.toParseNumber($store.state.backtest_detail.symbol, 'profit_winning', row.item.resumen.maximum_profit_winnings)}} ({{row.item.resumen.maximum_consecutive_winnings}})
					              </span>
								    		<span v-else>---</span>
		                	</div>
		              	</template>
		              	<template #cell(maximum_consecutive_winnings)="row" #cell(index)="row">
		              		<div class="description pointer">
		              			<span class="d-block mono small"  v-if="showResumen(row.index)">
					               {{$helpers.toParseNumber($store.state.backtest_detail.symbol, 'profit_winning', row.item.resumen.maximum_profit_winnings)}} ({{row.item.resumen.maximum_consecutive_winnings}})
					              </span>
								    		<span v-else>---</span>
		                	</div>
		              	</template>

		              	<template #cell(monthly_yield)="row" #cell(index)="row">
		              		<div
		              			class="description pointer"
		              			:style="{background: handleEditMonthlyYieldBoolean(row.item.monthly_yield, JSON.parse(row.item.condition.condition).base_configuration.balance, row.item.resumen.total_operation) && row.item.resumen.profit_net >= 0 ? '#001f00' : null }"
		              		>
		              			<span class="d-block mono small"  :class="[handleEditMonthlyYieldBoolean(row.item.monthly_yield, JSON.parse(row.item.condition.condition).base_configuration.balance, row.item.resumen.total_operation) && row.item.resumen.profit_net >= 0 ? 'text-success' : 'text-danger' ]" v-if="showResumen(row.index)">
					             		{{row.item.monthly_yield | handleEditMonthlyYield(JSON.parse(row.item.condition.condition).base_configuration.balance)}} ({{row.item.monthly_yield | handleEditMonthlyYieldPercentaje(JSON.parse(row.item.condition.condition).base_configuration.balance)}})
					              </span>
								    		<span v-else>---</span>
		                	</div>
		              	</template>

		              	<template #cell(options)="row" #cell(index)="row">
		              		<div class="description pointer"  style="width: 155px !important;">
		              			<span style="font-size: 22px" class="ml-2 pointer" v-b-tooltip.hover title="Show detail condition" @click="selectedCondition(row.item.condition, row.item.resumen, row.item.grafic)" v-if="showResumen(row.index)">
													<b-icon-eye></b-icon-eye>
												</span>

												<!-- Descargar reporte de la condicion individual -->
												<span style="font-size: 22px" class="ml-2 pointer" v-b-tooltip.hover title="Download individual report">
													<b-icon-cloud-arrow-down></b-icon-cloud-arrow-down>
												</span>

												<!-- Para copiar las codiciones -->
												<span style="font-size: 22px" class="ml-2 pointer" v-b-tooltip.hover title="Copy one or more conditions" @click.prevent="copyCondition(row.item.condition.condition, row.index, showResumen(row.index))" v-if="showResumen(row.index)">
													<b-icon-link></b-icon-link>
												</span>

												<!-- Para ver el json de la condicion -->
												<span style="font-size: 22px" class="ml-2 pointer" v-b-tooltip.hover title="View JSON" @click.prevent="showJson(row.item.condition.condition, index)">
													<b-icon-braces></b-icon-braces>
												</span>

												<!-- Para ver el json de la condicion -->
												<span style="font-size: 22px" class="ml-2 pointer" v-b-tooltip.hover title="View modal information" @click.prevent="showModalCondition(row.item.condition.condition)">
													<b-icon-journal-medical></b-icon-journal-medical>
												</span>
		                	</div>
		              	</template>
		              	<template #table-busy>
		                  <b-skeleton-table
						            :rows="5"
						            :columns="4"
						            :table-props="{  striped: true }"
						          ></b-skeleton-table>
		                </template>
		            	</b-table>
		           	</div>
		          </div>
		        </div>
		      </div>
		    </b-col>
		  </b-row>
	  </div>

	  <b-modal ref="modal-show-condition" id="modal-show-condition" hide-footer hide-header size="xl" ok-only>
      <div class="modal-create-conditions">
        <h3 class="modal-title">Show condition</h3>
        <div class="row">
	        <div class="col-md-12">
	          <input type="text" class="form-control d-block mb-2" v-model="filterCondition" placeholder="Filter condition..." id="filterCondition">
	        </div>
	        <div class="col-md-10">
        		<ViewJson :jsonData="selectedConditionJson" :filter="filterResumen" key="resumenCondition"  />
	        </div>
	        <div class="col-md-2">
						<span style="font-size: 22px" class="ml-2 pointer" v-b-tooltip.hover title="Copy one or more conditions" @click.prevent="copyCondition(selectedConditionJson)">
							<b-icon-link></b-icon-link>
						</span>
	        </div>
        </div>
      </div>
    </b-modal>
	</div>
</template>
<script>
	import { EventBus } from '@/helpers/event-bus.js';
	import ViewJson from '@/components/commons/ViewJson';

	export default {
		name: 'Conditions',
		components: {
			ViewJson,
		},
		props: {
			backtest_token: {
				required: true,
	      type: String,
			},
			performance_month_by_month: {
				required: true,
				type: Number,
				default: 0
			},
			success_rate: {
				required: true,
				type: Number,
				default: 0
			},
		},
		mounted() {
      this.addEventSeach();
    },
		created() {
			this.getConditions();
			if (this.$route.params.hasOwnProperty('condition_token')) {
	      this.condition_token = this.$route.params.condition_token;
	     	this.selectAutomaticCondition();
	    }
		},
		data() {
			return {
				selectedConditionJson: {},
				filterCondition: '',
				filterResumen: '',
				condition_token: null,
				selectedConditionToken: '',
				current_index_show: 0,
				fields: [
          {key: 'token',}, 
          {key: 'initial_balance',}, 
          {key: 'final_balance',}, 
          {key: 'performance_net',}, 
          {key: 'performance_percentage',}, 
          {key: 'total_operations',}, 
          {key: 'maximum_consecutive_losses',}, 
          {key: 'maximum_consecutive_winnings',}, 
          {key: 'monthly_yield',}, 
          {key: 'options',}, 
        ],
			};
		},
		methods: {
			getConditions() {
				if (!this.$store.state.backtest_detail.token) {
					setTimeout(() => {
						this.getConditions();
					}, 100)
					return;
				}
        const data = {
        	vm: this,
        	backtest_token: this.$props.backtest_token,
        	is_compound: this.$store.state.backtest_detail.compound.length > 0 ? 1 : 0,
        };
      	this.$store.commit('bwfs_get_condtions_backtest', data);
			},
			selectedCondition(c, resumen, grafic) {
      	this.$store.commit('bwfs_selected_condition_token', c);
      	const payload = {
					vm: this,
					condition_token: c.token,
					backtest_token: this.$store.state.token_backtest_detail,
      	};
      	this.condition_token = c.token;
				this.$store.commit('bwfs_get_condition_resumen', resumen);
        this.$store.commit('bwfs_get_history_balance', grafic);
        this.$router.push({ name: 'BacktestingDetailWithCondition', params: { token: this.$store.state.token_backtest_detail, condition_token: c.token }});
			},
			downloadReport(c,  condition_token) {
        let conditions = []
				if (this.$store.state.backtest_detail.compound.length > 0) {
          conditions = this.$store.state.backtest_detail.compound.filter(c => c.parent === condition_token || c.child === condition_token)
        }
				const payload = {
					vm: this,
					data : {
						condition_token: c.token,
						backtest_token: this.$store.state.token_backtest_detail,
						is_compound: this.$store.state.backtest_detail.compound.length > 0 ? 1 : 0,
          	conditions: conditions.length > 0 ? JSON.stringify({parent: conditions[0].parent, child: conditions[0].child}) : '{}',
					},
					server: 'https://backtesting.bwfs.creedandbear.com:8080',
      	};
        this.$store.commit('bwfs_download_report_by_condtion', payload);
			},
			showJson(condition, index) {
				this.current_index_show = index; 
				this.selectedConditionJson = JSON.parse(condition);
      	this.$bvModal.show('modal-show-condition');
			},
			getNote(condition) {
				return JSON.parse(condition.condition).base_configuration.note;
			},
			showModalCondition(condition) {
				this.$emit('handleShowCondition', JSON.parse(condition));
			},
			copyCondition(condition, index, isCompound) {
				if (typeof condition === 'string') {
					condition = JSON.parse(condition)
				}
				let currentCondition = condition;
				let backtest = {...this.$store.state.backtest_detail};
				condition.backtest = backtest;
				currentCondition.backtest = backtest;

				condition = JSON.stringify(condition);
				
				if (this.$store.state.backtest_detail.compound.length > 0) {
					let current_index = index ? index : this.current_index_show;
					let condition2 = this.$store.state.backtest_conditions[current_index - 1];
					if (typeof condition2.condition.condition == "string") {
						condition2.condition.condition = JSON.parse(condition2.condition.condition);
						condition2.condition.condition.backtest = backtest;
						condition2.condition.condition = JSON.stringify(condition2.condition.condition);
					} else {
						condition2.condition.condition.backtest = backtest;
					}
					condition = [condition2.condition.condition, condition];
				} else {
					if (typeof condition === 'string') {
						condition = JSON.parse(condition);
						if (!condition.token) {
							condition.token = this.$store.state.backtest_conditions[index].condition.token;
						}
						condition = JSON.stringify(condition);
					}
				}
				let conditions = [];
				conditions.push(condition);
				if (localStorage.getItem('copyCondition')) {
				  let c = localStorage.getItem('copyCondition');
				  c = JSON.parse(this.$crypto.decrypt(c));
				  conditions = [...conditions, ...c];
				}
				this.$helpers.copyTextToClipboard(JSON.stringify(currentCondition), this);
				localStorage.setItem('copyCondition', this.$crypto.encrypt(JSON.stringify(conditions)));
				EventBus.$emit('handleCopyCondition', true);
			},
			showResumen(i) {
				try {
					return ( this.$store.state.backtest_detail.compound && this.$store.state.backtest_detail.compound.length == 0) || (i % 2 !== 0 && this.$store.state.backtest_detail.compound.length > 0);
				} catch(e) {
					return false;
				}
			},
			selectAutomaticCondition() {
				if (this.$store.state.backtest_conditions.length == 0) {
					setTimeout(() => {
						this.selectAutomaticCondition();
					}, 500);
					return;
				} else {
					let select = this.$store.state.backtest_conditions.filter((c) => c.condition.token === this.condition_token);
					if (select.length > 0) {
						select = select[0];
						this.selectedCondition(select.condition, select.resumen, select.grafic);
					}
				}
			},
			handleEditMonthlyYieldBoolean(data, balance_inicial, total_operations) {
				let keys = Object.keys(data);
				let total_operation_winnings = 0;
				for (let i = 0; i < keys.length; i++) {
					let perfomance_mes_anterior  = 0;
					let current_month_perfomance = data[keys[i]].perfomance;
					let current_balance          = data[keys[i]].balance;
					let previous_balance         = data[keys[i]].balance;
					if (i > 0) {
						previous_balance = data[keys[i-1]].balance;
					}

					if (i == 0) {
          	perfomance_mes_anterior = ((current_balance - balance_inicial) / balance_inicial) * 100;
					} else {
          	perfomance_mes_anterior = ((current_balance - previous_balance) / previous_balance) * 100;
					}

					if (((current_balance > balance_inicial && perfomance_mes_anterior >= this.$props.performance_month_by_month) || (current_balance === 0)) && 
						(current_month_perfomance >= this.$props.performance_month_by_month)
					) {
						total_operation_winnings += 1;
					}
				}
				let success_rate_operations = ((total_operation_winnings * 100) / keys.length);
				return (total_operation_winnings === keys.length || success_rate_operations >= this.$props.success_rate) && total_operations > 0; 
			},
			addEventSeach() {
        const myInput = document.getElementById("filterCondition");
        if (!myInput) {
          setTimeout(() => {
            this.addEventSeach();
          }, 500);
        } else {
          myInput.addEventListener("keyup", this.$debounce(this.applyFilter));
        }
      },
      applyFilter() {
        this.filterResumen = this.filterCondition;
      },
		},
		filters: {
			handleEditMonthlyYield(data, balance_inicial) {
				let keys = Object.keys(data);
				let total_p = 0;
				for (let i = 0; i < keys.length; i++) {
					let mes_anterior = 0;
					if (i == 0) {
          	mes_anterior = ((data[keys[i]].balance - balance_inicial) / balance_inicial) * 100;
					} else {
          	mes_anterior = ((data[keys[i]].balance - data[keys[i - 1]].balance) / data[keys[i - 1]].balance) * 100;
					}

					if (((data[keys[i]].balance > balance_inicial && mes_anterior >= 0) || (data[keys[i]].balance === 0)) && data[keys[i]].perfomance >= 0) {
						total_p += 1;
					}
				}
				return `${total_p}/${keys.length}`;
			},
			handleEditMonthlyYieldPercentaje(data, balance_inicial) {
				let keys = Object.keys(data);
				let total_p = 0;
				for (let i = 0; i < keys.length; i++) {
					let mes_anterior = 0;
					if (i == 0) {
          	mes_anterior = ((data[keys[i]].balance - balance_inicial) / balance_inicial) * 100;
					} else {
          	mes_anterior = ((data[keys[i]].balance - data[keys[i - 1]].balance) / data[keys[i - 1]].balance) * 100;
					}
					if (((data[keys[i]].balance > balance_inicial && mes_anterior >= 0) || (data[keys[i]].balance === 0)) && data[keys[i]].perfomance >= 0) {
						total_p += 1;
					}
				}
				return `${((total_p * 100) / keys.length).toFixed(2)}%`;
			}
		},
		watch: {
			backtest_token(value) {
				if (value) {
					this.getConditions();
				}
			},
			success_rate(value) {
				if (value > 100 || value < 0) {
					this.success_rate = 80;
				}
			},
			performance_month_by_month(value) {
				if (value > 100 || value < -100) {
					this.performance_month_by_month = 0;
				}
			}
		},
	}
</script>

<style>
	@media screen and (min-width: 992px) {
    .list-conditions-desktop {
     display: block;
    }
    .list-conditions-movil {
     display: none;
    }
  }
  @media screen and (max-width: 992px) {
    .list-conditions-desktop {
     display: none;
    }
    .list-conditions-movil {
     display: block;
    }
  }
  .item_cm-2 {
  	width: 130px;
  	padding: 0 17px;
  }
</style>