import state from "@/helpers/store/state";
import set from "@/helpers/store/set";

import connection_list from "./actions/connection_list";
import connection_list_preview from "./actions/connection_list_preview";
import connection_list_selector from "./actions/connection_list_selector";
import create_connection from "./actions/create_connection";
import update_connection from "./actions/update_connection";
import delete_connection from "./actions/delete_connection";

import connection_list_adapter from "../../services/adapters/common/adapter_list";
import create_connection_adapter from "../../services/adapters/common/adapter_data";
import form_connection_adapter from "../../services/adapters/form_connection";

import message_error_adapter from "../../services/adapters/common/adapter_message";

import message_success_adapter from "../../services/adapters/common/adapter_message";

import set_credentials from "./mutations/set_credentials";
import set_investor_token from "./mutations/set_investor_token";
import set_creation_form from "./mutations/set_creation_form";
import set_is_editing_form from "./mutations/set_is_editing_form";

export default {
  namespaced: true,
  state: () => ({
    connection_list: state(connection_list_adapter),
    connection_list_preview: state(connection_list_adapter),
    connection_list_selector: state(create_connection_adapter),
    create_connection: state(create_connection_adapter),
    delete_connection: state(message_success_adapter),
    form_connection: { ...form_connection_adapter },
    investor_token: null,
    update_connection: state(message_error_adapter),
    is_editing_form: false,
  }),
  actions: {
    connection_list,
    connection_list_preview,
    connection_list_selector,
    create_connection,
    update_connection,
    delete_connection,
  },
  mutations: {
    set,
    set_credentials,
    set_investor_token,
    set_creation_form,
    set_is_editing_form,
  },
};
