<template>
  <div class="investor-preview-component">
    <div class="title">Investor preview</div>
    <div class="preview-container">
      <InvestorInfo />
      <Transactions />
      <Connections />
      <Portfolio />
    </div>
  </div>
</template>

<script>
import Connections from "./Connections.vue";
import InvestorInfo from "./InvestorInfo.vue";
import Transactions from "./Transactions.vue";
import Portfolio from "./Portfolio.vue";

export default {
  name: "InvestorPreview",
  components: { Connections, InvestorInfo, Portfolio, Transactions },
};
</script>

<style lang="scss">
@import "../../../../styles/pages/investor_configuration/investor_preview.scss";
</style>
