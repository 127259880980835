import IndividualTokenService from "../../../services/invidual_token_uptime.service";
export default ({ commit, state }, { token, start_date, end_date }) => {
  commit("set", {
    _state: "invidual_token_uptime",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });

  const params = {
    token,
    from_date:start_date,
    to_date:end_date
  };
  IndividualTokenService({
    params,
    callback: ({ response }) => {
      commit("set", {
        _state: "invidual_token_uptime",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "invidual_token_uptime",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
