<template>
  <b-col cols="12" lg="2" class="p-0 sidebar__user_information nice-scroll">
    <div class="p-2" v-if="!$store.state.loadingContributor">
      <div class="separator">Basic information</div>

      <div class="item-data">
        <div class="label">Name:</div>
        <div class="description">
          {{
            $store.state.contributorInfo.info
              ? $store.state.contributorInfo.info.name
              : "--"
          }}
        </div>
      </div>

      <div class="item-data">
        <div class="label">Token:</div>
        <div
          class="description"
          @click="
            copyToken(
              $store.state.contributorInfo.info
                ? $store.state.contributorInfo.info.token_user
                : '---'
            )
          "
        >
          {{
            $store.state.contributorInfo.info
              ? $store.state.contributorInfo.info.token_user
              : "---"
          }}
        </div>
      </div>
      <div class="item-data">
        <div class="label">Balance initial:</div>

        <div class="description">
          {{
            $helpers.toParseNumber(
              $store.state.contributorInfo.symbol,
              "profit",
              $store.state.contributorInfo.balance_initial
            )
          }}
        </div>
      </div>
      <div class="item-data">
        <div class="label">Equity:</div>
        <div
          class="description mono"
          :class="[
            $store.state.contributorInfo.resumen.profit_percentage >= 0
              ? 'text-success'
              : 'text-danger',
          ]"
        >
          {{
            $helpers.toParseNumber(
              $store.state.contributorInfo.symbol,
              "profit",
              $store.state.contributorInfo.resumen.profit_net
            )
          }}
          <span
            class="badget-number"
            v-b-tooltip.hover
            title="Last month's performance"
          >
            {{
              $store.state.contributorInfo.resumen.profit_percentage.toFixed(2)
            }}%
          </span>
        </div>
      </div>
      <div class="item-data">
        <div class="label">Operation time:</div>
        <div class="description">
          {{
            $store.state.contributorInfo.info
              ? $store.state.contributorInfo.info.time_operation
              : "--"
          }}
        </div>
      </div>
      <div class="item-data">
        <div class="label">Initial Date:</div>
        <div class="description">
          {{
            $store.state.contributorInfo.info
              ? $store.state.contributorInfo.info.time_start
              : "--"
          }}
        </div>
      </div>

      <!-- Esto sirve para solo para el administrador
	    funciones adicionales -->
      <div
        class="d-block mt-2 admin-menu"
        v-if="$store.state.user.role_id && $store.state.user.role_id === 1"
      >
        <span
          class="mr-2"
          v-b-tooltip.hover
          title="Download report"
          v-if="loadingReport"
        >
          <b-icon icon="arrow-repeat" animation="spin-pulse"></b-icon>
        </span>
        <span v-b-tooltip.hover class="mr-2" title="View" @click="showAccount">
          <b-icon-eye></b-icon-eye>
        </span>
      </div>
    </div>

    <div class="p-2" v-if="$store.state.loadingContributor">
      <b-skeleton-table
        :rows="5"
        :columns="1"
        :table-props="{ striped: true }"
      ></b-skeleton-table>
    </div>
    <CreateAndEditContributor
      :investor_token="investor_token"
      :investor="{}"
      :investor_balances="
        $store.state.contributorInfo.balances
          ? $store.state.contributorInfo.balances
          : []
      "
      ref="CreateAndEditContributor"
    />
  </b-col>
</template>
<script>
import CreateAndEditContributor from "@/components/pages/investors/contributor/CreateAndEditContributor.vue";

export default {
  name: "CardInfo",
  components: {
    CreateAndEditContributor,
  },
  created() {},
  data() {
    return {
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
      <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
      </svg> Error`,
      investor_token: "",
      loadingReport: false,
      new_update: 0,
      on: false,
    };
  },
  methods: {
    copyToken(token) {
      this.$helpers.copyTextToClipboard(token, this);
    },
    showAccount() {
      let subinvestor = this.$store.state.contributorInfo.info;
      subinvestor.token = this.$store.state.contributorInfo.info.token_user;
      subinvestor.note = this.$store.state.contributorInfo.info.note;
      subinvestor.balances = this.$store.state.contributorInfo.balances;
      this.$refs.CreateAndEditContributor.setDataFormAndDisable(subinvestor);
      setTimeout(() => {
        this.$bvModal.show("modal-create-contributor");
      }, 250);
    },
    downloadReport(token) {
      const data = {
        token_user: token,
      };
      this.loadingReport = true;
      this.$http
        .server1("bwfs/admin/subinvestors/downloadreport", data)
        .then((res) => {
          this.loadingReport = false;
          if (res.error) {
            this.$toastr.error(res.message, `${this.error}`);

            return;
          }
          window.open(this.$http.url_api(true) + "/bwfs/static/" + res.message);
        })
        .catch((e) => {
          this.loadingReport = false;
          this.$toastr.error(e.message, `${this.error}`);
        });
    },
  },
  filters: {
    parseSeconds(value) {
      return value;
    },
  },
};
</script>
