import random_number from "../../../helpers/generators/random_number";
import model from "../models/algorithm";

export default () => {
  const num = random_number(1, 5);
  let algorithms = [];
  for (let i = 0; i < num; i++) {
    const algo = model();
    algorithms.push(algo);
  }

  return {
    data: algorithms,
  };
};
