import random_date from "../../../helpers/generators/random_date";
import random_number from "../../../helpers/generators/random_number";
import random_string from "../../../helpers/generators/random_string";

export default () => {
  const market_type = ["Funding", "Futures", "Margin", "Spot"];
  const names = ["Binance", "Bybit", "kucoin"];
  const label = ["Api key", "Secret key", "Input2"];

  const created_at = random_date("MM/DD/YYYY h:mm:ss");
  const deleted_at = random_date("MM/DD/YYYY h:mm:ss");

  let market_array = [];
  const num_symbols = random_number(1, 4);
  for (let i = 0; i < num_symbols; i++) {
    const market = market_type[random_number(0, market_type.length - 1)];

    market_array.push(market);
  }
  const market_types = market_array;

  const fields_config = [
    {
      label: label[random_number(0, label.length - 1)],
      name: "api_key",
      togglable: true,
    },
    {
      label: label[random_number(0, label.length - 1)],
      name: "secret_key",
      togglable: true,
    },
  ];
  const name = names[random_number(0, names.length - 1)];

  const token = random_string(10);
  const updated_at = random_date("MM/DD/YYYY h:mm:ss");

  return {
    created_at,
    deleted_at,
    fields_config,
    name,
    market_types,
    token,
    updated_at,
  };
};
