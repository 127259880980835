<template>
  <div class="col-lg-10">
     <TimeFramesMenu/>
      <div id="chart" style="background-color: white; color:black;height:500px; min-height: 500px;margin-top: 15px;">
         <apexchart type="line" height="500px" ref="chart" :options="chartOptions" :series="chart_data"></apexchart>
      </div> 
      <!-- <b-button class="ml-3 mt-2" @click="stop = !stop"> {{stop? 'Start real-time': 'stop real-time'}}</b-button> !-->
  </div>  
</template>

<script>
import handleSocket from '@/helpers/socket';
import TimeFramesMenu from './correlation-chart/TImeframesMenu.vue';
import moment from 'moment';



export default {
  name: 'CorrelationChart',
  components: {
    TimeFramesMenu
  },
  computed: {
    DateTimeRange() {
      const timeframe = this.$store.state.timeframe;
      const minute =  60000;
      const hour = minute*60
      if (this.$store.state.realtime_chart) return minute/2;
      if (timeframe === '1m') return minute;
      if (timeframe === '2m') return 2*minute;
      if (timeframe === '3m') return 3*minute;
      if (timeframe === '5m') return 5*minute;
      if (timeframe === '15m') return 15*minute;
      if (timeframe === '30m') return 30*minute;
      if (timeframe === '1h') return hour;
      if (timeframe === '2h') return 2*hour;
      if (timeframe === '4h') return 4*hour;
      if (timeframe === '6h') return 6*hour;
      if (timeframe === '8h') return 8*hour;
      if (timeframe === '12h') return 12*hour;
      if (timeframe === '1d') return 24*hour;
    },
    realtime_chart() {
      return this.$store.state.realtime_chart;
    }
  },
  data() {
    return {
      url_websocket: 'stream_ticker',
      stop: false,
      time: 0,
      max_range: 0,
      min_range: 0,
      // Data para apex Chart
      chart_data: [],
      chartOptions: {
        chart: {
          id: 'realtime',
          height: 750,
          type: 'line',
          background: '#fff',
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1000
            }
          },
          tooltip: {
            enabled: false,
            shared: false,
            followCursor: false,
          },
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          show: true,
          width: 2,
          dashArray: 0, 
        },
        title: {
          text: 'Correlation Symbols',
          align: 'left'
        },
        markers: {
          size: 0
        },
        xaxis: {
          tooltip: {
            enabled: false,
          },
          // categories: [],
          range: 30000,
          type: 'datetime',
        },
        yaxis: {
          tooltip: {
            enabled: false,
          },
          min: 0,
          max: 50,
          decimalsInFloat: 2,
          formatter: (value) => { return value.toFixed(2) },
        },
        legend: {
          show: true
        },
      },    
    };
  },
  methods: {
    sendConnect() {
      if (this.stop) {
        return
      };
      if (this.$route.name.toLowerCase() !== 'correlator') {
        return
      };
      let kucoinSymbols = this.$store.state.symbols_correlator.filter((s) => s.exchange === 'kucoin').map((s) => s.symbol);
      let binanceSymbols = this.$store.state.symbols_correlator.filter((s) => s.exchange === 'binance').map((s) => s.symbol);
      this.doSend(JSON.stringify(kucoinSymbols));
      this.doSend(JSON.stringify(binanceSymbols));
      this.doSend(this.$store.state.timeframe);
    },
    onOpen() {
      if (this.stop) {
        return
      };
      if (this.$route.name.toLowerCase() !== 'correlator') {
        return
      };
      if(this.$store.state.symbols_correlator.length > 0) {
        let interval = setInterval(() => {
          if (this.$store.state.symbols_correlator.length > 0) {
            clearInterval(interval);
              this.sendConnect();
            }
          }, 250);
      } else {
        this.sendConnect();
      }
    }, 
    onClose(evt) {
      this.writeToScreen("DISCONNECTED");
      if (this.stop) {
        return
      };
      if (this.$route.name.toLowerCase() !== 'correlator') {
        return
      };
    },
    onMessage(evt) {
      if (this.stop) {
        return;
      };
      if (this.$route.name.toLowerCase() !== 'correlator') {
        return;
      };
      if (this.chart_data.length >= 0 && this.chart_data[0] && this.chart_data[0].data.length >= 8000) {
        this.resetData();
      }
      if (evt.data) {
        let data = JSON.parse(evt.data)
        console.log(data);
        let names = [];
        let is_price = false;
        if (data) {

          if(data.binance || data.prices.binance) {
            let binanceNames = data.binance ? data.binance : data.prices.binance;
            for (const b in binanceNames) {
              names.push({
                name: `binance-${b}`,
                property: b,
                exchange: 'binance',
              });
            }
          }
          if(data.kucoin || data.prices.kucoin) {
            let kucoinNames = data.kucoin ? data.kucoin : data.prices.kucoin;
            for (const k in kucoinNames) {
              names.push({
                name: `kucoin-${k}`,
                property: k,
                exchange: 'kucoin'
              }); 
            }
          }
          console.log('data', data.bid_ticks)
          console.log('realtime', this.realtime_chart)
          if (!this.$store.state.realtime_chart && !data.bid_ticks) {
            // timeframes
            for (let i = 0; i < names.length; i++) {
              const index = this.chart_data.map((c) => c.name).indexOf(names[i].name);
              const value = data[names[i].exchange][names[i].property]['correlation'];
              if (index > -1) {
                this.chart_data[index].data.push([data.time*1000, value]);
                is_price = true;
              }
            }
          } else if (this.$store.state.realtime_chart && data.bid_ticks) {
            // bid ticks
            for (let i = 0; i < names.length; i++) {
              let name = names[i];
              const value = data.prices[name.exchange][name.property]['correlation'];
              const index = this.chart_data.map((c) => c.name).indexOf(name.name);
              if (index > -1) {
                  this.chart_data[index].data.push([data.time*1000, value]);
                  is_price = true;
                }
              }
          }
          if (names.length > 0 && is_price) {
            let max_price = 0;
            let min_price = 0;
            for (let j = 0; j < this.chart_data.length; j ++) {
              const price = Math.max.apply(Math, this.chart_data[j].data.map(c => c[1]));
              const price_minimum = Math.min.apply(Math, this.chart_data[j].data.map(c => c[1]));
              if (j === 0) {
                max_price = price;
                min_price = price_minimum;
              }
              if (price > max_price) max_price = price; 
              if (min_price > price_minimum) min_price = price_minimum;  
            }

            this.chartOptions = {...this.chartOptions, ...{
              yaxis: {
                tooltip: {
                  enabled: true,
                },
                max: parseFloat((max_price * 1.01).toFixed(6)),
                min:  parseFloat((min_price * 0.99).toFixed(6)),
                decimalsInFloat: 8,
                formatter: (value) => { return value.toFixed(8) },
              }
            }}
          } 
          this.$refs.chart.updateSeries(this.chart_data);
          names = [];
        }
      }
    },
    onError(evt) {
      console.log(evt);
    },
    doSend(message) {
      this.websocket.send(message);
    },
    writeToScreen(message) {
      console.log("writeToScreen", message);
    },
    resetData() {
      //Cada 1500 elementos, se quita 1000 elementos para mantener depurada la data;
      for (let i = 0; i < this.chart_data.length; i++) {
        this.chart_data[i].data = this.chart_data[i].data.slice(3000);
      }
      this.$refs.chart.updateSeries(this.chart_data)
    },
  },
  beforeDestroy() {
    if (this.websocket) {
      this.websocket.close();
    }
  },
  watch: {
    '$store.state.add_symbol_correlator'(value) {
      if (value) {
        const symbols = this.$store.state.symbols_correlator;
        for (let i = 0; i < symbols.length; i++) {
          let symbol = symbols[i].symbol;
          if (symbols[i].exchange === 'kucoin') symbol = symbol.split('-').join('')
          const filter = this.chart_data.filter ((d) => d.name === `${symbols[i].exchange}-${symbol}`);
          if (filter.length === 0) this.chart_data.push({
            name: `${symbols[i].exchange}-${symbol}`,
            data: [],
          })
        }
        this.$store.commit('set_add_symbol_correlator', false);
      }
    },
    '$store.state.remove_symbol_correlator'(value) {
      if(value) {
        const symbols = this.$store.state.symbols_correlator;
        console.log('symbols', symbols);
        let index = 0;
        for (let i = 0; i < this.chart_data.length; i++) {
          const filter = symbols.filter ((s) => {
            let symbol = s.symbol;
            console.log('simp', symbol)
            if (s.exchange === 'kucoin') symbol = s.symbol.split('-').join('')
            console.log(symbol);
            return `${s.exchange}-${symbol}` === `${this.chart_data[i].name}`
          });


          if (filter.length === 0) {
            index = i;
          }
        }
        if (index === 0) {
          this.chart_data.shift();
        } else {
          this.chart_data.splice(index);
        }
        this.$refs.chart.updateSeries(this.chart_data);
        this.$store.commit('set_remove_symbol_correlator', false);
      }
    },
    '$store.state.make_correlation'(value) {
      if (value) {
        if (this.$route.name.toLowerCase() == 'correlator') {
          this.websocket = handleSocket(this.url_websocket, this.onOpen, this.onClose, this.onMessage, this.onError);
        }
        var self = this;
      } else {
        if(this.websocket) {
          this.websocket.close();
          this.$refs.chart.updateSeries([]);
        }
      }
    },
    '$store.state.timeframe_clicked'(value) {
      if (value) {
        this.chartOptions.xaxis.range = this.DateTimeRange;
        this.$store.commit('set_timeframe_clicked', false);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
 ::v-deep .apexcharts-tooltip {
  color: #000000;
  display: none;
};
::v-deep .apexcharts-legend-text {
  color: #000000 !important; 
}
</style>