import number_formatter from "../../../helpers/formatters/number_formatter";
import random_float from "../../../helpers/generators/random_float";
import random_number from "../../../helpers/generators/random_number";

export default () => {
  const market_types = ["Funding", "Futures", "Margin", "Spot"];
  const symbols = [
    "USDT",
    "BNB",
    "ETH",
    "ADA",
    "BTC",
    "SOL",
    "XRP",
    "DOGE",
    "LTC",
    "UNI",
  ];

  const contracts = random_number(0, 100000);
  const current_price = random_number(0, 100000);
  const entry_price = random_number(0, 100000);
  const exposure = random_number(0, 100000);
  const exposure_percentage = random_number(0, 100);
  const market_type = market_types[random_number(0, market_types.length - 1)];
  const upnl = random_float(0, 1000000);
  const upnl_percentage = random_float(0, 100);
  const symbol = symbols[random_number(0, symbols.length - 1)];

  return {
    contracts,
    current_price,
    entry_price,
    exposure,
    exposure_percentage,
    market_type,
    upnl,
    upnl_percentage,
    symbol,
  };
};
