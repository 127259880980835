<template>
	<div>
	   <div class="p-2"
            v-if="
            ($store.state.info_investor.balances || $store.state.SubInvestorInfo.balances || $store.state.contributorInfo.balances) &&
            ( ($store.state.info_investor.info) || $store.state.SubInvestorInfo.info || $store.state.contributorInfo.info)">
          <TableBalancesInvestors
            :showUpdateBalance="false"
            :investorForm="{}"
            :balances="getBalances"
            :loading="false"
            :isShow="false"
            :showOptions="false"
            :title="showTitle? getTitle(): ''"
            @handleDeleteBalance="() => {}"
            @handleAddBalance="() => {}"
          />
        </div>
    <div class="p-2" v-if="$store.state.info_investor.balances_futures && showBalances($store.state.info_investor.info.investor, 'futures') && $store.state.info_investor.info.investor.is_real == 1">
      <TableBalancesInvestors
        :showUpdateBalance="false"
        :investorForm="{}"
        :balances="getBalances"
        :loading="false"
        :isShow="false"
        :showOptions="false"
        :title="'Balances Futures'"
        @handleDeleteBalance="() => {}"
        @handleAddBalance="() => {}"
      />
    </div>
    <div class="p-2" v-if="$store.state.info_investor.balances_spot && showBalances($store.state.info_investor.info.investor, 'spot') && $store.state.info_investor.info.investor.is_real == 1">
      <TableBalancesInvestors
        :showUpdateBalance="false"
        :investorForm="{}"
        :balances="$store.state.info_investor.balances_spot"
        :loading="false"
        :isShow="false"
        :showOptions="false"
        :title="'Balances Spot'"
        @handleDeleteBalance="() => {}"
        @handleAddBalance="() => {}"
      />
    </div>
	</div>
</template>
<script>
	import TableBalancesInvestors from '@/components/pages/investors/TableBalancesInvestors';

	export default {
		name: 'BalancesDetailInvestors',
        props: {
            showTitle: {
                required: false,
                type: Boolean,
                default: true,
            }
        },
		components: {
			TableBalancesInvestors,
		},
        computed: {
            getBalances() {
                if (this.$route.name.toLowerCase() == 'investor') {
                    return this.$store.state.info_investor.balances;
                } else if (this.$route.name.toLowerCase() == 'subinvestor') {
                    return this.$store.state.SubInvestorInfo.balances;
                } else if (this.$route.name.toLowerCase() == 'contributor') {
                    return this.$store.state.contributorInfo.balances;
                }
            }
        },
		methods: {
			showBalances(investor, type) {
    	      if (investor.configurations == '' || investor.configurations == '{}') {
    	        return false;
    	      }
    	      let configurations = JSON.parse(investor.configurations);
    	      return configurations.market_type == type;
    	    },
            getTitle() {
                if (this.$route.name.toLowerCase() == 'investor') {
                    let configuration = JSON.parse(this.$store.state.info_investor.info.investor.configurations);
                    if (configuration.market_type == 'futures') {
                        return 'Balances Futures';
                    }
                }
                return 'Balances'
            }
		}
	}
</script>