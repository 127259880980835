<template>
	<div class="p-2">
		<div class="separator d-flex flex-row">ORDERS OPENED RESUME</div>
            <div class="table-responsive px-1 mb-2 mt-3">
              	<b-table 
	                id="table"
	                :items="open_orders" 
	                :fields="fields" 
	                striped
	                hover
	                small
	                borderless
	                :busy="loading_open_orders"
	                v-if="(open_orders.length > 0 && !loading_open_orders ) || loading_open_orders"
              	>

	                <template #cell(symbol)="row">
	                    ({{row.item.type}})  {{row.item.symbol.toUpperCase()}}
	                </template>

	                <template #cell(entry_price)="row">
	                    {{row.item.entry_price ? $helpers.numberFormat(row.item.entry_price) : 0}}
	                </template>

	                <template #cell(exposure)="row">
	                   {{row.item.exposure ? $helpers.numberFormat(row.item.exposure) : 0}} ({{row.item.exposure_percentage}} %)
	                </template>

	                <template #cell(pnl)="row">
	                  <span>
	                    {{row.item.pnl ? $helpers.numberFormat(row.item.pnl) : '0'}}
	                  </span>
	                </template>
	                

	                <template #table-busy>
		                <b-skeleton-table
		                    :rows="4"
		                    :columns="4"
		                    :table-props="{  striped: true }"
		                  ></b-skeleton-table>
	                </template>
              	</b-table>
              	<b-alert class="my-2" show variant="dark" v-if="open_orders.length === 0 && !loading_open_orders">
                	<span> No found orders. </span>
              	</b-alert>
            </div>
          </div>
</template>

<script>
	export default {
		name: 'OpenOrdersTable',
		props: {

		},
		created() {
		},
		data() {
			return {
				loading_open_orders: false,
				first_load: true,
				open_orders: [],
				fields: [
					{
						key: 'symbol',
						label: 'Sy.',
						thStyle: { width: "30%" },
					},
					{
						key: 'exposure',
						label: 'Exp. (%)',
						thStyle: { width: "30%" },
					},
					{
						key: 'entry_price',
						label: 'Entry P.',
						thStyle: { width: "20%" },
					},
					{
						key: 'pnl',
						label: 'PnL',
						thStyle: { width: "20%" },
					},
				],
				investor_token: null
			}
		},
		methods: {
			get_open_orders() {
				if (this.loading_open_orders) {
			        return;
			     }
		      	let data = {
		        	investor_token: this.investor_token,
		        	filter: "",
		      	}

		      	if (this.$store.state.algorithm_token_selected) {
          			let condition_token = this.$store.state.algorithm_token_selected;
          			data.algorithm_token = condition_token;
        		}

		      	this.loading_open_orders = true;
		      	this.$http.server1('bwfs/admin/investors/open_orders/resume', data).then(res => {
		        	this.loading_open_orders = false;
		        	if (res.error) {
		          		return;
		        	}
		        	this.open_orders = res.data;

		        	if (this.first_load) {
		        		this.first_load = false;
		        	}
		      	}).catch(e => {
		        	this.loading_open_orders = false;
		      	})
			}
		},
		watch: {
			'$store.state.info_investor'(value) {
				if (!this.first_load) {
    				return
    			}

      			if (value.info) {
      				this.investor_token = value.info.token;
			       	this.get_open_orders();
			    }
    		},
    		'$store.state.algorithm_token_selected'(value) {
	          	this.get_open_orders();
	      	},
		}
	}
	
</script>

<style type="text/css" scoped>
	.table {
		width: 160%;
	}

</style>