export const toolTipDirective = {
  bind(el, binding) {
    // Append "*" to the text
    const star = document.createElement("span");
    star.innerHTML = `<img style="width:10px" src="common/info-icon.svg" alt="SVG" />`;
    star.className = "hover-star";
    star.style.cursor = "pointer";
    star.style.marginLeft = "5px";
    el.appendChild(star);

    // Create and append tooltip modal
    const tooltipModal = document.createElement("div");
    tooltipModal.className = "tooltip-modal";
    tooltipModal.innerHTML =
      '<div class="tooltip-content">' + binding.value + "</div>";
    document.body.appendChild(tooltipModal);
    // Set initial styles for the tooltip modal
    tooltipModal.style.display = "none";
    tooltipModal.style.position = "absolute";
    tooltipModal.style.backgroundColor = "#000";
    tooltipModal.style.border = "1px solid #ccc";
    tooltipModal.style.padding = "10px";
    tooltipModal.style.zIndex = "1000";

    // Show tooltip modal on hover
    star.addEventListener("mouseover", (event) => {
      tooltipModal.style.display = "block";
      const tooltipWidth = tooltipModal.offsetWidth;
      // Set tooltip position based on mouse coordinates
      tooltipModal.style.top = `${
        event.pageY - tooltipModal.offsetHeight - 5
      }px`; // Adjust the offset as needed
      tooltipModal.style.left = `${event.pageX - tooltipWidth / 2}px`;
      console.log("top", tooltipWidth);

    });

    star.addEventListener("mouseout", () => {
      tooltipModal.style.display = "none";
    });
    // Hide tooltip modal on mouseout
  },
};
