import random_number from "../../../helpers/generators/random_number";
import random_string from "../../../helpers/generators/random_string";
import number_formatter from "../../../helpers/formatters/number_formatter";

export default () => {
  const names = ["Neo LMDD", "LMDD", "BWFS"];
  const name = names[random_number(0, names.length - 1)];
  const allocation = number_formatter(random_number(1000, 5000), 2);
  const allocation_percentage = number_formatter(random_number(1000, 5000), 2);
  const exposure = number_formatter(random_number(100, 500), 2);
  const exposure_percentage = number_formatter(random_number(0, 100), 2);
  const condition_token = random_string(30);
  const investor_token = random_string(30);
  const is_on = false;

  return {
    name,
    allocation,
    allocation_percentage,
    exposure,
    exposure_percentage,
    condition_token,
    investor_token,
    is_on,
  };
};
