<template>
  <span  v-b-tooltip.hover class="turn-button"  style="font-size: 1.5rem;">
    <b-icon icon="toggle-off" variant="danger"></b-icon>
  </span>
</template>

<script>
export default {
  name: 'toggle-off-danger'
}
</script>

<style scoped>
::v-deep svg {
  fill: #f82b2b;
}
</style>