<template>
	<span v-b-tooltip.hover title="Show json order" class="pointer" @click="showModal">
    	<b-icon-braces></b-icon-braces>
    	<b-modal :id="`${unique}_json_orders_child_blended_${this.$props.order_token}`" title="Json order blended" size="xl">
			<div class="separator d-flex flex-row">LOGS</div>

    		<ViewJson :jsonData="logs" filter="" />
    		<template #modal-footer="{ ok, cancel, hide }">
		        <b-button size="sm" variant="success" @click="hideModal">
		          Ok
		        </b-button>
		    </template>
    	</b-modal>
  	</span>
</template>

<script >
  	import ViewJson from '@/components/commons/ViewJson';

	export default {
		name: "JsonOrderChildBlended",
		components: {
      		ViewJson,
		},
		props: {
			logs: {
				type: Object,
				required: true
			},
			order_token: {
				required: true,
			},
		},
		created() {
			this.unique = new Date().getTime()
		},
		data() {
			return {
		        unique: 0,
		        filter: ''
			}
		},
		methods: {
			hideModal() {
				this.$bvModal.hide(`${this.unique}_json_orders_child_blended_${this.$props.order_token}`)
			},
			showModal(){
				this.$bvModal.show(`${this.unique}_json_orders_child_blended_${this.$props.order_token}`)
			}
		}
	}
</script>