<template>
    <div :class="`component_backtest_resume`">
      <label class="text-muted d-block mb-2 mt-4">Balance sheet performance and behavior</label>
		    <div class="card-bwfs">
          <div class="row">
            <div class="col-md-12" v-if="show && !isLoading">
              <apexchart 
                width="100%" 
                height="200px" 
                type="line"  
                :options="chart__performance_options" 
                :series="chart__performance_series">
              </apexchart>    
            </div>
            <div class="col-md-12" v-else>
              <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
            </div>
          </div>
        </div>

      <label class="text-muted d-block mb-2 mt-4">Information resulting from the condition</label>
      <div class="card-bwfs resume_info">
        <div class="row">
          <div class="col-md-2 text-center px-0">
            <div class="d-block pt-1 pb-1 item">
              <span class="d-block mono" :class="[$store.state.backtest_condition_resumen.profit_net >= 0 ? 'text-success' : 'text-danger']">
                {{$helpers.toParseNumber($store.state.backtest_detail.symbol, 'balance_inicial', $store.state.backtest_condition_resumen.profit_net)}} ({{$store.state.backtest_condition_resumen.total_operation}})
              </span>
              <span class="d-block small text-muted mt-0">
                Profits
              </span>
            </div>
          </div>
          <div class="col-md-2 text-center px-0">
            <div class="d-block pt-1 pb-1 item">
              <span class="d-block mono">
                {{$helpers.toParseNumber($store.state.backtest_detail.symbol, 'profit_losses', $store.state.backtest_condition_resumen.maximum_profit_losses)}} ({{$store.state.backtest_condition_resumen.maximum_consecutive_losses}})
              </span>
              <span class="d-block small text-muted mt-0">
                Maximum consecutive losses
              </span>
            </div>
          </div>
          <div class="col-md-2 text-center px-0">
            <div class="d-block pt-1 pb-1 item">
              <span class="d-block mono">
                {{$helpers.toParseNumber($store.state.backtest_detail.symbol, 'profit_winning', $store.state.backtest_condition_resumen.maximum_profit_winnings)}} ({{$store.state.backtest_condition_resumen.maximum_consecutive_winnings}})
              </span>
              <span class="d-block small text-muted mt-0">
                Maximum consecutive winnings
              </span>
            </div>
          </div>
          <div class="col-md-2 text-center px-0">
            <div class="d-block pt-1 pb-1 item">
              <span class="d-block mono">
                {{$helpers.toParseNumber($store.state.backtest_detail.symbol, 'total_commission', $store.state.backtest_condition_resumen.total_commission)}} ({{$store.state.backtest_condition_resumen.total_operation}})
              </span>
              <span class="d-block small text-muted mt-0">
                Total commissions paid
              </span>
            </div>
          </div>
          <div class="col-md-2 text-center px-0">
            <div class="d-block pt-1 pb-1 item">
              <span class="d-block mono">
                {{$helpers.toParseNumber($store.state.backtest_detail.symbol, 'profit_winning', $store.state.backtest_condition_resumen.profit_winning)}} ({{$store.state.backtest_condition_resumen.total_operation_winnings}})
              </span>
              <span class="d-block small text-muted mt-0">
                Net earnings
              </span>
            </div>
          </div>
          <div class="col-md-2 text-center px-0">
            <div class="d-block pt-1 pb-1 item">
              <span class="d-block mono">
                {{$helpers.toParseNumber($store.state.backtest_detail.symbol, 'profit_losses', $store.state.backtest_condition_resumen.profit_losses)}} ({{$store.state.backtest_condition_resumen.total_operation_losses}})
              </span>
              <span class="d-block small text-muted mt-0">
                Net losses
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
<script>
  export default {
    name: 'Resument',
    components: {
    },
    data() {
      let vm = this;
      return {
        chart__performance_options: {
          tooltip: {
            borderRadius: 0,
            x: {
              format: 'dd/MM/yy HH:mm'
            },
            custom: function({series, seriesIndex, dataPointIndex, w}) {
              let class_ccs = 'text-success';
              let class_ccs_ppb = 'text-success';
              let profit_percentage = '';

              if (vm.$store.state.history_balances[dataPointIndex].profit_percentage < 0) {
                class_ccs = 'text-danger';
              }

              if (vm.$store.state.history_balances[dataPointIndex].profit_percentage_balance < 0) {
                class_ccs_ppb = 'text-danger';
              }

              if (vm.$store.state.history_balances[dataPointIndex].profit_percentage) {
                profit_percentage = vm.$store.state.history_balances[dataPointIndex].profit_percentage.toFixed(2);
              }
              return `
                <div style="background: #232429;" class="p-2">
                  <span class="d-block small">
                  Token: <span class="text-muted">${vm.$store.state.history_balances[dataPointIndex].token}</span>
                  </span>
                  <span class="d-block small">
                  Date: <span class="text-muted">${vm.$store.state.history_balances[dataPointIndex].close_date_at}</span>
                  </span>
                  <span class="d-block small">
                  Profits: <span class="${class_ccs}">${vm.$helpers.toParseNumber(vm.$store.state.backtest_detail.symbol, 'profit', vm.$store.state.history_balances[dataPointIndex].profit_coin)} (${profit_percentage}%)</span>
                  </span>
                  <span class="d-block small">
                  Balance: <span class="mono">${vm.$helpers.toParseNumber(vm.$store.state.backtest_detail.symbol, 'profit', vm.$store.state.history_balances[dataPointIndex].accum)}</span>
                  </span>
                  <span class="d-block small">
                    performance: <span class="mono ${class_ccs_ppb}">${vm.$store.state.history_balances[dataPointIndex].profit_percentage_balance.toFixed(2)}%</span>
                  </span>
                </div>
              `;
            }
          },
          chart: {
            animations: {
              enabled: false,
            },
            toolbar: {
              show: false
            },
          },
          grid: {
            show: false,
            padding: {
              left: -10,
              right: 0
            }
          },
          annotations: {
            xaxis: [],
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            colors: ['#50E7FF'],
            width: 2,
            dashArray: 0,      
            curve: 'straight'
          },
          xaxis: {
            type: 'datetime',
            categories: [],
            labels: {
              show: false
            },
            tooltip: {
              enabled: false,
            },
            axisBorder: {
              show: false,
              color: '#50E7FF',
            },
            axisTicks: {
              show: false,
              color: '#50E7FF',
            },
          },
          yaxis: {
            tooltip: {
              enabled: false
            },
            labels: {
              enabled: false,
              show: false,
              style: {
              colors: ['#50E7FF'],
              }
            },
            axisBorder: {
              show: false,
              color: '#50E7FF',
            },
            axisTicks: {
              show: false,
              color: '#50E7FF',
            },
          },
        },
        chart__performance_series: [
          {
            name: 'series-1',
            data: [],
          },
        ],
        show: false,
        isLoading: false,
      }
    },
    methods: {
      handleData() {
     
        if (this.$store.state.history_balances.length === 0) {
          return;
        }
        this.isLoading = true;
        this.show = false;
        let data       = [];
        let x          = [];
        let annotentiosn = []
        let _temp = {}

        let index = 1;
        this.$store.state.history_balances.map((elem) => {
          data.push(elem.accum);
          x.push(index);
          index++;
          return elem;
        });
        this.chart__performance_options.xaxis.categories = x;
        this.chart__performance_series[0].data = data;
        setTimeout(() => {
          this.show = true;
          this.isLoading = false;
        }, 3000);
      },
    },
    watch: {
      '$store.state.history_balances'() {
        this.show = false;
        this.handleData();
      },
    },
  }
  </script>
  