const audit = [
  {
    title: "(Spot) Balances",
    url: "bwfs/v2/admin/investors/get_balances_by_market_type",
    url_subinvestor: "bwfs/v2/admin/investors/get_balances_by_market_type",
    method: "GET",
    params: { market_type: "spot" },
    fields: [],
  },
  {
    title: "(Spot) Closed Orders",
    url: "bwfs/v2/admin/get_closed_orders",
    params: { market_type: "spot" },
    fields: [],
  },
  {
    title: "(Spot) Open Orders",
    url: "bwfs/v2/admin/get_open_orders",
    params: { market_type: "spot" },
    fields: [],
  },
  {
    title: "(Spot) Trades",
    url: "bwfs/v2/admin/get_trades",
    url_subinvestor: "bwfs/v2/admin/get_trades",
    params: { market_type: "spot" },
    fields: [],
  },
  {
    title: "(Futures) Account",
    url: "bwfs/v2/admin/get_accounts",
    url_subinvestor: "bwfs/v2/admin/get_accounts",
    method: "GET",
    fields: [],
  },
  {
    title: "(Futures) Balances",
    url: "bwfs/v2/admin/investors/get_balances_by_market_type",
    url_subinvestor: "bwfs/v2/admin/investors/get_balances_by_market_type",
    method: "GET",
    params: { market_type: "future" },
    fields: ["asset", "balance"],
  },
  {
    title: "(Futures) Open Orders",
    url: "bwfs/v2/admin/get_open_orders",
    params: { market_type: "future" },
    fields: [],
  },
  {
    title: "(Futures) Closed Orders",
    url: "bwfs/v2/admin/get_closed_orders",
    params: { market_type: "future" },
    fields: [
      "orderId",
      "symbol",
      "status",
      "clientOrderId",
      "price",
      "avgPrice",
      "origQty",
      "executedQty",
      "cumQuote",
      "timeInForce",
      "type",
      "reduceOnly",
      "closePosition",
      "side",
      "origType",
      "positionSide",
      "priceProtect",
      "stopPrice",
      { key: "time", class: "time" },
      { key: "updateTime", class: "time" },
      "workingType",
    ],
  },
  {
    title: "Withdrawals",
    url: "bwfs/v2/admin/investors/get_account_withdrawals",
    method: "GET",
    fields: [],
  },
  {
    title: "Deposits",
    url: "bwfs/v2/admin/investors/get_account_deposits",
    method: "GET",
    fields: [],
  },
  {
    title: "Position Risk",
    url: "bwfs/v2/admin/investors/audit/position_risk",
    method: "GET",
    fields: [],
  },
];

export default audit;
