import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Helpers from "@/helpers/commons.js";
import Request from "@/helpers/request.js";
import Crypto from "@/helpers/crypto.js";
import Socket from "@/helpers/socket.js";
import { toolTipDirective } from "@/helpers/directives/toolTipDirective.js";
import toastr from "toastr";
import VueEasytable from "vue-easytable";

import { VePagination } from "vue-easytable";
import "vue-easytable/libs/theme-default/index.css";

import {
  BootstrapVue,
  IconsPlugin,
  LayoutPlugin,
  ButtonPlugin,
  AlertPlugin,
  CalendarPlugin,
  DropdownPlugin,
  FormPlugin,
  SpinnerPlugin,
  TooltipPlugin,
  ProgressPlugin,
} from "bootstrap-vue";

import VueApexCharts from "vue-apexcharts";
import VueCookies from "vue-cookies";
import Vuelidate from "vuelidate";
import VueSocketIO from "vue-socket.io";

Vue.config.productionTip = false;


Vue.use(Vuelidate);
Vue.use(VueCookies);
Vue.$cookies.config("31d");
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(LayoutPlugin);
Vue.use(ButtonPlugin);
Vue.use(AlertPlugin);
Vue.use(CalendarPlugin);
Vue.use(DropdownPlugin);
Vue.use(FormPlugin);
Vue.use(SpinnerPlugin);
Vue.use(TooltipPlugin);
Vue.use(ProgressPlugin);
Vue.use(VueEasytable);
Vue.use(VePagination);
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

const debounce = (callback, delay = 1500) => {
  let timeout;
  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
};

const close_toast = `<button><svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.2499 4.24989C11.1327 4.13272 10.9737 4.06689 10.808 4.06689C10.6423 4.06689 10.4833 4.13272 10.3661 4.24989L7.49989 7.11614L4.63364 4.24989C4.51643 4.13272 4.35749 4.06689 4.19176 4.06689C4.02603 4.06689 3.86709 4.13272 3.74989 4.24989C3.63272 4.36709 3.56689 4.52603 3.56689 4.69176C3.56689 4.85749 3.63272 5.01643 3.74989 5.13364L6.61614 7.99989L3.74989 10.8661C3.63272 10.9833 3.56689 11.1423 3.56689 11.308C3.56689 11.4737 3.63272 11.6327 3.74989 11.7499C3.86709 11.8671 4.02603 11.9329 4.19176 11.9329C4.35749 11.9329 4.51643 11.8671 4.63364 11.7499L7.49989 8.88364L10.3661 11.7499C10.4833 11.8671 10.6423 11.9329 10.808 11.9329C10.9737 11.9329 11.1327 11.8671 11.2499 11.7499C11.3671 11.6327 11.4329 11.4737 11.4329 11.308C11.4329 11.1423 11.3671 10.9833 11.2499 10.8661L8.38364 7.99989L11.2499 5.13364C11.3671 5.01643 11.4329 4.85749 11.4329 4.69176C11.4329 4.52603 11.3671 4.36709 11.2499 4.24989Z" fill="#F5F5F5"/>
</svg></button>`;

// Prototypes
toastr.options.timeOut = 2000;
toastr.options.extendedTimeOut = 2000;
toastr.options.timeOut = 2000;
toastr.options.extendedTimeOut = 2000;
toastr.options.closeHtml = close_toast;
toastr.options.escapeHtml = false;
toastr.options.positionClass = "toast-bottom-right";
toastr.options.closeButton = true;

Vue.prototype.$helpers = Helpers;
Vue.prototype.$http = Request;
Vue.prototype.$toastr = toastr;
Vue.prototype.$crypto = Crypto;
Vue.prototype.$debounce = debounce;
Vue.prototype.$socket = Socket;

Vue.directive("tooltip", toolTipDirective);


// Extends
Request._vm = Vue;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
