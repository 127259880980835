var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"modal-add-condition-v3","hide-footer":"","hide-header":"","size":"xl","ok-only":""}},[_c('div',{staticClass:"modal-create-conditions"},[_c('h3',{staticClass:"modal-title d-flex justify-content-between"},[_c('span',[_vm._v(" Condition generator aa ")]),(_vm.isCompound && !_vm.show)?_c('div',[_c('a',{staticClass:"pr-2",staticStyle:{"font-size":"13px","color":"#fff"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.activeTabCondition('buy')}}},[_c('span',{staticClass:"btn btn-dark small",style:({
              background: _vm.activeBuy ? '#11131b' : '#232429',
              fontWeight: '400',
            })},[_vm._v(" Condition #1 ")])]),_c('a',{staticStyle:{"font-size":"13px","color":"#fff"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.activeTabCondition('sell')}}},[_c('span',{staticClass:"btn btn-dark small",style:({
              background: _vm.activeSell ? '#11131b' : '#232429',
              fontWeight: '400',
            })},[_vm._v(" Condition #2 ")])])]):_vm._e()]),_c('ConditionGenerator',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeBuy),expression:"activeBuy"}],ref:"conditionGeneratorBuy",attrs:{"isCompound":_vm.isCompound,"backtest":_vm.investorForm,"is_investor":true,"showSignal":_vm.showSignal,"showBaseConfiguration":_vm.showBaseConfiguration},on:{"addCondition":_vm.handleAddCondition,"handleAddModify":_vm.handleAddModify,"handleCompound":_vm.handleCompoundCondition,"handlePriority":_vm.handlePriority,"handleAddCompound":_vm.handleAddCompoundCondition,"handleSetType":_vm.handletSeType,"handleBaseConfiguration":_vm.handleBaseConfiguration,"setConditionPasteSell":_vm.setConditionPasteSell}}),_c('ConditionGenerator',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeSell),expression:"activeSell"}],ref:"conditionGeneratorSell",attrs:{"tabSell":true,"isCompound":_vm.isCompound,"valueCompound":_vm.is_compound,"priority":_vm.priority,"type":_vm.currentTypeCondition === 'buy' ? 'sell' : 'buy',"backtest":_vm.investorForm,"is_investor":true,"showSignal":_vm.showSignal,"showBaseConfiguration":_vm.showBaseConfiguration},on:{"addCondition":_vm.handleAddCondition,"handleAddModify":_vm.handleAddModify,"handleCompound":_vm.handleCompoundCondition,"handleAddCompound":_vm.handleAddCompoundCondition}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }