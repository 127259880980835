<template>
  <b-col cols="12" lg="2" class="p-0 admin_sidebar nice-scroll">
    <div class="d-block p-2 mb-2">
      <div class="separator">Supervisors filtering</div>
      <div class="card p-2 mb-2 text-muted small" v-if="!$store.state.supervisors_loading">
        Applied Filters <br> 
        <span v-if="search">
          {{ "Search: "+search }} <br> 
        </span>
        <span>
            {{ "Status: "+ showStatus }} <br>
        </span>
      </div>
        <div class="card p-2 mb-2" v-if="search && $store.state.supervisors_loading">
          <b-list-group>
            <b-list-group-item>
              <div class="d-block">
                <b-skeleton animation="wave" width="75%"></b-skeleton>
                <b-skeleton animation="wave" width="10%"></b-skeleton>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
        <div class="card p-2 mb-2" v-if="!$store.state.supervisors_loading">
          <b-form-group label="Search">
            <b-input-group size="sm">
              <b-form-input
                id="searchSupervisor"
                type="search"
                placeholder="Type to Search"
                v-model="search"
                @change="searchingSupervisor"
              ></b-form-input>
            </b-input-group>
                <span class="text-muted small mt-1">
                  Search by token, email or investors...
                </span>
          </b-form-group>
        </div>
        <div class="card mb-2" v-if="$store.state.supervisors_loading">
          <b-card>
            <b-skeleton animation="wave" width="75%"></b-skeleton>
            <b-skeleton animation="wave" width="55%"></b-skeleton>
          </b-card>
        </div>
        <div class="card p-2 mb-2" v-if="!$store.state.supervisors_loading">
          <b-form-group label="Status">
            <select v-model="status" @change="searchingSupervisor" class="form-control">
              <option :value="2">All</option>
              <option :value="0">Active</option>
              <option :value="1">Inactive</option>
            </select>
          </b-form-group>
        </div>
        <div class="card mb-2" v-if="$store.state.supervisors_loading">
          <b-card>
            <b-skeleton animation="wave" width="75%"></b-skeleton>
            <b-skeleton animation="wave" width="55%"></b-skeleton>
          </b-card>
      </div>
    </div>
  </b-col>
</template>

<script>
export default {
  name: 'searchSupervisor',
  data() {
    return {
      statusOptions: [
        {text: 'All', value: 2},
        {text: 'Active', value: 1},
        {text: 'Inactive', value: 0},
      ],
    };
  },
  computed : {
    status: {
      get () {
        return this.$store.state.search_supervisor_status;
      },
      set(value) {
        this.$store.commit('set_search_supervisor_status', value);
      },
    },
    search: {
      get() {
        return this.$store.state.search_supervisor;
      },
      set(value) {
        this.$store.commit('set_search_supervisor', value);
      },
    },
    showStatus() {
      if(this.status === 2) {
        return 'All';
      }
      if (this.status === 0) {
        return 'Active';
      }
      if (this.status === 1) {
        return 'Inactive';
      }
    },
  },
  methods: {
    searchingSupervisor() {
      this.$store.dispatch('bwfs_get_supervisor', {
        vm: this,
        data: {
          page: 1,
          search: this.$store.state.search_supervisor,
          status: this.$store.state.search_supervisor_status,
        },
      });
    },
    addEventSearch() {
      const myInput = document.getElementById("searchSupervisor");
      if (!myInput) {
        setTimeout(() => {
          this.addEventSearch();
        }, 500);
      } else {
        myInput.addEventListener("keyup", this.$debounce(this.searchingSupervisor));
      }
    },
  },
  mounted() {
    this.addEventSearch();
  },
  watch: {
    '$store.state.supervisors_loading'(value) {
      if(!value) {
         this.addEventSearch();
      }
    },
  }
}
</script>

<style scoped>
.form-control, .custom-select{
  background-color: #0e1019 !important;
  color: #f5f5f5;
}
::v-deep .form-group{
  margin-bottom: 0.1rem;
}
</style>