import store from "../../store/index";

export default (to, from, next) => {
  if (!to.query.token) {
    next("/admin/investors");
  } else {
    store.commit("dashboard/set_investor_token", to.query.token);
    store.commit("connections/set_investor_token", to.query.token);
    store.commit("transactions/set_investor_token", to.query.token);
    store.commit("portfolios/set_investor_token", to.query.token);
    store.commit("investor_configuration/set_investor_token", to.query.token);
    next();
  }
};
