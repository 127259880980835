<template>
  <div class="settings-tab-notification" v-if="form_action == 0">
    <div class="tab-header">
      <span class="tab-title-icon"><img :src="settings" alt="" /></span>
      <div class="tab-title">Settings</div>
    </div>
    <div class="tab-content">
      <textarea
        placeholder="Enter your message"
        v-model="templateFormat"
      ></textarea>
      <KeySelector @variableSelected="addVariable" />
    </div>
  </div>
  <div class="settings-tab-request" v-else>
    <div class="tab-header">
      <span class="tab-title-icon"><img :src="request_icon" alt="" /></span>
      <div class="tab-title">Request</div>
    </div>
    <div class="tab-content">
      <div class="request-row">
        <div class="request-url">
          <Multiselect
            v-model="requestType"
            :clearOnSelect="true"
            :close-on-select="true"
            :hideSelected="true"
            :canClear="false"
            :options="http_types"
            label="name"
            valueProp="name"
          >
            <template v-slot:option="{ option }">
              <span :style="{ color: getColor(option.name) }">{{
                option.name
              }}</span>
            </template>
            <template v-slot:singlelabel="{ value }">
              <div
                class="multiselect-single-label"
                :style="{ color: getColor(value.name) }"
              >
                {{ value.name }}
              </div>
            </template>
            <template v-slot:caret>
              <span class="multiselect-caret">
                <img :src="icon_caret" alt="" />
              </span>
            </template>
          </Multiselect>
          <input type="text" placeholder="Enter URL or paste text" />
        </div>
        <button class="validate-button">Validate</button>
      </div>
      <div class="body-row">
        <div class="tabs-list">
          <div
            v-for="(tab, index) in tabs"
            :key="index"
            class="body-tab"
            :class="{ active: activeTab === index }"
            @click="setActiveTab(index)"
          >
            {{ tab }}
          </div>
        </div>
        <div class="key-values" v-if="activeTab == 0">
          <div class="grid-item header">Key</div>
          <div class="grid-item header">Value</div>
          <div class="grid-item">
            <div class="key-selector">
              <div class="key-text">Key</div>
              <div class="key-icon"><KeySelector :type="'two'" /></div>
            </div>
          </div>
          <div class="grid-item">
            <div class="key-selector">
              <div class="key-text">Value</div>
              <div class="key-icon"><KeySelector :type="'two'" /></div>
            </div>
          </div>
        </div>
        <div class="key-values" v-if="activeTab == 1">
          <div class="grid-item header">Key</div>
          <div class="grid-item header">Value</div>
          <div class="grid-item">Content-Type</div>
          <div class="grid-item">
            <Multiselect
              v-model="contentType"
              :clearOnSelect="true"
              :close-on-select="true"
              :hideSelected="true"
              :canClear="false"
              :options="content_types"
              class="content-type-selector"
              label="name"
              valueProp="name"
            >
              <template v-slot:caret>
                <span class="multiselect-caret">
                  <img :src="icon_caret" alt="" />
                </span>
              </template>
            </Multiselect>
          </div>
          <div class="grid-item">
            <div class="key-selector">
              <div class="key-text">Key</div>
              <div class="key-icon"><KeySelector :type="'two'" /></div>
            </div>
          </div>
          <div class="grid-item">
            <div class="key-selector">
              <div class="key-text">Value</div>
              <div class="key-icon"><KeySelector :type="'two'" /></div>
            </div>
          </div>
        </div>
        <div class="key-values" v-if="activeTab == 2">
          <div class="grid-item header">Key</div>
          <div class="grid-item header">Value</div>
          <div class="grid-item">
            <div class="key-selector">
              <div class="key-text">Key</div>
              <div class="key-icon"><KeySelector :type="'two'" /></div>
            </div>
          </div>
          <div class="grid-item">
            <div class="key-selector">
              <div class="key-text">Value</div>
              <div class="key-icon"><KeySelector :type="'two'" /></div>
            </div>
          </div>
        </div>
      </div>
      <div class="response-row">
        <div class="response-title">Validate response</div>
        <vue-json-pretty
          :data="responseJson"
          :showLineNumber="true"
          :showLine="false"
        >
          <template #nodeKey="{ node, defaultValue }">
            <span style="color: #ffffff80">{{ node.key }}</span>
          </template>
          <template #nodeValue="{ node, defaultValue }">
            <span style="color: #ffffff">{{ node.content }}</span>
          </template>
        </vue-json-pretty>
      </div>
    </div>
  </div>
</template>
<script>
import IconCaret from "../../../../../../../public/common/angle-down-icon.png";
import VariableIcon from "../../../../../../../public/common/variable-brace-icon.png";
import SettingsIcon from "../../../../../../../public/common/settings-sidebar.svg";
import RequestIcon from "../../../../../../../public/common/request-icon.png";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2";
import KeySelector from "./KeySelector.vue";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import { mapState } from "vuex";

export default {
  name: "SettingsTab",
  data() {
    return {
      settings: SettingsIcon,
      icon_caret: IconCaret,
      variable_icon: VariableIcon,
      request_icon: RequestIcon,
      showVariableList: false,
      activeTab: 0,
      tabs: ["Params", "Headers", "Body"],
      http_types: [
        { name: "GET" },
        { name: "POST" },
        { name: "PUT" },
        { name: "DELETE" },
      ],
      content_types: [
        { name: "application/json" },
        { name: "text/plain" },
        { name: "application/xml" },
        { name: "text/html" },
        { name: "application/pdf" },
        { name: "multipart/form-data" },
        { name: "application/x-www-form-urlencoded" },
      ],
      requestType: "GET",
      contentType: "application/json",
      responseJson: {
        "Request URL": "https://preproduction.bwfs.creedandbear.com/",
        "Request Method": "GET",
        "Status code": "200 OK",
        "Remote address": "207.154.205.234:443",
        "Referrer Policy": "strict-origin-when-cross-origin",
      },
      templateFormat: "",
    };
  },
  methods: {
    addVariable(variable) {
      this.templateFormat += `{${variable}}`;
    },
    setActiveTab(index) {
      this.activeTab = index;
    },
    getColor(name) {
      if (name == "GET") {
        return "#10A37F !important";
      } else if (name == "POST") {
        return "#ED871C !important";
      } else if (name == "PUT") {
        return "#74AEF6 !important";
      } else if (name == "DELETE") {
        return "#EE483C !important";
      }
    },
  },
  components: {
    Multiselect,
    KeySelector,
    VueJsonPretty,
  },
  computed: {
    ...mapState("investor_configuration", ["form_action"]),
  },
};
</script>

<style lang="scss">
@import "../../../../../../styles/pages/investor_configuration/settings_tab.scss";
</style>
