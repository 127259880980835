import model from "../models/exposure";

export default () => {
  let data = [];
  const exposure = model();
  data.push(exposure);
  return {
    data,
  };
};
