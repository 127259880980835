import number_formatter from "../../../helpers/formatters/number_formatter";
import random_float from "../../../helpers/generators/random_float";
import random_number from "../../../helpers/generators/random_number";
import random_string from "../../../helpers/generators/random_string";
import random_date from "../../../helpers/generators/random_date";

export default () => {
  const exchanges = ["Binance", "Bybit", "kucoin", "okx"];
  const names = [
    "Conenction 1",
    "Connection 2",
    "Connection 3",
    "Connection 4",
  ];

  const exchange = exchanges[random_number(0, exchanges.length - 1)];
  const leverage = random_number(0, 10);
  const name = names[random_number(0, names.length - 1)];
  const token = random_string(10);

  return {
    exchange,
    leverage,
    name,
    token,
  };
};
