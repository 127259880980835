<template>
<div>
	<label class="text-muted d-block mb-2">Chart</label>
	<div class="card-bwfs p-2" v-if="$store.state.candlestick_loading">
		<b-skeleton-img no-aspect height="200px"></b-skeleton-img>
	</div> 
	<div class="chart-wrapper card-bwfs p-2 mb-4" v-else>
	  <div id="container" class="chart"></div>	  
	</div>
</div>
</template>
<script>
	export default {
		name: 'CandlestickCharts',
		mounted() {
    	this.setChart();
    },
		created() {
    	const payload = {
    		vm: this,
    		data: {
    			symbol: this.$store.state.backtest_detail.symbol,
    			star_at: this.$store.state.backtest_detail.dates[0] == "[" ? JSON.parse(this.$store.state.backtest_detail.dates)[0] : this.$store.state.backtest_detail.dates[0],
    			end_at: this.$store.state.backtest_detail.dates[1] == '"' ? JSON.parse(this.$store.state.backtest_detail.dates)[1] : this.$store.state.backtest_detail.dates[1],
    		},
    	};
    	if (this.$store.state.backtest_detail.symbol) {
    		this.$store.commit('bwfs_get_candlestick', payload);
    	}
    },
    data() {
    	return {
	      show: false,
    	};
    },
		methods: {
			setChart() {
				this.show = false;
				if (this.$store.state.candlestick.length == 0) {
    			setTimeout(() => {
    				this.setChart();
    			},500);
    		} else {
					Highcharts.theme = {
						plotOptions: {
								candlestick: {
								color: 'red',
								upColor: 'green',
								lineColor: 'red',
								upLineColor: 'green', 
							}
						},
						navigator: {
							series: {
								color: '#000',
								lineWidth: 2
							}
						},
					};

					Highcharts.setOptions(Highcharts.theme);
			    var ohlc = this.$store.state.candlestick;
			    var dataLength = this.$store.state.candlestick.length;
			    var annotantions = this.$store.state.candlestick_anotations;
			    var annotantions_points = this.$store.state.candlestick_anotations_points;
			    const setTokenOrder = this.setTokenOrder
			    Highcharts.stockChart('container', {
						rangeSelector: {
							allButtonsEnabled: true,
							selected: 0,
						},
			    	navigationBindings: {
	            events: {
	                selectButton: function (event) {
	                    var newClassName = event.button.className + ' highcharts-active',
	                        topButton = event.button.parentNode.parentNode;
	                    if (topButton.classList.contains('right')) {
	                        newClassName += ' right';
	                    }
	                    // If this is a button with sub buttons,
	                    // change main icon to the current one:
	                    if (!topButton.classList.contains('highcharts-menu-wrapper')) {
	                        topButton.className = newClassName;
	                    }

	                    // Store info about active button:
	                    this.chart.activeButton = event.button;
	                },
	                deselectButton: function (event) {
	                    event.button.parentNode.parentNode.classList.remove('highcharts-active');

	                    // Remove info about active button:
	                    this.chart.activeButton = null;
	                },
	                showPopup: function (event) {

	                    if (!this.indicatorsPopupContainer) {
	                        this.indicatorsPopupContainer = document
	                            .getElementsByClassName('highcharts-popup-indicators')[4];
	                    }

	                    if (!this.annotationsPopupContainer) {
	                        this.annotationsPopupContainer = document
	                            .getElementsByClassName('highcharts-popup-annotations')[4];
	                    }

	                    if (event.formType === 'indicators') {
	                        this.indicatorsPopupContainer.style.display = 'block';
	                    } else if (event.formType === 'annotation-toolbar') {
	                        // If user is still adding an annotation, don't show popup:
	                        if (!this.chart.activeButton) {
	                            this.chart.currentAnnotation = event.annotation;
	                            this.annotationsPopupContainer.style.display = 'block';
	                        }
	                    }
	                },
	                closePopup: function () {
	                    this.indicatorsPopupContainer.style.display = 'none';
	                    this.annotationsPopupContainer.style.display = 'none';
	                }
	            }
						},
						stockTools: {
							gui: {
								enabled: false
							}
						},
						xAxis: {
	            title: {
	              text: 'Exchange rate'
	            },
	            plotLines: annotantions,
		        },
						series: [
							{
								type: 'candlestick',
								id: 'aapl-ohlc',
								name: 'Stock Price',
								data: ohlc
							}, 
							{
		            type: 'flags',
		            data: annotantions_points,
		            shape: 'squarepin',
		            onSeries: 'aapl-ohlc',
		            width: 16,
		            events: {
	                click: function (event) {
	                	setTokenOrder(parseInt(event.path[0].innerHTML));
	                }
		            }
			        }
						],
						responsive: {
							rules: [{
								condition: {
									maxWidth: 800
								},
								chartOptions: {
									rangeSelector: {
										inputEnabled: false
									}
								}
							}]
						}
			    });
					this.show = true;
    		}
			},
			setTokenOrder(index) {
				let condition = this.$store.state.backtest_conditions.filter((c) => c.condition.token === this.$store.state.condition_token_selected)[0];
      	let orders = condition.grafic; 
				this.$store.commit('bwfs_set_search_order', orders[index].token);
			},
		},
	};
	
</script>

<style>
	/* FULL SCREEN */

.chart:-webkit-full-screen {
    width: 100%;
    height: 100%;
}

.chart:-moz-full-screen {
    width: 100%;
    height: 100%;
}

.chart:-ms-fullscreen {
    width: 100%;
    height: 100%;
}

.chart:fullscreen {
    width: 100%;
    height: 100%;
}

/* GENERAL */

.chart {
    float: left;
    max-height: 800px;
    height: 75vh;
    position: relative;
    width: 100%;
}

.highcharts-draw-mode {
    cursor: crosshair;
}

.left {
    float: left;
}

.right,
.highcharts-stocktools-toolbar li.right {
    float: right;
}

/* GUI */

.highcharts-stocktools-toolbar {
    margin: 0px 0px 0px 10px;
    padding: 0px;
    width: calc(100% - 63px);
}

.highcharts-stocktools-toolbar li {
    background-color: #f7f7f7;
    background-repeat: no-repeat;
    cursor: pointer;
    float: left;
    height: 40px;
    list-style: none;
    margin-right: 2px;
    margin-bottom: 3px;
    padding: 0px;
    position: relative;
    width: auto;
}

.highcharts-stocktools-toolbar li ul {
    display: none;
    left: 0px;
    padding-left: 0px;
    position: absolute;
    z-index: 125;
}

.highcharts-stocktools-toolbar li:hover {
    background-color: #e6ebf5;
}

.highcharts-stocktools-toolbar li:hover ul {
    display: block;
}

.highcharts-stocktools-toolbar li ul li {
    margin-bottom: 0px;
    width: 160px;
}

.highcharts-stocktools-toolbar li>span.highcharts-menu-item-btn {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: block;
    float: left;
    height: 100%;
    width: 40px;
}

.highcharts-stocktools-toolbar li>.highcharts-menu-item-title {
    color: #666;
    line-height: 40px;
    font-size: 0.876em;
    padding: 0px 10px 0px 5px;
}

.highcharts-indicators>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/indicators.svg');
}

.highcharts-label-annotation>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/label.svg');
}

.highcharts-circle-annotation>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/circle.svg');
}

.highcharts-rectangle-annotation>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/rectangle.svg');
}

.highcharts-segment>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/segment.svg');
}

.highcharts-arrow-segment>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/arrow-segment.svg');
}

.highcharts-ray>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/ray.svg');
}

.highcharts-arrow-ray>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/arrow-ray.svg');
}

.highcharts-infinity-line>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/line.svg');
}

.highcharts-arrow-infinity-line>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/arrow-line.svg');
}

.highcharts-horizontal-line>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/horizontal-line.svg');
}

.highcharts-vertical-line>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/vertical-line.svg');
}

.highcharts-elliott3>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/elliott-3.svg');
}

.highcharts-elliott5>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/elliott-5.svg');
}

.highcharts-crooked3>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/crooked-3.svg');
}

.highcharts-crooked5>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/crooked-5.svg');
}

.highcharts-measure-xy>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/measure-xy.svg');
}

.highcharts-measure-x>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/measure-x.svg');
}

.highcharts-measure-y>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/measure-y.svg');
}

.highcharts-fibonacci>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/fibonacci.svg');
}

.highcharts-pitchfork>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/pitchfork.svg');
}

.highcharts-parallel-channel>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/parallel-channel.svg');
}

.highcharts-toggle-annotations>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/annotations-visible.svg');
}

.highcharts-vertical-counter>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/vertical-counter.svg');
}

.highcharts-vertical-label>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/vertical-label.svg');
}

.highcharts-vertical-arrow>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/vertical-arrow.svg');
}

.highcharts-vertical-double-arrow>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/vertical-double-arrow.svg');
}

.highcharts-flag-circlepin>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/flag-elipse.svg');
}

.highcharts-flag-diamondpin>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/flag-diamond.svg');
}

.highcharts-flag-squarepin>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/flag-trapeze.svg');
}

.highcharts-flag-simplepin>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/flag-basic.svg');
}

.highcharts-zoom-xy>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/zoom-xy.svg');
}

.highcharts-zoom-x>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/zoom-x.svg');
}

.highcharts-zoom-y>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/zoom-y.svg');
}

.highcharts-full-screen>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/fullscreen.svg');
}

.highcharts-series-type-ohlc>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/series-ohlc.svg');
}

.highcharts-series-type-line>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/series-line.svg');
}

.highcharts-series-type-candlestick>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/series-candlestick.svg');
}

.highcharts-current-price-indicator>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/current-price-show.svg');
}

.highcharts-save-chart>.highcharts-menu-item-btn {
    background-image: url('https://code.highcharts.com/gfx/stock-icons/save-chart.svg');
}

li.highcharts-active {
    background-color: #e6ebf5;
}

/* Popup */

.chart-wrapper {
    float: left;
    position: relative;
    width: 100%;
    background: white;
    padding-top: 10px;
}

/* Responsive design */

@media screen and (max-width: 1024px) {
    .highcharts-stocktools-toolbar li>.highcharts-menu-item-title {
        display: none;
    }
    .highcharts-stocktools-toolbar li ul li {
        width: auto;
    }
}

</style>