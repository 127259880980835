import * as request from "../helpers/request-new";
import adapter from "./adapters/common/adapter_data_array";
import response_mock from "./mockdata/responses/common/responses_error_data";
import { crypto_slider as microservice } from "../helpers/microservices";

export default ({ body, params, callback, error }) => {
  request.execute({
    microservice: microservice(),
    method: "GET",
    body,
    params,
    callback,
    error,
    adapter: adapter,
    mockdata: response_mock(),
    debug: false,
  });
};
