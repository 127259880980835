<template>
  <div>
    <div
      ref="investorModal"
      id="investorModal"
      class="modal fade modal-investor"
      tabindex="-1"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-lg"
        :class="{ 'modal-xl': isEdit }"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="!isEdit" class="modal-title">Create investor</h5>
            <h5 v-else class="modal-title">Edit investor</h5>
            <span class="modal-close" @click="closeModal"
              ><img :src="close_icon"
            /></span>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 col-md-6" :class="{ 'col-md-12': !isEdit }">
                <div class="row">
                  <div class="col-12">
                    <h2>Account info</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6 pr-0">
                    <div class="form-group">
                      <label>Name</label>
                      <input
                        v-model="form_investor.name"
                        :disabled="form_investor.is_on && isEdit"
                        class="form-control"
                        type="text"
                        placeholder="Name"
                        @input="$v.form_investor.name.$touch()"
                      />
                      <div v-if="$v.form_investor.name.$dirty">
                        <b-form-invalid-feedback
                          :state="$v.form_investor.name.required"
                        >
                          Required Field
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>Token</label>
                      <input
                        v-model="form_investor.token"
                        :disabled="isEdit"
                        type="text"
                        placeholder="Token"
                        class="form-control"
                      />
                      <span class="icon" v-if="!isEdit">
                        <b-icon-shuffle @click="generateToken"></b-icon-shuffle>
                      </span>
                      <div v-if="$v.form_investor.token.$dirty">
                        <b-form-invalid-feedback
                          :state="$v.form_investor.token.required"
                        >
                          Required Field
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <h2>Account type</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label>Demo</label>
                      <div class="form-check d-flex">
                        <input
                          :value="true"
                          :unchecked-value="false"
                          :disabled="isEdit"
                          v-model="form_investor.demo"
                          class="checkbox"
                          type="checkbox"
                          id="type"
                        />
                        <label for="type" class="label-checkbox">
                          <span v-if="!form_investor.demo">
                            It will <u>not</u> be a demo account
                          </span>
                          <span v-if="form_investor.demo">
                            <u>Yes</u>, it will be a demo account
                          </span></label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6 pr-0">
                    <div class="form-group">
                      <label>OMS</label>
                      <Multiselect
                        v-model="form_investor.manual_order"
                        :disabled="form_investor.is_on && isEdit"
                        :hideSelected="true"
                        :options="oms_options"
                        class="form-control selector"
                        label="name"
                        placeholder="Select an option"
                        valueProp="value"
                      >
                        <template v-slot:caret>
                          <span class="multiselect-caret">
                            <img :src="icon_caret" alt="" />
                          </span>
                        </template>
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>Base currency</label>
                      <Multiselect
                        v-model="form_investor.base_currency"
                        :disabled="form_investor.is_on && isEdit"
                        :hideSelected="true"
                        :options="base_currency_options"
                        class="form-control selector"
                        label="name"
                        placeholder="Select an option"
                        valueProp="value"
                      >
                        <template v-slot:caret>
                          <span class="multiselect-caret">
                            <img :src="icon_caret" alt="" />
                          </span>
                        </template>
                      </Multiselect>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <h2>Comments</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label>Additional note</label>
                      <textarea
                        v-model="form_investor.note"
                        :disabled="form_investor.is_on && isEdit"
                        class="form-control"
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        placeholder="Additional note"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div v-if="isEdit" class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label>Labeling</label>
                      <div class="input-label">
                        <input
                          v-model="labeling"
                          :disabled="form_investor.is_on && isEdit"
                          class="form-control"
                          type="text"
                          placeholder="Add tag..."
                        />
                        <button class="mt-0" @click="createLabeling">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="isEdit" class="col-12 col-md-6">
                <h2>Portfolios distribution balance</h2>
                <div v-if="!connections.length" class="alert-container">
                  <AlertInformation
                    description="You must add a connection to be able to add a new portfolio and a transacction"
                  />
                </div>
                <div v-else>
                  <div
                    v-if="!form_investor.is_on && isEdit && connections.length"
                    class="d-flex justify-content-end align-items-center"
                  >
                    <button @click="createPortfolioModal">
                      <span style="font-size: 22px">
                        <b-icon-plus></b-icon-plus> </span
                      >Add new portfolio
                    </button>
                  </div>

                  <ProcessingLoader v-if="portfolio_on_loading" />
                  <AlertErrorInformation v-else-if="portfolios_status.error" />
                  <div
                    class="table-portfolios table-responsive"
                    v-else-if="portfolios.length > 0"
                  >
                    <Table
                      :actions="false"
                      :data="portfolios"
                      :headers="columnHeadersPortfolio"
                      class="portfolios-table"
                      title="portfolios"
                    >
                      <template v-slot:name="{ text }">
                        <span @click="copyToken(text[1])" class="value">{{
                          text[0]
                        }}</span>
                      </template>
                      <template v-slot:initial_balance="{ text }">
                        <span class="value">{{
                          text ? formatter(text) : "0"
                        }}</span>
                      </template>
                      <template v-slot:current_balance="{ text }">
                        <span class="value">{{
                          text ? formatter(text) : "0"
                        }}</span>
                      </template>
                      <template v-slot:nav="{ text }">
                        <span class="value">{{
                          text ? formatter(text) : "0"
                        }}</span>
                      </template>

                      <template v-slot:actions="id">
                        <div
                          v-if="!form_investor.is_on && isEdit"
                          class="dropleft"
                        >
                          <span class="action-button" data-toggle="dropdown">
                            <img :src="menu_icon" />
                          </span>
                          <ul class="dropdown-menu">
                            <li @click="openEditPortfolioModal(id)">
                              Edit portfolio
                            </li>
                            <li @click="deletePortfolioModal(id)">
                              Delete portfolio
                            </li>
                          </ul>
                        </div>
                      </template>
                    </Table>
                  </div>
                  <AlertNoInformation v-else />
                </div>
              </div>
            </div>
            <div v-if="isEdit" class="row">
              <div class="col-12">
                <h2>Connections</h2>
                <div
                  v-if="!form_investor.is_on && isEdit"
                  class="d-flex justify-content-end align-items-center"
                >
                  <button @click="openCreateConnectionModal">
                    <span style="font-size: 22px">
                      <b-icon-plus></b-icon-plus> </span
                    >Add new connection
                  </button>
                </div>
              </div>
            </div>
            <div v-if="isEdit" class="row">
              <div class="col-12">
                <ProcessingLoader v-if="connection_on_loading" />
                <AlertErrorInformation v-else-if="connections_status.error" />
                <div
                  class="table-connections table-responsive"
                  v-else-if="connections.length > 0"
                >
                  <Table
                    :actions="false"
                    :data="connections"
                    :headers="columnHeaders"
                    class="portfolios-table"
                    title="Connections"
                  >
                    <template v-slot:leverage="{ text }">
                      <span class="value">{{ text }}</span>
                    </template>
                    <template v-slot:exchange="{ text }">
                      <span>{{ text.name }}</span>
                    </template>
                    <template v-slot:actions="id">
                      <div
                        v-if="!form_investor.is_on && isEdit"
                        class="dropleft"
                      >
                        <span class="action-button" data-toggle="dropdown">
                          <img :src="menu_icon" />
                        </span>
                        <ul class="dropdown-menu">
                          <li @click="editConnection(id)">Edit connection</li>
                          <li @click="deleteConnection(id)">
                            Delete connection
                          </li>
                        </ul>
                      </div>
                    </template>
                    <template #pagination>
                      <Pagination
                        :pagination="paginate_connection"
                        :go-first-page-callback="goFirstPage"
                        :go-last-page-callback="goLastPage"
                        :go-next-page-callback="goNextPage"
                        :go-prev-page-callback="goPrevPage"
                      />
                    </template>
                  </Table>
                </div>
                <AlertNoInformation v-else />
              </div>
            </div>
            <div class="d-flex justify-content-between container-form">
              <div>
                <button class="mb-0" @click="showEMCrendentials" v-if="isEdit">
                  Show EM Credentials
                </button>
              </div>
              <button
                v-if="!form_investor.is_on"
                class="mb-0"
                @click="handleInvestor"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CreateConnectionModal
      :show="showConnectionModal"
      :is-demo="form_investor.demo"
      :investor-token="form_investor.token"
      :open-from-investor="true"
      ref="modal_add_connection"
      @closed="showConnectionModal = false"
      @showInvestorModal="showInvestorModal"
    />
    <PortfolioModal
      :show-modal="showPortfolioModal"
      :edit="portfolio_edit"
      :demo="form_investor.demo"
      ref="ref_portfolio_modal"
      @set_portfolio_edit="portfolio_edit = false"
      @showInvestorModal="showInvestorModal"
    />
  </div>
</template>

<script>
import AlertInformation from "../../../commons/alerts/AlertInformation.vue";
import AlertErrorInformation from "../../../commons/alerts/AlertErrorInformation.vue";
import AlertNoInformation from "../../../commons/alerts/AlertNoInformation.vue";
import closeIcon from "../../../../assets/images/close.svg";
import CreateConnectionModal from "@/components/pages/investors/connections/CreateConnections.vue";
import IconCaret from "../../../../../public/common/select-arrow-down.svg";
import MenuOptions from "../../../../../public/common/menu-dots-vertical.png";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2";
import number_formatter from "../../../../helpers/formatters/number_formatter";
import Helpers from "@/helpers/commons.js";
import Pagination from "../../../commons/Pagination.vue";
import PortfolioModal from "../modals/Portfolio.vue";
import ProcessingLoader from "../../../commons/alerts/ProcessingLoader.vue";
import Table from "../../../commons/table/Table.vue";
import TableConnections from "@/components/pages/investors/TableConnections.vue";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import $ from "jquery";
import { Modal } from "bootstrap";

export default {
  name: "InvestorModal",
  components: {
    AlertInformation,
    AlertErrorInformation,
    AlertNoInformation,
    CreateConnectionModal,
    Multiselect,
    Pagination,
    PortfolioModal,
    ProcessingLoader,
    Table,
    TableConnections,
  },
  props: ["isEdit"],
  data() {
    return {
      base_currency_options: [
        { value: "usdt", name: "USDT" },
        { value: "btc", name: "BTC" },
        { value: "bnb", name: "BNB" },
        { value: "eth", name: "ETH" },
      ],
      close_icon: closeIcon,
      columnHeaders: [
        {
          name: "name",
          title: "Name",
          align: "left",
        },
        { name: "leverage", title: "Leverage", align: "left" },
        { name: "exchange", title: "Exchange", align: "left" },
        {
          title: "",
          action: true,
          id: "token",
        },
      ],
      columnHeadersPortfolio: [
        {
          name: "name",
          title: "Name",
          align: "left",
          secondary_name: "token",
        },
        { name: "initial_balance", title: "Initial balance", align: "left" },
        { name: "current_balance", title: "Current balance", align: "left" },
        { name: "nav", title: "NAV", align: "left" },
        {
          title: "",
          action: true,
          id: "token",
        },
      ],
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
        <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
        </svg> Error`,
      form_token: null,
      icon_caret: IconCaret,
      labeling: null,
      menu_icon: MenuOptions,
      oms_options: [
        { value: "1", name: "Expert mode" },
        { value: "2", name: "Ultimate OMS" },
      ],
      portfolio_edit: false,
      show_em_redentials: false,
      showConnectionModal: false,
      show_em_redentials: false,
      showPortfolioModal: false,
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
            <g clip-path="url(#clip0_1634_940)">
            <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
            </g>
            <defs>
            <clipPath id="clip0_1634_940">
            <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
            </clipPath>
            </defs>
            </svg> Success`,
    };
  },
  validations: {
    form_investor: {
      name: { required },
      token: { required },
      base_currency: { required },
    },
  },
  created() {
    if (!this.is_edit_investor) {
      this.form_investor.token = Helpers.MD5(Helpers.generateRandomString(10));
    }
  },
  mounted() {
    this.modal = new Modal(this.$refs.investorModal);
    const self = this;
    $("#investorModal").on("hide.bs.modal", function (event) {
      if (
        !self.showPortfolioModal &&
        !self.showConnectionModal &&
        !self.show_em_redentials
      ) {
        self.$store.commit("investors_v2/set_edit_investor_modal", false);
        self.resetModal();
      }
    });

    $("#investorModal").on("show.bs.modal", function (event) {
      if (!self.is_edit_investor) {
        self.form_investor.token = Helpers.MD5(
          Helpers.generateRandomString(10)
        );
      }
    });
  },
  computed: {
    ...mapState("investors_v2", ["form_investor", "is_edit_investor"]),
    ...mapState("connections", ["connection_list"]),
    ...mapState("portfolios", ["form_portfolio", "portfolio_list"]),
    connections() {
      return this.connection_list && this.connection_list._data
        ? this.connection_list._data.data
        : null;
    },
    connections_status() {
      return this.connection_list && this.connection_list._data
        ? this.connection_list._data
        : [];
    },
    connection_on_loading() {
      return this.connection_list._status._on_loading;
    },
    current_page_connection() {
      return this.connection_list._data.paginate
        ? this.connection_list._data.paginate.current_page
        : null;
    },
    current_page_portfolio() {
      return this.portfolio_list._data.paginate
        ? this.portfolio_list._data.paginate.current_page
        : null;
    },
    loadingTable() {
      return this.connection_list._status._on_loading;
    },
    paginate_connection() {
      return this.connection_list._data.paginate;
    },
    portfolios() {
      return this.portfolio_list && this.portfolio_list._data
        ? this.portfolio_list._data.data
        : null;
    },
    portfolio_on_loading() {
      return this.portfolio_list._status._on_loading;
    },
    portfolios_status() {
      return this.portfolio_list && this.portfolio_list._data
        ? this.portfolio_list._data
        : [];
    },
  },
  methods: {
    closeModal() {
      this.modal.hide();
    },
    copyToken(token) {
      navigator.clipboard.writeText(token);
      this.$toastr.success(
        "The token has been copied correctly.",
        `${this.success}`
      );
    },
    resetModal() {
      this.form_investor.base_currency = "usdt";
      this.form_investor.block_account = null;
      this.form_investor.demo = 1;
      this.form_investor.future = false;
      this.form_investor.labeling = null;
      this.form_investor.manual_order = 2;
      this.form_investor.name = null;
      this.form_investor.note = "";
      this.form_investor.is_on = false;
    },
    createInvestor() {
      this.$store.dispatch("investors_v2/create_investor", {
        callback: ({ response }) => {
          if (response.error) {
            if (response.message.length) {
              this.$toastr.error(`${response.message}`, `${this.error}`);
            } else {
              const error = Object.values(response.message);
              this.$toastr.error(`${error[0][0]}`, `${this.error}`);
            }
          } else {
            this.$toastr.success(
              "Investor created successfully.",
              `${this.success}`
            );
            this.$store.dispatch("investors_v2/investors_list", {});
            this.closeModal();
          }
        },
        error: ({ response }) => {},
      });
    },
    createLabeling() {
      const data = {
        investor_token: this.form_investor.token,
        labeling: this.labeling,
      };
      this.$store.dispatch("investors_v2/create_labeling", {
        callback: ({ response }) => {
          if (response.error) {
            if (response.message.length) {
              this.$toastr.error(`${response.message}`, `${this.error}`);
            } else {
              const error = Object.values(response.message);
              this.$toastr.error(`${error[0][0]}`, `${this.error}`);
            }
          } else {
            this.$toastr.success(
              "Labeling created successfully.",
              `${this.success}`
            );
            this.labeling = null;
          }
        },
        error: ({ response }) => {},
        data,
      });
    },
    createPortfolioModal() {
      this.showPortfolioModal = true;
      this.modal.hide();
      this.$store.dispatch("connections/connection_list_selector");
      this.portfolio_edit = false;
      this.$refs["ref_portfolio_modal"].show();
    },
    deleteConnection(id) {
      const body = {
        token: id.id,
      };

      Swal.fire({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#1c2030",
        confirmButtonText: "Delete",
        preConfirm: async () => {
          await this.performDeleteConnection(body);
        },
      }).then(async (result) => {
        if (result.value) {
        }
      });
    },
    deletePortfolioModal(id) {
      const body = {
        token: id.id,
      };

      Swal.fire({
        title: "Are you sure?",
        input: "checkbox",
        inputValue: 0,
        inputPlaceholder: "Ignore transaction",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#1c2030",
        confirmButtonText: "Delete",
        preConfirm: async (value) => {
          body.ignore_transaction = value;
          await this.performDeletePortfolio(body);
        },
      }).then(async (result) => {
        if (result.data) {
        }
      });
    },
    editConnection(token) {
      const data = this.connection_list._data.data.find(
        (connection) => connection.token == token.id
      );
      this.showConnectionModal = true;
      this.modal.hide();
      this.$store.commit("connections/set_is_editing_form", true);
      this.$store.commit("connections/set_creation_form", data);
    },
    formatter(number) {
      return number_formatter(number);
    },
    generateToken() {
      this.form_investor.token = Helpers.MD5(Helpers.generateRandomString(10));
    },
    goFirstPage() {
      this.$store.dispatch("connections/connection_list", {
        page: 1,
      });
    },
    goLastPage() {
      this.$store.dispatch("connections/connection_list", {
        page: this.paginate_connection.total_pages,
      });
    },
    goNextPage() {
      this.$store.dispatch("connections/connection_list", {
        page: this.current_page_connection + 1,
      });
    },
    goPrevPage() {
      this.$store.dispatch("connections/connection_list", {
        page: this.current_page_connection - 1,
      });
    },
    handleInvestor() {
      let validData =
        this.$v.form_investor.name.$invalid ||
        this.$v.form_investor.token.$invalid ||
        this.$v.form_investor.base_currency.$invalid;
      if (validData) {
        this.$toastr.error("Please complete the fields.", `${this.error}`);

        return;
      } else {
        if (this.isEdit) {
          this.updateInvestor();
        } else {
          this.createInvestor();
        }
      }
    },
    showInvestorModal() {
      this.showPortfolioModal = false;
      this.modal.show();
    },
    openCreateConnectionModal() {
      this.showConnectionModal = true;
      this.modal.hide();
    },
    openEditPortfolioModal(id) {
      this.showPortfolioModal = true;
      this.modal.hide();
      this.$store.dispatch("connections/connection_list_selector");
      const data = this.portfolios.find(
        (portfolio) => portfolio.token == id.id
      );
      this.$store.commit("portfolios/set_creation_form", data);
      this.portfolio_edit = true;
      this.$store.commit(
        "portfolios/set_current_balance",
        data.current_balance
      );
      this.$refs["ref_portfolio_modal"].show();
    },
    async performDeleteConnection(body) {
      return new Promise((resolve) => {
        this.$store.dispatch("connections/delete_connection", {
          callback: ({ response }) => {
            if (response.error) {
              this.$toastr.error(`${response.message}`, `${this.error}`);
              return;
            } else {
              this.$toastr.success(
                "Connection deleted successfully.",
                `${this.success}`
              );
              this.$store.dispatch("connections/connection_list");
              this.$store.dispatch("portfolios/portfolio_list");
            }
            resolve();
          },
          body,
        });
      });
    },
    async performDeletePortfolio(body) {
      return new Promise((resolve) => {
        this.$store.dispatch("portfolios/delete_portfolio", {
          callback: ({ response }) => {
            if (response.error) {
              this.$toastr.error(`${response.message}`, `${this.error}`);
              return;
            } else {
              this.$toastr.success(
                "Portfolio deleted successfully.",
                `${this.success}`
              );
              this.$store.dispatch("portfolios/portfolio_list");
            }
            resolve();
          },
          body,
        });
      });
    },
    updateInvestor() {
      const investor_token = {
        token: this.form_investor.token,
      };
      this.$store.dispatch("investors_v2/update_investor", {
        callback: ({ response }) => {
          if (response.error) {
            if (response.message.length) {
              this.$toastr.error(`${response.message}`, `${this.error}`);
            } else {
              const error = Object.values(response.message);
              this.$toastr.error(`${error[0][0]}`, `${this.error}`);
            }
          } else {
            this.$toastr.success(
              "Investor updated successfully.",
              `${this.success}`
            );
            this.$store.dispatch("investors_v2/investors_list", {});
            this.closeModal();
          }
        },
        error: ({ response }) => {},
        investor_token,
      });
    },
    show() {
      if (this.modal) {
        this.modal.show();
      }
    },
    showEMCrendentials() {
      this.show_em_redentials = true;
      this.modal.hide();
      Swal.fire({
        title: "2FA Token",
        input: "text",
        showCancelButton: true,
        background: "#0e1019",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#1c2030",
        confirmButtonText: "Send",
        reverseButtons: true,
        preConfirm: (fa2) => {
          if (fa2.length < 6) {
            Swal.showValidationMessage(`The 2FA code must be 6 characters.`);
          }
          let payload = {
            investor_token: this.form_investor.token,
            "2fa_token": fa2,
          };
          return new Promise((resolve) => {
            this.$http
              .server1(
                "bwfs/admin/InvestorExpertPanel/get_em_user_credential",
                payload
              )
              .then((res) => {
                if (res.error) {
                  Swal.showValidationMessage(`${res.message}`);
                }
                resolve(res);
              })
              .catch((e) => {
                Swal.showValidationMessage(`${e.message}b`);
              });
          });
        },
        allowOutsideClick: () => {
          this.show_em_redentials = false;
          this.showInvestorModal();
          this.$store.commit("investors_v2/set_edit_investor_modal", true);
          !Swal.isLoading();
          Swal.close();
        },
        allowEscapeKey: false,
      })
        .then((result) => {
          if (result.value) {
            let response = result.value;
            Swal.fire({
              width: 600,
              padding: "3em",
              html: `<span style="color:#fff; font-weight: 500;">Api key:</span><br> ${response.api_key}<br><span style="color:#fff; font-weight: 500;">Secret key:</span> ${response.secret_key}`,
              preConfirm: () => {
                this.show_em_redentials = false;
                this.showInvestorModal();
                this.$store.commit(
                  "investors_v2/set_edit_investor_modal",
                  true
                );
              },
              allowOutsideClick: () => {
                this.show_em_redentials = false;
                this.showInvestorModal();
                this.$store.commit(
                  "investors_v2/set_edit_investor_modal",
                  true
                );

                Swal.close();
              },
            });
            setTimeout(() => {
              document.getElementById("swal2-content").style.textAlign = "left";
            }, 200);
          }
          if (result.dismiss === Swal.DismissReason.cancel) {
            this.show_em_redentials = false;
            this.showInvestorModal();
            this.$store.commit("investors_v2/set_edit_investor_modal", true);
          }
        })
        .catch(() => {});
    },
  },
  watch: {
    isEdit(newValue) {
      if (newValue) {
        this.$store.commit(
          "connections/set_investor_token",
          this.form_investor.token
        );
        this.$store.commit(
          "portfolios/set_investor_token",
          this.form_investor.token
        );
        this.$store.dispatch("connections/connection_list");
        this.$store.dispatch("portfolios/portfolio_list");
      } else {
        this.form_investor.token = Helpers.MD5(
          Helpers.generateRandomString(10)
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../../styles/pages/investors_list/investor_modal.scss";
</style>
