var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('TopbarComponent'),_c('router-view'),_vm._l((_vm.conditions),function(condition,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name !== 'login' && _vm.refresh),expression:"$route.name !== 'login' && refresh"}],key:index,style:({
      position: 'absolute',
      left: '12px',
      zIndex: 999999,
      bottom: _vm.handleMargin(index),
    })},[_c('span',{staticClass:"d-flex justify-content-between"},[_c('b-button',{attrs:{"variant":"dark"},on:{"click":function($event){return _vm.showCondition(condition, index)}}},[_vm._v(" #"+_vm._s(index + 1)+" Token: "+_vm._s(condition && condition.token ? condition.token.toString().substr(0, 13) + "..." : "---")+" "),_c('br'),(
            condition.base_configuration && condition.base_configuration.note
          )?_c('span',[_vm._v(_vm._s(condition.base_configuration.note.substr(0, 13) + "..."))]):_vm._e()]),_c('span',{staticStyle:{"margin-left":"-10px","margin-top":"-10px","z-index":"9999999"}},[_c('b-icon-x-circle-fill',{on:{"click":function($event){return _vm.handleDeleteCondition(condition, index)}}})],1)],1)])}),_c('b-modal',{ref:"modal-add-condition-app",attrs:{"id":"modal-add-condition-app","hide-footer":"","hide-header":"","size":"xl","ok-only":""}},[_c('div',{staticClass:"modal-create-conditions"},[_c('h3',{staticClass:"modal-title d-flex justify-content-between"},[_c('span',[_vm._v(" Condition generator ")]),(_vm.isCompound && !_vm.show)?_c('div',[_c('a',{staticClass:"pr-2",staticStyle:{"font-size":"13px","color":"#fff"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.activeTabCondition('buy')}}},[_c('span',{staticClass:"btn btn-dark small",style:({
                background: _vm.activeBuy ? '#11131b' : '#232429',
                fontWeight: '400',
              })},[_vm._v(" Condition #1 ")])]),_c('a',{staticStyle:{"font-size":"13px","color":"#fff"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.activeTabCondition('sell')}}},[_c('span',{staticClass:"btn btn-dark small",style:({
                background: _vm.activeSell ? '#11131b' : '#232429',
                fontWeight: '400',
              })},[_vm._v(" Condition #2 ")])])]):_vm._e()]),_c('ConditionGenerator',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeBuy),expression:"activeBuy"}],ref:"conditionGeneratorBuyApp",attrs:{"isCompound":_vm.isCompound,"backtest":{},"is_investor":_vm.is_investor},on:{"handleCompound":_vm.handleCompoundCondition,"handleAddCompound":_vm.handleAddCompoundCondition,"handleSetType":_vm.handletSeType}}),_c('ConditionGenerator',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeSell),expression:"activeSell"}],ref:"conditionGeneratorSellApp",attrs:{"tabSell":true,"isCompound":_vm.isCompound,"valueCompound":_vm.is_compound,"backtest":{},"is_investor":_vm.is_investor,"type":_vm.currentTypeCondition === 'buy' ? 'sell' : 'buy'},on:{"handleCompound":_vm.handleCompoundCondition}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }