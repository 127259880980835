<template>
  <div :class="`page__${$route.name.toLowerCase()}`">
    <b-container class="pb-4">
      <b-row class="mt-4">
        <b-col cols="12" sm>
          <b-breadcrumb
            :items="[
              {
                text: 'Admin',
                href: '#',
              },
              {
                text: 'Manage',
                href: '#',
              },
              {
                text: 'Library',
                active: true,
              },
            ]"
          ></b-breadcrumb>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col cols="12" sm>
          <b-button class="mr-2" variant="dark">Dark</b-button>
          <b-button class="mr-2" variant="light">Light</b-button>
          <b-button class="mr-2" variant="success">Success</b-button>
          <b-button class="mr-2" variant="danger">Danger</b-button>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col cols="12" sm>
          <b-dropdown variant="light" text="Dropdown Button">
            <b-dropdown-item>First Action</b-dropdown-item>
            <b-dropdown-item>Second Action</b-dropdown-item>
            <b-dropdown-item>Third Action</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item active>Active action</b-dropdown-item>
            <b-dropdown-item disabled>Disabled action</b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col cols="12" sm>
          <b-alert class="mb-2" show variant="success">Success Alert</b-alert>
          <b-alert class="mb-2" show variant="danger">Danger Alert</b-alert>
          <b-alert class="mb-2" show variant="light">Light Alert</b-alert>
          <b-alert class="mb-2" show variant="dark">Dark Alert</b-alert>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col cols="12" sm>
          <b-calendar v-model="selected_date" locale="en"></b-calendar>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col cols="12" sm>
          <b-form @submit.stop.prevent>
            <label for="text-password">Password</label>
            <b-form-input
              type="password"
              id="text-password"
              aria-describedby="password-help-block"
            ></b-form-input>
            <b-form-text id="password-help-block">
              Your password must be 8-20 characters long, contain letters and
              numbers, and must not contain spaces, special characters, or
              emoji.
            </b-form-text>
          </b-form>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col cols="12" sm>
          <b-table
            :items="pagination_items"
            :per-page="pagination_perPage"
            :current-page="pagination_currentPage"
            striped
            hover
            small
          ></b-table>

          <p class="mt-3 text-muted small">
            Current Page: {{ pagination_currentPage }}
          </p>

          <b-pagination
            v-model="pagination_currentPage"
            :total-rows="pagination_rows"
            :per-page="pagination_perPage"
          ></b-pagination>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col cols="12" sm>
          <b-spinner type="grow" label="Spinning"></b-spinner>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col cols="12" sm>
          <b-button
            variant="light"
            v-b-tooltip.hover
            title="Tooltip directive content"
          >
            Hover Me
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col cols="12" sm>
          <label>Button loading</label>

          <div class="form-group">
            <b-button variant="light" class="btn-loading">
              Submit
              <div class="loading">
                <b-spinner variant="dark"></b-spinner>
              </div>
            </b-button>

            <b-button variant="dark" class="btn-loading ml-2">
              Submit
              <div class="loading">
                <b-spinner variant="light"></b-spinner>
              </div>
            </b-button>

            <b-button variant="dark" class="btn-loading ml-2" disabled>
              Submit disabled
              <div class="loading">
                <b-spinner variant="light"></b-spinner>
              </div>
            </b-button>

            <b-button variant="light" class="btn-loading ml-2" disabled>
              Submit disabled
              <div class="loading">
                <b-spinner variant="dark"></b-spinner>
              </div>
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col cols="12" sm>
          <div class="form-group">
            <label>Input loading</label>
            <input type="text" class="form-control" />
            <div class="loading">
              <b-spinner variant="light"></b-spinner>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" sm>
          <div class="form-group form-select">
            <label>Select loading</label>
            <select class="form-control">
              <option>1</option>
            </select>
            <div class="loading">
              <b-spinner variant="light"></b-spinner>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-0">
        <b-col cols="12" sm>
          <div class="form-group">
            <label>Input disabled</label>
            <input type="text" class="form-control" disabled />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "StyleGuide",

  components: {},

  data() {
    return {
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
        <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
        </svg> Error`,
      pagination_perPage: 3,
      pagination_currentPage: 1,
      pagination_items: [
        { id: 1, first_name: "Fred", last_name: "Flintstone" },
        { id: 2, first_name: "Wilma", last_name: "Flintstone" },
        { id: 3, first_name: "Barney", last_name: "Rubble" },
        { id: 4, first_name: "Betty", last_name: "Rubble" },
        { id: 5, first_name: "Pebbles", last_name: "Flintstone" },
        { id: 6, first_name: "Bamm Bamm", last_name: "Rubble" },
        { id: 7, first_name: "The Great", last_name: "Gazzoo" },
        { id: 8, first_name: "Rockhead", last_name: "Slate" },
        { id: 9, first_name: "Pearl", last_name: "Slaghoople" },
      ],
      selected_date: "",
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
          <g clip-path="url(#clip0_1634_940)">
          <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
          </g>
          <defs>
          <clipPath id="clip0_1634_940">
          <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
          </clipPath>
          </defs>
          </svg> Success`,
    };
  },

  computed: {
    rows() {
      return this.items.length;
    },
  },

  mounted() {
    this.$toastr.error(
      `error message - mensaje suficientemente largo como para ver varias lineas :)`,
      `${this.error}`
    );
    this.$toastr.success(
      `success message - mensaje suficientemente largo como para ver varias lineas :)`,
      `${this.success}`
    );
    this.$toastr.info(
      `info message - mensaje suficientemente largo como para ver varias lineas :)`
    );
  },
};
</script>
