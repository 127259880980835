export default {
  limit: 10,
  algorithm_token: "",
  search: null,
  sort_by: "updated_at",
  sort: "asc",
  is_active: 1,
  is_health_ok: null,
  tags: null,
};
