function getRandomBoolean() {
  return Math.random() < 0.5;
}

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function generateRandomTimestamp() {
  const year = 2023; // Assuming a specific year for the timestamp
  const month = String(Math.floor(Math.random() * 12) + 1).padStart(2, "0");
  const day = String(Math.floor(Math.random() * 28) + 1).padStart(2, "0");
  const hour = String(Math.floor(Math.random() * 24)).padStart(2, "0");
  const minute = String(Math.floor(Math.random() * 60)).padStart(2, "0");
  const second = String(Math.floor(Math.random() * 60)).padStart(2, "0");

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

function generateRandomTokenName() {
  const tokenNames = ["SubsystemA", "SubsystemB", "SubsystemC"];
  return tokenNames[Math.floor(Math.random() * tokenNames.length)];
}

function generateRandomPriority() {
  const priorities = ["low", "medium", "high"];
  return priorities[Math.floor(Math.random() * priorities.length)];
}

function generateRandomIncidents(wasRunning) {
  if (!wasRunning) return [];
  const numIncidents = Math.floor(Math.random() * 3); // Randomly choose 0, 1, or 2 incidents
  const incidents = [];

  for (let i = 0; i < numIncidents; i++) {
    const incident = {
      timestamp: generateRandomTimestamp(),
      system_name: generateRandomTokenName(),
      priority: generateRandomPriority(),
    };
    incidents.push(incident);
  }
  return getRandomBoolean() ? incidents : [];
}

function generateJsonObject(days_ago) {
  const today = new Date();
  const jsonArray = [];

  for (let i = 0; i < days_ago; i++) {
    const currentDate = new Date(today);
    currentDate.setDate(today.getDate() - i);
    const was_running = getRandomBoolean();
    const jsonObject = {
      date: formatDate(currentDate),
      was_running,
      incidents: generateRandomIncidents(was_running),
    };

    jsonArray.push(jsonObject);
  }

  return jsonArray;
}
function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
function getRandomPercentage() {
  var randomDecimal = Math.random() * 100;
  return parseFloat(randomDecimal.toFixed(2));
}
export default () => {
  const days_ago = getRandomNumber(100,300);
  const historical_timeline = generateJsonObject(days_ago);
  const uptime_percentage = getRandomPercentage();
  const dead_tokens_now = getRandomBoolean();

  return {
    historical_timeline,
    days_ago,
    uptime_percentage,
    dead_tokens_now
  };
};
