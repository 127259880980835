<template>
  <div>
    <div class="basic-information-title">Basic Information</div>
    <ProcessingLoader v-if="onLoading" />
    <div v-else class="basic-information-list">
      <div class="basic-information-item">
        <div class="basic-information-item-title">Name:</div>
        <div class="basic-information-item-detail">
          {{ basicInformation.investor_name }}
          <div
            :class="[basicInformation.is_on ? 'account-on' : 'account-off']"
            v-if="isLogged"
          ></div>
        </div>
      </div>
      <div class="basic-information-item">
        <div class="basic-information-item-title">Current NAV:</div>
        <div class="basic-information-item-detail value">
          <img :src="dollar_icon" />
          {{ formatter(basicInformation.current_nav, 2) }}
        </div>
      </div>
      <div class="basic-information-item">
        <div class="basic-information-item-title">Initial deposit:</div>
        <div class="basic-information-item-detail value">
          <img :src="dollar_icon" />
          {{ formatter(basicInformation.initial_deposit, 2) }}
        </div>
      </div>
      <div class="basic-information-item">
        <div class="basic-information-item-title">Deposits:</div>
        <div class="basic-information-item-detail value">
          <img :src="dollar_icon" />
          {{ formatter(basicInformation.total_deposits, 2) }}
        </div>
      </div>
      <div class="basic-information-item">
        <div class="basic-information-item-title">Withdrawals:</div>
        <div class="basic-information-item-detail value">
          <img :src="dollar_icon" />
          {{ formatter(basicInformation.total_withdrawal, 2) }}
        </div>
      </div>
      <div class="basic-information-item">
        <div class="basic-information-item-title">Performance to date:</div>
        <div class="basic-information-item-detail value">
          <img :src="dollar_icon" />
          {{ formatter(basicInformation.performance_to_date, 2) }}
        </div>
      </div>
      <div class="basic-information-item">
        <div class="basic-information-item-title">Last update:</div>
        <div class="basic-information-item-detail value">
          {{ timeAgo(basicInformation.last_snapshot_date) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Dollar from "../../../../public/common/dollar.png";
import Percentage from "../../../../public/common/percentage.png";
import ProcessingLoader from "../../commons/alerts/ProcessingLoader.vue";
import number_formatter from "../../../helpers/formatters/number_formatter";

export default {
  name: "BasicInformation",
  data() {
    return {
      dollar_icon: Dollar,
      percentage_icon: Percentage,
      isLogged: false,
    };
  },
  created() {
    const session = localStorage.getItem("userData");
    if (session && session !== "") {
      this.isLogged = true;
    }
  },
  computed: {
    ...mapState("dashboard", ["basic_information"]),
    basicInformation() {
      return this.basic_information && this.basic_information._data
        ? this.basic_information._data.data
        : null;
    },
    onLoading() {
      return this.basic_information._status._on_loading;
    },
  },
  methods: {
    formatter(number) {
      return number_formatter(number);
    },
    timeAgo(input) {
      if (!input) {
        return "No data";
      }
      const inputDate = moment(input, "YYYY-MM-DD HH:mm:ss");
      const now = moment();
      const diffMinutes = now.diff(inputDate, "minutes");
      const diffHours = now.diff(inputDate, "hours");
      const diffDays = now.diff(inputDate, "days");

      let agoText;
      if (diffMinutes < 1) {
        agoText = "just now";
      } else if (diffMinutes < 60) {
        agoText = `${diffMinutes} minute${diffMinutes > 1 ? "s" : ""} ago`;
      } else if (diffHours < 24) {
        agoText = `${diffHours} hour${diffHours > 1 ? "s" : ""} ago`;
      } else {
        agoText = `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
      }

      return `${input} (${agoText})`;
    },
  },
  components: { ProcessingLoader },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/pages/dashboard/basic_information.scss";
</style>
