import CreateInvestorService from "../../../services/create_investor.service";

export default ({ commit, state }, { callback, error }) => {
  commit("set", {
    _state: "create_investor",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });

  const { base_currency, future, demo, name, manual_order, note, token } =
    state.form_investor;
  const future_ = future ? 1 : 0;
  const body = {
    base_currency,
    future: manual_order == 2 ? 1 : future_,
    is_real: demo ? 0 : 1,
    name,
    manual_order,
    note,
    token,
  };

  CreateInvestorService({
    body,
    callback: ({ response }) => {
      commit("set", {
        _state: "create_investor",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "create_investor",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
