<template>
  <div class="sidebar">
    <div class="option" :class="{ act: sidebar_notification_option == 1 }">
      <span
        ><img
          :src="sidebar_notification_option == 1 ? action_act : action"
          alt=""
      /></span>
      <h1>Action</h1>
    </div>
    <div class="option" :class="{ act: sidebar_notification_option == 2 }">
      <span
        ><img
          :src="sidebar_notification_option == 2 ? event_act : event"
          alt=""
      /></span>
      <h1>Event</h1>
    </div>
    <div class="option" :class="{ act: sidebar_notification_option == 3 }">
      <span
        ><img
          :src="sidebar_notification_option == 3 ? settings_act : settings"
          alt=""
      /></span>
      <h1>Settings</h1>
    </div>
    <div
      v-if="form_action == 0"
      class="option"
      :class="{ act: sidebar_notification_option == 4 }"
    >
      <span
        ><img
          :src="sidebar_notification_option == 4 ? channel_act : channel"
          alt=""
      /></span>
      <h1>Channel</h1>
    </div>
  </div>
</template>

<script>
import ActionIcon from "../../../../../../../public/common/action-sidebar.svg";
import ActionActIcon from "../../../../../../../public/common/action-sidebar-act.svg";
import EventIcon from "../../../../../../../public/common/event-sidebar.svg";
import EventActIcon from "../../../../../../../public/common/event-sidebar-act.svg";
import SettingsIcon from "../../../../../../../public/common/settings-sidebar.svg";
import SettingsActIcon from "../../../../../../../public/common/settings-sidebar-act.svg";
import ChannelIcon from "../../../../../../../public/common/channel-sidebar.svg";
import ChannelActIcon from "../../../../../../../public/common/channel-sidebar-act.svg";

import { mapState } from "vuex";

export default {
  name: "CreateNotificationModal",

  data() {
    return {
      action: ActionIcon,
      action_act: ActionActIcon,
      event: EventIcon,
      event_act: EventActIcon,
      settings: SettingsIcon,
      settings_act: SettingsActIcon,
      channel: ChannelIcon,
      channel_act: ChannelActIcon,
    };
  },
  computed: {
    ...mapState("investor_configuration", [
      "sidebar_notification_option",
      "form_action",
    ]),
  },
  mounted() {},
  methods: {},
};
</script>
