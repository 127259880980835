<template>
  <thead>
    <tr>
      <th
        v-for="(header, index) in headers"
        :key="index"
        :width="header.width ? header.width : ''"
        scope="col"
      >
        <div
          v-if="!header.action"
          :class="
            header.align == 'right'
              ? 'align-right'
              : header.align == 'left'
              ? 'align-left'
              : 'align-center'
          "
          class="column-header"
        >
          <slot v-if="header.checkbox" name="checkboxx"> </slot>

          <div class="column-title">{{ header.title }}</div>
          <div v-if="header.sort" class="d-flex flex-column column-sorting">
            <img :src="upIcon" @click="sortAscending(header.name)" /><img
              :src="downIcon"
              @click="sortDescending(header.name)"
            />
          </div>
          <div v-if="header.info" class="d-flex flex-column column-sorting">
            <img
              :src="InfoIcon"
              @click="sortAscending(header.name)"
              width="12"
              height="12"
            />
          </div>
        </div>
        <div v-else class="action-header">{{ header.title }}</div>
      </th>
    </tr>
  </thead>
</template>

<script setup>
import InfoIcon from "../../../../public/common/info-icon.svg";
import { inject } from "vue";

const headers = inject("headers");
</script>
