import random_number from "../../../helpers/generators/random_number";
import model from "../models/connection_list_selector";

export default () => {
  const num = random_number(1, 20);
  let data = [];
  for (let i = 0; i < num; i++) {
    const connection = model(i);
    data.push(connection);
  }

  return {
    data,
  };
};
