import random_number from "../../../helpers/generators/random_number";

export default () => {
  const channels = ["Slack", "Whatsapp", "Email"];
  const contents = [
    "+545455455",
    "hfhfhfhf@dkdkd.co",
    "#canal",
    "+874665855",
    "jdjdjd@fff.vo",
  ];

  const channel_type = channels[random_number(0, channels.length - 1)];
  const name = contents[random_number(0, contents.length - 1)];
  return {
    channel_type,
    name,
  };
};
