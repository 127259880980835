import UpdateTransactionService from "../../../services/update_transaction.service";
export default ({ commit, state }, { callback, error }) => {
  commit("set", {
    _state: "update_transaction",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });

  const { amount, event, note, snapshot_token } = state.form_transaction;

  const body = {
    amount,
    event_type: event,
    note,
    snapshot_token,
  };

  UpdateTransactionService({
    body,
    callback: ({ response }) => {
      commit("set", {
        _state: "update_transaction",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "update_transaction",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
