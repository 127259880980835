import random_number from "../../../helpers/generators/random_number";
import model from "../models/open_order";

export default () => {
  const num = random_number(1, 5);
  let orders = [];
  for (let i = 0; i < num; i++) {
    const order = model();
    orders.push(order);
  }
  return {
    data: orders,
  };
};
