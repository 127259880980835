import ClosedOrdersListService from "../../../services/closed_orders_list.service";
export default ({ commit, state }, { page }) => {
  commit("set", {
    _state: "closed_orders_list",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });

  const { limit, portfolio_token, search, status, sort, sort_by } =
    state.closed_orders_filters;

  const params = {
    limit,
    search,
    status: status == "" ? 4 : status,
    page,
    portfolio_token,
    sort: sort == "" ? "desc" : sort,
    sort_by: sort_by == "" ? "open_date" : sort_by,
  };

  ClosedOrdersListService({
    params,
    token: state.investor_token,
    callback: ({ response }) => {
      commit("set", {
        _state: "closed_orders_list",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "closed_orders_list",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
