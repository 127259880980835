var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`panel page__${_vm.$route.name.toLowerCase()}`},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"p-0"},[_c('SearchInvestor',{ref:"searchInvestor",attrs:{"firstLoad":_vm.firstLoad}}),_c('b-col',{staticClass:"p-0 admin_content nice-scroll",attrs:{"cols":"12","lg":"10"}},[_c('div',{staticClass:"content p-2"},[_c('div',{staticClass:"row pl-3 pr-3"},[_c('div',{staticClass:"col-md-4 p-1 d-flex align-items-center"}),_c('div',{staticClass:"col-md-8 p-1 text-right"},[_c('MultiSelectAccounts'),(
                  _vm.$store.state.user.role_id === 1 &&
                  _vm.$store.state.select_accounts.length === 0
                )?_c('b-button',{attrs:{"variant":"dark"},on:{"click":function($event){return _vm.showCreate()}}},[_vm._v("New user")]):_vm._e(),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"dark"},on:{"click":_vm.loadUsers}},[_vm._v("Load users")]),_c('CreateEditInvestor',{ref:"CreateEditInvestor",on:{"handleReload":_vm.handleReload}})],1)]),_c('InvestorList',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.show &&
              !_vm.$store.state.order_tracking_mode &&
              _vm.$store.state.migration_authorized
            ),expression:"\n              show &&\n              !$store.state.order_tracking_mode &&\n              $store.state.migration_authorized\n            "}],ref:"InvestorList",attrs:{"firstLoad":_vm.firstLoad}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.show &&
              !_vm.$store.state.order_tracking_mode &&
              !_vm.$store.state.migration_authorized
            ),expression:"\n              show &&\n              !$store.state.order_tracking_mode &&\n              !$store.state.migration_authorized\n            "}],staticClass:"row pl-1 pr-1"},[_c('div',{staticClass:"col"},[_c('b-alert',{staticClass:"mb-2",attrs:{"show":"","variant":"dark"}},[_vm._v(" Authorize the user to make the migration")])],1)]),_c('div',{staticClass:"row pl-3 pr-3 d-flex flex-row"},[_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"page__investors"},[_c('div',{staticClass:"sidebar__user_closed_orders nice-scroll"},[_c('InvestorOrderTable',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.order_tracking_mode),expression:"$store.state.order_tracking_mode"}],ref:"OrdersTable"})],1)])]),_c('div',{staticClass:"col-md-2 p-0"},[_c('div',{staticClass:"page__investors"},[_c('div',{staticClass:"sidebar__user_manual_orders nice-scroll"},[_c('CardNotifications',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.order_tracking_mode),expression:"$store.state.order_tracking_mode"}]})],1)])])])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }