<template>
  <span>
    <div class="sidebar-component">
      <div>
        <div
          :class="{ act: sidebar_option == 1 }"
          class="box-container"
          @click="setSidebarOption(1)"
        >
          <div>
            <span
              ><img
                :src="sidebar_option == 1 ? user_act_icon : user_icon"
                alt=""
            /></span>
            Account Info
          </div>
          <div><img :src="check_icon" alt="" /></div>
        </div>
        <div
          :class="{ act: sidebar_option == 2 }"
          class="box-container"
          @click="setSidebarOption(2)"
        >
          <div>
            <span
              ><img
                :src="sidebar_option == 2 ? comment_act_icon : comment_icon"
                alt="" /></span
            >Comments
          </div>
          <div><img :src="check_icon" alt="" /></div>
        </div>
        <div
          :class="{ act: sidebar_option == 3 }"
          class="box-container"
          @click="setSidebarOption(3)"
        >
          <div>
            <span
              ><img
                :src="
                  sidebar_option == 3 ? transaction_act_icon : transaction_icon
                "
                alt="" /></span
            >Transactions
          </div>
          <div>
            <img
              :src="isAllowedTransactions == true ? check_icon : block_icon"
              alt=""
            />
          </div>
        </div>
        <div
          :class="{ act: sidebar_option == 4 }"
          class="box-container"
          @click="setSidebarOption(4)"
        >
          <div>
            <span
              ><img
                :src="
                  sidebar_option == 4 ? connection_act_icon : connection_icon
                "
                alt="" /></span
            >Connections
          </div>
          <div>
            <img
              :src="isAllowedConnections == true ? check_icon : block_icon"
              alt=""
            />
          </div>
        </div>
        <div
          :class="{ act: sidebar_option == 5 }"
          class="box-container"
          @click="setSidebarOption(5)"
        >
          <div>
            <span
              ><img
                :src="sidebar_option == 5 ? portfolio_act_icon : portfolio_icon"
                alt="" /></span
            >Portfolios
          </div>
          <div>
            <img
              :src="isAllowedPortfolios == true ? check_icon : block_icon"
              alt=""
            />
          </div>
        </div>
        <div
          :class="{ act: sidebar_option == 6 }"
          class="box-container"
          @click="setSidebarOption(6)"
        >
          <div>
            <span
              ><img
                :src="
                  sidebar_option == 6
                    ? notification_act_icon
                    : notification_icon
                "
                alt="" /></span
            >Notifications
          </div>
          <div>
            <img :src="check_icon" alt="" />
          </div>
        </div>
      </div>
      <div>
        <button @click="showEMCrendentials">Show EM credentials</button>
      </div>
    </div>
    <TwoFATokenModal :show="show_2factor_modal" @close="show_2factor_modal = false"/>
  </span>
</template>

<script>
import BlockIcon from "../../../../public/common/block-sidebar.svg";
import CheckIcon from "../../../../public/common/check-sidebar.svg";
import CommentIcon from "../../../../public/common/comment-sidebar.svg";
import CommentActIcon from "../../../../public/common/comment-sidebar-act.svg";
import ConnectionIcon from "../../../../public/common/connection-sidebar.svg";
import ConnectionActIcon from "../../../../public/common/connection-sidebar-act.svg";
import NotificationIcon from "../../../../public/common/notification-sidebar.svg";
import NotificationActIcon from "../../../../public/common/notification-sidebar-act.svg";
import PortfolioIcon from "../../../../public/common/portfolio-sidebar.svg";
import PortfolioActIcon from "../../../../public/common/portfolio-sidebar-act.svg";
import TransactionIcon from "../../../../public/common/transaction-sidebar.svg";
import TransactionActIcon from "../../../../public/common/transaction-sidebar-act.svg";
import UserIcon from "../../../../public/common/user-sidebar.svg";
import UserActIcon from "../../../../public/common/user-sidebar-act.svg";
import TwoFATokenModal from "./TwoFATokenModal.vue";
import { mapState } from "vuex";

export default {
  name: "InvestorSidebar",
  components: { TwoFATokenModal },
  data() {
    return {
      block_icon: BlockIcon,
      check_icon: CheckIcon,
      comment_icon: CommentIcon,
      comment_act_icon: CommentActIcon,
      connection_icon: ConnectionIcon,
      connection_act_icon: ConnectionActIcon,
      notification_icon: NotificationIcon,
      notification_act_icon: NotificationActIcon,
      portfolio_icon: PortfolioIcon,
      portfolio_act_icon: PortfolioActIcon,
      transaction_icon: TransactionIcon,
      transaction_act_icon: TransactionActIcon,
      user_icon: UserIcon,
      user_act_icon: UserActIcon,
      option_act: true,
      show_2factor_modal:false
    };
  },
  mounted() {},
  created() {},
  computed: {
    ...mapState("investor_configuration", [
      "sidebar_option",
      "investor_token",
      "investor_info",
    ]),
    investor() {
      return this.investor_info && this.investor_info._data
        ? this.investor_info._data.data
        : null;
    },
    isAllowedTransactions() {
      return this.investor && this.investor.has_connections;
    },
    isAllowedConnections() {
      return true;
    },
    isAllowedPortfolios() {
      return (
        this.investor &&
        this.investor.has_connections &&
        this.investor.has_transactions
      );
    },
  },
  methods: {
    setSidebarOption(opt) {
      this.$store.commit("investor_configuration/set_sidebar_option", opt);
    },
    showEMCrendentials() {
      this.show_2factor_modal = true;
    },
  },
  watch: {},
};
</script>

<style lang="scss">
@import "../../../styles/pages/investor_configuration/sidebar.scss";
</style>
