<template>
    <div :class="`panel page__${$route.name.toLowerCase()}`">
        <b-container fluid>
            <b-row class="p-0">
              <!--<b-col cols="12" lg="2" class="p-0 admin_sidebar nice-scroll">
                <SideBarServersInfo></SideBarServersInfo>
                <SideBarMaintenanceMode></SideBarMaintenanceMode>
              </b-col> !-->
              <b-col cols="12" lg="12" class="p-0 admin_content nice-scroll">
                <div class="content p-2">
                  <div class="row pl-3 pr-3">
                    <Table/>
                  </div>
                </div>
              </b-col>
            </b-row>
        </b-container>
    </div>
  </template>
  
<script>
  import ServersInfo from '@/components/template/sidebars/ServersInfo';
  import MaintenanceMode from '@/components/template/sidebars/MaintenanceMode';
  import Table from '../components/pages/server-management/table'

  export default {
    name: 'ServersManagement',

    components: {
        SideBarServersInfo: ServersInfo,
        SideBarMaintenanceMode: MaintenanceMode,
        Table
    },

    mounted() {
      this.$helpers.setWindowName();
    },

    created() {
      this.$helpers.setWindowName();
    },

    data () {
      return {

        
      }
    }
  }
  </script>
  
  <style lang="scss">
    @import '@/styles/commons/admin.scss';
  </style>