import state from "@/helpers/store/state";
import set from "@/helpers/store/set";

import algorithm_selector_list from "./actions/algorithm_selector_list";
import algorithms from "./actions/algorithms";
import basic_information from "./actions/basic_information";
import closed_orders_list from "./actions/closed_orders_list";
import connection_list_selector from "./actions/connection_list_selector";
import crypto_slider from "./actions/crypto_slider";
import current_positions from "./actions/current_positions";
import download_report from "./actions/download_report";
import download_orders_report from "./actions/download_orders_report";
import exchange_selector_list from "./actions/exchange_selector_list";
import exposure from "./actions/exposure";
import exposure_chart from "./actions/exposure_chart";
import news_sliders from "./actions/news_sliders";
import news_details from "./actions/news_details";
import open_orders from "./actions/open_orders";
import open_orders_json from "./actions/open_orders_json";
import performance_account from "./actions/performance_account";
import set_investor_token from "./mutations/set_investor_token";
import set_filter_chart from "./mutations/set_filter_chart";
import set_global_selected_algorithm from "./mutations/set_global_selected_algorithm";
import set_global_selected_exchange from "./mutations/set_global_selected_exchange";
import set_selected_exchange_token from "./mutations/set_selected_exchange_token";
import statistics_nav from "./actions/statistics_nav";
import statics_fee from "./actions/statics_fee";
import statics_max_drawdown from "./actions/statics_max_drawdown";
import statics_metrics from "./actions/statics_metrics";
import immediate_close from "./actions/immediate_close";

import algorithms_adapter from "../../services/adapters/algorithms";
import basic_information_adapter from "../../services/adapters/basic_information";
import download_report_adapter from "../../services/adapters/download_report";
import download_orders_report_adapter from "../../services/adapters/download_orders_report";
import exposure_adapter from "../../services/adapters/exposure";
import closed_orders_filters_adapter from "../../services/adapters/closed_orders_filters";
import common_list_adapter from "../../services/adapters/common/adapter_list";
import common_data_adapter from "../../services/adapters/common/adapter_data";
import current_positions_adapter from "../../services/adapters/current_positions";
import open_orders_adapter from "../../services/adapters/open_orders";
import performance_account_adapter from "../../services/adapters/performance_account";
import statics_fee_adapter from "../../services/adapters/statics_fee";
import statics_max_drawdown_adapter from "../../services/adapters/statics_max_drawdown";
import statics_metrics_adapter from "../../services/adapters/statics_metrics";
import statistics_nav_adapter from "../../services/adapters/statistics_nav";

export default {
  namespaced: true,
  state: () => ({
    algorithm_selector_list: state(common_data_adapter),
    algorithms: state(algorithms_adapter),
    algorithm_selector_list: state(common_data_adapter),
    basic_information: state(basic_information_adapter),
    closed_orders_filters: { ...closed_orders_filters_adapter },
    closed_orders_list: state(common_list_adapter),
    connection_list_selector: state(common_data_adapter),
    crypto_slider: state(common_data_adapter),
    current_positions: state(current_positions_adapter),
    download_report: state(download_report_adapter),
    download_orders_report: state(download_orders_report_adapter),
    exposure: state(exposure_adapter),
    exchange_selector_list: state(common_data_adapter),
    exposure_chart: state(common_data_adapter),
    filter_chart: null,
    global_selected_algorithm: null,
    global_selected_exchange: null,
    investor_token: null,
    immediate_close: state(null),
    news_sliders: state(common_data_adapter),
    news_details: state(common_data_adapter),
    open_orders: state(open_orders_adapter),
    open_orders_json: state(open_orders_adapter),
    performance_account: state(performance_account_adapter),
    selected_exchange_token: null,
    statics_fee: state(statics_fee_adapter),
    statics_max_drawdown: state(statics_max_drawdown_adapter),
    statics_metrics: state(statics_metrics_adapter),
    statistics_nav: state(statistics_nav_adapter),
  }),
  actions: {
    algorithm_selector_list,
    algorithms,
    algorithm_selector_list,
    basic_information,
    closed_orders_list,
    connection_list_selector,
    crypto_slider,
    current_positions,
    download_report,
    download_orders_report,
    exposure,
    exposure_chart,
    exchange_selector_list,
    immediate_close,
    news_sliders,
    news_details,
    open_orders,
    open_orders_json,
    performance_account,
    statics_fee,
    statics_max_drawdown,
    statics_metrics,
    statistics_nav,
    open_orders,
    open_orders_json,
  },
  mutations: {
    set,
    set_investor_token,
    set_filter_chart,
    set_global_selected_algorithm,
    set_global_selected_exchange,
    set_investor_token,
    set_selected_exchange_token,
  },
};
