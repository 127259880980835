<template>
  <div class="container-fluid">
    <div class="module-content module-health-audit">
      <div class="row"><HeaderTopBar /></div>
      <ActiveErrors />
      <div class="row divider"></div>
      <ResolvedErrors />
    </div>
  </div>
</template>

<script>
import HeaderTopBar from "../components/pages/health_audit/HeaderTopBar.vue";
import $ from "jquery";
import "@vueform/multiselect/themes/default.css";
import ActiveErrors from "../components/pages/health_audit/ActiveErrors.vue";
import ResolvedErrors from "../components/pages/health_audit/ResolvedErrors.vue";

export default {
  name: "health_audit",
  components: { HeaderTopBar, ActiveErrors, ResolvedErrors },
  created() {},
  methods: {},
  computed: {},
  mounted() {},
};
</script>
<style lang="scss">
@import "../styles/pages/health_audit.scss";
</style>
