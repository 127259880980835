<template>
  <b-modal
    id="modal-show-backtest"
    centered
    hide-footer
    hide-header
    size="md"
    v-model="showModal"
    @hide="closeModal()"
  >
    <div>
      <h3 class="modal-title">Edit Group</h3>
      <div class="row px-2">
        <div class="col-md-12 px-2">
          <b-form-group>
            <label>Name</label>
            <input
              type="text"
              placeholder="Name"
              class="form-control"
              v-model="groupForm.name"
              @input="$v.groupForm.name.$touch()"
              :disabled="false"
            />
            <div v-if="$v.groupForm.name.$dirty">
              <b-form-invalid-feedback :state="$v.groupForm.name.required">
                Required Field
              </b-form-invalid-feedback>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-12 px-2">
          <b-form-group>
            <label>Algorithms</label>
            <!-- <b-form-select
              v-model="groupForm.algorithm_token"
              :options="algorithmsOptions"
              @input="$v.groupForm.algorithm_token.$touch()"
            /> -->
            <Multiselect
              v-model="groupForm.algorithm_token"
              :hideSelected="true"
              :options="algorithmsOptions"
              class="form-control selector"
              label="name"
              placeholder="Select an algorithm"
              valueProp="token"
            >
              <template v-slot:caret>
                <span class="multiselect-caret">
                  <img :src="icon_caret" alt="" />
                </span>
              </template>
            </Multiselect>
            <div v-if="$v.groupForm.algorithm_token.$dirty">
              <b-form-invalid-feedback
                :state="$v.groupForm.algorithm_token.required"
              >
                Required Field
              </b-form-invalid-feedback>
            </div>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div id="save-button" class="col d-flex justify-content-end">
          <b-button
            class="btn btn-success ml-2 btn-create-group"
            @click="updateGroup"
          >
            <span v-if="!loadingRequest">Update</span>
            <div class="loading loading-space" v-if="loadingRequest">
              <b-spinner variant="light"></b-spinner>
            </div>
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import IconCaret from "../../../../public/common/select-arrow-down.svg";

import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2";

export default {
  name: "EditGroupModal",
  components: {
    Multiselect,
  },
  props: ["show", "name", "algorithm_token", "token"],
  data() {
    return {
      icon_caret: IconCaret,
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
      <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
      </svg> Error`,
      groupForm: {
        name: "",
        algorithm_token: "",
      },
      showModal: false,
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
          <g clip-path="url(#clip0_1634_940)">
          <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
          </g>
          <defs>
          <clipPath id="clip0_1634_940">
          <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
          </clipPath>
          </defs>
          </svg> Success`,
    };
  },
  validations: {
    groupForm: {
      name: { required },
      algorithm_token: { required },
    },
  },
  computed: {
    ...mapState("groups", ["algorithm_selector"]),
    algorithmsOptions() {
      return this.algorithm_selector && this.algorithm_selector._data
        ? this.algorithm_selector._data.data
        : [];
    },
    loadingRequest() {
      return this.$store.state.groups.edit_group._status._on_loading;
    },
  },
  watch: {
    show(newValue) {
      this.showModal = newValue;
    },
    name(newValue) {
      this.groupForm.name = newValue;
    },
    algorithm_token(newValue) {
      this.groupForm.algorithm_token = newValue;
    },
  },
  methods: {
    closeModal() {
      this.$emit("hide");
    },
    updateGroup() {
      if (this.$v.groupForm.$invalid) {
        this.$toastr.error(
          "Fulfill the field filling requirements",
          `${this.error}`
        );
        this.$v.$touch();
      } else {
        const body = {
          name: this.groupForm.name,
          token: this.token,
          algorithm_token: this.groupForm.algorithm_token,
        };
        this.$store.dispatch("groups/edit_group", {
          callback: ({ response }) => {
            if (response.error) {
              this.$toastr.error(response.message, `${this.error}`);

              return;
            } else {
              this.$toastr.success(`${response.message}`, `${this.success}`);
              this.closeModal();
              this.$emit("updated");
            }
          },
          body,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/pages/groups.scss";
</style>
