<template>
  <div class="investor-preview-portfolio-component">
    <div class="portfolio-container">
      <h1>Portfolios</h1>
      <div v-if="portfolios.length">
        <div
          class="portfolio"
          v-for="(item, index) in portfolios"
          :key="index"
          v-if="index < 2"
        >
          <label>
            <span><img :src="portfolio_icon" alt="" /></span>{{ item.name }}
          </label>
          <div class="info">
            <label>Initial balance</label>
            <p>$ {{ formatter(item.initial_balance) }}</p>
          </div>
          <div class="info">
            <label>Current balance</label>
            <p>$ {{ formatter(item.current_balance) }}</p>
          </div>
          <div class="info">
            <label>Nav</label>
            <p>$ {{ formatter(item.nav) }}</p>
          </div>
        </div>
      </div>
      <AlertNoInformation v-else :isDark="false" />
    </div>
  </div>
</template>

<script>
import AlertNoInformation from "../../../commons/alerts/AlertNoInformation.vue";
import PortfolioIcon from "../../../../../public/common/portfolio-sidebar.svg";
import number_formatter from "../../../../helpers/formatters/number_formatter";
import { mapState } from "vuex";

export default {
  name: "InvestorPreviewPortfolio",
  data() {
    return {
      portfolio_icon: PortfolioIcon,
    };
  },
  components: {
    AlertNoInformation,
  },
  methods: {
    formatter(number) {
      return number_formatter(number);
    },
  },
  computed: {
    ...mapState("portfolios", ["portfolio_list_preview"]),
    portfolios() {
      return this.portfolio_list_preview && this.portfolio_list_preview._data
        ? this.portfolio_list_preview._data.data
        : null;
    },
  },
};
</script>
