<template>
	<div class="item-data mb-2" >
    <div class="label">
      Token
    </div>
    <div class="description"  v-b-tooltip.hover :title="order.token" @click="copyToken(order.token)" style="cursor: pointer;">
      {{order.token.substr(0, 25)}}...
    </div>
    <div class="label mt-2">
      Symbol
    </div>
    <div class="description">
      <span>
        ({{order.type ? 'BUY': 'SELL'}}) {{order.symbol.toUpperCase()}}
      </span>
    </div>

    <div class="label mt-2">
      Open price
    </div>
    <div class="description mono">
      <span class="cut_text_200">
        {{$helpers.numberFormat(order.signal_price)}} 
      </span> 

      <div class="float-right" >
        <div class="mono" v-b-tooltip.hover title="Triggers: StopLoss / Takeprofit" v-if="order.stop_loss">
          <span class="text-muted">
            ~ {{(order.stop_loss * 100).toFixed(2)}}%
          </span> 
          <span class="text-muted">
            / 
          </span>
          <span class="text-muted">
            ~ {{(order.take_profit * 100).toFixed(2)}}%
          </span>
        </div>
      </div>
    </div>
    <div class="label mt-2">
      Open date
    </div>
    <div class="description mono">
      <span>
        {{order.open_date_at.substr(0,19)}}
      </span> 
    </div>

    <div class="label mt-2"  v-if="order.trailing_step">
      Trailing
    </div>
    <div class="description mono" v-if="order.trailing_step">
      <span v-b-tooltip.hover :title="'Trailing step / Trailing activation'">
        {{(order.trailing_step * 100).toFixed(2)}}% / {{(order.trailing_activation * 100).toFixed(2)}}%
      </span> 
    </div>
    
    <div class="label mt-2">
      Lot open
    </div>
    <div class="description mono">
      <span v-b-tooltip.hover title="Conversion Lot">
      	<span v-if="order.type == 0 && order.symbol.toLowerCase() == 'btcusdt' && isSpot">
        	{{$helpers.numberFormat(order.perfomance_open_lot)}}
      	</span>
      	<span v-else>
        	{{$helpers.numberFormat(order.perfomance_open_lot)}}
      	</span>
      </span> 
      <div class="float-right">
        <div class="mono text-muted" v-b-tooltip.hover title="Open lot">
          {{$helpers.numberFormat(order.open_lot)}} 
        </div>
      </div>
    </div>

    <div class="label mt-2" v-if="$props.order.total_orders_blended > 0">
      Total blended orders
    </div>
    <div class="description mono" v-if="$props.order.total_orders_blended > 0">
      <span v-b-tooltip.hover title="Conversion Lot">
        <span >
          {{$props.order.total_orders_blended}}
        </span>
      </span> 
    </div>

    <div class="label mt-2" >
      Unrealized PNL
    </div>
    <div class="description mono" >
      <span v-b-tooltip.hover title="Conversion Earnings" :class="[order.profit_coin >= 0 ? 'text-success' : 'text-danger']">
        <span v-if="order.type == 0 && order.symbol.toLowerCase() == 'btcusdt' && isSpot">
        	{{$helpers.numberFormat(order.profit_coin ? order.profit_coin : 0)}}
      	</span>
      	<span v-else>
        	{{$helpers.numberFormat(order.profit_coin ? order.profit_coin : 0)}}
      	</span>
      </span> 
      <div class="float-right">
        <div class="mono" :class="[order.profit_percentage >= 0 ? 'text-success' : 'text-danger']" v-b-tooltip.hover title="Earnings in percentage">
          ~ {{order.profit_percentage ? order.profit_percentage.toFixed(2) : 0}}%
        </div>
      </div>
    </div>

    <div class="label mt-2" >
      Algorithm
    </div>
      <div class="description mono" >
        <span v-b-tooltip.hover title="Conversion Earnings"">
          {{order.condition_token | nameAlgorithm($store.state.info_investor && $store.state.info_investor.conditions ? $store.state.info_investor.conditions : null)}}
        </span> 
      </div>

    
    <div class="label mt-2" v-if="$store.state.user.role_id && $store.state.user.role_id === 1">
      Last update
    </div>
    <div 
    	class="description mono"
    	:class="[order.last_updated_socket / 60  >= 5 ? 'text-danger': null, order.animated && setAnimate ? 'animate__pulse_text' : null]"
    	v-if="order.last_updated_socket  && $store.state.user.role_id && $store.state.user.role_id === 1"
    >
      {{order.last_updated_socket | parseSeconds}}

    </div>
    <div class="description mono" v-if="parseInt(order.last_updated_socket) == 0 && $store.state.user.role_id && $store.state.user.role_id === 1">
      ---
  	</div>

    <!-- Este bloque es solo visible si el 
    administrador tiene una sesion activa
    mienstras no.. el investor no ve este bloque con
    o sin sesion -->
    <div  v-if="$store.state.user.token" class="mt-2 mb-1">
      <div class="description mono text-right admin-options">
      	<span class="mr-2" v-b-tooltip.hover title="Inmediate closing" v-if="inClosingLoading && ($store.state.user.role_id === 1 || $store.state.user.role_id == 2)">
	        <b-icon icon="arrow-repeat" animation="spin-pulse"></b-icon>
	      </span>
        <span 
          v-b-tooltip.hover title="Inmediate closing"
          class="mx-1"
          v-if="!inClosingLoading && $store.state.user && ($store.state.user.role_id === 1 || $store.state.user.role_id == 2) && $route.name.toLowerCase() == 'investor'"
        >
          <b-icon-x @click="handleCloseInmediateOrder(order)"></b-icon-x>
        </span>
        <span v-b-tooltip.hover title="Show json order">
          <b-icon-braces @click="showOrders(order.token, order.condition_token)"></b-icon-braces>
        </span>
        <span v-b-tooltip.hover title="Show logs" class="ml-2 pointer" @click="showOrders(order.token, order.condition_token, true)">
          <b-icon-list-check></b-icon-list-check>
        </span>

        <span
          v-b-tooltip.hover
          title="Show blended orders"
          class="ml-2 pointer"
          @click="showAccumBlendedOrders"
          v-if="order.profits_by_orders_blended"
        >
          <b-icon-card-checklist></b-icon-card-checklist>
        </span>
      
      </div>
    </div>

    <TableBlendedOrder
      v-if="order.accum_orders"
      :orders_blended="order.accum_orders"
      :orders_blended_profits="order.profits_by_orders_blended"
      :order_token="order.token"
      ref="table_blended_order"
    />
  </div>
</template>
<script>
	import moment from 'moment';
  import TableBlendedOrder from '@/components/pages/investors/TableBlendedOrder';

	export default {
		name: 'CardOpenedOrder',
    components: {
      TableBlendedOrder,
    },
		props: {
			order: {
				type: Object,
				required: true,
			},
			inClosingLoading: {
				type: Boolean,
				required: true,
				default: false,				
			},
      setAnimate: {
        type: Boolean,
        required: false,
        default: false,
      },
		},
    created() {
    },
    data() {
      return {
        isSpot: true,
        saving: false,
      };
    },
		methods: {
			showOrders(token, condition_token, showLog=false) {
        this.$emit('handleShowOrders', token, condition_token, showLog);
      },
      handleCloseInmediateOrder(order) {
        this.$emit('handleCloseInmediateOrder', order);
      },
      copyToken(token) {
        this.$helpers.copyTextToClipboard(token, this);
      },
      showProfitBlendedOrders() {
        this.$bvModal.show(`orders_blended_profits_${this.$props.order.token}`)
      },
      showAccumBlendedOrders() {
        setTimeout(() =>{
          this.$refs.table_blended_order.showModal();
        }, 500);
      }
		},
    watch: {
      '$store.state.info_investor': function(investor) {
        this.isSpot = investor.info.investor.is_spot;
      }
    },
		filters: {
			parseSeconds(seconds)  {
				if (seconds > 60) {
					if (seconds >= 3600) {
						seconds /= 3600;
						return `${parseInt(seconds)} Hour(s)`;
					}
					seconds /= 60;
					return `${parseInt(seconds)} Minute(s)`;
				}
				return `${seconds} Second(s)`;
			},
			parseTime(date) {
				let end = moment(new Date()); //todays date
				let now = moment(new Date(date)); //todays date
				let duration = moment.duration(end.diff(now));
				let sec = duration.asSeconds();
				if (sec < 0) {
					return '';
				}
				if (sec > 60) {
					if (sec >= 3600) {
						sec /= 3600;
						return `${parseInt(sec)} Hour(s)`;
					}
					sec /= 60;
					return `${parseInt(sec)} Minute(s)`;
				}
				return `${sec} Second(s)`;
			},
      nameAlgorithm(condition_token, conditions) {
        if (!conditions) {
          return "---";
        }
        let condition = conditions.find(c => c.token == condition_token);
        if (!condition) {
          return "---";
        }
        return `${condition.condition.name ? condition.condition.name : '---'}`;
      }
		},
	};
</script>