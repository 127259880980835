<template>
  <div>
    <div v-if="$store.state.select_accounts.length > 0">
      <span class="mr-2 mb-2" style="font-size: 16px"
        >({{ investors.length }})</span
      >
      <!-- <b-button class="mr-2" @click="handleReportDownload()" :disabled="selecting">Download Reports</b-button> !-->
      <b-button class="mr-2 mb-2" @click="totalClose()" :disabled="selecting"
        >Total Close</b-button
      >
      <b-button
        class="mr-2 mb-2"
        @click="showModalSendOrder()"
        :disabled="selecting"
        >New order</b-button
      >
      <b-button
        class="mr-2 mb-2"
        @click="closeOrderByProfit()"
        :disabled="selecting"
        >Close orders by profit and symbol</b-button
      >
      <b-button
        class="mr-2 mb-2"
        @click="closeOrderByProfitAllSymbol()"
        :disabled="selecting"
        >Close orders by profit all symbol</b-button
      >
      <b-button
        class="mr-2 mb-2"
        @click="archiveAccounts()"
        :disabled="selecting"
        >Archive Accounts</b-button
      >

      <a href="javascript:;"
        ><b-button
          class="mr-2 mb-2"
          @click="deleteAccounts()"
          :disabled="selecting"
          v-if="$store.state.user && $store.state.user.role_id == 1"
          >Delete Accounts</b-button
        ></a
      >

      <b-button
        class="mr-2 mb-2"
        @click="$store.commit('set_turn_accounts', true)"
        :disabled="$store.state.turn_accounts"
        >Turn accounts</b-button
      >
      <b-button
        class="mr-2 mb-2"
        @click="$store.commit('unselect_all_investors_from_select')"
        >Unselect All</b-button
      >
    </div>
    <b-modal
      id="modal-date-range-report"
      hide-footer
      hide-header
      size="sm"
      ok-only
    >
      <div class="modal-create-backtest">
        <h3 class="modal-title d-flex justify-content-between">
          <span>Select a date range to download the reports</span>
        </h3>
        <div class="row">
          <div class="col-md-12 text-center">
            <BaseDateRangePicker
              v-model="dateRange"
              format="YYYY-MM-DD"
              :showLabel="false"
              label="Date Range"
              :maxDate="new Date()"
              :dateDefault="dateRange"
              :timepicker="true"
            />
          </div>
          <div class="col-md-12 text-center mt-2">
            <b-button variant="success" class="w-100" @click="downloadReports()"
              >Download</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-close-orders-by-profit-and-symbols"
      title="Close orders by profit"
      hide-footer
      size="lg"
      ok-only
    >
      <div class="row">
        <div class="col-md-12">
          <b-form-select
            v-model="selected_symbol_by_profit"
            multiple
            :options="open_symbols"
            id="open_symbols"
          ></b-form-select>
        </div>

        <div class="col-md-12 my-4">
          <label for="range-1" style="margin-bottom: 0px"
            >Select max profit</label
          >
          <b-form-input
            id="range-1"
            v-model="total_close_by_profit"
            type="range"
            min="-100"
            max="100"
            step="0.01"
          ></b-form-input>
          <div class="mt-0">
            Select percentage: {{ total_close_by_profit }}%
          </div>
        </div>

        <div class="col-md-3">
          <b-button
            class="ml-auto"
            v-b-tooltip.hover
            title="Close by symbol"
            @click="handleAllCloseOrderByProfit"
            :disabled="
              selected_symbol_by_profit == null || total_close_by_profit == 0
            "
          >
            <b-icon icon="check"></b-icon> Close
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-close-orders-by-profit-all-symbols"
      title="Close orders by profit"
      hide-footer
      size="lg"
      ok-only
    >
      <div class="row">
        <div class="col-md-12 my-4">
          <label for="range-1" style="margin-bottom: 0px"
            >Select max profit</label
          >
          <b-form-input
            id="range-1"
            v-model="total_close_by_profit"
            type="range"
            min="-100"
            max="100"
            step="0.01"
          ></b-form-input>
          <div class="mt-0">
            Select percentage: {{ total_close_by_profit }}%
          </div>
        </div>

        <div class="col-md-3">
          <b-button
            class="ml-auto"
            v-b-tooltip.hover
            title="Close by symbol"
            @click="handleAllCloseOrderByProfitAllSymbol"
            :disabled="total_close_by_profit == 0"
          >
            <b-icon icon="check"></b-icon> Close
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal id="modal-send-orders" title="Send order" hide-footer size="lg">
      <InvestorOrdersForm
        ref="investor_orders_form"
        :return_form="true"
        @sendOrder="sendOrder"
      />
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import BaseDateRangePicker from "@/components/commons/BaseDateRangePicker";
import InvestorOrdersForm from "@/components/pages/investors/InvestorOrdersForm";

export default {
  name: "MultiSelectAccounts",
  components: {
    BaseDateRangePicker,
    InvestorOrdersForm,
  },
  created() {
    let date_start = new Date();
    let date_end = new Date();
    date_start.setDate(date_start.getDate() - 7);
    this.date_report_star = date_start.toISOString().substr(0, 10);
    this.date_report_end = date_end.toISOString().substr(0, 10);
  },
  data() {
    return {
      selecting: false,
      date_report_star: "",
      date_report_end: "",
      dateRange: "",
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
      <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
      </svg> Error`,
      selected_symbol_by_profit: null,
      total_close_by_profit: 0,
      open_symbols: [],
      investors_tokens: [],
      investors_tokens_datos: {},
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
          <g clip-path="url(#clip0_1634_940)">
          <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
          </g>
          <defs>
          <clipPath id="clip0_1634_940">
          <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
          </clipPath>
          </defs>
          </svg> Success`,
    };
  },
  computed: {
    investors() {
      return this.$store.state.select_accounts;
    },
  },
  methods: {
    async archiveAccounts() {
      const investors = [...this.investors].length;
      const token = [...this.investors].map((investor) => investor.info.token);
      this.selecting = true;
      for (let i = 0; i < investors; i++) {
        await this.$store.dispatch("bwfs_archive_investor", {
          vm: this,
          data: {
            investor_token: token[i],
          },
        });
        this.$store.commit("unselect_investor_from_select", token[i]);
      }
      this.selecting = false;
    },
    async totalClose() {
      const total_investors = [...this.investors].length;
      const token = [...this.investors].map((investor) => investor.info.token);
      let tokens = [];

      for (let i = 0; i < total_investors; i++) {
        /*if (!parseInt(this.investors[i].info.investor.is_real)) {
          alert("You should only select real and turned off accounts.");
          break;
        }*/
        /*if (parseInt(this.investors[i].info.investor.status) != 4) {
          alert("You should only select real and turned off accounts.");
          break;
        }*/
        tokens.push(token[i]);
      }
      data = {
        investor_token: tokens.join(","),
      };
      await this.$store.dispatch("bwfs_total_close", {
        vm: this,
        data,
      });
    },
    closeOrderByProfit() {
      this.$bvModal.show("modal-close-orders-by-profit-and-symbols");
    },
    closeOrderByProfitAllSymbol() {
      this.$bvModal.show("modal-close-orders-by-profit-all-symbols");
    },
    handleReportDownload() {
      this.$bvModal.show("modal-date-range-report");
    },
    showModalSendOrder() {
      this.$bvModal.show("modal-send-orders");
    },
    handleAllCloseOrderByProfit() {
      if (!confirm("Are sure?")) {
        return;
      }
      const data = {
        investor_token: this.investors_tokens.join(","),
        symbol: this.selected_symbol_by_profit.join(","),
        profit: this.total_close_by_profit,
      };
      this.$bvModal.hide("modal-close-orders-by-profit-and-symbols");
      this.$http
        .server1(
          "bwfs/admin/investor/close/all_symbol/by_profit_and_investor",
          data
        )
        .then((res) => {
          this.loadingReport = false;
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.$toastr.success(`${res.message}`, `${this.success}`);
        })
        .catch((e) => {
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    handleAllCloseOrderByProfitAllSymbol() {
      if (!confirm("Are sure?")) {
        return;
      }
      const data = {
        investor_token: this.investors_tokens.join(","),
        profit: this.total_close_by_profit,
      };
      this.$bvModal.hide("modal-close-orders-by-profit-all-symbols");
      this.$http
        .server1(
          "bwfs/admin/investor/close/all_symbol/by_profit_and_investor",
          data
        )
        .then((res) => {
          this.loadingReport = false;
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.$toastr.success(`${res.message}`, `${this.success}`);
        })
        .catch((e) => {
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    getTotalOpenOrdersBySymbol(investor_token) {
      if (this.investors_tokens_datos[investor_token]) {
        let data_symbol = this.investors_tokens_datos[investor_token];
        for (let i = 0; i < data_symbol.length; i++) {
          let current_symbol = data_symbol[i];
          let exist = this.open_symbols.find(
            (e) => e.text.toLowerCase() == current_symbol.symbol.toLowerCase()
          );
          if (!exist) {
            this.open_symbols.push({
              text: `${current_symbol.symbol.toUpperCase()}`,
              value: current_symbol.symbol,
            });
          }
        }
        return;
      }

      const data = {
        investor_token,
      };

      this.$http
        .server1("bwfs/admin/investors/total_open_orders", data)
        .then((res) => {
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }

          if (!this.open_symbols.length) {
            this.open_symbols.push({
              value: null,
              text: "Please select an symbol",
            });
          }
          this.investors_tokens_datos[investor_token] = res.data;
          res.data.map((elem) => {
            let exist = this.open_symbols.find(
              (element) => element.value == elem.symbol.toLowerCase()
            );
            if (!exist) {
              this.open_symbols.push({
                text: `${elem.symbol.toUpperCase()}`,
                value: elem.symbol,
              });
            }
            // #1. Search symbol to investor for delete
            // #2.
          });
        })
        .catch((e) => {
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    async downloadReports() {
      this.selecting = true;
      let files = [];
      const investors = [...this.investors].length;
      if (this.date_report_star == "" || this.date_report_end == "") {
        this.$toastr.error("Please select date range", `${this.error}`);
        return;
      }
      this.$bvModal.hide("modal-date-range-report");
      for (let i = 0; i < investors; i++) {
        const data = {
          investor_token: this.investors[i].info.investor.token_user,
          start: this.date_report_star,
          end: this.date_report_end,
        };
        const res = await this.$http.server1(
          "bwfs/admin/investors/report",
          data
        );
        if (res.error) {
          this.$toastr.error(`${res.message}`, `${this.error}`);
          continue;
        }
        let now = moment().format("DD MM YYYY hh:mm:ss");

        let download = this.$http.url_api(true) + "/bwfs/static/" + res.message;
        let filename = `investors_${this.investors[i].info.token}_${now}.xlsx`;
        files.push({
          download,
          filename,
        });
      }
      this.downloadfiles(files);
      this.selecting = false;
    },
    downloadfiles(files) {
      // Posible Helper para descargar multiples archivos con multiples requests
      // var self = this;
      function download_next(i) {
        if (i >= files.length) {
          // self.$store.commit('unselect_all_investors_from_select');
          return;
        }
        window.open(files[i].download, "_blank");
        setTimeout(function () {
          download_next(i + 1);
        }, 500);
        return;
        // var a = document.createElement('a');
        a.href = files[i].download;
        a.target = "_parent";
        // Use a.download if available, it prevents plugins from opening.
        if ("download" in a) {
          a.download = files[i].filename;
        }
        // Add a to the doc for click to work.
        (document.body || document.documentElement).appendChild(a);
        a.click();
        // Delete the temporary link.
        a.parentNode.removeChild(a);
        // Download the next file with a small timeout. The timeout is necessary
        setTimeout(function () {
          download_next(i + 1);
        }, 500);
      }
      // Initiate the first download.
      download_next(0);
    },
    async deleteAccounts() {
      const investors = [...this.investors];
      const length = [...this.investors].length;

      this.selecting = true;
      if (confirm("are you sure?")) {
        for (let i = 0; i < length; i++) {
          if (investors[i].info.investor.block_account === 1) {
            const password = prompt(
              "Introduce the block account password to delete the account"
            );
            if (password === null) {
              this.$store.commit(
                "unselect_investor_from_select",
                investors[i].info.token
              );
              continue;
            }
            if (
              password !== investors[i].info.investor.block_account_password
            ) {
              this.$toastr.error("wrong password", `${this.error}`);
              this.$store.commit(
                "unselect_investor_from_select",
                investors[i].info.token
              );
              continue;
            }
          }
          const data = {
            investor_token: investors[i].info.token,
            comission:
              investors[i].conditions.length > 0 &&
              investors[i].conditions[0].condition.base_configuration
                ? investors[i].conditions[0].condition.base_configuration
                    .comission
                : "",
            balance: investors[i].info.investor.balance,
          };
          /*if (this.investors[i].info.investor.status !== 4 || this.investors[i].info.investor.status !== 8) {
            try {
              const payload = {
                vm: this,
                data : {
                  investor_token: token,
                  on: 0,
                }
              };
                this.$store.commit('handle_toggle_account', payload);
            } catch (e) {
              console.log(e);
            }     
          }*/
          await this.$store.dispatch("bwfs_delete_investor", {
            vm: this,
            data,
          });
          this.$store.commit(
            "unselect_investor_from_select",
            investors[i].info.token
          );
          this.$toastr.success(
            `${i + 1} of ${length} accounts deleted`,
            `${this.success}`
          );
        }
        // this.$store.commit('unselect_all_investors_from_select');
      }
      this.selecting = false;
    },
    updateSymbols(investors) {
      let investor_pop = null;
      for (let i = 0; i < this.investors_tokens.length; i++) {
        let current_investor = this.investors_tokens[i];
        let found = investors.find((e) => e.info.token == current_investor);
        if (!found) {
          investor_pop = current_investor;
          break;
        }
      }
      let data_symbols = this.investors_tokens_datos[investor_pop].map(
        (e) => e.symbol
      );
      let investors_tokens = Object.keys(this.investors_tokens_datos);
      for (let i = 0; i < data_symbols.length; i++) {
        let symbol = data_symbols[i];
        let exist_symbol = undefined;

        for (let x = 0; x < investors_tokens.length; x++) {
          let current_investor = investors_tokens[x];
          if (investor_pop == current_investor) {
            continue;
          }
          exist_symbol = this.investors_tokens_datos[current_investor].find(
            (e) => e.symbol.toLowerCase() == symbol.toLowerCase()
          );
        }
        if (!exist_symbol) {
          this.open_symbols = this.open_symbols.filter(
            (e) => e.text.toLowerCase() != symbol.toLowerCase()
          );
        }
      }
    },
    sendOrder(configuration) {
      this.$refs.investor_orders_form.send_order = 1;
      configuration["investors_tokens"] = this.investors_tokens.join(",");
      this.$http
        .server1("bwfs/admin/InvestorExpertPanel/create_an_order_3", data)
        .then((res) => {
          this.$refs.investor_orders_form.send_order = 0;
          if (res.error) {
            this.$toastr.error(`${res.msg}`, `${this.error}`);
            return;
          }
          this.$bvModal.hide("modal-send-orders");
          this.$toastr.success("Success send order", `${this.success}`);
        })
        .catch((e) => {
          this.send_order = 0;
          this.$refs.investor_orders_form.send_order = 0;
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
  },
  watch: {
    dateRange: function (newDates) {
      this.date_start = newDates.startDate.substr(0, 10);
      this.date_end = newDates.endDate.substr(0, 10);
    },
    "$store.state.select_accounts": function (value) {
      if (value.length < this.investors_tokens.length) {
        this.updateSymbols(value);
        return;
      }
      this.investors_tokens = [];
      for (var i = 0; i < value.length; i++) {
        let current_element = value[i];
        this.investors_tokens.push(current_element.info.token);
        this.getTotalOpenOrdersBySymbol(current_element.info.token);
      }
    },
  },
};
</script>

<style>
#open_symbols {
  height: 150px !important;
}
</style>
