<template>
  <div class="modal" ref="conditionalOrdersRefs" id="conditionalOrdersRefs">
    <div class="conditional-orders-modal">
      <div class="conditional-orders-modal-header">
        <span class="conditional-orders-modal-title">Conditional Orders</span>
        <span class="conditional-orders-modal-close" @click="close"
          ><img :src="close_icon"
        /></span>
      </div>
      <div></div>
      <div class="conditional-orders-table">
        <Table
          :actions="false"
          :data="conditional_orders"
          :headers="columnHeaders"
          class="ordrs-table"
          title="Orders"
          v-if="conditional_orders.length > 0"
        >
          <template v-slot:side="{ text }">
            <span class="d-flex value">{{ text ? "Buy" : "Sell" }} </span>
          </template>
          <template v-slot:price="{ text }">
            <span class="d-flex value">{{ formatter(text) }} </span>
          </template>
          <template v-slot:qty="{ text }">
            <span class="d-flex value">{{ formatter(text) }} </span>
          </template>
        </Table>
        <AlertInformation
          v-else
          :title="'Conditional Orders Not Found'"
          :description="'There are currently no conditional orders available.'"
        />
      </div>
      <div class="conditional-orders-close-button" @click="close">Close</div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import closeIcon from "../../../../assets/images/close.svg";
import { mapState } from "vuex";
import Table from "../../../commons/table/Table.vue";
import AlertInformation from "../../../commons/alerts/AlertInformation.vue";
import number_formatter from "../../../../helpers/formatters/number_formatter";

export default {
  name: "ConditionalOrdersModal",
  components: {
    Table,
    AlertInformation,
  },
  data() {
    return {
      close_icon: closeIcon,
      columnHeaders: [
        { name: "type", title: "Type", align: "left" },
        { name: "nickname", title: "Nickname", align: "left" },
        { name: "direction", title: "Direction", align: "left" },
        { name: "symbol", title: "Symbol", align: "left" },
        { name: "price", title: "Price", align: "left" },
        { name: "side", title: "Side", align: "left" },
        { name: "qty", title: "Qty", align: "left" },
      ],
    };
  },
  mounted() {
    $("#conditionalOrdersRefs").modal("show");
    const self = this;
    $("#conditionalOrdersRefs").on("hide.bs.modal", function (event) {
      self.$emit("closed");
    });
  },
  computed: {
    ...mapState("dashboard", ["open_orders_json"]),
    conditional_orders() {
      return this.open_orders_json &&
        this.open_orders_json._data.data &&
        this.open_orders_json._data.data.conditional_orders
        ? this.open_orders_json._data.data.conditional_orders
        : [];
    },
  },
  methods: {
    close() {
      $("#conditionalOrdersRefs").modal("hide");
    },
    copyLogs() {
      navigator.clipboard.writeText(JSON.stringify(JSON.stringify(this.log)));
      this.modal.hide();
      this.$toastr.success(
        "The log has been copied successfully.",
        `${this.success}`
      );
    },
    formatter(number) {
      return number_formatter(number);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/pages/dashboard/opened_orders_table.scss";
</style>
