<template>
    <div :class="`component_template__maintenance_mode`">
        <div class="d-block p-2 mb-2">
          <div class="separator">
              Maintenance mode (frontend)
            </div>

            <div class="card p-2 server-information mb-2">
              <b-form-checkbox>
                  Inative
              </b-form-checkbox>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MaintenanceMode',
    components: {
  
    }
  }
  </script>
  