<template>
  <div class="notification-tab">
    <div class="notification-tab-title">Notifications</div>
    <div class="notification-tab-content">
      <ProcessingLoader v-if="notification_on_loading" />
      <AlertErrorInformation v-else-if="notifications_status.error" />
      <div class="" v-else-if="notifications.length > 0">
        <Table
          :actions="true"
          :data="notifications"
          :headers="columnHeaders"
          class="notifications-table"
          title="notifications"
        >
          <template v-slot:initial_balance="{ text }">
            <span class="value"
              ><img :src="dollar_icon" />{{
                text ? formatter(text) : "0"
              }}</span
            >
          </template>
          <template v-slot:current_balance="{ text }">
            <span class="value"
              ><img :src="dollar_icon" />{{
                text ? formatter(text) : "0"
              }}</span
            >
          </template>
          <template v-slot:nav="{ text }">
            <span class="value"
              ><img :src="dollar_icon" />{{
                text ? formatter(text) : "0"
              }}</span
            >
          </template>
          <template v-slot:actions="id">
            <div class="dropdown">
              <span
                class="action-button"
                data-toggle="dropdown"
                data-reference="parent"
                data-offset="-135,0"
              >
                <img :src="menu_icon" />
              </span>
              <ul class="dropdown-menu">
                <li @click="editNotification(id)">
                  <img :src="edit_icon" />Edit
                </li>
                <li @click="deleteNotification(id)">
                  <img :src="trash_icon" />Delete
                </li>
              </ul>
            </div>
          </template>
          <template #pagination>
            <Pagination
              :pagination="paginate"
              :go-first-page-callback="goFirstPage"
              :go-last-page-callback="goLastPage"
              :go-next-page-callback="goNextPage"
              :go-prev-page-callback="goPrevPage"
            />
          </template>
        </Table>
      </div>
      <AlertNoInformation v-else />
    </div>
    <CreateNotificationModal
      :show="show_notification_modal"
      :is-edit="is_edit_notification"
      @set_notification_edit="is_edit_notification = false"
    />
  </div>
</template>
<script>
import AlertErrorInformation from "../../../commons/alerts/AlertErrorInformation.vue";
import AlertNoInformation from "../../../commons/alerts/AlertNoInformation.vue";
import CreateNotificationModal from "./modals/notifications/Index.vue";
import EditWhiteIcon from "../../../../../public/common/edit-white-icon.svg";
import IconCaret from "../../../../../public/common/select-arrow-down.svg";
import MenuOptions from "../../../../../public/common/menu-dots-vertical.png";
import Pagination from "../../../commons/Pagination.vue";
import ProcessingLoader from "../../../commons/alerts/ProcessingLoader.vue";
import Table from "../../../commons/table/Table.vue";
import TrashIcon from "../../../../../public/common/trash-icon.svg";
import { mapState } from "vuex";

export default {
  name: "NotificationsTab",
  data() {
    return {
      menu_icon: MenuOptions,
      edit_icon: EditWhiteIcon,
      trash_icon: TrashIcon,
      columnHeaders: [
        {
          name: "action",
          title: "Action",
          align: "left",
        },
        { name: "investor", title: "Investor", align: "left" },
        { name: "portfolio", title: "Portfolio", align: "left" },
        { name: "event", title: "Event", align: "left" },
        { name: "config", title: "Config", align: "left" },
        {
          title: "",
          action: true,
          id: "id",
        },
      ],
      is_edit_notification: false,
      notifications: [
        {
          id: "bb28fc069eec427da9e0adfdd9e39e88",
          action: "Notification",
          investor: "Investor name",
          portfolio: "Portfolio name 01",
          event: "Send order - Market",
          config: "View configuration",
        },

        {
          id: "bb28fc069eec427da9e0adfdd9e39e99",
          action: "Request",
          investor: "Investor name",
          portfolio: "Portfolio name 02",
          event: "Send order - Conditional",
          config: "View configuration",
        },
      ],
      paginate: {
        current_page: 1,
        per_page: 10,
        records: 0,
        total_pages: 0,
      },
      notification_on_loading: false,
      notifications_status: { error: false },
    };
  },
  components: {
    AlertErrorInformation,
    AlertNoInformation,
    CreateNotificationModal,
    Table,
    Pagination,
    ProcessingLoader,
  },
  computed: {
    ...mapState("investor_configuration", ["show_notification_modal"]),
  },
  methods: {
    deleteNotification(id) {},
    editNotification(id) {},
    async performDeleteNotification(body) {},
    goFirstPage() {},
    goLastPage() {},
    goNextPage() {},
    goPrevPage() {},
  },
  mounted() {},
};
</script>
<style lang="scss">
@import "../../../../styles/pages/investor_configuration/notification_tab.scss";
</style>
