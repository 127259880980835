import state from "@/helpers/store/state";
import set from "@/helpers/store/set";

import systems_heartbeat_list from "./actions/systems_heartbeat_list";
import delete_notification_configuration from "./actions/delete_notification_configuration";
import delete_systems_heartbeat from "./actions/delete_systems_heartbeat";
import create_notification_configuration from "./actions/create_notification_configuration";
import create_systems_heartbeat from "./actions/create_systems_heartbeat";
import notification_configuration_list from "./actions/notification_configuration_list";
import update_notification_configuration from "./actions/update_notification_configuration";
import update_systems_heartbeat from "./actions/update_systems_heartbeat";
import systems_health_timeline from "./actions/systems_health_timeline";
import invidual_token_uptime from "./actions/invidual_token_uptime";
import tags_list from "./actions/tags_list";

import algorithm_health_list_filters_adapter from "../../services/adapters/systems_heartbeat_filters";
import common_adapter_data from "../../services/adapters/common/adapter_data";
import common_adapter_list from "../../services/adapters/common/adapter_list";
import common_adapter_data_array from "../../services/adapters/common/adapter_data_array";
import common_adapter_message from "../../services/adapters/common/adapter_message";
import form_notification_configuration from "../../services/adapters/form_notification_configuration";
import form_systems_heartbeat_adapter from "../../services/adapters/form_systems_heartbeat";
import systems_health_timeline_adapter from "../../services/adapters/systems_health_timeline";

import set_algorithm_token from "./mutations/set_algorithm_token";
import set_notification_configuration_form from "./mutations/set_notification_configuration_form";
import set_systems_heartbeat_form from "./mutations/set_systems_heartbeat_form";
import set_systems_heartbeat_filters from "./mutations/set_systems_heartbeat_filters";

export default {
  namespaced: true,
  state: () => ({
    algorithm_token: null,
    systems_heartbeat_filters: { ...algorithm_health_list_filters_adapter },
    systems_heartbeat_list: state(common_adapter_list),
    create_notification_configuration: state(common_adapter_data),
    create_systems_heartbeat: state(common_adapter_data),
    delete_notification_configuration: state(common_adapter_message),
    delete_systems_heartbeat: state(common_adapter_message),
    form_notification_configuration: { ...form_notification_configuration },
    form_systems_heartbeat: { ...form_systems_heartbeat_adapter },
    notification_configuration_list: state(common_adapter_list),
    update_notification_configuration: state(common_adapter_data),
    update_systems_heartbeat: state(common_adapter_data),
    tags_list: state(common_adapter_data_array),
    systems_health_timeline: state(systems_health_timeline_adapter),
    invidual_token_uptime: state(systems_health_timeline_adapter),
  }),
  actions: {
    systems_heartbeat_list,
    delete_notification_configuration,
    delete_systems_heartbeat,
    create_notification_configuration,
    create_systems_heartbeat,
    notification_configuration_list,
    update_notification_configuration,
    update_systems_heartbeat,
    tags_list,
    systems_health_timeline,
    invidual_token_uptime,
  },
  mutations: {
    set,
    set_algorithm_token,
    set_notification_configuration_form,
    set_systems_heartbeat_form,
    set_systems_heartbeat_filters
  },
};
