<template>
  <div>
    <div
      :class="`component_template__user pb-2`"
      v-if="!$store.state.supervisor_has_investor_deleting && !investor_loading"
      :style="
        this.selectInvestor
          ? 'border-bottom: 3px solid #FFF333;'
          : this.on
          ? 'border-bottom: 3px solid #48c9df;'
          : 'border-bottom: 3px solid #dc3545;'
      "
    >
      <InvestorCardChart :investor_info="investors" :open_info="open" />

      <div class="row detail">
        <div class="col-md-12">
          <div
            :class="
              'small' +
              ' ' +
              (investors.info.investor.is_real == 0
                ? 'text-info'
                : 'text-success')
            "
          >
            {{ investors.info.investor.is_real == 0 ? "DEMO" : "REAL ACCOUNT" }}
          </div>
          <div class="title_block">Basic information</div>

          <div>
            <span
              v-b-tooltip.hover
              :title="investors ? investors.info.investor.name : ''"
            >
              {{ investor_short_name }}
            </span>

            <!-- Estod debe de mostrar la nota que tiene 
                        asignado el usuario en caso que la tenga -->
            <span
              v-if="investors.info.investor.note"
              class="float-right"
              style="font-size: 18px"
              v-b-tooltip.hover
              :title="
                investors.info.investor.note
                  ? investors.info.investor.note
                  : 'No notes'
              "
            >
              <b-icon-question></b-icon-question>
            </span>
          </div>
          <div class="text-muted small">
            {{ investors ? investors.info.token : "Token" }}
          </div>

          <div
            v-if="
              investors &&
              investors.info.investor.labeling &&
              investors.info.investor.labeling.trim().split(',').length > 0
            "
          >
            <b-button
              variant="dark"
              class="btn-sm"
              style="padding: 1px 4px; margin-right: 2px"
              v-for="label in investors.info.investor.labeling.split(',')"
              :key="label"
              v-if="label"
            >
              {{ label }}
            </b-button>
          </div>
        </div>
      </div>

      <div
        class="row detail mt-2"
        style="border-top: 1px #34363c solid; padding-top: 10px"
      >
        <div class="col-md-12">
          <div class="title_block">Operative</div>

          <div class="small">
            <!-- Aqui seria la fecha de "apertura"
                        de la primera operacion realizada -->
            <span
              class="text-muted small"
              v-b-tooltip.hover
              title="Date of execution of the first operation"
            >
              {{
                investors
                  ? $helpers.formatDateYMD(investors.info.investor.created_at)
                  : "Created at"
              }}
            </span>
            /

            <!-- Aqui seria la fecha de "cierre"
                        de la ultima operacion realizada -->
            <span
              class="text-muted small"
              v-b-tooltip.hover
              title="Date of execution of the last operation"
            >
              {{
                investors ? $helpers.formatDateYMD(closedDate) : "Last Login"
              }}
            </span>
          </div>

          <div class="small">
            <span class="text-muted small">
              {{
                investors.info.investor.time_operation
                  ? `${investors.info.investor.time_operation} operational this account`
                  : operativeDays
              }}
            </span>
          </div>
        </div>
      </div>

      <div
        class="row detail profits mt-2 mb-2"
        style="border-top: 1px #34363c solid; padding-top: 10px"
      >
        <div class="col-md-12">
          <div class="title_block">Resumen</div>

          <div class="mono d-block">
            <span class="text-muted small d-block">
              <span v-b-tooltip.hover title="Pending"> {{ pending }} </span> /
              <span
                :style="open > 0 ? 'color: #50E7FF' : ''"
                v-b-tooltip.hover
                title="Open"
              >
                {{ open }}
              </span>
              / <span v-b-tooltip.hover title="Close"> {{ close }} </span> /
              <span v-b-tooltip.hover title="Cancelled"> {{ cancelled }} </span>
            </span>

            <span
              :class="
                investors ? getColorBalance : 'small text-success d-block mt-1'
              "
            >
              {{
                investors
                  ? investors.resumen
                    ? `(${$helpers.numberFormat(investors.resumen.profit_net)})`
                    : "(0)"
                  : "(0)"
              }}
            </span>

            <span
              class="small d-block mt-1"
              :class="
                investors.resumen
                  ? investors.resumen.profit_percentage > 0
                    ? 'text-success'
                    : 'text-danger'
                  : 'text-success'
              "
            >
              {{ lastMonthPerformanceSymbol
              }}{{
                investors.resumen
                  ? investors.resumen.profit_percentage.toFixed(2)
                  : 0
              }}%
            </span>
          </div>
        </div>
      </div>

      <div
        id="button-investor-card"
        class="row detail menu mt-2"
        style="border-top: 1px #34363c solid; padding-top: 10px"
      >
        <div
          :class="[
            !supervisor_has_investor ? 'col-md-12' : 'col-md-12',
            addMarginTopIcons,
          ]"
        >
          <div v-if="!supervisor_has_investor && !$store.state.migration_mode">
            <a href="javascript:;">
              <span
                v-b-tooltip.hover
                class="mr-2"
                title="View and modify"
                @click="showHandleEdit"
              >
                <b-icon-gear></b-icon-gear>
              </span>
            </a>
            <a href="javascript:;">
              <span v-b-tooltip.hover class="mr-2" title="Open dashboard">
                <b-icon-box-arrow-up-right
                  @click="handleDashboardSelector(investors.info.token)"
                ></b-icon-box-arrow-up-right>
              </span>
            </a>
            <a href="javascript:;" v-if="!supervisor_has_investor">
              <span v-b-tooltip.hover class="mr-2" title="Download report">
                <b-icon-cloud-download
                  @click="handleReportDownload(investors.info.token)"
                  v-if="!loadingReport"
                ></b-icon-cloud-download>
                <b-icon
                  icon="arrow-repeat"
                  animation="spin-pulse"
                  v-else
                ></b-icon>
              </span>
            </a>
            <a href="javascript:;" @click="duplicateInvestor()">
              <span v-b-tooltip.hover class="mr-2" title="Duplicate account">
                <b-icon-front></b-icon-front>
              </span>
            </a>
            <a
              href="javascript:;"
              @click="
                $store.state.investor_is_archived == 0
                  ? archiveInvestor(1)
                  : archiveInvestor(0)
              "
            >
              <span
                v-b-tooltip.hover
                class="mr-2"
                :title="
                  investors.info.investor.archived == 0
                    ? 'Archive account'
                    : 'Desarchive account'
                "
              >
                <b-icon-folder-symlink></b-icon-folder-symlink>
              </span>
            </a>

            <a
              v-if="investors.info.investor.is_real != 0"
              href="javascript:;"
              v-b-modal="'modal-audit__' + investors.info.token"
            >
              <span v-b-tooltip.hover class="mr-2" title="Audit account">
                <b-icon-bezier2 @click="showModalAudit()"></b-icon-bezier2>
              </span>
            </a>

            <a
              href="javascript:;"
              @click="deleteAccount()"
              v-if="$store.state.user && $store.state.user.role_id == 1"
            >
              <span v-b-tooltip.hover class="mr-2" title="Delete account">
                <b-icon-trash></b-icon-trash>
              </span>
            </a>
            <a href="javascript:;">
              <span
                v-b-tooltip.hover
                class="mr-2"
                :title="selectInvestor ? 'Unselect account' : 'Select account'"
              >
                <b-icon-check-square
                  v-if="!selectInvestor"
                  @click="
                    $store.commit('select_investor_for_select', investors)
                  "
                ></b-icon-check-square>
                <b-icon-check-square-fill
                  v-else
                  @click="
                    $store.commit(
                      'unselect_investor_from_select',
                      investors.info.token
                    )
                  "
                ></b-icon-check-square-fill>
              </span>
            </a>
            <a v-if="investors.info.token" href="javascript:;">
              <span v-b-tooltip.hover class="mr-2" title="Transactions">
                <b-icon-file-earmark-ruled
                  @click="handleTransactions(investors.info.token)"
                ></b-icon-file-earmark-ruled>
              </span>
            </a>

            <ToggleOnOffAccount
              :investor="investors"
              :token="investors.info.token ? investors.info.token : ''"
              @handleUpdateIsOn="handleOffInvestor"
            />
          </div>

          <div
            class="d-flex flex-row"
            v-else-if="supervisor_has_investor && !$store.state.migration_mode"
          >
            <h5>
              <span v-b-tooltip.hover class="mr-2" title="Open new tab">
                <router-link
                  :to="`/investor/${investors.info.token}`"
                  target="_blank"
                >
                  <b-icon-box-arrow-up-right></b-icon-box-arrow-up-right>
                </router-link>
              </span>
            </h5>
            <h5>
              <span
                v-b-tooltip.hover
                class="mr-2"
                title="Delete account"
                v-if="$store.state.user && $store.state.user.role_id == 1"
              >
                <b-icon-trash
                  @click="deleteSupervisorHasInvestor()"
                ></b-icon-trash>
              </span>
            </h5>
          </div>
          <div class="d-flex flex-row" v-else-if="$store.state.migration_mode">
            <MigrateAccount :investor="investors" />
            <div style="display: none">
              <ToggleOnOffAccount
                :investor="investors"
                :token="investors.info.token"
                @handleUpdateIsOn="handleOffInvestor"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-add-condition-v"
      hide-footer
      hide-header
      size="xl"
      ok-only
    >
      <div class="modal-create-conditions">
        <h3 class="modal-title">Condition generator</h3>
        <ConditionGenerator ref="conditionGeneratorShow" />
      </div>
    </b-modal>
    <b-modal
      :id="'modal-dashboard-selection' + investors.info.token"
      hide-footer
      hide-header
      size="sm"
      ok-only
      v-if="!investor_loading"
    >
      <div class="modal-create-backtest">
        <h3 class="modal-title d-flex justify-content-between">
          <span>Select the dashboard</span>
        </h3>
        <div class="row">
          <div class="col-md-12 text-center">
            <div class="dashboard-list">
              <router-link
                :to="`/investor/${investors.info.token}`"
                target="_blank"
              >
                <b-icon-box-arrow-up-right></b-icon-box-arrow-up-right>
                Open Dashboard original
              </router-link>
            </div>
            <div class="dashboard-list">
              <router-link
                :to="`/dashboard?token=${investors.info.token}`"
                target="_blank"
              >
                <b-icon-box-arrow-up-right></b-icon-box-arrow-up-right>
                Open Dashboard pro
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      :id="'modal-date-range-report-' + investors.info.token"
      hide-footer
      hide-header
      size="sm"
      ok-only
      v-if="!investor_loading"
    >
      <div class="modal-create-backtest">
        <h3 class="modal-title d-flex justify-content-between">
          <span>Select the report</span>
        </h3>
        <div class="row">
          <div class="col-md-12 text-center mb-2">
            <select
              class="form-control"
              placeholder="Select report type"
              v-model="reportType"
            >
              <option value="reports">Reports</option>
              <option value="orders_reports">Orders Report</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <BaseDateRangePicker
              v-model="dateRange"
              format="YYYY-MM-DD"
              :showLabel="false"
              label="Date Range"
              :dateDefault="dateRange"
              :timepicker="true"
              :everydayMinDate="everydayMinDate"
              :everydayMaxDate="everydayMaxDate"
            />
          </div>
          <div class="col-md-12 text-center mt-2">
            <ProcessingLoader
              v-if="loadingReport"
              class="investor-progress-loader"
            />
            <b-button
              v-else
              variant="success"
              class="w-100 download-button"
              @click="downloadReport"
              >Download</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>

    <div v-if="investor_loading">
      <b-card style="height: 545px; background-color: #1d1e25">
        <b-skeleton-img height="200px"></b-skeleton-img>
        <b-skeleton class="mt-2" animation="wave" width="85%"></b-skeleton>
        <b-skeleton class="mt-2" animation="wave" width="70%"></b-skeleton>
        <b-skeleton class="mt-2" animation="wave" width="70%"></b-skeleton>
        <b-skeleton class="mt-2" animation="wave" width="70%"></b-skeleton>
        <b-skeleton class="mt-2" animation="wave" width="40%"></b-skeleton>
        <b-skeleton class="mt-2" animation="wave" width="50%"></b-skeleton>
        <b-skeleton class="mt-2" animation="wave" width="60%"></b-skeleton>
        <b-skeleton class="mt-2" animation="wave" width="70%"></b-skeleton>
        <b-skeleton class="mt-2" animation="wave" width="80%"></b-skeleton>
        <div class="d-flex flex-row">
          <b-skeleton class="mt-1" type="button"></b-skeleton>
          <b-skeleton class="mt-1" type="button"></b-skeleton>
          <b-skeleton class="mt-1" type="button"></b-skeleton>
        </div>
      </b-card>
    </div>
    <div v-if="$store.state.supervisor_has_investor_deleting">
      <b-card>
        <b-skeleton-img height="200px"></b-skeleton-img>
        <b-skeleton animation="wave" width="85%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
        <div class="d-flex flex-row">
          <b-skeleton type="button"></b-skeleton>
          <b-skeleton type="button"></b-skeleton>
          <b-skeleton type="button"></b-skeleton>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import InvestorCardChart from "./InvestorCardChart.vue";
import ToggleOnOffAccount from "./ToggleOnOffAccount.vue";

import MigrateAccount from "./MigrateAccount.vue";
import Helpers from "@/helpers/commons.js";
import moment from "moment";
import BaseDateRangePicker from "@/components/commons/BaseDateRangePicker";

import { saveAs } from "file-saver";
import ProcessingLoader from "../../commons/alerts/ProcessingLoader.vue";

export default {
  name: "UserComponent",
  props: ["openTransactionModal", "value", "sup_has_investor"],
  components: {
    BaseDateRangePicker,
    InvestorCardChart,
    MigrateAccount,
    ProcessingLoader,
    ToggleOnOffAccount,
  },
  data() {
    return {
      investors: this.value,
      show: false,
      supervisor_has_investor: this.sup_has_investor,
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
      <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
      </svg> Error`,
      lastYear: false,
      lastMonth: false,
      investor_loading: true,
      loadingReport: false,
      on: true,
      token_investor: this.value,
      date_report_star: "",
      date_report_end: "",
      token: "",
      dateRange: "",
      reportType: "reports",
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
          <g clip-path="url(#clip0_1634_940)">
          <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
          </g>
          <defs>
          <clipPath id="clip0_1634_940">
          <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
          </clipPath>
          </defs>
          </svg> Success`,
    };
  },
  created() {
    let date_start = new Date();
    let date_end = new Date();
    date_start.setDate(date_start.getDate() - 7);
    this.date_start = date_start.toISOString().substr(0, 10);
    this.date_end = date_end.toISOString().substr(0, 10);
  },
  computed: {
    selectInvestor() {
      const select = this.$store.state.select_accounts.filter(
        (t) =>
          t.info.investor.token_user === this.investors.info.investor.token_user
      );
      if (select.length > 0) return true;
      return false;
    },
    getColorBalance() {
      if (this.investors.resumen && this.investors.resumen.profit_net) {
        if (this.investors.resumen.profit_net < 0) {
          return "small text-danger float-right ml-1";
        }
      }
      return "small text-success float-right ml-1";
    },
    lastMonthPerformanceSymbol() {
      if (this.investors.resumen && this.investors.resumen.profit_percentage) {
        if (this.investors.resumen.profit_percentage > 0) {
          return "+";
        }
      }
      return "";
    },
    lastMonthDate() {
      let current_date = moment();
      let previousMonth = current_date.subtract(1, "months");
      return previousMonth.format("YYYY-MM");
    },
    lastYearDate() {
      let current_date = moment();
      let previousYear = current_date.subtract(1, "years");
      return previousYear.format("YYYY");
    },
    pending() {
      if (
        this.investors &&
        this.investors.info &&
        this.investors.info.investor.orders_pending
      ) {
        return this.investors.info.investor.orders_pending;
      }
      return 0;
    },
    open() {
      if (
        this.investors &&
        this.investors.info &&
        this.investors.info.investor.orders_open
      ) {
        return this.investors.info.investor.orders_open;
      }
      return 0;
    },
    close() {
      if (
        this.investors &&
        this.investors.info &&
        this.investors.info.investor.orders_close
      ) {
        return this.investors.info.investor.orders_close;
      }
      return 0;
    },
    cancelled() {
      if (
        this.investors &&
        this.investors.info &&
        this.investors.info.investor.orders_cancelled
      ) {
        return this.investors.info.investor.orders_cancelled;
      }
      return 0;
    },
    operativeDays() {
      if (this.investors) {
        const close = this.investors.info.investor.orders_close;
        if (close == 0 || close == undefined) {
          return "Account recently started";
        }
        if (!this.investors.grafic[close]) {
          return "Account recently started";
        }
        let current_date = moment(
          this.investors.grafic[close].close_date_at
        ).format("YYYY-MM-DD");
        current_date = moment(current_date);
        let created_date = moment(this.investors.info.created_at).format(
          "YYYY-MM-DD"
        );
        created_date = moment(created_date);
        let days = current_date.diff(created_date, "days");
        let operative_day =
          days > 0
            ? days +
              (days == 1
                ? " day operational this account"
                : " days operational this account")
            : "";
        if (days < 1) {
          return "Account recently started";
        }
        return operative_day;
      }
      return "0 Days";
    },
    investor_short_name() {
      if (!this.investors && !this.investors.info.investor.name) {
        return "";
      }

      let name = this.investors.info.investor.name;

      if (name.length > 15) {
        name = name.substr(0, 15) + "...";
      }
      return name;
    },
    closedDate() {
      if (
        this.investors &&
        this.investors.info &&
        this.investors.info.investor &&
        this.investors.info.investor.last_operation_date
      ) {
        return this.investors.info.investor.last_operation_date;
      }
      return this.investors.info.investor.created_at;
    },
    addMarginTopIcons() {
      if (
        this.investors &&
        this.investors.info &&
        this.investors.info.investor &&
        this.investors.info.investor.labeling &&
        this.investors.info.investor.labeling.trim().length > 0
      ) {
        return "mt-0";
      }
      return "mt-1";
    },
  },
  methods: {
    async archiveInvestor(value) {
      await this.$store.dispatch("bwfs_archive_investor", {
        vm: this,
        data: {
          investor_token: this.investors.info.token,
        },
      });
      this.investors.info.investor.archived = value;
      this.fetchInvestor();
    },
    deleteAccount() {
      const body = {
        token: this.investors.info.token,
      };

      Swal.fire({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#1c2030",
        confirmButtonText: "Delete",
        preConfirm: async (value) => {
          await this.performDeleteAccount(body);
        },
      }).then(async (result) => {
        if (result.data) {
        }
      });
    },
    async deleteInvestor() {
      const data = {
        investor_token: this.investors.info.token,
        comission: "",
        balance: this.investors.info.investor.balance,
      };
      if (confirm("are you sure?")) {
        if (this.investors.info.investor.block_account === 1) {
          const password = prompt(
            "Introduce the block account password to delete the account"
          );
          if (
            password !== this.investors.info.investor.block_account_password
          ) {
            this.$toastr.error("wrong password", `${this.error}`);
            return;
          }
        }
        if (
          this.investors.info.investor.status !== 4 ||
          this.investors.info.investor.status !== 8
        ) {
          this.handleOffInvestor(this.investors.info.token, 0);
        }
        this.investor_loading = true;
        await this.$store.dispatch("bwfs_delete_investor", {
          vm: this,
          data,
        });
        if (this.selectInvestor)
          this.$store.commit(
            "unselect_investor_for_select",
            this.investor.info.token
          );
        this.investor_loading = false;
      }
    },
    async deleteSupervisorHasInvestor() {
      const data = {
        token_investor: this.investors.info.token,
        token_supervisor: this.$store.state.supervisor_token,
      };
      await this.$store.dispatch("bwfs_delete_supervisor_has_investor", {
        vm: this,
        data,
      });
    },
    downloadReport() {
      if (
        this.date_start == "" ||
        this.date_end == "" ||
        this.reportType == ""
      ) {
        this.$toastr.error(
          "Please select date range / report type",
          `${this.error}`
        );

        return;
      }

      this.loadingReport = true;
      if (this.reportType == "orders_reports") {
        const data = {
          investor_token: this.token,
          start: this.date_start,
          end: this.date_end,
        };
        this.$http
          .server1("bwfs/admin/investors/report", data)
          .then((res) => {
            this.loadingReport = false;
            if (res.error) {
              this.$toastr.error(`${res.message}`, `${this.error}`);
              if (this.selectInvestor)
                this.$store.commit(
                  "unselect_investor_for_select",
                  this.investor.info.token
                );
              return;
            }
            window.open(
              this.$http.url_api(true) + "/bwfs/static/" + res.message
            );
            if (this.$store.state.download_reports)
              this.$store.commit(
                "unselect_investor_for_select",
                this.investor.info.token
              );
            this.$bvModal.hide("modal-date-range-report-" + this.token);
          })
          .catch((e) => {
            this.loadingReport = false;
            this.$toastr.error(`${e.message}`, `${this.error}`);
            if (this.selectInvestor)
              this.$store.commit(
                "unselect_investor_for_select",
                this.investor.info.token
              );
          });
      } else {
        const data = {
          investors_token: this.token,
          start: this.date_start,
          end: this.date_end,
        };
        this.$http
          .server1("bwfs/v2/investor/generate_investors_report", data)
          .then((res) => {
            this.loadingReport = false;
            if (res.error) {
              this.$toastr.error(`${res.message}`, `${this.error}`);
              if (this.selectInvestor)
                this.$store.commit(
                  "unselect_investor_for_select",
                  this.investor.info.token
                );
              return;
            }
            let file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.content}`;
            saveAs(file, res.filename);
            if (this.$store.state.download_reports)
              this.$store.commit(
                "unselect_investor_for_select",
                this.investor.info.token
              );
            this.$bvModal.hide("modal-date-range-report-" + this.token);
          })
          .catch((e) => {
            this.loadingReport = false;
            this.$toastr.error(`${e.message}`, `${this.error}`);
            if (this.selectInvestor)
              this.$store.commit(
                "unselect_investor_for_select",
                this.investor.info.token
              );
          });
      }
    },
    duplicateInvestor() {
      let investor = { ...this.investors };
      let conditions = [];
      for (let i = 0; i < investor.conditions.length; i++) {
        conditions.push({ ...investor.conditions[i].condition });
      }
      investor.conditions = [];
      investor.conditions = conditions;
      this.$emit("handleShowDuplicate", { ...investor });
    },
    async fetchInvestor() {
      if (!this.value) {
        return null;
      }
      this.investor_loading = true;
      const data = { investor_token: this.value };
      const response = await this.$http.server1(
        "bwfs/admin/investors/usercard",
        data
      );
      if (response.error) {
        let investors = this.$store.state.investors;
        const index = investors.indexOf(
          this.$store.state.investors_error_token
        );

        if (index > -1) {
          investors.splice(index, 1);
          this.investor_loading = false;
          this.$store.commit("set_investors", { investors });
          this.$store.commit("set_investor_error", true);
        }
        return;
      }
      this.investors = response.data[0];
      if (parseInt(this.investors.info.investor.status) !== 2) {
        this.on = false;
      }

      this.everydayMaxDate = new Date();
      let startDate = new Date(this.investors.info.created_at);
      startDate.setDate(startDate.getDate() - 7);
      this.everydayMinDate = startDate;

      this.investor_loading = false;
    },
    handleDashboardSelector(token) {
      this.token = token;
      this.$bvModal.show("modal-dashboard-selection" + this.token);
    },
    handleOffInvestor(isOn) {
      this.on = isOn;
    },
    handleReportDownload(token) {
      this.token = token;
      this.$bvModal.show("modal-date-range-report-" + this.token);
    },
    handleTransactions(token) {
      this.$store.commit("connections/set_investor_token", token);
      this.$store.dispatch("connections/connection_list_selector");
      this.$store.commit("transactions/set_investor_token", token);
      this.$store.dispatch("transactions/transaction_list", {});
      this.openTransactionModal();
    },
    async performDeleteAccount(body) {
      return new Promise((resolve) => {
        this.$store.dispatch("investors_v2/delete_investor", {
          callback: ({ response }) => {
            if (response.error) {
              this.$toastr.error(`${response.message}`, `${this.error}`);
              return;
            } else {
              this.$store.commit("set_investor_delete_token", body.token);
              this.$store.commit("set_investor_is_deleted", true);
              this.$store.commit("set_investor_deleting", false);
              this.$toastr.success(
                "Account deleted successfully.",
                `${this.success}`
              );
            }
            resolve();
          },
          body,
        });
      });
    },
    setMonth() {
      this.lastMonth = !this.lastMonth;
      this.lastYear = false;
    },
    setYear() {
      this.lastYear = !this.lastYear;
      this.lastMonth = false;
    },
    showModalAudit() {
      const investor = { ...this.investors };
      this.$emit("handleAudit", { ...investor });
    },
    showHandleEdit() {
      let investor = { ...this.investors };
      investor.on = this.on;
      this.$emit("handleShowEdit", { ...investor });
    },
  },
  async mounted() {
    this.fetchInvestor();
  },
  watch: {
    dateRange: function (newDates) {
      this.date_start = newDates.startDate.substr(0, 10);
      this.date_end = newDates.endDate.substr(0, 10);
    },
    value(newValue) {
      this.investors = newValue;
      this.fetchInvestor();
    },
    "$store.state.supervisor_has_investor_deleted": async function () {
      if (this.$store.state.supervisor_has_investor_deleted) {
        await this.$store.dispatch("bwfs_get_supervisor", {
          vm: this,
          data: {
            page: 1,
            search: this.$store.state.search_supervisor,
            status: this.$store.state.search_supervisor_status,
          },
        });
        this.$store.commit("set_supervisor_has_investor_deleted", false);
      }
    },
    "$store.state.delete_accounts"(value) {
      if (value) this.deleteInvestor();
    },
    sup_has_investor(newValue) {
      this.supervisor_has_investor = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.order-open {
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 0.1em solid #50e7ff;
  outline: none;
  background: #50e7ff;
}
::v-deep .text-muted {
  color: #9b9b9b !important;
  overflow-wrap: break-word;
}
::v-deep .chart {
  height: auto;
}
::v-deep h5 {
  margin-bottom: 0rem;
}
::v-deep .b-icon.bi {
  font-size: 20px;
}
.blackbox {
  min-height: 215px;
  background: #17181f;
  margin-bottom: 12px;
  .blackbox-text {
    color: #f5f5f;
    font-size: 1.5em;
  }
}
.mgb {
  margin-bottom: 15px;
}
.last-size {
  font-size: 27px !important;
}
.turn-button {
  margin-top: 2px !important;
}

.turn-button svg {
  font-size: 24px !important;
}
@media (min-width: 768px) and (max-width: 1200px) {
  ::v-deep .b-icon.bi {
    font-size: 1rem;
  }
  #button-investor-card {
    margin-top: 0px !important;
  }
}
.dashboard-list {
  text-align: left;
  padding: 5px 0;
  a {
    color: #f5f5f5;
  }
}
.investor-progress-loader {
  padding: 11px !important;
}
.download-button {
  background-color: #4f505a;
  color: white;
  &:hover {
    background-color: #777880;
    color: rgb(255, 255, 255);
  }
}
</style>

<style>
span.float-right.turn-button svg {
  font-size: 25px !important;
  margin-top: 12px;
}
.vue-daterange-picker {
  width: 100% !important;
}
</style>
