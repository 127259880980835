<template>
  <div
    ref="newsModal"
    id="newsModal"
    class="modal fade modal-news"
    tabindex="-1"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">News</h5>
          <span class="icon-close" @click="modal.hide()"
            ><img :src="close_icon"
          /></span>
        </div>
        <div class="modal-body">
          <div>
            <div class="date-container">
              <span>{{ getDate(news.date) }}</span>
              <span><img :src="separator_icon" alt="" /></span>
              <span :class="[news.sentiment < 0 ? 'red' : 'green']"
                >{{ getPercentageSentiment(news.sentiment) }}%</span
              >
            </div>
            <div class="title">
              {{ news.title }}
            </div>
            <div class="link">
              Source:
              <a class="link" target="_blank" :href="news.source">{{
                news.source
              }}</a>
            </div>
            <div class="description">
              {{ news.description }}
            </div>
            <div class="affected-currency">
              <h3>Affected currency</h3>
              <div v-if="news.currency" class="date-container">
                <span><img :src="news.currency.image" height="15" /></span>
                <span>{{ news.currency.name }} coin</span>
                <img :src="separator_icon" alt="" />
                <span>{{ news.currency.symbol }}</span>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button @click="closeModal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import closeIcon from "../../../../assets/images/close.svg";
import SeparatorIcon from "../../../../../public/common/dot-separator.svg";
import BnbIcon from "../../../../../public/common/bnb-icon.svg";

import $ from "jquery";
import moment from "moment";
import { Modal } from "bootstrap";
import { mapState } from "vuex";

export default {
  name: "NewsModal",
  components: { BnbIcon },
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      close_icon: closeIcon,
      modal: null,
      separator_icon: SeparatorIcon,
      coin_icon: BnbIcon,
    };
  },
  mounted() {
    this.modal = new Modal(this.$refs.newsModal);
    const self = this;
    $("#newsModal").on("hide.bs.modal", function (event) {
      self.$emit("closed");
    });
  },
  computed: {
    ...mapState("dashboard", ["news_details"]),
    news() {
      return this.news_details && this.news_details._data
        ? this.news_details._data.data
        : [];
    },
  },
  methods: {
    closeModal() {
      this.modal.hide();
    },
    getDate(date) {
      return moment(date).format("MMM DD h:ss A");
    },
    getPercentageSentiment(sentiment) {
      return sentiment * 100;
    },
    showModal() {
      this.modal.show();
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.modal.show();
      } else {
        this.modal.hide();
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../../styles/pages/dashboard/news_modal.scss";
</style>
