export default {
 	mutations:{
    SOCKET_CONNECT: (state,  status ) => {
    	console.log('SOCKET_CONNECT', status);
      state.connect = true;
    },
    SOCKET_USER_MESSAGE: (state,  message) => {
    	console.log('SOCKET_USER_MESSAGE', message);
      state.message = message;
    },
  },
	actions: {
    SOCKET_OOPS(state, server) {
      console.log('SOCKET_oops', server);
    },
    SOCKET_SUCCESS(state, server) {
      console.log('SOCKET_success', server);
    },
    SOCKET_INFO(state, server) {
      console.log('SOCKET_info', server);
   	},
  },
}