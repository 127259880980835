<template>
  <div class="panel page__investors">
    <b-container fluid>
      <b-row class="p-0">
        <PanelControl
          :paginate="setPaginate"
          @loading="setLoading"
          @rows="setRows"
          @setTable="settingSetTable"
          @symbol="getSymbol"
          @mode="getMode"
          @selectedSymbolForAll="getSymbolForAllLMDD"
          @pagination="getPagination"
          @isFilter="getFilter"
          @isSymbol="getIsSymbol"
        />
        <TableLMDD
          :pagination="pagination"
          :loading="loading"
          :rows="rows"
          :setTable="setTable"
          :refreshTable="refresh"
          :symbol="symbol"
          :mode="mode"
          :isFilter="isFilter"
          :symbolLMDDSearch="symbolLMDDSearch"
          :isSymbol="isSymbol"
          @refreshIsDone="refresh = false"
          @setPagination="settingPaginate"
        />
      </b-row>
    </b-container>
  </div>
</template>

<script>
import TableLMDD from '../components/pages/lmdd/table.vue'
import PanelControl from '../components/pages/lmdd/DateframeControl.vue'
import moment from 'moment'

export default {
  name:'LMDD',
  components: {
    TableLMDD,
    PanelControl,
  },
  data () {
    return {
      rows: [],
      loading: false,
      setTable: false,
      refresh: false,
      symbol: 'btcusdt',
      mode: {},
      symbolLMDDSearch: {},
      pagination: {},
      setPaginate: {},
      isFilter: false,
      isSymbol: false
    }
  },
  methods: {
    settingPaginate(paginate) {
      this.setPaginate = paginate
    },
    getFilter(filter) {
      this.isFilter = filter
    },
    getIsSymbol(isSymbol) {
      this.isSymbol = isSymbol
    },
    getPagination(pagination) {
      this.pagination = pagination
    },
    getSymbolForAllLMDD(symbolsObject) {
      this.symbolLMDDSearch = symbolsObject
    },
    setLoading(loading) {
      this.loading = loading
    },
    setRows(rows) {
      this.rows = rows
      this.refresh = true;
    },
    settingSetTable() {
      this.setTable = true;
    },
    getSymbol(symbol) {
      this.symbol = symbol
    },
    getMode(mode) {
      console.log(mode)
      this.mode = mode
    }
  }
}
</script>
