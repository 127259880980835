var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-resolved-errors-table max-width"},[_c('p',[_vm._v("RESOLVED ERRORS")]),(_vm.onError)?_c('AlertErrorInformation'):(_vm.active_errors.length > 0)?_c('div',{staticClass:"table-resolved-errors table-responsive"},[_c('Table',{staticClass:"orders-table",attrs:{"actions":false,"data":_vm.active_errors,"headers":_vm.columnHeaders,"title":"Orders"},scopedSlots:_vm._u([{key:"comments",fn:function({ text }){return [(text)?_c('span',[_vm._v(_vm._s(text))]):_c('span',[_vm._v("No comments...")])]}},{key:"level",fn:function({ text }){return [_c('span',{staticClass:"d-flex",class:[
            text == 'high' ? 'red' : text == 'medium' ? 'yellow' : 'green',
          ]},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":text == 'high'
                  ? _vm.arrow_up
                  : text == 'low'
                  ? _vm.arrow_down
                  : _vm.minus_icon,"alt":""}})]),_vm._v(_vm._s(text)+" ")])]}},{key:"actions",fn:function(id){return [_c('div',{staticClass:"dropdown"},[_c('span',{staticClass:"action-button",attrs:{"data-toggle":"dropdown"}},[_c('img',{attrs:{"src":_vm.menu_icon,"alt":""}})]),_c('ul',{staticClass:"dropdown-menu"},[_c('li',[_c('img',{attrs:{"src":_vm.json_icon}}),_vm._v("View JSON")]),_c('li',[_c('img',{attrs:{"src":_vm.logs_icon}}),_vm._v("Mark as unsolved")]),_c('li',[_c('img',{attrs:{"src":_vm.comments_icon}}),_vm._v("Comments")])])])]}},{key:"pagination",fn:function(){return [_c('Pagination',{attrs:{"pagination":_vm.paginate,"go-next-page-callback":_vm.goNextPage,"go-prev-page-callback":_vm.goPrevPage}})]},proxy:true}])})],1):_c('AlertNoInformation')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }