<template>
  <div class="component-opened-orders">
    <div class="pb-2">
      <p>OPEN ORDERS RESUME</p>
      <ProcessingLoader v-if="onLoading" />
      <AlertErrorInformation v-else-if="onError" />
      <div v-else-if="exposure_data.length">
        <ul class="info" v-for="(item, index) in exposure_data" :key="index">
          <li>
            <span class="title">Token:</span> <span>{{ item.symbol }}</span>
          </li>
          <li>
            <span class="title">{{ item.symbol }} exposure:</span>
            <span class="d-flex value"
              ><span class="icon"><img :src="dollar" alt="" /></span
              >{{ formatter(item.exposure) }}
              {{ formatter(item.exposure_percentage) }}
              <span class="icon"><img :src="percentage" alt="" /></span
            ></span>
          </li>
          <li>
            <span class="title">Market:</span>
            <span>{{ item.market_type }}</span>
          </li>
          <li>
            <span class="title">Current price: </span>
            <span class="d-flex value"
              ><span class="icon"><img :src="dollar" alt="" /></span
              >{{ formatter(item.current_price) }}</span
            >
          </li>
          <li>
            <span class="title">Contracts:</span>
            <span class="value">{{ formatter(item.contracts,3) }}</span>
          </li>
          <li>
            <span class="title">Entry price:</span>
            <span class="d-flex value"
              ><span class="icon"><img :src="dollar" alt="" /></span
              >{{ formatter(item.entry_price) }}</span
            >
          </li>
          <li>
            <span class="title">Unrealized PnL (Exposure):</span>
            <span
              class="d-flex value"
              :class="[colorStatus(item.upnl_percentage)]"
              >{{ formatter(item.upnl_percentage)
              }}<span class="icon"
                ><img
                  :src="
                    item.upnl_percentage > 0
                      ? percentage_green
                      : item.upnl_percentage == 0
                      ? percentage
                      : percentage_red
                  "
                  alt="" /></span
            ></span>
          </li>
        </ul>
        <Pagination
          :pagination="paginate"
          :go-first-page-callback="goFirstPage"
          :go-last-page-callback="goLastPage"
          :go-next-page-callback="goNextPage"
          :go-prev-page-callback="goPrevPage"
        />
      </div>
      <div v-else>
        <AlertNoInformation />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import AlertErrorInformation from "../../commons/alerts/AlertErrorInformation.vue";
import AlertNoInformation from "../../commons/alerts/AlertNoInformation.vue";
import ArrowLeft from "../../../../public/common/arrow-left.png";
import ArrowRight from "../../../../public/common/arrow-right.png";
import Dollar from "../../../../public/common/dollar.png";
import DollarGreen from "../../../../public/common/dollar-green.png";
import DollarRed from "../../../../public/common/dollar-red.png";
import DoubleArrowLeft from "../../../../public/common/double-arrow-left.png";
import DoubleArrowRight from "../../../../public/common/double-arrow-right.png";
import number_formatter from "../../../helpers/formatters/number_formatter";
import Pagination from "../../commons/Pagination.vue";
import Percentage from "../../../../public/common/percentage.png";
import PercentageGreen from "../../../../public/common/percentage-green.png";
import PercentageRed from "../../../../public/common/percentage-red.png";
import ProcessingLoader from "../../commons/alerts/ProcessingLoader.vue";

export default {
  name: "OpenedOrders",
  components: {
    AlertNoInformation,
    AlertErrorInformation,
    Pagination,
    ProcessingLoader,
  },
  data() {
    return {
      arrow_left: ArrowLeft,
      arrow_right: ArrowRight,
      dollar: Dollar,
      dollar_green: DollarGreen,
      dollar_red: DollarRed,
      double_arrow_left: DoubleArrowLeft,
      double_arrow_right: DoubleArrowRight,
      percentage: Percentage,
      percentage_green: PercentageGreen,
      percentage_red: PercentageRed,
      perPage: 1,
      currentPage: 1,
    };
  },
  computed: {
    ...mapState("dashboard", ["exposure"]),
    exposure_data() {
      return this.exposure && this.exposure._data
        ? this.exposure._data.data
        : null;
    },
    onError() {
      return this.exposure._status._on_error;
    },
    onLoading() {
      return this.exposure._status._on_loading;
    },
    paginate() {
      return this.exposure && this.exposure._data
        ? this.exposure._data.paginate
        : null;
    },
    current_page() {
      return this.exposure._data.paginate
        ? this.exposure._data.paginate.current_page
        : null;
    },
  },
  methods: {
    colorStatus(value) {
      return value > 0 ? "green" : value < 0 ? "red" : "";
    },
    formatter(number, decimal = 2) {
      return number_formatter(number, decimal);
    },
    handlePageChange(value) {
      this.currentPage = value;
      const params = {
        page: value,
        limit: 1,
      };
      this.$store.dispatch("dashboard/exposure", params);
    },
    goFirstPage() {
      this.$store.dispatch("dashboard/exposure", {
        page: 1,
        limit: 1,
      });
    },
    goLastPage() {
      this.$store.dispatch("dashboard/exposure", {
        page: this.paginate.total_pages,
        limit: 1,
      });
    },
    goNextPage() {
      this.$store.dispatch("dashboard/exposure", {
        page: this.current_page + 1,
        limit: 1,
      });
    },
    goPrevPage() {
      this.$store.dispatch("dashboard/exposure", {
        page: this.current_page - 1,
        limit: 1,
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/pages/dashboard/opened_orders.scss";
</style>
