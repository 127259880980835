<template>
  <div>
    <HeaderTopSliders
      v-if="$route.name && $route.name.toLowerCase() == 'dashboard'"
    />
    <div :class="`component_template__topbar`">
      <b-container fluid>
        <b-row class="p-0">
          <b-col cols="12" lg="12" class="p-0">
            <b-navbar
              toggleable="lg"
              type="dark"
              variant="dark"
              class="main-navbar"
            >
              <b-navbar-brand href="#">
                <img src="/logo_white.png" class="logo" alt="logo" />
              </b-navbar-brand>
              <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
              <b-collapse
                id="nav-collapse"
                is-nav
                v-if="$route.name && $route.name.toLowerCase() == 'investor'"
              >
                <!-- Componentes de la top bar
              para los usuarios / cuentas tipo investor -->
                <PriceRealTime />

                <b-navbar-nav
                  class="ml-auto"
                  v-if="
                    $store.state.user.token &&
                    $store.state.user.role_id !== 1 &&
                    $store.state.user.role_id !== 2
                  "
                >
                  <b-navbar-nav class="users_menu">
                    <b-nav-item href="#">
                      <b-button variant="dark" @click.prevent="logout">
                        Sign off
                        <b-icon-power class="ml-1"></b-icon-power>
                      </b-button>
                    </b-nav-item>
                  </b-navbar-nav>
                </b-navbar-nav>
              </b-collapse>

              <!-- Componentes de la top bar
              para cuando hay login admin -->
              <b-collapse
                id="nav-collapse"
                is-nav
                v-if="$store.state.token && $store.state.user.role_id === 1"
              >
                <b-navbar-nav class="ml-auto">
                  <b-navbar-nav>
                    <li
                      class="nav-item"
                      v-if="
                        $route.name.toLowerCase() !== 'investor' &&
                        $route.name.toLowerCase() != 'subinvestor' &&
                        $route.name.toLowerCase() !== 'contributor'
                      "
                    >
                      <b-dropdown
                        id="dropdown-1"
                        text="Management"
                        class="m-md-2"
                      >
                        <b-dropdown-item
                          :to="{ name: 'Supervisors', params: {} }"
                        >
                          <li
                            style="justify-content: left; margin-left: 2px"
                            class="nav-item"
                            v-if="
                              $route.name.toLowerCase() !== 'investor' &&
                              $route.name.toLowerCase() != 'subinvestor' &&
                              $route.name.toLowerCase() !== 'contributor'
                            "
                          >
                            <router-link
                              :to="{ name: 'Supervisors', params: {} }"
                              class="nav-link"
                            >
                              Supervisors
                            </router-link>
                          </li>
                        </b-dropdown-item>
                        <b-dropdown-item
                          :to="{ name: 'Investors', params: {} }"
                        >
                          <li
                            style="justify-content: left; margin-left: 2px"
                            class="nav-item"
                          >
                            <router-link
                              :to="{ name: 'Investors', params: {} }"
                              class="nav-link"
                            >
                              Investors
                            </router-link>
                          </li>
                        </b-dropdown-item>
                        <b-dropdown-item
                          :to="{ name: 'InvestorsList', params: {} }"
                        >
                          <li
                            style="justify-content: left; margin-left: 2px"
                            class="nav-item"
                          >
                            <router-link
                              :to="{ name: 'InvestorsList', params: {} }"
                              class="nav-link"
                            >
                              Investors v2
                            </router-link>
                          </li>
                        </b-dropdown-item>
                        <b-dropdown-item
                          :to="{ name: 'Authorizations', params: {} }"
                        >
                          <li
                            style="justify-content: left; margin-left: 2px"
                            class="nav-item"
                            v-if="
                              $route.name.toLowerCase() !== 'investor' &&
                              $route.name.toLowerCase() != 'subinvestor' &&
                              $route.name.toLowerCase() !== 'contributor'
                            "
                          >
                            <router-link
                              :to="{ name: 'Authorizations', params: {} }"
                              class="nav-link"
                            >
                              Authorizations
                              <span
                                class="badget badget-info"
                                v-if="totalPendingAuthorization > 0"
                                >{{ totalPendingAuthorization }}</span
                              >
                            </router-link>
                          </li>
                        </b-dropdown-item>

                        <b-dropdown-item
                          :to="{ name: 'notifications', params: {} }"
                        >
                          <li
                            style="justify-content: left; margin-left: 2px"
                            class="nav-item"
                            v-if="
                              $route.name.toLowerCase() !== 'investor' &&
                              $route.name.toLowerCase() != 'subinvestor' &&
                              $route.name.toLowerCase() !== 'contributor'
                            "
                          >
                            <router-link
                              :to="{ name: 'notifications', params: {} }"
                              class="nav-link"
                            >
                              Notifications
                            </router-link>
                          </li>
                        </b-dropdown-item>
                        <b-dropdown-item
                          :to="{ name: 'scripts/authorization', params: {} }"
                        >
                          <li
                            style="justify-content: left; margin-left: 2px"
                            class="nav-item"
                            v-if="
                              $route.name.toLowerCase() !== 'investor' &&
                              $route.name.toLowerCase() != 'subinvestor' &&
                              $route.name.toLowerCase() !== 'contributor'
                            "
                          >
                            <router-link
                              :to="{
                                name: 'scripts/authorization',
                                params: {},
                              }"
                              class="nav-link"
                            >
                              Authorization Script
                            </router-link>
                          </li>
                        </b-dropdown-item>
                        <b-dropdown-item
                          :to="{ name: 'request/logs', params: {} }"
                        >
                          <li
                            style="justify-content: left; margin-left: 2px"
                            class="nav-item"
                            v-if="
                              $route.name.toLowerCase() !== 'investor' &&
                              $route.name.toLowerCase() != 'subinvestor' &&
                              $route.name.toLowerCase() !== 'contributor'
                            "
                          >
                            <router-link
                              :to="{ name: 'request/logs', params: {} }"
                              class="nav-link"
                            >
                              Request logs
                            </router-link>
                          </li>
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'Groups', params: {} }">
                          <li
                            style="justify-content: left; margin-left: 2px"
                            class="nav-item"
                          >
                            <router-link
                              :to="{ name: 'Groups', params: {} }"
                              class="nav-link"
                            >
                              Groups
                            </router-link>
                          </li>
                        </b-dropdown-item>
                        <b-dropdown-item
                          :to="{ name: 'HealthAudit', params: {} }"
                        >
                          <li
                            style="justify-content: left; margin-left: 2px"
                            class="nav-item"
                          >
                            <router-link
                              :to="{ name: 'HealthAudit', params: {} }"
                              class="nav-link"
                            >
                              Health Audit
                            </router-link>
                          </li>
                        </b-dropdown-item>
                        <b-dropdown-item
                          :to="{ name: 'SystemsHeartbeat', params: {} }"
                        >
                          <li
                            style="justify-content: left; margin-left: 2px"
                            class="nav-item"
                          >
                            <router-link
                              :to="{ name: 'SystemsHeartbeat', params: {} }"
                              class="nav-link"
                            >
                              Systems heartbeat
                            </router-link>
                          </li>
                        </b-dropdown-item>
                      </b-dropdown>
                    </li>

                    <li
                      class="nav-item"
                      v-if="
                        $route.name.toLowerCase() !== 'investor' &&
                        $route.name.toLowerCase() != 'subinvestor' &&
                        $route.name.toLowerCase() !== 'contributor'
                      "
                    >
                      <b-dropdown id="dropdown-1" text="Systems" class="m-md-2">
                        <b-dropdown-item :to="{ name: 'LMDD', params: {} }">
                          <li
                            style="justify-content: left; margin-left: 2px"
                            class="nav-item"
                            v-if="
                              $route.name.toLowerCase() !== 'investor' &&
                              $route.name.toLowerCase() != 'subinvestor' &&
                              $route.name.toLowerCase() !== 'contributor'
                            "
                          >
                            <router-link
                              :to="{ name: 'LMDD', params: {} }"
                              class="nav-link"
                            >
                              LMDD (Collector)
                            </router-link>
                          </li>
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'LMDD', params: {} }">
                          <li
                            style="justify-content: left; margin-left: 2px"
                            class="nav-item"
                            v-if="
                              $route.name.toLowerCase() !== 'investor' &&
                              $route.name.toLowerCase() != 'subinvestor' &&
                              $route.name.toLowerCase() !== 'contributor'
                            "
                          >
                            <router-link
                              :to="{ name: 'LMDDAccount', params: {} }"
                              class="nav-link"
                            >
                              LMDD (Account)
                            </router-link>
                          </li>
                        </b-dropdown-item>
                        <b-dropdown-item
                          :to="{ name: 'ServersManagement', params: {} }"
                        >
                          <li
                            style="justify-content: left; margin-left: 2px"
                            class="nav-item"
                            v-if="
                              $route.name.toLowerCase() !== 'investor' &&
                              $route.name.toLowerCase() != 'subinvestor' &&
                              $route.name.toLowerCase() !== 'contributor'
                            "
                          >
                            <router-link
                              :to="{ name: 'ServersManagement', params: {} }"
                              class="nav-link"
                            >
                              Backup
                            </router-link>
                          </li>
                        </b-dropdown-item>
                        <b-dropdown-item
                          :to="{ name: 'Backtestings', params: {} }"
                        >
                          <li
                            style="justify-content: left; margin-left: 2px"
                            class="nav-item"
                            v-if="
                              $route.name.toLowerCase() !== 'investor' &&
                              $route.name.toLowerCase() != 'subinvestor' &&
                              $route.name.toLowerCase() !== 'contributor'
                            "
                          >
                            <router-link
                              :to="{ name: 'Backtestings', params: {} }"
                              class="nav-link"
                            >
                              Backtestings
                            </router-link>
                          </li>
                        </b-dropdown-item>
                        <!-- <b-dropdown-item :to="{ name: 'Correlator', params: {}}">
                          <li style ="justify-content: left; margin-left: 2px;" class="nav-item" v-if="$route.name.toLowerCase() !== 'investor' && $route.name.toLowerCase() != 'subinvestor' && $route.name.toLowerCase() !== 'contributor'">
                            <router-link :to="{ name: 'Correlator', params: {}}" class="nav-link">
                              Correlator
                            </router-link>
                          </li> 
                        </b-dropdown-item> !-->
                        <b-dropdown-item>
                          <li
                            style="justify-content: left; margin-left: 2px"
                            class="nav-item"
                            v-if="
                              $route.name.toLowerCase() !== 'investor' &&
                              $route.name.toLowerCase() != 'subinvestor' &&
                              $route.name.toLowerCase() !== 'contributor'
                            "
                          >
                            <span
                              class="nav-link"
                              style="display: flex; align-items: center"
                              @click.prevent="toggleMaintenanceMode"
                              ><span
                                :class="[
                                  'maintenance_mode',
                                  maintenanceMode ? 'active' : null,
                                ]"
                              ></span
                              >Maintenance mode</span
                            >
                          </li>
                        </b-dropdown-item>
                      </b-dropdown>
                    </li>

                    <li
                      class="nav-item"
                      style="font-size: 24px"
                      v-if="$route.name.toLowerCase() === 'investor'"
                    >
                      <a>
                        <span
                          v-b-tooltip.hover
                          title="Activate expert mode"
                          class="turn-button"
                          @click="$store.commit('set_expert_mode', true)"
                          v-if="!$store.state.expert_mode"
                        >
                          <b-icon-toggle-off
                            class="text-success"
                          ></b-icon-toggle-off>
                        </span>
                        <span
                          v-b-tooltip.hover
                          title="Deactivate expert mode"
                          class="turn-button"
                          @click="$store.commit('set_expert_mode', false)"
                          v-else
                        >
                          <b-icon-toggle-on
                            class="text-success"
                          ></b-icon-toggle-on>
                        </span>
                      </a>
                    </li>
                    <li
                      class="nav-item"
                      v-if="$route.name.toLowerCase() === 'investor'"
                    >
                      <span class="nav-link"> Expert mode </span>
                    </li>

                    <li
                      class="nav-item"
                      v-if="$route.name.toLowerCase() === 'investor'"
                    >
                      <router-link
                        :to="{ name: 'Investors', params: {} }"
                        class="nav-link"
                      >
                        Investors
                      </router-link>
                    </li>

                    <b-nav-item
                      href="#"
                      v-if="
                        $store.state.user.token &&
                        $store.state.user.role_id === 1 &&
                        !$route.params.view_for_admin &&
                        $store.state.investors_view_mode == 0 &&
                        is_dashboard
                      "
                      @click="openNotificationsModal"
                    >
                      <div>
                        <img
                          :src="bell_icon"
                          alt=""
                          class="position-absolute"
                        />
                        <div class="alert-notification">
                          {{ notification_records }}
                        </div>
                      </div>
                    </b-nav-item>

                    <b-nav-item
                      href="#"
                      v-if="
                        $store.state.user.token &&
                        $store.state.user.role_id === 1
                      "
                    >
                      <b-button variant="dark" v-b-modal.modal-code-qr-user>
                        QR
                      </b-button>
                    </b-nav-item>

                    <b-nav-item
                      href="#"
                      v-if="
                        ($route.name.toLowerCase() !== 'investor' &&
                          $route.name.toLowerCase() != 'subinvestor' &&
                          $route.name.toLowerCase() !== 'contributor') ||
                        $route.name.toLowerCase() !== 'supervisor'
                      "
                    >
                      <b-button variant="dark">
                        Server time: {{ dateTime }} UTC
                      </b-button>
                    </b-nav-item>

                    <b-nav-item href="#">
                      <b-button variant="danger" @click.prevent="logout">
                        Sign off
                        <b-icon-power class="ml-1"></b-icon-power>
                      </b-button>
                    </b-nav-item>
                  </b-navbar-nav>
                </b-navbar-nav>
              </b-collapse>
              <b-collapse
                id="nav-collapse"
                is-nav
                v-if="$store.state.token && $store.state.user.role_id === 2"
              >
                <!-- Componentes de la top bar
              para los usuarios / cuentas tipo supervisor 
                 <b-navbar-nav class="ml-auto">   
                   <li v-if="$route.name.toLowerCase() !== 'supervisor'" class="nav-item">
                    <router-link :to="{ name: 'Supervisor', params: {}}" class="nav-link">
                        Investors
                    </router-link>
                   </li>  
                 </b-navbar-nav>-->

                <b-navbar-nav
                  class="ml-auto"
                  v-if="
                    $store.state.user.token && $store.state.user.role_id !== 1
                  "
                >
                  <b-navbar-nav class="ml-auto">
                    <b-dropdown
                      id="dropdown-2"
                      text="Management"
                      class="m-md-2"
                    >
                      <li
                        class="nav-item"
                        v-if="
                          $route.name.toLowerCase() !== 'investors' &&
                          $route.name.toLowerCase() != 'subinvestor' &&
                          $route.name.toLowerCase() !== 'contributor'
                        "
                      >
                        <b-dropdown-item
                          :to="{ name: 'InvestorsList', params: {} }"
                        >
                          <li
                            style="justify-content: left; margin-left: 2px"
                            class="nav-item"
                          >
                            <router-link
                              :to="{ name: 'InvestorsList', params: {} }"
                              class="nav-link"
                            >
                              Investors
                            </router-link>
                          </li>
                        </b-dropdown-item>
                      </li>
                    </b-dropdown>
                  </b-navbar-nav>
                  <b-navbar-nav class="users_menu">
                    <b-nav-item href="#">
                      <b-button variant="dark" @click.prevent="logout">
                        Sign off
                        <b-icon-power class="ml-1"></b-icon-power>
                      </b-button>
                    </b-nav-item>
                  </b-navbar-nav>
                </b-navbar-nav>
              </b-collapse>
            </b-navbar>
          </b-col>
        </b-row>

        <b-modal id="modal-code-qr-user" title="2FA QR Code">
          <div style="text-align: center">
            <vue-qr :text="codeQR" :size="300"></vue-qr>
          </div>
          <template #modal-footer="{ ok, cancel }"> </template>
        </b-modal>
      </b-container>
      <NotificationsModal
        :notifications="notifications"
        :show="show_notifications_modal"
        @closed="show_notifications_modal = false"
      />
    </div>
  </div>
</template>

<script>
import BellIcon from "../../../public/common/bell.png";
import HeaderTopSliders from "../pages/dashboard/HeaderTopSliders.vue";
import PriceRealTime from "@/components/template/PriceRealTime";
import NotificationsModal from "../pages/dashboard/modals/NotificationsModal.vue";
import moment from "moment";
import VueQr from "vue-qr";

export default {
  name: "TopBar",
  components: {
    HeaderTopSliders,
    NotificationsModal,
    PriceRealTime,
    VueQr,
  },
  data() {
    return {
      bell_icon: BellIcon,
      codeQR: "",
      dateTime: "",
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
        <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
        </svg> Error`,
      maintenanceMode: false,
      totalPendingAuthorization: 0,
      token: "",
      websocket: undefined,
      new_notification: {},
      animate: false,
      search_notification: "",
      show_notifications_modal: false,
      notificationsBK: [],
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
          <g clip-path="url(#clip0_1634_940)">
          <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
          </g>
          <defs>
          <clipPath id="clip0_1634_940">
          <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
          </clipPath>
          </defs>
          </svg> Success`,
    };
  },
  created() {
    this.getMaintenanceMode();
    if (localStorage.userData) {
      this.getCountPendingAutorizations();
      this.getQrData();
    }

    if (this.is_dashboard) {
      if (!this.$store.state.dashboard.investor_token) {
        let interval = setInterval(() => {
          if (this.$store.state.dashboard.investor_token) {
            clearInterval(interval);
            this.getNotifications(1);
          }
        }, 250);
      } else {
        this.getNotifications(1);
      }
    }
  },
  mounted() {
    setInterval(this.handleUpdatedTime, 1000);
  },
  computed: {
    is_dashboard() {
      return this.$route.name.toLowerCase() == "dashboard" ? true : false;
    },
    notifications() {
      let cc = [];

      if (this.$store.state.investor_notifications.length > 0) {
        for (
          let i = 0;
          i < this.$store.state.investor_notifications.length;
          i++
        ) {
          let msg = this.$store.state.investor_notifications[i].message;
          try {
            cc.push({
              msg: JSON.parse(msg).msg ? JSON.parse(msg).msg : msg,
              date: JSON.parse(msg).date ? JSON.parse(msg).date : "--",
            });
          } catch (e) {
            let msg = this.$store.state.investor_notifications[i].message;
            if (!msg) {
              continue;
            }
            msg = msg.replace("{", "").replace("}", "").split(",");
            let newmsg = {
              msg: msg[0].replace('"msg":', ""),
              date: msg[2].replace('"date":', ""),
              token: msg[1].replace('"token":', ""),
            };
            cc.push(newmsg);
          }
        }
      }
      return cc;
    },
    notification_records() {
      return this.$store.state.investor_notifications_paginate.records
        ? this.$store.state.investor_notifications_paginate.records
        : 0;
    },
  },
  methods: {
    getNotifications(page = 1) {
      const { investor_token } = this.$store.state.dashboard;
      const payload = {
        vm: this,
        data: {
          page,
          limit: 10,
          investor_token,
          // investor_token: this.$store.state.info_investor.info.token,
          search: this.search_notification,
        },
      };
      this.$store.commit("get_notification_investor", payload);
    },
    getMaintenanceMode() {
      this.$http
        .server1("bwfs/admin/maintenance/mode", {})
        .then((res) => {
          if (res.error) {
            return;
          }
          this.maintenanceMode = res.data;
        })
        .catch(() => {});
    },
    getCountPendingAutorizations() {
      this.$http
        .server1("bwfs/admin/access/count/pending", {})
        .then((res) => {
          if (res.error) {
            return;
          }
          this.totalPendingAuthorization = res.data;
        })
        .catch(() => {});
    },
    getQrData() {
      this.$http
        .server1("bwfs/admin/getTOTPURICurrentUser", {})
        .then((res) => {
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.codeQR = res.data.uri;
        })
        .catch((e) => {
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    logout() {
      localStorage.clear();
      window.location.reload();
    },
    handleUpdatedTime() {
      let localDate = new Date();
      let localMoment = moment();
      let utcMoment = moment.utc();
      let utcDate = new Date(utcMoment.format());
      this.dateTime = utcMoment.format().replace("T", " ").substring(0, 19);
    },
    openNotificationsModal() {
      this.show_notifications_modal = true;
    },
    toggleMaintenanceMode() {
      if (
        !confirm(
          `Are you sure to ${
            this.maintenanceMode ? "disable" : "activate"
          } the maintenance mode`
        )
      ) {
        return;
      }
      this.$http
        .server1("bwfs/admin/maintenance/mode/toggle", {})
        .then((res) => {
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.$toastr.success(`${res.message}`, `${this.success}`);
          this.getMaintenanceMode();
        })
        .catch(() => {});
    },
  },
  watch: {
    $route(to, from) {
      if (to.name.toLowerCase() !== "investor") {
        clearInterval(this.idsInterval);
        this.$helpers.resetFavIcon();
      }
      if (!this.codeQR && localStorage.userData) {
        this.getQrData();
      }
    },
  },
};
</script>

<style scoped>
::v-deep .btn-secondary {
  color: #9b9b9b;
  background-color: #232429;
  border-color: #6c757d;
}
::v-deep .dropdown-menu.show {
  background-color: #232429;
}
::v-deep .main-navbar.navbar-dark .dropdown-menu {
  min-width: 150px;
}
::v-deep .dropdown-item:hover,
.dropdown-item:focus {
  text-decoration: none;
  background-color: rgb(160, 160, 160);
}
/* ::v-deep .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: #232429;
} */
::v-deep .dropdown-item {
  display: block;
  width: 100%;
  padding: 0px;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: left;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.maintenance_mode {
  display: block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  border: 0.1em solid #d5d5d5;
  background: #d5d5d5;
  margin-right: 3px;
}

.maintenance_mode.active {
  border: 0.1em solid green !important;
  background: green !important;
}
.alert-notification {
  background: #dc3545;
  border-radius: 100px;
  bottom: 8px;
  left: 8px;
  position: relative;
  padding: 2px 5px 1px 5px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
