import { render, staticRenderFns } from "./OrdersTable.vue?vue&type=template&id=4409de60&"
import script from "./OrdersTable.vue?vue&type=script&lang=js&"
export * from "./OrdersTable.vue?vue&type=script&lang=js&"
import style0 from "./OrdersTable.vue?vue&type=style&index=0&id=4409de60&prod&lang=scss&"
import style1 from "./OrdersTable.vue?vue&type=style&index=1&id=4409de60&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports