<template>
  <b-col cols="12" lg="2" class="p-0 admin_sidebar nice-scroll">
    <div class="d-block p-2 mb-2">
      <div class="separator">User filtering</div>

      <div
        class="card p-2 mb-2 text-muted small"
        v-if="!$store.state.first_searched_investor"
      >
        Applied Filters <br />
        <span v-if="search"> {{ "Search: " + search }} <br /> </span>
        <span> User type: {{ getUserTypeString() }} <br /> </span>
        <span>
          Only investors archived: {{ userArchived == 1 ? "Yes" : "No" }} <br />
        </span>
        <span>
          Only investors running: {{ userRunning == 2 ? "Yes" : "No" }} <br />
        </span>
        <span> Order by: {{ getOrderByString() }} <br /> </span>
        <span> Max per page: {{ limit }} <br /> </span>
        <span> Labeling: {{ labeling }} <br /> </span>
      </div>

      <div v-show="!$store.state.first_searched_investor" class="card p-2 mb-2">
        <label>Search</label>
        <input
          id="searchInvestor"
          v-model="search"
          type="search"
          class="form-control"
          placeholder="Enter text here."
        />
        <p class="small text-muted mt-1 mb-0">
          Search Investor by token or name.
        </p>
      </div>
      <div v-show="!$store.state.first_searched_investor" class="card p-2 mb-2">
        <label>Users type</label>
        <select
          v-show="!$store.state.order_tracking_mode"
          @change="searchingInvestor"
          v-model="userType"
          class="form-control"
        >
          <option v-if="!orderTracking" :value="10">All modes</option>
          <option :value="0">Mode demo</option>
          <option :value="1">Mode real</option>
        </select>
        <select
          v-show="$store.state.order_tracking_mode"
          @change="searchingInvestor"
          v-model="userTypeOrderTracking"
          class="form-control"
        >
          <option :value="0">Mode demo</option>
          <option :value="1">Mode real</option>
        </select>
      </div>
      <div v-show="!$store.state.first_searched_investor" class="card p-2 mb-2">
        <b-form-checkbox
          @input="searchingInvestor"
          v-model="userArchived"
          :value="1"
          :unchecked-value="0"
        >
          Only investors archived
        </b-form-checkbox>
      </div>

      <div class="card p-2 mb-2" v-show="!$store.state.first_searched_investor">
        <b-form-checkbox
          @input="searchingInvestor"
          v-model="userRunning"
          :value="2"
          :unchecked-value="10"
        >
          Only investors running
        </b-form-checkbox>
      </div>

      <div
        class="card p-2 mb-2"
        v-show="
          !$store.state.first_searched_investor &&
          $store.state.order_tracking_mode
        "
      >
        <label>User list</label>
        <b-form-select
          id="select-client"
          v-model="investor"
          :options="investorsOptions"
          multiple
          :select-size="4"
        />
      </div>
      <div
        class="card p-2 mb-2"
        v-show="
          !$store.state.first_searched_investor &&
          $store.state.order_tracking_mode
        "
      >
        <button
          class="btn btn-dark"
          type="button"
          @click="orderModeSearching()"
        >
          Search Orders
        </button>
      </div>

      <div v-show="!$store.state.first_searched_investor" class="card p-2 mb-2">
        <label>Order by</label>
        <select
          @change="searchingInvestor"
          v-model="sortBy"
          class="form-control"
        >
          <option :value="1">↓ Last Created</option>
          <option :value="2">↓ Profits</option>
          <option :value="3">↑ Profits</option>
          <option :value="4">↓ Last operation</option>
          <option :value="5">↓ Open orders</option>
        </select>
      </div>
      <div
        class="card p-2 mb-2"
        v-show="
          !$store.state.first_searched_investor &&
          !$store.state.order_tracking_mode
        "
      >
        <label>Max per page</label>
        <select
          @change="searchingInvestor"
          v-model="limit"
          class="form-control"
        >
          <option :value="5">5</option>
          <option :value="10">10</option>
          <option :value="15">15</option>
          <option :value="25">25</option>
          <option :value="100">100</option>
        </select>
      </div>

      <template>
        <div
          class="card p-2 mb-2"
          v-show="$store.state.first_searched_investor"
          v-for="i in 5"
        >
          <b-list-group>
            <b-list-group-item>
              <div class="d-block">
                <b-skeleton animation="wave" width="75%"></b-skeleton>
                <b-skeleton animation="wave" width="10%"></b-skeleton>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
      </template>

      <div
        class="card p-2 mb-2"
        v-show="
          !$store.state.first_searched_investor &&
          !$store.state.order_tracking_mode
        "
      >
        <label>Labeling</label>
        <!--<b-form-tags id="labeling" v-model="labeling" class="form-control" style="color: #fff !important;"></b-form-tags>-->
        <select v-model="labeling" id="select-labeling" class="form-control">
          <option value=""></option>
          <option v-for="(label, index) in labelings" :value="label">
            {{ label }}
          </option>
        </select>
      </div>
    </div>
  </b-col>
</template>

<script>
import CONSTANTS from "../../../helpers/constants";
import Crypto from "../../../helpers/crypto";

export default {
  name: "SearchInvestor",
  props: {
    firstLoad: {
      required: false,
      default: false,
    },
  },
  created() {
    if (localStorage.getItem("filters_investors")) {
      let filters = JSON.parse(localStorage.getItem("filters_investors"));
      if (filters.label) {
        this.labeling = filters.label;
      }
    }
    if (this.orderTracking) {
      this.userType = 1;
    }
    this.getLabeling();
  },
  data() {
    return {
      sort_by: 1,
      page: 1,
      develop_api: CONSTANTS.develop_api,
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
      <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
      </svg> Error`,
      production_api: CONSTANTS.production_api,
      labelings: [],
      labeling: "",
      filterLabel: "",
    };
  },
  computed: {
    search: {
      get() {
        return this.$store.state.investor_search;
      },
      set(value) {
        this.$store.commit("set_investor_search", value);
      },
    },
    userType: {
      get() {
        return this.$store.state.user_type;
      },
      set(value) {
        this.$store.commit("set_user_type", value);
      },
    },
    limit: {
      get() {
        return this.$store.state.investor_limit;
      },
      set(value) {
        this.$store.commit("set_investor_limit", value);
      },
    },
    userRunning: {
      get() {
        return this.$store.state.investor_status;
      },
      set(value) {
        this.$store.commit("set_investor_status", value);
      },
    },
    userArchived: {
      get() {
        return this.$store.state.investor_is_archived;
      },
      set(value) {
        this.$store.commit("set_investor_is_archived", value);
      },
    },
    sortBy: {
      get() {
        return this.$store.state.investors_sort_by;
      },
      set(value) {
        this.$store.commit("set_investors_sort_by", value);
      },
    },
    orderTracking: {
      get() {
        return this.$store.state.order_tracking_mode;
      },
      set(value) {
        this.$store.commit("set_order_tracking_mode", value);
      },
    },
    userTypeOrderTracking: {
      get() {
        return this.$store.state.user_type_order_tracking;
      },
      set(value) {
        this.$store.commit("set_user_type_order_tracking", value);
      },
    },
    investorsOptions() {
      if (this.$store.state.investors_orders_token) {
        return this.$store.state.investors_orders_token.map((investor) => {
          return {
            text: investor.name,
            value: investor.token,
          };
        });
      }
      return [];
    },
    investor: {
      get() {
        return this.$store.state.investors_tokens;
      },
      set(value) {
        this.$store.commit("set_investors_tokens", value);
      },
    },
    endpoint: {
      get() {
        return this.$store.state.investor_api_url;
      },
      set(value) {
        this.$store.commit("set_investor_api_url", value);
      },
    },
    currentRouteName() {
      return this.$route.name.toLowerCase();
    },
  },
  methods: {
    async searchingInvestor() {
      if (this.firstLoad) {
        return;
      }
      let routeName = this.currentRouteName;
      this.addLocalStorageFilters();
      const user_role = this.$store.state.user.role_id;
      if (user_role == 1 || user_role == 2)
        this.$store.commit("set_investor_supervisor_searched", true);
      await this.$store.dispatch("bwfs_get_investors", {
        vm: this,
        data: {
          page: this.$store.state.investors_paginate.current_page
            ? this.$store.state.investors_paginate.current_page
            : 1,
          limit: this.$store.state.investor_limit,
          search: this.search,
          user_type: this.userType,
          status: this.$store.state.investor_status,
          is_archived:
            routeName == "supervisor"
              ? 2
              : this.$store.state.investor_is_archived,
          supervisor_token:
            this.$store.state.user.role_id == 2
              ? this.$store.state.user.token
              : "",
          orderBy: this.sortBy,
          labeling: this.labeling,
        },
      });
      if (user_role == 1 || user_role == 2)
        this.$store.commit("set_investor_supervisor_searched", false);
    },
    addEventSearch() {
      //const myInput = document.getElementById("searchInvestor");
      const myInputLabelin = document.getElementById("select-labeling");
      // if (!myInput || !myInputLabelin) {
      if (!myInputLabelin) {
        setTimeout(() => {
          this.addEventSearch();
        }, 500);
      } else {
        // myInput.addEventListener("keyup", this.$debounce(this.searchingInvestor));
        myInputLabelin.addEventListener(
          "keyup",
          this.$debounce(this.searchingInvestor)
        );
      }
    },
    getOrderByString() {
      if (this.sortBy) {
        if (this.sortBy == 1) {
          return "Last Created";
        }
        if (this.sortBy == 2) {
          return "↓ Profits";
        }
        if (this.sortBy == 3) {
          return "↑ Profits";
        }
        if (this.sortBy == 4) {
          return "↓ Last operation";
        }
        if (this.sortBy == 5) {
          return "↓ Open orders";
        }
      }
      return "";
    },
    getUserTypeString() {
      if (this.userType == 10) {
        return "All Modes";
      } else if (this.userType == 1) {
        return "Mode real";
      } else if (this.userType == 0) {
        return "Mode demo";
      } else {
        return "";
      }
    },
    addLocalStorageFilters() {
      let filters = {};
      filters.search = this.search;
      filters.user_type = this.userType;
      filters.status = this.$store.state.investor_status;
      filters.is_archived = this.$store.state.investor_is_archived;
      filters.limit = this.$store.state.investor_limit;
      filters.orderBy = this.sortBy;
      filters.label = this.labeling;
      localStorage.setItem("filters_investors", JSON.stringify(filters));
    },
    orderModeSearching() {
      this.$store.commit("set_investor_notifications_orders_search", true);
      this.$store.commit("set_orders_tracking_search", true);
    },
    getLabeling() {
      this.$http.server1("bwfs/get/labeling", {}).then((response) => {
        if (response.error) {
          return;
        }
        this.labelings = response.data;
      });
    },
  },
  mounted() {
    this.addEventSearch();
  },
  watch: {
    "$store.state.investors_loading"(value) {
      if (!value) {
        this.addEventSearch();
      }
    },
    labeling(value) {
      this.searchingInvestor();
    },
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 768px) and (max-width: 1025px) {
  ::v-deep .col-md-3 {
    flex: 0 0 75%;
    max-width: 50%;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  ::v-deep .col-md-3 {
    flex: 0 0 70%;
    max-width: 45%;
  }
  ::v-deep .col-lg-2 {
    flex: 0 0 22%;
    max-width: 22%;
  }
  ::v-deep .col-lg-10 {
    flex: 0 0 78%;
    max-width: 78%;
  }
}
::v-deep .custom-select,
.swal2-select,
.swal2-input {
  background-color: #0e1019 !important;
  color: #f5f5f5 !important;
  border: 0 !important;
  font-size: 13px !important;
  height: 10rem !important;
}
</style>
