/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable dot-notation */

import $ from "jquery";
import Crypto from "./crypto";
import axios from "axios";
import CONSTANTS from "./constants";

var base_url_server1 = "";
var base_url_server2 = "";
let current_host = window.location.hostname;
let migration_mode = false;
base_url_server2 = CONSTANTS.backtesting_api;
let schema = "https://";
let captcha_token = "6LfY6wcaAAAAAM1NPhcjFLlrl_fSjR0odZBWKZyI";

if (current_host !== "localhost") {
  // base_url_server1 = CONSTANTS.production_api_testing;
  base_url_server2 = CONSTANTS.backtesting_api;

  if (current_host.substr(0, 10) == "10.103.245") {
    schema = "http://";
  }

  base_url_server1 = schema + current_host + ":8080";
} else {
  base_url_server1 = CONSTANTS.develop_api;
  base_url_server2 = CONSTANTS.backtesting_api;
}

const Request = {
  server1: (
    path,
    data,
    customHeaders = {},
    filterEndoint = null,
    method = null
  ) => {
    method = method ? method : "POST";
    return new Promise((resolve, reject) => {
      grecaptcha.ready(() => {
        grecaptcha.execute(captcha_token, { action: path }).then((token) => {
          let settings = {
            url: `${base_url_server1}/${path}`,
            type: method,
            timeout: 0,
            dataType: "json",
            data,
          };
          let token_auth = localStorage.getItem("token");
          // let cookieAuth = Request._vm.$cookies.get("cookie_autorized");
          let cookieAuth = localStorage.getItem("cookie_autorized");
          if (migration_mode && localStorage.getItem("token_migration")) {
            token_auth = localStorage.getItem("token_migration");
          }
          if (path != "bwfs/access" && token_auth) {
            let headers = {
              Authorization: Crypto.decrypt(token_auth),
              Cookie_Auth: cookieAuth,
            };
            settings["headers"] = headers;
            if (Object.keys(customHeaders).length > 0) {
              const keysHeaders = Object.keys(customHeaders);
              for (let index = 0; index < keysHeaders.length; index += 1) {
                const key = keysHeaders[index];
                settings["headers"][key] = customHeaders[key];
                // request.setRequestHeader(key, customHeaders[key]);
              }
            }
          }
          $.ajax(settings)
            .done((response) => {
              resolve({ encryptor: Crypto, ...response });
            })
            .fail((response, xhr, error) => {
              console.log(error);
              reject(error);
            });
        });
      });
    });
  },
  server2: (path, data, customHeaders = {}, endpoint = null) => {
    let base_url = base_url_server2;
    if (endpoint) {
      if (endpoint == "production") {
        base_url = CONSTANTS.backtesting_api;
      } else {
        base_url = CONSTANTS.develop_api;
      }
    }
    return new Promise((resolve, reject) => {
      grecaptcha.ready(() => {
        grecaptcha.execute(captcha_token, { action: path }).then((token) => {
          let settings = {
            url: `${base_url}/${path}`,
            type: "POST",
            timeout: 0,
            dataType: "json",
            data,
          };
          let token_auth = localStorage.getItem("token");
          // let cookieAuth = Request._vm.$cookies.get("cookie_autorized");
          let cookieAuth = localStorage.getItem("cookie_autorized");
          if (path != "bwfs/access" && token_auth) {
            let headers = {
              Authorization: Crypto.decrypt(token_auth),
              Cookie_Auth: cookieAuth,
            };
            settings["headers"] = headers;
            if (Object.keys(customHeaders).length > 0) {
              const keysHeaders = Object.keys(customHeaders);
              for (let index = 0; index < keysHeaders.length; index += 1) {
                const key = keysHeaders[index];
                settings["headers"][key] = customHeaders[key];
              }
            }
          }
          $.ajax(settings)
            .done((response) => {
              resolve({ encryptor: Crypto, ...response });
            })
            .fail((response, xhr, error) => {
              reject(error);
            });
        });
      });
    });
  },
  url_api: (isInvestor = false) => {
    return base_url_server1;
  },
  set_url_by_filter: (url = base_url_server1, set = false) => {
    if (set) {
      base_url_server1 = url;
      if (url == CONSTANTS.production_api) {
        console.log("migration mode activate");
        migration_mode = false;
      } else {
        migration_mode = false;
      }
    }
  },
};

export default Request;
