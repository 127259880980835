<template>
  <div class="container-fluid">
    <div class="content">
      <b-col cols="12" lg="2" class="p-0">
        <SearchGroup @searchInput="searchInput"/>
      </b-col>
      <b-col cols="12" lg="10" class="p-0">
        <GroupsList :search="search"/>
      </b-col>
    </div>
  </div>
</template>

<script>
import NewGroupModal from "../components/pages/groups/NewGroupModal.vue";
import GroupsList from "../components/pages/groups/GroupsList.vue";
import SearchGroup from "../components/pages/groups/SearchGroup.vue";

export default {
  name: "Groups",
  components: {
    NewGroupModal,
    GroupsList,
    SearchGroup,
  },
  data() {
    return {
      search: null,
    };
  },
  computed: {
    total_records() {
      return this.$store.state.groups.groups._data.paginate.records;
    },
  },
  methods:{
    searchInput(value){
      this.search = value;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/pages/groups.scss";
</style>
