<template>
  <div class="event-tab-component">
    <div class="title">
      <span><img :src="event" alt="" width="20" height="20px" /></span>
      <h1>Event & Portfolio</h1>
    </div>
    <div>
      <div class="form-group">
        <label>Portfolio</label>
        <Multiselect
          v-model="form_event.portfolio"
          :hideSelected="true"
          :options="[]"
          class="form-control selector"
          label="name"
          placeholder="Select a portfolio"
          valueProp="token"
        >
          <template v-slot:caret>
            <span class="multiselect-caret">
              <img :src="icon_caret" alt="" />
            </span>
          </template>
        </Multiselect>
      </div>
      <label class="m-0">Event</label>
      <div class="event-container">
        <div
          :class="{ act: form_event.event == 'turn_off' }"
          class="box"
          @click="setEvent(0)"
        >
          <span><img :src="power_off" alt="" /></span>
          <h2>When: Turn off account</h2>
          <h3>Deactivating keeps open orders but stops new ones.</h3>
        </div>
        <div
          :class="{ act: form_event.event == 'turn_on' }"
          class="box"
          @click="setEvent(1)"
        >
          <span><img :src="power_on" alt="" /></span>
          <h2>When: Turn on account</h2>
          <h3>Activate your account to open new orders.</h3>
        </div>
      </div>
      <div class="event-container">
        <div
          :class="{ act: form_event.event == 'send_order' }"
          class="box"
          @click="setEvent(2)"
        >
          <span><img :src="action" alt="" /></span>
          <h2>When: Send order</h2>
          <h3>Deactivating keeps open orders but stops new ones.</h3>
          <div class="input-send-order">
            <div class="form-group">
              <div class="container-type">
                <div
                  :class="{ container_bg: send_order == 1 }"
                  class="container_no_bg"
                  @click="setSendOrder(1)"
                >
                  Market
                </div>
                <div
                  :class="{ container_bg: send_order == 0 }"
                  class="container_no_bg"
                  @click="setSendOrder(0)"
                >
                  Conditional
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionIcon from "../../../../../../../public/common/action-sidebar-act.svg";
import EventIcon from "../../../../../../../public/common/event-sidebar.svg";
import IconCaret from "../../../../../../../public/common/select-arrow-down.svg";
import PowerOn from "../../../../../../../public/common/power-on.svg";
import PowerOff from "../../../../../../../public/common/power-off.svg";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2";
import { mapState } from "vuex";

export default {
  name: "EventTab",
  data() {
    return {
      action: ActionIcon,
      event: EventIcon,
      icon_caret: IconCaret,
      power_on: PowerOn,
      power_off: PowerOff,
      send_order: 0,
    };
  },
  components: { Multiselect },
  computed: {
    ...mapState("investor_configuration", ["form_event"]),
  },
  methods: {
    setEvent(opt) {
      const event = {
        portfolio: this.form_event.portfolio,
        event: opt == 2 ? "send_order" : opt ? "turn_on" : "turn_off",
      };
      this.$store.commit("investor_configuration/set_form_event", event);
    },
    setSendOrder(option) {
      this.send_order = option;
    },
  },
};
</script>

<style lang="scss">
@import "../../../../../../styles/pages/investor_configuration/event_tab.scss";
</style>
