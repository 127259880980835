export default (value, fraction_digits = 2) => {
  const num = Number(value);
  if (typeof num !== "number") {
    return value;
  }

  const currencyFractionDigits = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: fraction_digits,
  }).resolvedOptions().maximumFractionDigits;

  if (isNaN(num)) {
    const res = 0;
    return res.toLocaleString("en-US", {
      maximumFractionDigits: currencyFractionDigits,
      minimumFractionDigits: currencyFractionDigits,
    });
  }
  return num.toLocaleString("en-US", {
    maximumFractionDigits: currencyFractionDigits,
    minimumFractionDigits: currencyFractionDigits,
  });
};
