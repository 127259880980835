<template>
  <div :class="`panel page__${$route.name.toLowerCase()}`">
    <b-container fluid>
      <b-row class="p-0">
        <b-col cols="12" lg="2" class="p-0 admin_sidebar nice-scroll">
          <div class="d-block p-2 mb-2">
            <div class="separator">Request logs filtering</div>
            <div class="card p-2 mb-2 d-block">
              <div class="form-group">
                <label>Filter by</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="search"
                  id="search_request"
                />
                <div class="loading" v-if="isLoading">
                  <b-spinner variant="light"></b-spinner>
                </div>
              </div>
              <div class="form-group mb-2">
                <label>Type request</label>
                <select
                  name=""
                  id=""
                  class="form-control"
                  v-model="selectedTypeRequest"
                >
                  <option value=""></option>
                  <option value="incoming_requests">Incoming requests</option>
                </select>
                <div class="loading" v-if="isLoading">
                  <b-spinner variant="light"></b-spinner>
                </div>
                <b-button
                  variant="danger"
                  class="w-100 mt-2"
                  @click.prevent="clearRequestLog"
                  >Clear Request</b-button
                >
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" lg="10" class="p-0 admin_content nice-scroll">
          <div class="content p-2">
            <div class="row pl-3 pr-3">
              <div class="col-md-12 p-1 d-flex align-items-center">
                Total records found: {{ paginate.records }}
              </div>
            </div>
            <div class="row pl-3 pr-3">
              <div class="col-md-12 p-1">
                <div class="table-responsive">
                  <b-table
                    id="table"
                    :items="requestLogs"
                    :fields="fields"
                    striped
                    hover
                    small
                    borderless
                    :busy="isLoading"
                  >
                    <template #table-busy>
                      <div class="text-center my-2">
                        <b-spinner type="grow" label="Spinning"></b-spinner>
                      </div>
                    </template>

                    <template #cell(label)="row">
                      <span v-if="cleanLabels(row.item.label).length > 0">
                        <b-button
                          size="sm"
                          :class="[la ? 'p-1 mr-1' : 'p-0']"
                          v-for="la in cleanLabels(row.item.label)"
                          :key="la"
                          >{{ la }}</b-button
                        >
                      </span>
                      <span v-else>--</span>
                    </template>

                    <template #cell(option)="row">
                      <span
                        v-b-tooltip.hover
                        title="Banned IP"
                        class="mx-1"
                        style="font-size: 18px"
                        @click.prevent="banIp(row.item)"
                        v-if="!row.item.is_band"
                      >
                        <b-icon-x-circle variant="danger"></b-icon-x-circle>
                      </span>
                      <span
                        v-b-tooltip.hover
                        title="Desbanned IP"
                        class="mx-1"
                        style="font-size: 18px"
                        @click.prevent="desBanIp(row.item)"
                        v-if="row.item.is_band"
                      >
                        <b-icon-arrow-repeat
                          variant="danger"
                        ></b-icon-arrow-repeat>
                      </span>
                      <span
                        v-b-tooltip.hover
                        title="Add Label"
                        class="mx-1"
                        style="font-size: 18px"
                        @click.prevent="handleAddLabel(row.item)"
                      >
                        <b-icon-card-list variant="danger"></b-icon-card-list>
                      </span>
                    </template>
                  </b-table>
                  <b-pagination
                    id="table"
                    v-model="currentPage"
                    :total-rows="paginate.records"
                    :limit="20"
                    class="mt-2"
                    @page-click="handleNext"
                    v-if="paginate.total_pages > 0"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="modal-add-label" :title="titleModal">
      <div class="form-group mt-2">
        <label for="tags-basic">Labels</label>
        <b-form-tags
          input-id="tags-basic"
          v-model="labels"
          class="form-control"
          style="color: #fff !important"
        ></b-form-tags>
      </div>
      <template #modal-footer>
        <b-button
          variant="success"
          size="sm"
          class="float-right"
          @click.prevent="saveLabel"
          :disabled="loadingSaveLabel"
        >
          <b-spinner v-if="loadingSaveLabel" variant="light" small></b-spinner
          >Save
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { required, minLength, email } from "vuelidate/lib/validators";
export default {
  created() {
    this.getRequests();
  },
  data() {
    return {
      currentPage: 1,
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
        <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
        </svg> Error`,
      fields: [
        { key: "date", sortable: true },
        { key: "src_address", sortable: true },
        { key: "country", sortable: true },
        { key: "regionName", sortable: true },
        { key: "city", sortable: true },
        { key: "dst_address", sortable: true },
        { key: "label", sortable: true },
        { key: "src_port", sortable: true },
        { key: "dst_port", sortable: true },
        { key: "protocol", sortable: false },
        { key: "option", sortable: false },
      ],
      isLoading: false,
      labels: [],
      loadingBanned: false,
      loadingSaveLabel: false,
      requestLogs: [],
      search: "",
      paginate: {
        current_page: 1,
        per_page: 20,
        total_pages: 0,
        records: 0,
      },
      selectedRequest: {},
      selectedTypeRequest: "",
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
          <g clip-path="url(#clip0_1634_940)">
          <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
          </g>
          <defs>
          <clipPath id="clip0_1634_940">
          <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
          </clipPath>
          </defs>
          </svg> Success`,
      titleModal: "Add Modal for ip",
    };
  },
  mounted() {
    const myInput = document.getElementById("search_request");
    myInput.addEventListener("keyup", this.$debounce(this.getRequests));
  },
  methods: {
    banIp(item) {
      if (this.loadingBanned) {
        this.$toastr.error("Request in process, Please wait", `${this.error}`);

        return;
      }
      if (!confirm("Are you sure?")) {
        return;
      }

      this.loadingBanned = true;
      let data = { ip: item.src_address };
      this.$http
        .server1("bwfs/banned/ip", data)
        .then((res) => {
          this.loadingBanned = false;
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.$toastr.success("Success Banned", `${this.success}`);
          this.getRequests(1);
        })
        .catch((e) => {
          this.loadingBanned = false;
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    clearRequestLog() {
      if (this.isLoading) {
        return;
      }
      if (!confirm("Are you sure?")) {
        return;
      }
      this.$http
        .server1("bwfs/requests/logs/clear", {})
        .then((res) => {
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.$toastr.success("Success clear logs.", `${this.success}`);
          this.getRequests(1);
        })
        .catch((e) => {
          this.loadingSaveLabel = false;
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    cleanLabels(value) {
      let label = value.trim().split(",");
      let all_labels = [];
      for (let i = 0; i < label.length; i++) {
        if (label[i]) {
          all_labels.push(valye);
        }
      }
      return all_labels;
    },
    desBanIp(item) {
      if (this.loadingBanned) {
        this.$toastr.error("Request in process, Please wait", `${this.error}`);
        return;
      }
      if (!confirm("Are you sure?")) {
        return;
      }
      this.loadingBanned = true;
      let data = { ip: item.src_address };
      this.$http
        .server1("bwfs/desbanned/ip", data)
        .then((res) => {
          this.loadingBanned = false;
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.$toastr.success("Success Desbanned", `${this.success}`);
          this.getRequests(1);
        })
        .catch((e) => {
          this.loadingBanned = false;
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    getRequests(page = 1) {
      this.isLoading = true;
      let data = {
        page: page,
        limit: this.paginate.per_page,
        search: this.search,
        type_request: this.selectedTypeRequest,
      };
      this.$http
        .server1("bwfs/requests/logs", data)
        .then((res) => {
          this.isLoading = false;
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.requestLogs = res.data;
          this.paginate = res.paginate;
        })
        .catch((e) => {
          this.isLoading = false;
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
    handleNext(event, num) {
      this.getRequests(num);
    },
    handleAddLabel(item) {
      this.labels = [];
      this.selectedRequest = item;
      this.titleModal = `Add Label for IP: ${item.src_address}`;
      if (item.label.trim()) {
        this.labels = item.label.trim().split(",");
      }
      this.$bvModal.show("modal-add-label");
    },
    saveLabel() {
      if (this.loadingSaveLabel) {
        this.$toastr.error("Please wait saving current label", `${this.error}`);
        return;
      }
      this.loadingSaveLabel = true;
      let data = {
        ip: this.selectedRequest.src_address,
        label: this.labels.join(","),
      };

      this.$http
        .server1("bwfs/requests/logs/add/label", data)
        .then((res) => {
          this.loadingSaveLabel = false;
          if (res.error) {
            this.$toastr.error(`${res.message}`, `${this.error}`);
            return;
          }
          this.$toastr.success(`Success add labels`, `${this.success}`);
          this.getRequests(1);
          this.$bvModal.hide("modal-add-label");
        })
        .catch((e) => {
          this.loadingSaveLabel = false;
          this.$toastr.error(`${e.message}`, `${this.error}`);
        });
    },
  },
  watch: {
    selectedTypeRequest(value) {
      this.getRequests(1);
    },
  },
  filters: {},
};
</script>

<style lang="scss">
@import "@/styles/commons/admin.scss";
@import "@/styles/pages/users.scss";
</style>
