import state from "@/helpers/store/state";
import set from "@/helpers/store/set";

import investor_info from "./action/investor_info";
import update_investor from "./action/update_investor";

import show_connection_modal from "./mutation/show_connection_modal";
import show_notification_modal from "./mutation/show_notification_modal";
import show_portfolio_modal from "./mutation/show_portfolio_modal";
import show_transaction_modal from "./mutation/show_transaction_modal";
import set_form_action from "./mutation/set_form_action";
import set_form_channel from "./mutation/set_form_channel";
import set_form_event from "./mutation/set_form_event";
import set_form_settings from "./mutation/set_form_settings";
import set_investor_form from "./mutation/set_investor_form";
import set_investor_token from "./mutation/set_investor_token";
import set_sidebar_option from "./mutation/set_sidebar_option";
import set_sidebar_notification_option from "./mutation/set_sidebar_notification_option";

import common_data_adapter from "../../services/adapters/common/adapter_data";
import common_message_adapter from "../../services/adapters/common/adapter_message";
import form_investor_adapter from "../../services/adapters/form_investor";

export default {
  namespaced: true,
  state: () => ({
    form_action: 0,
    form_channel: { channel: "email", emails: [] },
    form_event: {},
    form_settings: {},
    form_investor: { ...form_investor_adapter },
    investor_info: state(common_data_adapter),
    investor_token: null,
    show_connection_modal: false,
    show_notification_modal: false,
    show_portfolio_modal: false,
    show_transaction_modal: false,
    sidebar_option: 1,
    sidebar_notification_option: 1,
    update_investor: state(common_message_adapter),
  }),
  actions: {
    investor_info,
    update_investor,
  },
  mutations: {
    set,
    set_form_action,
    set_form_channel,
    set_form_event,
    set_form_settings,
    set_investor_form,
    set_investor_token,
    set_sidebar_option,
    set_sidebar_notification_option,
    show_connection_modal,
    show_notification_modal,
    show_portfolio_modal,
    show_transaction_modal,
  },
};
