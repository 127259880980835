<template>
  <div>
    <div>
      <div
        ref="twoFAModal"
        id="twoFAModal"
        class="modal fade modal-create-connection"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content max-width">
            <div class="modal-header">
              <h5 class="modal-title">2FA Token</h5>
              <span class="view-logs-modal-close" @click="modal.hide()"
                ><img :src="close_icon"
              /></span>
            </div>
            <div class="modal-body">
              <div class="container-form">
                <div class="form-group">
                  <label>Token</label>
                  <input
                    v-model="form_token.token"
                    type="text"
                    placeholder="Enter your 2FA token"
                    class="form-control"
                    :disabled="false"
                    @input="resetForm"
                  />
                  <div v-if="$v.form_token.token.$dirty">
                    <b-form-invalid-feedback
                      :state="$v.form_token.token.required"
                    >
                      Required Field
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      :state="$v.form_token.token.minLength"
                    >
                      The 2FA code must be 6 characters.
                    </b-form-invalid-feedback>
                  </div>
                  <span
                    v-if="codeNotValid"
                    style="
                      font-family: Roboto;
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 14.06px;
                      text-align: left;
                      color: #dc3545;
                    "
                  >
                    The code you have used is not valid.
                  </span>
                </div>
                <div class="modal-buttons">
                  <button class="no-bg" @click="modal.hide()">Cancel</button>
                  <button @click="handleSend">Send</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ApiKeyInfoModal :show="showApiKeyInfoModal" :keyInfo="keyInfo" />
  </div>
</template>
<script>
import $ from "jquery";
import { Modal } from "bootstrap";
import CloseIcon from "../../../assets/images/close.svg";
import { required, minLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import ApiKeyInfoModal from "./ApiKeyInfoModal.vue";

export default {
  name: "TwoFATokenModal",
  components: {
    ApiKeyInfoModal,
  },
  mounted() {
    this.modal = new Modal(this.$refs.twoFAModal);
    const self = this;
    $("#twoFAModal").on("hide.bs.modal", function (event) {
      self.$emit("close");
      self.$v.$reset();
      self.form_token.token = "";
    });
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  validations: {
    form_token: {
      token: { required, minLength: minLength(6) },
    },
  },
  data() {
    return {
      close_icon: CloseIcon,
      form_token: {
        token: "",
      },
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
      <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
      </svg> Error`,
      codeNotValid: false,
      showApiKeyInfoModal: false,
      keyInfo: {
        api_key: null,
        secret_key: null,
      },
    };
  },
  computed: {
    ...mapState("investor_configuration", ["investor_token"]),
  },
  methods: {
    handleSend() {
      let validData = this.$v.form_token.token.$invalid;
      if (validData) {
        this.$toastr.error("Please complete the fields.", `${this.error}`);
        this.$v.$touch();
        return;
      } else {
        let payload = {
          investor_token: this.investor_token,
          "2fa_token": this.form_token.token,
        };
        this.$http
          .server1(
            "bwfs/admin/InvestorExpertPanel/get_em_user_credential",
            payload
          )
          .then((res) => {
            if (res.error) {
              this.codeNotValid = true;
            } else {
              this.keyInfo = res;
              this.showApiKeyInfoModal = true;
              this.modal.hide();
            }
          })
          .catch((e) => {
            this.$toastr.error("Please complete the fields.", `${e.message}`);
          });
      }
    },
    resetForm() {
      this.$v.form_token.token.$touch();
      this.codeNotValid = false;
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.modal.show();
      } else {
        this.modal.hide();
      }
    },
  },
};
</script>
