var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-responsive"},[_c('div',{staticClass:"col-md-12 d-flex px-0"},[_c('label',{attrs:{"for":""}},[_vm._v("Sub Investors")]),(_vm.$props.isEdit)?_c('b-button',{staticClass:"small ml-auto",attrs:{"variant":"success"},on:{"click":function($event){return _vm.$emit('handleCreateSubInvestors')}}},[_vm._v("Add Sub Investor")]):_vm._e()],1),(
      _vm.$store.state.subinvestors.length > 0 ||
      _vm.$store.state.get_subinvestors_loading
    )?_c('b-table',{attrs:{"id":"table","items":_vm.$store.state.subinvestors,"fields":_vm.fields,"striped":"","hover":"","small":"","borderless":"","busy":_vm.$store.state.get_subinvestors_loading},scopedSlots:_vm._u([{key:"cell(name)",fn:function(row){return [_c('div',{staticClass:"description pointer"},[_c('span',{staticClass:"d-block mono small"},[_vm._v(" "+_vm._s(row.item.subinvestor.name)+" ")])])]}},{key:"cell(is_real)",fn:function(row){return [_c('div',{staticClass:"description pointer"},[_c('span',{staticClass:"d-block mono small"},[_vm._v(" "+_vm._s(row.item.subinvestor.is_real ? "YES" : "NO")+" ")])])]}},{key:"cell(future)",fn:function(row){return [_c('div',{staticClass:"description pointer"},[_c('span',{staticClass:"d-block mono small"},[_vm._v(" "+_vm._s(JSON.parse(row.item.subinvestor.configurations).market_type == "futures" ? "YES" : "NO")+" ")])])]}},{key:"cell(options)",fn:function(row){return [(row.item.subinvestor.is_real)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2 pointer",staticStyle:{"font-size":"14px !important"},attrs:{"title":"Audit"},on:{"click":function($event){$event.preventDefault();return _vm.handleAudit(row.item)}}},[_c('b-icon-check-circle')],1):_vm._e(),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2",staticStyle:{"font-size":"14px !important"},attrs:{"title":"Download report"}},[(_vm.token_loading != row.item.subinvestor.token_user)?_c('b-icon-cloud-download',{on:{"click":function($event){return _vm.downloadReport(row.item.subinvestor.token_user)}}}):_vm._e(),(
            _vm.loadingReport && _vm.token_loading == row.item.subinvestor.token_user
          )?_c('b-icon',{staticStyle:{"font-size":"14px !important"},attrs:{"icon":"arrow-repeat","animation":"spin-pulse"}}):_vm._e()],1),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2",staticStyle:{"font-size":"14px !important"},attrs:{"title":"Open new tab"}},[_c('router-link',{attrs:{"to":`/subinvestor/${row.item.subinvestor.token_user}`,"target":"_blank"}},[_c('b-icon-box-arrow-up-right')],1)],1),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2 pointer",staticStyle:{"font-size":"14px !important"},attrs:{"title":"Edit sub investor"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('handleEditSubinvestor', row.item)}}},[_c('b-icon-gear')],1),(_vm.$store.state.user && _vm.$store.state.user.role_id == 1)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2 pointer",staticStyle:{"font-size":"14px !important"},attrs:{"title":"Delete sub investors"},on:{"click":function($event){$event.preventDefault();return _vm.handleDelete(row.item.subinvestor)}}},[_c('b-icon-x-circle')],1):_vm._e()]}},{key:"table-busy",fn:function(){return [_c('b-skeleton-table',{attrs:{"rows":3,"columns":4,"table-props":{ striped: true }}})]},proxy:true}],null,false,2803594452)}):_vm._e(),(
      _vm.$store.state.subinvestors.length == 0 &&
      !_vm.$store.state.get_subinvestors_loading
    )?_c('b-alert',{staticClass:"my-2",attrs:{"show":"","variant":"dark"}},[_vm._v("No sub investors available.")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }