import Crypto from '@/helpers/crypto.js';

export default (to, from, next) => {
  const session = localStorage.getItem('userData');
 	if (session && session !== '') {
  	const user = JSON.parse(Crypto.decrypt(session));
 	
 	if (to.name === 'Login' && user) {
		if (user.role_id === 1) {
	     	next('/admin/backtestings');
	    } else if (user.role_id == 2) {
			next('/supervisors');
		} else {
	     	next('/investor/'+user.token);
	    } 
 	} else {
 		
 		if (user.role_id == 0 && (to.name != "Investor" || to.name != "Login")) {
 			next('/investor/'+user.token);
 		}

 		if (!user) {
	 		next('/login');
 		} else {
				next();
 			}
 		}
	} else if (to.name === 'Login') {
		next();
	} else {
	 	next('/login');
	}
};