<template>
  <div class="sidebar">
    <div class="seperator">GROUPS FILTERING</div>
    <div class="card p-2 mb-2 text-muted small">Applied Filters<br /></div>
    <div class="card p-2 mb-2">
      <b-form-group label="Search">
        <b-input-group size="sm">
          <b-form-input
            id="searchSupervisor"
            type="search"
            placeholder="Type to Search"
            v-model="search"
            @input="searchingGroup"
          ></b-form-input>
        </b-input-group>
        <span class="text-muted small mt-1"> Search by name, token... </span>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import { debounce } from "debounce";

export default {
  name: "SearchGroup",
  data() {
    return {
      search: null,
    };
  },
  methods: {
    searchingGroup: debounce(function (e) {
      this.$emit('searchInput', this.search);
      const body = {
        page: 1,
        limit: 10,
        search: this.search,
      };
      this.$store.dispatch("groups/fetch_groups", body);
    }, 500),
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/pages/groups.scss";
</style>
