<template>
		<div :id="idView" class="view-json-container"></div>
</template>
<script>
  import JSONTreeView from 'json-tree-view';
	export default {
		name: 'ViewJson',
		props: {
			jsonData: {
				required: true,
				type: Object,
				default: () => {},
			},
			filter: {
				type: String,
				required: true,
			},
			Applyfilter: {
				type: Boolean,
				required: false,
				default: false,
			},
		},
		mounted() {
			this.handleUpdate();
		},
		data() {
			return {
				idView: '',
				view: '',
				isExpanded: false,
			};
		},
		methods: {
			handleUpdate() {
				if (!this.idView && !this.view) {
					this.idView = this.$helpers.getRandomInt();
					this.view = new JSONTreeView(this.idView, {...this.$props.jsonData});
				} else {
					this.view.value = {...this.$props.jsonData};
				}
	      this.view.refresh();
	      this.view.readonly = true;
	      this.view.valueEditable = false;
	      this.view.valueEditable = false;
	      let view = this.view;

	      this.view.on('change', function(self, key, oldValue, newValue){
				  // console.log('change', key, oldValue, '=>', newValue, self);
				});
				this.view.on('rename', function(self, key, oldName, newName) {
				  // console.log('rename', key, oldName, '=>', newName, self);
				});
				this.view.on('delete', function(self, key, value, parentType) {
				  // console.log('delete', key, '=', value, parentType, self);
				});
				this.view.on('append', function(self, key, nameOrValue, newValue) {
				  //console.log('append', key, nameOrValue, '=>', newValue, self);
				});
				this.view.on('click', function(self, key, value) {
					if (key.length > 1) {
						view.children[key[1]].collapse();
					}
				});
				this.view.on('expand', function(self, key, value) {
					view.readonly = true;
				  // console.log('expand event', key, '=', value, self);
				});
				this.view.on('collapse', function(self, key, value) {
				  // console.log('collapse event', key, '=', value, self);
				});
				this.view.on('refresh', function(self, key, value) {
				  // console.log('refresh', key, '=', value, self);
				});
	      setTimeout(() => {
	      	if (Array.from(document.getElementById(this.idView.toString()).children).length >= 1) {
	      		console.log('ya existe');
	      	} else {
	      		document.getElementById(this.idView.toString()).appendChild(this.view.dom);
	      		window.view = this.view;
	      	} 
					view.readonlyWhenFiltering = true;
					view.readonly = true;
					// view.expand(1)
					// this.view.refresh();
	      }, 250);
			},
			handleFilter(){

			}
		},
		watch: {
			jsonData() {
				if (this.$props.Applyfilter) {
					this.handleUpdate();
				}
			},
			filter(value) {
				console.log('**** filter ****', value);
				if (!this.isExpanded) {
					this.view.expand(1);
					this.isExpanded = true;
				}
				if (!value) {
					this.view.filterText = null;
					this.isExpanded = false;
					this.view.collapse(1);
				} else {
					setTimeout(() => {
						this.view.filterText = value;
					}, 250);
				}
			},
		}
	}
</script>
<style>
	.view-json-container{
		line-height: 1.2;
    border-radius: 4px;
    background-color: #12141c;
    padding: 8px 4px;
	}

	.expanded{
		margin-left: 10px;
	}
</style>