<template>
  <div class="component-investors-table max-width">
    <ProcessingLoader v-if="onLoading" />
    <AlertErrorInformation v-else-if="investors_status.error" />
    <div
      class="table-investors table-responsive"
      v-else-if="investors.length > 0"
    >
      <Table
        :actions="false"
        :data="investors"
        :headers="columnHeaders"
        class="invstors-table"
        title="Investors"
      >
        <template v-slot:checkboxx>
          <!-- <div class="form-check d-flex">
            <input v-model="select_all" class="checkbox" type="checkbox" /></div
        > --></template
        >
        <template v-slot:checkbox="{ text }">
          <div class="form-check checkbox-container checkbox-label">
            <input
              v-model="selected_investor"
              :value="getInvestor(text[1])"
              class="cb-filter"
              type="checkbox"
              id="id"
            />
          </div>
        </template>
        <template v-slot:investor_name="{ text }">
          <span
            v-if="text != ''"
            class="d-flex"
            v-b-tooltip.hover
            :title="text[1]"
            :class="{
              red: !text[2],
              green: text[2],
            }"
            @click="copytoken(text[1])"
          >
            {{ text[0] }}
          </span>
          <span v-else class="no-data">no data</span>
        </template>
        <template v-slot:demo="{ text }">
          <span class="d-flex"> {{ text == 0 ? "Real" : "Demo" }} </span>
        </template>
        <template v-slot:live_nav="{ text }">
          <span v-if="text != ''" class="d-flex value"
            ><span class="icon"><img :src="dollar" /></span>
            {{ formatter(text) }}
          </span>
          <span v-else class="no-data">no data</span>
        </template>
        <template v-slot:exposure="{ text }">
          <span v-if="text[0] != ''" class="exposure">
            <span class="d-flex value"
              ><span class="icon"><img :src="dollar" /></span>
              {{ formatter(text[0]) }}
            </span>
            <span class="badge"
              >{{ formatter(text[1]) }}<img :src="percentage_gray" alt=""
            /></span>
          </span>
          <span v-else class="no-data">no data</span>
        </template>
        <template v-slot:live_pnl_quantity="{ text }">
          <span v-if="text[0] != ''" class="exposure">
            <span class="d-flex value"
              ><span class="icon"><img :src="dollar" /></span>
              {{ formatter(text[0]) }}
            </span>
            <span class="badge"
              >{{ formatter(text[1]) }}<img :src="percentage_gray" alt=""
            /></span>
          </span>
          <span v-else class="no-data">no data</span>
        </template>
        <template v-slot:performance_since_inception="{ text }">
          <span v-if="text != ''" class="d-flex value"
            ><span class="icon"><img :src="dollar" /></span>
            {{ formatter(text) }}
          </span>
          <span v-else class="no-data">no data</span>
        </template>
        <template v-slot:outflows_since_inception="{ text }">
          <span v-if="text != ''" class="d-flex value"
            ><span class="icon"><img :src="dollar" /></span>
            {{ formatter(text) }}
          </span>
          <span v-else class="no-data">no data</span>
        </template>
        <template v-slot:inflows_since_inception="{ text }">
          <span v-if="text != ''" class="d-flex value"
            ><span class="icon"><img :src="dollar" /></span>
            {{ formatter(text) }}
          </span>
          <span v-else class="no-data">no data</span>
        </template>
        <template v-slot:actions="id">
          <div class="dropdown">
            <span
              class="action-button"
              data-toggle="dropdown"
              data-reference="parent"
              data-offset="-135,0"
            >
              <img :src="menu_icon" />
            </span>
            <ul class="dropdown-menu">
              <li
                v-if="!archivedAccount(id) && user_role != 2"
                @click="archiveInvestor(id)"
              >
                <img :src="archive_icon" /> Archive account
              </li>
              <li @click="auditAccount(id)">
                <img :src="audit_icon" /> Audit account
              </li>
              <!-- <li
                v-if="$store.state.user && $store.state.user.role_id == 1"
                @click="deleteAccount(id)"
              >
                <img :src="trash_icon" /> Delete account
              </li> -->
              <li @click="downloadReportModal(id)">
                <img :src="download_icon" /> Download report
              </li>
              <li class="d-none"><img :src="copy_icon" /> Duplicate account</li>
              <li class="d-none"><img :src="eye_icon" /> View details</li>
              <li
                v-if="!statusAccount(id) && user_role != 2"
                @click="editInvestor(id)"
              >
                <img :src="eye_icon" /> Edit investor
              </li>
              <router-link :to="`/dashboard?token=${id.id}`" target="_blank">
                <li>
                  <img :src="external_link_icon" />
                  Open Dashboard
                </li>
              </router-link>
              <li @click="depositWithdrawlsInvestor(id)">
                <b-icon-file-earmark-ruled /> Transactions
              </li>
              <li
                v-if="statusAccount(id) && user_role != 2"
                @click="turnOnOffAccount(0, id)"
              >
                <img :src="turn_off_icon" /> Turn off account
              </li>
              <li
                v-if="!statusAccount(id) && user_role != 2"
                @click="turnOnOffAccount(1, id)"
              >
                <img :src="turn_on_icon" /> Turn on account
              </li>
              <li
                v-if="archivedAccount(id) && user_role != 2"
                @click="archiveInvestor(id)"
              >
                <img :src="archive_icon" /> Unarchive account
              </li>
              <li
                v-if="statusAccount(id) && user_role != 2"
                @click="editInvestor(id)"
              >
                <img :src="eye_icon" /> View config
              </li>
            </ul>
          </div>
        </template>
        <template #pagination>
          <Pagination
            :pagination="paginate"
            :go-first-page-callback="goFirstPage"
            :go-last-page-callback="goLastPage"
            :go-next-page-callback="goNextPage"
            :go-prev-page-callback="goPrevPage"
          />
        </template>
      </Table>
    </div>
    <AlertNoInformation v-else />

    <b-modal
      :id="'modal-date-range-report-k'"
      hide-footer
      hide-header
      size="sm"
      ok-only
    >
      <div class="modal-create-backtest">
        <h3 class="modal-title d-flex justify-content-between">
          <span>Select the report</span>
        </h3>
        <div class="row">
          <div class="col-md-12 text-center mb-2">
            <Multiselect
              v-model="reportType"
              :hideSelected="true"
              :options="report_type_options"
              class="form-control selector"
              label="name"
              placeholder="Select report type"
              valueProp="value"
              @select="reportTypeSelected"
            >
              <template v-slot:caret>
                <span class="multiselect-caret">
                  <img :src="icon_caret" alt="" />
                </span>
              </template>
            </Multiselect>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <BaseDateRangePicker
              v-model="dateRange"
              format="YYYY-MM-DD"
              :showLabel="false"
              label="Date Range"
              :dateDefault="dateRange"
              :timepicker="true"
              :everydayMinDate="everydayMinDate"
              :everydayMaxDate="everydayMaxDate"
            />
          </div>
          <div class="col-md-12 text-center mt-2">
            <ProcessingLoader
              v-if="loadingReport"
              class="investor-progress-loader"
            />
            <b-button
              v-else
              variant="success"
              class="w-100 download-button"
              @click="downloadReportHandle"
              >Downloads</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
    <AuditAccount
      :display-modal="displayAuditModal"
      :investor="investor_for_audit"
      @close="handleAuditModalClose"
    />
  </div>
</template>

<script>
import AlertErrorInformation from "../../commons/alerts/AlertErrorInformation.vue";
import AlertNoInformation from "../../commons/alerts/AlertNoInformation.vue";
import ArchiveIcon from "../../../../public/common/archive-icon.svg";
import AuditIcon from "../../../../public/common/audit-menu.svg";
import BaseDateRangePicker from "@/components/commons/BaseDateRangePicker";
import CopyIcon from "../../../../public/common/copy_white.png";

import Dollar from "../../../../public/common/dollar.png";
import DownloadIcon from "../../../../public/common/download-menu.svg";
import EyeIcon from "../../../assets/images/eye.svg";
import ExternalLinkIcon from "../../../../public/common/external-link-icon.svg";
import IconCaret from "../../../../public/common/select-arrow-down.svg";
import MenuOptions from "../../../../public/common/menu-dots-vertical.png";
import moment from "moment";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2";
import number_formatter from "../../../helpers/formatters/number_formatter";
import Pagination from "../../commons/Pagination.vue";
import Percentage from "../../../../public/common/percentage.png";
import PercentageGrey from "../../../../public/common/percentage-gray.svg";
import ProcessingLoader from "../../commons/alerts/ProcessingLoader.vue";
import Table from "../../commons/table/Table.vue";
import TrashIcon from "../../../../public/common/trash-icon.svg";
import TurnOnIcon from "../../../../public/common/toggle-on.svg";
import TurnOffIcon from "../../../../public/common/toggle-off.svg";
import { mapState } from "vuex";
import { saveAs } from "file-saver";
import AuditAccount from "@/components/pages/investors/AuditAccount.vue";

export default {
  name: "InvestorsTable",
  props: {
    openDepositModal: {
      type: Function,
      default: () => {},
    },
    openInvestorModal: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      archive_icon: ArchiveIcon,
      audit_icon: AuditIcon,
      checkbox_model: true,
      columnHeaders: [
        /* {
          name: "checkbox",
          title: "",
          id: "investor_token",
          checkbox: true,
          secondary_name: "investor_token",
        }, */
        {
          name: "investor_name",
          title: "Investor",
          align: "left",
          secondary_name: "investor_token",
          third_name: "is_on",
        },
        {
          name: "demo",
          title: "Type",
          align: "left",
        },
        { name: "live_nav", title: "Live NAV", align: "left" },
        {
          name: "exposure",
          title: "Exposure USDT",
          align: "left",
          secondary_name: "exposure_percentage",
          info: true,
        },
        {
          name: "live_pnl_quantity",
          title: "Live PnL",
          align: "left",
          secondary_name: "live_pnl_percentage",
          info: true,
        },
        {
          name: "performance_since_inception",
          title: "Performance",
          align: "left",
          info: true,
        },
        {
          name: "inflows_since_inception",
          title: "Inflows",
          align: "left",
          info: true,
        },
        {
          name: "outflows_since_inception",
          title: "Outflows",
          align: "left",
          info: true,
        },
        {
          title: "",
          action: true,
          id: "investor_token",
        },
      ],
      copy_icon: CopyIcon,
      dateRange: "",
      date_report_star: "",
      date_report_end: "",
      displayAuditModal: false,
      dollar: Dollar,
      download_icon: DownloadIcon,
      error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
      <path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
      </svg> Error`,
      eye_icon: EyeIcon,
      everydayMinDate: null,
      external_link_icon: ExternalLinkIcon,
      icon_caret: IconCaret,
      investor_for_audit: null,
      loadingReport: false,
      menu_icon: MenuOptions,
      percentage: Percentage,
      percentage_gray: PercentageGrey,
      reportType: "reports",
      report_type_options: [
        { value: "reports", name: "Reports" },
        { value: "orders_reports", name: "Orders Report" },
      ],
      showDepositWithdrwalModal: false,
      showInvestorModal: false,
      success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="10" fill="#8BF125" fill-opacity="0.3"/>
          <g clip-path="url(#clip0_1634_940)">
          <path d="M14.2995 6.84625L8.5416 12.6038C8.50288 12.6426 8.45688 12.6735 8.40622 12.6945C8.35556 12.7155 8.30124 12.7264 8.24639 12.7264C8.19153 12.7264 8.13722 12.7155 8.08656 12.6945C8.0359 12.6735 7.98989 12.6426 7.95118 12.6038L5.72451 10.375C5.6858 10.3361 5.63979 10.3053 5.58913 10.2843C5.53848 10.2632 5.48416 10.2524 5.4293 10.2524C5.37445 10.2524 5.32013 10.2632 5.26947 10.2843C5.21881 10.3053 5.17281 10.3361 5.1341 10.375C5.09523 10.4137 5.06439 10.4597 5.04335 10.5104C5.02231 10.561 5.01147 10.6154 5.01147 10.6702C5.01147 10.7251 5.02231 10.7794 5.04335 10.83C5.06439 10.8807 5.09523 10.9267 5.1341 10.9654L7.3616 13.1925C7.59657 13.427 7.91501 13.5588 8.24701 13.5588C8.57901 13.5588 8.89745 13.427 9.13243 13.1925L14.8899 7.43625C14.9287 7.39755 14.9595 7.35157 14.9805 7.30095C15.0015 7.25033 15.0123 7.19606 15.0123 7.14125C15.0123 7.08645 15.0015 7.03218 14.9805 6.98156C14.9595 6.93094 14.9287 6.88496 14.8899 6.84625C14.8512 6.80739 14.8052 6.77655 14.7546 6.75551C14.7039 6.73446 14.6496 6.72363 14.5947 6.72363C14.5399 6.72363 14.4855 6.73446 14.4349 6.75551C14.3842 6.77655 14.3382 6.80739 14.2995 6.84625Z" fill="#1D1E25"/>
          </g>
          <defs>
          <clipPath id="clip0_1634_940">
          <rect width="10" height="10" fill="white" transform="translate(5 5)"/>
          </clipPath>
          </defs>
          </svg> Success`,
      token: null,
      trash_icon: TrashIcon,
      turn_on_icon: TurnOnIcon,
      turn_off_icon: TurnOffIcon,
    };
  },
  components: {
    AlertErrorInformation,
    AlertNoInformation,
    AuditAccount,
    BaseDateRangePicker,
    Multiselect,
    Pagination,
    ProcessingLoader,
    Table,
  },
  created() {
    this.$store.dispatch("investors_v2/investors_list", {});
    let date_start = new Date();
    let date_end = new Date();
    date_start.setDate(date_start.getDate() - 7);
    this.date_start = date_start.toISOString().substr(0, 10);
    this.date_end = date_end.toISOString().substr(0, 10);
  },
  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId == "modal-date-range-report-k") {
        this.dateRange = "";
      }
    });
  },
  computed: {
    ...mapState("investors_v2", ["investors_list"]),
    current_page() {
      return this.investors_list._data.paginate
        ? this.investors_list._data.paginate.current_page
        : null;
    },
    everydayMaxDate() {
      return new Date();
    },
    investors() {
      return this.investors_list && this.investors_list._data
        ? this.investors_list._data.data
        : [];
    },
    investors_status() {
      return this.investors_list && this.investors_list._data
        ? this.investors_list._data
        : [];
    },
    onLoading() {
      return this.investors_list._status._on_loading;
    },
    paginate() {
      return this.investors_list._data.paginate
        ? this.investors_list._data.paginate
        : null;
    },
    select_all: {
      get() {
        return this.$store.state.investors.selected_all_investors;
      },
      set(value) {
        this.$store.commit("investors_v2/selected_all_investors", value);

        if (value) {
          const tokens = this.investors_list._data.data.map(
            ({ investor_token }) => {
              return investor_token;
            }
          );

          this.$store.commit("investors_v2/selected_investor", tokens);
        } else {
          this.$store.commit("investors_v2/selected_investor", []);
        }
      },
    },
    selected_investor: {
      get() {
        return this.$store.state.investors.selected_investor;
      },
      set(value) {
        if (this.select_all && value.length != this.selected_investor.length) {
          this.$store.commit("investors_v2/selected_all_investors", false);
        }

        this.$store.commit("investors_v2/selected_investor", value);
      },
    },
    showReloadButton() {
      return (
        this.investors_list._status._on_ready ||
        this.investors_list._status._on_error
      );
    },
    user_role() {
      return this.$store.state.user ? this.$store.state.user.role_id : null;
    },
  },
  watch: {
    showReloadButton: function (value) {
      this.$emit("reload", value);
    },
  },
  methods: {
    auditAccount(id) {
      this.investor_for_audit = id.id;
      this.displayAuditModal = true;
    },
    archivedAccount(token) {
      const res = this.investors
        .filter((investor) => investor.investor_token == token.id)
        .map((investor) => investor.is_archived)
        .toString();

      return res && res == "1" ? true : false;
    },
    archiveInvestor(id) {
      const body = {
        investor_token: id.id,
      };

      Swal.fire({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#1c2030",
        confirmButtonText: "Confirm",
        preConfirm: async () => {
          await this.performArchiveAccount(body);
        },
      }).then(async (result) => {
        if (result.value) {
        }
      });
    },
    clearModal() {
      this.dateRange = "";
    },
    copytoken(token) {
      navigator.clipboard.writeText(token);
      this.$toastr.success(
        "The token has been copied correctly.",
        `${this.success}`
      );
    },
    deleteAccount(id) {
      const body = {
        token: id.id,
      };

      Swal.fire({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#1c2030",
        confirmButtonText: "Delete",
        preConfirm: async (value) => {
          await this.performDeleteAccount(body);
        },
      }).then(async (result) => {
        if (result.data) {
        }
      });
    },
    depositWithdrawlsInvestor(id) {
      this.$store.commit("connections/set_investor_token", id.id);
      this.$store.dispatch("connections/connection_list_selector");
      this.$store.commit("transactions/set_investor_token", id.id);
      this.$store.dispatch("transactions/transaction_list", {});
      this.openDepositModal();
    },
    downloadReportModal(id) {
      this.$store.commit("dashboard/set_investor_token", id.id);
      const selected_investor = this.investors_list._data.data.find(
        (investor) => investor.investor_token == id.id
      );

      this.everydayMinDate = selected_investor.first_open_order_date
        ? new Date(selected_investor.first_open_order_date)
        : new Date(selected_investor.created_at);

      this.$bvModal.show("modal-date-range-report-k");
    },
    downloadReportHandle() {
      if (this.reportType == "reports") {
        this.downloadReport();
      } else {
        this.downloadOrdersReport();
      }
    },
    downloadReport() {
      if (!this.dateRange.startDate) {
        this.$toastr.error("Please select the date range", `${this.error}`);
        return false;
      }

      this.$store.dispatch("dashboard/download_report", {
        start_date: moment(this.dateRange.startDate + " 00:00:00").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        end_date: moment(this.dateRange.endDate + " 23:59:59").format(
          "YYYY-MM-DD HH:mm:ss"
        ),

        callback: ({ response }) => {
          if (response.error) {
            this.$toastr.error(response.message, `${this.error}`);
            return;
          } else {
            let file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.content}`;
            this.convertBlobToExcel(file, response.filename);
            this.closeModal();
            this.$bvModal.hide("modal-date-range-report-k");
            this.$toastr.error(
              "Report successfully downloaded ",
              `${this.success}`
            );
          }
        },
      });
    },
    convertBlobToExcel(blobData, filename) {
      saveAs(blobData, filename);
    },
    downloadOrdersReport() {
      if (!this.dateRange.startDate) {
        this.$toastr.error("Please select the date range", `${this.error}`);
        return false;
      }

      this.$store.dispatch("dashboard/download_orders_report", {
        start_date: moment(this.dateRange.startDate).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD HH:mm:ss"),

        callback: ({ response }) => {
          if (response.error) {
            this.$toastr.error(response.message, `${this.error}`);
            return;
          } else {
            window.open(
              this.$http.url_api(true) + "/bwfs/static/" + response.message
            );
            this.$bvModal.hide("modal-date-range-report-k");
            this.$toastr.error(
              "Report successfully downloaded ",
              `${this.success}`
            );
          }
        },
      });
    },
    editInvestor(id) {
      const selected_investor = this.investors_list._data.data.find(
        (investor) => investor.investor_token == id.id
      );

      const form_data = {
        name: selected_investor.investor_name,
        note: selected_investor.note,
        token: selected_investor.investor_token,
        base_currency: selected_investor.base_currency,
        demo: selected_investor.demo == 1 ? true : false,
        future: selected_investor.future,
        manual_order: selected_investor.manual_order,
        note: selected_investor.note,
        is_on: selected_investor.is_on == 1 ? true : false,
      };

      this.$store.commit("investors_v2/set_investor_form", form_data);
      this.$store.commit("investors_v2/set_edit_investor_modal", true);

      this.openInvestorModal();
    },
    formatter(number) {
      return number_formatter(number);
    },
    getInvestor(id) {
      return id;
    },
    goFirstPage() {
      this.$store.dispatch("investors_v2/investors_list", {
        page: 1,
      });
    },
    goLastPage() {
      this.$store.dispatch("investors_v2/investors_list", {
        page: this.paginate.total_pages,
      });
    },
    goNextPage() {
      this.$store.dispatch("investors_v2/investors_list", {
        page: this.current_page + 1,
      });
    },
    goPrevPage() {
      this.$store.dispatch("investors_v2/investors_list", {
        page: this.current_page - 1,
      });
    },
    handleAuditModalClose() {
      this.displayAuditModal = false;
    },
    async performArchiveAccount(body) {
      return new Promise((resolve) => {
        this.$store.dispatch("investors_v2/archive_account", {
          callback: ({ response }) => {
            if (response.error) {
              this.$toastr.error(`${response.message}`, `${this.error}`);
              return;
            } else {
              this.$toastr.success(`${response.message}`, `${this.success}`);
              this.$store.dispatch("investors_v2/investors_list", {});
            }
            resolve();
          },
          body,
        });
      });
    },
    async performDeleteAccount(body) {
      return new Promise((resolve) => {
        this.$store.dispatch("investors_v2/delete_investor", {
          callback: ({ response }) => {
            if (response.error) {
              this.$toastr.error(`${response.message}`, `${this.error}`);
              return;
            } else {
              this.$toastr.success(
                "Account deleted successfully.",
                `${this.success}`
              );
              this.$store.dispatch("investors_v2/investors_list", {});
            }
            resolve();
          },
          body,
        });
      });
    },
    async performTurnOnOffAccount(body) {
      return new Promise((resolve) => {
        this.$store.dispatch("investors_v2/turn_on_off_account", {
          callback: ({ response }) => {
            if (response.error) {
              this.$toastr.error(`${response.message}`, `${this.error}`);
              return;
            } else {
              this.$toastr.success(
                "Investor archived successfully.",
                `${this.success}`
              );
              this.$store.dispatch("investors_v2/investors_list", {});
            }
            resolve();
          },
          body,
        });
      });
    },
    reportTypeSelected(value) {
      this.reportType = value;
    },
    statusAccount(token) {
      const res = this.investors
        .filter((investor) => investor.investor_token == token.id)
        .map((investor) => investor.is_on)
        .toString();

      return res && res == "1" ? true : false;
    },
    turnOnOffAccount(value, token) {
      const body = { investor_token: token.id, on: value };

      Swal.fire({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#1c2030",
        confirmButtonText: "Confirm",
        preConfirm: async () => {
          await this.performTurnOnOffAccount(body);
        },
      }).then(async (result) => {
        if (result.value) {
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/pages/investors_list/investors_table.scss";
</style>
