const error_icon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="20" height="20" rx="10" fill="#DC3545" fill-opacity="0.3"/>
<path d="M12.4999 7.49992C12.4218 7.42181 12.3158 7.37793 12.2053 7.37793C12.0949 7.37793 11.9889 7.42181 11.9108 7.49992L9.99992 9.41076L8.08909 7.49992C8.01095 7.42181 7.90499 7.37793 7.79451 7.37793C7.68402 7.37793 7.57806 7.42181 7.49992 7.49992C7.42181 7.57806 7.37793 7.68402 7.37793 7.79451C7.37793 7.90499 7.42181 8.01095 7.49992 8.08909L9.41076 9.99992L7.49992 11.9108C7.42181 11.9889 7.37793 12.0949 7.37793 12.2053C7.37793 12.3158 7.42181 12.4218 7.49992 12.4999C7.57806 12.578 7.68402 12.6219 7.79451 12.6219C7.90499 12.6219 8.01095 12.578 8.08909 12.4999L9.99992 10.5891L11.9108 12.4999C11.9889 12.578 12.0949 12.6219 12.2053 12.6219C12.3158 12.6219 12.4218 12.578 12.4999 12.4999C12.578 12.4218 12.6219 12.3158 12.6219 12.2053C12.6219 12.0949 12.578 11.9889 12.4999 11.9108L10.5891 9.99992L12.4999 8.08909C12.578 8.01095 12.6219 7.90499 12.6219 7.79451C12.6219 7.68402 12.578 7.57806 12.4999 7.49992Z" fill="#1D1E25"/>
</svg> Error`;

export default {
  state: {
    loadingInfoSubInvestor: false,
    SubInvestorInfo: {},
  },
  mutations: {
    get_info_subinvestor(state, payload) {
      state.loadingInfoSubInvestor = true;
      payload.vm.$http
        .server1("bwfs/admin/subinvestors/info", payload.data)
        .then((res) => {
          if (res.error) {
            state.loadingInfoSubInvestor = false;
            payload.vm.$toastr.error(
              res.message ? res.message : "An error has occurred",
              error_icon
            );
            return;
          }
          state.loadingInfoSubInvestor = false;
          state.SubInvestorInfo = res.data;
        });
    },
    set_subinvestor_loading(state, loadingInfoSubInvestor) {
      state.loadingInfoSubInvestor = loadingInfoSubInvestor;
    },
  },
};
