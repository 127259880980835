import CONSTANTS from './constants';

let Socket = {};

function handleSocket (path, onOpen, onClose, onMessage, onError, customUrl=null) {
  let basePath = CONSTANTS.ws_uri_develop;
  
  let current_host = window.location.hostname;
  if (current_host == 'production.bwfs.creedandbear.com') {
    basePath = CONSTANTS.ws_uri_production;
  } else if (current_host == 'production-btc.bwfs.creedandbear.com') {
    basePath = CONSTANTS.ws_uri_production_btc;
  }
  let wsUri = basePath+path;
  if (customUrl) {
  	wsUri = customUrl;
  }
  let websocket = new WebSocket(wsUri);
  websocket.onopen = (evt) =>  onOpen(evt);
  websocket.onclose = (evt) =>  onClose(evt);
  websocket.onmessage = (evt) =>  onMessage(evt);
  websocket.onerror = (evt) =>  onError(evt);
  return websocket;
}
export default handleSocket;

