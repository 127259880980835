<template>
  <div class="container-fluid">
    <div class="module-content module-dashboard">
      <div class="row">
        <HeaderTopBar />
      </div>
      <div class="row max-width">
        <div class="col-12 col-xl-3 br-right pd-top">
          <BasicInformation />
          <Algorithms />
          <OptionsModal />
        </div>
        <div class="col-12 col-xl-6 br-right">
          <PerformanceChart />
          <Statistics />
        </div>
        <div class="col-12 col-xl-3">
          <div>
            <ExposureChart />
          </div>
          <div>
            <OpenedOrders />
          </div>
        </div>
      </div>
      <div class="row br-top">
        <div class="col-12">
          <OpenedOrdersTable />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Algorithms from "../components/pages/dashboard/Algorithms.vue";
import BasicInformation from "../components/pages/dashboard/BasicInformation.vue";
import ExposureChart from "../components/pages/dashboard/ExposureChart.vue";
import HeaderTopBar from "../components/pages/dashboard/HeaderTopBar.vue";
import OpenedOrders from "../components/pages/dashboard/OpenedOrders.vue";
import OpenedOrdersTable from "../components/pages/dashboard/OpenedOrdersTable.vue";
import OptionsModal from "../components/pages/dashboard/modals/OptionsModal.vue";
import PerformanceChart from "../components/pages/dashboard/PerformanceChart.vue";
import Statistics from "../components/pages/dashboard/Statistics.vue";
import "@vueform/multiselect/themes/default.css";
import $ from "jquery";

export default {
  name: "Dashboard",
  components: {
    ExposureChart,
    BasicInformation,
    Algorithms,
    Statistics,
    HeaderTopBar,
    PerformanceChart,
    OpenedOrders,
    OpenedOrdersTable,
    OptionsModal,
  },
  created() {
    this.$store.dispatch("dashboard/basic_information");
    this.$store.dispatch("dashboard/statics_fee");
    this.$store.dispatch("dashboard/statics_max_drawdown");
    this.$store.dispatch("dashboard/statics_metrics");
    this.$store.dispatch("dashboard/exposure");
    this.$store.dispatch("dashboard/exposure_chart");
    this.$store.dispatch("dashboard/algorithms");
    this.$store.dispatch("dashboard/performance_account", {});
    this.$store.dispatch("dashboard/statistics_nav");
    this.$store.dispatch("dashboard/algorithm_selector_list");
    this.$store.dispatch("dashboard/open_orders");
    this.$store.dispatch("dashboard/exchange_selector_list");
    this.$store.dispatch("dashboard/connection_list_selector");
  },
  methods: {
    load() {
      this.$store.dispatch("dashboard/basic_information");
      this.$store.dispatch("dashboard/statics_fee");
      this.$store.dispatch("dashboard/statics_max_drawdown");
      this.$store.dispatch("dashboard/statics_metrics");
      this.$store.dispatch("dashboard/exposure");
      this.$store.dispatch("dashboard/exposure_chart");
      this.$store.dispatch("dashboard/algorithms");
      this.$store.dispatch("dashboard/performance_account", {});
      this.$store.dispatch("dashboard/statistics_nav");
    },
  },
  computed: {
    algorithm() {
      return this.$store.state.dashboard.global_selected_algorithm;
    },
    exchange() {
      return this.$store.state.dashboard.global_selected_exchange;
    },
  },
  mounted() {
    $(".main-navbar").addClass("max-width");
    $(".main-navbar").addClass("border-bottom-0");
  },
  beforeDestroy() {
    $(".main-navbar").removeClass("max-width");
    $(".main-navbar").removeClass("border-bottom-0");
  },
  watch: {
    algorithm(value) {
      this.load();
    },
    exchange(value) {
      this.load();
    },
    "$route.query.token"(value) {
      this.$router.go();
    },
  },
};
</script>
<style lang="scss">
@import "../styles/pages/dashboard.scss";
</style>
