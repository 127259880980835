import DeleteNotificationConfigService from "../../../services/delete_notification_configuration.service";
export default ({ commit, state }, { callback, error, body }) => {
  commit("set", {
    _state: "delete_notification_configuration",
    _status: {
      _on_ready: false,
      _on_loading: true,
      _on_error: false,
    },
  });

  const token = body.token;

  DeleteNotificationConfigService({
    token,
    callback: ({ response }) => {
      commit("set", {
        _state: "delete_notification_configuration",
        _data: response,
        _status: {
          _on_ready: true,
          _on_loading: false,
        },
      });
      if (typeof callback !== "undefined") {
        callback({ response });
      }
    },
    error: ({ response }) => {
      commit("set", {
        _state: "delete_notification_configuration",
        _status: {
          _on_ready: false,
          _on_error: true,
          _on_loading: false,
        },
      });

      if (typeof error !== "undefined") {
        error({ response });
      }
    },
  });
};
