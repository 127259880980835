export default {
  data: {
    nav: null,
    performance_since_inception: null,
    performance_since_inception_percentage: null,
    performance_year_to_date: null,
    performance_year_to_date_percentage: null,
    performance_month_to_date: null,
    performance_month_to_date_percentage: null,
    exposure: null,
    today_returns: null,
    exposure_percentage: null,
    upnl: null,
    upnl_percentage: null,
  },
};
