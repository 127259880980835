<template>
  <div class="algorithms">
    <div class="algorithms-title">Algorithm's</div>
    <ProcessingLoader v-if="onLoading" />
    <AlertErrorInformation v-else-if="onError" />
    <div
      v-else-if="series.length > 0"
      class="algorithms-table-container nice-scroll"
    >
      <Table
        :actions="false"
        :data="series"
        :headers="columnHeaders"
        class="algorithms-table"
        title="Orders"
      >
        <template v-slot:algorithm_name="{ text }">
          <span
            :class="[text[1] ? 'green' : 'red']"
            v-b-tooltip.hover
            :title="text[0]"
            >{{ text[0] }}</span
          >
        </template>
        <template v-slot:current_nav="{ text }">
          <div class="value">$ {{ formatter(text[0]) }} ({{ formatter(text[1]) + "%" }})</div>
        </template>
        <template v-slot:exposure="{ text }">
          <div class="value">
            $ {{ formatter(text[0]) }} ({{ formatter(text[1]) + "%" }})
          </div>
        </template>
      </Table>
    </div>
    <AlertNoInformation v-else />
  </div>
</template>

<script>
import { mapState } from "vuex";
import AlertErrorInformation from "../../commons/alerts/AlertErrorInformation.vue";
import AlertNoInformation from "../../commons/alerts/AlertNoInformation.vue";
import ProcessingLoader from "../../commons/alerts/ProcessingLoader.vue";
import Table from "../../commons/table/Table.vue";
import number_formatter from "../../../helpers/formatters/number_formatter";

export default {
  name: "Algorithms",
  components: {
    AlertNoInformation,
    AlertErrorInformation,
    ProcessingLoader,
    Table,
  },
  data() {
    return {
      columnHeaders: [
        {
          name: "algorithm_name",
          title: "Name",
          align: "left",
          secondary_name: "is_on",
        },
        {
          name: "leverage",
          title: "Leverage",
          align: "left",
        },
        {
          name: "current_nav",
          title: "Nav",
          align: "left",
          secondary_name: "nav_percentage",
        },
        {
          name: "exposure",
          title: "Exposure",
          align: "left",
          secondary_name: "exposure_percentage",
        },
      ],
      selectedAlgorithm: "",
    };
  },
  computed: {
    ...mapState("dashboard", ["algorithms"]),
    onError() {
      return this.algorithms._status._on_error;
    },
    onLoading() {
      return this.algorithms._status._on_loading;
    },
    series() {
      const data =
        this.algorithms && this.algorithms._data
          ? this.algorithms._data.data
          : [];

      const totalSum = data.reduce((accumulator, current) => {
        return accumulator + current.current_nav;
      }, 0);

      const result = data.map((item) => {
        return {
          ...item,
          nav_percentage: ((item.current_nav / totalSum) * 100).toFixed(2), 
        };
      });
      return result;
    },
  },
  methods: {
    formatter(number) {
      return number_formatter(number, 2);
    },
  },
};
</script>
<style lang="scss">
@import "../../../styles/pages/dashboard/algorithms.scss";
</style>