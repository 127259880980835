<template>
  <div class="container-fluid">
    <div class="systems-heartbeat">
      <div class="row"><HeaderTopBar /></div>

      <div class="health-filter">
        <div class="alert-section" v-if="showAlert">
          <AlgorithmAlert />
        </div>

        <ProcessingLoader v-if="onLoading" />
        <div class="timeline-section" v-if="!onLoading">
          <HealthBarTimeline :timelineOptions="['All']" :data="timeline_data" />
        </div>
        <div class="health-filter-sec">
          <div class="fake-alert-section" v-if="showAlert"></div>
          <div class="filter-section">
            <Filters />
          </div>
        </div>
      </div>
      <SystemsHeartbeatTable />
    </div>
  </div>
</template>

<script>
import SystemsHeartbeatTable from "../components/pages/systems_heartbeat/SystemsHeartbeatTable.vue";
import HeaderTopBar from "../components/pages/systems_heartbeat/HeaderTopBar.vue";
import Filters from "../components/pages/systems_heartbeat/Filters.vue";
import $ from "jquery";
import "@vueform/multiselect/themes/default.css";
import AlgorithmAlert from "../components/pages/systems_heartbeat/AlgorithmAlert.vue";
import HealthBarTimeline from "../components/commons/HealthBarTimeline.vue";
import { mapState } from "vuex";
import ProcessingLoader from "../components/commons/alerts/ProcessingLoader.vue";

export default {
  name: "systems_heartbeat",
  components: {
    AlgorithmAlert,
    Filters,
    HealthBarTimeline,
    HeaderTopBar,
    SystemsHeartbeatTable,
    ProcessingLoader,
  },
  created() {},
  computed: {
    ...mapState("systems_heartbeat", ["systems_health_timeline"]),
    timeline_data() {
      return this.systems_health_timeline._data.data;
    },
    showAlert() {
      return this.systems_health_timeline._data.data.dead_tokens_now;
    },
    onLoading() {
      return this.systems_health_timeline._status._on_loading;
    },
  },
  mounted() {
    this.$store.dispatch("systems_heartbeat/systems_health_timeline");
  },
};
</script>
<style lang="scss">
@import "../styles/pages/systems_heartbeat.scss";
</style>
