<template>
  <div>
    <div class="card p-2 mb-2">
      <label>Symbols</label>
      <b-table
        :items="symbol"
        :fields ="fields"
        responsive
        show-empty
      >
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableSymbols',
  props: ['symbol'],
  data() {
    return {
      fields: ['symbol'],
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .table tbody tr td {
  padding: 3px;
  font-size: 12px;
}
::v-deep .table thead tr th {
  padding: 3px;
}
</style>