import state from "@/helpers/store/state";
import set from "@/helpers/store/set";

import archive_account from "./action/archive_account";
import create_investor from "./action/create_investor";
import create_labeling from "./action/create_labeling";
import delete_investor from "./action/delete_investor";
import investors_list from "./action/investors_list";
import labeling_list from "./action/labeling_list";
import turn_on_off_account from "./action/turn_on_off_account";
import update_investor from "./action/update_investor";

import selected_all_investors from "./mutations/selected_all_investors";
import selected_investor from "./mutations/selected_investor";
import set_edit_investor_modal from "./mutations/set_edit_investor_modal";
import set_investor_archived from "./mutations/set_investor_archived";
import set_investor_form from "./mutations/set_investor_form";
import set_investor_running from "./mutations/set_investor_running";

import common_list_adapter from "../../services/adapters/common/adapter_list";
import common_message_adapter from "../../services/adapters/common/adapter_message";
import form_investor_adapter from "../../services/adapters/form_investor";
import labeling_list_adapter from "../../services/adapters/common/adapter_data_array";
import investors_filters_adapter from "../../services/adapters/investors_filters";

export default {
  namespaced: true,
  state: () => ({
    archive_account: state(common_message_adapter),
    create_investor: state(common_message_adapter),
    create_labeling: state(common_message_adapter),
    delete_investor: state(common_message_adapter),
    form_investor: { ...form_investor_adapter },
    investors_filters: { ...investors_filters_adapter },
    investors_list: state(common_list_adapter),
    is_edit_investor: false,
    labeling_list: state(labeling_list_adapter),
    selected_all_investors: false,
    selected_investor: [],
    turn_on_off_account: state(common_message_adapter),
    update_investor: state(common_message_adapter),
  }),
  actions: {
    archive_account,
    create_investor,
    create_labeling,
    delete_investor,
    investors_list,
    labeling_list,
    turn_on_off_account,
    update_investor,
  },
  mutations: {
    selected_all_investors,
    selected_investor,
    set,
    set_edit_investor_modal,
    set_investor_archived,
    set_investor_form,
    set_investor_running,
  },
};
