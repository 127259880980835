import number_formatter from "../../../helpers/formatters/number_formatter";
import random_number from "../../../helpers/generators/random_number";

export default () => {
  const annualized_volatility = 0;
  const drawdown_high_water_mark = 0;
  const high_water_mark = 0;
  const sharpe_ratio = 0;

  return {
    annualized_volatility,
    drawdown_high_water_mark,
    high_water_mark,
    sharpe_ratio,
  };
};
