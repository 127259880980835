<template>
  <div
    ref="confirmDelete"
    id="confirmDelete"
    class="modal fade modal-confirm-delete"
    tabindex="-1"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-confirm-delete-close" @click="closeModal"
            ><img :src="close_icon"
          /></span>
        </div>
        <div class="warning-icon">
          <img :src="warning_icon" />
        </div>
        <div class="warning-title">Confirm deletion!</div>
        <p class="warning-description">
          You are about to delete this item and it cannot be recovered. Do you
          wish to continue?
        </p>
        <button class="delete-button" @click="confirmDelete">Delete</button>
        <button class="cancel-button" @click="closeModal">cancel</button>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import closeIcon from "../../../../assets/images/close.svg";
import warningIcon from "../../../../../public/common/confirm-delete-warning.png";
export default {
  name: "ConfirmDeleteModal",
  mounted() {
    $("#confirmDelete").modal("show");
    const self = this;
    $("#confirmDelete").on("hide.bs.modal", function (event) {
      self.$emit("closed");
    });
  },
  data() {
    return {
      close_icon: closeIcon,
      warning_icon: warningIcon,
    };
  },
  methods: {
    closeModal() {
      $("#confirmDelete").modal("hide");
    },
    confirmDelete() {
      this.$emit('confirm-delete');
      this.closeModal();
    },
  },
};
</script>

<style lang="scss">
@import "../../../../styles/pages/systems_heartbeat/confirm_delete_modal.scss";
</style>
