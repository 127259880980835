import * as request from "../helpers/request-new";
import adapter from "./adapters/common/adapter_message";
import response_mock from "./mockdata/responses/common/responses_message_success";
import { delete_investor as microservice } from "../helpers/microservices";

export default ({ body, params, callback, error, token }) => {
  request.execute({
    microservice: microservice(token),
    method: "DELETE",
    body,
    params,
    callback,
    error,
    adapter: adapter,
    mockdata: response_mock(),
    debug: false,
  });
};
