export default {
  data: {
    fee_since_inception: null,
    fee_since_inception_percentage: null,
    fee_year_to_date: null,
    fee_year_to_date_percentage: null,
    fee_month_to_date: null,
    fee_month_to_date_percentage: null,
  },
};
