<template>
  <div class="channels-tab-component">
    <div class="title">
      <span><img :src="channel" alt="" width="20" height="20px" /></span>
      <h1>Channels</h1>
    </div>
    <div class="channels">
      <div class="channels-container">
        <div
          :class="{ act: form_channel.channel == 'email' }"
          class="box"
          @click="setChannel(0)"
        >
          <span
            ><img
              :src="form_channel.channel == 'email' ? envelope_act : envelope"
              alt=""
          /></span>
          <h2>Email</h2>
        </div>
        <div
          :class="{ act: form_channel.channel == 'whatsapp' }"
          class="box"
          @click="setChannel(1)"
        >
          <span
            ><img
              :src="
                form_channel.channel == 'whatsapp' ? whatsapp_act : whatsapp
              "
              alt=""
          /></span>
          <h2>Whatsapp</h2>
        </div>
      </div>
      <div class="channels-container">
        <div
          :class="{ act: form_channel.channel == 'telegram' }"
          class="box"
          @click="setChannel(2)"
        >
          <span
            ><img
              :src="
                form_channel.channel == 'telegram' ? telegram_act : telegram
              "
              alt=""
          /></span>
          <h2>Telegram</h2>
        </div>
        <div
          :class="{ act: form_channel.channel == 'slack' }"
          class="box"
          @click="setChannel(3)"
        >
          <span
            ><img
              :src="form_channel.channel == 'slack' ? slack_act : slack"
              alt=""
          /></span>
          <h2>Slack</h2>
        </div>
      </div>
    </div>

    <hr />
    <div class="email">
      <div class="form-group">
        <label>Email</label>
        <div class="input-group">
          <input
            v-model="new_item"
            type="text"
            class="form-control"
            placeholder="Enter an email"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            @keyup.enter="addItem"
          />

          <div class="input-group-append">
            <button class="no-bg" type="button" @click="addItem">
              <img :src="select_icon" alt="" />
            </button>
          </div>
        </div>

        <div class="badge-container">
          <div v-for="(email, index) in form_channel.emails">
            <span>
              <label class="badge">
                <span>{{ email }}</span>
                <span role="button" @click="removeTag(index)"
                  ><img :src="remove_badge" alt=""
                /></span>
              </label>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionIcon from "../../../../../../../public/common/action-sidebar-act.svg";
import ChannelIcon from "../../../../../../../public/common/channel-sidebar.svg";
import EnvelopeIcon from "../../../../../../../public/common/envelope-action.svg";
import EnvelopeActIcon from "../../../../../../../public/common/envelope-act.svg";
import RemoveBadgeIcon from "../../../../../../../public/common/remove-badge.svg";
import SelectIcon from "../../../../../../../public/common/select.svg";
import SlackIcon from "../../../../../../../public/common/slack_gray.svg";
import SlackActIcon from "../../../../../../../public/common/slack_act.svg";
import TelegramIcon from "../../../../../../../public/common/telegram_channel.svg";
import TelegramActIcon from "../../../../../../../public/common/telegram_act.svg";
import WhatsappIcon from "../../../../../../../public/common/whatsapp_gray.svg";
import WhatsappActIcon from "../../../../../../../public/common/whatsapp_act.svg";
import { mapState } from "vuex";

export default {
  name: "ChannelsTab",

  data() {
    return {
      action: ActionIcon,
      channel: ChannelIcon,
      envelope: EnvelopeActIcon,
      envelope_act: EnvelopeIcon,
      new_item: [],
      remove_badge: RemoveBadgeIcon,
      select_icon: SelectIcon,
      slack: SlackIcon,
      slack_act: SlackActIcon,
      telegram: TelegramIcon,
      telegram_act: TelegramActIcon,
      whatsapp: WhatsappIcon,
      whatsapp_act: WhatsappActIcon,
      emails: ["example@test.com", "example2@test.com"],
    };
  },
  computed: {
    ...mapState("investor_configuration", ["form_channel"]),
  },
  mounted() {},
  methods: {
    addItem() {
      const currentChannel =
        this.$store.state.investor_configuration.form_channel.channel;
      const email = {
        channel: currentChannel,
        emails: [
          ...this.$store.state.investor_configuration.form_channel.emails,
          this.new_item,
        ],
      };
      this.$store.commit("investor_configuration/set_form_channel", email);
      this.new_item = "";
    },
    setChannel(opt) {
      const channel = {
        channel:
          opt == 3
            ? "slack"
            : opt == 2
            ? "telegram"
            : opt
            ? "whatsapp"
            : "email",
      };
      const updatedChannel = {
        ...this.$store.state.investor_configuration.form_channel,
        ...channel,
      };
      this.$store.commit(
        "investor_configuration/set_form_channel",
        updatedChannel
      );
    },
  },
};
</script>

<style lang="scss">
@import "../../../../../../styles/pages/investor_configuration/channels_tab.scss";
</style>
