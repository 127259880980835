<template>
  <b-modal
    ref="my-modal"
    id="modal-audit"
    hide-footer
    hide-header
    size="xl"
    ok-only
    :visible="displayModal"
    body-class="p-0"
    @hide="$emit('close')"
  >
    <div class="modal-audit">
      <h3 class="modal-title">
        <div class="modal-title-text">Audit Account</div>
        <div class="modal-title-close-icon" @click="handleCloseButtonClicked">
          <img :src="close_icon" alt="close" />
        </div>
      </h3>
      <div class="connection">
        <Multiselect
          v-model="connection"
          :hideSelected="true"
          :options="connection_options"
          label="name"
          placeholder="Select a connection"
          valueProp="token"
          @select="handleConnectionChange"
        >
          <template v-slot:caret>
            <span class="multiselect-caret">
              <img :src="icon_caret" alt="" />
            </span>
          </template>
        </Multiselect>
      </div>
      <b-card no-body class="audit-card">
        <b-tabs pills card class="audit-tabs">
          <b-tab
            v-for="(tab, index) in tabs"
            :key="index"
            :title="tab.title"
            @click="handleTabChange(index)"
            ><b-card-text>
              <AlertInformation
                title="You haven't selected any connection"
                description="Please select a connection to display the data"
                v-if="connection == null"
              />
              <template v-else>
                <div v-if="showSymbols" class="row mt-2 mb-2">
                  <div class="col-md-6">
                    <label>Filter by symbol</label>
                    <Multiselect
                      v-model="symbol"
                      :hideSelected="true"
                      :searchable="true"
                      :options="symbolOptions"
                      label="name"
                      placeholder="Select a symbol"
                      valueProp="token"
                    >
                      <template v-slot:caret>
                        <span class="multiselect-caret">
                          <img :src="icon_caret" alt="" />
                        </span>
                      </template>
                    </Multiselect>
                  </div>
                  <div class="col-md-6" v-if="showDateRange">
                    <label>Filter by a date range</label>
                    <BaseDateRangePicker
                      style="width: 100%"
                      v-model="dateRange"
                      format="YYYY-MM-DD"
                      :showLabel="false"
                      :icon="calendar_icon"
                      label="Date Range"
                      placeholder="From - To"
                      :everydayMinDate="everydayMinDate"
                      :everydayMaxDate="everydayMaxDate"
                    />
                  </div>
                </div>

                <div
                  v-if="showInputDay"
                  class="form-group d-flex flex-column aling-item-center justify-content-end"
                >
                  <div class="row mt-2">
                    <div class="col-md-6">
                      <label>Filter by coin</label>
                      <input
                        v-on:input="debounceInputCoin"
                        type="text"
                        class="form-control"
                        style="margin-bottom: 8px"
                        id="coin"
                        placeholder="Filter by coin"
                        v-model="searchCoin"
                      />
                    </div>
                    <div class="col-md-6">
                      <label>Filter by a date range</label>
                      <BaseDateRangePicker
                        style="width: 100%"
                        v-model="dateRange"
                        format="YYYY-MM-DD"
                        :showLabel="false"
                        :icon="calendar_icon"
                        label="Date Range"
                        placeholder="From - To"
                        :everydayMinDate="everydayMinDate"
                        :everydayMaxDate="everydayMaxDate"
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <b-table
                  v-show="showTable"
                  :items="items"
                  :currentPage="currentPage"
                  :fields="tab.fields.length > 0 ? tab.fields : null"
                  :per-page="100"
                  responsive
                  sticky-header
                  :busy="loading"
                  show-empty
                  :empty-text="tab.emptyMsg"
                >
                  <template #empty="scope">
                    <AlertNoInformation />
                  </template>
                  <template #table-busy>
                    <b-skeleton-table
                      :rows="5"
                      :columns="4"
                      :table-props="{ striped: true }"
                    ></b-skeleton-table>
                  </template>

                  <template #cell(orderId)="data">
                    <div class="d-flex flex-row">
                      {{ data.item.orderId }}
                      <span v-show="data.item.orderIdStatus" class="exist"
                        ><b-icon icon="check-circle"></b-icon
                      ></span>
                      <span v-show="!data.item.orderIdStatus" class="fail">
                        <b-icon icon="asterisk"></b-icon
                      ></span>
                    </div>
                  </template>
                  <template #cell(time)="data">
                    <div class="time">
                      <span>{{
                        $helpers.formatToUTC(new Date(data.item.time))
                      }}</span>
                    </div>
                  </template>
                  <template #cell(updateTime)="data">
                    <div class="time">
                      <span>{{
                        $helpers.formatToUTC(new Date(data.item.updateTime))
                      }}</span>
                    </div>
                  </template>
                </b-table>

                <AlertInformation
                  title="You haven't selected any symbol"
                  description="Please select a symbol"
                  v-show="!showTable"
                />

                <div class="row pl-3 pr-3">
                  <div class="col d-flex p-1">
                    <b-pagination
                      v-show="
                        items > 0 &&
                        (this.selectedTab !== 0 || this.selectedTab !== 4)
                      "
                      v-model="currentPage"
                      :total-rows="items.length > 0 ? items.length : 0"
                      :per-page="100"
                    />
                  </div>
                </div>
              </template> </b-card-text
          ></b-tab>
        </b-tabs>
      </b-card>
    </div>
  </b-modal>
</template>

<script>
import audit from "./audit-account/audit";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2";
import { debounce } from "debounce";
import { mapState } from "vuex";
import closeIcon from "@/assets/images/close.svg";
import calendarIcon from "@/assets/images/calendar.svg";
import IconCaret from "../../../../public/common/select-arrow-down.svg";
import AlertNoInformation from "../../commons/alerts/AlertNoInformation.vue";
import AlertInformation from "../../commons/alerts/AlertInformation.vue";
import BaseDateRangePicker from "@/components/commons/BaseDateRangePicker";

export default {
  name: "AuditAccountNew",
  props: {
    displayModal: {
      required: true,
      type: Boolean,
    },
    investor: {
      required: true,
    },
  },
  created() {
    this.getSymbols();
  },
  components: {
    Multiselect,
    AlertNoInformation,
    AlertInformation,
    BaseDateRangePicker,
  },
  data() {
    return {
      show: false,
      fields: [],
      tabs: audit,
      selectedTab: 0,
      items: [],
      loading: false,
      currentPage: 1,
      symbol: "",
      symbolOptions: [],
      symbols_records: 0,
      symbolsPage: 1,
      searchSymbol: "",
      days: 30,
      searchCoin: "",
      dateRange: null,
      loadingSymbol: false,
      connection: null,
      close_icon: closeIcon,
      icon_caret: IconCaret,
      calendar_icon: calendarIcon,
    };
  },
  computed: {
    ...mapState("connections", ["connection_list_selector"]),
    connection_options() {
      return this.connection_list_selector &&
        this.connection_list_selector._data
        ? this.connection_list_selector._data.data
        : null;
    },
    investors() {
      if (this.investor && this.investor.info && this.investor.info) {
        return this.investor.info.investor;
      } else if (this.subinvestor) {
        return this.subinvestor.subinvestor;
      }
      return {};
    },
    exchange() {
      if (this.investors) {
        const configuration = JSON.parse(this.investors.configurations);
        return configuration.exchange;
      }
      return null;
    },
    showSymbols() {
      if (
        this.selectedTab === 0 ||
        this.selectedTab === 4 ||
        this.selectedTab === 5 ||
        this.selectedTab === 8 ||
        this.selectedTab === 9
      )
        return false;
      return true;
    },
    showInputDay() {
      return this.selectedTab === 8 || this.selectedTab === 9;
    },
    showDateRange() {
      return this.selectedTab === 3;
    },
    showTable() {
      if (
        this.selectedTab === 0 ||
        this.selectedTab === 4 ||
        this.selectedTab === 5 ||
        this.selectedTab === 8 ||
        this.selectedTab === 9 ||
        this.selectedTab === 10
      )
        return true;
      if (this.symbol == null || this.symbol == "") return false;
      return true;
    },
  },
  methods: {
    everydayMinDate() {
      return new Date();
    },
    everydayMaxDate() {
      return new Date();
    },
    debounceInputCoin: debounce(function (e) {
      this.fetchAudit(this.selectedTab);
    }, 500),
    debounceInputDay: debounce(function (e) {
      this.fetchAudit(this.selectedTab);
    }, 1000),
    handleCloseButtonClicked() {
      this.$emit("close");
    },
    handlePageChange(page) {
      this.symbolsPage = page;
      this.getSymbols();
    },
    handleTabChange(index) {
      this.selectedTab = index;
      this.dateRange = null;
      this.symbol = "";
      if (
        index === 0 ||
        index === 4 ||
        index === 5 ||
        this.selectedTab === 8 ||
        this.selectedTab === 9 ||
        this.selectedTab === 10
      ) {
        this.fetchAudit(index);
      }
      if (this.symbol) this.fetchAudit(index);
    },
    handleConnectionChange() {
      this.fetchAudit(this.selectedTab);
    },
    async getSymbols() {
      this.loadingSymbol = true;
      const data = {
        page_kucoin: 1,
        page_binance: this.symbolsPage,
        search: this.searchSymbol,
      };
      const response = await this.$http.server1(
        "bwfs/admin/symbols/correlator",
        data
      );
      this.symbolOptions = response.data.binance_response;
      this.symbols_records = response.paginate.binance_paginate.records;
      this.loadingSymbol = false;
    },
    async fetchAudit(index) {
      if (this.connection == null) {
        return false;
      }
      this.loading = true;
      const data = {
        exchange_token: this.connection,
        ...(this.tabs[index].params ? this.tabs[index].params : {}),
      };
      if (this.dateRange) {
        data.start_time = this.dateRange.startDate;
        data.end_time = this.dateRange.endDate;
      }
      if (
        index === 0 ||
        index === 4 ||
        index === 5 ||
        this.selectedTab === 8 ||
        this.selectedTab === 9
      ) {
        /*
          index === 0 (Spot Balances)
          index === 4 (Futures Account)
          index === 5 (Futures Balances)
          index === 8 (Withdrawals)
          index === 9 (Deposits)
        */
        const url = this.tabs[index].url;
        const response = await this.$http.server1(
          url,
          data,
          {},
          null,
          this.tabs[index].method
        );

        if (this.selectedTab === 8 || this.selectedTab === 9) {
          this.items = [];
          if (response.error) {
            this.items = [];
            this.loading = false;
            return;
          }
          this.items = response.data;
          this.loading = false;
          return;
        }
        if (index === 0 || index === 5) {
          if (response.error) {
            this.items = [];
            this.loading = false;
            return;
          }
          let balances = response.balances;

          this.items = balances;
          this.loading = false;
        } else {
          const array = [];
          if (response.error) {
            this.items = [];
            this.loading = false;
            return;
          }
          let futuresAccount = response.data;
          delete futuresAccount.positions;
          delete futuresAccount.assets;
          array.push(futuresAccount);
          this.items = array;
          this.loading = false;
        }
      } else {
        if (this.symbol) {
          data.symbol = this.symbol;
        }
        if (index === 3) {
          // index = 3 (spot trades)
          const response = await this.$http.server1(
            this.tabs[index].url,
            data,
            {},
            null,
            "GET"
          );
          if (response.error) {
            this.items = [];
            this.loading = false;
            return;
          }
          this.items = response.data;
          this.loading = false;
        }
        if (index === 10) {
          const url = this.tabs[index].url;
          const response = await this.$http.server1(url, data, {}, null, "GET");
          if (response.error) {
            this.items = [];
            this.loading = false;
            return;
          }
          this.items = response.data;
          this.loading = false;
        }
        else {
          const url = this.tabs[index].url;
          const response = await this.$http.server1(url, data, {}, null, "GET");
          if (response.error) {
            this.items = [];
            this.loading = false;
            return;
          }
          /* let orders = [...response.data].reverse();
          for (let i = 0; i < orders.length; i++) {
            const body = { client_order_id: orders[i].clientOrderId };
            const res = await this.$http.server1(this.tabs[index].url2, body);
            let orderStatus = null;
            if (res.data.length > 0) {
              orderStatus = true;
            } else {
              orderStatus = false;
            }
            orders[i].orderIdStatus = orderStatus;
          } */
          this.items = response.data;
          this.loading = false;
        }
      }
    },
    showModalUsingRef() {
      this.$refs["my-modal"].show();
    },
    hideModalUsingRef() {
      this.$refs["my-modal"].hide();
    },
    resetLocalVariables() {
      this.currentPage = 1;
      this.symbolsPage = 1;
      this.symbol = "";
      this.selectedTab = 0;
      this.connection = null;
    },
    resetGlobalVariables() {
      this.$store.commit("set_subinvestor", { subinvestor: {} });
      this.$store.commit("set_investor", { investor: {} });
    },
    refreshSymbols() {
      this.getSymbols();
    },
    checkforNegativeValue(value) {
      return !value;
    },
    getDataforConnectionSelector() {
      const token = this.investor.info
        ? this.investor.info.token
        : this.investor;
      this.$store.commit("connections/set_investor_token", token);
      this.$store.dispatch("connections/connection_list_selector");
    },
  },
  watch: {
    dateRange(value) {
      if (value != null) {
        this.fetchAudit(this.selectedTab);
      }
    },
    displayModal(value) {
      if (this.checkforNegativeValue(value)) {
        //this.hideModalUsingRef();

        this.resetLocalVariables();
        this.resetGlobalVariables();

        this.refreshSymbols();
        return;
      }
      this.getDataforConnectionSelector();
      //this.showModalUsingRef();
    },
    symbol(value) {
      if (value !== "") this.fetchAudit(this.selectedTab);
    },
    async investors(value) {
      if (value) {
        await this.fetchAudit(this.selectedTab);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.exist {
  & svg {
    color: #28a745 !important;
    fill: #28a745 !important;
  }
}
.fail {
  & svg {
    color: #dc3545 !important;
    fill: #dc3545 !important;
  }
}
.time {
  max-width: 200px !important;
  width: 200px;
}
.connection {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 10px;
  padding-right: 20px;
  gap: 1px;
  .multiselect {
    width: 240px !important;
    margin: 0 !important;
  }
  .card-header {
    margin: 2px;
  }
}
.modal-audit {
  .modal-title {
    background: #1d1e25;
    display: flex;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .modal-title-close-icon {
      cursor: pointer;
    }
  }
  .audit-card {
    margin: 20px;
    margin-top: 0px;
  }
}
</style>
<style lang="scss">
.audit-tabs {
  .card-header-pills {
    margin-left: -2px;
  }
}
.modal-audit {
  .reportrange-text {
    color: rgba(255, 255, 255, 0.5) !important;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px !important;
  }
  .multiselect-search {
    background: none;
    color: #fff;
    padding-left: 0;
  }
}
</style>
