import multiguard from "vue-router-multiguard";
import Vue from "vue";
import VueRouter from "vue-router";

import SystemsHeartbeat from "../views/SystemsHeartbeat.vue";
import Authorizations from "../views/Authorizations.vue";
import AuthorizationScripts from "../views/AuthorizationScripts.vue";
import Backtestings from "../views/Backtestings.vue";
import Contributor from "../views/Contributor.vue";
import Correlator from "../views/Correlator.vue";
import Dashboard from "../views/Dashboard.vue";
import Groups from "../views/Groups.vue";
import HealthAudit from "../views/HealthAudit.vue";
import Investor from "../views/Investor.vue";
import InvestorConfiguration from "../views/InvestorConfiguration";
import Investors from "../views/Investors.vue";
import InvestorsList from "../views/InvestorsList.vue";
import LMDDCollector from "../views/LMDDCollector.vue";
import LMDDAccount from "../views/LMDDAccount.vue";
import Login from "../views/Login.vue";
import MaintenanceMode from "../views/MaintenanceMode.vue";
import Notifications from "../views/Notifications.vue";
import RequestAccess from "../views/RequestAccess.vue";
import RequestLogs from "../views/RequestLogs.vue";
import RequestPending from "../views/RequestPending.vue";
import ServersManagement from "../views/ServersManagement.vue";
import Supervisors from "../views/Supervisors.vue";
import StyleGuide from "../views/StyleGuide.vue";
import SubInvestor from "../views/SubInvestor.vue";

import isLoggedIn from "@/helpers/middlewares/isLoggedIn";
import isLoggedIndex from "@/helpers/middlewares/isLoggedIndex";
import isTokenAvailable from "@/helpers/middlewares/isTokenAvailable";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    beforeEnter: multiguard([isLoggedIndex]),
  },
  /* 
    Pagina: login
    --- Destinada para el inicio de sesion de los usuarios en suus diferentes roles
  */
  {
    path: "/login/:token?",
    name: "Login",
    component: Login,
    beforeEnter: multiguard([isLoggedIn]),
  },

  /* 
    Pagina: request access
    --- Destinada para solicitar acceeso a la plataforma 
  */
  {
    path: "/request/access",
    name: "RequestAccess",
    component: RequestAccess,
  },

  /* 
    Pagina: request access
    --- Destinada para notificar que su token de acceso esta pendiente por autorizar 
  */
  {
    path: "/request/peding",
    name: "RequestPending",
    component: RequestPending,
  },

  /* 
    Pagina: investor
    --- Destinada al detalle del investor luego que hace login, o cuando un administrador quiere ver dicho investor.
  */
  {
    path: "/investor/:token",
    name: "Investor",
    component: Investor,
    // beforeEnter: multiguard([isLoggedIn]),
  },
  {
    path: "/subinvestor/:token",
    name: "SubInvestor",
    component: SubInvestor,
    // beforeEnter: multiguard([isLoggedIn]),
  },
  {
    path: "/contributor/:token",
    name: "Contributor",
    component: Contributor,
    // beforeEnter: multiguard([isLoggedIn]),
  },

  /* 
    Pagina: users
    --- Destinada a la gestion de usuarios
  */
  {
    path: "/admin/investors",
    name: "Investors",
    component: Investors,
    beforeEnter: multiguard([isLoggedIn]),
  },

  /* 
    Pagina: users
    --- Destinada a la gestion de usuarios
  */
  {
    path: "/admin/supervisors",
    name: "Supervisors",
    component: Supervisors,
    beforeEnter: multiguard([isLoggedIn]),
  },
  /*
    Pagina: supervisors
    -Destinada a la gestion de investores cuando un supervisor hace login
  */
  {
    path: "/supervisors",
    name: "Supervisor",
    component: InvestorsList,
    beforeEnter: multiguard([isLoggedIn]),
  },
  /* 
    Pagina: users
    --- Destinada a la gestion de usuarios
  */
  {
    path: "/admin/supervisors",
    name: "Supervisors",
    component: Supervisors,
    beforeEnter: multiguard([isLoggedIn]),
  },

  /* 
    Pagina: authorizations
    --- Destinada a la gestion de autorizaciones
  */
  {
    path: "/admin/authorizations",
    name: "Authorizations",
    component: Authorizations,
    beforeEnter: multiguard([isLoggedIn]),
  },

  /* 
    Pagina: Backtestings
    --- Destinada a la gestion de backtestings
  */
  {
    path: "/admin/backtestings",
    name: "Backtestings",
    component: Backtestings,
    beforeEnter: multiguard([isLoggedIn]),
  },
  {
    path: "/admin/backtestings/:token",
    name: "BacktestingDetail",
    component: Backtestings,
    beforeEnter: multiguard([isLoggedIn]),
  },
  {
    path: "/admin/backtestings/:token/:condition_token",
    name: "BacktestingDetailWithCondition",
    component: Backtestings,
    beforeEnter: multiguard([isLoggedIn]),
  },
  /* 
    Pagina: Correlator
    --- Destinada a la gestion de precios de
  */
  {
    path: "/admin/correlator",
    name: "Correlator",
    component: Correlator,
    beforeEnter: multiguard([isLoggedIn]),
  },
  /* 
    Pagina: servers
    --- Destinada a la visualizacion de recursos del servidor
  */
  {
    path: "/admin/servers",
    name: "ServersManagement",
    component: ServersManagement,
    beforeEnter: multiguard([isLoggedIn]),
  },
  /* 
    Pagina: LLMMD Collector
    --- Destinada al manejo de respaldos
  */
  {
    path: "/admin/backup",
    name: "LMDD",
    component: LMDDCollector,
    beforeEnter: multiguard([isLoggedIn]),
  },

  /* 
    Pagina: LMMD Account
    --- Destinada a las cuentas lmdd
  */
  {
    path: "/admin/backup/accounts",
    name: "LMDDAccount",
    component: LMDDAccount,
    beforeEnter: multiguard([isLoggedIn]),
  },
  /* 
    Pagina: Notifications
    --- Gestion de notificaciones
  */
  {
    path: "/admin/notifications",
    name: "notifications",
    component: Notifications,
    beforeEnter: multiguard([isLoggedIn]),
  },

  /* 
    Pagina: Autorizaciones de scripts
  */
  {
    path: "/admin/scripts/authorization",
    name: "scripts/authorization",
    component: AuthorizationScripts,
    beforeEnter: multiguard([isLoggedIn]),
  },

  /* 
    Pagina: logs requests
  */
  {
    path: "/admin/request/logs",
    name: "request/logs",
    component: RequestLogs,
    beforeEnter: multiguard([isLoggedIn]),
  },

  /* 
    Pagina: groups
  */
  {
    path: "/admin/groups",
    name: "Groups",
    component: Groups,
    beforeEnter: multiguard([isLoggedIn]),
  },

  /* 
    Pagina: logs requests
  */
  {
    path: "/maintenance-mode",
    name: "maintenance-mode",
    component: MaintenanceMode,
  },

  /* 
    Pagina: styles
    --- Guia de estilos para la pagina web
  */
  {
    path: "/styles",
    name: "StyleGuide",
    component: StyleGuide,
  },
  /*
    Page : Dashboard 
   */
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: multiguard([isTokenAvailable]),
  },
  // page: health audit
  {
    path: "/health-audit",
    name: "HealthAudit",
    component: HealthAudit,
    beforeEnter: multiguard([isLoggedIn]),
  },
  // page: Investors list
  {
    path: "/admin/investors-list",
    name: "InvestorsList",
    component: InvestorsList,
    beforeEnter: multiguard([isLoggedIn]),
  },
  // page: Investor configuration
  {
    path: "/admin/investor-configuration",
    name: "InvestorConfiguration",
    component: InvestorConfiguration,
    beforeEnter: multiguard([isLoggedIn, isTokenAvailable]),
  },
  // page: Algorithm health
  {
    path: "/systems-heartbeat",
    name: "SystemsHeartbeat",
    component: SystemsHeartbeat,
    beforeEnter: multiguard([isLoggedIn]),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
