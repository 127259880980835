<template>
  <div>
    <div class="row">
        <div class="col-md-12 chart text-right">
            <div class="p-2 pt-0 links d-flex fle
            x-row">
                <div class="mt-1">
                <!-- <span  v-if="open>0" class="order-open mr-5 pt-1"></span> !-->
                </div>
                <div class="ml-auto">
                    <a href="javascript:void(0);" @click=" activeLastYear ? unSetActiveLastYear() : activeLastMonth ? showGrafic('all') : showGrafic('month')" :class="activeLastMonth ? 'mr-1 active' : 'mr-1'">
                      Last month
                    </a>
                    <a href="javascript:void(0);"  @click="activeLastMonth ? unSetActiveLastMonth() : activeLastYear ? showGrafic('all') : showGrafic('year')" :class="activeLastYear ? 'active' : ''">
                      Last year
                    </a>
                </div>
            </div>
        </div> 
    </div>
     
    <div class="row">
      <div class="col-md-12 mgb" v-if="investor.info.investor.orders_close > 0">
        <apexchart 
          width="100%" 
          height="200px" 
          type="line"  
          :options="chart__performance_options" 
          :series="chart__performance_series"
          v-if="activeAllHistory"
        >
        </apexchart>
        <apexchart 
          width="100%" 
          height="200px" 
          type="line"  
          :options="chart__performance_series_last_month.length > 0 ? chart__performance_series_last_month[0].data.length > 1 ? chart__performance_options_last_month : chart__performance_options : chart__performance_options"
          :series="chart__performance_series_last_month.length > 0 ? chart__performance_series_last_month[0].data.length > 1 ? chart__performance_series_last_month : chart__performance_series : chart__performance_series"
          v-if="activeLastMonth"
        >
        </apexchart>
        <apexchart 
          width="100%" 
          height="200px" 
          type="line"  
          :options="chart__performance_series_last_year.length > 0 ? chart__performance_series_last_year[0].data.length > 1 ? chart__performance_options_last_year : chart__performance_options : chart__performance_options"
          :series="chart__performance_series_last_year.length > 0 ? chart__performance_series_last_year[0].data.length > 1 ? chart__performance_series_last_year : chart__performance_series : chart__performance_series"
          v-if="activeLastYear"
        >
        </apexchart>
      </div>

        <div class="col-md-12"  v-if="investor.info.investor.orders_close ? investor.info.investor.orders_close   ==  0 : true">
          <div class="blackbox d-flex justify-content-center align-items-center">
              <span class="blackbox-text small"> No available information</span>
          </div>
        </div>
      
     
    </div>
  
  </div>
</template>
<script>
	export default {
		name: 'InvestorCardChart',
    props: ['investor_info', 'open_info'],
		created() {
      if (this.investor) { 
        this.settingGraficValues(this.investor);
      }
    },
		data() {
      let vm = this;
      return {
        investor: this.investor_info,
        open: this.open_info,
  			symbol: 'BTCUSDT',
        modal__change_order_data__immediate_closure: 0,
        chart__performance_options: {
          tooltip: {
            borderRadius: 0,
            x: {
              // format: 'dd/MM/yy HH:mm'
            },
            custom: function({series, seriesIndex, dataPointIndex, w}) {
              let date = vm.grafic[dataPointIndex].close_date_at;
              let profit = vm.grafic[dataPointIndex].perfomance;
              let profit_day = vm.grafic[dataPointIndex].perfomance_day;
              let ppb = vm.grafic[dataPointIndex].profit_percentage_balance;
              let ppbd = vm.grafic[dataPointIndex].profit_percentage_day;
              
              let class_ccs_ppb = 'text-success';
              if (ppb < 0) {
                class_ccs_ppb = 'text-danger';
              }

              let class_ccs_ppbd = 'text-success';
              if (ppbd < 0) {
                class_ccs_ppbd = 'text-danger';
              }
              
              return `
                <div style="background: #232429;" class="p-2">
                  <span class="d-block small">
                    From: <span class="text-muted">${date}</span><br>
                    To: <span class="text-muted">${date.substr(0, 10)} 23:59:59</span>
                  </span>
                  <span class="d-block small">
                    Daily N.A.V: <span class="mono ${class_ccs_ppb}">${vm.$helpers.numberFormat(profit)} (${ppb.toFixed(2)}%)</span>
                  </span>
                  <span class="d-block small">
                    Daily closed performance: <span class="mono ${class_ccs_ppbd}">${vm.$helpers.numberFormat(profit_day)} (${ppbd.toFixed(2)}%)</span>
                  </span>
                </div>
              `;
            }
          },
          annotations: {
            xaxis: [
            ]
          },
          chart: {
            animations: {
              enabled: false,
            },
            toolbar: {
              show: false
            },
          },
          grid: {
            show: false,
            padding: {
              left: -10,
              right: 0
            }
          },
          fill: {
            type: 'line',
            gradient: {
              shade: 'dark',
              gradientToColors: [ '#0D141D'],
              shadeIntensity: 1,
              type: 'vertical',
              opacityFrom: 0.5,
              opacityTo: 0.4,
              stops: [0, 100]
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            colors: ['#50E7FF'],
            width: 2,
            dashArray: 0,      
            curve: 'straight'
          },
          xaxis: {
            type: 'datetime',
            categories: [],
            labels: {
              show: false
            },
            tooltip: {
              enabled: false
            },
            axisBorder: {
              show: false,
              color: '#50E7FF',
            },
            axisTicks: {
              show: false,
              color: '#50E7FF',
            },
          },
          yaxis: {
            tooltip: {
              enabled: false
            },
            labels: {
              enabled: false,
              show: false,
              style: {
                  colors: ['#50E7FF'],
              }
            },
            axisBorder: {
              show: false,
              color: '#50E7FF',
            },
            axisTicks: {
              show: false,
              color: '#50E7FF',
            },
          },
        },
        chart__performance_series: [{
          name: 'series-1',
          data: []
        }],
        chart__performance_options_last_month: {
          tooltip: {
            borderRadius: 0,
            x: {
              // format: 'dd/MM/yy HH:mm'
            },
            custom: function({series, seriesIndex, dataPointIndex, w}) {
              let date = vm.grafic[dataPointIndex].close_date_at;
              let profit = vm.grafic[dataPointIndex].perfomance;
              let profit_day = vm.grafic[dataPointIndex].perfomance_day;
              let ppb = vm.grafic[dataPointIndex].profit_percentage_balance;
              let ppbd = vm.grafic[dataPointIndex].profit_percentage_day;
              
              let class_ccs_ppb = 'text-success';
              if (ppb < 0) {
                class_ccs_ppb = 'text-danger';
              }

              let class_ccs_ppbd = 'text-success';
              if (ppbd < 0) {
                class_ccs_ppbd = 'text-danger';
              }

              return `
                <div style="background: #232429;" class="p-2">
                  <span class="d-block small">
                    From: <span class="text-muted">${date}</span><br>
                    To: <span class="text-muted">${date.substr(0, 10)} 23:59:59</span>
                  </span>
                  <span class="d-block small">
                    Daily N.A.V: <span class="mono ${class_ccs_ppb}">${vm.$helpers.numberFormat(profit)} (${ppb.toFixed(2)}%)</span>
                  </span>
                  <span class="d-block small">
                    Daily closed performance: <span class="mono ${class_ccs_ppbd}">${vm.$helpers.numberFormat(profit_day)} (${ppbd.toFixed(2)}%)</span>
                  </span>
                </div>
              `;
            }
          },
          annotations: {
            xaxis: [
            ]
          },
          chart: {
            animations: {
              enabled: false,
            },
            toolbar: {
              show: false
            },
          },
          grid: {
            show: false,
            padding: {
              left: -10,
              right: 0
            }
          },
          fill: {
            type: 'line',
            gradient: {
              shade: 'dark',
              gradientToColors: [ '#0D141D'],
              shadeIntensity: 1,
              type: 'vertical',
              opacityFrom: 0.5,
              opacityTo: 0.4,
              stops: [0, 100]
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            colors: ['#50E7FF'],
            width: 2,
            dashArray: 0,      
            curve: 'straight'
          },
          xaxis: {
            type: 'datetime',
            categories: [],
            labels: {
              show: false
            },
            tooltip: {
              enabled: false
            },
            axisBorder: {
              show: false,
              color: '#50E7FF',
            },
            axisTicks: {
              show: false,
              color: '#50E7FF',
            },
          },
          yaxis: {
            tooltip: {
              enabled: false
            },
            labels: {
              enabled: false,
              show: false,
              style: {
                  colors: ['#50E7FF'],
              }
            },
            axisBorder: {
              show: false,
              color: '#50E7FF',
            },
            axisTicks: {
              show: false,
              color: '#50E7FF',
            },
          },
        },
        chart__performance_series_last_month: [{
          name: 'series-1',
          data: []
        }],
        chart__performance_options_last_year: {
          tooltip: {
            borderRadius: 0,
            x: {
              // format: 'dd/MM/yy HH:mm'
            },
            custom: function({series, seriesIndex, dataPointIndex, w}) {
              let date = vm.grafic[dataPointIndex].close_date_at;
              let profit = vm.grafic[dataPointIndex].perfomance;
              let profit_day = vm.grafic[dataPointIndex].perfomance_day;
              let ppb = vm.grafic[dataPointIndex].profit_percentage_balance;
              let ppbd = vm.grafic[dataPointIndex].profit_percentage_day;
              
              let class_ccs_ppb = 'text-success';
              if (ppb < 0) {
                class_ccs_ppb = 'text-danger';
              }

              let class_ccs_ppbd = 'text-success';
              if (ppbd < 0) {
                class_ccs_ppbd = 'text-danger';
              }
              
              return `
                <div style="background: #232429;" class="p-2">
                  <span class="d-block small">
                    From: <span class="text-muted">${date}</span><br>
                    To: <span class="text-muted">${date.substr(0, 10)} 23:59:59</span>
                  </span>
                  <span class="d-block small">
                    Daily N.A.V: <span class="mono ${class_ccs_ppb}">${vm.$helpers.numberFormat(profit)} (${ppb.toFixed(2)}%)</span>
                  </span>
                  <span class="d-block small">
                    Daily closed performance: <span class="mono ${class_ccs_ppbd}">${vm.$helpers.numberFormat(profit_day)} (${ppbd.toFixed(2)}%)</span>
                  </span>
                </div>
              `;
            }
          },
          annotations: {
            xaxis: [
            ]
          },
          chart: {
            animations: {
              enabled: false,
            },
            toolbar: {
              show: false
            },
          },
          grid: {
            show: false,
            padding: {
              left: -10,
              right: 0
            }
          },
          fill: {
            type: 'line',
            gradient: {
              shade: 'dark',
             // gradientToColors: [ '#0D141D'],
              shadeIntensity: 1,
              type: 'vertical',
              opacityFrom: 0.5,
              opacityTo: 0.4,
              stops: [0, 100]
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            colors: ['#50E7FF'],
            width: 2,
            dashArray: 0,      
            curve: 'straight'
          },
          xaxis: {
            type: 'datetime',
            categories: [],
            labels: {
              show: false
            },
            tooltip: {
              enabled: false
            },
            axisBorder: {
              show: false,
              color: '#50E7FF',
            },
            axisTicks: {
              show: false,
              color: '#50E7FF',
            },
          },
          yaxis: {
            tooltip: {
              enabled: false
            },
            labels: {
              enabled: false,
              show: false,
              style: {
                  colors: ['#50E7FF'],
              }
            },
            axisBorder: {
              show: false,
              color: '#50E7FF',
            },
            axisTicks: {
              show: false,
              color: '#50E7FF',
            },
          },
        },
        chart__performance_series_last_year: [{
          name: 'series-1',
          data: []
        }],
        token: '',
        grafic: [],
        grafic_last_month: [],
        grafic_last_year: [],
        resumen: {},
        monthly_yield: {},
        balance: 0,
        activeAllHistory: true,
        activeLastMonth: false,
        activeLastYear: false,
      };
		},
		methods: {
      showGrafic(type) {
        if (type == 'all') {
          this.activeAllHistory = true;
          this.activeLastMonth = false;
          this.activeLastYear = false;
        } else if (type == 'month') {
          this.activeAllHistory = false;
          this.activeLastMonth = true;
          this.activeLastYear = false;
        } else if (type == 'year') {
          this.activeAllHistory = false;
          this.activeLastMonth = false;
          this.activeLastYear = true;
        }
      },
      handleEditMonthlyYieldBoolean(data, balance_inicial, total_operations) {
        let keys = Object.keys(data);
        let total_p = 0;
        for (let i = 0; i < keys.length; i++) {
          let mes_anterior = 0;
          if (i == 0) {
            mes_anterior = ((data[keys[i]].balance - balance_inicial) / balance_inicial) * 100;
          } else {
            mes_anterior = ((data[keys[i]].balance - data[keys[i - 1]].balance) / data[keys[i - 1]].balance) * 100;
          }
          if (((data[keys[i]].balance > balance_inicial && mes_anterior >= 0) || (data[keys[i]].balance === 0)) && data[keys[i]].perfomance >= 0) {
            total_p += 1;
          }
        }
        let p = ((total_p * 100) / keys.length);
        return (total_p === keys.length || p >= 80) && total_operations > 0; 
      }, 
      settingGraficValues(value) {
        let perfomance = [];
        let perfomance_last_month = [];
        let perfomance_last_year = [];
        let categories = [];
        let categories_last_month = [];
        let categories_last_year = [];


        if (this.investor && this.investor.grafic &&  this.investor.grafic.all.length < 2) {
          return
        };

        this.grafic_last_year = [];
        this.grafic_last_month = [];
        this.grafic = value.grafic;
        this.resumen = value.resumen;
        this.symbol = value.info.investor.symbol;
        this.balance = value.info.investor.balance;

       

        this.grafic_last_year =  value.grafic.last_year
        this.grafic_last_month =  value.grafic.last_month
        this.grafic =  value.grafic.all

        //this.monthly_yield = tm;
        this.chart__performance_series[0].data = value.grafic.all.map(e => e.perfomance);
        this.chart__performance_options.xaxis.categories = value.grafic.all.map((e, i) => i);

        this.chart__performance_series_last_month[0].data = value.grafic.last_month.map(e => e.perfomance);
        this.chart__performance_options_last_month.xaxis.categories = value.grafic.last_month.map((e, i) => i);

        this.chart__performance_series_last_year[0].data = value.grafic.last_year.map(e => e.perfomance);
        this.chart__performance_options_last_year.xaxis.categories = value.grafic.last_year.map((e, i) => i);
      },
      unSetActiveLastYear() {
        this.activeLastYear = false;
        this.activeLastMonth = true;
        this.showGrafic('month');

      },
      unSetActiveLastMonth() {
        this.activeLastMonth = false;
        this.activeLastYear = true;
        this.showGrafic('year');
      }
		},
    watch:{
      investor(value) {
        if(value) {
          console.log(value);
          this.settingGraficValues(value);
        }
      },
    },
    filters: {
      handleEditMonthlyYield(data, balance_inicial) {
        let keys = Object.keys(data);
        let total_p = 0;
        for (let i = 0; i < keys.length; i++) {
          let mes_anterior = 0;
          if (i == 0) {
            mes_anterior = ((data[keys[i]].balance - balance_inicial) / balance_inicial) * 100;
          } else {
            mes_anterior = ((data[keys[i]].balance - data[keys[i - 1]].balance) / data[keys[i - 1]].balance) * 100;
          }

          if (((data[keys[i]].balance > balance_inicial && mes_anterior >= 0) || (data[keys[i]].balance === 0)) && data[keys[i]].perfomance >= 0) {
            total_p += 1;
          }
        }
        return `${total_p}/${keys.length}`;
      },
      handleEditMonthlyYieldPercentaje(data, balance_inicial) {
        let keys = Object.keys(data);
        let total_p = 0;
        for (let i = 0; i < keys.length; i++) {
          let mes_anterior = 0;
          if (i == 0) {
            mes_anterior = ((data[keys[i]].balance - balance_inicial) / balance_inicial) * 100;
          } else {
            mes_anterior = ((data[keys[i]].balance - data[keys[i - 1]].balance) / data[keys[i - 1]].balance) * 100;
          }
          if (((data[keys[i]].balance > balance_inicial && mes_anterior >= 0) || (data[keys[i]].balance === 0)) && data[keys[i]].perfomance >= 0) {
            total_p += 1;
          }
        }
        return `${((total_p * 100) / keys.length).toFixed(2)}%`;
      }
    },
	};
</script>

<style lang="scss" scoped>
 .order-open {
    display: block;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: 0.1em solid #50E7FF;
    outline: none;
    background: #50E7FF;
   
  }
  ::v-deep .text-muted{
    color: #9b9b9b !important;
    overflow-wrap: break-word;
  }
  ::v-deep .chart {
      height: auto;
  }
  ::v-deep h5 {
      margin-bottom: 0rem;
  }
  ::v-deep .b-icon.bi {
      font-size: 20px;
  }
  .blackbox {
      min-height: 215px;
      background: #17181f;
      margin-bottom:  12px;
      .blackbox-text {
          color: #f5f5f;
          font-size: 1.5em;
      }
  }
  .mgb {
      margin-bottom: 15px;
  }
  .last-size {
      font-size: 27px !important;
  }
  .turn-button {
      margin-top: 2px !important;
  }

  .turn-button svg {
      font-size: 24px !important;
  }
@media (min-width: 768px) and (max-width: 1200px) {
    ::v-deep .b-icon.bi {
        font-size: 1rem;
    }
   #button-investor-card {
       margin-top: 0px !important;
   }
  }
</style>
